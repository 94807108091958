export enum SignUpSteps {
  ADMIN_PROFILE_1 = 'Create admin profile - name',
  SETUP_AGENCY = 'Set up agency',
  SETUP_GENERAL_AGENT_FIRM = 'Set up general agent firm',
  AGENCY_DETAILS = 'Verify agency details',
  COMPLETE = 'Complete',
  WELCOME_SCREEN = 'Welcome',
  INVITATION = 'Invitation if any',
  ADMIN_INVITE = 'Admin Invite',
  DOWNLINE_INVITE = 'Downline Invite',
  CHOOSE_PROFILE = 'Choose Profile',
}

export enum InviteType {
  ADMIN = 'Admin invite',
  DOWNLINE = 'Downline invite',
}
