import Plausible from 'plausible-tracker';
import { PlausibleEventNameConstants } from '../constants/eventName.constants';

export function trackFilterUsage(
  pageName: string,
  filterCategory: object
): void {
  const plausible = Plausible();
  if (plausible) {
    plausible.trackEvent(PlausibleEventNameConstants.APPLY_FILTER, {
      props: {
        page: pageName,
        filters: JSON.stringify(filterCategory),
      },
    });
  } else {
    console.error('Plausible is not defined');
  }
}

export function trackCustomEvent(
  eventName: string,
  pageName: string,
  userRole?: string
): void {
  const plausible = Plausible();
  if (plausible) {
    plausible.trackEvent(eventName, {
      props: {
        page: pageName,
        role: JSON.stringify(userRole),
      },
    });
  } else {
    console.error('Plausible is not defined');
  }
}
