// All utils in this page are meant to be used by engine lights and pre application submittion modal

// returns tooltip for payer
export const getPayerTooltip = (
  payer: string,
  isPaymentTypeConfigured: boolean
): string => {
  if (isPaymentTypeConfigured) return 'Payer is configured';
  // else if (payer === 'agency' && !isPaymentTypeConfigured)
  //   return 'Payment type is not configured';
  // else if (payer === 'producer') return 'Payer is configured as Producer';
  else return 'Payer not configured';
};
