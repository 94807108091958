import HandleAgentSignUpFlow from './handleAgentSignUpFlow';
import Loading from './loading';
import MyLayout from './layout';
import { RouteConstants } from '../constants/routes.constants';
import { UserType } from '../enums/userType.enum';
import { apiStore } from '../stores/api.store';
import { auth0Store } from '../stores/auth0Store';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../stores/signupStore';
import { useAuth } from '../auth/authProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function OnboardOrAppAccess() {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isOnboarded,
    onboardingStage,
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeApp = async () => {
      await apiStore.init();
      await getAccessTokenSilently().catch((e) => console.error('e :: ', e));
    };
    initializeApp();
  }, []);

  useEffect(() => {
    if (!signupStore.isAppLoading)
      if (signupStore.isAdminAuthorized === false) {
        navigate('/unauthorized-page');
      } else if (signupStore.isAgentAuthorized === false) {
        navigate('/unauthorized-page');
      }
  }, [
    signupStore.isAdminAuthorized,
    signupStore.isAgentAuthorized,
    signupStore.isAppLoading,
  ]);

  useEffect(() => {
    if (isAuthenticated()) {
      if (!isOnboarded && !isEmpty(onboardingStage)) {
        navigate(RouteConstants.onboarding.path);
      }
    }
  }, [onboardingStage, isOnboarded, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated()) navigate(RouteConstants.rootRedirect.path);
  }, [isAuthenticated()]);

  if (
    auth0Store.user?.user_metadata.isOnboarded &&
    (signupStore.isAdminAuthorized || signupStore.isAgentAuthorized)
  )
    return <MyLayout />;
  else {
    if (signupStore.userType === UserType.ADMIN) return <Loading logo />;
    else if (signupStore.userType === UserType.AGENT)
      return signupStore.isInvited === null ? (
        <Loading />
      ) : signupStore.isAgentAuthorized === true ||
        signupStore.isInvited === true ? (
        <HandleAgentSignUpFlow />
      ) : (
        <Loading logo={true} />
      );
    else return <Loading logo={true} />;
  }
}

export default observer(OnboardOrAppAccess);
