import * as React from 'react';

import {
  AccountWithProducerDocument,
  ProducerDocument,
} from '../types/response/data/agents/agentDocument.type';
import { ConfigProvider, Row, Space, Switch, Tooltip, Typography } from 'antd';

import { ColumnsType } from 'antd/es/table';
import { FileSyncOutlined } from '@ant-design/icons';
import { Name } from '../types/data/name.type';
import StatesCircled from '../components/common/statesCircled/StatesCircled';
import { getNameString } from '../utils/name.utils';
import { getStateNameFromCode } from '../utils/common.utils';
import { isEmpty } from 'lodash';

export const getPaymentConfigDisplayName = (name: string) => {
  if (!isEmpty(name)) {
    if (name.toLowerCase() === 'agent' || name.toLowerCase() === 'producer')
      return 'Producer';
    else if (name.toLowerCase() === 'agency') return 'Agency';
    return name;
  } else {
    return 'Not configured';
  }
};

const residentStateColumn: ColumnsType<AccountWithProducerDocument> = [
  {
    dataIndex: 'producer',
    title: 'Home State',
    sorter: false,
    key: 'producer.residentState',
    render: (producer: ProducerDocument) => (
      <StatesCircled
        label={producer.residentState}
        showTooltip={true}
        tooltipTitle={`${
          getStateNameFromCode(producer.residentState) ||
          'Resident state missing'
        }`}
      />
    ),
  },
];

const contactEmailColumn: ColumnsType<AccountWithProducerDocument> = [
  {
    dataIndex: 'email',
    title: 'Contact Email',
    sorter: false,
    key: 'email',
    render: (email: string) => (
      <Typography.Paragraph
        style={{
          color: '#000000',
          textDecoration: 'none',
          fontSize: '14px',
          cursor: 'default',
          marginBottom: '0px',
        }}
      >
        {email}
      </Typography.Paragraph>
    ),
  },
];

const paymentConfigColumns: ColumnsType<AccountWithProducerDocument> = [
  {
    title: 'Initial Payment Account',
    dataIndex: 'paymentConfig',
    key: 'producer.paymentConfig.licenseInitialPayee',
    width: '200px',
    sorter: true,
    align: 'center',
    render: (text: any, record: AccountWithProducerDocument) => {
      return (
        <Row
          style={{
            justifyContent: 'center',
          }}
        >
          {record.producer?.paymentConfig?.licenseInitialPayee ? (
            getPaymentConfigDisplayName(
              record.producer?.paymentConfig?.licenseInitialPayee
            )
          ) : (
            <span style={{ color: '#92A69E', fontSize: '12px' }}>
              Not configured
            </span>
          )}
        </Row>
      );
    },
  },
  {
    title: 'Renewal Payment Account',
    dataIndex: 'paymentConfig',
    key: 'producer.paymentConfig.licenseRenewalPayee',
    width: '200px',
    sorter: true,
    align: 'center',
    render: (text: any, record: AccountWithProducerDocument) => {
      return (
        <Row
          style={{
            justifyContent: 'center',
          }}
        >
          {record.producer?.paymentConfig?.licenseRenewalPayee ? (
            getPaymentConfigDisplayName(
              record.producer?.paymentConfig?.licenseRenewalPayee
            )
          ) : (
            <span style={{ color: '#92A69E', fontSize: '12px' }}>
              Not configured
            </span>
          )}
        </Row>
      );
    },
  },
  {
    title: 'Auto Apply',
    dataIndex: 'autoRenewEnabled',
    key: 'autoRenewEnabled',
    align: 'center',
    render: (text: any, record: AccountWithProducerDocument) => {
      return (
        <Row style={{ justifyContent: 'center' }}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#0588ca',
              },
            }}
          >
            <Switch
              checked={!!record.producer?.paymentConfig?.isAutoRenewalActive}
              disabled
            />
          </ConfigProvider>
        </Row>
      );
    },
  },
];

const getProducerDetailsTableColumns = ({
  showContactEmail = false,
  showResidentState = false,
  showPaymentConfig = false,
  allowPaymentConfigEdit = false,
  onPaymentConfigEdit,
  onAgentClick,
}: {
  showContactEmail?: boolean;
  showResidentState?: boolean;
  showPaymentConfig?: boolean;
  allowPaymentConfigEdit?: boolean;
  onPaymentConfigEdit?: (
    id: string,
    account: AccountWithProducerDocument
  ) => void;
  onAgentClick: (account: AccountWithProducerDocument) => void;
}): ColumnsType<AccountWithProducerDocument> => {
  let columns: ColumnsType<AccountWithProducerDocument> = [];

  const basicColumns: ColumnsType<AccountWithProducerDocument> = [
    {
      title: 'Producer',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name: Name, record: AccountWithProducerDocument): any => {
        let tooltipTitle = '';

        if (
          record.producer?.processingFlags?.isLicenseProcessing &&
          record.producer?.processingFlags?.isAssignmentProcessing
        ) {
          tooltipTitle =
            'License(s) and Assignment(s) are currently being processed';
        } else if (record.producer?.processingFlags?.isLicenseProcessing) {
          tooltipTitle = 'License(s) currently being processed';
        } else if (record.producer?.processingFlags?.isAssignmentProcessing) {
          tooltipTitle = 'Assignment(s) currently being processed';
        }
        return (
          <Space>
            {(record.producer?.processingFlags?.isLicenseProcessing ||
              record.producer?.processingFlags?.isAssignmentProcessing) && (
              <ConfigProvider
                theme={{ token: { colorTextLightSolid: '#000' } }}
              >
                <Tooltip
                  title={tooltipTitle}
                  color="#fff"
                  key="#fff"
                  style={{ color: '#f00' }}
                  overlayInnerStyle={{ color: '#000' }}
                >
                  <FileSyncOutlined />
                </Tooltip>
              </ConfigProvider>
            )}
            <Typography.Paragraph
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'none',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0px',
              }}
              className="agent-link"
            >
              <span
                className="cursor-pointer"
                onClick={() => onAgentClick(record)}
              >
                {getNameString(
                  Object.keys(name || {})?.length
                    ? record?.producer?.name
                    : name
                )}{' '}
              </span>
            </Typography.Paragraph>
          </Space>
        );
      },
    },
    {
      title: 'NPN',
      dataIndex: 'producer',
      key: 'producer.npn',
      sorter: true,
      width: '100px',
      render: (text: string, record: AccountWithProducerDocument): any => {
        return (
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#000000',
              marginBottom: '0px',
            }}
          >
            {record.producer.npn}
          </Typography.Paragraph>
        );
      },
    },
  ];

  columns.push(...basicColumns);
  if (showContactEmail) columns.push(...contactEmailColumn);

  if (showPaymentConfig && !allowPaymentConfigEdit)
    columns.push(...paymentConfigColumns);
  else if (showPaymentConfig && allowPaymentConfigEdit)
    columns.push(...paymentConfigColumns);

  if (showResidentState) columns.push(...residentStateColumn);

  return columns;
};

export default getProducerDetailsTableColumns;
