import styled from 'styled-components';
interface TextDetailProps {
  textColor?: string;
  fontSize?: string;
  fontWeight?: number;
}
export const ModalContainer = styled.div`
  display: inline-flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const TextDetail = styled.div<TextDetailProps>`
  color: ${(props) => props.textColor || '#222'};
  font-family: Poppins;
  font-size: ${(props) => props.fontSize || '10px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 20px; /* 200% */
`;
