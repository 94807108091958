export class TourConstants {
  public static readonly START_TOUR = 'onboardNewUser';
  public static readonly AGENCY_ONBOARD_TOUR = 'agentOnboardTour';
  public static readonly PRODUCER_ONBOARDING_STEP = 'producer-onboard-step';
  public static readonly SIGNUP_DEMO_BY_SECTION = 'exploreSection';
  public static readonly STRIPE_ONBOARD = 'stripe-onboard-pending';

  public static readonly SIDER_MENU = {
    TERRITORIES: 'sider-menu-territories',
    OVERVIEW: 'sider-menu-overview',
    PRODUCER_ONBOARDING: 'sider-menu-producer-onboarding',
    DOWNLINES: 'sider-menu-downlines',
    LICENSES: 'sider-menu-licenses',
    WATCH_LIST: 'sider-menu-watchlist',
    ACTIONABLE_LICENSES: 'sider-menu-actionable-licenses',
    USERS: 'sider-menu-users',
    RTS: {
      HOME: 'sider-menu-ready-to sell',
      BY_AGENCY: 'sider-menu-by-agency',
      BY_PRODUCER: 'sider-menu-by-producer',
    },
  };
}
