import { Button, Modal, Typography } from 'antd';

import { DetailsContainer } from '../../utils/modal/application-status/applicationstatus.style';
import { IdConstants } from '../../constants/id.constants';
import { NiprTransactionType } from '../../enums/niprTransactionType';
import { VerifyDownlineDetails } from './inviteDownline';

type InviteDownLineModalProps = {
  visible: boolean;
  handleVisibility: (value: boolean) => void;
  onClose: () => void;
  downLineDetails?: VerifyDownlineDetails;
  inviteLoading: boolean;
  handleSendInvitation: () => void;
  downLineType: NiprTransactionType | undefined;
};

const InviteDownLineModal: React.FC<InviteDownLineModalProps> = (props) => {
  const handlePrev = () => {
    props.handleVisibility(false);
  };

  return (
    <Modal
      open={props.visible}
      onCancel={() => {
        props.handleVisibility(false);
      }}
      closable={!props.inviteLoading}
      destroyOnClose
      centered
      maskClosable={false}
      width="600px"
      style={{ boxShadow: 'none' }}
      footer={
        <div>
          <Button
            id={IdConstants.AGENTS.INVITE_DOWNLINE_MODAL.CONFIRM_ALLOCATION}
            htmlType="submit"
            size="large"
            className="button"
            style={{
              width: '100%',
              marginBottom: '10px',
            }}
            loading={props.inviteLoading}
            onClick={props.handleSendInvitation}
          >
            Confirm
          </Button>
          <Button
            id={IdConstants.AGENTS.INVITE_DOWNLINE_MODAL.GO_BACK}
            size="large"
            style={{
              width: '100%',
              marginLeft: '-5px',
              color: 'var(--secondary-color)',
              border: '1px solid var(--border-color)',
              boxShadow: 'none',
            }}
            disabled={props.inviteLoading}
            onClick={handlePrev}
          >
            Go Back
          </Button>
        </div>
      }
    >
      <Typography.Paragraph
        style={{
          fontSize: '16px',
          fontWeight: 500,
          color: 'var(--primary-color)',
        }}
      >
        Confirm Invitation
      </Typography.Paragraph>

      <DetailsContainer>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ marginRight: '15%' }}>
            <Typography.Paragraph style={{ fontSize: '10px' }}>
              {props.downLineType === 'FIRM' ? 'Agency Name' : 'Producer Name'}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{ fontSize: '12px', marginTop: '-2px' }}
            >
              {props.downLineDetails && props.downLineDetails.name}
            </Typography.Paragraph>
          </div>
          <div>
            <Typography.Paragraph style={{ fontSize: '10px' }}>
              NPN Number
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{ fontSize: '12px', marginTop: '-2px' }}
            >
              {props.downLineDetails && props.downLineDetails.npn}
            </Typography.Paragraph>
          </div>
        </div>
      </DetailsContainer>
    </Modal>
  );
};

export default InviteDownLineModal;
