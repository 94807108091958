import { Avatar, Card, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { DragItemTypes } from '../../../../types/dnd/dragItem.types';
import { HoverDetails } from './droppableStateCard';
import { IdConstants } from '../../../../constants/id.constants';
import { LicenseConfig } from '../../../../types/data/stateGroup.types';
import { LoaDragItem } from '../editStateGroup';
import { ResidencyType } from '../../../../enums/residencyType.enum';
import Title from 'antd/es/typography/Title';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { getResidentToolTip } from '../../../../utils/table-manager/residentType.utils';
import { useDrop } from 'react-dnd';

interface DroppableLcCardProps {
  licenseInfo: LicenseConfig;
  currentLoaDragItem: LoaDragItem;
  stateCode: string;
  updateLicenseConfig: (
    stateCode: string,
    licenseConfig: LicenseConfig
  ) => void;
  currentHoverDetails: HoverDetails;
  updateLcCodeForHoverDetails: (prop: string) => void;
  updateLoaCodeForHoverDetails: (loaCode: string, lcCode?: string) => void;
  removeLcFromStateGroup: (stateCode: string, lcCode: string) => void;
  removeLoaFromStateGroup: (
    stateCode: string,
    lcCode: string,
    loaCode: string,
    loaId: string
  ) => void;
}

function DroppableLcCard({
  licenseInfo,
  currentLoaDragItem,
  stateCode,
  updateLicenseConfig,
  currentHoverDetails,
  updateLcCodeForHoverDetails,
  updateLoaCodeForHoverDetails,
  removeLcFromStateGroup,
  removeLoaFromStateGroup,
}: DroppableLcCardProps) {
  const [mouseHover, setMouseHover] = useState(false);

  useEffect(() => {
    setMouseHover(
      currentHoverDetails.isHovering &&
        currentHoverDetails.lcCode === licenseInfo.lcCode &&
        !currentHoverDetails.loaCode
    );
  }, [currentHoverDetails]);

  const [{ isOverCurrent }, dropRef] = useDrop(
    () => ({
      accept: DragItemTypes.LOA,
      drop(_item: unknown, monitor) {
        if (
          currentLoaDragItem.stateCode === stateCode &&
          currentLoaDragItem.lcCode === licenseInfo.lcCode &&
          !licenseInfo.loaCodes.includes(currentLoaDragItem.loaCode)
        ) {
          updateLicenseConfig(stateCode, {
            lcCode: licenseInfo.lcCode,
            loaCodes: [...licenseInfo.loaCodes, currentLoaDragItem.loaCode],
            loaids: [...(licenseInfo.loaids || []), currentLoaDragItem.loaids],
          });
        }
      },
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [currentLoaDragItem]
  );

  return (
    <div
      id={
        IdConstants.STATE_GROUPS.DROPPABLE_LC_CARD +
        '-' +
        licenseInfo.lcCode +
        '-state-groups'
      }
      key={licenseInfo.lcCode}
      ref={dropRef}
      className="lc-with-close-button"
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        padding: '1px 2px',
        marginTop: '10px',
        ...(isOverCurrent &&
          currentLoaDragItem.stateCode === stateCode &&
          currentLoaDragItem.lcCode === licenseInfo.lcCode &&
          !licenseInfo.loaCodes.includes(currentLoaDragItem.loaCode) && {
            backgroundColor: 'azure',
          }),
        ...(isOverCurrent &&
          (currentLoaDragItem.stateCode !== stateCode ||
            currentLoaDragItem.lcCode !== licenseInfo.lcCode ||
            licenseInfo.loaCodes.includes(currentLoaDragItem.loaCode)) && {
            backgroundColor: '#ffede8',
          }),
      }}
      onMouseEnter={() => {
        !currentHoverDetails.loaCode &&
          updateLcCodeForHoverDetails(licenseInfo.lcCode);
      }}
      onMouseLeave={() => {
        updateLcCodeForHoverDetails('');
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title level={5} style={{ marginTop: '0', fontSize: '14px' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            {appStateInfoStore.getNameByCodeFromLicenseDetails(
              null,
              stateCode,
              licenseInfo.lcCode
            )}{' '}
            -{' '}
            {(() => {
              const residencyType = appStateInfoStore.getResidencyType(
                licenseInfo.lcCode,
                stateCode
              );
              if (residencyType !== ResidencyType.NotApplicable) {
                return (
                  <Tooltip title={residencyType}>
                    <Avatar
                      style={{
                        backgroundColor: '#D3F3F5',
                        color: '#000000',
                        width: '26px',
                        height: '26px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        justifyItems: 'center',
                        marginRight: '4px',
                        cursor: 'default',
                      }}
                    >
                      {residencyType || <span>-</span>}
                    </Avatar>
                  </Tooltip>
                );
              } else if (residencyType) {
                return (
                  <>
                    <Tooltip title={getResidentToolTip(ResidencyType.Resident)}>
                      <Avatar
                        style={{
                          backgroundColor: '#D3F3F5',
                          color: '#000000',
                          width: '26px',
                          height: '26px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          justifyItems: 'center',
                          marginRight: '0',
                          cursor: 'default',
                        }}
                      >
                        R
                      </Avatar>
                    </Tooltip>
                    <Tooltip
                      title={getResidentToolTip(ResidencyType.NonResident)}
                    >
                      <Avatar
                        style={{
                          backgroundColor: '#D3F3F5',
                          color: '#000000',
                          width: '26px',
                          height: '26px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          justifyItems: 'center',
                          marginLeft: '0',
                          cursor: 'default',
                        }}
                      >
                        NR
                      </Avatar>
                    </Tooltip>
                  </>
                );
              } else {
                return null;
              }
            })()}
          </div>
        </Title>

        <CloseOutlined
          style={{
            visibility: mouseHover ? 'visible' : 'hidden',
            cursor: 'pointer',
          }}
          onClick={() => {
            removeLcFromStateGroup(stateCode, licenseInfo.lcCode);
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          rowGap: '4px',
        }}
      >
        {licenseInfo.loaCodes.map((loaItr, index) => {
          return (
            <Card
              id={
                IdConstants.STATE_GROUPS.DROPPABLE_LOA_CARD +
                '-' +
                loaItr +
                '-state-groups'
              }
              bodyStyle={{
                padding: '1px 6px',
                display: 'flex',
                columnGap: '10px',
                alignItems: 'center',
              }}
              key={loaItr}
              onMouseEnter={() => {
                updateLoaCodeForHoverDetails(loaItr, licenseInfo.lcCode);
              }}
              onMouseLeave={() => {
                updateLoaCodeForHoverDetails('', licenseInfo.lcCode);
              }}
            >
              {appStateInfoStore.getNameByCodeFromLicenseDetails(
                loaItr,
                stateCode,
                licenseInfo.lcCode
              )}
              <CloseOutlined
                style={{
                  visibility:
                    currentHoverDetails.isHovering &&
                    currentHoverDetails.lcCode === licenseInfo.lcCode &&
                    currentHoverDetails.loaCode === loaItr
                      ? 'visible'
                      : 'hidden',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  removeLoaFromStateGroup(
                    stateCode,
                    licenseInfo.lcCode,
                    loaItr,
                    licenseInfo.loaids?.[index] || ''
                  );
                }}
              />
            </Card>
          );
        })}
      </div>
    </div>
  );
}

export default DroppableLcCard;
