import styled from 'styled-components';
interface TextDetailProps {
  textColor?: string;
  fontSize?: string;
  fontWeight?: number;
}
export const TextDetail = styled.div<TextDetailProps>`
  color: ${(props) => props.textColor || '#222'};
  font-family: Poppins;
  font-size: ${(props) => props.fontSize || '10px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 20px; /* 200% */
`;

export const EmpDetailsCard = styled.div`
  display: flex;
  width: 1344px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;

export const DisplayRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const DetailsCard = styled.div`
  display: flex;
  width: 180px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const AlertCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 20px;
  justify-content: center;
  // align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #00a6fb;
  background: #fff;
`;
