const DuplicateValueErrorMessage = (field: string, value: string) =>
  `Duplicate value: ${value} detected for field: ${field}. Please change the value and try again`;
const CustomErrorMessage = (value: string) => `${value}. Please try again`;
const SameLookupMyAgency = (value: string, field: string) =>
  `Producer with ${field} ${value} already exists in your agency with the same ${field}`;
const SameLookupOtherAgency = (value: string, field: string) =>
  `Producer with ${field} ${value} already exists in another agency with the same ${field}`;
const AssignmentInProgressForSingleAgent = (name: string) =>
  `The last assignment for ${name} is still processing. Please try again after a while.`;
const AssignmentInProgressForManyAgents = (names: string[]) => {
  return `The last assignment for the following agents are still processing. Please try again after a while. Agents: ${names.join(
    ', '
  )}`;
};

export class ErrorMessageConstants {
  public static readonly INVALID_NUMBER = 'Please enter a valid number';
  public static readonly DUPLICATE_EMAIL =
    'The given email already exists with InsureTrek. Please try with a different email.';
  public static readonly DUPLICATE_AGENCY_NAME =
    'The given organization name already exists with InsureTrek. Please try with a different name.';
  public static readonly VERIFY_YOUR_AGENCY_DETAILS =
    'An unknown error occurred while creating your agency. Please try again or reach out to our support team';
  public static readonly EMPTY_FIELDS =
    'Please fill in all the required fields';
  public static readonly ORGANIZATION_NAME_ALREADY_EXISTS =
    'Organization name is already in use';
  public static readonly CREATE_ADMIN_PROFILE =
    'An unknown error occurred while creating your admin profile. Please try again or reach out to our support team';
  public static readonly INVALID_FEIN = 'Please enter a valid FEIN';
  public static readonly FEIN_NOT_FOUND =
    'Provided FEIN does not exist or could not be found';
  public static readonly NO_CONTACT_INFORMATION =
    'Contact information not available for the specified state';
  public static readonly NO_ADDRESS_INFORMATION =
    'Address information not available for the specified state';
  public static readonly FEIN_ALREADY_EXISTS =
    'FEIN is already associated with an existing agency';
  public static readonly FEIN_ALREADY_ONBOARDED =
    'The provided FEIN has already been used for onboarding';
  public static readonly SETUP_AGENCY =
    'An unknown error occurred while setting up your agency. Please try again or reach out to our support team';
  public static readonly FIVE_YEARS_ENTRY_REQUIRED =
    'Please provide employment history for a continuous period of 5 years';
  public static readonly WORKING_PERIOD_EXISTS =
    'Duplicate entry detected in employment history';
  public static readonly END_DATE_LESSER_THAN_START_DATE =
    'Please ensure the end date is after the start date';
  public static readonly EMP_HISTORY =
    'An unknown error occurred while accepting employment history. Please try again or reach out to our support team';
  public static readonly FUTURE_DATE =
    'Please ensure that the date is lesser than the current date';
  public static readonly BG_QUESTIONS_EMPTY_ANSWER =
    'Please provide answers to all background questions';
  public static readonly UPLOAD_FILE_WRONG =
    'Ensure the file size and type meet the specified requirements. Please upload .doc, .docx, .gif, .jpg, .jpeg, .jpe, or .pdf files and file must be 10MB or smaller.';
  public static readonly BG_QUESTIONS =
    'An unknown error occurred while accepting response for background questions. Please try again or reach out to our support team';
  public static readonly DUPLICATE_FILE =
    'Duplicate file detected. Please choose a different file and try again';
  public static readonly INVALID_PHONE_NUMBER =
    'Please enter a valid phone number';
  public static readonly REJECT_INVITE_ERROR =
    'Something went wrong in the process of rejecting your invite. Please try again or reach out to our support team';
  public static readonly ACCEPT_INVITE_ERROR =
    'Something went wrong in the process of accepting your invite. Please try again or reach out to our support team';
  public static readonly NPN_NOT_FOUND = 'NPN not found';

  public static readonly SAME_STATE_GROUP_NAME =
    'Territory with the same name already exists. Please use a different name and try again';
  public static readonly SAME_LOOKUP_MY_AGENCY = SameLookupMyAgency;
  public static readonly SAME_LOOKUP_OTHER_AGENCY = SameLookupOtherAgency;
  public static readonly INVALID_NPN = 'NPN is not valid';
  public static readonly INVALID_SSNS =
    'One or more of the given SSNs are incorrect';
  public static readonly NIPR_LOOKUP_FAILURE =
    'Unable to retrieve information from NIPR';
  public static readonly NO_LICENSE_INFO =
    'No license information found for the producer';
  public static readonly YOUR_DOWNLINE_AGENT =
    'Producer already affiliated as your downline producer';
  public static readonly OTHER_DOWNLINE_AGENT =
    'Producer already affiliated as another agency’s downline producer.';
  public static readonly YOUR_DOWNLINE_AGENCY =
    'Agency already affiliated as your downline agency';
  public static readonly OTHER_DOWNLINE_AGENCY =
    'Agency already affiliated as another agency’s downline agency.';
  public static readonly YOUR_AGENT =
    'Producer already affiliated in your agency';
  public static readonly OTHER_AGENT =
    'Producer already affiliated in another agency';
  public static readonly YOUR_AGENCY =
    'Agency already affiliated in your agency';
  public static readonly OTHER_AGENCY =
    'Agency already affiliated in another agency.';
  public static readonly DUPLICATE_LICENSE = 'Duplicate license detected.';
  public static readonly DUPLICATE_STATEGROUP_NAME =
    'Given name is already in use';
  public static readonly LICENSE_STATE_MISMATCH =
    'License number does not match with State';
  public static readonly SSN_NAME_MISTMATCH =
    'No producer found with the provided SSN and Last Name';
  public static readonly NPN_MISMATCH =
    'No producer found with the provided NPN';
  public static readonly COMMON =
    'Something went wrong. Please try again or reach out to our support team';

  public static readonly CHOOSE_ONE_OPTION = 'Choose at least one option';
  public static readonly STRIPE_CUSTOMER_ID_MISSING =
    'Stripe customer id is missing. Please contact support.';
  public static readonly NO_PAYMENT_METHODS_FOUND =
    'No payment methods found for customer. Please contact support.';

  public static readonly CUSTOM_ERROR = CustomErrorMessage;
  public static readonly DUPLICATE_VALUE_ERROR = DuplicateValueErrorMessage;
  public static readonly PRODUCER_ALREADY_ONBOARDED_ERROR = `This producer has already been onboarded in another agency.`;
  public static readonly ASSIGNMENT_IN_PROGRESS =
    AssignmentInProgressForSingleAgent;
  public static readonly ASSIGNMENT_IN_PROGRESS_BULK =
    AssignmentInProgressForManyAgents;
}
