import styled from 'styled-components';

interface TextDetailProps {
  textColor?: string;
  fontSize?: string;
  fontWeight?: number;
}

export const ModalContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 12px;
  background: #fff;
`;

export const ApplicationDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

export const DetailsHeading = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

export const DetailsCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
`;

export const LicenseDetails = styled.div`
  display: flex;
  // width: 100px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

export const TextDetail = styled.div<TextDetailProps>`
  color: ${(props) => props.textColor || '#222'};
  font-family: Poppins;
  font-size: ${(props) => props.fontSize || '12px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 20px; /* 200% */
`;

export const Insurancetypes = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 4px;
  flex-wrap: wrap;
`;
export const ContactDetails = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: ${(props) => props.width || '100px'};
`;
export const InsuranceLayout = styled.div`
  display: flex;
  padding: 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 26px;
  border: 1px solid #dff4f5;
  background: #f7fcfc;
`;
export const TransactionDetails = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: ${(props) => props.width || '100px'};
`;

export const ButtonAlignment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const ErrorList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: disc;
`;

export const ErrorListItem = styled.li`
  color: #222;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 10px;
`;
