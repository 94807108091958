import { Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { TooltipWrapper } from '../../pages/agents/agentsOverview/agentOverview/paymentConfig';
import { TourConstants } from '../../constants/tour.constant';
import { observer } from 'mobx-react-lite';
import { stripeStore } from '../../stores/payment.store';
import { useAuth } from '../../auth/authProvider';
import { useState } from 'react';

export interface StripeOnboardingButtonProps {
  collapsed: boolean;
  text?: string;
  style?: React.CSSProperties;
  showTooltip?: boolean;
}

function StripeOnboardingButton(props: StripeOnboardingButtonProps) {
  const { getAccessTokenSilently } = useAuth();
  const [isStripeLoading, setIsStripeLoading] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const rerouteToStripeOnboarding = async () => {
    try {
      setIsStripeLoading(true);
      const token = await getAccessTokenSilently();
      const url = await stripeStore.getActiveOnboardingLink(token);
      url && window.open(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsStripeLoading(false);
    }
  };
  return stripeStore.getStripeDetails()?.isOnboarded === false ? (
    <TooltipWrapper
      children={
        <Button
          danger
          style={{
            borderWidth: '0',
            color: '#660000',
            backgroundColor: isHovered ? '#fb000020' : '#fb000010',
            padding: '2px 8px',
            transition: '!important width 0.3s ease backgroundColor 0.3s ease',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ...(props.style || {}),
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          loading={isStripeLoading}
          id={TourConstants.STRIPE_ONBOARD}
          onClick={rerouteToStripeOnboarding}
          icon={<ExclamationCircleOutlined />}
        >
          {!props.collapsed && (props.text || 'Pending: Stripe Onboard')}
        </Button>
      }
      isDisabled={!props.showTooltip}
      key={'stripe-onboarding-sider'}
      message="Complete your Stripe onboarding to manage funds and streamline payments within InsureTrek."
    />
  ) : (
    <></>
  );
}

export default observer(StripeOnboardingButton);
