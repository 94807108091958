import AdminSignUpLayout from './signUp/layouts/signUpLayout';
import AgentSignupSteps from '../pages/agentSidePortal/agentSignupSteps';
import DownlineInvite from '../pages/signup/invites/downlineInvite';
import MyLayout from './layout';
import Unauthorized from './unauthorized';
import { agentSideStore } from '../stores/agentPortalStore';
import { auth0Store } from '../stores/auth0Store';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../stores/signupStore';

function HandleAgentSignUpFlow() {
  return auth0Store.user?.user_metadata.isOnboarded &&
    agentSideStore.getAgencyName() ? (
    <MyLayout />
  ) : agentSideStore.agencyName ? (
    <AgentSignupSteps />
  ) : signupStore.isInvited ? (
    <AdminSignUpLayout>
      <DownlineInvite />
    </AdminSignUpLayout>
  ) : (
    <Unauthorized />
  );
}

export default observer(HandleAgentSignUpFlow);
