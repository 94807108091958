export const costFormatter = (
  val: string | undefined | number,
  limitDecimals = true
): string => {
  return val
    ? `${limitDecimals ? (+val).toFixed(2) : val}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      )
    : '0';
};
