export enum QuestionId {
  MISDEMEANOR = '1',
  FELONY = '2',
  FELONY_A = '2A',
  FELONY_B = '2B',
  MILITARY_OFFENCE = '3',
  ADMINISTRATIVE_PROCEEDING = '4',
  DEMAND_OR_JUDGEMENT = '5',
  TAX_OBLIGATION = '6',
  LEGAL_LIABILITY = '7',
  ALLEGED_MISCONDUCT = '8',
  CHILD_SUPPORT = '9',
  CHILD_SUPPORT_A = '9A',
  CHILD_SUPPORT_B = '9B',
  CHILD_SUPPORT_C = '9C',
  NAIC_NIPR_ATTACHMENTS = '10',
  NAIC_NIPR_ATTACHMENTS_A = '10A',
  NON_UNIFORM_AL_1 = '11',
  NON_UNIFORM_AL_1A = '11A',
  NON_UNIFORM_AL_20 = '12',
  NON_UNIFORM_AR_3 = '13',
  NON_UNIFORM_CA_1 = '14',
  NON_UNIFORM_CA_3 = '15',
  NON_UNIFORM_CA_3A = '15A',
  NON_UNIFORM_CA_3_R = '16',
  NON_UNIFORM_CA_3A_R = '16A',
  NON_UNIFORM_CA_7 = '17',
  NON_UNIFORM_FL_105 = '18',
  NON_UNIFORM_KS_1 = '19',
  NON_UNIFORM_GA_1 = '20',
  NON_UNIFORM_GA_3 = '21',
  NON_UNIFORM_GA_4 = '22',
  NON_UNIFORM_GA_7 = '23',
  NON_UNIFORM_HI_A1 = '24',
  NON_UNIFORM_MA_2 = '25',
  NON_UNIFORM_MA_4 = '26',
  NON_UNIFORM_MN_1 = '27',
  NON_UNIFORM_MO_1 = '28',
  NON_UNIFORM_OH_C1 = '29',
  NON_UNIFORM_TN_1_R = '30',
  NON_UNIFORM_TN_1 = '31',
  NON_UNIFORM_TN_2 = '32',
  NON_UNIFORM_TN_3 = '33',
  NON_UNIFORM_NJ_1 = '34',
  NON_UNIFORM_OR_3 = '35',
  NON_UNIFORM_WV_1 = '36',
  NON_UNIFORM_VI_7 = '37',
  NON_UNIFORM_UT_P1 = '38',
  NON_UNIFORM_TX_5 = '39',
  NON_UNIFORM_TX_4 = '40',
  NON_UNIFORM_TX_3 = '41',
  NON_UNIFORM_TX_2 = '42',
  NON_UNIFORM_PA_1 = '43',
  NON_UNIFORM_PA_2 = '44',
  NON_UNIFORM_MS_8 = '45',
  NON_UNIFORM_WA_1 = '46',
}

export enum AgencyQuestionId {
  MISDEMEANOR = '1',
  FELONY = '2',
  FELONY_A = '2A',
  FELONY_B = '2B',
  MILITARY_OFFENCE = '3',
  ADMINISTRATIVE_PROCEEDING = '4',
  DEMAND_OR_JUDGEMENT = '5',
  TAX_OBLIGATION = '6',
  LEGAL_LIABILITY = '7',
  ALLEGED_MISCONDUCT = '8',
  NAIC_NIPR_ATTACHMENTS = '9',
  NAIC_NIPR_ATTACHMENTS_A = '9A',
}
