import React, { useEffect } from 'react';

import { DragItemTypes } from '../../../../types/dnd/dragItem.types';
import IconDraggable from '../../../../assets/icons/iconDraggable.svg';
import { IdConstants } from '../../../../constants/id.constants';
import { getStateNameFromCode } from '../../../../utils/common.utils';
import { useDrag } from 'react-dnd';

interface DraggableStateLabelProps {
  stateCode: string;
  setCurrentStateDragItemCode: (stateCode: string) => void;
}

function DraggableStateLabel({
  stateCode,
  setCurrentStateDragItemCode,
}: DraggableStateLabelProps) {
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: DragItemTypes.STATE,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    []
  );

  useEffect(() => {
    isDragging && setCurrentStateDragItemCode(stateCode);
  }, [isDragging]);

  return (
    <div
      id={
        IdConstants.STATE_GROUPS.DRAGGABLE_STATE_LABEL +
        '-' +
        getStateNameFromCode(stateCode).toLocaleLowerCase() +
        '-state-groups'
      }
      ref={dragRef}
      style={{
        display: 'flex',
        columnGap: '20px',
        justifyContent: 'space-between',
      }}
    >
      <span>{getStateNameFromCode(stateCode)}</span>
      <img src={IconDraggable} />
    </div>
  );
}

export default DraggableStateLabel;
