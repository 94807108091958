export enum LoaStatus {
  ACTIVE = 'Active',
  MISSING = 'missing',
  PENDING = 'Pending',
  INACTIVE = 'Inactive',
  CANCELLED = 'Cancelled',
}

export const statusLabelMap: Record<LoaStatus, string> = {
  [LoaStatus.ACTIVE]: 'Active',
  [LoaStatus.MISSING]: 'Missing',
  [LoaStatus.PENDING]: 'Pending',
  [LoaStatus.INACTIVE]: 'Missing',
  [LoaStatus.CANCELLED]: 'Cancelled',
};
