import {
  Button,
  Card,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../services/agency.service';
import { IdConstants } from '../../constants/id.constants';
import ManageTerritoryTour from '../../components/common/Tour/manageTerritoryTour';
import { RoleType } from '../../enums/roles.enum';
import { RouteConstants } from '../../constants/routes.constants';
import StateGroupTable from '../../components/common/tables/stateGroupTable';
import { UpdatedStateGroup } from '../../types/data/stateGroup.types';
import { adminStore } from '../../stores/admin.store';
import { getStateNameFromCode } from '../../utils/common.utils';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../stores/tour.store';
import { useAuth } from '../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useQueryState } from '../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../auth/useRoles';

const { Search } = Input;

function StateGroups() {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [stateGroups, setStateGroups] = useState<UpdatedStateGroup[]>([]);
  const [stateGroupsTotalCount, setStateGroupsTotalCount] = useState<number>(0);
  const { getAccessTokenSilently } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [sortValue, setSortValue] = useState({});

  const onPaginationChange: PaginationProps['onChange'] = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchStateGroups(queryText || '', newPageNumber, newPageSize);
  };

  async function fetchStateGroups(
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) {
    if (adminStore.agency?.id) {
      setIsLoading(true);
      if (tourStore.manageTerritoryCurrentStep === 5) {
        tourStore.setManageTerritoryTour(false);
      }
      const token = await getAccessTokenSilently();
      await AgencyService.getStateGroupsForAgency(
        token,
        page || pageNumber,
        size || pageSize,
        searchTextProp ? searchTextProp : queryText || '',
        { ...(sort || sortValue || {}) }
      )
        .then((resp) => {
          if (resp && resp.data) {
            const data = resp.data;
            data.forEach((item) => {
              item.stateLicenseConfigs.sort((a, b) => {
                const stateNameA = getStateNameFromCode(a.stateCode);
                const stateNameB = getStateNameFromCode(b.stateCode);
                return stateNameA.localeCompare(stateNameB);
              });
            });
            setStateGroups(resp.data);
            'totalCount' in resp && setStateGroupsTotalCount(resp.totalCount);
          }
        })
        .catch((error) => {
          console.error('StateGroups :: fetchStateGroups :: error :: ', error);
        })
        .finally(() => {
          if (tourStore.manageTerritoryCurrentStep === 5) {
            tourStore.setManageTerritoryTour(true);
          }
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    fetchStateGroups();
  }, [adminStore.account, queryText]);

  useEffect(() => {
    queryText ? setSearchString(queryText) : setSearchString('');
  }, [queryText]);

  return (
    <Card
      style={{
        width: '100%',
        height: 'fit-content',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <div style={{ display: 'flex', gap: 12 }}>
          {/* <Search
            id={IdConstants.SEARCH_INPUT + '-state-group'}
            placeholder="Search by territory name"
            size="middle"
            style={{
              width: '100%',
            }}
            value={searchString}
            // allowClear
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            onSearch={(value) => {
              if (queryText !== value) {
                setPageNumber(1);
                setQueryText(value);
              }
            }}
            onPressEnter={(e) => {
              if (queryText !== (e.target as HTMLInputElement).value) {
                setQueryText((e.target as HTMLInputElement).value);
                setPageNumber(1);
              }
            }}
            disabled={isLoading}
          /> */}
          <Tooltip title="Create Territory">
            <Button
              id={
                IdConstants.STATE_GROUPS.CREATE_STATE_GROUPS_BUTTON +
                '-state-groups'
              }
              className="button-secondary-color-important"
              onClick={() =>
                navigate(`${RouteConstants.createStateGroup.path}`)
              }
              size="middle"
            >
              Create New
            </Button>
          </Tooltip>
        </div>

        <StateGroupTable
          loader={isLoading}
          stateGroups={stateGroups}
          refresh={fetchStateGroups}
          setSortValue={setSortValue}
        />
        {!isEmpty(stateGroups) && (
          <Row
            className="pagination"
            style={{
              marginBottom: '10px',
            }}
            justify="end"
          >
            <Pagination
              current={pageNumber}
              defaultCurrent={pageNumber}
              total={stateGroupsTotalCount}
              pageSize={pageSize}
              onChange={onPaginationChange}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={isLoading}
            />
          </Row>
        )}
      </div>
      {tourStore.manageTerritoryTour && <ManageTerritoryTour />}
    </Card>
  );
}

export default withRoles(observer(StateGroups), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
