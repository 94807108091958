import { Auth0Service } from '../services/auth0.service';
import { Name } from '../types/data/name.type';
import { Profile } from '../types/common/profile.type';
import { RoleType } from '../enums/roles.enum';
import { makeAutoObservable } from 'mobx';

export interface InsureTrekUser {
  account: InsureTrekAccount;
  auth0User: Auth0UserData;
  producer?: InsureTrekProducer;
  agency: InsureTrekAgency;
  role: RoleType;
}

export interface InsureTrekAccount {
  id: string;
  name: Name;
  email: string;
}

export interface InsureTrekAgency {
  id: string;
  name: string;
  businessEmail?: string;
  npn: string;
  residentStateCode: string;
  type: string;
  agencySignupInfoDto?: any;
}
export interface InsureTrekProducer {
  id: string;
  name: Name;
  accountId: string;
  npn: string;
  dateOfBirth: string;
  gender: string;
  residentState: string;
  engineLights: {
    paymentConfig: boolean;
    employmentHistory: boolean;
    backgroundQuestion: boolean;
  };
  paymentConfig: InsureTrekPaymentConfig;
  processingFlags: {
    isAssignmentProcessing?: boolean;
    isLicenseProcessing?: boolean;
  };
}

export interface InsureTrekPaymentConfig {
  licenseInitialPayee: string;
  licenseRenewalPayee: string;
  paymentType: string;
  isAutoRenewalActive: boolean;
  cappedAmount?: number;
}

export interface Auth0UserData {
  email: string;
  email_verified?: boolean;
  name: string;
  picture?: string;
  user_id: string;
  user_metadata: UserMetadata;
}

interface UserMetadata {
  hasLoggedInBefore: boolean;
  isOnboarded: boolean;
  isOnboardingEnabled: boolean;
  onboardingStage: string;
}

class Auth0Store {
  user: Auth0UserData | null = null;
  profiles: Profile[] = [];
  currentProfile: Profile | undefined;
  areProfilesLoaded: boolean = false;

  getCurrentProfile = (): Profile | undefined => this.currentProfile;
  setCurrentProfile(profile: Profile) {
    this.currentProfile = profile;
    localStorage.setItem('x-agency-id', profile.agencyId);
    localStorage.setItem('x-role', profile.role);
  }

  getProfiles = (): Profile[] => this.profiles;
  setProfiles(profiles: Profile[]): void {
    this.profiles = profiles;
  }
  async loadProfiles(token: string, inUserInvite: boolean) {
    const profilesResponse = await Auth0Service.getCurrentUserProfiles(
      token,
      inUserInvite
    );
    this.setProfiles(profilesResponse.data);

    const existingAgencyId = localStorage.getItem('x-agency-id');
    const existingProfileName = localStorage.getItem('x-role');
    const selectedProfile = profilesResponse.data.find(
      (itr) =>
        itr.agencyId === existingAgencyId && itr.role === existingProfileName
    );

    // load existing profile or fetch first admin profile
    if (selectedProfile) this.setCurrentProfile(selectedProfile);
    else {
      const adminProfiles = profilesResponse.data?.filter(
        (itr) => itr.role === 'admin'
      );

      const newProfile =
        (adminProfiles?.length > 0 && adminProfiles[0]) ||
        profilesResponse.data[0];
      !this.currentProfile && newProfile && this.setCurrentProfile(newProfile);
    }

    this.setProfilesLoaded(true);
  }

  public setProfilesLoaded(areProfilesLoaded: boolean) {
    this.areProfilesLoaded = areProfilesLoaded;
  }

  public getProfilesLoaded(): boolean {
    return this.areProfilesLoaded;
  }

  findProfileById(id: string, agencyId: string) {
    return this.profiles?.find(
      (itr) => itr.id === id && itr.agencyId === agencyId
    );
  }

  async loadUser(
    token: string,
    inUserInvite: boolean,
    isLogin?: boolean
  ): Promise<InsureTrekUser> {
    const userData: InsureTrekUser = await Auth0Service.getCurrentUser(
      token,
      inUserInvite,
      isLogin
    );
    this.setUser(userData.auth0User);
    return userData;
  }

  getUser = (): Auth0UserData | null => this.user;
  setUser(user: Auth0UserData): void {
    this.user = user;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const auth0Store = new Auth0Store();
