import { IdConstants } from '../../../constants/id.constants';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

export type ButtonProp = {
  value: string;
  label: string;
};

type Props = {
  buttons: Array<ButtonProp>;
  size?: 'small' | 'middle' | 'large';
  buttonStyle?: 'outline' | 'solid';
  value?: string;
  onChange: (e: any) => void;
  style?: React.CSSProperties;
  variant?: 'boxed';
  allowUnselect?: boolean;
  page?: string;
  id?: string;
};

const RadioButton = ({
  buttons,
  size = 'middle',
  buttonStyle = 'outline',
  value,
  onChange,
  style,
  variant,
  page,
  id,
}: Props) => {
  if (variant) {
    return (
      <div>
        <p>Variant</p>
      </div>
    );
  } else {
    return (
      <Radio.Group
        className="radio-group"
        {...(id ? { id } : {})}
        size={size}
        buttonStyle={buttonStyle}
        value={value}
        onChange={onChange}
      >
        {buttons.map((button) => (
          <Radio.Button
            id={
              page
                ? `${IdConstants.RADIO_BUTTON}-${button.value
                    .toLowerCase()
                    .replace(' ', '-')}-${page}`
                : `${IdConstants.RADIO_BUTTON}-${button.value}`
            }
            key={button.value}
            value={button.value}
            style={style}
          >
            {button.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    );
  }
};

RadioButton.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  buttonStyle: PropTypes.oneOf(['outline', 'solid']),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  size: 'middle',
  buttonStyle: 'outline',
};

export default RadioButton;
