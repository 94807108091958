import { Button, Skeleton, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';

import { AgentDetails } from '../../../../types/data/agentDetail.type';
import { AgentService } from '../../../../services/agent.service';
import { Name } from '../../../../types/data/name.type';
import { useAuth } from '../../../../auth/authProvider';

const tableColumns: ColumnsType<AgentDetails> = [
  {
    title: 'Producer',
    dataIndex: 'name',
    render: (text: Name) => text.firstName + ' ' + text.lastName,
  },
  {
    title: 'NPN',
    dataIndex: 'npn',
  },
];

type AddNewAgentsProps = {
  oldStateGroupId: string;
  newStateGroupExistingAgents: string[];
  setNewAgents: (agents: AgentDetails[]) => void;
  setStep: () => void;
};

function AddNewAgents(props: AddNewAgentsProps) {
  const { getAccessTokenSilently } = useAuth();
  const [agents, setAgents] = useState<AgentDetails[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<AgentDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAgentNotForStateGroup = async (stateGroupId: string) => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const response = await AgentService.getAgentsforStateGroup(
      stateGroupId,
      token,
      false,
      props.newStateGroupExistingAgents
    ).catch((error) => {
      console.error('error :: ', error);
    });
    if (response?.data) {
      setAgents(response.data);
    }
    setIsLoading(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AgentDetails[]) => {
      setSelectedAgents(selectedRows as AgentDetails[]);
    },
  };

  useEffect(() => {
    fetchAgentNotForStateGroup(props.oldStateGroupId);
  }, [props.oldStateGroupId]);

  return (
    <div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div style={{ width: '100%', marginTop: '20px' }}>
          <Typography.Paragraph
            style={{
              color: 'var(--secondary-color)',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            Add new agents to the New Territory
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{
              color: 'var(--grey-text)',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            These agents will be added to the new territory
          </Typography.Paragraph>
          <Table
            rowKey={(record) => record.npn}
            rowSelection={rowSelection}
            dataSource={agents}
            columns={tableColumns}
            style={{ marginTop: '20px' }}
            pagination={false}
          />
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {/* <Button
                size="large"
                onClick={() => {
                  // setDiscardModalVisibility(true);
                }}
              >
                Back
              </Button> */}
            </div>
            <div
              style={{
                display: 'flex',
                columnGap: '25px',
              }}
            >
              <Button
                size="large"
                className="button secondary-button"
                onClick={() => {
                  props.setStep();
                }}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="button primary-button"
                onClick={() => {
                  selectedAgents && props.setNewAgents(selectedAgents);
                  props.setStep();
                }}
              >
                Save New Producers
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddNewAgents;
