import { Card, Row, Space, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import RenderColumn from './renderColumn';
import RootCard from './rootCard';
import { adminStore } from '../../../../stores/admin.store';
import { hierarchyViewStore } from '../../../../stores/hierarchyViewStore';
import { observer } from 'mobx-react-lite';

export interface DownlineInfo {
  _id: string;
  name: string;
  depth: number;
  children?: DownlineInfo[];
  parent: string;
}

type hierarchyViewProps = {
  currentAgency: (value: string) => void;
};

function HierarchyView({ currentAgency }: hierarchyViewProps) {
  const adminAgencyId = adminStore.agency?.id ?? '';
  const [currentChain, setCurrentChain] = useState<
    { id: string; agencyName: string }[]
  >([]);

  const handleCardClick = async (
    agencyId: string,
    depth: number,
    agencyName: string
  ) => {
    if (currentChain.length <= depth) {
      let newCurrentChain = [...currentChain];
      newCurrentChain.push({ id: agencyId, agencyName });
      setCurrentChain(newCurrentChain);
    } else {
      let newCurrentChain = [...currentChain];
      newCurrentChain.splice(depth);
      newCurrentChain.push({ id: agencyId, agencyName });

      setCurrentChain(newCurrentChain);
    }
    hierarchyViewStore.currentSelectedAgencyId = agencyId;
  };

  // useEffect(() => {
  //   currentAgency(currentChain[currentChain.length - 1]);
  // }, [currentChain]);

  return (
    <Row>
      <Card
        style={{
          width: '100%',
          fontSize: '16px',
          fontWeight: '400',
        }}
      >
        <Row justify="space-between" className="radio-buttons">
          <Space>
            <Typography.Paragraph
              style={{
                fontWeight: 500,
                fontSize: '16px',
                margin: '0px 0px',
                color: 'var(--secondary-color)',
              }}
            >
              Distribution Channel View
            </Typography.Paragraph>
            <Tooltip title="Gain a comprehensive organizational overview into your agency's structure, displaying the details and a count of total producers within the downline.">
              <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Tooltip>
          </Space>
        </Row>
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <div style={{ display: 'flex', width: '120%', marginTop: '10px' }}>
            <div style={{ width: '220px' }}>
              <div
                style={{
                  border: '1px solid var(--border-color)',
                  width: '100%',
                  padding: '10px',
                }}
              >
                {hierarchyViewStore.agencyName}
              </div>
              <div
                style={{
                  border: '1px solid var(--border-color)',
                  borderTop: '0',
                  padding: '10px 2px',
                  height: '35dvh',
                  width: '100%',
                  overflowY: 'auto',
                }}
                key={adminAgencyId}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleCardClick(
                      adminAgencyId,
                      0,
                      adminStore.agency?.name || ''
                    );
                  }
                }}
                onClick={() => {
                  handleCardClick(
                    adminAgencyId,
                    0,
                    adminStore.agency?.name || ''
                  );
                }}
              >
                {adminAgencyId && (
                  <RootCard
                    agencyId={adminAgencyId}
                    isSelected={true}
                    // data={hierarchyViewStore.agencyDetails}
                  />
                )}
              </div>
            </div>
            {currentChain.map((item, index) => {
              return (
                <RenderColumn
                  agencyId={item.id}
                  currentChain={currentChain}
                  handleCardClick={handleCardClick}
                  depth={index + 1}
                  key={item.id + (index + 1) + item.agencyName}
                />
              );
            })}
          </div>
        </div>
      </Card>
    </Row>
  );
}

export default observer(HierarchyView);
