import '../generalPageStyle.css';

import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  Typography,
  message,
  notification,
} from 'antd';
import {
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { AdminService } from '../../../services/admin.service';
import { AgentService } from '../../../services/agent.service';
import { Auth0Service } from '../../../services/auth0.service';
import ChangePasswordModal from '../../../utils/modal/admins/change-password/change-password';
import { IdConstants } from '../../../constants/id.constants';
import { Name } from '../../../types/data/name.type';
import { RoleType } from '../../../enums/roles.enum';
import { adminStore } from '../../../stores/admin.store';
import { auth0Store } from '../../../stores/auth0Store';
import { getNameString } from '../../../utils/name.utils';
import { isEmpty } from 'lodash';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../../auth/authProvider';
import { withRoles } from '../../../auth/useRoles';

interface userProfileProps {
  hideDetails?: boolean;
}

const UserProfile: React.FC<userProfileProps> = ({ hideDetails }) => {
  const { performLogout, getAccessTokenSilently } = useAuth();

  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const [resetPasswordEmailSentModal, setResetPasswordEmailSentModal] =
    useState(false);

  const [profileFirstName, setProfileFirstName] = useState(
    adminStore.account?.name?.firstName
  );
  const [profileLastName, setProfileLastName] = useState(
    adminStore.account?.name?.lastName
  );
  const [loading, setLoading] = useState(true);
  const [profileInfoEdit, setProfileInfoEdit] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [resendVerificationEmailLoader, setResendVerificationEmailLoader] =
    useState<boolean>(false);
  const [
    resendEmailVerificationSuccessModal,
    setResendEmailVerificationSuccessModal,
  ] = useState(false);

  const [form2] = Form.useForm();

  useEffect(() => {
    const loadUser = async () => {
      const bearerToken = await getAccessTokenSilently();
      let inUserInvite = false;
      if (window.location.pathname.includes('user-invite')) inUserInvite = true;
      bearerToken && (await auth0Store.loadUser(bearerToken, inUserInvite));
      setLoading(false);
    };
    loadUser();
  }, []);

  const UpdateAdminInformation = async (values: any) => {
    try {
      if (isEmpty(values.firstName) || isEmpty(values.lastName)) {
        notification.error({
          message: 'Error',
          description: 'Name should not be empty',
        });
        setProfileInfoEdit(!profileInfoEdit);
        return;
      }

      setLoading(true);
      if (adminStore) {
        const bearerToken = await getAccessTokenSilently();
        const response = await AdminService.updateAccountName(
          values.firstName,
          values.lastName,
          bearerToken
        ).catch((error: any) => {
          console.error(error);
        });
        if (response)
          adminStore.updateName(values.firstName, '', values.lastName);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setProfileInfoEdit(!profileInfoEdit);
      setLoading(false);
    }
  };

  const sendResetPasswordEmail = async () => {
    try {
      const token = await getAccessTokenSilently();
      setResetPasswordLoader(true);
      const response: any = await Auth0Service.sendResetPasswordMail(token);

      if (response.status === 'SUCCESS') setResetPasswordEmailSentModal(true);
      else message.error('Something went wrong , Please try again later');
      setResetPasswordLoader(false);
    } catch (error) {
      setResetPasswordLoader(false);
      console.error(error);
    }
  };

  const handleResendVerificationEmail = async () => {
    if (signupStore.isAdminAuthorized) {
      try {
        const token = await getAccessTokenSilently();
        setResendVerificationEmailLoader(true);
        const response: any = await AgentService.resendVerificationEmail(token);
        if (response.status === 'SUCCESS')
          setResendEmailVerificationSuccessModal(true);
        else message.error('Something went wrong , Please try again later');
        setResendVerificationEmailLoader(false);
      } catch (error) {
        setResendVerificationEmailLoader(false);
        console.error(error);
      }
    } else if (signupStore.isAgentAuthorized) {
      try {
        const token = await getAccessTokenSilently();
        setResendVerificationEmailLoader(true);
        const response: any = await AgentService.resendVerificationEmail(token);
        if (response.status === 'SUCCESS')
          setResendEmailVerificationSuccessModal(true);
        else message.error('Something went wrong , Please try again later');
        setResendVerificationEmailLoader(false);
      } catch (error) {
        setResendVerificationEmailLoader(false);
        console.error(error);
      }
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {loading ? (
        <div
          className="spin-style"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Skeleton active />
        </div>
      ) : (
        <div>
          <Card
            style={{
              display: 'grid',
              rowGap: '24px',
              padding: '0px',
            }}
          >
            <div>
              <Typography.Paragraph className="heading">
                Your Information
              </Typography.Paragraph>
              <div style={{ position: 'absolute', top: 15, right: 25 }}>
                {!profileInfoEdit && (
                  <Space>
                    <Button
                      id={IdConstants.LOGOUT.AGENCY}
                      className="button important-action-button"
                      onClick={() => {
                        performLogout(window.location.origin);
                      }}
                      icon={<LogoutOutlined />}
                    >
                      Logout
                    </Button>
                    {!hideDetails && (
                      <Button
                        id={
                          IdConstants.AGENCY.GENERAL.EDIT_INFORMATION.BUTTON +
                          '-agency-general'
                        }
                        size="middle"
                        onClick={() => {
                          setProfileInfoEdit(!profileInfoEdit);
                          if (profileInfoEdit) {
                            form2.setFieldsValue({
                              firstName: profileFirstName,
                              lastName: profileLastName,
                            });
                          }
                        }}
                      >
                        Edit Information
                      </Button>
                    )}
                  </Space>
                )}
                {profileInfoEdit && (
                  <Button
                    id={
                      IdConstants.AGENCY.GENERAL.EDIT_INFORMATION.DISCARD +
                      '-agency-general'
                    }
                    size="middle"
                    onClick={() => {
                      setProfileInfoEdit(!profileInfoEdit);
                    }}
                  >
                    Discard
                  </Button>
                )}
                {profileInfoEdit && (
                  <Button
                    id={
                      IdConstants.AGENCY.GENERAL.EDIT_INFORMATION.SAVE +
                      '-agency-general'
                    }
                    style={{ marginLeft: '15px' }}
                    className="button"
                    size="middle"
                    onClick={() => {
                      form2.submit();
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>

            <div className="outer-box">
              <div className="content-outer-box" style={{ marginTop: '20px' }}>
                <div className="content-label-box">
                  <Typography.Paragraph className="content-label">
                    Your Name
                  </Typography.Paragraph>
                </div>
                <div className="content-box">
                  {!profileInfoEdit && (
                    <Typography.Paragraph className="content">
                      {getNameString(adminStore.account?.name as Name)}
                    </Typography.Paragraph>
                  )}
                  {profileInfoEdit && (
                    <Form
                      form={form2}
                      layout="vertical"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      autoComplete="false"
                      onFinish={UpdateAdminInformation}
                    >
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        style={{ width: '49%' }}
                        initialValue={profileFirstName}
                        rules={[
                          { required: true },
                          {
                            pattern: /^[a-zA-Z0-9\s,;&.]+$/,
                            message:
                              'Name can only contain letters, numbers, spaces, `,`, `&`, and `;`',
                          },
                          {
                            validator: (_, value) =>
                              value.trim() === ''
                                ? Promise.reject(
                                    new Error(
                                      'First name cannot contain only spaces'
                                    )
                                  )
                                : Promise.resolve(),
                          },
                        ]}
                      >
                        <Input
                          id={
                            IdConstants.AGENCY.GENERAL.EDIT_INFORMATION
                              .FIRST_NAME_INPUT + '-agency-general'
                          }
                          placeholder="Enter your first name"
                          defaultValue={profileFirstName}
                          onChange={(e) => {
                            const value = e.target.value;
                            const cleanedValue = value.replace(
                              /[^A-Za-z\s]/g,
                              ''
                            );
                            const capitalizedValue =
                              cleanedValue.charAt(0).toUpperCase() +
                              cleanedValue.slice(1);
                            form2.setFieldsValue({
                              firstName: capitalizedValue,
                            });
                          }}
                          onKeyPress={(event) => {
                            const regex = /^[A-Za-z\s]+$/;
                            const char = String.fromCharCode(
                              event.which || event.keyCode
                            );
                            if (!regex.test(char)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(event) => {
                            const pastedText =
                              event.clipboardData.getData('text/plain');
                            const regex = /^[A-Za-z\s]+$/;
                            if (!regex.test(pastedText)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                          { required: true },
                          {
                            pattern: /^[a-zA-Z0-9\s,;&.]+$/,
                            message:
                              'Name can only contain letters, numbers, spaces, `,`, `&`, and `;`',
                          },
                          {
                            validator: (_, value) =>
                              value.trim() === ''
                                ? Promise.reject(
                                    new Error(
                                      'Last name cannot contain only spaces'
                                    )
                                  )
                                : Promise.resolve(),
                          },
                        ]}
                        style={{ width: '49%' }}
                        initialValue={profileLastName}
                      >
                        <Input
                          id={
                            IdConstants.AGENCY.GENERAL.EDIT_INFORMATION
                              .LAST_NAME_INPUT + '-agency-general'
                          }
                          placeholder="Enter your last name"
                          defaultValue={profileLastName}
                          onChange={(e) => {
                            const value = e.target.value;
                            const cleanedValue = value.replace(
                              /[^A-Za-z\s]/g,
                              ''
                            );
                            const capitalizedValue =
                              cleanedValue.charAt(0).toUpperCase() +
                              cleanedValue.slice(1);
                            form2.setFieldsValue({
                              lastName: capitalizedValue,
                            });
                          }}
                          onKeyPress={(event) => {
                            const regex = /^[A-Za-z\s]+$/;
                            const char = String.fromCharCode(
                              event.which || event.keyCode
                            );
                            if (!regex.test(char)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(event) => {
                            const pastedText =
                              event.clipboardData.getData('text/plain');
                            const regex = /^[A-Za-z\s]+$/;
                            if (!regex.test(pastedText)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Form>
                  )}
                </div>
              </div>
              {!hideDetails && (
                <div
                  className="content-outer-box"
                  style={{ marginTop: '20px' }}
                >
                  <div className="content-label-box">
                    <Typography.Paragraph className="content-label">
                      Role
                    </Typography.Paragraph>
                  </div>
                  <div className="content-box">
                    <Typography.Paragraph className="content">
                      {adminStore.role}
                    </Typography.Paragraph>
                  </div>
                </div>
              )}
              {!hideDetails && (
                <hr
                  style={{
                    backgroundColor: '#D9D9D9',
                    width: '100%',
                    textAlign: 'left',
                    border: '0.01px solid #D9D9D9',
                  }}
                />
              )}

              <div
                className="content-outer-box"
                style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                <div className="content-label-box">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                      }}
                    >
                      <Typography.Paragraph
                        className="content-label"
                        style={{ marginBottom: '0.5em' }}
                      >
                        Email Address
                      </Typography.Paragraph>
                      <div
                        style={{
                          display: 'flex',
                          gap: '3px',
                          marginBottom: '1em',
                        }}
                      >
                        {auth0Store.user?.email_verified ? (
                          <Row>
                            <Space style={{ alignContent: 'baseline' }}>
                              <CheckCircleOutlined style={{ color: 'green' }} />
                              <Typography.Paragraph
                                className="content-label"
                                style={{ marginBottom: 0 }}
                              >
                                Email Verified
                              </Typography.Paragraph>
                            </Space>
                          </Row>
                        ) : (
                          <Row>
                            <Space style={{ alignContent: 'baseline' }}>
                              <CloseCircleOutlined style={{ color: 'red' }} />
                              <Typography.Paragraph
                                className="content-label"
                                style={{ marginBottom: 0 }}
                              >
                                Email Not Verified
                              </Typography.Paragraph>
                            </Space>
                          </Row>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-box" style={{ flexBasis: '29%' }}>
                  <Typography.Paragraph
                    className="content"
                    style={{ marginTop: '8px' }}
                  >
                    {auth0Store.user?.email}
                  </Typography.Paragraph>
                  {auth0Store.user?.email_verified ? (
                    <></>
                  ) : (
                    <Button
                      type="default"
                      loading={resendVerificationEmailLoader}
                      onClick={handleResendVerificationEmail}
                    >
                      Resend Verification
                    </Button>
                  )}
                </div>
              </div>

              <div className="content-outer-box">
                <div className="content-label-box">
                  <Typography.Paragraph className="content-label">
                    Password
                  </Typography.Paragraph>
                </div>
                <div className="content-box">
                  <Button
                    id={
                      IdConstants.AGENCY.GENERAL.CHANGE_PASSWORD +
                      '-agency-general'
                    }
                    loading={resetPasswordLoader}
                    style={{
                      borderRadius: '6px',
                      borderColor: 'var(--border-color)',
                      color: 'var(--secondary-color)',
                    }}
                    className="button"
                    onClick={() => {
                      sendResetPasswordEmail();
                    }}
                    size="middle"
                  >
                    Reset Password
                  </Button>
                </div>
              </div>
            </div>
          </Card>

          <ChangePasswordModal
            visible={updatePasswordModal}
            setVisible={setUpdatePasswordModal}
          />

          {resendEmailVerificationSuccessModal && (
            <Modal
              centered
              closable={false}
              open={resendEmailVerificationSuccessModal}
              footer={null}
            >
              <div style={{ textAlign: 'center' }}>
                <h2 style={{ marginBottom: '10px' }}>
                  Verification email successfully sent!
                </h2>
                <CheckCircleTwoTone
                  style={{ fontSize: '50px' }}
                  color="#00FF00"
                />
                <p>
                  Please check your email inbox for a verification email. Click
                  on the verification link to confirm your email
                </p>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  onClick={() => {
                    setResendEmailVerificationSuccessModal(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </Modal>
          )}
          {resetPasswordEmailSentModal && (
            <Modal
              centered
              closable={false}
              open={resetPasswordEmailSentModal}
              footer={null}
            >
              <div style={{ textAlign: 'center' }}>
                <h2 style={{ marginBottom: '10px' }}>
                  Password Reset Email Sent Successfully
                </h2>
                <CheckCircleTwoTone
                  style={{ fontSize: '36px' }}
                  color="#00FF00"
                />
                <p>Please check your email to reset your password</p>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  onClick={() => {
                    setResetPasswordEmailSentModal(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default withRoles(UserProfile, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
  RoleType.AGENT,
]);
