import { ObjectSuccessResponse } from '../types/response/objectSuccessResponse.type';
import { ReferralConfig } from '../types/data/referralConfig.type';
import { UrlConstants } from '../constants/url.constants';
import { UserData } from '../auth/onboarding/signup/verifyDetails';
import { apiStore } from '../stores/api.store';

export class SignupService {
  static readonly host = UrlConstants.backend;
  static readonly modulePath = '/signup';

  static async createNewUser(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    recaptchaToken: string,
    inviteToken?: string,
    urlParams?: {
      utm_medium?: string;
      utm_source?: string;
      utm_term?: string;
      utm_campaign?: string;
      utm_content?: string;
      invite_token?: string;
      referral_code?: string;
    }
  ) {
    const data: any = {
      firstName,
      lastName,
      email,
      password,
      recaptchaToken,
    };
    if (inviteToken) data.inviteToken = inviteToken;
    if (urlParams) data.params = urlParams;

    return new Promise((resolve, reject) => {
      apiStore
        .getApiClientWithoutAuthentication()
        .post(`${this.host}/auth/sign-up`, data)
        .then((response: any) => {
          if (response.data?.status === 'SUCCESS') {
            resolve(response.data.data);
          } else throw response.data;
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  }

  static async getBasicInfo(token: string): Promise<any> {
    const path = `/agency/onboard`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}/basic-info`);

        resolve(response.data);
      } catch (error: any) {
        console.error('SignupService :: getBasicInfo :: Error :: ', error);
        reject(error?.response?.data?.error);
      }
    });
  }

  static async updateBasicInfo(token: string, basicInfo: any) {
    const path = `/agency/onboard`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}/basic-info`, basicInfo);
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'SignupService :: postBasicInfo :: Error :: ',
          error?.message
        );
        let errorMessage = 'Unable to submit information.';
        if (error?.response?.data?.message) {
          errorMessage = error?.response?.data?.message;
        } else if (error?.response?.data?.error) {
          if (error?.response?.data?.error?.error_description)
            errorMessage = error?.response?.data?.error?.error_description;
          else if (error?.response?.data?.error?.message)
            errorMessage = error?.response?.data?.error?.message;
        }
        reject(errorMessage);
      }
    });
  }

  static async getMagicLinkStatus(token: string) {
    const path = `/magic-link`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}/`);

        resolve(response.data);
      } catch (error: any) {
        console.error(
          'SignupService :: getMagicLinkStatus :: Error :: ',
          error?.message
        );
        let errorMessage = 'Unable to submit information.';
        if (error?.response?.data?.message) {
          errorMessage = error?.response?.data?.message;
        } else if (error?.response?.data?.error) {
          if (error?.response?.data?.error?.error_description)
            errorMessage = error?.response?.data?.error?.error_description;
          else if (error?.response?.data?.error?.message)
            errorMessage = error?.response?.data?.error?.message;
        }
        reject(errorMessage);
      }
    });
  }

  static async resendMagicLink(token: string) {
    const path = `/magic-link`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .patch(`${this.host}${path}/`);

        resolve(response.data);
      } catch (error: any) {
        console.error(
          'SignupService :: resendMagicLink :: Error :: ',
          error?.message
        );
        let errorMessage = 'Unable to submit information.';
        if (error?.response?.data?.message) {
          errorMessage = error?.response?.data?.message;
        } else if (error?.response?.data?.error) {
          if (error?.response?.data?.error?.error_description)
            errorMessage = error?.response?.data?.error?.error_description;
          else if (error?.response?.data?.error?.message)
            errorMessage = error?.response?.data?.error?.message;
        }
        reject(errorMessage);
      }
    });
  }

  static async verifyMagicLink(id: string) {
    return SignupService.verifyMagicLinkOrCode(id, 'link');
  }

  static async verifyMagicCode(code: string, token: string) {
    return SignupService.verifyMagicLinkOrCode(code, 'code', token);
  }

  private static async verifyMagicLinkOrCode(
    value: string,
    type: 'code' | 'link',
    token?: string
  ) {
    return new Promise((resolve, reject) => {
      let url = `${this.host}/magic-link/verify`;
      let data: { magicLink?: string; magicCode?: string } = {};
      let apiClient = apiStore.getApiClientWithoutAuthentication();

      if (type === 'code' && token) {
        url += '/code';
        data.magicCode = value;
        apiClient = apiStore.getApiClient(token);
      } else {
        url += '/link';
        data.magicLink = value;
      }

      apiClient
        .post(url, data)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  }

  static async validateStripePayment(token: string, data: any) {
    return new Promise((resolve, reject) => {
      //TODO Make POST Call to URL here with request body
      apiStore
        .getApiClient(token)
        .post(`${this.host}/agency/onboard/stripe-callback`, data)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  }

  static async getStripeValidURL(token: string) {
    return new Promise((resolve, reject) => {
      apiStore
        .getApiClient(token)
        .get(`${this.host}/agency/onboard/stripe-payment-url`)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  }

  static async getInviteDetails(
    inviteToken: string
  ): Promise<ObjectSuccessResponse<any>> {
    const path = `/invite`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClientWithoutAuthentication()
          .get(`${this.host}${path}/${inviteToken}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data as unknown as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error(
          'signupService:: getInviteDetails Error :: ',
          error?.message
        );
        reject(error?.response?.data?.error);
      }
    });
  }

  static async VerifyDetails(
    bearerToken: string,
    requestBody: Object,
    OnboardingStatus?: boolean
  ): Promise<ObjectSuccessResponse<any>> {
    const path = '/agency/onboard/verify-details';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(bearerToken).post(
          `${this.host}${path}`,
          { ...requestBody, onboardingComplete: OnboardingStatus },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );
        resolve(response.data as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error(
          ' SignUpService :: VerifyDetails :: Error :: ',
          JSON.stringify(error)
        );
        reject(error);
      }
    });
  }

  static async getDetails(bearerToken: string): Promise<UserData> {
    const path = '/agency/onboard/verify-details';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);
        resolve(AllLicenses.data.data);
      } catch (error: any) {
        console.error(
          ' SignUpService :: getVerifyDetails :: Error :: ',
          JSON.stringify(error)
        );
        reject(error);
      }
    });
  }

  static async fetchReferralConfig(
    referralCode: string
  ): Promise<ReferralConfig> {
    return new Promise((resolve, reject) => {
      apiStore
        .getApiClientWithoutAuthentication()
        .get(`${this.host}/referral/${referralCode}`)
        .then((response: any) => {
          if (response.data?.status === 'SUCCESS') {
            resolve(response.data.data as ReferralConfig);
          } else throw response.data;
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  }
}
