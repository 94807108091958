export const ClickableLinkText = (text: string): (JSX.Element | string)[] => {
  if (!text) return [text];

  const linkRegex = /((?:http?s?:\/\/|www\.)[^\s]+)/g;
  const link = text.match(linkRegex);

  if (link) {
    const parts = text.split(linkRegex);
    return parts.map((data, index) => {
      if (data.match(linkRegex)) {
        const modifiedData = data.endsWith('.')
          ? data.slice(0, data.length - 1)
          : data;

        return (
          <a
            key={`link-${index}`}
            href={
              modifiedData.startsWith('https')
                ? modifiedData
                : `//${modifiedData}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {data}
          </a>
        );
      } else {
        return <span key={`text-${index}`}>{data}</span>;
      }
    });
  } else {
    return [text];
  }
};
