import { useEffect, useState } from 'react';

import Loading from '../../components/loading';
import { OnboardingLayout } from '../../components/layout/onboardingLayout';
import { RouteConstants } from '../../constants/routes.constants';
import { SignUpOnboarding } from './signup/signUpOnboarding';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../../stores/signupStore';
import { useAuth } from '../authProvider';
import { useNavigate } from 'react-router';

function OnboardingScreen() {
  const { isAuthenticated, isOnboarded, onboardingStage } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate(RouteConstants.rootRedirect.path);
    } else {
      if (isOnboarded) {
        navigate('/redirect');
      }
    }
    setLoading(false);
  }, [isAuthenticated(), isOnboarded, onboardingStage]);

  return (
    <>
      {(isLoading || signupStore.isAppLoading) && (
        <Loading fullHeight logo showAsOverlay />
      )}
      <OnboardingLayout step={2} flowType="Onboarding">
        <SignUpOnboarding />
      </OnboardingLayout>
    </>
  );
}

export default observer(OnboardingScreen);
