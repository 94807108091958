import {
  AutoComplete,
  Card,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Table,
  Typography,
  notification,
} from 'antd';
import {
  DYNAMIC_DEFAULT_FILTER,
  DYNAMIC_DEFAULT_SELECTED_FILTER,
} from '../actionable';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../../types/common/filters.type';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../../utils/search.utils';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import AgentAssignmentStatus from '../../../../components/licenses/agentAssignmentStatus';
import { AgentService } from '../../../../services/agent.service';
import { AllLicenseResponse } from '../../../../types/response/all-license.type';
import { ApplicationStatusEnum } from '../../../../enums/applicationStatus.enum';
import AssociatedAgent from '../../../../components/licenses/associatedAgent';
import { ColumnsType } from 'antd/es/table';
import DaysToExpireCell from '../../../../components/licenses/daysToExpireCell';
import FilterButton from '../../../../components/filterButton';
import { FilterType } from '../../../../enums/filterType.enum';
import { IdConstants } from '../../../../constants/id.constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import LcLoaCell from '../../../../components/licenses/lcLoaCell';
import { LicenseStatus } from '../../../../enums/licenseStatus.enum';
import LicenseStatusCell from '../../../../components/licenses/licenseStatusCell';
import { LicensesService } from '../../../../services/licenses.service';
import { PageConstants } from '../../../../constants/page.constants';
import { PaginatedSuccessResponse } from '../../../../types/response/paginatedSuccessResponse.type';
import { PaymentTypeEnum } from '../../../../enums/paymentType.enum';
import { PlausiblePageConstants } from '../../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../../enums/roles.enum';
import { SEARCH_REGEX } from '../../../../constants/regex.constants';
import SimpleFilter from '../../../../components/common/simpleFilter/simpleFilter';
import StateCell from '../../../../components/licenses/stateCell';
import { StateConstants } from '../../../../constants/state.constants';
import { adminStore } from '../../../../stores/admin.store';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { applySort } from '../../../../utils/common.utils';
import { cancelPreviousRequest } from '../../../../utils/api.utils';
import { getNameString } from '../../../../utils/name.utils';
import { isEmpty } from 'lodash';
import { setFilterGroups } from '../../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../../auth/authProvider';
import { useQueryState } from '../../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../../auth/useRoles';

enum Tab {
  ALL_LICENSES = 'all-licenses',
  DEPENDENT_LICENSES = 'dependent-licenses',
}

const tooltipMessages = {
  [Tab.ALL_LICENSES]:
    "Access a comprehensive view of associated producers' state licenses, complete with status and key configurations, providing a centralized overview for efficient monitoring.",
  [Tab.DEPENDENT_LICENSES]:
    'View all active and missing licenses of producers by state, and identify assignment status for each license.',
};

const DEFAULT_SELECTED_FILTER = {
  'License Status': {
    labelValuePair: {
      value: 'active',
      label: 'Active',
    },
    key: 'status',
  },
};

const DEFAULT_FILTER = [
  {
    operator: 'or',
    filters: [
      {
        key: 'status',
        condition: 'is',
        value: 'active',
      },
    ],
  },
];

const { Search } = Input;

interface AllLicensesProps {
  isAgentOverview?: boolean;
}

const AllLicensesAgentsTab: React.FC<AllLicensesProps> = ({
  isAgentOverview,
}) => {
  const { agentId } = useParams();
  const licenseStatus = LicenseStatus.Active;
  const [searchParams, setSearchParams] = useSearchParams();

  const licenseApplicationStatus = ApplicationStatusEnum.Success;
  const currentTab = Tab.DEPENDENT_LICENSES;
  const [loading, setIsLoading] = useState<boolean>(false);
  const [activeData, setActiveData] = useState<AllLicenseResponse[]>([]);

  const { bearerToken, getAccessTokenSilently } = useAuth();
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [sortValue, setSortValue] = useState({});
  const [disableAssign, setDisableAssign] = useState(false);
  const [currentAssignLoadingId, setCurrentAssignLoadingId] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);

  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const createAssignmentForLicense = async (
    producerId: string,
    requestBody: any
  ) => {
    try {
      setDisableAssign(true);
      //setCurrentAssignLoadingId(licenseId);
      const token = await getAccessTokenSilently();
      const resp = await AgentService.createAssignmentForLicense(
        token,
        producerId,
        requestBody
      );
      if (resp) fetchLicenseDetails();
    } catch (e: any) {
      console.error('createAssignmentForLicense error :: ', e);
      if (e?.error?.message) showError(e.error.message);
      else showError('Assignment failed for given license');
    } finally {
      setDisableAssign(false);
      setCurrentAssignLoadingId('');
    }
  };

  const updateRenewalPaymentAccountByAgentId = (
    agent: any,
    renewalPaymentAccount: any
  ) => {
    if (agent.includes(agent._id)) {
      return {
        ...agent,
        paymentConfig: {
          ...agent.paymentConfig,
          renewalPaymentPayee: renewalPaymentAccount,
          paymentType:
            agent.paymentConfig?.initialPaymentPayee === 'Agent' &&
            renewalPaymentAccount === 'Agent'
              ? PaymentTypeEnum.NA
              : agent.paymentConfig?.paymentType,
        },
        autoRenewEnabled:
          renewalPaymentAccount === 'Agent' ? false : agent.autoRenewEnabled,
      };
    }
    return agent;
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      setPageNumber(1);
      fetchLicenseDetails('', 1);
    }
  }, [requestBody]);

  useEffect(() => {
    fetchLicenseDetails();
  }, [
    licenseStatus,
    adminStore.account,
    licenseApplicationStatus,
    queryText,
    searchType,
  ]);

  const getAgentDetails = async () => {
    const response: any = await AgentService.getAgentDetails(
      agentId || '',
      bearerToken || ''
    ).catch((error) => {
      console.error('Error:: ', error.message);
    });
    if (response.data) {
      appStateInfoStore.setCurrentAgentId(response.data.id);
      appStateInfoStore.setHeader(getNameString(response.data.name));
    }
  };

  useEffect(() => {
    if (
      !appStateInfoStore.header &&
      isAgentOverview &&
      agentId &&
      bearerToken
    ) {
      getAgentDetails();
    }
  }, [agentId]);

  const clearFilters = () => {
    let data = {};
    if (isAgentOverview) {
      data = {
        ...Object.fromEntries(
          Object.entries(selectedFilters?.data)?.filter(
            ([k, v]) => k === 'Producer'
          )
        ),
      };
    }

    setSelectedFilters({ data });
    const filterGroups = setFilterGroups({ data });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  let columns: ColumnsType<AllLicenseResponse> = [
    {
      title: 'State',
      dataIndex: 'stateCode',
      sorter: true,
      key: 'stateCode',
      render: (text: string, record: AllLicenseResponse) => (
        <StateCell
          state={StateConstants[text]}
          licenseNumber={record.licenseNumber}
        />
      ),
    },
    ...(agentId
      ? []
      : [
          {
            title: 'Producer',
            dataIndex: 'producerDetails',
            sorter: true,
            key: 'producerDetails.name',
            render: (producer: any, record: AllLicenseResponse) => {
              return (
                // <></>
                <AssociatedAgent
                  agentId={producer.producerId}
                  agentName={producer.name}
                  isLicenseProcessing={producer.isLicenseProcessing}
                  isAssignmentProcessing={producer.isAssignmentProcessing}
                  id={`${IdConstants.LICENSES.TABLE.ASSOCIATED_AGENT}-${record.licenseNumber}`}
                />
              );
            },
          },
        ]),
    {
      title: 'License Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text: string, record: AllLicenseResponse) => (
        <LicenseStatusCell licenseStatus={text} />
      ),
    },
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurationDetails.licenseClass',
      sorter: true,
      render: (loas: any, record: any) => {
        return (
          <Row align="middle">
            <LcLoaCell
              licenseClass={record?.licenseConfigurations?.licenseClass}
              loaDetails={loas?.loaDetails}
              // requiredStatus={LoaStatus.ACTIVE}
            />
          </Row>
        );
      },
    },
    {
      title: 'Expiry',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <DaysToExpireCell
            expiryDate={text}
            licenseStatus={record.status as LicenseStatus}
            licenseState={record.stateCode}
          />
        );
      },
    },
    {
      title: 'Renewal Type',
      dataIndex: 'autoRenews',
      key: 'autoRenews',
      render: (text: boolean, record: any) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            type="secondary"
            style={{
              fontSize: '12px',
              marginBottom: '0.25em',
            }}
          >
            {record?.producerDetails?.paymentConfig?.isAutoRenewalActive ===
            true
              ? 'Auto Apply'
              : 'Does not auto apply'}
          </Typography.Paragraph>
        </Row>
      ),
    },

    {
      title: 'Assignment Status',
      sorter: true,
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurations.agencyAssignment.isAssigned',
      onCell: (record: any) => ({
        style: {
          color: record?.licenseConfigurations?.agencyAssignment[0]?.isAssigned
            ? 'green'
            : 'red',
        },
      }),
      render: (licenseConfiguration: any, record: any) => {
        const isLoading = currentAssignLoadingId === record.id;
        return (
          <AgentAssignmentStatus
            isAssigned={licenseConfiguration?.agencyAssignment[0]?.isAssigned}
            showAssignButton={
              !licenseConfiguration?.agencyAssignment[0]?.isAssigned &&
              record.status === LicenseStatus.Active
            }
            handleClick={() => {
              const licenseConfigurationIds =
                record.licenseConfigurations.loaDetails.map((loa: any) => {
                  return loa.licenseConfigurationId;
                });
              const stateCode = record.stateCode;
              const requestBody = { licenseConfigurationIds, stateCode };
              createAssignmentForLicense(
                record.producerDetails.producerId,
                requestBody
              );
            }}
            isLoading={isLoading}
            isDisabled={!isLoading && disableAssign}
          />
        );
      },
    },
  ];

  const tableColumns = columns;

  const onPaginationChange: PaginationProps['onChange'] = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    adminStore.agency?.id &&
      fetchLicenseDetails(queryText, newPageNumber, newPageSize);
  };

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const defaultRequestBody = {
        agency: adminStore.agency?.id,
        isLatest: true,
      };
      if (bearerToken) {
        const response: any =
          await LicensesService.getFilterInfoForFindAllLicenses(
            bearerToken,
            defaultRequestBody
          );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string; key: string }) =>
                data.filterType === FilterType.SEARCH &&
                (!isAgentOverview || data.key !== 'name')
            ),
            PageConstants.ALL_PRODUCER_LICENSES
          )
        );
        return isAgentOverview
          ? response?.data?.filter((data: any) => data.key !== 'producerIds')
          : response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const fetchLicenseDetails = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object
  ) => {
    try {
      setIsLoading(true);
      const agencyId = adminStore.agency?.id;
      if (!agencyId) return;
      if (bearerToken) {
        let filter = {};
        if (searchParams.has('overview')) {
          setSelectedFilters({
            data: DEFAULT_SELECTED_FILTER,
          } as unknown as FiltersType);
          filter = {
            filterGroups: DEFAULT_FILTER,
          };
          searchParams.delete('overview');
          setRequestBody({ ...requestBody, ...filter });
          setSearchParams(searchParams.toString(), { replace: true });
        }
        if (
          searchParams.has('producerId') &&
          !(isAgentOverview && Object.keys(selectedFilters?.data)?.length > 1)
        ) {
          const producerId = searchParams.get('producerId') || '';
          const producerName = searchParams.get('producerName') || '';
          setSelectedFilters({
            data: DYNAMIC_DEFAULT_SELECTED_FILTER('', {
              value: producerId,
              label: producerName,
            }),
          } as unknown as FiltersType);
          filter = {
            filterGroups: DYNAMIC_DEFAULT_FILTER('', producerId || ''),
          };
          if (!isAgentOverview) searchParams.delete('producerId');
          searchParams.delete('producerName');
          if (producerName || !isAgentOverview)
            setRequestBody({ ...requestBody, ...filter });
          setSearchParams(searchParams.toString(), { replace: true });
        }
        const defaultRequestBody = {
          agency: agencyId,
          isLatest: true,
        };
        cancelToken && cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const response: PaginatedSuccessResponse<AllLicenseResponse> =
          await LicensesService.findAllForCurrentAgency(
            {
              ...requestBody,
              ...defaultRequestBody,
              ...filter,
              ...(sort || sortValue || {}),
              ...(isAgentOverview && {
                agentIds: [agentId],
              }),
            },
            bearerToken,
            pageSizeProp || pageSize,
            pageNumberProp || pageNumber,
            searchTextProp ? searchTextProp : queryText || '',
            searchType ? searchType : searchType || '',
            cancelTokenSource,
            agentId
          );
        if (response) {
          setCancelToken(undefined);
          setTotalCount(response.totalCount);
          setActiveData(response.data);
          setIsLoading(false);
        } else {
          setTotalCount(0);
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setCancelToken(undefined);
        setIsLoading(false);
      }
      console.error('Error:', error);
    }
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);

      return updatedFilters;
    });
  };

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_ALL_LICENSES_AGENTS;
  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <Card
      className="reset-icon-size"
      style={{
        width: '100%',
        height: 'fit-content',
        marginTop: '20px',
      }}
    >
      {contextHolder}
      <div>
        <Typography.Text type="secondary" style={{ fontSize: 14 }}>
          <InfoCircleOutlined />{' '}
          <span>{tooltipMessages[currentTab as unknown as Tab]}</span>
        </Typography.Text>
      </div>
      <div style={{ display: 'flex', columnGap: '10px', margin: '10px 0px' }}>
        <AutoComplete
          options={options}
          style={{ width: '100%' }}
          disabled={loading}
          value={getSearchDisplayText(
            searchType,
            displaySearchType,
            searchText
          )}
          onSelect={handleOnSelect}
          onChange={handleOnChange}
        >
          <Search
            id={IdConstants.SEARCH_INPUT + '-all-licenses-agent-tab'}
            placeholder={
              isAgentOverview
                ? 'Search Licenses by State, LOAs or License Class'
                : 'Search Licenses by State, Producer, LOAs or License Class'
            }
            style={{ width: '100%' }}
            // allowClear
            onSearch={handleOnSearch}
          />
        </AutoComplete>

        <FilterButton
          {...{
            filterInfoLoading,
            activeData,
            requestBody,
            isLoading: loading,
            setIsFilterVisible,
            pageName,
          }}
        />
      </div>
      <RenderFilterChip
        {...{
          selectedFilters: !isAgentOverview
            ? selectedFilters
            : {
                ...selectedFilters,
                data: {
                  ...Object.fromEntries(
                    Object.entries(selectedFilters?.data)?.filter(
                      ([k, v]) => k !== 'Producer'
                    )
                  ),
                },
              },
          removeFilter,
          clearFilters,
          pageName,
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <Table
          columns={tableColumns}
          dataSource={activeData}
          pagination={false}
          loading={loading}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, fetchLicenseDetails);
            setSortValue(sort);
          }}
        />
        {!isEmpty(activeData) && (
          <Row justify="end">
            <Pagination
              showSizeChanger
              pageSize={pageSize}
              current={pageNumber}
              onChange={onPaginationChange}
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={loading}
            />
          </Row>
        )}
        <div />
      </div>
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => fetchFiltersInfoForFindAll()}
        clearFilters={() => {
          clearFilters();
        }}
        pageName={pageName}
        filterPage={PageConstants.ALL_PRODUCER_LICENSES}
      />
    </Card>
  );
};
export default withRoles(AllLicensesAgentsTab, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
