import { Breadcrumb, Card, Col, Row, Typography } from 'antd';

import { HeaderProps } from './header';
import { RoleType } from '../../enums/roles.enum';
import { RouteConstants } from '../../constants/routes.constants';
import SelectProfile from '../common/userProfile/selectProfile';
import { agentSideStore } from '../../stores/agentPortalStore';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { withRoles } from '../../auth/useRoles';

function AgentHeader({ headerText }: HeaderProps) {
  const location = useLocation();
  const path = location.pathname;

  return (
    <Card
      style={{
        height: '70px',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
      type="inner"
      className="header"
    >
      <Row style={{ justifyContent: 'space-between' }}>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Row
            gutter={10}
            align="middle"
            justify={{
              xl: 'space-around',
            }}
            style={{
              width: '100%',
              columnGap: '20px',
            }}
          >
            {appStateInfoStore.menuIcon}
            <Typography
              style={{
                color: 'var(--primary-color)',
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '28px',
              }}
            >
              {headerText}
            </Typography>
            <div style={{ width: 'auto', borderRadius: 1, flexGrow: 1 }} />
          </Row>
          <Row
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            {/* Agent */}
            {path === RouteConstants.agentActionableLicenses.path && (
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'My Producer',
                  },
                  {
                    title: 'Needs Attention',
                  },
                ]}
              />
            )}
            {path === RouteConstants.agentApplications.path && (
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'My Producer',
                  },
                  {
                    title: 'Pending Applications',
                  },
                ]}
              />
            )}

            {path === RouteConstants.agentAllLicenses.path && (
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'My Producer',
                  },
                  {
                    title: 'All Licenses',
                  },
                ]}
              />
            )}
            {path === RouteConstants.employmentHistory.path && (
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'Onboarding',
                  },
                  {
                    title: 'Employment History',
                  },
                ]}
              />
            )}
            {path === RouteConstants.backgroundQuestions.path && (
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'Onboarding',
                  },
                  {
                    title: 'Background Questions',
                  },
                ]}
              />
            )}
            {path === RouteConstants.agentSupportDocuments.path && (
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'Onboarding',
                  },
                  {
                    title: 'Supporting Documents',
                  },
                ]}
              />
            )}
            {path === RouteConstants.agentProfile.path && (
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'My Account',
                  },
                  {
                    title: 'Account',
                  },
                ]}
              />
            )}
            {path === RouteConstants.agentPortalRTS.path && (
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'My Producer',
                  },
                  {
                    title: 'Appointments',
                  },
                  {
                    title: agentSideStore?.agentName,
                  },
                ]}
              />
            )}
            {path === RouteConstants.invites.path && (
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'My Account',
                  },
                  {
                    title: 'Team Invites',
                  },
                  {
                    title: agentSideStore?.agentName,
                  },
                ]}
              />
            )}

            {/* {path === RouteConstants.wallet.path && (
          <Breadcrumb
            separator=">"
            items={[
              {
                title: 'Settings',
              },
              {
                title: 'Wallet',
              },
            ]}
          />
        )} */}
          </Row>
        </Col>
        <Col
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <SelectProfile />
        </Col>
      </Row>
    </Card>
  );
}

export default withRoles(observer(AgentHeader), [RoleType.AGENT]);
