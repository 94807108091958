import {
  AllLicensesAgencyResponseType,
  agencyLicensesType,
} from '../../../../../types/request/agency/allLicenses/allLicenses.type';
import { CloseCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import USMapView, { USMapViewDataProp } from '../../../../../components/usMap';
import { useEffect, useState } from 'react';

import { ApplicationStatusEnum } from '../../../../../enums/applicationStatus.enum';
import { GeneralTabType } from '../../../../../enums/generalTabs.enum';
import { LicenseStatus } from '../../../../../enums/licenseStatus.enum';
import LicensedProducer from '../../../../../components/agencyLicensing/licensedProducer';
import ListAgentDetails from './listAgentDetails';
import { LoaStatus } from '../../../../../enums/loaStatus.enum';
import { RouteConstants } from '../../../../../constants/routes.constants';
import { StateConstants } from '../../../../../constants/state.constants';
import { getAssignedDrlp } from '../../../../../components/agencyLicensing/reviewSection';
import { groupBy } from 'lodash';
import { useNavigate } from 'react-router';

const mapColor = {
  ACTIVE: '#3EAB22',
  PENDING: '#FCEFCF',
  MISSING: 'rgba(252, 239, 207, 1)',
  INACTIVE: '#FACBCB',
};

const mapTooltip = [
  { title: LicenseStatus.Active, color: mapColor.ACTIVE },
  { title: 'Pending', color: mapColor.PENDING },
  { title: 'Inactive', color: mapColor.INACTIVE },
  { title: 'Missing', color: mapColor.MISSING },
];

interface AllLicensesAgencyMapType {
  allLicenses?: AllLicensesAgencyResponseType | null;
}

export const getStatus = (licenseGroup: agencyLicensesType[]) => {
  let hasPending = false;
  let hasMissingLoa = false;
  let allActive = true;
  const status = licenseGroup.every((d) => d.status === LicenseStatus.Active)
    ? LicenseStatus.Active
    : licenseGroup.some(
        (d) =>
          d?.latestApplication?.[0]?.status === ApplicationStatusEnum.PENDING ||
          d.status === ApplicationStatusEnum.PENDING
      )
    ? 'pending'
    : LicenseStatus.Missing;

  for (const d of licenseGroup) {
    if (d.status !== LicenseStatus.Active) {
      allActive = false;
    }

    if (
      d?.latestApplication?.[0]?.status === ApplicationStatusEnum.PENDING ||
      d.status === ApplicationStatusEnum.PENDING
    ) {
      hasPending = true;
    }

    if (d.status === 'missing_loa') {
      hasMissingLoa = true;
    }

    // If all necessary conditions are met, break the loop early
    if (hasPending && hasMissingLoa && !allActive) {
      break;
    }
  }

  if (allActive) return LicenseStatus.Active;
  if (hasPending) return 'pending';
  if (hasMissingLoa) return 'missing';
  return LicenseStatus.Inactive;
};

const AllLicensesAgencyMap: React.FC<AllLicensesAgencyMapType> = ({
  allLicenses,
}) => {
  const navigate = useNavigate();
  const [usMapData, setUsMapData] = useState<USMapViewDataProp>();

  const [selectedState, setSelectedState] = useState('');
  const [selectedRecords, setSelectedRecords] = useState<any>();

  useEffect(() => {
    const licenses = groupBy(allLicenses?.agencyLicenses || [], 'stateName');
    const usMapData: USMapViewDataProp = {};

    Object.entries(StateConstants).forEach(([k, v]) => {
      if (licenses?.[v]) {
        const licenseGroup = licenses?.[v];
        const uniqueDrlpNpnSet = new Set();
        const drlpsCount = licenseGroup?.reduce((acc, val) => {
          val.drlps?.forEach((drlp) => {
            if (!uniqueDrlpNpnSet.has(drlp?.npn)) {
              uniqueDrlpNpnSet.add(drlp?.npn);
              acc += 1;
            }
          });
          return acc;
        }, 0);
        const activeLoaCount = licenseGroup?.reduce((acc, val) => {
          return (
            acc +
            val.licenseConfigurations?.loaDetails?.filter(
              (data) => data?.status === LoaStatus.ACTIVE
            ).length
          );
        }, 0);
        const status = getStatus(licenseGroup);
        usMapData[k as string] = {
          backgroundColor:
            status === LicenseStatus.Missing
              ? mapColor.MISSING
              : status === LicenseStatus.Inactive
              ? mapColor.INACTIVE
              : status === 'pending'
              ? mapColor.PENDING
              : mapColor.ACTIVE,
          textColor: [
            LicenseStatus.Missing,
            'pending',
            LicenseStatus.Inactive,
          ].includes(status)
            ? '#000000'
            : '#fff',
          tooltip: (
            <div>
              Active LOA's - {activeLoaCount} <br />
              DRLP's - {drlpsCount}
            </div>
          ),
        };
        return;
      }
      usMapData[k as string] = {
        backgroundColor: '#D1D1D1',
        textColor: '#989898',
        tooltip: '',
        cursor: 'default',
      };
    });
    setUsMapData(usMapData);
  }, [allLicenses]);

  useEffect(() => {
    const licenses = groupBy(allLicenses?.agencyLicenses || [], 'stateCode');
    const licenseGroup = licenses[selectedState];
    if (!licenseGroup) {
      return;
    } else {
      const name = licenseGroup?.[0]?.drlps?.map((data) =>
        getAssignedDrlp(data)
      );
      const status = getStatus(licenseGroup);
      const isBackgroundQnsAnswered = licenseGroup?.some(
        (d) => d?.bgQuestionsEngineLight
      );
      setSelectedRecords({
        drlpRecordName: name?.length ? name : null,
        agencyLicenses: licenseGroup,
        bgQuestionsEngineLight: isBackgroundQnsAnswered,
        state: licenseGroup?.[0]?.stateName,
        status: status,
        stateCode: licenseGroup?.[0]?.stateCode,
        bgColor:
          status === LicenseStatus.Missing
            ? mapColor.MISSING
            : status === LicenseStatus.Inactive
            ? mapColor.INACTIVE
            : status === 'pending'
            ? mapColor.PENDING
            : mapColor.ACTIVE,
        color: status !== LicenseStatus.Active ? '#000' : '#fff',
      });
    }
  }, [selectedState]);

  return (
    <div className="all-licenses-agency-map">
      <div className={`map-container ${!selectedRecords ? 'w-full' : ''}`}>
        {usMapData ? (
          <USMapView
            setSelectedState={setSelectedState}
            data={usMapData}
            height={selectedRecords ? 500 : 300}
            projectionScale={selectedRecords ? 900 : 600}
          />
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'center', gap: 48 }}>
          {mapTooltip.map((data) => (
            <div
              style={{
                display: 'flex',
                gap: 3,
                alignItems: 'center',
                textTransform: 'capitalize',
              }}
            >
              <p
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: data.color,
                }}
              ></p>
              <p>{data.title}</p>
            </div>
          ))}
        </div>
      </div>
      {selectedRecords ? (
        <div className="map-details-container">
          {' '}
          <div
            style={{
              display: 'flex',
              gap: 24,
              alignItems: 'center',
              width: '100%',
            }}
          >
            <p
              style={{
                fontSize: 18,
                lineHeight: '20px',
                fontWeight: 500,
                color: 'rgba(0, 31, 69, 1)',
              }}
            >
              {selectedRecords?.state || ''}
            </p>
            <p
              style={{
                ...(selectedRecords?.color
                  ? {
                      border: `1px solid ${selectedRecords.bgColor}`,
                      backgroundColor: selectedRecords.bgColor,
                      borderRadius: 16,
                      padding: '3px 12px',
                      fontSize: 12,
                      display: 'inline-block',
                      maxWidth: 'fit-content',
                      textTransform: 'capitalize',
                      color: selectedRecords.color,
                    }
                  : {}),
              }}
            >
              {selectedRecords?.status}
            </p>
            <p
              style={{ marginLeft: 'auto', marginRight: 12 }}
              className="override-icon-size"
            >
              <CloseCircleOutlined
                className="cursor-pointer"
                onClick={() => {
                  setSelectedState('');
                  setSelectedRecords(null);
                }}
              />
            </p>
          </div>
          <div>
            <div
              style={{
                fontSize: 12,
                color: selectedRecords?.drlpRecordName?.length
                  ? '#848484'
                  : '#ABABAB',
              }}
            >
              {selectedRecords?.drlpRecordName?.length
                ? 'DRLP’s Name:'
                : 'No DRLP’s Assigned'}
            </div>
            <div>
              <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 24 }}>
                {selectedRecords?.drlpRecordName?.map((data: string) => {
                  if (data) {
                    return (
                      <div
                        style={{
                          margin: '6px 0',
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: '20px',
                        }}
                      >
                        <LicensedProducer
                          text={data}
                          notClosable={true}
                          onClose={() => {}}
                          noMargin={true}
                        />
                      </div>
                    );
                  }
                })}
                {/* <p style={{ margin: '6px 0' }}>{data.lastName}</p> */}
              </div>
            </div>
          </div>
          <p
            style={{
              display: 'flex',
              gap: 3,
              fontWeight: 400,
              width: 'fit-content',
              height: 24,
              fontSize: 12,
              lineHeight: '20px',
              margin: '16px 0',
              alignItems: 'center',
              backgroundColor: selectedRecords?.bgQuestionsEngineLight
                ? '#D3E7FF'
                : 'rgba(252, 239, 207, 1)',
              border: `1px solid ${
                selectedRecords?.bgQuestionsEngineLight
                  ? '#437EE1'
                  : 'rgba(255, 201, 66, 1)'
              }`,
              padding: '6px 12px',
              color: 'rgba(29, 44, 80, 1)',
              borderRadius: 16,
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(
                RouteConstants.agencyGeneralProfile.path +
                  `?tab=${GeneralTabType.BACKGROUND_QUESTIONS}`
              );
            }}
          >
            <FileTextOutlined />
            Background Questions
          </p>
          <ListAgentDetails
            styles={{
              margin: '16px 0 8px',
              maxHeight: '360px',
              overflowY: 'auto',
            }}
            licenseDetails={selectedRecords?.agencyLicenses || []}
            listStyle={{ backgroundColor: '#ffffff' }}
            stateCode={selectedRecords?.stateCode}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AllLicensesAgencyMap;
