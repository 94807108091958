import { Button, Input, Modal, Typography, notification } from 'antd';

import { AgencyService } from '../../../../services/agency.service';
import { ErrorMessageConstants } from '../../../../constants/error-message.constants';
import { ErrorResponse } from '../../../../types/response/errorResponse.type';
import { IdConstants } from '../../../../constants/id.constants';
import { RouteConstants } from '../../../../constants/routes.constants';
import { StateGroup } from '../../../../types/data/stateGroup.types';
import { adminStore } from '../../../../stores/admin.store';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { getStateNameFromCode } from '../../../../utils/common.utils';
import { useAuth } from '../../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useState } from 'react';

enum ButtonType {
  MANAGE_AGENTS = 'Manage Producers and then create Territory',
  CREATE_STATE_GROUP = 'Create Territory',
}

type SaveStateGroupModalProps = {
  visible: boolean;
  handleVisibility: () => void;
  manageAssignments: boolean;
  loading: boolean;
  setLoading: () => void;
  stateGroup: StateGroup;
  setStepState: () => void;
  setNewStateGroup: (value: any) => void;
  isAgentAdded: () => boolean;
  duplicateStateGroup: () => void;
  isDuplicateLoading: boolean;
};

function SaveNewStateGroupModal(props: SaveStateGroupModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAgentLoading, setIsAgentLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };
  const { getAccessTokenSilently } = useAuth();
  const navigate = useNavigate();
  const [stateGroupName, setStateGroupName] = useState<string>();

  const validateStateGroupName = async (): Promise<boolean> => {
    let isValid = false;
    if (stateGroupName) {
      const token = await getAccessTokenSilently();
      const resp = await AgencyService.getStateGroupByName(
        stateGroupName,
        token
      ).catch((e: ErrorResponse) => {
        isValid = true;
      });
      if (resp) showError(ErrorMessageConstants.DUPLICATE_STATEGROUP_NAME);
    } else isValid = false;
    return isValid;
  };

  const handleSaveStateGroup = async (buttonClicked: ButtonType) => {
    if (buttonClicked === ButtonType.CREATE_STATE_GROUP) {
      if (props.isAgentAdded()) props.duplicateStateGroup();
      else adminStore.agency && createStateGroup(adminStore.agency.id);
    } else {
      setIsAgentLoading(true);
      const isSgNameValid = await validateStateGroupName();
      if (isSgNameValid) {
        props.setNewStateGroup(stateGroupName);
        props.setStepState();
        props.handleVisibility();
      }
      setIsAgentLoading(false);
    }
  };

  const handleClose = () => {
    setIsLoading(false);
    setIsAgentLoading(false);
    props.handleVisibility();
  };

  const validateStateGroupBeforeSave = (stateGroup: StateGroup): boolean => {
    //name should not be empty
    if (!stateGroupName) {
      showError('Please add a valid name for the territory');
      return false;
    }

    // atleast one state must be present
    if (stateGroup.stateLicenseConfigs.length === 0) {
      showError('Please add atleast one state');
      return false;
    }

    // no state should be empty
    return stateGroup.stateLicenseConfigs.every((stateItr) => {
      if (stateItr.licenseConfigs.length === 0) {
        showError(
          `Please add at least one license class to the state ${getStateNameFromCode(
            stateItr.stateCode
          )}`
        );
        return false;
      }
      return true;
    });
  };

  const createStateGroup = async (agencyId: string) => {
    try {
      if (validateStateGroupBeforeSave(props.stateGroup) && stateGroupName) {
        let resp: any;
        setIsLoading(true);
        if (adminStore.account) {
          const token = await getAccessTokenSilently();
          resp = await AgencyService.createStateGroup(
            stateGroupName,
            props.stateGroup.stateLicenseConfigs.map((d) => ({
              stateCode: d.stateCode,
              licenseConfigurations: d.licenseConfigs?.flatMap(
                (d) => d.loaids || ''
              ),
            })),
            token
          );
        }
        if (resp.status === 'SUCCESS') {
          setIsLoading(false);
          if (resp.data) {
            if (resp.data?.id) {
              appStateInfoStore.setHeader(stateGroupName);
              navigate(
                RouteConstants.editStateGroup.path.replace(':id', resp.data?.id)
              );
            } else navigate(RouteConstants.stateGroups.path);
          }
        }
      }
    } catch (error: any) {
      if (error?.error?.exceptionName === 'DuplicateValueException') {
        showError(
          ErrorMessageConstants.DUPLICATE_VALUE_ERROR(
            'Territory Name',
            error?.error?.value
          )
        );
      } else if (
        error?.error?.message ===
        'A stateGroup with one of the given names already exists'
      ) {
        showError(
          'Territory name already exists. Please choose a different name'
        );
      } else {
        showError(ErrorMessageConstants.COMMON);
      }
      setIsLoading(false);
    }
  };

  const loading =
    !stateGroupName || isAgentLoading || isLoading || props.isDuplicateLoading;

  return (
    <Modal
      width={600}
      open={props.visible}
      onCancel={handleClose}
      maskClosable={false}
      destroyOnClose
      centered
      style={{ boxShadow: 'none' }}
      footer={
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <Button
            id={
              IdConstants.STATE_GROUPS.SAVE_NEW_STATE_GROUP_MODAL.CANCEL +
              '-state-groups'
            }
            size="large"
            style={{
              width: '100%',
              marginLeft: '-5px',
            }}
            onClick={() => {
              handleClose();
            }}
            disabled={isLoading || isAgentLoading || props.isDuplicateLoading}
          >
            Cancel
          </Button>
          <Button
            id={
              IdConstants.STATE_GROUPS.SAVE_NEW_STATE_GROUP_MODAL.SAVE +
              '-state-groups'
            }
            htmlType="submit"
            size="large"
            className="button"
            style={{
              width: '100%',
              marginBottom: '10px',
              opacity: loading ? '50%' : '100%',
            }}
            onClick={() => {
              handleSaveStateGroup(ButtonType.CREATE_STATE_GROUP);
              appStateInfoStore.setCloneEnabled(true);
            }}
            loading={isLoading || props.isDuplicateLoading}
            disabled={loading}
          >
            Clone Territory
          </Button>
        </div>
      }
    >
      {contextHolder}
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        Are you sure you want to clone the territory?
      </Typography.Paragraph>
      <Typography.Paragraph
        style={{
          color: 'var(--grey-text)',
          fontSize: '14px',
          fontWeight: '400',
        }}
      >
        The new territory will save the indicated changes as a new territory
      </Typography.Paragraph>
      <div style={{ flexShrink: '0', marginTop: '25px' }}>
        <Typography.Title level={5} style={{ marginTop: '0' }}>
          New Territory Name <span style={{ color: 'red' }}>*</span>
        </Typography.Title>
        <Input
          id={
            IdConstants.STATE_GROUPS.SAVE_NEW_STATE_GROUP_MODAL.NEW_NAME +
            '-state-groups'
          }
          style={{ height: '50px', marginBottom: '10px' }}
          placeholder="Enter the territory name"
          value={stateGroupName}
          defaultValue={stateGroupName}
          onChange={(e) => setStateGroupName(e.target.value)}
          showCount
          maxLength={50}
        />
      </div>
    </Modal>
  );
}

export default SaveNewStateGroupModal;
