import { ConfigProvider, Radio, Space, Typography } from 'antd';
import {
  FilterOptions,
  FiltersType,
  LabelPairType,
} from '../../../../types/common/filters.type';

import React from 'react';

type RadioProps = {
  keyData: string;
  options: FilterOptions[];
  label: string;
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
};

const RadioButtonFilter: React.FC<RadioProps> = ({
  keyData,
  options,
  label,
  filters,
  setFilters,
}) => {
  const selectedValue = filters.data[label]?.labelValuePair || '';
  const handleRadioClick = (option: FilterOptions) => {
    const isOptionPresent = Object.values(filters.data).some((filter) => {
      if (filter.key === keyData) {
        if (!Array.isArray(filter.labelValuePair)) {
          return filter.labelValuePair.value === option.value;
        } else {
          return filter.labelValuePair.some(
            (item) => item.value === option.value
          );
        }
      }
      return false;
    });

    let updatedFilters: FiltersType;
    if (isOptionPresent) {
      const updatedData = Object.entries(filters.data).reduce(
        (acc, [filterKey, filterValue]) => {
          if (filterValue.key === keyData) {
            return acc;
          }
          return { ...acc, [filterKey]: filterValue };
        },
        {}
      );
      updatedFilters = { ...filters, data: updatedData };
    } else {
      updatedFilters = {
        data: {
          ...filters.data,
          [label]: {
            labelValuePair: {
              value: option.value,
              label: option.label || option.value,
            },
            key: keyData,
          },
        },
      };
    }

    setFilters(updatedFilters);
  };

  return (
    <div>
      <Typography.Paragraph
        style={{
          fontSize: '14px',
          color: 'var(--secondary-color)',
          fontWeight: 500,
        }}
      >
        {label}
      </Typography.Paragraph>
      <ConfigProvider theme={{ token: { colorPrimary: '#f7fcfc' } }}>
        <Radio.Group value={(selectedValue as LabelPairType).value}>
          <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
            {options.map((option: FilterOptions) => (
              <Radio
                key={option.label || option.value}
                style={{ fontSize: '14px', fontFamily: 'Poppins' }}
                value={option.value}
                onClick={() => handleRadioClick(option)}
              >
                {option.label || option.value}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </ConfigProvider>
    </div>
  );
};

export default RadioButtonFilter;
