import {
  AutoComplete,
  Card,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from 'antd';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import { AgentsDownlineUpline } from '../../../types/data/agencyDownline';
import { DownlineInviteService } from '../../../services/downline-invite.service';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { PageConstants } from '../../../constants/page.constants';
import ReviewInviteModal from '../../../utils/modal/uplines/review-invite/review-invite';
import { RoleType } from '../../../enums/roles.enum';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import { adminStore } from '../../../stores/admin.store';
import { applySort } from '../../../utils/common.utils';
import { isEmpty } from 'lodash';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;

function ViewInvitations() {
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(10);
  const [activeData, setActiveData] = useState<any>('');
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [agencyId, setAgencyId] = useState(adminStore.agency?.id);
  const { bearerToken } = useAuth();
  const [reviewInviteModal, setReviewInviteModal] = useState(false);
  const [inviteDetails, setInviteDetails] = useState<any>();
  const { getAccessTokenSilently } = useAuth();
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [sortValue, setSortValue] = useState({});

  const columns: ColumnsType<AgentsDownlineUpline> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'agencyDetails.name',
      sorter: true,

      render: (text: string, record: AgentsDownlineUpline) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                color: 'var(--secondary-color)',
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {text}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'agencyDetails.npn',
      sorter: true,

      render: (text: string) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: (
        <span>
          Invited On{' '}
          <Tooltip title="The invite expires in 30 days from start date!">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: 'invitedOn',
      key: 'createdAt',
      sorter: true,

      render: (text: string, record: AgentsDownlineUpline) => {
        const inviteDate = new Date(text).getTime();
        const currentDate = new Date().getTime();
        const millisecondsInDay = 1000 * 60 * 60 * 24;
        const daysDifference = Math.floor(
          (currentDate - inviteDate) / millisecondsInDay
        );

        const expiryDays = 30;
        const color = record.isExpired ? '#ff4d4f' : '#faad14';
        let content = '';
        if (daysDifference > 0 && daysDifference < expiryDays) {
          content = `Invite expires in ${expiryDays - daysDifference} ${
            daysDifference === 1 ? 'day' : 'days'
          }`;
        } else if (record.isExpired) {
          content = 'Invite Expired';
        }

        return (
          <Row align="middle" style={{ textAlign: 'center' }}>
            <Tooltip title={content}>
              <Typography.Paragraph
                style={{
                  color: color,
                  fontSize: '14px',
                  fontWeight: '400',
                }}
              >
                {text}
              </Typography.Paragraph>
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: 'Upline Type',
      dataIndex: 'type',
      key: 'type',

      render: (text: string) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      render: (record: AgentsDownlineUpline) => {
        const currentDate = new Date().getTime();
        const inviteDate = new Date(record.invitedOn).getTime();
        const millisecondsInDay = 1000 * 60 * 60 * 24;
        const daysDifference = Math.floor(
          (currentDate - inviteDate) / millisecondsInDay
        );
        const expiryDays = 30;
        const isInviteExpired = daysDifference > expiryDays;

        return (
          <Row
            align="middle"
            style={{ textAlign: 'center', cursor: 'pointer' }}
          >
            <Typography.Paragraph
              onClick={() => {
                if (!isInviteExpired) {
                  setInviteDetails({
                    id: record.id,
                    agencyName: record.name,
                    amountAllocated: record.total_amount_allocated,
                    expiresOn: record.expiresOn,
                    inviteToken: record.inviteToken,
                  });
                  setReviewInviteModal(true);
                }
              }}
              style={{
                color: isInviteExpired ? '#999' : '#000',
              }}
            >
              Review Invite <RightOutlined style={{ color: '#00a6fb' }} />
            </Typography.Paragraph>
          </Row>
        );
      },
    },
  ];

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchUplineIvitation(queryText || '', newPageNumber, newPageSize);
  };
  const handleInputChange = (e: any) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    adminStore.agency?.id && setAgencyId(adminStore.agency.id);
  }, [adminStore.account]);

  const fetchUplineIvitation = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    try {
      if (!agencyId) return;
      if (bearerToken) {
        setIsLoading(true);
        // const userEmail = user?.email ? user?.email : '';
        const response: any =
          await DownlineInviteService.getAgenciesUplineInvite(
            agencyId,
            pageSize,
            pageNumber,
            bearerToken,
            searchText,
            searchType ? searchType : '',
            { ...(sort || sortValue || {}) }
          );
        if (response) {
          setCount(response.count);
          setActiveData(response.uplineData);
        } else {
          setCount(0);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFiltersInfoForFindAll = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any =
          await DownlineInviteService.getUplineInvitationFilters(token);
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.UPLINE_INVITATIONS
          )
        );
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    bearerToken && agencyId && fetchUplineIvitation();
  }, [bearerToken, agencyId, queryText, searchType]);

  useEffect(() => {
    fetchFiltersInfoForFindAll();
  }, []);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <Card style={{ width: '100%', height: 'fit-content' }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        <div style={{ marginBottom: '10px' }}>
          <Typography.Text style={{ color: 'var(--primary-color)' }}>
            Allocated amount from the uplines will be added to your wallet
            directly
          </Typography.Text>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            disabled={isLoading}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-agency-uplines-my-invites'}
              size="middle"
              placeholder="Search Invites by Name and NPN"
              style={{ width: '100%', marginBottom: '10px' }}
              onSearch={handleOnSearch}
            />
          </AutoComplete>
        </div>

        <Table
          loading={isLoading}
          columns={columns}
          dataSource={activeData}
          pagination={false}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, fetchUplineIvitation);
            setSortValue(sort);
          }}
        />
        {!isEmpty(activeData) && (
          <Row
            className="pagination"
            style={{ marginTop: '10px' }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              pageSize={pageSize}
              onChange={onChange}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={isLoading}
            />
          </Row>
        )}
        <ReviewInviteModal
          visible={reviewInviteModal}
          setVisible={setReviewInviteModal}
          invitationDetails={inviteDetails}
        />
      </ConfigProvider>
    </Card>
  );
}

export default withRoles(ViewInvitations, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
