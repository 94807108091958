import { Card, Col, Row, Skeleton, Typography } from 'antd';
import { CardHeading, DashboardCard, TextDetail } from './dashboard.style';
import USMapView, { USMapViewDataProp } from '../../../components/usMap';
import { useEffect, useState } from 'react';

import AgentBiographicInfoCard from '../../../components/agentBiographicInfo/agentBiographicInfo';
import AgentPaymentConfig from './paymentConfig';
import { AgentService } from '../../../services/agent.service';
import { ProducerDetails } from '../../agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { RoleType } from '../../../enums/roles.enum';
import { isEmpty } from 'lodash';
import { useAuth } from '../../../auth/authProvider';
import { withRoles } from '../../../auth/useRoles';

function AgentDashboard() {
  const { bearerToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [mapLoader, setMapLoader] = useState(true);
  const [agentDetails, setAgentDetails] = useState<
    ProducerDetails | undefined
  >();
  const [autoRenew, setAutoRenew] = useState<any>();
  const [paymentConfigLoader, setPaymentConfigLoader] = useState(false);
  const [stateDataForMap, setStateDataForMap] = useState<USMapViewDataProp>({});

  useEffect(() => {
    getAgentDetails();
    getMapData();
  }, []);

  const getAgentDetails = async () => {
    try {
      setIsLoading(true);
      if (bearerToken) {
        const agentDetails: any = await AgentService.getCurrentAgent(
          bearerToken
        );
        setAgentDetails(agentDetails.data);
      }
    } catch (error) {
      console.error('error in getAgentDetails', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMapData = async () => {
    try {
      setMapLoader(true);
      if (bearerToken) {
        const licenseDetails: any = await AgentService.getLicenseStatus(
          bearerToken
        ).catch((error) => {
          console.error('Error:: ', error.message);
        });
        const licenseColor: { [key: string]: string } = {};
        licenseDetails?.forEach(
          (data: { stateCode: string; color: string }) => {
            licenseColor[data.stateCode] = data.color;
          }
        );
        const agentStateCustomization: any = {};
        Object.entries(licenseColor)?.forEach(([k, v]) => {
          agentStateCustomization[k] = {
            backgroundColor: v,
          };
        });

        setStateDataForMap(agentStateCustomization);
      }
    } catch (error) {
      console.error('getMapsData :: Error ::', error);
    } finally {
      setMapLoader(false);
    }
  };

  const onChange = async (checked: boolean) => {
    try {
      setPaymentConfigLoader(true);
      if (bearerToken) {
        const paymentConfig = {
          ...agentDetails?.paymentConfig,
          isAutoRenewalActive: checked,
        };
        await AgentService.updatePaymentConfigAutoRenew(
          paymentConfig,
          bearerToken
        );
        setAgentDetails((prev) => {
          if (prev)
            return {
              ...prev,
              paymentConfig: {
                ...prev.paymentConfig,
                isAutoRenewalActive: checked,
              },
            };
        });
      }
      setAutoRenew(checked);
    } catch (error) {
      console.error(`Error while updating payment config | Error : ${error}`);
    } finally {
      setPaymentConfigLoader(false);
    }
  };

  return (
    <>
      {isLoading || mapLoader ? (
        <div
          className="spin-style"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Skeleton active />
        </div>
      ) : (
        !isEmpty(agentDetails) && (
          <div style={{ gap: '10px', width: '100%' }}>
            <DashboardCard style={{ width: '100%' }}>
              <CardHeading>
                <TextDetail fontSize={'16px'} fontWeight={500}>
                  Biographic Info
                </TextDetail>
              </CardHeading>
              <AgentBiographicInfoCard agent={agentDetails} />
            </DashboardCard>

            <Row gutter={8} style={{ marginTop: 20, marginBottom: 20 }}>
              <Col span={24} className="stats-card">
                <Card style={{ height: '100%' }}>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <TextDetail fontSize={'16px'} fontWeight={500}>
                      Assignments
                    </TextDetail>
                  </Row>
                  <hr className="primary-line" style={{ marginTop: '20px' }} />
                  {isLoading || mapLoader ? (
                    <Skeleton active />
                  ) : (
                    <Row
                      style={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                      <USMapView data={stateDataForMap} />
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>

            <Card style={{ marginTop: 15, marginBottom: 15 }}>
              <Typography.Paragraph
                style={{ fontSize: '16px', fontWeight: 500 }}
              >
                Payment Configurations
              </Typography.Paragraph>
              <hr className="primary-line" style={{ marginTop: '20px' }} />
              {isLoading || mapLoader || paymentConfigLoader ? (
                <Skeleton active />
              ) : (
                <AgentPaymentConfig
                  autoRenew={autoRenew}
                  agent={agentDetails}
                  onChange={onChange}
                />
              )}
            </Card>
          </div>
        )
      )}
    </>
  );
}

export default withRoles(AgentDashboard, [RoleType.AGENT]);
