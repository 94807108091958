import AdminSignUpLayout from './signUp/layouts/signUpLayout';
import TextWithIcon from './common/textWithIcon/textWithIcon';
import { Typography } from 'antd';
import bottomScreen from '../assets/images/bottomScreen.svg';
import location from '../assets/icons/location.svg';
import mail from '../assets/icons/mail.svg';
import phone from '../assets/icons/phone.svg';

interface UnauthorizedLayoutProps {
  title: string;
  text: string;
}

function UnauthorizedLayout({ title, text }: UnauthorizedLayoutProps) {
  return (
    <AdminSignUpLayout>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          justifyItems: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '0 10%',
        }}
      >
        <img
          src={bottomScreen}
          alt=""
          style={{
            position: 'absolute',
            bottom: '-50%',
            left: '-5%',
            zIndex: '-1',
            width: '45%',
          }}
        />
        <div
          style={{
            marginTop: '10%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
          }}
        >
          <div style={{ flexBasis: '40%' }}>
            <Typography.Paragraph
              style={{
                fontWeight: '500',
                fontSize: '48px',
                color: 'var(--primary-color)',
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </Typography.Paragraph>
          </div>

          <div style={{ flexBasis: '60%' }}>
            <Typography.Paragraph
              style={{
                fontWeight: '400',
                fontSize: '28px',
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: text }}></div>
            </Typography.Paragraph>
            <div style={{ marginTop: '13%' }}>
              <TextWithIcon
                textStyle={{
                  fontWeight: '400',
                  fontSize: '24px',
                  marginTop: '-1%',
                }}
                text="sales@insurtek.com"
                icon={<img src={mail} alt="mail" />}
              />
            </div>
            <div style={{ marginTop: '5%' }}>
              <TextWithIcon
                textStyle={{
                  fontWeight: '400',
                  fontSize: '24px',
                }}
                text="+212 211334245"
                icon={<img src={phone} alt="phone" />}
              />
            </div>
            <div style={{ marginTop: '5%' }}>
              <TextWithIcon
                textStyle={{
                  fontWeight: '400',
                  fontSize: '24px',
                }}
                text="11 Dallas Ct, Greenwood Arkansas"
                icon={<img src={location} alt="location" />}
              />
            </div>
          </div>
        </div>
      </div>
    </AdminSignUpLayout>
  );
}

export default UnauthorizedLayout;
