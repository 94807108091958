import styled from 'styled-components';
interface TextDetailProps {
  textColor?: string;
  fontSize?: string;
  fontWeight?: number;
}
export const ModalContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 26px 20px;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0px;
  gap: 23px;
`;

export const HeadingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const TitleCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;
export const TextWrapper = styled.div<TextDetailProps>`
  color: ${(props) => props.textColor || '#222'};
  font-family: Poppins;
  font-size: ${(props) => props.fontSize || '10px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 24px; /* 150% */
`;
