import {
  Button,
  Card,
  Pagination,
  Row,
  Skeleton,
  Table,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';

import { AgentDetails } from '../../../../types/data/agentDetail.type';
import { ColumnsType } from 'antd/es/table';
import DiscardConfirmationModal from '../modals/discardChanges';
import { Name } from '../../../../types/data/name.type';
import { StateGroup } from '../../../../types/data/stateGroup.types';

const tableColumns: ColumnsType<AgentDetails> = [
  {
    title: 'Producer',
    dataIndex: 'name',
    render: (text: Name) => text.firstName + ' ' + text.lastName,
  },
  {
    title: 'NPN',
    dataIndex: 'npn',
  },
];

type TransferProps = {
  oldStateGroupAgents: AgentDetails[];
  oldStateGroupName: string;
  oldStateGroupId: string;
  newStateGroup: StateGroup;
  onClickNext: () => void;
  setNewStateGroupAgents: (value: AgentDetails[]) => void;
  onBack: () => void;
  savedAgentsToBeTransferred: AgentDetails[];
};

function TransferAgents(props: TransferProps) {
  const [discardModalVisibility, setDiscardModalVisibility] = useState(false);

  const [loading, setLoading] = useState(false);
  const [activeData, setActiveData] = useState<AgentDetails[]>(
    props.oldStateGroupAgents
  );
  const [leftSelectedIds, setLeftSelectedIds] = useState<string[]>([]);
  const [rightSelectedIds, setRightSelectedIds] = useState<string[]>([]);
  const [agentsToBeTransferred, setAgentsToBeTransferred] = useState<
    AgentDetails[]
  >([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [agentCount, setAgentCount] = useState(0);

  useEffect(() => {
    setAgentCount(activeData.length);
  }, [activeData]);

  //Transfer Controls Start
  const moveToRight = () => {
    const newRightAgents = activeData.filter((itr) =>
      leftSelectedIds.includes(itr.id)
    );
    const remainingLeftAgents = activeData.filter(
      (itr) => !leftSelectedIds.includes(itr.id)
    );
    setActiveData(remainingLeftAgents);
    setAgentsToBeTransferred([...agentsToBeTransferred, ...newRightAgents]);
  };

  const moveToLeft = () => {
    const newLeftAgents = agentsToBeTransferred.filter((itr) =>
      rightSelectedIds.includes(itr.id)
    );
    const remainingRightAgents = agentsToBeTransferred.filter(
      (itr) => !rightSelectedIds.includes(itr.id)
    );
    setAgentsToBeTransferred(remainingRightAgents);
    setActiveData([...activeData, ...newLeftAgents]);
  };

  const rowSelectionLeft = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AgentDetails[]) => {
      setLeftSelectedIds(selectedRowKeys as string[]);
    },
  };

  const rowSelectionRight = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AgentDetails[]) => {
      setRightSelectedIds(selectedRowKeys as string[]);
    },
  };
  //Transfer Controls End

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
  };

  useEffect(() => {
    const alreadyTransferred = props.savedAgentsToBeTransferred?.map(
      (data) => data.id
    );
    const isNotTransferred = props?.oldStateGroupAgents?.filter(
      (data) => !alreadyTransferred.includes(data.id)
    );
    setActiveData(isNotTransferred);
  }, [props.oldStateGroupAgents]);

  useEffect(() => {
    setAgentsToBeTransferred(props.savedAgentsToBeTransferred);
  }, [props.savedAgentsToBeTransferred]);

  return (
    <div style={{ width: '100%' }}>
      {loading ? (
        <Skeleton active />
      ) : (
        <div style={{ width: '100%', marginTop: '20px' }}>
          <Typography.Paragraph
            style={{
              color: 'var(--secondary-color)',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            Manage the agents you need in the new territory.
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{
              color: 'var(--grey-text)',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            These agents will be moved from the existing territory to the new
            territory{' '}
          </Typography.Paragraph>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'start',
              minHeight: '100%',
              flexGrow: '1',
              paddingTop: '20px',
            }}
          >
            <Card
              style={{ width: '45%' }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
                minHeight: '100%',
              }}
            >
              <Typography className="typography_1">
                {' '}
                Old Territory: {props.oldStateGroupName}
              </Typography>
              <Table
                rowKey={(record) => record.id}
                columns={tableColumns}
                dataSource={activeData}
                rowSelection={{ type: 'checkbox', ...rowSelectionLeft }}
                style={{ minHeight: '100%' }}
                pagination={false}
              />
              <Row justify="end">
                <Pagination
                  pageSize={pageSize}
                  current={pageNumber}
                  total={agentCount}
                  onChange={onPageChange}
                  showSizeChanger
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                />
              </Row>
            </Card>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 10px',
                alignSelf: 'center',
              }}
            >
              <Button type="primary" onClick={moveToRight}>
                Move to right
              </Button>
              <Button
                type="text"
                style={{ marginTop: 12 }}
                onClick={moveToLeft}
              >
                Move to left
              </Button>
            </div>
            <Card
              style={{ width: '45%' }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
                minHeight: '100%',
              }}
            >
              <Typography className="typography_1">
                New Territory: {props.newStateGroup.name}
              </Typography>
              <Table
                style={{ minHeight: '100%' }}
                rowKey={(record) => record.id}
                columns={tableColumns}
                dataSource={agentsToBeTransferred}
                rowSelection={{ type: 'checkbox', ...rowSelectionRight }}
                pagination={false}
              />
            </Card>
          </div>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              style={{ width: '10%' }}
              size="large"
              onClick={() => {
                props.onBack();
              }}
            >
              Back
            </Button>
            <Button
              size="large"
              className="button primary-button"
              onClick={() => {
                props.setNewStateGroupAgents(agentsToBeTransferred);
                props.onClickNext();
              }}
              style={{ width: '10%' }}
            >
              Next
            </Button>
          </div>
        </div>
      )}
      <DiscardConfirmationModal
        visible={discardModalVisibility}
        handleVisibility={() => {
          setDiscardModalVisibility(false);
        }}
      />
    </div>
  );
}

export default TransferAgents;
