import { Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FiltersType } from '../../../../types/common/filters.type';
import React from 'react';
import dayjs from 'dayjs';

type filterChipProps = {
  selectedFilters: FiltersType;
  removeFilters: (
    keyToRemove: string,
    valueToRemove: string,
    removeKey?: boolean
  ) => void;
};
export const FilterChip: React.FC<filterChipProps> = ({
  selectedFilters,
  removeFilters,
}) => {
  return (
    <>
      {Object.entries(selectedFilters.data).map(([key, value]) => {
        return (
          <Card
            className="filter-chip-card"
            style={{
              borderColor: 'var(--border-color)',
              textAlign: 'center',
              maxWidth: 'fit-content',
              display: 'flex',
              marginRight: '10px',
              alignItems: 'center',
              padding: '1px',
            }}
          >
            {Array.isArray(value.labelValuePair) ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Poppins',
                  marginRight: '10px',
                  marginLeft: '10px',
                  width: '100%',
                }}
              >
                <div style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                  {key} -{' '}
                </div>
                {value.labelValuePair.map((data, index) => (
                  <div style={{ display: 'flex' }} key={index}>
                    <Card
                      size="small"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Poppins',
                        marginRight: '5px',
                        width: '100%',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <span>
                        {!value.isDate ? (
                          data.label
                        ) : (
                          <span style={{ textTransform: 'capitalize' }}>
                            {' '}
                            {data.condition} :{' '}
                            <b>{dayjs(data.value).format('YYYY/MM/DD')}</b>
                          </span>
                        )}
                      </span>
                      {!value.isDate && (
                        <CloseOutlined
                          onClick={() => removeFilters(key, data.value)}
                          style={{
                            marginLeft: '10px',
                            marginRight: '5px',
                            visibility: 'visible',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </Card>
                  </div>
                ))}
                {value.labelValuePair.length > 1 && (
                  <CloseOutlined
                    onClick={() => removeFilters(key, '', true)}
                    style={{
                      marginLeft: '5px',
                      marginRight: '5px',
                      visibility: 'visible',
                      cursor: 'pointer',
                    }}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontFamily: 'Poppins',
                  alignItems: 'center',
                  width: '100%',
                  whiteSpace: 'nowrap',
                }}
              >
                {key} - {value.labelValuePair.label}
                <CloseOutlined
                  style={{
                    marginLeft: '10px',
                    marginRight: '5px',
                    visibility: 'visible',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    removeFilters(key, '', true);
                  }}
                />
              </div>
            )}
          </Card>
        );
      })}
    </>
  );
};
