import { Card, Typography } from 'antd';
import React, { useEffect } from 'react';

import { DragItemTypes } from '../../../../types/dnd/dragItem.types';
import IconDraggable from '../../../../assets/icons/iconDraggable.svg';
import { IdConstants } from '../../../../constants/id.constants';
import { LoaDetails } from '../../../../types/data/masterData';
import { LoaDragItem } from '../editStateGroup';
import { useDrag } from 'react-dnd';

interface DraggableLoaCardProps {
  loaDetails: LoaDetails;
  stateCode: string;
  lcCode: string;
  setCurrentLoaDragItem: (props: LoaDragItem) => void;
}

function DraggableLoaCard({
  loaDetails,
  stateCode,
  lcCode,
  setCurrentLoaDragItem,
}: DraggableLoaCardProps) {
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: DragItemTypes.LOA,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    []
  );

  useEffect(() => {
    isDragging &&
      setCurrentLoaDragItem({
        stateCode,
        lcCode,
        loaCode: loaDetails.loaCode,
        loaids: loaDetails.id || '',
      });
  }, [isDragging]);

  return (
    <Card
      id={
        IdConstants.STATE_GROUPS.DRAGGABLE_LOA_CARD +
        '-' +
        loaDetails.loaCode +
        '-state-groups'
      }
      onDrag={(e) => {
        e.stopPropagation();
      }}
      ref={dragRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
        fontSize: 25,
        fontWeight: 'bold',
        cursor: 'move',
        margin: '0 5px',
      }}
      bodyStyle={{
        padding: '1px 2px',
        display: 'flex',
        flexDirection: 'row',
        columnGap: '5px',
        borderRadius: '1px',
      }}
    >
      <Typography>{loaDetails.loa}</Typography>
      <img src={IconDraggable} />
    </Card>
  );
}

export default DraggableLoaCard;
