import { Button, Skeleton, Space, Tag, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  applySort,
  getStateNameFromCode,
  getTextColor,
  stringToColor,
} from '../../../utils/common.utils';

import AgentsByStateGroupModal from '../../agentByStateGroup/AgentsByStateGroupModal';
import type { ColumnsType } from 'antd/es/table';
import DeleteConfirmationModal from '../../../pages/stateGroups/editStateGroup/modals/deleteStateGroup';
import { IdConstants } from '../../../constants/id.constants';
import { RouteConstants } from '../../../constants/routes.constants';
import StatesCircled from '../statesCircled/StatesCircled';
import Table from 'antd/es/table';
import { UpdatedStateGroup } from '../../../types/data/stateGroup.types';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { useNavigate } from 'react-router';
import { useState } from 'react';

interface StateGroupTableProps {
  loader: boolean;
  stateGroups: UpdatedStateGroup[];
  refresh: () => void;
  setSortValue?: React.Dispatch<React.SetStateAction<any>>;
}

function StateGroupTable({
  loader,
  stateGroups,
  refresh,
  setSortValue,
}: StateGroupTableProps) {
  const [stateGroupId, setStateGroupId] = useState('');
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [isViewAgents, setIsViewAgents] = useState(false);
  const navigate = useNavigate();
  const Columns: ColumnsType<UpdatedStateGroup> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: '20%',
      render: (name, { id }) => {
        const bgColor = stringToColor(id);
        return (
          <Space size={[0, 8]} wrap key={name}>
            <Tag color={bgColor}>
              <span
                style={{
                  color: getTextColor(bgColor),
                }}
              >
                {name}
              </span>
            </Tag>
          </Space>
        );
      },
    },
    {
      title: 'States',
      dataIndex: 'states',
      key: 'states',
      width: '60%',
      render: (_, { stateLicenseConfigs }) => {
        return (
          <Space wrap>
            {stateLicenseConfigs.map((stateLicenseConfig) => {
              return (
                <StatesCircled
                  showTooltip
                  tooltipTitle={`${getStateNameFromCode(
                    stateLicenseConfig.stateCode
                  )}`}
                  label={stateLicenseConfig.stateCode}
                  key={stateLicenseConfig.stateCode}
                />
              );
            })}
          </Space>
        );
      },
    },
    {
      title: '',
      dataIndex: 'viewGroup',
      key: 'id',
      width: '20%',
      render: (_, { id }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            gap: 24,
            paddingRight: '20px',
          }}
        >
          <Tooltip title="Edit Territory">
            <Button
              id={IdConstants.STATE_GROUPS.VIEW_STATE_GROUP + '-state-groups'}
              className="button-secondary-color-important"
              onClick={() => {
                appStateInfoStore.setCloneEnabled(false);
                navigate(RouteConstants.editStateGroup.path.replace(':id', id));
              }}
            >
              {/* View Territories */}
              <EditOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Delete Territory">
            <Button
              // shape="circle"
              className="button important-action-button"
              onClick={() => {
                setStateGroupId(id);
                setDeleteModalVisibility(true);
              }}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        pagination={false}
        columns={Columns}
        loading={loader}
        dataSource={stateGroups}
        onChange={(pagination, filters, sorter) => {
          const sort = applySort(sorter, refresh);
          if (setSortValue) setSortValue(sort);
        }}
        style={{ overflowY: 'auto' }}
        className={IdConstants.STATE_GROUPS.STATE_GROUPS_TABLE_CLASS_NAME}
      />
      <DeleteConfirmationModal
        stateGroupId={stateGroupId}
        visible={deleteModalVisibility}
        viewAgents={() => {
          setIsViewAgents(true);
        }}
        handleVisibility={(succes?: boolean) => {
          setDeleteModalVisibility(false);
          if (succes) refresh();
        }}
      />
      {isViewAgents && (
        <AgentsByStateGroupModal
          stateGroupId={stateGroupId}
          isViewAgents={isViewAgents}
          close={() => setIsViewAgents(false)}
        />
      )}
    </>
  );
}
export default StateGroupTable;
