import './draggerComponent.css';

import { useEffect, useState } from 'react';

import Dragger from 'antd/es/upload/Dragger';
import Papa from 'papaparse';
import dropperLogo from '../../../assets/icons/dropperLogo.svg';
import googleSheetsLogo from '../../../assets/icons/googleSheetsLogo.svg';
import { notification } from 'antd';

interface DraggerProps {
  handleFileChange: (info: any, fileContent: any) => void;
  setIsUploadComplete: (isUploadComplete: boolean) => void;
  acceptedFileSize: number;
  acceptedFileTypes: string[];
  isFileUploaded: boolean;
  uploadedFileName: string;
  setUploadedFile?: React.Dispatch<React.SetStateAction<any>>;
}

const DraggerComponent = ({
  handleFileChange,
  setIsUploadComplete,
  acceptedFileSize,
  acceptedFileTypes,
  isFileUploaded,
  uploadedFileName,
  setUploadedFile,
}: DraggerProps) => {
  const [fileData, setFileData] = useState<File>();
  const [fileContent, setFileContent] = useState<string>('');
  const [isFileUploadedState, setIsFileUploadedState] =
    useState(isFileUploaded);

  useEffect(() => {
    setIsFileUploadedState(isFileUploaded);
  }, [isFileUploaded]);

  const formatFileSize = (size: number) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;

    if (size >= gigabyte) {
      return `${(size / gigabyte).toFixed(2)} GB`;
    } else if (size >= megabyte) {
      return `${(size / megabyte).toFixed(2)} MB`;
    } else if (size >= kilobyte) {
      return `${(size / kilobyte).toFixed(2)} KB`;
    } else {
      return `${size} B`;
    }
  };

  const handleBeforeUpload = (file: File) => {
    const fileType = file.type;
    const fileSize = file.size;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileContent = e.target.result;
      setFileContent(fileContent);
    };
    reader.readAsText(file);

    if (!acceptedFileTypes.includes(fileType)) {
      const supportedFormats = acceptedFileTypes.join(', ');
      notification.error({
        message: 'Error',
        description: `Unsupported file type. Supported file type(s): ${supportedFormats}`,
      });
      return false;
    }

    if (fileSize > acceptedFileSize) {
      const maxSizeFormatted = formatFileSize(acceptedFileSize);
      const fileSizeFormatted = formatFileSize(fileSize);
      notification.error({
        message: 'Error',
        description: `File size exceeds the maximum limit of ${maxSizeFormatted}. Current file size: ${fileSizeFormatted}`,
      });
      return false;
    }

    if (setUploadedFile) {
      const excludeColumns = ['s no', 'reason (optional)'];
      Papa.parse(file, {
        header: true,
        transform: (value, column: string) => {
          // Exclude unwanted columns
          if (excludeColumns.includes(column)) {
            return undefined; // Exclude the column
          }

          // Transform the value if needed
          return value;
        },
        beforeFirstChunk: (chunk) => {
          // Split the headers
          const lines = chunk.split('\n');

          // Modify the headers
          const modifiedHeaders = lines[0]
            .split(',')
            .map((header) => transformHeader(header));
          // .filter((header) => !excludeColumns.includes(header));

          // Replace the original headers with modified headers
          lines[0] = modifiedHeaders.join(',');

          // Join the lines back together
          const modifiedChunk = lines.join('\n');

          return modifiedChunk;
        },
        complete: (results: any) => {
          setUploadedFile(results.data);
        },
        error: (error) => {
          console.error('Error:', error.message);
        },
      });
    }

    setFileData(file);

    setIsFileUploadedState(isFileUploaded);
    return true;
  };

  const transformHeader = (header: string) => {
    const lowercaseHeader = header.trim().toLowerCase();

    // Additional rules for specific headers
    switch (lowercaseHeader) {
      case 'ssn':
        return 'ssn';
      case 'npn':
        return 'npn';
      case 'lastname':
        return 'lastName';
      case 'email':
        return 'email';
      case 'name':
        return 'name';
      case 'License Number':
        return 'licenseNumber';

      // Add more rules as needed
      default:
        // If no specific rule, return the lowercase header
        return lowercaseHeader;
    }
  };

  const handleVerifyData = async () => {
    try {
      const response = { success: true };
      setIsUploadComplete(response.success);
    } catch (error) {
      const response = { failure: false };
      setIsUploadComplete(response.failure);
    }
  };

  const handleFileUpload = (info: any) => {
    handleFileChange(info, fileContent);
    handleVerifyData();
  };

  const dummyRequest = (onSuccess: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 1000);
  };
  return (
    <Dragger
      accept=".csv,.xls,.xlsx"
      beforeUpload={handleBeforeUpload}
      onChange={handleFileUpload}
      multiple={false}
      customRequest={({ onSuccess }) => {
        dummyRequest(onSuccess);
      }}
      className="custom-dragger"
      style={{
        height: '70px',
      }}
    >
      <p className="ant-upload-drag-icon">
        {isFileUploadedState ? (
          <img
            src={googleSheetsLogo}
            alt="googleSheetsLogo"
            style={{ width: '56px', height: '154px', marginBottom: '-52px' }}
          />
        ) : (
          <img src={dropperLogo} alt="dropperLogo" />
        )}
      </p>
      <p className="ant-upload-hint" style={{ fontWeight: 500 }}>
        {isFileUploadedState ? (
          <span>{uploadedFileName}</span>
        ) : (
          <>
            Drag and drop File here or{' '}
            <span className="upload-hint-choose-file">Choose File</span>
          </>
        )}
      </p>
      {isFileUploadedState && (
        <p className="ant-upload-hint" style={{ fontWeight: 500 }}>
          <span className="upload-hint-choose-file">Replace File</span>
        </p>
      )}
    </Dragger>
  );
};

export default DraggerComponent;
