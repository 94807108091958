import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { ModalType, userDetails } from './OwnershipDetails';
import {
  OWNERSHIP_TYPE_FIELDS,
  OWNERSHIP_TYPE_TITLE,
} from './OwnershipDetailsCard';
import dayjs, { isDayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';

import { Card } from 'antd';
import { Popover } from 'antd';
import { formatDate } from '../../../utils/date.utils';

interface OwnershipDetailsType extends userDetails {
  onPopoverCta: (userDetails: userDetails, action?: ModalType) => void;
  isModalOpen: boolean;
}

const OwnershipDetailsDisplayCard: React.FC<OwnershipDetailsType> = (props) => {
  const popOverRef = useRef<HTMLDivElement>(null);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const { onPopoverCta, isModalOpen, ...userDetails } = props;
  const {
    firstName,
    npn,
    ownershipPercent,
    title,
    dateOfBirth,
    type,
    firmOrLastName,
  } = userDetails;
  const details = [
    { title: 'NPN', value: npn },
    { title: 'Ownership Percentage', value: ownershipPercent + '%' },
    { title: 'Title', value: title },
    ...(type === OWNERSHIP_TYPE_FIELDS.PRODUCER
      ? [
          {
            title: 'Date of Birth',
            value: dateOfBirth ? formatDate(dateOfBirth as string) : '',
          },
        ]
      : [{ title: '', value: '' }]),
  ];

  useEffect(() => {
    if (isModalOpen) {
      setPopUpOpen(false);
    }
  }, [isModalOpen]);

  return (
    <Card style={{ marginBottom: 12 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
        <div
          style={{
            display: 'flex',
            gap: 8,
            border: '1px',
            borderRight: '2px solid #E6E6E6',
            width: 300,
            flexWrap: 'wrap',
          }}
        >
          {' '}
          <div
            style={{
              width: 78,
              height: 78,
              borderRadius: '50%',
              display: 'grid',
              placeContent: 'center',
              backgroundColor: '#EDF9FF',
              fontSize: 32,
              color: '#0074B0',
              fontWeight: 'bolder',
            }}
          >
            {type === OWNERSHIP_TYPE_FIELDS.AGENCY ? (
              firmOrLastName?.[0]
            ) : (
              <>
                {' '}
                {firstName?.[0] || ''}
                {firmOrLastName?.[0] || ''}
              </>
            )}
          </div>
          <div style={{ marginTop: 6, marginRight: 24 }}>
            <div
              style={{
                fontSize: 18,
                fontWeight: '500',
                wordBreak: 'break-word',
              }}
            >
              {type === OWNERSHIP_TYPE_FIELDS.AGENCY ? (
                firmOrLastName
              ) : (
                <>
                  {firstName} {firmOrLastName}
                </>
              )}
            </div>
            <div style={{ marginTop: 6 }}>
              <UserAddOutlined style={{ marginRight: 3 }} />{' '}
              {type === OWNERSHIP_TYPE_FIELDS.AGENCY
                ? OWNERSHIP_TYPE_TITLE.BUSINESS
                : OWNERSHIP_TYPE_TITLE.INDIVIDUAL}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            backgroundColor: '#EDF9FF',
            padding: '16px 24px',
            flex: 1,
            alignItems: 'center',
            borderRadius: 12,
            gap: 48,
            alignSelf: 'flex-start',
          }}
        >
          {details.map((data) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  gap: 3,
                }}
              >
                {' '}
                <div style={{ color: '#4F9EFF' }}> {data.title}</div>{' '}
                <div>
                  {' '}
                  {data.title === 'Date of Birth' && isDayjs(data.value)
                    ? dayjs(data.value, 'MM/DD/YYYY').toString()
                    : data.value?.toString()}
                </div>{' '}
              </div>
            );
          })}
        </div>
        <div>
          <Popover
            placement="bottomRight"
            // {...(isModalOpen ? { open: false } : {})}
            open={popUpOpen}
            rootClassName="ownership-details-popover"
            content={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                }}
                ref={popOverRef}
                onMouseLeave={(e) => {
                  setPopUpOpen(false);
                }}
              >
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    onPopoverCta(
                      {
                        ...userDetails,
                      },
                      'edit'
                    )
                  }
                  style={{
                    border: '1px',
                    padding: '0 12px 8px',
                    borderBottom: '1px solid #4F9EFF',
                  }}
                >
                  <EditOutlined
                    style={{
                      marginRight: 6,
                    }}
                  />
                  Edit Ownership Details
                </div>
                <div
                  onClick={() => {
                    onPopoverCta(userDetails, 'delete');
                  }}
                  className="cursor-pointer"
                  style={{ padding: '0 12px' }}
                >
                  <DeleteOutlined style={{ marginRight: 6 }} />
                  Delete
                </div>
              </div>
            }
          >
            <div
              style={{
                transform: 'rotate(90deg)',
                fontSize: '1.2rem', // equivalent to text-2xl
                height: '40px',
                cursor: 'pointer',
              }}
              onMouseEnter={() => setPopUpOpen(true)}
              onMouseLeave={(e) => {
                if (!popOverRef.current) {
                  setPopUpOpen(false);
                }
              }}
            >
              . . .
            </div>
          </Popover>
        </div>
      </div>
    </Card>
  );
};

export default OwnershipDetailsDisplayCard;
