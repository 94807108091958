import { Button, Tooltip, Typography } from 'antd';
import {
  EyeOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  LicenseApplicationStatus,
  LicenseApplicationStatusLabels,
} from '../../enums/licenseApplicationStatus.enum';

import { ApplicationStatusEnum } from '../../enums/applicationStatus.enum';

function ApplicationStatusCell({
  applicationStatus,
  showViewDetails = true,
  showError = false,
}: {
  applicationStatus: string;
  showViewDetails?: boolean;
  showError?: boolean;
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        ...(applicationStatus?.toLocaleLowerCase() !==
          ApplicationStatusEnum.Not_Applied && { cursor: 'pointer' }),
      }}
    >
      <Typography.Paragraph
        style={{
          fontSize: '14px',
          fontWeight: 500,
          display: 'flex',
          gap: 6,
          marginBottom: 0,
          minWidth: 'fit-content',

          color:
            applicationStatus?.toLocaleLowerCase() ===
            ApplicationStatusEnum.Declined
              ? '#F00'
              : applicationStatus?.toLocaleLowerCase() ===
                ApplicationStatusEnum.PENDING
              ? '#FF8616'
              : applicationStatus?.toLocaleLowerCase() ===
                ApplicationStatusEnum.Success
              ? '#0A1'
              : 'var(--primary-color)',
        }}
      >
        {showError && (
          <Tooltip title="Error Occurred while submitting application">
            <WarningOutlined />
          </Tooltip>
        )}{' '}
        {LicenseApplicationStatusLabels[
          applicationStatus as LicenseApplicationStatus
        ] || applicationStatus}{' '}
        {showError && (
          <Tooltip title="Navigate to the 'Needs Attention' section to re-apply for the license">
            <InfoCircleOutlined style={{ marginRight: '10px' }} />
          </Tooltip>
        )}
      </Typography.Paragraph>
      {showViewDetails &&
        applicationStatus?.toLocaleLowerCase() !==
          ApplicationStatusEnum.Not_Applied && (
          <div
            className="ml-auto reset-icon-size font-16"
            style={{ marginLeft: '10%' }}
          >
            <Tooltip title="View Details">
              <Button className="button primary-button ml-auto">
                <EyeOutlined />
              </Button>
            </Tooltip>
          </div>
        )}
    </div>
  );
}

export default ApplicationStatusCell;
