import qs, { ParsedQs } from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCallback } from 'react';

type UseQueryStateReturnType = [string | undefined, (value: string) => void];

export const useQueryState = (query: string): UseQueryStateReturnType => {
  const location = useLocation();
  const history = useNavigate();
  let existingQueries: ParsedQs | undefined;

  if (location.search) {
    existingQueries = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }

  const setQuery = useCallback(
    (value: string) => {
      if (existingQueries) {
        const queryString = qs.stringify(
          { ...existingQueries, [query]: value },
          { skipNulls: true }
        );

        history(`${location.pathname}?${queryString}`);
      } else {
        // If there are no existing queries, create a new query object with the specified parameter.
        const queryString = qs.stringify(
          { [query]: value },
          { skipNulls: true }
        );
        history(`${location.pathname}?${queryString}`);
      }
    },
    [history, location, query, existingQueries]
  );

  return [
    (existingQueries as ParsedQs)?.[query] as string | undefined,
    setQuery,
  ];
};
