import {
  BankOutlined,
  CrownOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';

import CustomIcon from '../components/common/customiseIcon/customIcon';
import { IconConstants } from './icons.constants';
import actionableLicensesIcon from '../assets/icons/actionableLicense.svg';
import adminIcon from '../assets/icons/admin.svg';
import agencyIcon from '../assets/icons/agency.svg';
import applicationsIcon from '../assets/icons/applications.svg';
import assignmentsIcon from '../assets/icons/assignments.svg';
import bgQuestionsIcon from '../assets/icons/bgQuestions.svg';
import dashboardIcon from '../assets/icons/dashboard.svg';
import downlineIcon from '../assets/icons/downline.svg';
import inviteDownlineIcon from '../assets/icons/inviteDownline.svg';
import uplineIcon from '../assets/icons/upline.svg';
import watchlistIcon from '../assets/icons/watchlist.svg';

export type RouteType = {
  path: string;
  headerText: string;
  key: string;
  icon?: JSX.Element;
  tooltip?: string;
  helmetText?: string;
};

export const RouteConstants = {
  rootRedirect: {
    path: '/',
    headerText: 'Home',
    key: 'home-redirect',
  },
  signup: {
    path: '/signup',
    headerText: 'Sign up',
    key: 'sign-up',
    helmetText: 'Create your account',
  },
  forgotPassword: {
    path: '/forgot-password',
    headerText: 'Forgot Password',
    key: 'forgot-password',
    helmetText: 'Forgot Password',
  },
  magicLink: {
    path: '/magic-link/:id',
    headerText: 'Email Verification',
    key: 'magic-link',
    helmetText: 'Email Verification',
  },
  myAgencyManageAssignments: {
    path: '/agency/manage-assignments',
    headerText: 'Manage assignments',
    key: 'agency-manage-assignments',
    helmetText: 'My Agency | Manage Assignments',
  },
  stripeRedirection: {
    path: '/payment-redirect',
    headerText: 'Payment Validation',
    key: 'payment-redirect',
    helmetText: 'Payment Validation',
  },
  onboarding: {
    path: '/onboarding',
    headerText: 'Onboarding',
    key: 'onboarding',
    helmetText: 'Onboarding',
  },
  agentSignup: {
    path: '/agentSignup',
    headerText: 'agentSignup',
    key: 'x',
    helmetText: 'Create your account',
  },
  agentDashBoard: {
    path: '/dashboard',
    headerText: 'Home',
    key: 'agent-dashboard',
    icon: <CustomIcon icon={dashboardIcon} />,
    helmetText: 'My Home',
  },
  allAgents: {
    path: '/home',
    headerText: 'Home',
    key: 'admin-overview',
    icon: <CustomIcon icon={IconConstants.agencyOverview} />,
    tooltip:
      'Explore the perspective of your agency, highlighting the quantity of producers in each state and the tasks that demand your focus.',
    helmetText: 'Home ',
  },
  onboardAgents: {
    path: '/producers/onboard',
    headerText: 'Producer Onboarding',
    key: 'admin-onboard-agents',
    icon: <CustomIcon icon={IconConstants.onboard} />,
    tooltip:
      'Seamlessly onboard producers in 5 simple steps—ideal for both individual producers and batches',
    helmetText: 'Onboard | Producers',
  },
  onboardIndividualProducer: {
    path: '/agents/onboard/individual-onboarding',
    headerText: 'Producer Onboarding',
    key: 'admin-onboard-agents',
    icon: <CustomIcon icon={IconConstants.onboard} />,
    tooltip:
      'Seamlessly onboard producers in 5 simple steps—ideal for both individual producers and batches',
    helmetText: 'Onboard | Producers',
  },
  offboardAgents: {
    path: '/producers/offboard',
    headerText: 'Producer Offboarding',
    key: 'admin-offboard-agents',
    icon: <CustomIcon icon={IconConstants.offboard} />,
    tooltip:
      "Select producers from the list and click 'Offboard' to remove them from the agency. They will no longer be visible in the system",
    helmetText: 'Offboard | Producers',
  },
  agentOverview: {
    path: '/agent/:agentId/home',
    headerText: '',
    key: 'admin-portal_agent-overview',
    icon: <CustomIcon icon={IconConstants.agentOverview} />,
    helmetText: 'Home | Producer',
  },
  agentActivity: {
    path: '/agent/:agentId/activity',
    headerText: '',
    key: 'admin-portal_agent-activity',
    icon: <CustomIcon icon={IconConstants.agentActivity} />,
    helmetText: 'Activity | Producer',
  },
  agencySupportDocuments: {
    path: '/agent/:agentId/support-documents',
    headerText: 'Support Documents',
    key: 'admin-portal_agent-support-documents',
    icon: <CustomIcon icon={IconConstants.supportingDocuments} />,
    helmetText: 'Supporting Documents',
  },
  agentSupportDocuments: {
    path: '/supporting-documents',
    headerText: 'Support Documents',
    key: 'agent-support-documents',
    icon: <CustomIcon icon={IconConstants.supportingDocuments} />,
    helmetText: 'Supporting Documents',
  },
  agentOverviewLicense: {
    path: '/agent/:agentId/needs-attention',
    headerText: '',
    key: 'admin-portal_agent-license',
    icon: <CustomIcon icon={IconConstants.licenses} />,
    helmetText: 'Needs Attention | My Producer',
  },
  agentOverviewAllLicenses: {
    path: '/agent/:agentId/all-licenses',
    headerText: '',
    key: 'admin-portal_agent-overview-all-license',
    icon: <CustomIcon icon={IconConstants.licenses} />,
    helmetText: 'All Licenses | My Producer',
  },
  agentOverviewPendingApplications: {
    path: '/agent/:agentId/pending-applications',
    headerText: '',
    key: 'admin-portal_agent-overview-pending-applications',
    icon: <CustomIcon icon={IconConstants.licenses} />,
    helmetText: 'Pending Applications | My Producer',
  },
  inviteDownline: {
    path: '/agency/downlines/invite',
    headerText: 'Invite Downline',
    key: '1.5',
    icon: <CustomIcon icon={inviteDownlineIcon} />,
    tooltip:
      'Easily invite a downline to join your agency hierarchy. Simply verify and send the invite – a straightforward process. Upon acceptance, the downline seamlessly integrates into your hierarchy',
    helmetText: 'Invite Downline | Agency',
  },
  removeDownline: {
    path: '/agency/downlines/remove',
    headerText: 'Remove Downline',
    key: '1.5',
    icon: <CustomIcon icon={inviteDownlineIcon} />,
    helmetText: 'Remove Downline | Agency',
  },
  agentPortalRTS: {
    path: '/appointments',
    headerText: 'Appointments',
    key: 'agent-rts',
    icon: <CustomIcon icon={IconConstants.agents} />,
    tooltip:
      'Ready-to-sell reports are the reports that contain the existing appointments and state licenses of an producer, offering admins a quick access to comprehensive insights',
    helmetText: 'Appointments',
  },
  agentRTS: {
    path: '/producers/appointments',
    headerText: 'Appointments',
    key: 'admin-agent-wise-rts',
    icon: <CustomIcon icon={IconConstants.rts} />,
    tooltip:
      'Ready-to-sell reports are the reports that contain the existing appointments and state licenses of an producer, offering admins a quick access to comprehensive insights',
    helmetText: 'Appointments | Producers',
  },
  RTSViewAgentsFromOverView: {
    path: '/agent/:agentId/appointments',
    headerText: 'Appointments',
    key: 'admin-portal_agent-rts',
    icon: <CustomIcon icon={IconConstants.rts} />,
    helmetText: 'Appointments | Agent',
  },
  RTSViewAgentsFromTable: {
    path: '/appointments/agent-wise/:agentId/details',
    headerText: 'Appointments',
    key: 'admin-portal_agent-details-rts',
    icon: <CustomIcon icon={IconConstants.agents} />,
    helmetText: 'Agent Wise | Appointments | Producers',
  },
  agencyRTS: {
    path: '/agency/appointments',
    headerText: 'Appointments',
    key: 'admin-agency-wide-rts',
    icon: <CustomIcon icon={IconConstants.rts} />,
    tooltip:
      'Ready-to-sell reports are the reports that contain the existing appointments and state licenses of an producer, offering admins a quick access to comprehensive insights',
    helmetText: 'Appointments | Agency',
  },

  agentLicenses: {
    path: '/agent-licenses',
    headerText: 'Producer Licenses',
    key: 'agent-licenses',
    helmetText: 'Licenses',
  },
  agentAllLicenses: {
    path: '/all-licenses',
    headerText: 'All Licenses',
    key: 'agent-all-licenses',
    icon: <CustomIcon icon={IconConstants.licenses} />,
    helmetText: 'All Licenses',
  },
  agentActionableLicenses: {
    path: '/needs-attention',
    headerText: 'Needs Attentions',
    key: 'agent-actionable-licenses',
    icon: <CustomIcon icon={actionableLicensesIcon} />,
    helmetText: 'Needs Attention',
  },
  agentApplications: {
    path: '/pending-applications',
    headerText: 'Pending Applications',
    key: 'agent-applications',
    icon: <CustomIcon icon={applicationsIcon} />,
    helmetText: 'Pending Applications',
  },
  agenctSupportDocuments: {
    path: '/support-documents',
    headerText: 'Support Documents',
    key: 'agent-support-documents',
    icon: <CustomIcon icon={bgQuestionsIcon} />,
    helmetText: 'Supporting Documents',
  },
  agencyLicenses: {
    path: '/producers/all-licenses',
    headerText: 'All Licenses',
    key: 'admin-all-licenses',
    icon: <CustomIcon icon={IconConstants.licenses} />,
    helmetText: 'All Licenses | Producers',
  },
  licensesAgency: {
    path: '/agency/licenses',
    headerText: 'Licenses',
    key: 'agency-licenses',
    icon: <CustomIcon icon={IconConstants.licenses} />,
    helmetText: 'Licenses | Agency',
  },
  // actionable changed into 'Needs Attention',
  agencyActionableLicenses: {
    path: '/producers/needs-attention',
    headerText: 'Needs Attention',
    key: 'admin-attention-required-licenses',
    icon: <CustomIcon icon={actionableLicensesIcon} />,
    helmetText: 'Needs Attention | Producers',
  },
  renewalLicenses: {
    path: '/producers/upcoming-renewals',
    headerText: 'Upcoming Renewals',
    key: 'admin-renewal-licenses',
    icon: <FileSyncOutlined />,
    helmetText: 'Upcoming Renewals | Producers',
  },
  agencyAgentApplications: {
    path: '/producers/pending-applications',
    headerText: 'Pending Applications',
    key: 'admin-agent-applications',
    icon: <CustomIcon icon={applicationsIcon} />,
    tooltip:
      'Track license applications effortlessly. A centralized space to monitor application status and types for streamlined management',
    helmetText: 'Pending Applications | Producers',
  },
  agencyApplications: {
    path: '/agency/pending-applications',
    headerText: 'Pending Applications',
    key: 'admin-agency-applications',
    icon: <CustomIcon icon={applicationsIcon} />,
    tooltip:
      'Track license applications effortlessly. A centralized space to monitor application status and types for streamlined management',
    helmetText: 'Pending Applications | Agency',
  },
  stateGroups: {
    path: '/agency/territories',
    headerText: 'Territories',
    key: 'admin-state-groups',
    icon: <CustomIcon icon={IconConstants.stateGroups} />,
    tooltip:
      'Simplify assignments by creating a territory. Add states, LCs, and LOAs in one go, streamlining the producer onboarding process and ongoing assignments effortlessly',
    helmetText: 'Territories | Agency',
  },
  createStateGroup: {
    path: '/territories/create/',
    headerText: 'Create Territory',
    key: '3',
    icon: <CustomIcon icon={IconConstants.stateGroups} />,
    tooltip:
      'Select and drag the Territory to the right column, then drag and merge License Classes into the state cell. Remove additional LOAs under each LC by clicking the cross sign in the cell',
    helmetText: 'Create | Territory',
  },
  editStateGroup: {
    path: '/territories/edit/:id',
    headerText: 'Edit Territory',
    key: '3',
    icon: <CustomIcon icon={IconConstants.stateGroups} />,
    helmetText: 'Edit | Territory',
  },
  agencyGeneralProfile: {
    path: '/agency/details',
    headerText: 'Details',
    key: 'admin-agency-general',
    icon: <CustomIcon icon={agencyIcon} />,
    helmetText: 'Details | Agency',
  },
  agencyHierarchy: {
    path: '/agency/organization',
    headerText: 'Organization',
    key: 'admin-agency-hierarchy',
    icon: <CustomIcon icon={IconConstants.hierarchy} />,
    helmetText: 'Organization | Agency',
  },
  agencyAdminProfile: {
    path: '/my-account/account',
    headerText: 'Account',
    key: 'admin-settings-admin-profile',
    icon: <CustomIcon icon={IconConstants.user} />,
    helmetText: 'Account | My Account',
  },
  agentProfile: {
    path: '/my-account/account',
    headerText: 'Account',
    key: 'agent-settings-agent-profile',
    icon: <CustomIcon icon={IconConstants.user} />,
    helmetText: 'Account | My Account',
  },
  downlines: {
    path: '/agency/downlines',
    headerText: 'Downlines',
    key: 'admin-agency-downline',
    icon: <CustomIcon icon={downlineIcon} />,
    helmetText: 'Downlines | Agency',
  },
  uplines: {
    path: '/agency/uplines',
    headerText: 'Uplines',
    key: 'admin-agency-upline',
    icon: <CustomIcon icon={uplineIcon} />,
    helmetText: 'Uplines | Agency',
  },
  invite_uplines: {
    path: '/agency/uplines/invite',
    headerText: 'Invitations',
    key: 'admin-agency-upline',
    icon: <CustomIcon icon={uplineIcon} />,
    helmetText: 'Upline Invites | Agency',
  },
  invite_downlines: {
    path: '/agency/downlines/invitation',
    headerText: 'Downline Invitations',
    key: 'admin-agency-downline',
    icon: <CustomIcon icon={downlineIcon} />,
    helmetText: 'Downline Invites | Agency',
  },
  blacklist: {
    path: '/agency/watchlist',
    headerText: 'Watchlist',
    key: 'admin-watchlist',
    icon: <CustomIcon icon={watchlistIcon} />,
    tooltip:
      'Prevent any malicious activity by blacklisting an producer or agency. Note that once an entity is watchlisted, the action is irreversible for enhanced platform security',
    helmetText: 'Watchlist | Agency',
  },
  admins: {
    path: '/setup/users',
    headerText: 'Users',
    key: 'admin-settings-users',
    icon: <CustomIcon icon={adminIcon} />,
    tooltip:
      'Manage platform access for your agency by inviting new users and overseeing existing users with an option to revoke their access',
    helmetText: 'Users | Setup',
  },
  invite_admins: {
    path: '/setup/users/invitations',
    headerText: 'Users',
    key: 'admin-settings-users',
    icon: <CustomIcon icon={adminIcon} />,
    tooltip:
      'View and manage invited Users, with the option to revoke access as needed',
    helmetText: 'User Invites | Setup',
  },
  agentPortal: {
    path: '/agent-portal',
    headerText: 'Producer Portal',
    key: '',
    helmetText: 'Producer Portal',
  },
  agentPortalLicenses: {
    path: '/agent-portal/licenses',
    headerText: 'Producer Portal Licenses',
    icon: <CustomIcon icon={IconConstants.licenses} />,
    key: '',
    helmetText: 'Producer Portal Licenses',
  },
  agentSettings: {
    path: '/agent-portal/settings',
    headerText: 'Producer Portal Settings',
    key: '',
    helmetText: 'Producer Portal Settings',
  },
  backgroundQuestions: {
    path: '/background-questions',
    headerText: 'Background Questions',
    key: 'agent-background-questions',
    icon: <FileTextOutlined />,
    helmetText: 'Background Questions',
  },
  agencyBackgroundQuestions: {
    path: '/agent/:agentId/background-questions',
    headerText: 'Background Questions',
    key: 'agency-background-questions',
    icon: <FileTextOutlined />,
    helmetText: 'Background Questions | Agency',
  },
  employmentHistory: {
    path: '/employment-history',
    headerText: 'Employment History',
    key: 'agent-employment-history',
    icon: <CustomIcon icon={IconConstants.employmentHistory} />,
    helmetText: 'Employment History',
  },
  agencyEmploymentHistory: {
    path: '/agent/:agentId/employment-history',
    headerText: 'Employment History',
    key: 'agency-employment-history',
    icon: <CustomIcon icon={IconConstants.employmentHistory} />,
    helmetText: 'Employment History | Producer',
  },
  notifications: {
    path: '/notifications',
    headerText: 'Notifications',
    key: '',
    helmetText: 'Notifications',
  },
  billing: {
    path: '/setup/billing',
    headerText: 'Billing',
    key: 'billing',
    icon: <CustomIcon icon={IconConstants.billing} />,
    tooltip: 'Billing and Subscriptions',
    helmetText: 'Billing | Setup',
  },
  wallet: {
    path: '/wallet',
    headerText: 'Wallet',
    key: 'wallet',
    icon: <CustomIcon icon={IconConstants.billing} />,
    tooltip:
      "Insuretrek's integrated financial management platform. Easily top up your wallet, keep track of balances, and effortlessly view transaction history all in one convenient location.",
    helmetText: 'Wallet',
  },
  manageAssignments: {
    path: '/producers/manage-assignments',
    headerText: 'Manage Assignments',
    key: 'admin-agent-assignments',
    icon: <CustomIcon icon={assignmentsIcon} />,
    tooltip:
      "Efficiently assign, manage, and delete states and territories for producers under your agency's administration",
    helmetText: 'Assignments | Producers',
  },
  importAgents: {
    path: '/agents/onboard-agents/multiple-onboard/import-agents',
    headerText: 'Import Producers',
    key: '',
    helmetText: 'Multiple onboarding | Producers',
  },
  verifyCsv: {
    path: '/agents/onboard-agents/multiple-onboard/verify-csv',
    headerText: 'Import Producers',
    key: '',
    helmetText: 'Multiple onboarding | Producers',
  },
  applicationLicenses: {
    path: '/licenses/application',
    headerText: 'Applications',
    key: '',
    tooltip:
      'Track license applications effortlessly. A centralized space to monitor application status and types for streamlined management',
    helmetText: 'License Applications',
  },
  bulkAddressUpdate: {
    path: '/agents/general/update-address',
    headerText: 'Update Producer Details',
    key: 'admin-agency-general',
    icon: <CustomIcon icon={agencyIcon} />,
    helmetText: 'Bulk Update | Producers',
  },
  niprTransaction: {
    path: '/agency/licensing-transactions',
    headerText: 'Licensing Transactions',
    key: 'admin-agency-nipr-transactions',
    icon: <ReconciliationOutlined />,
    helmetText: 'Licensing Transactions | Agency',
  },

  pageNotFound: {
    path: '/page-not-found',
    headerText: 'Page Not Found',
    key: 'page-not-found',
    icon: <CustomIcon icon={agencyIcon} />,
    helmetText: 'Page not found',
  },
  agentTransactions: {
    path: '/agents/transactions',
    headerText: 'Producer Transactions',
    key: 'x',
    helmetText: 'Transactions',
  },
  authUserInvite: {
    path: '/user-invite',
    headerText: 'User Invite',
    key: 'x',
    helmetText: 'User Invite',
  },
  authDownlineInvite: {
    path: '/downline-invite',
    headerText: 'Downline Invite',
    key: 'x',
    helmetText: 'Downline Invite',
  },
  invites: {
    path: '/my-account/team-invites',
    headerText: 'Team Invites',
    key: 'invites',
    helmetText: 'Team Invites | My Account',
    icon: <CustomIcon icon={agencyIcon} />,
  },
};
