import {
  Button,
  Card,
  Form,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Table,
  Typography,
  message,
  notification,
} from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { formatDateWithTime, isXDaysOld } from '../../utils/date.utils';
import { isEmpty, isString } from 'lodash';

import AddressDetailModal from '../../utils/modal/general/update-agent-address-details';
import { AgencyService } from '../../services/agency.service';
import { AgentBiographicInfo } from '../../components/agentBiographicInfo/agentBiographicInfo';
import { AgentService } from '../../services/agent.service';
import { ColumnsType } from 'antd/es/table';
import { ErrorMessageConstants } from '../../constants/error-message.constants';
import { RoleType } from '../../enums/roles.enum';
import StripeOnboardingRequiredToast from '../../components/common/stripeOnboardingRequiredToast';
import { SuccessMessageConstants } from '../../constants/succes-message.constants';
import { TableType } from '../../enums/tableType.enum';
import { TooltipWrapper } from '../agents/agentsOverview/agentOverview/paymentConfig';
import UpdateAddressModal from '../../utils/modal/general/update-bulk-address';
import { UpdateAgentAddressType } from '../../types/data/updateAgentAddress';
import { adminStore } from '../../stores/admin.store';
import { getNameString } from '../../utils/name.utils';
import { isErrorStripeOnboardingRequired } from '../../utils/errorUtils';
import { useAuth } from '../../auth/authProvider';
import { withRoles } from '../../auth/useRoles';

const { Search } = Input;

function BulkAddressUpdate() {
  const tableType = TableType.AGENCY_UPLINE_INVITATION;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(10);
  const [activeData, setActiveData] = useState<any>([]);
  const [searchText, setSearchText] = useState('');
  const [agencyId, setAgencyId] = useState(adminStore.agency?.id);
  const { bearerToken } = useAuth();
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [updateAddressModal, setUpdateAddressModal] = useState(false);
  const [isUpdateAll, setIsUpdateAll] = useState(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [modalError, setModalError] = useState('');
  const [updateMultipleAgents, setUpdateMultipleAgents] = useState(false);
  const [agentIdstoBeUpdated, setAgentIdstoBeUpdated] = useState<any>([]);
  const [singleAgentrecord, setSingleAgentRecord] = useState<any>('');
  const [selectedAgentsCount, setSelectedAgentsCount] = useState<string[]>([]);
  const [agentDetails, setAgentDetails] = useState<AgentBiographicInfo[]>([]);

  const [api, contextHolder] = notification.useNotification();
  const showError = (errorMessage: ReactNode) => {
    api['error']({
      message: 'Error',
      description: errorMessage,
      className: 'bulk-address-notification',
    });
  };

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: UpdateAgentAddressType[]
    ) => {
      setSelectedAgentsCount(selectedRows.map((data) => data.id));
      if (selectedRows.length > 0) {
        setUpdateMultipleAgents(true);
      } else {
        setUpdateMultipleAgents(false);
      }

      setAgentIdstoBeUpdated(selectedRowKeys);
    },
    getCheckboxProps: (record: UpdateAgentAddressType) => ({
      disabled:
        record.name === 'Disabled User' ||
        (record.addressLastUpdatedAt &&
          !isXDaysOld(record.addressLastUpdatedAt, 3)), // Column configuration not to be checked
      name: record.name,
    }),
  };
  const UpdateAddressAgentColumns: ColumnsType<UpdateAgentAddressType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',

      render: (text: string, record: UpdateAgentAddressType) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                fontWeight: 500,
                marginBottom: 0,
              }}
            >
              {text}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
    {
      title: 'Producer Type',
      dataIndex: 'type',
      key: 'type',

      render: (text: string) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
              textAlign: 'center',
              marginBottom: 0,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'NPN Number',
      dataIndex: 'npn',
      key: 'npn',

      render: (text: string) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            // onClick={openModalCallback}
            style={{
              fontSize: '14px',
              fontWeight: 400,
              marginBottom: 0,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Address last updated at',
      dataIndex: 'npn',
      key: 'npn',

      render: (text: string, record: UpdateAgentAddressType) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
              marginBottom: 0,
            }}
          >
            {record?.addressLastUpdatedAt
              ? formatDateWithTime(record?.addressLastUpdatedAt)
              : `-`}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      render: (record: UpdateAgentAddressType) => {
        return (
          <Row
            align="middle"
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              pointerEvents: updateMultipleAgents ? 'none' : 'auto',
              marginBottom: 0,
            }}
          >
            {!updateMultipleAgents && (
              <TooltipWrapper
                children={
                  <Button
                    onClick={() => handleRowClick(record)}
                    size="middle"
                    disabled={
                      updateMultipleAgents ||
                      (record.addressLastUpdatedAt &&
                        !isXDaysOld(record.addressLastUpdatedAt, 3))
                    }
                  >
                    Update Addresses
                  </Button>
                }
                isDisabled={
                  record.addressLastUpdatedAt &&
                  !isXDaysOld(record.addressLastUpdatedAt, 3)
                }
                message="Address update has been submitted for review to the State department. Please await further updates."
                key={record.npn}
              ></TooltipWrapper>
            )}
          </Row>
        );
      },
    },
  ];
  const handleRowClick = (record: UpdateAgentAddressType) => {
    // Store the selected row data
    setSingleAgentRecord(record.id);
    setIsUpdateModalVisible(true); // Open the modal
  };

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setAgentIdstoBeUpdated([]);
    setSelectedAgentsCount([]);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
  };

  const handleInputChange = (e: any) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    adminStore.agency?.id && setAgencyId(adminStore.agency.id);
  }, [adminStore.account]);

  const fetchAgentDetails = async (agencyId: string) => {
    try {
      if (bearerToken) {
        setIsLoading(true);
        const response: any = await AgentService.getAgentsForHierarchyViewTable(
          pageSize,
          pageNumber,
          searchText,
          bearerToken
        );
        if (response) {
          setCount(response.count);

          setActiveData(response.agentData);
          setAgentDetails(response.allAgentDetails);
        } else {
          setCount(0);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bearerToken && agencyId && fetchAgentDetails(agencyId);
  }, [bearerToken, pageNumber, pageSize, tableType, agencyId]);

  const onFinish = async (values: any) => {
    try {
      setButtonLoading(true);
      if (
        values.mailingAddress === true ||
        values.bussinessAddress ||
        values.fax ||
        values.phone ||
        values.email
      ) {
        if (bearerToken && agencyId) {
          const resp = await AgencyService.getMyAgencyInfo(bearerToken);
          const addressDict: any = {};
          resp.data?.stateAddresses?.forEach((stateAddress: any) => {
            if (stateAddress.state === resp.data.homeState) {
              stateAddress.addresses.forEach((stateAddress: any) => {
                addressDict[stateAddress.addressTypeCode] = stateAddress;
              });
            }
          });

          const agencyInformation: any = {
            name: resp?.data?.name,
            npn: resp?.data?.npn,
            fein: resp?.data?.fein,
            address: addressDict,
            email: resp?.data?.email,
            phone: resp?.data?.phone,
            fax: resp?.data?.fax,
            registeredState: resp?.data?.residentStateCode,
          };

          const dateTobeUpdated: any = {};
          if (values.mailingAddress || values.bussinessAddress) {
            dateTobeUpdated.stateAddresses = {
              addresses: [],
            };
          }

          if (!isUpdateAll) {
            dateTobeUpdated.agentIds = !isEmpty(agentIdstoBeUpdated)
              ? agentIdstoBeUpdated
              : [singleAgentrecord];
          }
          if (values.fax) {
            dateTobeUpdated.fax = agencyInformation.fax;
          }
          if (values.phone) {
            dateTobeUpdated.phone = agencyInformation.phone;
          }
          if (values.email) {
            dateTobeUpdated.businessEmail = agencyInformation.email;
          }
          if (values.bussinessAddress) {
            dateTobeUpdated.stateAddresses.addresses.push(
              agencyInformation.address[2]
            );
          }
          if (values.mailingAddress) {
            dateTobeUpdated.stateAddresses.addresses.push(
              agencyInformation.address[3]
            );
          }

          const UpdateAgents: any = await AgencyService.UpdateBulkAgentDetails(
            dateTobeUpdated,
            bearerToken,
            isUpdateAll
          );
          if (UpdateAgents.status === 'SUCCESS') {
            setAgentIdstoBeUpdated([]);
            setSingleAgentRecord('');
            message.success(SuccessMessageConstants.AGENT_DETAILS_UPDATED);
            setUpdateMultipleAgents(false);
            setSelectedAgentsCount([]);
            setUpdateAddressModal(false);
            form.resetFields();
            setIsUpdateAll(false);
          }
        }
      } else {
        showError(ErrorMessageConstants.CHOOSE_ONE_OPTION);
      }
    } catch (error: any) {
      if (isErrorStripeOnboardingRequired(error.response.data))
        showError(<StripeOnboardingRequiredToast />);
      else {
        const errorMessage = error?.response?.data?.error?.message;
        if (Array.isArray(errorMessage)) {
          const message = errorMessage.map((error: any, index: number) => {
            if (isString(error)) return error;
            return (
              <p key={index}>
                <b>{getNameString(error?.name)}</b> - {error?.error?.message}
              </p>
            );
          });
          showError(message);
        } else {
          showError(ErrorMessageConstants.CUSTOM_ERROR(errorMessage));
        }
      }
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Card style={{ width: '100%', height: 'fit-content' }}>
      {contextHolder}
      <div style={{ marginBottom: '10px' }}>
        <Typography.Paragraph
          style={{
            fontSize: '16px',
            fontWeight: 500,
            color: 'var(--secondary-color)',
          }}
        >
          Manage producer's contact details and Addresses
        </Typography.Paragraph>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 10,
        }}
      >
        {/* <Search
          size="middle"
          placeholder="Search By Name or NPN"
          style={{ width: '100%', marginBottom: '10px' }}
          onChange={handleInputChange}
          value={searchText}
          // allowClear
          onPressEnter={() => {
            agencyId && fetchAgentDetails(agencyId);
          }}
          onSearch={() => {
            agencyId && fetchAgentDetails(agencyId);
          }}
          disabled={isLoading}
        /> */}
        <Button
          onClick={() => {
            setIsUpdateAll(true);
            setUpdateAddressModal(true);
          }}
          className="button-secondary-color-important"
          disabled={
            isLoading ||
            !!selectedAgentsCount.length ||
            buttonLoading ||
            !activeData.length
          }
        >
          Update All
        </Button>
      </div>

      {selectedAgentsCount.length > 0 && (
        <div
          style={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              Update contact and addresses for selected producers
            </Typography.Text>

            <Typography.Text style={{ fontSize: '12px', color: '#92A69E' }}>
              {agentIdstoBeUpdated.length} Producer
              {agentIdstoBeUpdated.length > 1 ? 's' : ''} Selected
            </Typography.Text>
          </div>
          <Button
            onClick={() => setIsUpdateModalVisible(!isUpdateModalVisible)}
            type="primary"
          >
            Update
          </Button>
        </div>
      )}

      {isLoading ? (
        <Skeleton active />
      ) : (
        <Table
          rowKey={(record) => record.id}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={UpdateAddressAgentColumns}
          dataSource={activeData}
          pagination={false}
        />
      )}
      {!isEmpty(activeData) && (
        <Row className="pagination" style={{ marginTop: '10px' }} justify="end">
          <Pagination
            defaultCurrent={1}
            total={count}
            pageSize={pageSize}
            onChange={onChange}
            showSizeChanger
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            disabled={isLoading}
          />
        </Row>
      )}
      <UpdateAddressModal
        visible={updateAddressModal}
        onCancelCb={() => {
          setIsUpdateAll(false);
        }}
        setVisible={setUpdateAddressModal}
        onFinish={onFinish}
        form={form}
        isLoading={buttonLoading}
        error={modalError}
        setError={setModalError}
        isUpdateAll={isUpdateAll}
      />
      {isUpdateModalVisible && (
        <AddressDetailModal
          visible={isUpdateModalVisible}
          selectedAgents={
            selectedAgentsCount.length
              ? agentDetails.filter((data) =>
                  selectedAgentsCount.some((id) => id.includes(data.id))
                )
              : agentDetails.filter((data) => data.id === singleAgentrecord)
          }
          isMultiSelect={!!selectedAgentsCount.length}
          onCancel={() => {
            setIsUpdateModalVisible(false);
          }}
          onNext={() => {
            setIsUpdateModalVisible(false);
            setUpdateAddressModal(true);
          }}
          form={form1}
        />
      )}
    </Card>
  );
}

export default withRoles(BulkAddressUpdate, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
