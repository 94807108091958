import CsvOffboard from './csvOffboard/csvOffboard';
import IndividualOffboard from './individualOffboard/individualOffboard';
import { RoleType } from '../../../enums/roles.enum';
import TabView from '../../../components/common/tabview/tabview';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { withRoles } from '../../../auth/useRoles';

function OffboardAgents() {
  const [selectedTab, setSelectedTab] = useState('1');

  const handleTabChange = (selectedTab: string) => {
    setSelectedTab(selectedTab);
  };

  const options = [
    {
      label: 'Offboarding',
      key: '1',
    },
    {
      label: 'CSV Offboarding',
      key: '2',
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <TabView
        options={options}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
      {selectedTab === '1' && <IndividualOffboard />}
      {selectedTab === '2' && <CsvOffboard />}
    </div>
  );
}

export default withRoles(observer(OffboardAgents), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
