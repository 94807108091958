import styled from 'styled-components';

interface TextDetailProps {
  textColor?: string;
  fontSize?: string;
  fontWeight?: number;
}
export const HeadingCard = styled.div`
  display: flex;
  height: 51px;
  padding: 0px 32px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #dff4f5;
  background: #f7fcfc;
`;

export const TextDetail = styled.div<TextDetailProps>`
  color: ${(props) => props.textColor || '#222'};
  font-family: Poppins;
  font-size: ${(props) => props.fontSize || '10px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 20px; /* 200% */
`;

export const QuestionCard = styled.div`
  display: flex;
  padding: 16px 32px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border: 1px solid #dff4f5;
  justify-content: space-between;
  background: #fff;
`;
