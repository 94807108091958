import { Avatar, Card, Checkbox, Row, Tooltip, Typography } from 'antd';

import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ResidencyType } from '../../../enums/residencyType.enum';
import { getResidentToolTip } from '../../../utils/table-manager/residentType.utils';

type AssignStatesCellProps = {
  isChecked: boolean;
  name: string;
  id: string;
  price?: string;
  setCurrentValue?: any;
  toggleIsChecked: any;
  currentValue?: string;
  columnName?: 'loa' | 'lc' | 'state' | 'territory';
  disableCheck?: boolean;
  disabled?: boolean;
  field?: string;
  residentType?: string;
  currentState: string;
  homeState?: string[];
  fromStateGroup?: boolean;
  disableTheStateCardForRLicenses?: boolean;
};

function AssignStatesCell({
  name,
  id,
  isChecked,
  price,
  setCurrentValue,
  toggleIsChecked,
  currentValue,
  columnName,
  disableCheck,
  disabled,
  field,
  residentType,
  currentState,
  homeState,
  fromStateGroup,
  disableTheStateCardForRLicenses,
}: AssignStatesCellProps) {
  const isCellDisabled = (): boolean => {
    if (disableTheStateCardForRLicenses) {
      return true;
    }
    if (!disableCheck && fromStateGroup) {
      return true;
    }
    if (disabled && !fromStateGroup) {
      return true;
    }
    if (
      homeState?.includes(currentState) &&
      homeState.length > 1 &&
      !fromStateGroup
    ) {
      return false;
    } else if (
      !homeState?.includes(currentState) &&
      residentType === ResidencyType.Resident &&
      !fromStateGroup
    ) {
      return true;
    } else if (
      homeState?.includes(currentState) &&
      residentType === ResidencyType.NonResident
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Card
      id={'assign-state-check-' + id + (field ? '-' + field : '')}
      onClick={setCurrentValue}
      style={{
        ...(id === currentValue
          ? {
              backgroundColor: 'var(--selection-color)',
              border: 'none',
            }
          : { border: 'none' }),
      }}
      hoverable={true}
      className="hover-card state-groups-card"
    >
      <Row
        align="middle"
        style={{
          // border: '1px solid var(--border-color)',
          display: 'flex',
        }}
      >
        {!disableCheck && (
          <div style={{ width: '10%', marginBottom: '1em' }}>
            <Checkbox
              name={columnName + '-as-states-chbx-' + id}
              disabled={isCellDisabled()}
              checked={isChecked}
              onChange={(e: CheckboxChangeEvent) => {
                if (!isCellDisabled()) toggleIsChecked();
              }}
            />
          </div>
        )}
        <div
          style={
            columnName === 'loa' && price
              ? { width: '70%', textAlign: 'left' }
              : { width: '90%', textAlign: 'left' }
          }
        >
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
              marginLeft: '15px',
              display: 'flex',
              justifyContent: 'space-between',
              opacity: isCellDisabled() ? 0.8 : 1,
              color: isCellDisabled() ? '#999' : 'inherit',
            }}
            onClick={() => {
              if (!isCellDisabled()) toggleIsChecked();
            }}
          >
            {isCellDisabled() ? (
              <Tooltip
                title={
                  disableTheStateCardForRLicenses
                    ? 'This state cannot be selected as there are no matching license classes available.'
                    : !homeState?.includes(currentState) &&
                      residentType === ResidencyType.Resident
                    ? 'This configuration cannot be applied to the producer as it is designated for residents'
                    : homeState?.includes(currentState) &&
                      residentType === ResidencyType.NonResident
                    ? 'This configuration cannot be applied to the producer as it is designated for non-residents'
                    : ''
                }
              >
                {name}
              </Tooltip>
            ) : (
              name
            )}
            <div style={{ display: 'flex', gap: '10px' }}>
              {residentType && residentType !== ResidencyType.NotApplicable ? (
                <Tooltip title={getResidentToolTip(residentType)}>
                  <Avatar
                    style={{
                      backgroundColor: '#D3F3F5',
                      color: '#000000',
                      width: '26px',
                      height: '26px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      justifyItems: 'center',
                      marginRight: '4px',
                      cursor: 'default',
                    }}
                  >
                    {residentType || <span>-</span>}
                  </Avatar>
                </Tooltip>
              ) : residentType ? (
                <>
                  <Tooltip title={getResidentToolTip(ResidencyType.Resident)}>
                    <Avatar
                      style={{
                        backgroundColor: '#D3F3F5',
                        color: '#000000',
                        width: '26px',
                        height: '26px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        justifyItems: 'center',
                        marginRight: '0',
                        cursor: 'default',
                      }}
                    >
                      R
                    </Avatar>
                  </Tooltip>
                  <Tooltip
                    title={getResidentToolTip(ResidencyType.NonResident)}
                  >
                    <Avatar
                      style={{
                        backgroundColor: '#D3F3F5',
                        color: '#000000',
                        width: '26px',
                        height: '26px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        justifyItems: 'center',
                        marginLeft: '0',
                        // marginRight: '4px',
                        cursor: 'default',
                      }}
                    >
                      NR
                    </Avatar>
                  </Tooltip>
                </>
              ) : null}
            </div>
          </Typography.Paragraph>
        </div>
        {columnName === 'loa' && price && (
          <div style={{ width: '20%' }}>
            <Typography.Paragraph
              style={{
                fontSize: '10px',
                fontWeight: 400,
                color: 'var(--important-color)',
                marginLeft: '15px',
              }}
            >
              {' '}
              ({price}$)
            </Typography.Paragraph>
          </div>
        )}
      </Row>
    </Card>
  );
}

export default AssignStatesCell;
