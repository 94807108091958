import { CustomModalProps } from './agencyLicensingInterfaces.type';
import { Modal } from 'antd';
import React from 'react';
import checkboxCircle from '../../assets/images/checkbox-circle-line.png';

const CustomModal: React.FC<CustomModalProps> = ({
  visible,
  onClose,
  stateCount,
}) => {
  return (
    <Modal
      open={visible}
      footer={null}
      centered
      closable={false}
      width={464}
      bodyStyle={{
        padding: '62px 52px',
        height: '395px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <img src={checkboxCircle} alt="Checkbox Circle" />
        <p
          style={{
            width: '70px',
            height: '28px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px',
            color: '#07212D',
            margin: 0,
          }}
        >
          All Set!
        </p>
        <p
          style={{
            width: '359px',
            height: '20px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            color: '#99ACB5',
            margin: 0,
          }}
        >
          You've added {stateCount} states to your agency.
        </p>
        <button
          style={{
            width: '359px',
            height: '36px',
            borderRadius: '8px',
            backgroundColor: '#001F45',
            color: '#FFFFFF',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            cursor: 'pointer',
            border: 'none',
            marginTop: '20px',
          }}
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default CustomModal;
