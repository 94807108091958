import { Card, Form, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';

import { AddressDetails } from '../../../types/data/addressDetails.type';
import AddressInput from '../../../components/common/addressInput';
import { AgentBiographicInfo } from '../../../components/agentBiographicInfo/agentBiographicInfo';
import { TextDetail } from './update-bulk-address.style';
import { getNameString } from '../../name.utils';

interface AddressDetailModalProps {
  visible: boolean;
  onCancel: () => void;
  onNext: () => void;
  selectedAgents: AgentBiographicInfo[];
  form: any;
  isMultiSelect: boolean;
}

type addressType = {
  business: AddressDetails;
  mailing: AddressDetails;
};

const BUSINESS = 'business';
const MAILING = 'mailing';

const AddressDetailModal: React.FC<AddressDetailModalProps> = ({
  visible,
  form,
  onCancel,
  onNext,
  isMultiSelect,
  selectedAgents,
}) => {
  const [address, setAddress] = useState<addressType>({
    business: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressType: '',
      addressTypeCode: '',
      city: '',
      country: '',
      dateUpdated: '',
      state: '',
      zip: '',
    },
    mailing: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressType: '',
      addressTypeCode: '',
      city: '',
      country: '',
      dateUpdated: '',
      state: '',
      zip: '',
    },
  });

  const updateAgent = (agent: AgentBiographicInfo) => {
    const address = agent?.stateAddresses?.filter(
      (state: any) => state.state === agent?.homeState
    );
    const business = address?.[0]?.addresses?.filter(
      (data) => data.addressType === 'Business'
    )?.[0];
    const mailing = address?.[0]?.addresses?.filter(
      (data) => data.addressType === 'Mailing'
    )?.[0];
    setAddress({
      business,
      mailing,
    });

    form.setFieldsValue({
      [`${BUSINESS}addressLine1`]: business?.addressLine1,
      [`${BUSINESS}addressLine2`]: business?.addressLine2,
      [`${BUSINESS}addressLine3`]: business?.addressLine3,
      [`${BUSINESS}city`]: business?.city,
      [`${BUSINESS}state`]: business?.state,
      [`${BUSINESS}zipcode`]: business?.zip,
      [`${BUSINESS}country`]: business?.country,
      [`${MAILING}addressLine1`]: mailing?.addressLine1,
      [`${MAILING}addressLine2`]: mailing?.addressLine2,
      [`${MAILING}addressLine3`]: mailing?.addressLine3,
      [`${MAILING}city`]: mailing?.city,
      [`${MAILING}state`]: mailing?.state,
      [`${MAILING}zipcode`]: mailing?.zip,
      [`${MAILING}country`]: mailing?.country,
    });
  };

  useEffect(() => {
    const agent = selectedAgents?.[0];
    updateAgent(agent);
  }, [selectedAgents]);

  return (
    <Modal
      destroyOnClose={true}
      width={780}
      open={visible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={onNext}
      okText="Next"
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <TextDetail textColor={'#0588ca'} fontWeight={500} fontSize={'16px'}>
          Update Contact and Address Information
        </TextDetail>
        <TextDetail
          fontWeight={400}
          fontSize={'14px'}
          style={{ paddingTop: 6, paddingBottom: 12 }}
        >
          View the producer's current address and contact information
        </TextDetail>
        {isMultiSelect && (
          <div
            style={{
              // paddingTop: 3,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Select
              defaultValue={getNameString(selectedAgents?.[0]?.name)}
              onChange={(id) => {
                const selectedAgent = selectedAgents.filter(
                  (data) => data.id === id
                )?.[0];
                updateAgent(selectedAgent);
              }}
              style={{ width: 180 }}
              options={selectedAgents?.map((data) => {
                const agentName = getNameString(data?.name);
                return { label: agentName, value: data?.id };
              })}
            />
          </div>
        )}
        <Card
          style={{
            backgroundColor: '#FCFCFC',
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <Form
            layout="vertical"
            onFinish={() => {
              form.resetFields();
            }}
            form={form}
          >
            <div
              style={{
                display: 'flex',
                gap: 24,
                justifyContent: 'space-between ',
              }}
            >
              <AddressInput
                viewOnly={true}
                addressDetails={{
                  addressTypeCode: '',
                  addressType: '',
                  addressLine1: address?.business?.addressLine1,
                  addressLine2: address?.business?.addressLine2,
                  addressLine3: address?.business?.addressLine3,
                  city: address?.business?.city,
                  state: address?.business?.state,
                  zip: address?.business?.zip,
                  country: address?.business?.country,
                }}
                addressType={BUSINESS}
                isGeneral={true}
                style={{ width: '50%', display: 'block' }}
              />
              <AddressInput
                viewOnly={true}
                addressDetails={{
                  addressTypeCode: '',
                  addressType: '',
                  addressLine1: address?.mailing?.addressLine1,
                  addressLine2: address?.mailing?.addressLine2,
                  addressLine3: address?.mailing?.addressLine3,
                  city: address?.mailing?.city,
                  state: address?.mailing?.state,
                  zip: address?.mailing?.zip,
                  country: address?.mailing?.country,
                }}
                isGeneral={true}
                addressType={MAILING}
                style={{ width: '50%', display: 'block' }}
              />
            </div>
          </Form>
        </Card>
      </div>
    </Modal>
  );
};

export default AddressDetailModal;
