import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Input,
  Radio,
  Spin,
  Typography,
  notification,
} from 'antd';
import { NAME_REGEX, NPN_REGEX } from '../../../constants/regex.constants';
import { isEmpty, isUndefined } from 'lodash';
import { onboardingStore, signupStore } from '../../../stores/signupStore';
import { useEffect, useState } from 'react';

import { BeError } from '../../../types/response/error.type';
import { DownlineInviteService } from '../../../services/downline-invite.service';
import { ExceptionName } from '../../../enums/exceptionName.enum';
import { InviteResponseEnum } from '../../../enums/invite-response.enum';
import { NiprTransactionType } from '../../../enums/niprTransactionType';
import { SignupService } from '../../../services/signup.service';
import { useAuth } from '../../authProvider';

type Props = {
  form: FormInstance;
  isLoading: boolean;
  onSubmit: (values: any) => void;
};
export const SignUpBasicInfoStep = ({ form, isLoading, onSubmit }: Props) => {
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const [teamType, setTeamType] = useState(
    onboardingStore.basicInfo.accountType
  );
  const { getAccessTokenSilently } = useAuth();

  const [onboardSelf, setOnboardSelf] = useState(false);
  const [formRender, setFormRender] = useState<number>(0);
  const [basicInfoLoading, setBasicInfoLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState<any>([]);
  const [selectedInvite, setSelectedInvite] = useState<any>(null);
  const { bearerToken } = useAuth();

  useEffect(() => {
    fetchBasicInfo().finally(() => form.resetFields());
  }, []);

  const fetchBasicInfo = async () => {
    const token = await getAccessTokenSilently();
    if (token) {
      setBasicInfoLoading(true);
      const response = await SignupService.getBasicInfo(token).catch(
        (e: BeError) => {
          e.exceptionName !== ExceptionName.FORBIDDEN_EXCEPTION &&
            showError(e?.message);
        }
      );
      if (!isEmpty(response.data)) {
        setInvites(response.data);
      }
      setBasicInfoLoading(false);
      setFormRender((prev) => prev + 1);
    }
  };
  const handleAcceptInvite = (invite: any) => {
    if (invite.teamType === 'agency') {
      onboardingStore.setBasicInfo('agency', invite.npn);
      form.setFieldValue('agencyNPN', invite.npn);
    } else {
      onboardingStore.setBasicInfo('producer', invite.npn, invite.npn);
      form.setFieldValue('producerNPN', invite.npn);
    }
    setSelectedInvite(invite);
    setTeamType(invite.teamType);
    form.setFieldValue('teamType', invite.teamType);
    setInvites([]);
  };

  const handleRejectInvite = async (inviteToken: string) => {
    if (bearerToken)
      await DownlineInviteService.updateInviteStatus(
        inviteToken,
        bearerToken,
        false
      );
    setInvites(
      invites.filter((invite: any) => invite.inviteToken !== inviteToken)
    );
  };

  return (
    <>
      {contextHolder}
      {basicInfoLoading ? (
        <Spin />
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            rowGap: 24,
            justifyContent: 'flex-start',
          }}
        >
          {invites.length > 0 && (
            <div style={{ marginBottom: 20 }}>
              {invites.map((invite: any) => (
                <div
                  key={invite.inviteToken}
                  style={{
                    marginBottom: 16,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography.Text
                    style={{ alignContent: 'center', marginRight: 10 }}
                  >{`Are you interested in accepting the invite from  ${invite.name}?`}</Typography.Text>
                  <div
                    style={{
                      display: 'flex',
                      gap: 8,
                      justifyContent: 'center',
                      marginBottom: 5,
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => handleAcceptInvite(invite)}
                    >
                      Accept
                    </Button>
                    <Button
                      className="button important-action-button"
                      onClick={() => handleRejectInvite(invite.inviteToken)}
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
            Tell us the National Producer Number
          </Typography.Title>

          <Form
            key={formRender}
            autoComplete="off"
            layout="vertical"
            onFinish={(values: any) => {
              const submissionValues = selectedInvite
                ? { ...values, inviteToken: selectedInvite.inviteToken }
                : values;

              onSubmit(submissionValues);
            }}
            form={form}
            style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
            className="onboarding-screen"
            disabled={invites.length !== 0}
          >
            <Form.Item
              label="Team Type"
              name="teamType"
              rules={[
                {
                  required: true,
                  message: 'Please select type',
                },
              ]}
            >
              <Radio.Group
                className="onboarding-radio-button"
                onChange={(e) => {
                  form.setFieldValue('teamType', e.target.value);
                  setTeamType(e.target.value);
                }}
                disabled={isLoading || invites.length !== 0 || selectedInvite} // Make Radio.Group non-editable
              >
                <Radio value={'agency'}>Agency</Radio>
                <Radio value={'producer'}>Individual</Radio>
              </Radio.Group>
            </Form.Item>
            <div className="onboarding-radio-button-note">
              <Typography.Paragraph>
                {teamType === 'producer'
                  ? 'I’m a producer, and will manage my producer licenses. I may have other producers who work with me, but I don’t want to manage agency licenses'
                  : 'The agency is its own entity, has its own NPN, and I can manage both agency and producer licenses in the app.'}
              </Typography.Paragraph>
            </div>

            {teamType === 'agency' && (
              <>
                {/* <Form.Item
                  label="Agency Name"
                  name="agencyName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter agency name',
                    },
                    {
                      pattern: NAME_REGEX,
                      message: 'Agency name must contain only letters',
                    },
                    {
                      validator: (_, value) =>
                        value.trim() === ''
                          ? Promise.reject(
                              new Error(
                                'Agency Name cannot contain only spaces'
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    id="agency-name-input"
                    placeholder="Enter Agency Name"
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldsValue({ agencyName: value });
                    }}
                    disabled={isLoading}
                  />
                </Form.Item> */}
                <Form.Item
                  label="Agency NPN"
                  name="agencyNPN"
                  initialValue={
                    selectedInvite && onboardingStore.basicInfo.agencyNPN
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Please enter agency NPN',
                    },
                    {
                      pattern: NPN_REGEX,
                      message: 'Please enter a valid NPN',
                    },
                    {
                      validator: (_, value) =>
                        value.trim() === ''
                          ? Promise.reject(
                              new Error('Please enter a valid NPN')
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    id="agency-npn-input"
                    placeholder="Enter NPN"
                    maxLength={10}
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldsValue({ agencyNPN: value });
                    }}
                    disabled={
                      invites.length !== 0 ||
                      selectedInvite ||
                      isLoading ||
                      !!onboardingStore.basicInfo.agencyNPN ||
                      signupStore.downlineInviteResponse ===
                        InviteResponseEnum.ACCEPTED
                    } // Make Input non-editable
                  />
                </Form.Item>
                <Form.Item name="selfOnboard">
                  <Checkbox
                    id="onboard-myself"
                    onChange={(e) => {
                      const value = e.target.checked;
                      form.setFieldsValue({ selfOnboard: value });
                      setOnboardSelf(value);
                    }}
                    checked={onboardSelf}
                  >
                    Onboard Myself
                  </Checkbox>
                </Form.Item>
              </>
            )}
            {(teamType === 'producer' || onboardSelf) && (
              <Form.Item
                label="Producer NPN"
                name="producerNPN"
                initialValue={
                  selectedInvite &&
                  onboardingStore.basicInfo.accountType &&
                  onboardingStore.basicInfo.producerNPN
                }
                rules={[
                  {
                    required: true,
                    message: 'Please enter your NPN',
                  },
                  {
                    pattern: NPN_REGEX,
                    message: 'Please enter a valid NPN',
                  },
                  {
                    validator: (_, value) =>
                      value.trim() === ''
                        ? Promise.reject(new Error('Please enter a valid NPN'))
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input
                  id="producer-npn-input"
                  placeholder="Enter Producer NPN"
                  onChange={(e) => {
                    const value = e.target.value;
                    form.setFieldsValue({ producerNPN: value });
                  }}
                  maxLength={10}
                  disabled={
                    (isLoading || invites.length !== 0 || selectedInvite) &&
                    !onboardSelf
                  } // Make Input non-editable
                />
              </Form.Item>
            )}

            <Button
              type="primary"
              htmlType="submit"
              style={{ height: 46, width: '50%' }}
              loading={isLoading}
            >
              Next
            </Button>
          </Form>
        </div>
      )}
    </>
  );
};
