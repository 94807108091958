import {
  Button,
  Form,
  Input,
  InputRef,
  Modal,
  Select,
  Space,
  Tooltip,
  notification,
} from 'antd';
import {
  EmailCard,
  ModalContainer,
  TextDetail,
} from '../invite-admins/inviteAdmin.style';
import React, { useEffect, useRef, useState } from 'react';
import { RoleType, RoleTypeLabels } from '../../../../enums/roles.enum';

import { AdminInvitesService } from '../../../../services/adminInvites.service';
import { EMAIL_REGEX } from '../../../../constants/regex.constants';
import { IdConstants } from '../../../../constants/id.constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ResponseStatus } from '../../../../enums/responseStatus.enum';
import { useAuth } from '../../../../auth/authProvider';
import { userRoleType } from '../../../../enums/userRoleType.enum';

const selectOptions = [
  {
    label: (
      <Space>
        <Tooltip title="Admins have the authority to onboard new admins, revoke access, and manage the admin roster">
          <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
        </Tooltip>
        {userRoleType.SUB_ADMIN}
      </Space>
    ),

    value: RoleType.ADMIN,
    title: '',
  },
  // {
  //   label: (
  //     <Space>
  //       <Tooltip title="Super admins have the authority to onboard new admins, revoke access, and manage the admin roster">
  //         <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
  //       </Tooltip>
  //       {userRoleType.SUPER_ADMIN}
  //     </Space>
  //   ),
  //   value: RoleType.SUPER_ADMIN,
  //   tooltip: 'Admin with all priviledges',
  // },
];
interface LicenseModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

const InviteAdminsModal: React.FC<LicenseModalProps> = ({
  visible,
  setVisible,
  onClose,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const [inputVisible, setInputVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { bearerToken } = useAuth();
  let inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  type FieldType = {
    email?: string;
    role?: string;
    npn?: string;
  };

  const onFinish = async (values: any) => {
    try {
      if (bearerToken) {
        setIsLoading(true);

        const response = await AdminInvitesService.createAdminInvite(
          values.email,
          values.role,
          bearerToken
        );
        if (response.status === ResponseStatus.SUCCESS) {
          api['success']({
            message: `${RoleTypeLabels[values.role as RoleType]} invited`,
            description: `Invite sent successfully to ${values.email}`,
          });
          onClose();
        }
      }
    } catch (error:any) {
      console.error('Error:', error);
        if (error.statusCode === 400) {
          showError(error.message);
      }
    } finally {
      setIsLoading(false);
      form.resetFields();
      onClose();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <Modal
      width={'600px'}
      maskClosable={false}
      open={visible}
      onCancel={() => {
        setVisible(false); // Call the setVisible function to close the modal
      }}
      okText="Ok"
      footer={null}
    >
      {contextHolder}
      <ModalContainer>
        <TextDetail textcolor={'#0588ca'} fontSize={'16px'} fontWeight={500}>
          Invite Users
        </TextDetail>
        <Tooltip>
          <span
            style={{ color: 'grey', marginTop: '-20px', marginBottom: '-15px' }}
          >
            <InfoCircleOutlined style={{ marginRight: '5px' }} />
            The invite expires in 30 days!
          </span>
        </Tooltip>
        <EmailCard>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <div
              style={{ display: 'flex', gap: 16 }}
              id={IdConstants.AGENCY.USERS.INVITE_USERS_MODAL}
            >
              <Form.Item<FieldType>
                label="Email Address"
                name="email"
                style={{ marginBottom: 0 }}
                labelCol={{ style: { width: '100%' } }}
                rules={[
                  { required: true, message: 'Please input email!' },
                  {
                    pattern: EMAIL_REGEX,
                    message: 'Please enter a valid email address',
                  },
                ]}
              >
                <Input
                  style={{
                    width: '250px',
                  }}
                  onInput={(e: any) =>
                    (e.target.value = e.target.value.toLowerCase())
                  }
                  placeholder="Enter an email address"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Select Role"
                name="role"
                style={{ marginBottom: 0 }}
                labelCol={{ style: { width: '100%' } }}
                rules={[{ required: true, message: 'Please Select Role' }]}
              >
                <Select
                  style={{
                    width: '250px',
                  }}
                  placeholder="Select a role"
                  options={selectOptions}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: 16,
                marginTop: 24,
              }}
            >
              <div
                style={{ width: '100%' }}
                id={IdConstants.INVITE_ADMIN.SEND_INVITE}
              >
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Send Invite
                </Button>
              </div>
              <div style={{ width: '100%' }}>
                <Button
                  danger
                  id={IdConstants.INVITE_ADMIN.CANCEL_BUTTON}
                  disabled={isLoading}
                  style={{ width: '100%' }}
                  htmlType="reset"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </EmailCard>
      </ModalContainer>
    </Modal>
  );
};

export default InviteAdminsModal;
