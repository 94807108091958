import { CancelTokenSource } from 'axios';

/**
 * Cancels the previous API request.
 */
export const cancelPreviousRequest = (
  cancelTokenSource: CancelTokenSource | undefined
) => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Request canceled due to a new request.');
  }
};
