import { Button, Tour } from 'antd';
import { useEffect, useState } from 'react';

import { IdConstants } from '../../../constants/id.constants';
import { TourConstants } from '../../../constants/tour.constant';
import type { TourProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../../stores/tour.store';

const tourTitleStyle = {
  margin: 0,
  gap: 8,
};

const TourTitle = () => {
  return (
    <div style={{ margin: 0, paddingTop: 6 }}>
      <h2 style={tourTitleStyle}>Agency Wide View</h2>
    </div>
  );
};

const RtsByAgency = () => {
  const csvDownloadButton = document.getElementById(
    IdConstants.RTS.BY_AGENCY.CSV_DOWNLOAD
  );
  const siderMenyByProducer = document.getElementById(
    TourConstants.SIDER_MENU.RTS.BY_PRODUCER
  );
  const rtsState = document.getElementsByClassName(
    IdConstants.RTS.BY_AGENCY.STATE_RADIO
  )?.[0] as HTMLElement;
  const rtsCarrier = document.getElementsByClassName(
    IdConstants.RTS.BY_AGENCY.CARRIER_RADIO
  )?.[0] as HTMLElement;

  const [currentStep, setCurrentStep] = useState(0);
  const stepsCount = 2;

  useEffect(() => {
    setCurrentStep(tourStore.rtsByAgencyCurrentStep);
  }, [tourStore.rtsByAgencyCurrentStep]);

  const resetStore = () => {
    tourStore.setRtsByAgencyCurrentStep(0);
    tourStore.setRtsByAgencyTour(false);
    const isSignupDemo = sessionStorage.getItem(
      TourConstants.SIGNUP_DEMO_BY_SECTION
    );
    if (isSignupDemo) {
      siderMenyByProducer?.click();
      tourStore.setSignUpDemoTour(true);
      sessionStorage.removeItem(TourConstants.SIGNUP_DEMO_BY_SECTION);
    }
  };

  const updateCurrentStep = (isNext: boolean) => {
    if (isNext) {
      if (currentStep + 1 > stepsCount) {
        resetStore();
      } else {
        tourStore.setRtsByAgencyCurrentStep(true);
      }
      setCurrentStep((currentStep) => currentStep + 1);
    } else {
      tourStore.setRtsByAgencyCurrentStep(false);
      setCurrentStep((currentStep) => currentStep - 1);
    }
  };

  const handlePrevClick = (
    prev: HTMLElement | [HTMLElement, string, number] | null
  ) => {
    const prevElement = Array.isArray(prev) ? prev?.[0] : prev;
    if (!prevElement) return;

    if (Array.isArray(prev)) {
      prevElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(prev?.[1]);
        elem?.click();
      }, prev?.[2]);
    } else {
      prevElement?.click();
    }
    updateCurrentStep(false);
  };

  const handleNextClick = (
    next: HTMLElement | [HTMLElement, string, number] | null,
    selectChild?: boolean
  ) => {
    const nextElement = Array.isArray(next) ? next?.[0] : next;
    if (!nextElement) {
      updateCurrentStep(true);
      return;
    }

    if (Array.isArray(next)) {
      nextElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(next?.[1]);
        elem?.click();
        updateCurrentStep(true);
      }, next?.[2]);
    } else {
      nextElement?.click();
      if (selectChild) {
        const elem = nextElement.querySelector('*') as HTMLElement;
        elem?.click();
      }
      updateCurrentStep(true);
    }
  };

  const navigateTour = (
    prev: HTMLElement | [HTMLElement, string, number] | null,
    next: HTMLElement | [HTMLElement, string, number] | null,
    cb?: (isNext: boolean) => void,
    selectChild?: boolean
  ) => {
    return (
      <div className="antd-navigate-footer">
        <div>
          {currentStep + 1} of {stepsCount + 1}
        </div>
        <div className="flex" style={{ gap: 12 }}>
          <Button
            size="small"
            style={{
              width: 90,
            }}
            onClick={() => {
              resetStore();
            }}
          >
            Skip
          </Button>
          {![0].includes(currentStep) && (
            <Button
              size="small"
              style={{
                width: 90,
              }}
              onClick={() => {
                if (cb) cb(false);
                handlePrevClick(prev);
              }}
            >
              Previous
            </Button>
          )}
          <Button
            size="small"
            style={{
              width: 90,
              paddingLeft: 0,
            }}
            onClick={() => {
              if (cb) cb(true);
              handleNextClick(next, selectChild);
            }}
            type="primary"
          >
            {currentStep === stepsCount ? 'Finish' : 'Next'}
          </Button>
        </div>
      </div>
    );
  };

  const steps: TourProps['steps'] = [
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          In State View, expand states to reveal carriers, producers, license
          details, and appointment statuses.
          {navigateTour(null, rtsCarrier)}
        </div>
      ),
      target: rtsState,
      placement: 'left',
    },
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          In Carriers View, navigate through states to access producer license
          and appointment information.
          {navigateTour(rtsState, null)}
        </div>
      ),
      target: rtsCarrier,
      placement: 'left',
    },
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          Download CSV reports and expand/collapse sections as needed.
          {navigateTour(rtsCarrier, null)}
        </div>
      ),
      target: csvDownloadButton,
      placement: 'left',
    },
  ];

  return (
    <>
      {tourStore.rtsByAgencyTour && <div className="overlay" />}
      <Tour
        rootClassName="onboard-antd"
        open={tourStore.rtsByAgencyTour}
        onClose={() => {
          resetStore();
        }}
        steps={steps}
        current={currentStep}
      />
    </>
  );
};

export default observer(RtsByAgency);
