import { Button, Checkbox, Form, Modal, Row } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalContainer } from './update-bulk-address.style';
import React from 'react';
import { TextDetail } from '../application-status/applicationstatus.style';

interface LicenseModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onFinish: (values: any) => void;
  form: any;
  isLoading: boolean;
  error: string;
  isUpdateAll: boolean;
  setError: React.Dispatch<React.SetStateAction<any>>;
  onCancelCb: () => void;
}

const UpdateAddressModal: React.FC<LicenseModalProps> = ({
  visible,
  setVisible,
  onFinish,
  form,
  isLoading,
  error,
  setError,
  isUpdateAll,
  onCancelCb,
}) => {
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    onCancelCb();
  };

  type FieldType = {
    bussinessAddress?: Boolean;
    mailingAddress?: Boolean;
    email?: boolean;
    fax: boolean;
    phone: boolean;
  };
  return (
    <Modal
      afterClose={() => {
        setError('');
      }}
      destroyOnClose={true}
      width={'500px'}
      open={visible}
      onCancel={() => {
        form.resetFields();
        onCancelCb();
        setVisible(false); // Call the setVisible function to close the modal
      }}
      okText="Ok"
      footer={null}
    >
      <ModalContainer style={{ width: '430px' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <TextDetail textColor={'#0588ca'} fontWeight={500} fontSize={'16px'}>
            Update Contact and Address Information
          </TextDetail>
          <TextDetail fontWeight={400} fontSize={'14px'}>
            Select address and contact info you want to update for{' '}
            {isUpdateAll ? 'all ' : 'selected '}
            producers
          </TextDetail>
        </div>

        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ width: '600px', minHeight: '1px' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item<FieldType>
            name="bussinessAddress"
            valuePropName="checked"
            initialValue={false}
            style={{ minHeight: '1px' }}
          >
            <Checkbox
              onChange={(e) => {
                form.setFieldsValue({ bussinessAddress: e.target.checked });
              }}
            >
              Update Business Address
            </Checkbox>
          </Form.Item>
          <Form.Item<FieldType>
            name="mailingAddress"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              onChange={(e) => {
                form.setFieldsValue({ mailingAddress: e.target.checked });
              }}
            >
              Update Mailing Address
            </Checkbox>
          </Form.Item>
          <div style={{ display: 'flex', width: '450px', gap: '5px' }}>
            <div>
              <ExclamationCircleOutlined />
            </div>
            <TextDetail
              textColor={'#92A69E'}
              fontWeight={400}
              fontSize={'14px'}
            >
              These information will be updated as per your agency’s contact and
              address info
            </TextDetail>
          </div>

          <Form.Item style={{ marginTop: '20px' }}>
            <Row justify={'space-between'}>
              <Button
                onClick={onCancel}
                danger
                size="large"
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                size="large"
              >
                Update Information
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </ModalContainer>
    </Modal>
  );
};

export default UpdateAddressModal;
