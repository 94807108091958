import {
  Checkbox,
  Form,
  FormInstance,
  Input,
  Select,
  Spin,
  Typography,
  notification,
} from 'antd';
import {
  SmartyAddress,
  Suggestion,
} from '../../../services/smartyAddress.service';
import { useRef, useState } from 'react';

import { Address } from '../../../types/data/address.type';
import { LoadingOutlined } from '@ant-design/icons';
import { StateConstants } from '../../../constants/state.constants';
import { isUndefined } from 'lodash';

interface AddressInputProps {
  addressDetails: Address;
  style?: any;
  addressType: 'business' | 'mailing' | 'residence' | null;
  disableIfEmpty?: boolean;
  viewOnly?: boolean;
  isGeneral?: boolean;
  formInputStyle?: Object;
  checkboxState?: boolean;
  form?: FormInstance;
  handleCheckBoxChange?: () => void;
  disableCheckbox?: boolean;
  hideCountry?: boolean;
  hideOptional3Address?: boolean;
}

function AddressInput({
  addressDetails,
  style,
  addressType,
  disableIfEmpty,
  viewOnly,
  isGeneral = false,
  formInputStyle,
  form,
  checkboxState,
  handleCheckBoxChange,
  disableCheckbox,
  hideCountry,
  hideOptional3Address,
}: AddressInputProps) {
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [addressLine1, setAddressLine1] = useState<string>(
    addressDetails.addressLine1 || ''
  );

  const addressTypeValue = isGeneral
    ? addressType
    : !addressType
    ? ''
    : addressType?.[0];

  const [lastEmptySearch, setLastEmptySearch] = useState<string | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleAddressLine1Change = async (
    value: string,
    formattedaddress?: string
  ) => {
    setAddressLine1(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (value?.trim().length >= 3) {
      setLoading(true);
      if (formattedaddress) setSuggestions([]);
      timeoutRef.current = setTimeout(async () => {
        if (lastEmptySearch && value.startsWith(lastEmptySearch)) {
          setSuggestions([
            {
              street_line: value,
            },
          ]);
          setDropdownOpen(true);
          setLoading(false);
          return;
        }

        setDropdownOpen(true);
        setLoading(true);
        const fetchedSuggestions = await SmartyAddress.getSuggestions(
          value,
          formattedaddress
        ).catch((e) => {
          setLoading(false);
          if (e.response.status === 429)
            showError(
              'We are experiencing a brief delay. Please try again shortly'
            );
          else showError('Something went wrong. Please try again later');
          setSuggestions([
            {
              street_line: value,
            },
          ]);
          setLastEmptySearch(value);
        });

        // Add the input string as an option if there are no suggestions
        if (fetchedSuggestions) {
          if (fetchedSuggestions.length === 0 && value.trim()) {
            setSuggestions([
              {
                street_line: value,
              },
            ]);
            setLastEmptySearch(value);
          } else {
            setSuggestions(fetchedSuggestions);
            setLastEmptySearch(null);
          }
          setLoading(false);
        }
      }, 1000);
    } else {
      setSuggestions([]);
      setLoading(false);
    }
  };

  function buildAddress(suggestion: Suggestion): string {
    let whiteSpace = '';
    if (suggestion.secondary && suggestion.entries && suggestion.entries > 1) {
      suggestion.secondary += ` (${suggestion.entries})`;
      whiteSpace = ' ';
    }
    return `${suggestion.street_line}${whiteSpace}${
      suggestion.secondary || ''
    } ${suggestion.city} ${suggestion.state} ${suggestion.zipcode}`;
  }

  return (
    <div style={style}>
      {contextHolder}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {addressType ? (
          <Typography.Paragraph
            style={{
              color: 'var(--secondary-color)',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            {addressType === 'business' && 'Business'}
            {addressType === 'mailing' && 'Mailing'}
            {addressType === 'residence' && 'Resident'} Address
          </Typography.Paragraph>
        ) : null}

        {(checkboxState || handleCheckBoxChange) && (
          <div>
            <Checkbox
              checked={checkboxState}
              disabled={disableCheckbox}
              onChange={handleCheckBoxChange}
            >
              Same as business address
            </Checkbox>
          </div>
        )}
      </div>
      <Form.Item
        label="Address Line 1"
        name={addressTypeValue + 'addressLine1'}
        style={{ width: '100%', ...formInputStyle }}
        rules={
          viewOnly
            ? []
            : [
                { required: true },
                {
                  validator: (_, value) =>
                    value?.trim() === ''
                      ? Promise.reject(
                          new Error('Address Line 1 cannot contain only spaces')
                        )
                      : Promise.resolve(),
                },
              ]
        }
        initialValue={addressDetails.addressLine1}
      >
        <Select
          showSearch
          placeholder="Enter your address"
          onSearch={handleAddressLine1Change}
          open={dropdownOpen}
          notFoundContent={
            loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '40px',
                }}
              >
                <Spin size="small" />
              </div>
            ) : null
          }
          onSelect={async (value) => {
            const [street_line, secondary, city, state, zipcode] =
              value.split('|');
            let selectedSuggestion = suggestions.find(
              (suggestion) =>
                suggestion.street_line === street_line &&
                (suggestion.secondary === secondary ||
                  (!suggestion.secondary && !secondary)) &&
                suggestion.city === city &&
                suggestion.state === state &&
                suggestion.zipcode === zipcode
            );
            if (selectedSuggestion && form) {
              if (
                selectedSuggestion.entries &&
                selectedSuggestion.entries > 1
              ) {
                // If there are multiple entries or exactly one entry, use buildAddress to format the address
                const formattedAddress = buildAddress(selectedSuggestion);

                // Update the search string to selectedSuggestion.street_line and send formattedAddress to handleAddressLine1Change
                handleAddressLine1Change(
                  selectedSuggestion.street_line,
                  formattedAddress
                );
              } else {
                const fieldsToUpdate: { [key: string]: any } = {};

                // Only set the field if it exists in selectedSuggestion
                if (!isUndefined(selectedSuggestion.street_line)) {
                  fieldsToUpdate[`${addressTypeValue}addressLine1`] =
                    selectedSuggestion.street_line;
                }
                if (!isUndefined(selectedSuggestion.state)) {
                  fieldsToUpdate[`${addressTypeValue}state`] =
                    selectedSuggestion.state;
                }
                if (!isUndefined(selectedSuggestion.secondary)) {
                  fieldsToUpdate[`${addressTypeValue}addressLine2`] =
                    selectedSuggestion.secondary;
                }
                if (!isUndefined(selectedSuggestion.city)) {
                  fieldsToUpdate[`${addressTypeValue}city`] =
                    selectedSuggestion.city;
                }
                if (!isUndefined(selectedSuggestion.zipcode)) {
                  fieldsToUpdate[`${addressTypeValue}zipcode`] =
                    selectedSuggestion.zipcode;
                }
                // Always clear addressLine3 if it exists in the form
                fieldsToUpdate[`${addressTypeValue}addressLine3`] = '';

                form.setFieldsValue(fieldsToUpdate);

                setDropdownOpen(false);
              }
            }
          }}
          filterOption={false}
          disabled={
            (disableIfEmpty && !addressDetails.addressLine1) || viewOnly
          }
        >
          {suggestions.map((suggestion, index) => (
            <Select.Option
              key={index + '_' + suggestion.street_line}
              value={`${suggestion.street_line}|${suggestion.secondary || ''}|${
                suggestion.city || ''
              }|${suggestion.state || ''}|${suggestion.zipcode || ''}`}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ flex: 1 }}>
                  <div>{suggestion.street_line}</div>
                  <div>
                    {suggestion.city && ` ${suggestion.city}`}
                    {suggestion.secondary && ` ,${suggestion.secondary}`}
                    {suggestion.state && ` ${suggestion.state}`}
                    {suggestion.city && ` ${suggestion.city}`}
                  </div>
                </div>
                {suggestion.entries && suggestion.entries > 1 ? (
                  <div style={{ marginLeft: '10px' }}>
                    {`${suggestion.entries} entries`}
                  </div>
                ) : (
                  <div style={{ marginLeft: '10px' }}>{''}</div>
                )}
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Address Line 2"
        name={addressTypeValue + 'addressLine2'}
        style={{ width: '100%', ...formInputStyle }}
        initialValue={addressDetails.addressLine2}
      >
        <Input
          id={addressTypeValue + '-addressLine2'}
          placeholder="Enter your address (optional)"
          disabled={
            (disableIfEmpty && !!addressDetails.addressLine2) || viewOnly
          }
        />
      </Form.Item>
      {hideOptional3Address ? null : (
        <Form.Item
          label="Address Line 3"
          name={addressTypeValue + 'addressLine3'}
          style={{ width: '100%', ...formInputStyle }}
          initialValue={addressDetails.addressLine3}
        >
          <Input
            id={addressTypeValue + '-addressLine3'}
            placeholder="Enter your address (optional)"
            disabled={
              (disableIfEmpty && !!addressDetails.addressLine3) || viewOnly
            }
          />
        </Form.Item>
      )}
      <div style={{ display: 'flex', width: '100%', columnGap: '5px' }}>
        <Form.Item
          label="City"
          name={addressTypeValue + 'city'}
          rules={
            viewOnly
              ? []
              : [
                  { required: true },
                  {
                    validator: (_, value) =>
                      value?.trim() === ''
                        ? Promise.reject(
                            new Error('City cannot contain only spaces')
                          )
                        : Promise.resolve(),
                  },
                ]
          }
          style={{ width: '50%', ...formInputStyle }}
          initialValue={addressDetails.city}
        >
          <Input
            id={addressTypeValue + '-city'}
            placeholder="Enter your city"
            onKeyPress={(event) => {
              const regex = /^[A-Za-z\s]+$/;
              const char = String.fromCharCode(event.which || event.keyCode);
              if (!regex.test(char)) {
                event.preventDefault();
              }
            }}
            onPaste={(event) => {
              const pastedText = event.clipboardData.getData('text/plain');
              const regex = /^[A-Za-z\s]+$/;
              if (!regex.test(pastedText)) {
                event.preventDefault();
              }
            }}
            disabled={(disableIfEmpty && !!addressDetails.city) || viewOnly}
          />
        </Form.Item>
        <Form.Item
          label="State"
          name={addressTypeValue + 'state'}
          rules={viewOnly ? [] : [{ required: true }]}
          style={{ width: '50%', ...formInputStyle }}
          initialValue={addressDetails.stateCode}
        >
          <Select
            id={addressTypeValue + '-state'}
            showSearch
            placeholder="Select your State"
            filterOption={(input, option) => {
              const inputValue = input.toLowerCase();
              const optionValue = (
                option?.children as unknown as string
              ).toLowerCase();
              const optionWords = optionValue.split(' ');
              const isMatch = optionWords.some((word) =>
                word.startsWith(inputValue)
              );
              return isMatch;
            }}
            disabled={
              (disableIfEmpty && !!addressDetails.stateCode) || viewOnly
            }
          >
            {Object.keys(StateConstants).map((stateCode) => (
              <Option key={stateCode} value={stateCode}>
                {StateConstants[stateCode]}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <Form.Item
        label="Zip code"
        name={addressTypeValue + 'zipcode'}
        rules={
          viewOnly
            ? []
            : [
                {
                  required: true,
                },
              ]
        }
        style={{ width: '100%', ...formInputStyle }}
        initialValue={addressDetails.zip}
      >
        <Input
          id={addressTypeValue + '-zip-code'}
          placeholder="Enter your zip code"
          onKeyPress={(event) => {
            if (!/[0-9-]+$/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onPaste={(event) => {
            const pastedText = event.clipboardData.getData('text/plain');
            if (!/^[0-9-]+$/.test(pastedText)) {
              event.preventDefault();
            }
          }}
          disabled={(disableIfEmpty && !!addressDetails.zip) || viewOnly}
        />
      </Form.Item>
      {hideCountry ? null : (
        <Form.Item
          label="Country"
          name={addressTypeValue + 'country'}
          rules={viewOnly ? [] : [{ required: true }]}
          style={{ width: '100%', ...formInputStyle }}
          initialValue={
            Object.values(addressDetails).filter((value) => !!value).length > 0
              ? 'U.S.A'
              : ''
          }
        >
          <Input
            id={addressTypeValue + '-country'}
            placeholder="Enter your Country"
            className="display-input"
            disabled
          />
        </Form.Item>
      )}
    </div>
  );
}

export default AddressInput;
