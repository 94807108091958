import { Button, Form, Input, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';

import { EMAIL_REGEX } from '../../constants/regex.constants';
import { Link } from 'react-router-dom';
import Loading from '../../components/loading';
import { OnboardingLayout } from '../../components/layout/onboardingLayout';
import { ReCaptchaWrapper } from '../../components/common/recaptcha/recaptcha-wrapper';
import { RouteConstants } from '../../constants/routes.constants';
import { getQueryParams } from '../../utils/queryParams.utils';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../authProvider';
import { useNavigate } from 'react-router';

const LoginScreen = () => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const { isAuthenticated, isOnboarded, onboardingStage, performLogin } =
    useAuth();
  const navigate = useNavigate();
  const queryParams = getQueryParams();

  const showError = (description: string) => {
    api['error']({
      message: 'Authentication Failed',
      description,
    });
  };
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated()) {
      if (queryParams['redirectTo']) {
        const redirectTo = decodeURIComponent(queryParams['redirectTo']);
        navigate((redirectTo.startsWith('/') ? '' : '/') + redirectTo);
      } else if (isOnboarded) navigate('/redirect');
      else if (!isEmpty(onboardingStage)) {
        navigate(RouteConstants.onboarding.path);
      }
    }
    setIsPageLoading(false);
  }, [onboardingStage, isOnboarded, isAuthenticated]);

  const handleLogin = (email: string, password: string) => {
    setLoading(true);
    performLogin(email, password, reCaptchaToken)
      .catch((err: any) => {
        setRefreshReCaptcha((refreshReCaptcha) => !refreshReCaptcha);
        showError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyReCaptchaToken = (token: any) => {
    setReCaptchaToken(token);
  };

  return isPageLoading ? (
    <Loading fullHeight logo />
  ) : (
    <OnboardingLayout step={1} flowType="SignIn">
      {/* DND Start */}
      {contextHolder}
      {/* DND End */}

      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 24 }}>
        <div style={{ textAlign: 'center' }}>
          <Typography.Title level={2}>Login to your account</Typography.Title>
          <Typography.Paragraph type="secondary">
            Onboard and manage your producer licenses
          </Typography.Paragraph>
        </div>
        <Form
          autoComplete="off"
          layout="vertical"
          onFinish={(values) => {
            handleLogin(values.email, values.password);
          }}
          form={form}
          style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
          className="onboarding-screen"
        >
          <Form.Item
            label="Email address"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email',
              },
              {
                pattern: EMAIL_REGEX,
                message: 'Please enter a valid email',
              },
            ]}
          >
            <Input
              id="email-input"
              placeholder="Enter Email address"
              onChange={(e) => {
                const value = e.target.value;
                form.setFieldsValue({ email: value });
              }}
              disabled={isLoading}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter a password' }]}
          >
            <Input.Password placeholder="Enter Password" disabled={isLoading} />
          </Form.Item>

          <Typography.Paragraph
            type="secondary"
            style={{
              fontSize: '14px',
              alignSelf: 'left',
              marginBottom: 0,
            }}
          >
            <Link
              to="/forgot-password"
              style={{ color: 'var(--primary-color)' }}
            >
              Forgot your password?
            </Link>
          </Typography.Paragraph>

          <ReCaptchaWrapper
            onVerify={verifyReCaptchaToken}
            refreshReCaptcha={refreshReCaptcha}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ height: 46, width: '100%' }}
              loading={isLoading}
            >
              Login
            </Button>
          </ReCaptchaWrapper>
        </Form>

        <Typography.Paragraph
          type="secondary"
          style={{ fontSize: '14px', alignSelf: 'center', marginBottom: 0 }}
        >
          Don't have an account?{' '}
          {isLoading ? (
            <span
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'underline',
              }}
            >
              Sign Up
            </span>
          ) : (
            <Link
              to="/signup"
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'underline',
              }}
            >
              Sign Up
            </Link>
          )}
        </Typography.Paragraph>
        <Typography.Paragraph
          type="secondary"
          style={{ fontSize: '13px', marginBottom: 0 }}
        >
          By continuing you agree to the{' '}
          {isLoading ? (
            <span
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'underline',
              }}
            >
              Terms of service
            </span>
          ) : (
            <Link
              to="https://insuretrek.com/terms-of-service/"
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'underline',
              }}
            >
              Terms of service
            </Link>
          )}{' '}
          and{' '}
          {isLoading ? (
            <span
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'underline',
              }}
            >
              Privacy Policy
            </span>
          ) : (
            <Link
              to="https://insuretrek.com/privacy-policy/"
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'underline',
              }}
            >
              Privacy Policy
            </Link>
          )}
        </Typography.Paragraph>
      </div>
    </OnboardingLayout>
  );
};

export default observer(LoginScreen);
