import { Button, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';

import { AgentDetails } from '../../../../types/data/agentDetail.type';
import DiscardConfirmationModal from '../modals/discardChanges';
import EmptyState from '../../../../components/common/emptyState/emptyState';
import { Name } from '../../../../types/data/name.type';

type SaveAgentsViewProps = {
  newAgents: AgentDetails[];
  newlyAddedAgents: AgentDetails[];
  setStep: () => void;
  continueToAssignments: () => void;
  onBack: (agentDetails: AgentDetails[]) => void;
};

const tableColumns: ColumnsType<AgentDetails> = [
  {
    title: 'Producer',
    dataIndex: 'name',
    render: (text: Name) => text.firstName + ' ' + text.lastName,
  },
  {
    title: 'NPN',
    dataIndex: 'npn',
  },
];

function SaveNewAgents(props: SaveAgentsViewProps) {
  const [discardModalVisibility, setDiscardModalVisibility] = useState(false);
  const [activeData, setActiveData] = useState<AgentDetails[]>([]);

  useEffect(() => {
    setActiveData([
      ...activeData,
      ...props.newAgents,
      ...props.newlyAddedAgents,
    ]);
  }, [props.newAgents, props.newlyAddedAgents]);

  return (
    <div style={{ width: '100%', marginTop: '20px' }}>
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        Producers
      </Typography.Paragraph>
      <Typography.Paragraph
        style={{
          color: 'var(--grey-text)',
          fontSize: '14px',
          fontWeight: '400',
        }}
      >
        These agents will be assigned to the new territory
      </Typography.Paragraph>
      <Table
        dataSource={activeData}
        columns={tableColumns}
        style={{ marginTop: '20px' }}
        pagination={false}
        locale={{
          emptyText: () => {
            return (
              <div
                style={{
                  textAlign: 'center',
                  marginLeft: '17%',
                  marginTop: '2%',
                }}
              >
                <EmptyState
                  image="empty data"
                  heading="No producers to show yet"
                  content={
                    'You have not added or transferred any producers for this new Territory'
                  }
                />
              </div>
            );
          },
        }}
      />
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button
            size="large"
            onClick={() => {
              props.onBack(activeData);
            }}
          >
            Back
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            columnGap: '25px',
          }}
        >
          <Button
            size="large"
            className="button secondary-button"
            onClick={() => {
              props.setStep();
            }}
          >
            Add New Producers
          </Button>
          <Button
            size="large"
            className="button primary-button"
            onClick={() => {
              props.continueToAssignments();
            }}
          >
            Done
          </Button>
        </div>
      </div>
      <DiscardConfirmationModal
        visible={discardModalVisibility}
        handleVisibility={() => {
          setDiscardModalVisibility(false);
        }}
      />
    </div>
  );
}

export default SaveNewAgents;
