import { Col, Row, Select, Skeleton, Space, Switch, Typography } from 'antd';
import USMapView, { USMapViewDataProp } from '../../../../components/usMap';
import { forEach, isEmpty, sortedUniq } from 'lodash';
import { useEffect, useState } from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import { LicensesService } from '../../../../services/licenses.service';
import { StateConstants } from '../../../../constants/state.constants';
import { getStateNameFromCode } from '../../../../utils/common.utils';
import { useAuth } from '../../../../auth/authProvider';

var blue = ['#eeeeee', '#b3e5fc', '#4fc3f7', '#01579b'];
var red = ['#eeeeee', '#ffcdd2', '#e57373', '#b71c1c'];

const { Option } = Select;
const { Text } = Typography;

interface AgencyProps {
  isViewActionableData: boolean;
  selectedState: string;
  setSelectedState: React.Dispatch<React.SetStateAction<string>>;
  setIsViewActionableData: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdated: boolean;
  agencyId: string;
}

const AgencyAssignmentOverview = ({
  selectedState,
  isViewActionableData,
  setSelectedState,
  setIsViewActionableData,
  isUpdated,
  agencyId,
}: AgencyProps) => {
  const { getAccessTokenSilently } = useAuth();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<USMapViewDataProp>({});
  const [loaList, setLoaList] = useState<string[]>([]);
  const [nthPart, setNthPart] = useState<number>(0);
  const [selectedLOA, setSelectedLOA] = useState<string>('');
  const [statisticsData, setStatisticsData] = useState<any>({});

  const processAssignmentOverviewData = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    LicensesService.getLicenseStatisticsForAgencyOverview(token)
      .then((response: any) => {
        setStatisticsData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error:', err);
      });
    // fetch Data from service
  };

  useEffect(() => {
    processAssignmentOverviewData();
  }, [isUpdated, agencyId]);

  const getUSMapData = (
    stateCode: string,
    backgroundColor: string,
    textColor: string,
    agentCount: number,
    actionableCount: number
  ) => {
    let tooltipText =
      getStateNameFromCode(stateCode) +
      ': ' +
      (isViewActionableData ? actionableCount : agentCount) +
      ' producer' +
      ((isViewActionableData ? actionableCount : agentCount) > 1 ? 's' : '') +
      (isViewActionableData ? ' needs attention.' : '');
    if (actionableCount > 0 && !isViewActionableData)
      tooltipText +=
        ' (Action required for ' +
        actionableCount +
        ' producer' +
        (actionableCount > 1 ? 's' : '') +
        ')';
    return {
      backgroundColor: backgroundColor,
      textColor: textColor,
      tooltip: tooltipText,
    };
  };

  const getStatistics = (data = statisticsData) => {
    if (isEmpty(data)) {
      return;
    }
    const loaList: string[] = [];
    // Finding Maximum count
    let maxCount = 0;
    data?.forEach((d: any) => {
      let count = d.count;
      if (selectedLOA) {
        const loa = d?.loaGroups?.find((l: any) => l.loa === selectedLOA);
        if (loa) count = loa.count;
        else count = 0;
      }
      if (count > maxCount) maxCount = count;
      d?.loaGroups?.forEach((l: any) => {
        if (
          (isViewActionableData && l.actionableCount > 0) ||
          !isViewActionableData
        ) {
          loaList.push(l.loa);
        }
      });
    });
    // Set LoaList to enable filter by LOA
    if (!selectedLOA) setLoaList(sortedUniq(loaList.sort()));

    // Split maximum count into 3 equal parts (5th part is always white as it means count is 0)
    const nthPart = maxCount > 3 ? Math.ceil(maxCount / 3) : 1;
    setNthPart(nthPart);
    const dataStats: USMapViewDataProp = {};
    // Initialize all states with count 0 and color as blue[0]
    Object.keys(StateConstants)?.forEach(
      (s) =>
        (dataStats[s] = {
          backgroundColor: isViewActionableData ? red[0] : blue[0],
          textColor: '#000',
        })
    );

    // Assign the correct color code to each state based on the bucket it falls under as per count
    // Light - Low Count -- Dark - High count
    forEach(data || [], (d) => {
      let count = d.count,
        marker = d.actionableCount;
      if (selectedLOA) {
        const loa = d?.loaGroups?.find((l: any) => l.loa === selectedLOA);
        if (loa) {
          count = loa.count;
          marker = loa.actionableCount;
        } else {
          count = 0;
          marker = 0;
        }
      }
      const countToCheck = isViewActionableData ? marker : count;
      if (countToCheck > 0 && countToCheck <= nthPart)
        dataStats[d.stateCode] = getUSMapData(
          d.stateCode,
          isViewActionableData ? red[1] : blue[1],
          '#000',
          count,
          marker
        );
      else if (countToCheck > nthPart && countToCheck <= 2 * nthPart)
        dataStats[d.stateCode] = getUSMapData(
          d.stateCode,
          isViewActionableData ? red[2] : blue[2],
          '#000',
          count,
          marker
        );
      else if (countToCheck > 2 * nthPart)
        dataStats[d.stateCode] = getUSMapData(
          d.stateCode,
          isViewActionableData ? red[3] : blue[3],
          '#fff',
          count,
          marker
        );
    });
    setStatistics(dataStats);
    setLoading(false);
  };

  useEffect(() => {
    getStatistics();
  }, [selectedLOA, statisticsData, isViewActionableData]);

  return (
    <Row>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'}>
              <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                <Select
                  showSearch
                  placeholder="Filter by LOA"
                  filterOption={(input, option) => {
                    const inputValue = input.toLowerCase();
                    const optionValue = (
                      option?.children as unknown as string
                    ).toLowerCase();
                    const isMatch = optionValue.startsWith(inputValue);
                    return isMatch;
                  }}
                  allowClear
                  disabled={loaList.length === 0}
                  style={{ width: '200px', marginRight: '10px' }}
                  onSelect={(e) => setSelectedLOA(e)}
                  onClear={() => setSelectedLOA('')}
                >
                  {loaList.map((loa, index) => (
                    <Option key={index} value={loa}>
                      {loa}
                    </Option>
                  ))}
                </Select>
                {selectedState && (
                  <div className="us-map-state-selection reset-icon-size">
                    {StateConstants[selectedState]}
                    <CloseCircleOutlined
                      className="cursor-pointer"
                      onClick={() => setSelectedState('')}
                    />
                  </div>
                )}
              </div>
              <Space>
                <Text type="secondary">Needs Attention</Text>
                <Switch
                  onChange={(checked) => setIsViewActionableData(checked)}
                />
              </Space>
            </Row>
          </Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexGrow: 1,
              marginTop: 24,
              height: '100%',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <USMapView data={statistics} setSelectedState={setSelectedState} />
          </div>
          <Col
            style={{
              display: 'flex',
              gap: 16,
              width: '100%',
              marginTop: 24,
              justifyContent: 'flex-end',
            }}
          >
            {(isViewActionableData ? red : blue).map((b, index) => (
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}
                key={index}
              >
                {(loaList.length !== 0 || index === 0) && (
                  <div className="square" style={{ background: b }}></div>
                )}
                <div>
                  {index === 0 ? (
                    <Typography.Text type="secondary">
                      No Producers
                    </Typography.Text>
                  ) : (
                    loaList.length !== 0 &&
                    ((index - 1) * nthPart + 1 === index * nthPart ||
                    index * nthPart === 0 ? (
                      <Typography.Text type="secondary">{`${
                        index * nthPart
                      } Producer${
                        index * nthPart > 1 ? 's' : ''
                      }`}</Typography.Text>
                    ) : (
                      <Typography.Text type="secondary">{`${
                        (index - 1) * nthPart + 1
                      } to ${index * nthPart} Producer${
                        index * nthPart > 1 ? 's' : ''
                      }`}</Typography.Text>
                    ))
                  )}
                </div>
              </div>
            ))}
          </Col>
        </>
      )}
    </Row>
  );
};

export default AgencyAssignmentOverview;
