import { Menu } from 'antd';

type TabProps = {
  options: { label: string; key: string }[];
  selectedTab: string;
  onTabChange: (selectedTab: string) => void;
};

export default function TabView({
  options,
  selectedTab,
  onTabChange,
}: TabProps) {
  const handleTabChange = (tab: any) => {
    const selectedTab = tab.key;
    onTabChange(selectedTab);
  };

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[selectedTab]}
      onClick={handleTabChange}
      style={{ background: 'none', fontSize: '16px' }}
    >
      {options.map((option) => (
        <Menu.Item key={option.key}>{option.label}</Menu.Item>
      ))}
    </Menu>
  );
}
