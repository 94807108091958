import { Button, Col, Modal, Row, Typography } from 'antd';

import { AccountWithProducerDocument } from '../../../../types/response/data/agents/agentDocument.type';
import { AgentService } from '../../../../services/agent.service';
import { IdConstants } from '../../../../constants/id.constants';
import { getNameString } from '../../../../utils/name.utils';
import { useAuth } from '../../../../auth/authProvider';
import { useState } from 'react';

type ModalProps = {
  visibility: boolean;
  handleVisibility: (isCancel?: boolean) => void;
  agentsToBeOffboarded: AccountWithProducerDocument[];
  setAgentsToBeOffboarded: (value: any) => void;
  reloadPage: () => void;
  setError?: (msg: string) => void;
  onSuccess?: () => void;
};

function OffboardConfirmModal(props: ModalProps) {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth();

  const offboardAgents = async () => {
    setLoading(true);
    if (props.agentsToBeOffboarded.length > 0) {
      const token = await getAccessTokenSilently();
      const response: any = await AgentService.offboardAgents(
        props.agentsToBeOffboarded.map((itr) => itr.producer.id),
        token
      ).catch((error) => {
        console.error(error);
        setLoading(false);
        if (props.setError) {
          let errorMessage =
            'Something went wrong on our end. Please try again in a few moments.';
          if (error?.data?.error?.message) {
            errorMessage = error?.data?.error?.message;
          }
          props.setError(errorMessage);
        }
      });

      if (props.onSuccess) {
        props.onSuccess();
      }
      if (response?.data?.message && props.setError) {
        props.setError(response.data.message);
      }
      props.setAgentsToBeOffboarded([]);
      props.handleVisibility();
      props.reloadPage();
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props.visibility}
      onCancel={() => !loading && props.handleVisibility(true)}
      closable
      maskClosable={false}
      centered
      footer={
        <Row justify="space-between">
          <Col>
            <Button
              id={
                IdConstants.AGENTS.OFFBOARD.OFFBOARD_CONFIRMATION_MODAL
                  .CONFIRM_BUTTON
              }
              size="large"
              disabled={loading}
              onClick={() => {
                props.setAgentsToBeOffboarded([]);
                props.handleVisibility(true);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              id={
                IdConstants.AGENTS.OFFBOARD.OFFBOARD_CONFIRMATION_MODAL
                  .CONFIRM_BUTTON
              }
              size="large"
              loading={loading}
              type="primary"
              className="button important-action-button"
              onClick={offboardAgents}
              style={{ width: '100%' }}
            >
              Offboard
            </Button>
          </Col>
        </Row>
      }
    >
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        Offboard{' '}
        {props.agentsToBeOffboarded.length === 1 ? `Producer` : `Producers`}
      </Typography.Paragraph>

      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '14px',
          fontWeight: '400',
        }}
      >
        Selected{' '}
        {props.agentsToBeOffboarded.length === 1 ? `Producer` : `Producers`}{' '}
        will be removed from your agency
      </Typography.Paragraph>

      <Row>
        <Col span={10}>
          <Typography.Paragraph style={{ color: '#787878', fontSize: '12px' }}>
            Producer
          </Typography.Paragraph>
        </Col>
        <Col>
          <Typography.Paragraph style={{ color: '#787878', fontSize: '12px' }}>
            NPN
          </Typography.Paragraph>
        </Col>
      </Row>
      {props.agentsToBeOffboarded.map((agent, index: number) => {
        return (
          <Row key={index}>
            <Col span={10}>
              <Typography.Paragraph style={{ fontSize: '12px' }}>
                {getNameString(agent.name)}
              </Typography.Paragraph>
            </Col>
            <Col>
              <Typography.Paragraph style={{ fontSize: '12px' }}>
                {agent.producer.npn}
              </Typography.Paragraph>
            </Col>
          </Row>
        );
      })}
    </Modal>
  );
}

export default OffboardConfirmModal;
