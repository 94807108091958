export enum TableType {
  ALL_AGENTS = 'ALL_AGENTS',
  MANAGE_ALL_AGENTS = 'MANAGE_ALL_AGENTS',
  DEPENDENT_AGENTS = 'DEPENDENT_AGENTS',
  ASSIGNED_AGENTS = 'ASSIGNED_AGENTS',
  GENERAL_AGENTS = 'GENERAL_AGENTS',
  LINE_ONLY_AGENTS = 'LINE_ONLY_AGENTS',
  UNASSIGNED_AGENTS = 'UNASSIGNED_AGENTS',
  AGENTS_LICENSES_OVERVIEW = 'AGENTS_LICENCES_OVERVIEW',
  AGENTS_APPOINTMENTS_OVERVIEW = 'AGENTS_APPOINTMENTS_OVERVIEW',
  AGENTS_SCORE_CARD_OVERVIEW = 'AGENTS_SCORE_CARD_OVERVIEW',
  AGENTS_TRANSACTIONS = 'AGENTS_TRANSACTIONS',
  AGENTS_LICENSES = 'AGENTS_LICENSES',
  AGENTS_APPOINTMENTS = 'AGENTS_APPOINTMENTS',
  AGENTS_ASSIGNMENTS = 'AGENTS_ASSIGNMENTS',
  ALL_LICENSES = 'ALL_LICENSES',
  VERIFY_LAST_NAME_SSN = 'VERIFY_NAME_SSN',
  VERIFY_NAME_NPN = 'VERIFY_NAME_NPN',
  VERIFY_NAME_LICENSE_STATE = 'VERIFY_NAME_LICENSE_STATE',
  HIERARCHY_VIEW = 'HIERARCHY_VIEW',
  INACTIVE_LICENSES = 'INACTIVE_LICENSES',
  AGENCY_DOWNLINE = 'AGENCY_DOWNLINE',
  AGENCY_UPLINE_INVITATION = 'AGENCY_UPLINE_INVITATION',
  AGENCY_DOWNLINE_INVITATION = 'AGENCY_DOWNLINE_INVITATION',
  AGENCY_UPLINES = 'AGENCY_UPLINES',
  ALLOCATION_HISTORY = 'ALLOCATION_HISTORY',
  MISSING_LICENSES = 'MISSING_LICENSES',
  MISSING_LOA = 'MISSING_LOA',
  DEPENDENT_LICENSES = 'DEPENDENT_LICENSES',
  ALL_ADMINS = 'ALL_ADMINS',
  INVITED_ADMINS = 'INVITED_ADMINS',
  BLACKLIST = 'BLACKLIST',
  INDIVIDUAL_ONBOARD_ASSIGNMENTS = 'INDIVIDUAL_ONBOARD_ASSIGNMENTS',
  INDIVIDUAL_LICENSES = 'INDIVIDUAL_LICENSES',
  INDIVIDUAL_INACTIVE_LICENSES = 'INDIVIDUAL_INACTIVE_LICENSES',
  INDIVIDUAL_MISSING_LICENSES = 'INDIVIDUAL_MISSING_LICENSES',
  INDIVIDUAL_MISSING_LOA = 'INDIVIDUAL_MISSING_LOA',
  UPDATE_AGENT_ADDRESS_IN_ADDRESS = 'UPDATE_AGENT_ADDRESS_IN_ADDRESS',
}
