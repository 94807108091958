import {
  DownlineAgencyHierarchyDetails,
  DownlineAgentHierarchyDetails,
  hierarchyViewStore,
} from '../../../../stores/hierarchyViewStore';
import { useEffect, useState } from 'react';

import DownlineCardWithPositionedLines from './downlineCardWithPositionedLines';
import EmptyState from '../../../../components/common/emptyState/emptyState';
import { Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';

type renderProps = {
  agencyId: string;
  currentChain: { id: string; agencyName: string }[];

  depth: number;
  handleCardClick: (
    agencyid: string,
    depth: number,
    agencyName: string
  ) => void;
};

function RenderColumn({
  agencyId,
  currentChain,
  depth,
  handleCardClick,
}: Readonly<renderProps>) {
  const [downlineAgents, setDownlineAgents] = useState<
    DownlineAgentHierarchyDetails[]
  >([]);
  const [downlineAgencies, setDownlineAgencies] = useState<
    DownlineAgencyHierarchyDetails[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    const distributionChannelDetails =
      hierarchyViewStore.distributionChannelDetails;
    if (depth === 1) {
      const downlineAgencyData = distributionChannelDetails
        ?.flatMap((d) => d.downlineAgencyDetails)
        ?.filter((d) => Boolean(d))
        .map((d) => ({ ...d, type: 'agency' }));
      const downlineAgentData = distributionChannelDetails
        ?.flatMap((d) => d.downlineAgentDetails)
        ?.filter((d) => Boolean(d))
        .map((d) => ({ ...d, type: 'producer' }));
      hierarchyViewStore.downlineAgencyCount = downlineAgencyData.length;
      hierarchyViewStore.downlineAgentCount = downlineAgentData.length;

      setDownlineAgencies(
        downlineAgencyData as DownlineAgencyHierarchyDetails[]
      );
      setDownlineAgents(downlineAgentData as DownlineAgentHierarchyDetails[]);
    } else {
      const selectedDistribution = distributionChannelDetails.filter(
        (d) => d.downlineAgencyDetails?._id === currentChain?.[1]?.id
      )?.[0];
      const selectedDistirubutionDownlines =
        selectedDistribution?.downlineHierarchy?.filter(
          (d) =>
            d.uplineAgencyId === currentChain?.[depth - 1]?.id &&
            d.level === depth - 2
        ) || [];
      const agencyHierarchy = [];
      const agentHiearchy = [];
      for (const distribution of selectedDistirubutionDownlines) {
        if (distribution?.downlineAgencyHierarchyDetails) {
          agencyHierarchy.push({
            ...distribution?.downlineAgencyHierarchyDetails,
            type: 'agency',
          });
        }
        if (distribution?.downlineAgentHierarchyDetails) {
          agentHiearchy.push({
            ...distribution?.downlineAgentHierarchyDetails,
            type: 'producer',
          });
        }
      }
      setDownlineAgencies(agencyHierarchy);
      setDownlineAgents(agentHiearchy);
    }
    // const downlineAgencyData = await hierarchyViewStore
    //   .getDistributionChannelDetails(agencyId, bearerToken)
    //   .catch((error: any) => {
    //     console.error(error);
    //   });
    // downlineAgencyData && setDownlineAgencies(downlineAgencyData);
    // todo downline agent details
    // const downlineAgentData = await hierarchyViewStore
    //   .getAgentDownline(agencyId, bearerToken)
    //   .catch((error: any) => {
    //     console.error(error);
    //   });
    // downlineAgentData && setDownlineAgents(downlineAgentData);

    // const name = await hierarchyViewStore
    //   .getAnAgencyName(agencyId)
    //   .catch((error: any) => {
    //     console.error(error);
    //   });
    // setAgencyName(name);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [agencyId, depth]);

  return (
    <div style={{ width: '220px', marginLeft: '0' }}>
      <div
        style={{
          border: '1px solid var(--border-color)',
          width: '100%',
          padding: '10px',
        }}
      >
        {depth === 1
          ? 'Downlines'
          : currentChain?.[depth - 1]?.agencyName ?? 'Downlines'}
      </div>
      <div
        style={{
          border: '1px solid var(--border-color)',
          borderTop: '0',
          padding: '10px 2px',
          height: '35dvh',
          width: '100%',
          overflowY: 'auto',
        }}
      >
        {isLoading ? (
          <Skeleton active />
        ) : (
          <div>
            {downlineAgencies.map((item, index: any) => {
              return (
                <div
                  id={`hierarchy-view-${depth}-depth-${item._id}`}
                  key={item._id}
                  style={{
                    display: 'flex',
                    columnGap: '10px',
                    width: '100%',
                    padding: '5px 5px',
                    cursor: 'pointer',
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleCardClick(item._id, depth, item.name);
                    }
                  }}
                  onClick={() => {
                    handleCardClick(item._id, depth, item.name);
                  }}
                >
                  <DownlineCardWithPositionedLines
                    item={item}
                    index={index}
                    isLastIndex={index === downlineAgencies.length - 1}
                    currentChain={[]}
                  />
                </div>
              );
            })}
            {downlineAgents.map((item: any, index: any) => {
              return (
                <div
                  key={item.id}
                  style={{
                    display: 'flex',
                    columnGap: '10px',
                    width: '100%',
                    padding: '5px 5px',
                  }}
                >
                  <DownlineCardWithPositionedLines
                    item={item}
                    index={index}
                    isLastIndex={index === downlineAgents.length - 1}
                    currentChain={currentChain}
                  />
                </div>
              );
            })}
            {downlineAgencies.length < 1 &&
              downlineAgents.length < 1 &&
              !isLoading && (
                <div
                  style={{
                    height: '80%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <EmptyState
                    heading="No downlines to show"
                    content="Invite downlines and they will start showing up here once
                      they accept your invite"
                  />
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
}
export default observer(RenderColumn);
