import {
  ApplicationDetails,
  DetailsCard,
  DetailsContainer,
  DetailsHeading,
  ErrorList,
  ErrorListItem,
  InsuranceLayout,
  Insurancetypes,
  LicenseDetails,
  ModalContainer,
  TextDetail,
  TransactionDetails,
} from './applicationstatus.style';
import React, { useEffect, useState } from 'react';
import {
  TransStatus,
  TransStatusLabels,
} from '../../../enums/nipr/transactionStatus.enum';

import ApplicationStatusCell from '../../../components/licenses/applicationStatusCell';
import { ClickableLinkText } from '../../../components/clickableLink';
import LicenseStatusCell from '../../../components/licenses/licenseStatusCell';
import { Modal } from 'antd';
import { PortalType } from '../../../enums/portalType.enum';
import { StateConstants } from '../../../constants/state.constants';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { capitalizeFirstLetter } from '../../common.utils';
import { costFormatter } from '../../input.utils';
import { getNameString } from '../../name.utils';
import { getNiprResponseFromLicenseForModal } from '../../nipr/license.utils';
import { isArray } from 'lodash';
import moment from 'moment';

interface LicenseModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  agentLicenseDetails: any | undefined;
  portal: PortalType;
  showTransactionDetails: boolean;
  autoRenewEnabled: boolean;
  hideFees?: boolean;
  hideProducerName?: boolean;
}

const LicenseModal: React.FC<LicenseModalProps> = ({
  visible,
  setVisible,
  agentLicenseDetails,
  portal,
  showTransactionDetails,
  autoRenewEnabled,
  hideFees,
  hideProducerName,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [niprResponses, setNiprResponses] = useState<string[]>([]);

  useEffect(() => {
    if (agentLicenseDetails) {
      const niprResponse =
        getNiprResponseFromLicenseForModal(agentLicenseDetails);
      if (
        niprResponse.errors.length === 0 ||
        agentLicenseDetails.gatewayTransaction.submissionSuccess
      ) {
        setNiprResponses(niprResponse.messages);
        if (niprResponse.errors && niprResponse.errors.length)
          setWarnings(niprResponse.errors);
        setErrors([]);
      } else {
        setErrors(niprResponse.errors);
        setNiprResponses([]);
      }
    }
  }, [agentLicenseDetails]);

  const stateFee =
    agentLicenseDetails?.gatewayTransaction?.niprGatewaySuccessResponse
      ?.stateFee || 0;
  const transFee =
    agentLicenseDetails?.gatewayTransaction?.niprGatewaySuccessResponse
      ?.transFee || 0;
  const fees = stateFee + transFee;

  return agentLicenseDetails && Object.keys(agentLicenseDetails).length ? (
    <Modal
      width={'850px'}
      open={visible}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}
      okText="Ok"
      footer={null}
    >
      <ModalContainer>
        <ApplicationDetails>
          <DetailsContainer>
            <DetailsHeading>License Details</DetailsHeading>
            <DetailsCard>
              {PortalType.AGENCY === portal && !hideProducerName && (
                <LicenseDetails>
                  <TextDetail>Producer</TextDetail>
                  <TextDetail fontSize={'14px'}>
                    {agentLicenseDetails.producerDetails?.name
                      ? getNameString(agentLicenseDetails.producerDetails?.name)
                      : agentLicenseDetails.producer
                      ? getNameString(agentLicenseDetails.producer[0]?.name)
                      : ''}
                  </TextDetail>
                  <TextDetail></TextDetail>
                </LicenseDetails>
              )}
              <LicenseDetails>
                <TextDetail>State</TextDetail>
                <TextDetail textColor={'#0588ca'} fontSize={'14px'}>
                  {isArray(agentLicenseDetails?.license) &&
                  agentLicenseDetails?.license.length
                    ? StateConstants[agentLicenseDetails?.license[0].stateCode]
                    : StateConstants[agentLicenseDetails?.stateCode]
                    ? StateConstants[agentLicenseDetails?.stateCode]
                    : StateConstants[agentLicenseDetails?.license.stateCode]
                    ? StateConstants[agentLicenseDetails?.license.stateCode]
                    : agentLicenseDetails?.license?.stateName
                    ? agentLicenseDetails?.license?.stateName
                    : ''}
                </TextDetail>
                <TextDetail></TextDetail>
              </LicenseDetails>
              <LicenseDetails>
                <TextDetail>License Status</TextDetail>
                <TextDetail fontSize={'14px'}>
                  <LicenseStatusCell
                    licenseStatus={
                      isArray(agentLicenseDetails?.license) &&
                      agentLicenseDetails?.license.length
                        ? agentLicenseDetails?.license[0].status
                        : agentLicenseDetails?.status
                        ? agentLicenseDetails?.status
                        : ''
                    }
                  />
                </TextDetail>
              </LicenseDetails>
              <LicenseDetails>
                <TextDetail>Application Status</TextDetail>
                <ApplicationStatusCell
                  applicationStatus={
                    agentLicenseDetails?.latestApplicationStatus
                      ? agentLicenseDetails?.latestApplicationStatus
                      : agentLicenseDetails?.status
                      ? agentLicenseDetails.status.charAt(0).toUpperCase() +
                        agentLicenseDetails.status.slice(1)
                      : ''
                  }
                  showViewDetails={false}
                />
              </LicenseDetails>
            </DetailsCard>
            <LicenseDetails style={{ width: '100%' }}>
              <TextDetail>License Class & LOAs</TextDetail>
              <TextDetail fontSize={'12px'}>
                {isArray(agentLicenseDetails?.licenseConfigurations) &&
                agentLicenseDetails?.licenseConfigurations.length
                  ? agentLicenseDetails?.licenseConfigurations[0]?.licenseClass
                  : agentLicenseDetails?.licenseConfigurations?.licenseClass}
              </TextDetail>
              <Insurancetypes>
                {isArray(agentLicenseDetails?.licenseConfigurations)
                  ? agentLicenseDetails?.licenseConfigurations.map(
                      (loaDetails: any) => (
                        <InsuranceLayout>
                          <TextDetail
                            style={{ textAlign: 'center' }}
                            fontSize={'12px'}
                          >
                            {loaDetails.loa}
                          </TextDetail>
                        </InsuranceLayout>
                      )
                    )
                  : agentLicenseDetails?.licenseConfigurations?.loaDetails.map(
                      (loaDetail: any) => (
                        <InsuranceLayout>
                          <TextDetail
                            style={{ textAlign: 'center' }}
                            fontSize={'12px'}
                          >
                            {loaDetail?.loa}
                          </TextDetail>
                        </InsuranceLayout>
                      )
                    )}
              </Insurancetypes>
            </LicenseDetails>
            <DetailsCard>
              <TransactionDetails>
                <TextDetail>Type</TextDetail>
                <TextDetail fontSize={'12px'}>
                  {capitalizeFirstLetter(
                    agentLicenseDetails?.applicationType?.toString()
                  )}
                </TextDetail>
              </TransactionDetails>
              <TransactionDetails>
                <TextDetail>Renewal Type</TextDetail>
                <TextDetail fontSize={'12px'}>
                  {autoRenewEnabled ? 'Auto Apply' : 'Manual Apply'}
                </TextDetail>
              </TransactionDetails>
              <TransactionDetails width={'160px'}>
                <TextDetail>NIPR Status</TextDetail>
                <TextDetail fontSize={'12px'}>
                  {agentLicenseDetails?.gatewayTransaction
                    ?.niprGatewayTransactionResponseDto?.transaction
                    ?.transStatus
                    ? capitalizeFirstLetter(
                        TransStatusLabels[
                          agentLicenseDetails?.gatewayTransaction
                            ?.niprGatewayTransactionResponseDto?.transaction
                            ?.transStatus as TransStatus
                        ]
                      )
                    : agentLicenseDetails?.gatewayTransaction
                        ?.niprGatewayTransactionResponseDto?.errors
                    ? 'Application failed'
                    : 'No response found'}
                </TextDetail>
              </TransactionDetails>
            </DetailsCard>
          </DetailsContainer>

          {showTransactionDetails && (
            <DetailsContainer>
              <DetailsHeading>Transaction Details</DetailsHeading>
              <DetailsContainer>
                <DetailsCard>
                  <TransactionDetails width="248px">
                    <TextDetail>Transaction ID</TextDetail>
                    <TextDetail fontSize={'14px'}>
                      {agentLicenseDetails?.id}
                    </TextDetail>
                  </TransactionDetails>
                  <TransactionDetails>
                    <TextDetail>Fee</TextDetail>
                    {!hideFees && fees ? (
                      <TextDetail fontSize={'14px'}>
                        $ {costFormatter(fees)}{' '}
                      </TextDetail>
                    ) : (
                      <div style={{ marginLeft: 6 }}> - </div>
                    )}
                  </TransactionDetails>
                  {agentLicenseDetails?.applyDate && (
                    <TransactionDetails width={'140px'}>
                      <TextDetail>Applied at</TextDetail>
                      <TextDetail fontSize={'12px'}>
                        {agentLicenseDetails?.applyDate
                          ? moment(
                              new Date(agentLicenseDetails?.applyDate)
                            ).format('lll')
                          : '-'}
                      </TextDetail>
                    </TransactionDetails>
                  )}
                </DetailsCard>
              </DetailsContainer>
            </DetailsContainer>
          )}

          {errors.length > 0 && (
            <DetailsContainer>
              <DetailsHeading>
                Application {errors.length === 1 ? 'Error' : 'Errors'}
              </DetailsHeading>
              <ErrorList>
                {errors.map((data, index) => (
                  <ErrorListItem key={index}>
                    {ClickableLinkText(data)}
                  </ErrorListItem>
                ))}
              </ErrorList>
            </DetailsContainer>
          )}
          {niprResponses.length > 0 && (
            <DetailsContainer>
              <DetailsHeading>
                {niprResponses.length === 1 ? 'NIPR Message' : 'NIPR Messages'}
              </DetailsHeading>
              <ErrorList>
                {niprResponses.map((data, index) => (
                  <ErrorListItem key={index}>
                    {ClickableLinkText(data)}
                  </ErrorListItem>
                ))}
              </ErrorList>
            </DetailsContainer>
          )}
          {warnings.length > 0 && (
            <DetailsContainer>
              <DetailsHeading>
                Application {errors.length === 1 ? 'Warning' : 'Warnings'}
              </DetailsHeading>
              <ErrorList>
                {warnings.map((data, index) => (
                  <ErrorListItem key={index}>
                    {ClickableLinkText(data)}
                  </ErrorListItem>
                ))}
              </ErrorList>
            </DetailsContainer>
          )}
        </ApplicationDetails>
      </ModalContainer>
    </Modal>
  ) : (
    <></>
  );
};

export default LicenseModal;
