import { Row, Tooltip, Typography } from 'antd';

import { getPerpetualStateNames } from '../../constants/perpetualStates.constants';

interface StateCellProps {
  state: string;
  licenseNumber?: string;
  hidePerpetualTag?: boolean;
}
function StateCell({ state, licenseNumber, hidePerpetualTag }: StateCellProps) {
  return (
    <Row align="middle" style={{ marginRight: '5px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }}>
        {!hidePerpetualTag && (
          <Tooltip title="Perpetual State">
            <div
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                border: '1.5px solid blue',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '3px',
                visibility: getPerpetualStateNames().includes(state)
                  ? 'visible'
                  : 'hidden',
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: '10px',
                  color: 'blue',
                  cursor: 'default',
                }}
              >
                P
              </p>
            </div>
          </Tooltip>
        )}
        <div>
          <Typography.Paragraph
            style={{
              color: 'var(--secondary-color)',
              fontSize: '14px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
              marginBottom: licenseNumber ? '1em' : '0px',
            }}
          >
            {state}
          </Typography.Paragraph>
          {licenseNumber && (
            <Typography.Paragraph
              style={{
                fontSize: '11px',
                fontWeight: 400,
                marginTop: '-10px',
                marginBottom: '0px',
              }}
            >
              {licenseNumber}
            </Typography.Paragraph>
          )}
        </div>
      </div>
    </Row>
  );
}

export default StateCell;
