import AllLicensesAgentsTab from './all-licenses-agents-tab';
import { RoleType } from '../../../../enums/roles.enum';
import { withRoles } from '../../../../auth/useRoles';

interface AllLicensesProps {
  isAgentOverview?: boolean;
}

const AllLicensesTabView: React.FC<AllLicensesProps> = ({
  isAgentOverview,
}) => {
  return (
    <div style={{ width: '100%' }} className="onboard-active">
      {<AllLicensesAgentsTab {...{ isAgentOverview }} />}
    </div>
  );
};

export default withRoles(AllLicensesTabView, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
