export interface Invite {
  id: string;
  inviteToken: string;
  invitedUsing: string;
  inviteType: InviteType;
  inviterDetails: {
    agency: any;
    account: any;
  };
  inviteeDetails: {
    email: string;
    role?: string;
    npn?: string;
  };
  status: string;
  invitedOn: Date;
  expiryDate: Date;
}

export enum InviteType {
  downlineInvite = 'downline',
  userInvite = 'user',
}

export const InviteTypeLabels: { [key in InviteType]: string } = {
  [InviteType.downlineInvite]: 'Downline Invite',
  [InviteType.userInvite]: 'User Invite',
};
