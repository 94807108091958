import { Name } from '../types/data/name.type';

export const getNameString = (name: Name | string): string => {
  if (!name) return '';
  if (name instanceof String || typeof name === 'string') return name as string;
  const firstName = name?.firstName ?? '';
  const lastName = name?.lastName ?? '';
  return firstName.trim() + ' ' + lastName.trim();
};

export const convertStringToCaps = (input: string): string => {
  // Convert the string to uppercase and replace underscores with empty spaces
  if (!input) return input;
  const result = input.toUpperCase().replace(/_/g, ' ').replace(/-/g, ' ');
  return result;
};

export const cleanAndCapitalizeString = (name: string): string => {
  const cleanedValue = name.replace(/[^A-Za-z\s]/g, '');
  return cleanedValue.charAt(0).toUpperCase() + cleanedValue.slice(1);
};
