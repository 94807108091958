import React from 'react';

const AgencyAssignedIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="27" height="19" rx="3.5" fill="#F1F8FE" />
      <rect x="0.5" y="0.5" width="27" height="19" rx="3.5" stroke="#437EE1" />
      <path
        d="M20.0001 14.6657H21.3334V15.999H6.66675V14.6657H8.00008V4.66569C8.00008 4.2975 8.29856 3.99902 8.66675 3.99902H15.3334C15.7016 3.99902 16.0001 4.2975 16.0001 4.66569V14.6657H18.6667V9.33236H17.3334V7.99902H19.3334C19.7016 7.99902 20.0001 8.2975 20.0001 8.66569V14.6657ZM9.33341 5.33236V14.6657H14.6667V5.33236H9.33341ZM10.6667 9.33236H13.3334V10.6657H10.6667V9.33236ZM10.6667 6.66569H13.3334V7.99902H10.6667V6.66569Z"
        fill="black"
      />
    </svg>
  );
};

export default AgencyAssignedIcon;
