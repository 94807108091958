import { Button, Tour } from 'antd';
import { useEffect, useState } from 'react';

import { IdConstants } from '../../../constants/id.constants';
import { TourConstants } from '../../../constants/tour.constant';
import type { TourProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../../stores/tour.store';

const tourTitleStyle = {
  margin: 0,
  gap: 8,
};

const TourTitle = () => {
  return (
    <div style={{ margin: 0, paddingTop: 6 }}>
      <h2 style={tourTitleStyle}>Producer Wise View</h2>
    </div>
  );
};

const RtsByProducer = () => {
  const siderMenyByProducer = document.getElementById(
    TourConstants.SIDER_MENU.RTS.BY_PRODUCER
  );
  const rtsButton = document.getElementsByClassName(
    IdConstants.RTS.BY_PRODUCER.VIEW_BUTTON
  )?.[0] as HTMLElement;
  const appointmentsCard = document.getElementsByClassName(
    IdConstants.RTS.BY_PRODUCER.APPOINTMENTS_CARD
  )?.[0] as HTMLElement;
  const appointmentsCardBody = appointmentsCard?.querySelector(
    `.${IdConstants.ANT_CARD_BODY}`
  ) as HTMLElement;
  const assignmentsCard = document.getElementsByClassName(
    IdConstants.RTS.BY_PRODUCER.ASSIGNMENTS_CARD
  )?.[0] as HTMLElement;
  const assignmentsCardBody = assignmentsCard?.querySelector(
    `.${IdConstants.ANT_CARD_BODY}`
  ) as HTMLElement;

  const [currentStep, setCurrentStep] = useState(0);
  const stepsCount = 2;

  useEffect(() => {
    setCurrentStep(tourStore.rtsByProducerCurrentStep);
  }, [tourStore.rtsByProducerCurrentStep]);

  const resetStore = () => {
    tourStore.setRtsByProducerCurrentStep(0);
    tourStore.setRtsByProducerTour(false);
    tourStore.setRtsByProducerVisible(false);
    const isSignupDemo = sessionStorage.getItem(
      TourConstants.SIGNUP_DEMO_BY_SECTION
    );
    if (isSignupDemo) {
      siderMenyByProducer?.click();
      tourStore.setSignUpDemoTour(true);
      sessionStorage.removeItem(TourConstants.SIGNUP_DEMO_BY_SECTION);
    }
  };

  const updateCurrentStep = (isNext: boolean) => {
    if (isNext) {
      if (currentStep + 1 > stepsCount) {
        resetStore();
      } else {
        tourStore.setRtsByProducerCurrentStep(true);
      }
      setCurrentStep((currentStep) => currentStep + 1);
    } else {
      tourStore.setRtsByProducerCurrentStep(false);
      setCurrentStep((currentStep) => currentStep - 1);
    }
  };

  const handlePrevClick = (
    prev: HTMLElement | [HTMLElement, string, number] | null
  ) => {
    const prevElement = Array.isArray(prev) ? prev?.[0] : prev;
    if (!prevElement) return;

    if (Array.isArray(prev)) {
      prevElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(prev?.[1]);
        elem?.click();
      }, prev?.[2]);
    } else {
      prevElement?.click();
    }
    updateCurrentStep(false);
  };

  const handleNextClick = (
    next: HTMLElement | [HTMLElement, string, number] | null,
    selectChild?: boolean
  ) => {
    const nextElement = Array.isArray(next) ? next?.[0] : next;
    if (!nextElement) {
      updateCurrentStep(true);
      return;
    }

    if (Array.isArray(next)) {
      nextElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(next?.[1]);
        elem?.click();
        updateCurrentStep(true);
      }, next?.[2]);
    } else {
      nextElement?.click();
      if (selectChild) {
        const elem = nextElement.querySelector('*') as HTMLElement;
        elem?.click();
      }
      updateCurrentStep(true);
    }
  };

  const navigateTour = (
    prev: HTMLElement | [HTMLElement, string, number] | null,
    next: HTMLElement | [HTMLElement, string, number] | null,
    cb?: (isNext: boolean) => void,
    selectChild?: boolean
  ) => {
    return (
      <div className="antd-navigate-footer">
        <div>
          {currentStep + 1} of {stepsCount + 1}
        </div>
        <div className="flex" style={{ gap: 12 }}>
          <Button
            size="small"
            style={{
              width: 90,
            }}
            onClick={() => {
              resetStore();
            }}
          >
            Skip
          </Button>
          {![0].includes(currentStep) && (
            <Button
              size="small"
              style={{
                width: 90,
              }}
              onClick={() => {
                if (cb) cb(false);
                handlePrevClick(prev);
              }}
            >
              Previous
            </Button>
          )}
          <Button
            size="small"
            style={{
              width: 90,
              paddingLeft: 0,
            }}
            onClick={() => {
              if (cb) cb(true);
              handleNextClick(next, selectChild);
            }}
            type="primary"
          >
            {currentStep === stepsCount ? 'Finish' : 'Next'}
          </Button>
        </div>
      </div>
    );
  };

  const steps: TourProps['steps'] = [
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          In Producer View, click to access RTS reports for specific producers.
          {navigateTour(null, rtsButton, (isNext: boolean) => {
            if (!isNext) return;
            tourStore.setRtsByProducerVisible(false);
            setTimeout(() => {
              tourStore.setRtsByProducerCurrentStep(1);
              tourStore.setRtsByProducerVisible(true);
            }, 100);
          })}
        </div>
      ),
      target: rtsButton,
      placement: 'left',
    },
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          Explore producer licenses by state, LC, and LOA. Track NIPR polling
          status and refresh as needed.
          {navigateTour(null, null, (isNext: boolean) => {
            if (isNext) return;
            window.history.back();
            tourStore.setRtsByProducerVisible(false);
            tourStore.setRtsByProducerCurrentStep(0);
          })}
        </div>
      ),
      target: assignmentsCardBody,
      placement: 'left',
      scrollIntoViewOptions: false,
    },
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          Discover producer appointments by carrier and state. Expand dropdowns
          to view carrier details, LOA, status, and renewal dates.
          {navigateTour(assignmentsCardBody, null)}
        </div>
      ),
      target: appointmentsCardBody,
      placement: 'left',
      scrollIntoViewOptions: false,
    },
  ];

  return (
    <>
      {tourStore.rtsByProducerVisible && <div className="overlay" />}
      <Tour
        rootClassName="onboard-antd"
        open={tourStore.rtsByProducerVisible}
        onClose={() => {
          resetStore();
        }}
        steps={steps}
        current={currentStep}
      />
    </>
  );
};

export default observer(RtsByProducer);
