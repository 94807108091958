import {
  AgencyRtsTableProps,
  AgentsRtsTableType,
  AgentsType,
  CarriersType,
  StateType,
} from './agencyRtsTypes';
import { Key, useEffect } from 'react';
import { groupBy, sortBy } from 'lodash';

import { Appointment } from '../../../types/data/rts.type';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { RouteConstants } from '../../../constants/routes.constants';
import { StateConstants } from '../../../constants/state.constants';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import TextWithIcon from '../../../components/common/textWithIcon/textWithIcon';
import { expandAgents } from './agencyRts';
import { getNameString } from '../../../utils/name.utils';

export const AgentRtsHeader = ({ record }: { record: AgentsType }) => {
  return (
    <div className="flex align-items-center justify-between">
      <p> {record.Agents} </p>
      <p className="px-6 cursor-pointer">
        <Link
          to={RouteConstants.RTSViewAgentsFromTable.path.replace(
            ':agentId',
            record.agentId
          )}
        >
          <TextWithIcon
            iconAlignment="right"
            text="View Details"
            icon={<RightOutlined style={{ color: 'var(--primary-color)' }} />}
          />
        </Link>
      </p>
    </div>
  );
};

export const AgentRtsCompanyHeader = ({ record }: { record: CarriersType }) => {
  if (!record.companyName) return <span>Unknown</span>;
  return (
    <span
      style={{ padding: '3px' }}
      className="flex align-items-center justify-between"
    >
      <span>{record.companyName}</span>
      <span className="pr-6">
        Company Code :{' '}
        <b
          className="px-6"
          style={{ minWidth: '60px', display: 'inline-block' }}
        >
          {record.coCode}
        </b>
      </span>
    </span>
  );
};

interface AgentData {
  [key: string]: {
    [companyName: string]: AgentsRtsTableType[];
  };
}

const AgencyRtsByCarrierTable: React.FC<AgencyRtsTableProps> = ({
  expandedKeys,
  setExpandedKeys,
  tableData,
  isExpanded,
  moveToTop,
  setAllExpandedKeys,
}) => {
  let updatedData: Record<string, any[]> = {};
  tableData?.forEach((d) => {
    updatedData[d.appointments?.[0]?.companyName] = d?.appointments;
  });
  let agentData: AgentData = {};
  Object.keys(updatedData).map((key: string) => {
    agentData[key] = {
      ...groupBy(updatedData[key], 'stateCode'),
    };
  });

  useEffect(() => {
    if (!isExpanded) return;
    const stateKeys: Key[] = [];
    const carrierKeys: Key[] = [];
    const agentKeys: Key[] = [];
    Object.keys(agentData).forEach((companyName: string) => {
      carrierKeys.push(companyName);
      Object.keys(agentData[companyName]).forEach((state) => {
        stateKeys.push(companyName + state);
        agentData?.[companyName]?.[state]?.forEach((agents) => {
          agentKeys.push(agents?.producer?.id + companyName + state);
        });
      });
    });
    setExpandedKeys({
      state: stateKeys,
      carrier: carrierKeys,
      agent: agentKeys,
    });
    setAllExpandedKeys((prev) => ({
      ...prev,
      carrier: {
        state: stateKeys,
        carrier: carrierKeys,
        agent: agentKeys,
      },
    }));
  }, [isExpanded]);

  // Carriers Table
  const expandCarriers = (event: CarriersType) => {
    const columns: TableColumnsType<StateType> = [
      {
        dataIndex: 'state',
        key: 'state',
        render: (text) => {
          return <span>{StateConstants[text]}</span>;
        },
      },
    ];

    const data: StateType[] = [];
    updatedData[event.companyName].forEach((key, index) => {
      if (
        data.map((data) => data.key).includes(key.companyName + key.stateCode)
      )
        return;
      data.push({
        key: key.companyName + key.stateCode,
        state: key.stateCode,
      });
    });
    data.sort((a, b) => a.state.localeCompare(b.state));

    const handleStateRowClick = (record: StateType) => {
      const key = record.key;
      const isExpanded = expandedKeys.state.includes(key);
      if (isExpanded) {
        setExpandedKeys((prevState) => ({
          ...prevState,
          state: prevState.state.filter((k) => k !== key),
        }));
      } else {
        setExpandedKeys((prevState) => ({
          ...prevState,
          state: [...prevState.state, key],
        }));
      }
    };

    return (
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (e) => expandStates(e, event.companyName),
          defaultExpandedRowKeys: data.map((i) => i.key),
          expandedRowKeys: expandedKeys.state,
          onExpand: (expanded, record) => handleStateRowClick(record),
        }}
        onRow={(record) => ({
          onClick: () => handleStateRowClick(record),
        })}
        dataSource={data}
        size="small"
        pagination={false}
      />
    );
  };
  //

  const expandStates = (e: StateType, companyName: string) => {
    const columns: TableColumnsType<AgentsType> = [
      {
        dataIndex: 'Agents',
        key: 'Agents',
        render: (text, record) => <AgentRtsHeader record={record} />,
      },
    ];

    const datas: AgentsType[] = [];
    const updatedFields: any[] = [];
    agentData[companyName]?.[e.state]?.forEach((key, index) => {
      updatedFields.push(key);
      if (
        datas
          .map((data) => data.key)
          .includes(key?.producer?.id + companyName + e.state)
      )
        return;
      datas.push({
        key: key?.producer?.id + companyName + e.state,
        Agents: getNameString(key?.producer?.name),
        agentId: key?.producer?.id,
      });
    });
    datas.sort((a, b) => a.Agents.localeCompare(b.Agents));

    const handleAgentRowClick = (record: AgentsType) => {
      const key = record.key;
      const isExpanded = expandedKeys.agent.includes(key);
      if (isExpanded) {
        setExpandedKeys((prevState) => ({
          ...prevState,
          agent: prevState.agent.filter((k) => k !== key),
        }));
      } else {
        setExpandedKeys((prevState) => ({
          ...prevState,
          agent: [...prevState.agent, key],
        }));
      }
    };

    return (
      <Table
        columns={columns}
        dataSource={datas}
        style={{ margin: '6px 0' }}
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleAgentRowClick(record), // Handle row click
        })}
        expandable={{
          expandedRowRender: (e) =>
            expandAgents(e, updatedFields as Appointment[]),
          expandedRowKeys: expandedKeys.agent,
          onExpand: (expanded, record) => handleAgentRowClick(record),
        }}
      />
    );
  };

  const columns: TableColumnsType<CarriersType> = [
    {
      title: 'Carriers',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <AgentRtsCompanyHeader record={record} />,
    },
  ];

  const data: CarriersType[] = Object.keys(updatedData).map((key, index) => ({
    key: key,
    companyName: key,
    coCode: updatedData[key]?.[0]?.coCode,
  }));
  const sortedData: CarriersType[] = sortBy(data, 'companyName');

  Object.keys(updatedData).forEach((key, index) => {
    data.push({
      key: key,
      companyName: key,
      coCode: updatedData[key]?.[0]?.coCode,
    });
  });

  const handleCarrierRowClick = (record: CarriersType) => {
    const key = record.key;
    const isExpanded = expandedKeys.carrier.includes(key);
    if (isExpanded) {
      setExpandedKeys((prevState) => ({
        ...prevState,
        carrier: prevState.carrier.filter((k) => k !== key),
      }));
    } else {
      setExpandedKeys((prevState) => ({
        ...prevState,
        carrier: [...prevState.carrier, key],
      }));
    }
  };

  return (
    <>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: expandCarriers,
          expandedRowKeys: expandedKeys.carrier,
          onExpand: (expanded, record) => handleCarrierRowClick(record),
        }}
        onRow={(record) => ({
          onClick: () => handleCarrierRowClick(record),
        })}
        dataSource={sortedData}
        size="small"
        pagination={{
          defaultPageSize: 10,
          onChange: () => {
            moveToTop();
          },
        }}
      />
    </>
  );

  //
};

export default AgencyRtsByCarrierTable;
