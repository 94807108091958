import {
  ExclamationCircleOutlined,
  RightCircleFilled,
  RightCircleOutlined,
} from '@ant-design/icons';
import React, { ReactNode, useState } from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

const ErrorDiv = styled.div`
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  border-width: ${(props) => (props.color === 'hover' ? '1.5px' : '1px')};
  color: red;
  border-color: red;
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  position: relative;
  transition: opacity 0.3s ease border-width 0.3s ease;
  cursor: ${(props) => (props.color === 'hover' ? 'pointer' : 'auto')};
`;

type RequirementErrorDivProps = {
  text: string | ReactNode;
  onClick: () => void;
  isLoading: boolean;
};

function RequirementErrorDiv(props: RequirementErrorDivProps) {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <ErrorDiv
      color={isHovered && !props.isLoading ? 'hover' : ''}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={props.onClick}
    >
      <ExclamationCircleOutlined style={{ marginTop: '4px' }} />
      <Typography>{props.text}</Typography>
      <RightCircleFilled
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          opacity: isHovered && !props.isLoading ? '1' : '0',
          transition: 'opacity 0.3s ease',
        }}
      />
      <RightCircleOutlined
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          opacity: isHovered && !props.isLoading ? '0' : '1',
          transition: 'opacity 0.3s ease',
        }}
      />
    </ErrorDiv>
  );
}

export default RequirementErrorDiv;
