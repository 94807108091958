import axios, { CancelTokenSource } from 'axios';

import { AdvancedFilters } from '../types/data/advancedFilters.type';
import { AllLicenseResponse } from '../types/response/all-license.type';
import { AllLicensesAgencyResponseType } from '../types/request/agency/allLicenses/allLicenses.type';
import { ApplicationTypeEnumInBackend } from '../enums/applicationType.enum';
import { ErrorResponse } from '../types/response/errorResponse.type';
import { LicenseApplicationResponse } from '../types/response/license-application.type';
import { LicenseFilters } from '../types/data/licenseFilters.type';
import { LicenseLookupState } from '../types/request/agents/bulkLookup.type';
import { ObjectSuccessResponse } from '../types/response/objectSuccessResponse.type';
import { PaginatedSuccessResponse } from '../types/response/paginatedSuccessResponse.type';
import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';
import { cancelPreviousRequest } from '../utils/api.utils';
import { filterRequestBody } from '../utils/common.utils';

export class LicensesService {
  static host = UrlConstants.backend;
  static modulePath = '/licenses/{id}';

  // endpoint -> /license/my-agency/renewals
  // renewal licenses  - agency portal
  static async getRenewalLicensesForAgency(
    bearerToken: string,
    size: number,
    page: number,
    searchString: string,
    searchType: string,
    requestBody: Object = {}
  ): Promise<PaginatedSuccessResponse<LicenseApplicationResponse>> {
    const path = '/license/renewals';
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    // Process requestBody using filterRequestBody function
    filterRequestBody(requestBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(apiStore.getTokenForRenewals());
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForRenewals(cancelTokenSource);

        const AllLicenses = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(`${url_}`);
        const response = AllLicenses?.data?.data;
        const res = response?.map((data: any) => ({
          license: {
            licenseNumber: data?.licenseNumber,
            status: data?.status,
            state: data?.state,
            stateName: data?.stateName,
            licenseClass: data?.licenseClass,
            loaDetails: data?.loaDetails,
            latestApplication: data?.latestApplication,
          },
          ...(data || {}),
          status: data?.latestApplication?.status || 'not_applied',
        }));

        resolve({
          ...(AllLicenses?.data || []),
          data: res,
        } as PaginatedSuccessResponse<LicenseApplicationResponse>);
      } catch (error: any) {
        console.error(
          'LicenseService :: Renewals :: Error :: ',
          error?.message
        );
        if (!axios.isCancel(error)) {
          apiStore.setTokenForRenewals(undefined);
        }
        reject(error);
      }
    });
  }

  // endpoint -> /license/my-agency/renewals/filter-options
  // renewal licenses  filters - agency portal
  static async getFilterInfoForRenewals(
    bearerToken: string,
    requestBody: Object
  ): Promise<any> {
    const path = '/license/renewal/filters';

    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: Renewals :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> /license/actionable/my-agency
  // actionable licenses - agency portal
  static async getActionableLicensesForAgency(
    applicationType: ApplicationTypeEnumInBackend | null,
    bearerToken: string,
    size: number,
    page: number,
    searchString: string,
    searchType: string,
    requestBody: Object = {},
    agentId?: string
  ): Promise<PaginatedSuccessResponse<LicenseApplicationResponse>> {
    const path = agentId
      ? `/license/producer/${agentId}/needs-attention`
      : '/license/needs-attention';
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    // Process requestBody using filterRequestBody function
    filterRequestBody(requestBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(
          apiStore.getTokenForGetActionableLicensesForAgency()
        );
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetActionableLicensesForAgency(cancelTokenSource);

        const AllLicenses = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(
            `${url_}`
            // { ...requestBody }
          );

        resolve(
          AllLicenses.data as PaginatedSuccessResponse<LicenseApplicationResponse>
        );
      } catch (error: any) {
        console.error(
          'LicenseService :: getLicenses :: Error :: ',
          error?.message
        );
        if (!axios.isCancel(error)) {
          apiStore.setTokenForGetApplications(undefined);
        }
        reject(error);
      }
    });
  }
  /**
   * Returns filter info for users table
   * @param applicationType
   * @param token
   * @param requestBody
   * @returns
   */

  static async getFilterInfoForFindActionable(
    applicationType: ApplicationTypeEnumInBackend,
    bearerToken: string,
    requestBody: Object
  ): Promise<any> {
    const path = `/license/needs-attention/filters${
      applicationType === ApplicationTypeEnumInBackend.missing
        ? '?type=missing'
        : ''
    }`;

    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getFilterInfoForFindActionable :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for users table
   * @param applicationType
   * @param token
   * @param requestBody
   * @returns
   */

  static async getFilterInfoForFindAgentActionable(
    applicationType: ApplicationTypeEnumInBackend,
    bearerToken: string,
    requestBody: Object
  ): Promise<any> {
    const path = '/license/needs-attention/filters';

    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getFilterInfoForFindAgentActionable :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }
  /**
   * Returns filter info for agent application table
   * @param applicationType
   * @param token
   * @param requestBody
   * @returns
   */

  static async getFilterInfoForAgentApplication(
    bearerToken: string,
    requestBody?: Object
  ): Promise<any> {
    const path = '/license-application/producer/filters';

    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getFilterInfoForAgentApplication :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> /license/actionable/me
  // actionable - agent portal
  static async getActionableLicensesForCurrentAgent(
    applicationType: ApplicationTypeEnumInBackend,
    agentId: string,
    searchString: string = '',
    searchType: string,
    bearerToken: string,
    size: number,
    page: number,
    requestBody: Object = {}
  ): Promise<PaginatedSuccessResponse<LicenseApplicationResponse>> {
    const path = '/license/producer/needs-attention';
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    if (applicationType)
      url_.searchParams.append('type', applicationType.toString());

    // Process requestBody using filterRequestBody function
    filterRequestBody(requestBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(
          apiStore.getTokenForGetActionableLicensesForAgent()
        );
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetActionableLicensesForAgent(cancelTokenSource);
        const actionableLicenseResponse = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(`${url_}`, requestBody);
        apiStore.setTokenForGetUnconfiguredPaymentAgents(undefined);

        resolve(
          actionableLicenseResponse.data as PaginatedSuccessResponse<LicenseApplicationResponse>
        );
      } catch (error: any) {
        console.error(
          'LicenseService :: getActionableLicenses :: Error :: ',
          error?.message
        );
        apiStore.setTokenForGetUnconfiguredPaymentAgents(undefined);
        reject(error);
      }
    });
  }

  // endpoint -> /license/actionable/{:agentId}
  // actionable - single onboard
  static async getActionableLicensesForAgentById(
    applicationType: ApplicationTypeEnumInBackend,
    agentId: string,
    searchString: string = '',
    bearerToken: string,
    size: number,
    page: number,
    requestBody?: object
  ): Promise<PaginatedSuccessResponse<LicenseApplicationResponse>> {
    const path = '/license-applications/actionable';
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(
          apiStore.getTokenForGetActionableLicensesForAgent()
        );
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetActionableLicensesForAgent(cancelTokenSource);
        const actionableLicenseResponse = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .post(
            `${this.host}${path}/${agentId}?size=${size}&page=${page}${
              searchString &&
              `&searchString=${encodeURIComponent(searchString)}`
            }`,
            requestBody
          );
        apiStore.setTokenForGetUnconfiguredPaymentAgents(undefined);

        const respData =
          actionableLicenseResponse.data as PaginatedSuccessResponse<LicenseApplicationResponse>;

        resolve(respData);
      } catch (error: any) {
        console.error(
          'LicenseService :: getActionableLicenses :: Error :: ',
          error?.message
        );
        if (!axios.isCancel(error)) {
          apiStore.setTokenForGetUnconfiguredPaymentAgents(undefined);
        }
        reject(error);
      }
    });
  }

  // endpoint -> license/my-agency
  // all licenses - agency portal
  // all licenses - agent portal
  static async findAllForCurrentAgency(
    requestBody: Object,
    bearerToken: string,
    size: number,
    page: number,
    searchString: string,
    searchType: string,
    cancelTokenSource?: CancelTokenSource,
    agentId?: string
  ): Promise<PaginatedSuccessResponse<AllLicenseResponse>> {
    const path = agentId
      ? `/license/producer/${agentId}/all-licenses`
      : '/license/all-licenses';
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    // Process requestBody using filterRequestBody function
    filterRequestBody(requestBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(
          AllLicenses.data as PaginatedSuccessResponse<AllLicenseResponse>
        );
      } catch (error: any) {
        console.error(
          'LicenseService :: getallLicenses :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }
  static async findAllAgencyLicensesForCurrentAgency(
    requestBody: Object,
    bearerToken: string,
    size: number,
    page: number,
    searchString: string,
    cancelTokenSource?: CancelTokenSource,
    advancedFilters?: AdvancedFilters
  ): Promise<PaginatedSuccessResponse<AllLicenseResponse>> {
    const path = '/license/my-agency/agency-licenses';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .post(
            `${this.host}${path}?size=${size}&page=${page}${
              searchString &&
              `&searchString=${encodeURIComponent(searchString)}`
            }`,
            requestBody,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );

        resolve(
          AllLicenses.data as PaginatedSuccessResponse<AllLicenseResponse>
        );
      } catch (error: any) {
        console.error(
          'LicenseService :: findAllAgencyLicensesForCurrentAgency :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for all licenses -> agency tab
   * @param token
   * @param requestBody
   * @returns
   */
  static async getFilterInfoForFindAllLicenses(
    bearerToken: string,
    requestBody?: Object
  ): Promise<any> {
    const path = '/license/producer/filters';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getFilterInfoForFindAll :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for all licenses -> agency tab
   * @param token
   * @param requestBody
   * @returns
   */
  static async getFilterInfoForFindAllLicensesAgencyTab(
    bearerToken: string,
    requestBody?: Object
  ): Promise<any> {
    const path = '/license/my-agency/agency-licenses/filter-options';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, requestBody, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getFilterInfoForFindAll :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for all licenses list and view -> agency tab
   * @param token
   * @param requestBody
   * @returns
   */
  static async getFilterInfoForFindAllLicensesMapListAgencyTab(
    bearerToken: string
  ): Promise<any> {
    const path = '/license/agency/filters';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getFilterInfoForFindAllAgencyList_Map :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for users admins table
   * @param token
   * @param requestBody
   * @returns
   */
  static async getFilterInfoForFindAgentAllLicenses(
    bearerToken: string,
    requestBody?: Object
  ): Promise<any> {
    const path = '/license/producer/filters';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getFilterInfoForFindAgentAllLicenses :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> license/:id
  // all licenses - agency portal - onboard
  static async findOneForAgency(
    id: string,
    bearerToken: string,
    filters: LicenseFilters,
    size: number,
    page: number,
    searchString: string
  ): Promise<PaginatedSuccessResponse<AllLicenseResponse>> {
    const path = '/license/{id}';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .post(
            `${this.host}${path.replace(`{id}`, id)}?size=${size}&page=${page}${
              searchString &&
              `&searchString=${encodeURIComponent(searchString)}`
            }`,
            filters,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );

        resolve(
          AllLicenses.data as PaginatedSuccessResponse<AllLicenseResponse>
        );
      } catch (error: any) {
        console.error(
          'LicenseService :: getallLicenses :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> license/:id
  // all licenses - agency portal - onboard
  static async findAllForAgent(
    requestBody: Object,
    bearerToken: string,
    filters: LicenseFilters,
    size: number,
    page: number,
    searchString: string,
    searchType: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<PaginatedSuccessResponse<AllLicenseResponse>> {
    const path = '/license/producer/all-licenses';
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    // Process requestBody using filterRequestBody function
    filterRequestBody(requestBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(
          AllLicenses.data as PaginatedSuccessResponse<AllLicenseResponse>
        );
      } catch (error: any) {
        console.error(
          'LicenseService :: getallLicenses :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> license/my-agency/application
  // applications - agency portal
  static async getApplicationsForCurrentAgency(
    requestBody: Object,
    bearerToken: string,
    size: number,
    page: number,
    searchString: string = '',
    searchType: string,
    agentId?: string
  ): Promise<PaginatedSuccessResponse<LicenseApplicationResponse>> {
    const path = agentId
      ? `/license-application/producer/${agentId}/pending-applications`
      : '/license-application/pending-applications';
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    // Process requestBody using filterRequestBody function
    filterRequestBody(requestBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(apiStore.getTokenForGetApplications());
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetApplications(cancelTokenSource);
        const applications = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(
          applications.data as PaginatedSuccessResponse<LicenseApplicationResponse>
        );
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          apiStore.setTokenForGetApplications(undefined);
        }
        console.error(
          'LicenseService :: getApplications :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getAgencyApplicationsForCurrentAgency(
    requestBody: Object,
    bearerToken: string,
    size: number,
    page: number,
    searchString: string = '',
    searchType: string
  ): Promise<PaginatedSuccessResponse<LicenseApplicationResponse>> {
    const path = '/license-application/agency/pending-applications';
    const url = new URL(`${this.host}${path}`);
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(apiStore.getTokenForGetApplications());
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetApplications(cancelTokenSource);
        if (searchString)
          url.searchParams.append(
            'searchString',
            encodeURIComponent(searchString)
          );
        if (searchType)
          url.searchParams.append('searchType', encodeURIComponent(searchType));
        if (size) url.searchParams.append('size', size.toString());
        if (page) url.searchParams.append('page', page.toString());
        filterRequestBody(requestBody, url);
        const applications = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(
            `${url}`,
            // requestBody,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );

        resolve(
          applications.data as PaginatedSuccessResponse<LicenseApplicationResponse>
        );
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          apiStore.setTokenForGetApplications(undefined);
        }
        console.error(
          'LicenseService :: getApplications :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> license/me/application
  // applications - agent portal
  static async getApplicationsForCurrentAgent(
    requestBody: Object,
    bearerToken: string,
    size: number,
    page: number,
    searchString: string = '',
    searchType: string
  ): Promise<PaginatedSuccessResponse<LicenseApplicationResponse>> {
    const path = '/license-application/producer/pending-applications';
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    if (requestBody) filterRequestBody(requestBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(apiStore.getTokenForGetApplications());
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetApplications(cancelTokenSource);
        const applications = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(
          applications.data as PaginatedSuccessResponse<LicenseApplicationResponse>
        );
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          apiStore.setTokenForGetApplications(undefined);
        }
        console.error(
          'LicenseService :: getApplications :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for users admins table
   * @param token
   * @param requestBody
   * @returns
   */
  static async getFilterInfoForFindApplications(
    bearerToken: string,
    requestBody?: Object
  ): Promise<any> {
    const path = '/license-application/producer/filters';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getFilterInfoForFindApplications :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for agency applications
   * @param token
   * @param requestBody
   * @returns
   */
  static async getFilterInfoForFindAgencyApplications(
    bearerToken: string,
    requestBody?: Object
  ): Promise<any> {
    const path = '/license-application/agency/filters';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getFilterInfoForFindApplications :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> license/{:licenseId}/create-application
  // all licenses - agent portal
  // actionable - agency portal
  // acionable - agent portal
  // actionable - single onboard
  static async applyLicenses(licenseId: string, bearerToken: string) {
    const path = '/license-application/';
    return new Promise(async (resolve, reject) => {
      try {
        const licenseResponse = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}${licenseId}/apply`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(licenseResponse.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: applyLicenses :: Error :: ',
          error?.message
        );
        reject(
          error.message
            ? {
                error: {
                  message: error.message,
                },
              }
            : error
        );
      }
    });
  }

  /**
   * API to apply for a license about to expire
   * @param licenseId
   * @param bearerToken
   * @returns
   */
  static async applyRenewalLicense(licenseId: string, bearerToken: string) {
    const path = '/license/';
    return new Promise(async (resolve, reject) => {
      try {
        const licenseResponse = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}${licenseId}/create-renewal-application`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(licenseResponse.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: applyLicenses :: Error :: ',
          error?.message
        );
        reject((error?.response?.data as ErrorResponse) || error);
      }
    });
  }

  // endpoint -> /license/bulk/create-applications
  // apply license modal view
  static async applyBulkLicenses(licenseIds: string[], bearerToken: string) {
    const path = '/license/bulk/create-applications';
    return new Promise(async (resolve, reject) => {
      try {
        const licenseResponse = await apiStore.getApiClient(bearerToken).post(
          `${this.host}${path}`,
          {
            data: licenseIds,
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );
        resolve(licenseResponse);
      } catch (error: any) {
        console.error(
          'LicenseService :: applyBulkLicenses :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> /license/find-many-license-state
  // complete offboard
  static async findManyByLicenseStateArray(
    licenseLookupObject: LicenseLookupState[],
    bearerToken: string
  ) {
    const path = '/license/find-many-license-state';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, licenseLookupObject, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: findManyByLicenseStateArray :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getLicenseStatisticsForAgencyOverview(bearerToken: string) {
    const path = '/license/overview-map';
    return new Promise(async (resolve, reject) => {
      try {
        const statsResponse = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(statsResponse.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getLicenseStatisticsForAgencyOverview :: Error :: ',
          error?.message
        );
        reject((error?.response?.data as ErrorResponse) || error);
      }
    });
  }

  // endpoint -> /:licenseId/poll
  // complete offboard
  static async getPollingStatusById(licenseId: string, bearerToken: string) {
    const path = `/license/${licenseId}/poll`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getPollingStatusById :: Error :: ',
          JSON.stringify(error)
        );
        reject(error);
      }
    });
  }
  // endpoint -> /:licenseId/poll
  // complete offboard
  static async getAllPollingStatus(bearerToken: string) {
    const path = `/license/poll-gateway-transactions`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getPollingStatusById :: Error :: ',
          JSON.stringify(error)
        );
        reject(error);
      }
    });
  }

  static async getRefreshOpenApplications(bearerToken: string) {
    const path = `/license/my-agency/poll-gateway-transactions`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getPollingStatusById :: Error :: ',
          JSON.stringify(error)
        );
        reject(error);
      }
    });
  }

  static async pollAgencyFirmApplications(bearerToken: string) {
    const path = `/license/my-agency/poll-firm-gateway-transactions`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getPollingStatusById :: Error :: ',
          JSON.stringify(error)
        );
        reject(error);
      }
    });
  }

  static async findLatestApplicationForGivenLicense(
    bearerToken: string,
    licenseId: string
  ): Promise<ObjectSuccessResponse<LicenseApplicationResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}/license/${licenseId}/latest-application`;
        const application = await apiStore.getApiClient(bearerToken).get(url, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

        resolve(
          application.data as ObjectSuccessResponse<LicenseApplicationResponse>
        );
      } catch (error: any) {
        console.error(
          'findNextApplicationForGivenApplication :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getActionableLicensesOnMultipleOnboarding(
    token: string,
    agentIds: string[],
    size: number,
    page: number,
    searchString: string,
    requestBody: Object = {}
  ) {
    return new Promise(async (resolve, reject) => {
      const path = `/license-applications/actionable/my-agency`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(
            `${this.host}${path}?size=${size}&page=${page}${
              searchString &&
              `&searchString=${encodeURIComponent(searchString)}`
            }`,
            { agentIds: agentIds, ...requestBody }
          );
        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  static async getActionableLicensesFilterOptionOnMultipleOnboarding(
    token: string,
    requestBody: Object = {}
  ) {
    return new Promise(async (resolve, reject) => {
      const path = `/license-applications/actionable/my-agency/filter-options`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`, requestBody);
        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  static async multipleOnboardLookup(token: string, reportId: string) {
    return new Promise(async (resolve, reject) => {
      const path = `${this.modulePath}/verify-multiple-onboard-lookup`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`, { reportId: `${reportId}` });
        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  // endpoint -> agencies/my-agency/licenses-list-map
  static async getAgencyLicensesForList_Map(
    bearerToken: string,
    cancelTokenSource: CancelTokenSource,
    searchString: string,
    searchType: string,
    requestBody?: object
  ): Promise<AllLicensesAgencyResponseType> {
    let path = '/agency/licenses-list-map';

    const url = new URL(
      `${this.host}${path}?searchString=${encodeURIComponent(
        searchString
      )}&searchType=${encodeURIComponent(searchType)}`
    );

    return new Promise(async (resolve, reject) => {
      try {
        // if (searchString) {
        //   if (!url.endsWith('&')) url += '?';
        //   url += `searchString=${encodeURIComponent(searchString)}`;
        // }
        filterRequestBody(requestBody, url);
        const AllLicenses = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(`${url}`, requestBody);
        resolve(AllLicenses?.data?.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: Agency Licenses For List & Map :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> /agency/agency-state-assignments
  static async getStateListFromAssignment(
    bearerToken: string,
    searchString?: string,
    requestBody?: object
  ): Promise<any> {
    let url = '/agency/agency-state-assignments';

    return new Promise(async (resolve, reject) => {
      try {
        if (searchString) {
          if (!url.endsWith('&')) url += '?';
          url += `searchString=${encodeURIComponent(searchString)}`;
        }
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${url}`, requestBody);
        resolve(AllLicenses?.data?.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getStateListFromAssignment :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async createDrlp(bearerToken: string, body: object): Promise<any> {
    let url = `/drlp/create-one`;

    return new Promise(async (resolve, reject) => {
      try {
        const createDrlp = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${url}`, body);
        resolve(createDrlp?.data?.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: createDrlp :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async addNewAgentToDrlp(
    bearerToken: string,
    stateCode: string,
    body: object
  ): Promise<any> {
    let url = `/agents/individual-onboard-for-drlp/${stateCode}`;

    return new Promise(async (resolve, reject) => {
      try {
        const onboardDrlp = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${url}`, body);
        resolve(onboardDrlp?.data?.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: addNewAgentToDrlp :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getCheckNpnForOnboardingDrlp(
    bearerToken: string,
    body: object
  ): Promise<any> {
    let url = '/agents/check-npn-for-onboarding-drlp';

    return new Promise(async (resolve, reject) => {
      try {
        const onboardDrlp = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${url}`, body);
        resolve(onboardDrlp?.data?.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getDrlpListState :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getDrlpListState(
    bearerToken: string,
    stateCode: string,
    cancelToken: CancelTokenSource
  ): Promise<any> {
    let url = `/drlp/options/${stateCode}`;

    return new Promise(async (resolve, reject) => {
      try {
        const drlpOptions = await apiStore
          .getApiClient(bearerToken, cancelToken)
          .get(`${this.host}${url}`);
        resolve(drlpOptions?.data?.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: getDrlpListState :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> /agencies/operational-states/list
  static async getAgencyOperationalStates(bearerToken: string): Promise<any> {
    const path = '/agencies/operational-states/list';

    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: Operational States :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> /license/agency/refresh
  static async refreshAgencyLicenses(bearerToken: string): Promise<any> {
    const path = '/license/agency/refresh';

    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: Refresh Agency Licneses :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> /agencies/my-agency/agency-licenses-refresh-flag
  static async refreshAgencyLicensesFlag(bearerToken: string): Promise<any> {
    const path = '/agencies/my-agency/agency-licenses-refresh-flag';

    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);
        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: Refresh Agency Licenses Flas :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  // endpoint -> /agencies/my-agency-applications-apply/
  static async applyLicenseForAgency(
    bearerToken: string,
    licenseId: string
  ): Promise<any> {
    const path = `/license-application/${licenseId}/apply`;

    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`);
        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'LicenseService :: Apply License Agency Id :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getActiveLicenseForProducers(token: string, producerId: string) {
    const path = `/license/producer/${producerId}/active`;
    try {
      const response = await apiStore
        .getApiClient(token)
        .get(`${this.host}${path}`, {
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
        });
      return response;
    } catch (error: any) {
      console.error(
        'LicenseService :: getActiveLicenseForProducers :: Error :: ',
        error?.message
      );
      throw error;
    }
  }
}
