import { ResidencyType } from '../../enums/residencyType.enum';

export const getResidentToolTip = (residentType: string): string => {
  const residency: ResidencyType = residentType as ResidencyType;
  switch (residency) {
    case ResidencyType.Resident:
      return 'Resident';
    case ResidencyType.NonResident:
      return 'Non-resident';
    case ResidencyType.NotApplicable:
      return 'Not applicable';
    default:
      return 'Unknown residency type';
  }
};
