import { MultipleOnboardLookup } from '../../enums/onboardSteps.enum';
import validateMultipleOnboardLookup from './multipleOnboard/validate-look-up-csv';

export const validateNameSsn = (columnName: any) => {
  const hasLastName = 'lastName' in columnName;
  const hasSSN = 'ssn' in columnName;
  const hasGender = 'gender' in columnName;
  const hasNPN = 'npn' in columnName;
  const hasPhone = 'phone' in columnName;
  const hasEmail = 'email' in columnName;

  return hasLastName && hasSSN && hasGender && hasNPN && hasPhone && hasEmail;
};

export const validateNameNpn = (columnName: any) => {
  const hasAgentName = 'Name' in columnName;
  const hasNPN = 'NPN' in columnName;
  return hasAgentName && hasNPN;
};

export const validateNpn = (columnName: any) => {
  const hasNPN = 'npn' in columnName;
  return hasNPN;
};

export const validateNameLicenseState = (columnName: any) => {
  const hasName = 'Name' in columnName;
  const hasLicenseNumber = 'License Number' in columnName;
  const hasState = 'State' in columnName;
  return hasName && hasLicenseNumber && hasState;
};

export const validateLookup = (
  multipleOnboardLookup: string,
  columnName: any
) => {
  // switch (multipleOnboardLookup) {
  // case MultipleOnboardLookup.SSN:
  if (!validateNpn(columnName)) {
    const incorrectColumns = Object.keys(columnName)
      .slice(1)
      .map((column) => column.trim())
      .filter((column) => column !== 'Last Name' && column !== 'SSN');
    throw new Error(`'${incorrectColumns.join("', '")}'`);
  }
  // break;

  // case MultipleOnboardLookup.NPN:
  //   if (!validateNameNpn(columnName)) {
  //     const incorrectColumns = Object.keys(columnName)
  //       .slice(1)
  //       .map((column) => column.trim())
  //       .filter((column) => column !== 'Name' && column !== 'NPN');
  //     throw new Error(`'${incorrectColumns.join("', '")}'`);
  //   }
  //   break;

  // case MultipleOnboardLookup.LICENSE:
  //   if (!validateNameLicenseState(columnName)) {
  //     const incorrectColumns = Object.keys(columnName)
  //       .slice(1)
  //       .map((column) => column.trim())
  //       .filter(
  //         (column) =>
  //           column !== 'Name' &&
  //           column !== 'License Number' &&
  //           column !== 'State'
  //       );
  //     throw new Error(`'${incorrectColumns.join("', '")}'`);
  //   }
  //   break;

  // default:
  //   break;
  // }

  return true;
};

export default validateLookup;
