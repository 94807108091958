import { Button, Modal } from 'antd';

import React from 'react';
import Title from 'antd/es/typography/Title';
import Typography from 'antd/es/typography/Typography';
import { multipleOnboardStore } from '../../../../stores/multipleOnboardStore';

interface BulkOnboardCountConfirmModalProps {
  modalVisibility: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: () => void;
  handleCancel: () => void;
}

function BulkOnboardCountConfirmModal(
  props: BulkOnboardCountConfirmModalProps
) {
  return (
    <Modal
      open={props.modalVisibility}
      width={'850px'}
      closable={false}
      bodyStyle={{ minHeight: '100px' }}
      onCancel={() => props.setModalVisibility(false)}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={props.handleCancel}>Cancel</Button>
          <Button onClick={props.handleNext}>It's Okay, Continue</Button>
        </div>,
      ]}
    >
      <Title
        style={{ marginTop: '0', textAlign: 'center', marginBottom: '20px' }}
        level={4}
      >
        Suggestion
      </Title>
      <Typography>
        With the number of producers that you are onboarding, consider your
        onboarding method: Bulk onboarding incurs a standard NIPR fee of $50,
        while individual onboarding costs $1.3 per producer. Assess your options
        carefully to optimize cost efficiency.
      </Typography>
      <br />
      Cost via individual onboarding ={' '}
      {multipleOnboardStore.multipleOnboardJsonData?.length}{' '}
      {multipleOnboardStore.multipleOnboardJsonData?.length &&
      multipleOnboardStore.multipleOnboardJsonData?.length > 1
        ? 'producers'
        : 'producer'}{' '}
      x $1.30 ={' $'}
      {multipleOnboardStore.multipleOnboardJsonData?.length
        ? (multipleOnboardStore.multipleOnboardJsonData?.length * 1.3)?.toFixed(
            2
          )
        : ''}
      <br />
      Cost via bulk onboarding = $50.00
    </Modal>
  );
}

export default BulkOnboardCountConfirmModal;
