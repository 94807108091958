import { Button, Form, Input, Typography, notification } from 'antd';

import { Auth0Service } from '../../services/auth0.service';
import { EMAIL_REGEX } from '../../constants/regex.constants';
import { Link } from 'react-router-dom';
import { OnboardingLayout } from '../../components/layout/onboardingLayout';
import { ReCaptchaWrapper } from '../../components/common/recaptcha/recaptcha-wrapper';
import React from 'react';
import { RouteConstants } from '../../constants/routes.constants';
import { useNavigate } from 'react-router';

export const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [reCaptchaToken, setReCaptchaToken] = React.useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);

  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const showSuccess = (message: string) => {
    api['success']({
      message: 'Forgot Password',
      description: message,
    });
  };

  const showError = (description: string) => {
    api['error']({
      message: 'Authentication Failed',
      description,
    });
  };

  const handleLogin = async (email: string) => {
    setIsLoading(true);
    Auth0Service.forgotPassword(email, reCaptchaToken)
      .then((resp: any) => {
        showSuccess(resp);
        setDisabled(true);
        setTimeout(() => {
          navigate(RouteConstants.rootRedirect.path);
        }, 4000);
      })
      .catch((err) => {
        setRefreshReCaptcha((refreshReCaptcha) => !refreshReCaptcha);
        showError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const verifyReCaptchaToken = (token: any) => {
    setReCaptchaToken(token);
  };

  return (
    <OnboardingLayout step={1} flowType="ForgotPassword">
      {/* DND Start */}
      {contextHolder}
      {/* DND End */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 24,
          width: '60%',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{ fontWeight: 400, color: '#222222', fontSize: '14px' }}
          >
            Enter the email address associated with your account, and we'll send
            you a link to reset your password.{' '}
          </Typography.Paragraph>
        </div>
        <Form
          autoComplete="off"
          layout="vertical"
          onFinish={(values) => {
            handleLogin(values.email);
          }}
          form={form}
          disabled={disabled}
          style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
          className="onboarding-screen"
        >
          <Form.Item
            label="Email address"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email',
              },
              {
                pattern: EMAIL_REGEX,
                message: 'Please enter a valid email',
              },
              {
                validator: (_, value) =>
                  value.trim() === ''
                    ? Promise.reject(new Error('Please enter a valid email'))
                    : Promise.resolve(),
              },
            ]}
          >
            <Input
              id="email-input"
              placeholder="Enter Email address"
              onChange={(e) => {
                const value = e.target.value;
                form.setFieldsValue({ email: value });
              }}
            />
          </Form.Item>

          <ReCaptchaWrapper
            onVerify={verifyReCaptchaToken}
            refreshReCaptcha={refreshReCaptcha}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ height: 46, width: '100%' }}
              loading={isLoading}
            >
              Reset Password
            </Button>
          </ReCaptchaWrapper>
        </Form>
        <Typography.Paragraph
          type="secondary"
          style={{ fontSize: '14px', marginBottom: 0 }}
        >
          Already have an account?{' '}
          {isLoading ? (
            <span
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'underline',
              }}
            >
              Log in
            </span>
          ) : (
            <Link
              to="/"
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'underline',
              }}
            >
              Log in
            </Link>
          )}
        </Typography.Paragraph>
      </div>
    </OnboardingLayout>
  );
};
