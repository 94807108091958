import {
  Annotation,
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';

import { ReactNode } from 'react';
import { Tooltip } from 'antd';
import allStates from '../../data/usMapStatesData.json';
import { geoCentroid } from 'd3-geo';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

export type USMapViewDataProp = {
  [key: string]: {
    backgroundColor: string;
    textColor: string;
    tooltip?: string | ReactNode;
    marker?: boolean;
    cursor?: string;
  };
};

type OffsetProp = {
  [key: string]: [number, number];
};

const offsets: OffsetProp = {
  VT: [50, -9],
  NH: [34, 1],
  MA: [30, -2],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const USMapView: React.FC<{
  data: USMapViewDataProp;
  width?: number;
  height?: number;
  projectionScale?: number;
  setSelectedState?: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  data,
  width = 700,
  height = 250,
  projectionScale = 550,
  setSelectedState,
}) => {
  const stateIdBasedData: USMapViewDataProp = {};
  Object.entries(data).forEach(
    ([key, value]) =>
      (stateIdBasedData[allStates[key as keyof typeof allStates]] = value)
  );

  return (
    <ComposableMap
      projection="geoAlbersUsa"
      projectionConfig={{ scale: projectionScale, center: [0, 0] }}
      width={width}
      height={height}
      style={{ width: '100%', height: 'auto' }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => (
              <Tooltip
                title={
                  stateIdBasedData[geo.id]?.tooltip
                    ? stateIdBasedData[geo.id].tooltip
                    : ''
                }
                key={geo.id}
              >
                <Geography
                  key={geo.rsmKey}
                  stroke={'#FFF'}
                  geography={geo}
                  onClick={() => {
                    const selectedState = Object.keys(allStates).find(
                      (s) => allStates[s as keyof typeof allStates] === geo.id
                    );
                    if (setSelectedState) setSelectedState(selectedState || '');
                  }}
                  fill={
                    stateIdBasedData[geo.id]
                      ? stateIdBasedData[geo.id].backgroundColor
                      : '#DDD'
                  }
                  style={{
                    default: { outline: 'none' },
                    hover: {
                      outline: 'none',
                      cursor: stateIdBasedData?.[geo.id]?.cursor || 'pointer',
                    },
                    pressed: { outline: 'none' },
                  }}
                />
              </Tooltip>
            ))}
            {geographies.map((geo) => {
              const centroid = geoCentroid(geo);
              const cur = Object.keys(allStates).find(
                (s) => allStates[s as keyof typeof allStates] === geo.id
              );
              return (
                <Tooltip
                  key={geo.id}
                  title={
                    stateIdBasedData[geo.id]?.tooltip
                      ? stateIdBasedData[geo.id].tooltip
                      : ''
                  }
                >
                  <g
                    key={geo.rsmKey + '-name'}
                    onClick={() => {
                      if (setSelectedState) setSelectedState(cur || '');
                    }}
                  >
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur) === -1 ? (
                        <Marker
                          coordinates={centroid}
                          style={{
                            hover: {
                              cursor:
                                stateIdBasedData[geo.id]?.cursor || 'pointer',
                            },
                          }}
                        >
                          {stateIdBasedData[geo.id]?.marker && (
                            <g
                              key={geo.rsmKey + '-name'}
                              fill="none"
                              stroke="#C70039"
                              strokeWidth="0"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="2" cy="5" r="2" fill="#C70039" />
                            </g>
                          )}
                          <text
                            y="2"
                            x={
                              cur === 'LA'
                                ? '-3'
                                : cur === 'FL' || cur === 'MI'
                                ? '6'
                                : 0
                            }
                            fontSize={8}
                            textAnchor="middle"
                            fill={
                              cur !== 'HI' && stateIdBasedData[geo.id]
                                ? stateIdBasedData[geo.id].textColor
                                : '#000'
                            }
                          >
                            {cur}
                          </text>
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={offsets[cur][0]}
                          dy={offsets[cur][1]}
                          connectorProps={{ strokeWidth: 0.5 }}
                        >
                          <text x={4} fontSize={8} alignmentBaseline="middle">
                            {cur}
                          </text>
                          {stateIdBasedData[geo.id]?.marker && (
                            <g
                              key={geo.rsmKey + '-name'}
                              fill="none"
                              stroke="#C70039"
                              strokeWidth="0"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="20" cy="0" r="2" fill="#C70039" />
                            </g>
                          )}
                        </Annotation>
                      ))}
                  </g>
                </Tooltip>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};

export default USMapView;
