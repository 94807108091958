import React, { useState } from 'react';

import AgentSignupSteps from './agentSignupSteps';
import { RoleType } from '../../enums/roles.enum';
import { withRoles } from '../../auth/useRoles';

const tabOption = [
  {
    label: 'Licenses',
    key: '1',
  },
  {
    label: 'Settings',
    key: '2',
  },
  {
    label: 'Background Questions',
    key: '3',
  },
];

const tabKeys = tabOption.map((info) => info.key);

function AgentPortal() {
  return <AgentSignupSteps />;
}

export default withRoles(AgentPortal, [RoleType.AGENT]);
