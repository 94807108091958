import { Button, Form, Input, Modal, Row } from 'antd';

import { IdConstants } from '../../../../constants/id.constants';
import { ModalContainer } from './addBlacklist.style';
import { NPN_REGEX } from '../../../../constants/regex.constants';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { TextDetail } from '../../application-status/applicationstatus.style';

interface LicenseModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBlockListAdded: (value: boolean) => void;
  onFinish: (values: any) => void;
  form: any;
  isLoading: boolean;
  error: string;
  setError: React.Dispatch<React.SetStateAction<any>>;
}

const BlacklistModal: React.FC<LicenseModalProps> = ({
  visible,
  setVisible,
  setIsBlockListAdded,
  onFinish,
  form,
  isLoading,
  error,
  setError,
}) => {
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const onCancel = () => {
    setVisible(false);
  };

  type FieldType = {
    npn?: string;
    reason?: string;
  };
  return (
    <Modal
      afterClose={onClose}
      destroyOnClose={true}
      width={'500px'}
      open={visible}
      onCancel={() => {
        setVisible(false); // Call the setVisible function to close the modal
      }}
      okText="Ok"
      footer={null}
    >
      <ModalContainer>
        <TextDetail textColor={'#0588ca'} fontWeight={500} fontSize={'16px'}>
          Add producer to watchlist
        </TextDetail>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 650, width: '650px' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item<FieldType>
            label="NPN Number"
            name="npn"
            rules={[
              { required: true, message: 'Please input  NPN!' },
              { pattern: NPN_REGEX, message: 'Enter a valid NPN' },
            ]}
          >
            <Input
              id={IdConstants.WATCH_LIST_MODAL.NPN_INPUT + '-agency-watchlist'}
              placeholder="Enter a National Producer Number"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength={10}
              onPaste={(event) => {
                const pastedText = event.clipboardData.getData('text/plain');
                if (!/^[0-9]+$/.test(pastedText)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="Reason For Watchlisting"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Please input reason for watchlisting',
              },
            ]}
          >
            <Input
              id={
                IdConstants.WATCH_LIST_MODAL.REASON_INPUT + '-agency-watchlist'
              }
              placeholder="Provide a reason for watchlisting"
            />
          </Form.Item>
          <Form.Item>
            <Row justify={'space-between'}>
              <Button
                id={
                  IdConstants.WATCH_LIST_MODAL.CANCEL_BUTTON +
                  '-agency-watchlist'
                }
                onClick={onCancel}
                disabled={isLoading}
                danger
                size="large"
              >
                Cancel
              </Button>
              <Button
                id={
                  IdConstants.WATCH_LIST_MODAL.ADD_BUTTON + '-agency-watchlist'
                }
                type="primary"
                htmlType="submit"
                loading={isLoading}
                size="large"
              >
                <PlusOutlined /> Add to Watchlist
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </ModalContainer>
    </Modal>
  );
};

export default BlacklistModal;
