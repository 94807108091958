import { Space, Tag, Tooltip, Typography } from 'antd';

import { LoaDetail } from '../../types/data/loaDetail.type';
import { LoaStatus } from '../../enums/loaStatus.enum';
import { Tab } from '../../pages/agency/licenses/actionable';
import { appStateInfoStore } from '../../stores/appStateInfo.store';

type LcLoaCellProps = {
  licenseClass: string;
  loaDetails: LoaDetail[];
  currentTab?: Tab;
  requiredTab?: Tab;
  requiredStatus?: LoaStatus[];
};

function LcLoaCell({
  licenseClass,
  loaDetails,
  currentTab,
  requiredTab,
  requiredStatus,
}: LcLoaCellProps) {
  return (
    <div>
      <Typography.Paragraph
        style={{
          fontSize: '14px',
          marginBottom: '0.25em',
        }}
      >
        {appStateInfoStore.getLcNameFromCode(licenseClass)}
      </Typography.Paragraph>
      {loaDetails
        .filter((itr) => {
          if (currentTab && requiredTab && requiredStatus) {
            if (currentTab === requiredTab)
              return requiredStatus.includes(itr.status);
          } else if (requiredStatus) {
            return requiredStatus.includes(itr.status);
          } else return true;
        })
        .map((loa: LoaDetail, index: number) => {
          return (
            <Space size={[8, 16]} wrap key={index}>
              <Tooltip
                title={
                  loa.status === LoaStatus.INACTIVE
                    ? 'Inactive LOA'
                    : loa.status === LoaStatus.MISSING || !loa.status
                    ? 'Missing LOA'
                    : loa.status === LoaStatus.CANCELLED
                    ? 'Cancelled LOA'
                    : loa.status === LoaStatus.PENDING
                    ? 'Pending LOA'
                    : 'Active LOA'
                }
              >
                <Tag
                  color={
                    loa.status === LoaStatus.INACTIVE ||
                    loa.status === LoaStatus.MISSING ||
                    loa.status === LoaStatus.CANCELLED ||
                    !loa.status
                      ? '#ffccc7'
                      : loa.status === LoaStatus.PENDING
                      ? '#FFEECB'
                      : '#F7FCFC'
                  }
                  style={{
                    borderRadius: '10px',
                    marginTop: '5px',
                    marginRight: '5px',
                    border: `1px solid ${
                      loa.status === LoaStatus.INACTIVE ||
                      loa.status === LoaStatus.MISSING ||
                      loa.status === LoaStatus.CANCELLED ||
                      !loa.status
                        ? '#ff7875'
                        : loa.status === LoaStatus.PENDING
                        ? '#ffd591'
                        : '#DFF4F5'
                    }`,
                  }}
                >
                  <span style={{ color: 'black' }}>
                    {appStateInfoStore.getLoaNameFromCode(loa.loa)}
                  </span>
                </Tag>
              </Tooltip>
            </Space>
          );
        })}
    </div>
  );
}

export default LcLoaCell;
