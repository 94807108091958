import { Button, Collapse, Tooltip, message } from 'antd';
import {
  EyeOutlined,
  FileTextOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { ActionableLicenseResponseError } from '../../../../../types/response/actionable-license.type';
import { AllLicensesAgencyResponseType } from '../../../../../types/request/agency/allLicenses/allLicenses.type';
import { ApplicationStatusEnum } from '../../../../../enums/applicationStatus.enum';
import { ErrorResponse } from '../../../../../types/response/errorResponse.type';
import { ExceptionName } from '../../../../../enums/exceptionName.enum';
import FillOwnershipDetails from './fillOwnershipDetails';
import { GatewayTransactionApplyError } from '../../../../../types/nipr/gatewayTransaction.types';
import { GeneralTabType } from '../../../../../enums/generalTabs.enum';
import LicenseModal from '../../../../../utils/modal/application-status/applictionStatus.modal';
import { LicenseStatus } from '../../../../../enums/licenseStatus.enum';
import LicensedProducer from '../../../../../components/agencyLicensing/licensedProducer';
import { LicensesService } from '../../../../../services/licenses.service';
import ListAgentDetails from './listAgentDetails';
import { PortalType } from '../../../../../enums/portalType.enum';
import { RouteConstants } from '../../../../../constants/routes.constants';
import { getAssignedDrlp } from '../../../../../components/agencyLicensing/reviewSection';
import { getStateNameFromCode } from '../../../../../utils/common.utils';
import { getStatus } from './agencyMap';
import { groupBy } from 'lodash';
import { listColors } from './allLicensesAgencyTab';
import { statesWithAffiliation } from '../../../../../components/agencyLicensing/statesWithAffiliationsConst';
import { useAuth } from '../../../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useState } from 'react';

interface AllLicensesAgencyType {
  isFilterVisible: boolean;
  collapseKeys: string | string[];
  allLicenses: AllLicensesAgencyResponseType | null;
  onCollapseChange: (key: string | string[]) => void;
  refetch: () => void;
  setApplyLicenseErrorModalVisibility?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setErrors?: React.Dispatch<
    React.SetStateAction<ActionableLicenseResponseError[]>
  >;
}

const AllLicensesAgencyList: React.FC<AllLicensesAgencyType> = ({
  isFilterVisible,
  collapseKeys,
  allLicenses,
  onCollapseChange,
  refetch,
  setErrors,
  setApplyLicenseErrorModalVisibility,
}) => {
  const navigate = useNavigate();
  const { bearerToken } = useAuth();
  const [isApplying, setIsApplying] = useState('');
  const [selectedLicenseDetails, setSelectedLicenseDetails] = useState<any>();

  const applyLicenses = async (licenseId: string) => {
    if (!bearerToken) return;
    try {
      if (!allLicenses?.isOwnerDetailsFilled) {
        message.error(
          'Please fill the ownership details to proceed forward with the agency licenses application'
        );
        return;
      }
      setIsApplying(licenseId);
      const resp = await LicensesService.applyLicenseForAgency(
        bearerToken,
        licenseId
      );

      if (resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse) {
        const error =
          resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse?.license;
        const lcRespMessages = Array.isArray(error?.response)
          ? error?.response
              ?.filter((itr: any) => !!itr && itr?.approved !== 'Y')
              ?.flatMap((itr: any) => itr?.messages)
              ?.map((d: any) => d?.comments) || []
          : error?.response?.approved !== 'Y'
          ? error?.response?.map((msg: any) => msg?.comments) || []
          : [];

        const loaRespMessages = Array.isArray(error?.loaCodes)
          ? error?.loaCodes
              ?.flatMap((d: any) => d?.response)
              ?.filter((itr: any) => !!itr)
              ?.flatMap((itr: any) =>
                itr?.approved !== 'Y' ? itr?.messages : []
              )
              ?.map((d: any) => d?.comments) || []
          : error?.loaCodes?.approved !== 'Y'
          ? error?.loaCodes?.response
              ?.filter((itr: any) => !!itr)
              ?.flatMap((itr: any) =>
                itr?.approved !== 'Y' ? itr?.messages : []
              )
              ?.map((d: any) => d?.comments) || []
          : [];

        const intermediateMessages = Array.isArray(error?.intResponse)
          ? error?.intResponse
              ?.filter((itr: any) => !!itr)
              ?.flatMap((d: any) => d?.messages)
              ?.map((itr: any) => {
                return {
                  actionable: itr.actionRequired,
                  message: itr.comments,
                };
              })
          : error?.intResponse?.messages
          ? error?.intResponse?.messages?.map((itr: any) => {
              return {
                actionable: itr.actionRequired,
                message: itr.comments,
              };
            })
          : [];

        const lcErrorDetails = { errorMessages: lcRespMessages };
        const loaErrorDetails = { errorMessages: loaRespMessages };
        const mainErrorDetails = { errorMessages: [] };
        const intermediateErrorDetails = {
          errorMessages: intermediateMessages,
        };

        setErrors &&
          setErrors([
            {
              LcErrorDetails: lcErrorDetails,
              LoaErrorDetails: loaErrorDetails,
              mainErrorDetails: mainErrorDetails,
              intermediateErrorDetails: intermediateErrorDetails,
            },
          ]);
        setApplyLicenseErrorModalVisibility &&
          setApplyLicenseErrorModalVisibility(true);
      } else if (resp?.data?.gatewayTransaction?.niprGatewayErrorResponse) {
        const errors = resp?.data?.gatewayTransaction?.niprGatewayErrorResponse;
        setErrors &&
          setErrors([
            {
              LcErrorDetails: {
                errorMessages: [],
              },
              LoaErrorDetails: {
                errorMessages: [],
              },
              mainErrorDetails: {
                errorMessages: errors?.map((d: any) => d.description) || [],
              },
              intermediateErrorDetails: {
                errorMessages: [],
              },
            },
          ]);
        setApplyLicenseErrorModalVisibility &&
          setApplyLicenseErrorModalVisibility(true);
      }
      // todo :: error modal integration
      if (resp?.data?.status === 'pending') {
        refetch();
        message.success('License applied successfully');
      }
    } catch (error: any) {
      console.error(error);
      const e = error?.response?.data as ErrorResponse;
      if (
        e?.error?.exceptionName ===
        ExceptionName.NIPR_GATEWAY_TRANSACTION_EXCEPTION
      ) {
        const error = e.error.data as GatewayTransactionApplyError;
        const lcRespMessages = error.lcRespMessages
          .filter((itr) => !!itr)
          .map((itr) => itr.comment);

        const loaRespMessages = error.loaRespMessages
          .filter((itr) => !!itr)
          .flatMap((itr) =>
            itr.approved !== 'Y'
              ? itr.respMessages.map((msg) => msg.comment)
              : []
          );
        const mainErrorMessages = error.mainErrorMessages
          .filter((itr) => !!itr)
          .flatMap((itr) => itr.description);
        const intermediateMessages = error.intermediateMessages
          .filter((itr) => !!itr)
          .flatMap((itr) => {
            return { actionable: itr.actionRequired, message: itr.comment };
          });

        const lcErrorDetails = { errorMessages: lcRespMessages };
        const loaErrorDetails = { errorMessages: loaRespMessages };
        const mainErrorDetails = { errorMessages: mainErrorMessages };
        const intermediateErrorDetails = {
          errorMessages: intermediateMessages,
        };

        setErrors &&
          setErrors([
            {
              LcErrorDetails: lcErrorDetails,
              LoaErrorDetails: loaErrorDetails,
              mainErrorDetails: mainErrorDetails,
              intermediateErrorDetails: intermediateErrorDetails,
            },
          ]);
        setApplyLicenseErrorModalVisibility &&
          setApplyLicenseErrorModalVisibility(true);
      } else
        message.error(
          error?.response?.data?.message ||
            'Failed to apply license.please try again'
        );
    } finally {
      setIsApplying('');
    }
  };
  return (
    <>
      {!allLicenses?.isOwnerDetailsFilled ? <FillOwnershipDetails /> : null}
      <Collapse
        items={collapseItems(
          allLicenses,
          isApplying,
          applyLicenses,
          (path: string) => {
            navigate(path);
          },
          setSelectedLicenseDetails
        )}
        activeKey={collapseKeys}
        onChange={onCollapseChange}
        className={
          collapseKeys.includes(
            allLicenses?.agencyLicenses?.[0]?.stateCode || ''
          )
            ? 'collapse-first-child-open'
            : ''
        }
      />
      <LicenseModal
        visible={!!selectedLicenseDetails}
        setVisible={() => {
          setSelectedLicenseDetails(null);
        }}
        agentLicenseDetails={{
          ...selectedLicenseDetails,
        }}
        portal={PortalType.AGENT}
        showTransactionDetails={false}
        autoRenewEnabled={false}
      />
    </>
  );
};

export default AllLicensesAgencyList;

export const collapseItems = (
  allLicenses: AllLicensesAgencyResponseType | null,
  loading?: string,
  applyLicenses?: (licenseId: string) => void,
  navigate?: (path: string) => void,
  setSelectedLicenseDetails?: React.Dispatch<React.SetStateAction<any>>
) => {
  if (!allLicenses) return [];
  // populating stateName since it will not be present in BE v2
  allLicenses.agencyLicenses = allLicenses.agencyLicenses.map((itr) => ({
    ...itr,
    stateName: getStateNameFromCode((itr as any).stateCode),
  }));

  const licenses = groupBy(
    allLicenses?.agencyLicenses?.sort((a, b) =>
      a?.stateName?.localeCompare(b?.stateName)
    ),
    'stateName'
  );

  return Object.entries(licenses).map(([k, value]) => {
    const data = value?.[0];

    const status = getStatus(value);

    const uniqueIds = new Set();
    const drlp = value
      .flatMap((d) => d?.drlps)
      .filter((item) => {
        if (uniqueIds.has(item?.npn)) {
          return false;
        } else {
          uniqueIds.add(item?.npn);

          return true;
        }
      });

    const color = listColors.filter((e) =>
      e.type.includes(status?.toLowerCase())
    )?.[0];

    const isBackgroundQnsAnswered = value?.some(
      (d) => d?.bgQuestionsEngineLight
    );

    return {
      key: data.stateCode,
      label: (
        <div style={{ display: 'flex', gap: 12 }}>
          <div className="agency-licenses-list-left-pane agency-list-border">
            <div
              style={{
                fontSize: 18,
                fontWeight: 500,
                lineHeight: '20px',
                display: 'flex',
              }}
            >
              {data.stateName}{' '}
              {statesWithAffiliation.includes(data.stateName) ? (
                <div>
                  <Tooltip
                    title={`We do not presently offer support for ${data.stateName}.`}
                  >
                    <QuestionCircleOutlined
                      style={{
                        marginLeft: '8px',
                        cursor: 'pointer',
                        color: '#97AABF',
                      }}
                    />
                  </Tooltip>
                </div>
              ) : null}
            </div>
            <div
              style={{
                ...(color
                  ? {
                      border: `1px solid ${color.borderColor}`,
                      backgroundColor: color.color,
                      borderRadius: 26,
                      padding: '2px 6px',
                      marginBottom: 8,
                      marginTop: 18,
                      fontSize: 12,
                      display: 'inline-block',
                      maxWidth: 'fit-content',
                      textTransform: 'capitalize',
                    }
                  : {}),
              }}
            >
              {status === LicenseStatus.Active
                ? 'Active'
                : status === 'pending'
                ? 'Pending'
                : status === 'missing'
                ? 'Missing Loas'
                : 'Inactive'}
            </div>
          </div>
          <div
            className="agency-licenses-list-right-pane"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: 12,
                    color: drlp?.length ? '#848484' : '#ABABAB',
                  }}
                >
                  {drlp?.length ? 'DRLP’s Name:' : 'No DRLP’s Assigned'}
                </div>
                {drlp?.length ? (
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', columnGap: 12 }}
                  >
                    {drlp?.map((data: any, index: number) => {
                      if (data) {
                        return (
                          <div
                            key={index}
                            style={{
                              margin: '12px 0',
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: '20px',
                            }}
                          >
                            <LicensedProducer
                              text={getAssignedDrlp({
                                ...data,
                              })}
                              notClosable={true}
                              onClose={() => {}}
                              noMargin={true}
                            />
                          </div>
                        );
                      }
                    })}
                    {/* <p style={{ margin: '6px 0' }}>{data.lastName}</p> */}
                  </div>
                ) : null}
              </div>
              {status !== LicenseStatus.Active ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 'fit-content',
                    gap: 24,
                  }}
                >
                  <Tooltip
                    title={
                      !drlp?.length
                        ? 'Please assign drlp for this state'
                        : !isBackgroundQnsAnswered
                        ? 'Please fill background questions before applying for this license.'
                        : !allLicenses?.isOwnerDetailsFilled
                        ? 'Please fill the ownership details to proceed forward with the agency licenses application'
                        : ''
                    }
                  >
                    <Button
                      type="primary"
                      id="agency-all-licenses-apply-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (applyLicenses) applyLicenses(data.id);
                      }}
                      style={{
                        width: 120,
                        height: 36,
                      }}
                      disabled={
                        !!loading ||
                        status === 'pending' ||
                        statesWithAffiliation.includes(data.stateName) ||
                        !drlp?.length ||
                        !isBackgroundQnsAnswered ||
                        !allLicenses?.isOwnerDetailsFilled
                      }
                      loading={loading === data.id}
                    >
                      {data?.latestApplication?.[0]?.status === 'declined'
                        ? 'Re-Apply'
                        : 'Apply'}
                    </Button>
                    {data?.latestApplication?.[0]?.status === 'declined' ? (
                      <Tooltip title="View Details">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (setSelectedLicenseDetails)
                              setSelectedLicenseDetails({
                                ...(data?.latestApplication?.[0] || {}),
                                licenseConfigurations:
                                  data?.licenseConfigurations,
                                license: {
                                  stateName: data?.stateName,
                                  status: data?.status,
                                },
                              });
                          }}
                          style={{ marginLeft: 12 }}
                          className="button primary-button"
                        >
                          <EyeOutlined />
                        </Button>
                      </Tooltip>
                    ) : null}
                  </Tooltip>
                </div>
              ) : null}
            </div>
            <p
              style={{
                margin: '6px 0',
                display: 'flex',
                gap: 3,
                fontWeight: 400,
                width: 'fit-content',
                height: 24,
                fontSize: 12,
                lineHeight: '20px',
                alignItems: 'center',
                backgroundColor: isBackgroundQnsAnswered
                  ? '#D3E7FF'
                  : 'rgba(252, 239, 207, 1)',
                border: `1px solid ${
                  isBackgroundQnsAnswered ? '#437EE1' : 'rgba(255, 201, 66, 1)'
                }`,
                // backgroundColor: '#D3E7FF',
                // border: `1px solid #437EE1`,
                padding: '6px 12px',
                color: 'rgba(29, 44, 80, 1)',
                borderRadius: 16,
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (navigate)
                  navigate(
                    RouteConstants.agencyGeneralProfile.path +
                      `?tab=${GeneralTabType.BACKGROUND_QUESTIONS}`
                  );
              }}
            >
              <FileTextOutlined />
              Background Questions
            </p>
          </div>
        </div>
      ),
      children: (
        <div style={{ display: 'flex' }}>
          <div className="agency-list-border"></div>
          <div className="agency-licenses-list-right-pane">
            <ListAgentDetails
              licenseDetails={value}
              styles={{ padding: '0 12px' }}
              stateCode={value?.[0]?.stateCode}
            />
          </div>
        </div>
      ),
    };
  });
};
