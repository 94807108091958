import {
  AuditOutlined,
  BellOutlined,
  ClusterOutlined,
  ContainerOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  FileAddOutlined,
  FileOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FilterOutlined,
  GroupOutlined,
  LayoutOutlined,
  ProjectOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';

export const IconConstants = {
  dashboard: <LayoutOutlined />,
  agents: <TeamOutlined />,
  onboard: <UsergroupAddOutlined />,
  offboard: <UsergroupDeleteOutlined />,
  agentOverview: <ProjectOutlined />,
  agentActivity: <ScheduleOutlined />,
  agentTransactions: <FileOutlined />,
  agentLicenses: <FileOutlined />,
  agentAppointments: <FileAddOutlined />,
  agentAssignments: <FileTextOutlined />,
  licenses: <FileProtectOutlined />,
  stateGroups: <GroupOutlined />,
  agency: <FileOutlined />,
  notification: <BellOutlined />,
  agencyOverview: <ProjectOutlined />,
  settings: <SettingOutlined />,
  rts: <AuditOutlined />,
  billing: <DollarOutlined />,
  user: <UserOutlined />,
  hierarchy: <ClusterOutlined />,
  supportingDocuments: <ContainerOutlined />,
  employmentHistory: <FieldTimeOutlined />,
  filter: (
    <FilterOutlined
      style={{
        background: '#FFEAE8',
        width: '40px',
        height: '40px',
        fontSize: '24px',

        justifyContent: 'center',
      }}
    />
  ),
};
