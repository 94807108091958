import { Button, Col, Modal, Row, Typography } from 'antd';

import { Name } from '../../../../types/data/name.type';
import { getNameString } from '../../../../utils/name.utils';

export interface WatchlistedAgentDetails {
  agentName: Name;
  npn?: string;
  reason?: string;
  downlineInvite?: boolean;
}

type watchlistModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => boolean;
  agents: WatchlistedAgentDetails[];
  type: 'multiple' | 'individual';
  onClose?: () => void;
};

function IsWatchlistedModal(props: watchlistModalProps) {
  return (
    <Modal
      width={750}
      open={props.visible}
      closable={false}
      maskClosable={false}
      centered
      footer={
        <div>
          <Button
            id="watchlist-modal-keep-agent-button"
            htmlType="submit"
            size="large"
            className="button"
            style={{ width: '100%', marginBottom: '10px' }}
            onClick={(values) => {
              props.setVisible(false);
            }}
          >
            {props.type === 'multiple'
              ? 'Keep these agents and Continue'
              : 'Keep the agent and Continue'}
          </Button>
          <Button
            id="watchlist-modal-discard-onboarding-button"
            size="large"
            style={{
              width: '100%',
              marginLeft: '-5px',
              color: 'var(--secondary-color)',
              border: '1px solid var(--border-color)',
              boxShadow: 'none',
            }}
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              } else {
                window.location.reload();
              }
            }}
          >
            {props.agents[0]?.downlineInvite
              ? 'Discard'
              : props.type === 'multiple'
              ? 'Remove these agents and Continue'
              : 'Discard Onboarding'}
          </Button>
        </div>
      }
    >
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        Watchlisted Agent
      </Typography.Paragraph>
      <Typography.Paragraph
        style={{
          fontSize: '16px',
          fontWeight: '400',
        }}
      >
        {props.agents[0]?.downlineInvite
          ? 'We found that you are trying to invite a watchlisted agent as your downline'
          : props.type === 'multiple'
          ? 'We found Watchlisted Producers in your upload batch'
          : 'We found that you are trying to onboard a watchlisted agent'}
      </Typography.Paragraph>

      <Row>
        <Col span={10}>
          <Typography.Paragraph style={{ color: '#787878', fontSize: '12px' }}>
            Agent Name
          </Typography.Paragraph>
        </Col>
        <Col span={6}>
          <Typography.Paragraph style={{ color: '#787878', fontSize: '12px' }}>
            NPN
          </Typography.Paragraph>
        </Col>
        <Col>
          <Typography.Paragraph style={{ color: '#787878', fontSize: '12px' }}>
            Reason
          </Typography.Paragraph>
        </Col>
      </Row>

      {props.agents?.map((agent: WatchlistedAgentDetails) => {
        return (
          <Row>
            <Col span={10}>
              <Typography.Paragraph style={{ fontSize: '12px' }}>
                {getNameString(agent?.agentName)}
              </Typography.Paragraph>
            </Col>
            <Col span={6}>
              <Typography.Paragraph style={{ fontSize: '12px' }}>
                {agent?.npn}
              </Typography.Paragraph>
            </Col>
            <Col>
              <Typography.Paragraph style={{ fontSize: '12px' }}>
                {agent?.reason}
              </Typography.Paragraph>
            </Col>
          </Row>
        );
      })}
    </Modal>
  );
}

export default IsWatchlistedModal;
