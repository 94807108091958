import styled from 'styled-components';
interface TextDetailProps {
  textcolor?: string;
  fontSize?: string;
  fontWeight?: number;
}
export const ModalContainer = styled.div`
  display: inline-flex;
  // padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TextDetail = styled.div<TextDetailProps>`
  color: ${(props) => props.textcolor || '#222'};
  font-family: Poppins;
  font-size: ${(props) => props.fontSize || '10px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 20px; /* 200% */
`;

export const EmailAddressContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`;
export const EmailCard = styled.div`
  display: flex;
  min-width: 120px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding=top: 16px;
  align-self: stretch;
  gap: 16px;
`;
