import './agentPortal.css';

import BackgroundQuestionsScreen, {
  RefObject_,
} from './agentInformation/backgroundQuestions/backgroundQuestions';
import {
  Button,
  Card,
  FloatButton,
  Modal,
  Result,
  Spin,
  message,
  notification,
} from 'antd';
import { EmployeeHistory, agentSideStore } from '../../stores/agentPortalStore';
import React, { useEffect, useState } from 'react';

import AdminSignUpLayout from '../../components/signUp/layouts/signUpLayout';
import { AgentService } from '../../services/agent.service';
import { Auth0Service } from '../../services/auth0.service';
import CustomButton from '../../components/common/button/CustomButton';
import CustomStepper from '../../components/common/steps/customStepper';
import EmploymentHistory from './agentInformation/backgroundQuestions/employmentHistory';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { RoleType } from '../../enums/roles.enum';
import { RouteConstants } from '../../constants/routes.constants';
import { auth0Store } from '../../stores/auth0Store';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../auth/authProvider';
import { useNavigate } from 'react-router';

const stepInfo = [
  {
    steps: 'Employment History',
    content: 'Enter up to 5 years of your employment history',
    key: 0,
  },
  {
    steps: 'Background Questions',
    content:
      'Answer your responses accurately to your belief. Note: License fee is based on the responses',
    key: 1,
  },
];

const loaderMessages = [
  'Please wait while we upload the support documents to the InsureTrek database...',
  'Please wait, retrieving agent information from the InsureTrek database...',
  'Logging in to your portal. Please wait while we securely authenticate your credentials...',
];

const steps = stepInfo.map((info) => info.steps);
const stepKeys = stepInfo.map((info) => info.key);

/**
 * Calculate the longest continuous duration of employment history.
 *
 * @param {EmployeeHistory[]} empHistory - The array of employee history records.
 * @returns {number} The longest continuous duration in months.
 */

export function calculateLongestContinuousDuration(
  empHistory: EmployeeHistory[]
): number {
  let longestDuration = 0;
  let currentDuration = 0;
  const sortedRecords = empHistory.sort((a, b) => {
    return a.startDate.valueOf() - b.startDate.valueOf();
  });

  for (let i = 0; i < sortedRecords.length; i++) {
    const currentEmployment = sortedRecords[i];
    const nextEmployment = sortedRecords[i + 1];

    if (currentEmployment.endDateDisabled) {
      const startDate = currentEmployment.startDate || dayjs(new Date());
      const endDate = dayjs(new Date());
      const duration = endDate.diff(startDate, 'months');
      currentDuration += duration;
    } else {
      const duration = currentEmployment.endDate
        ? currentEmployment.endDate.diff(currentEmployment.startDate, 'months')
        : 0;
      currentDuration += duration;
    }

    if (currentDuration > longestDuration) {
      longestDuration = currentDuration;
    }

    if (
      nextEmployment &&
      nextEmployment.startDate?.isSame(currentEmployment.endDate, 'month') &&
      nextEmployment.startDate?.isSame(currentEmployment.endDate, 'year')
    ) {
      continue;
    }

    currentDuration = 0;
  }
  return longestDuration;
}

function AgentSignupSteps() {
  const { getAccessTokenSilently } = useAuth();
  const [current, setCurrent] = useState(stepKeys[0]);
  const [maxStep, setMaxStep] = useState(stepKeys[0]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { bearerToken, roles, refreshToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [checkBgQuestionAdded, setCheckBgQuestionAdded] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState<string>(
    'Please wait, saving responses to the background questions in the InsureTrek database...'
  );

  const navigate = useNavigate();
  const backgroundQuestionsRef = React.useRef<RefObject_>(null);

  useEffect(() => {
    if (!roles.includes(RoleType.AGENT)) refreshToken(true);
  }, []);

  const handleMaxStep = (step: number) => {
    if (step > maxStep) {
      setMaxStep(step);
    }
  };

  const handleStepClick = (step: number) => {
    if (step <= maxStep) {
      setCurrent(step);
      if (step === stepKeys[1]) {
      }
    }
  };

  const handleNext = async () => {
    if (current === 0) {
      if (
        calculateLongestContinuousDuration(agentSideStore.getEmpHistory()) >= 60
      ) {
        if (bearerToken) {
          setIsLoading(true);
          const response: any = await AgentService.createEmploymentHistory(
            agentSideStore.getEmpHistory(),
            bearerToken
          );
          if (response.status === 'SUCCESS') {
            setCurrent(current + 1);
            handleMaxStep(current + 1);
            setIsLoading(false);
          }
        }
      } else {
        notification.error({
          message: 'Error',
          description: 'Up to 5 years of employment history is mandatory!',
        });
        setIsLoading(false);
      }
    }
    if (current === 1) {
      const answers =
        backgroundQuestionsRef.current?.validateAndGetFilledResponse();

      if (answers !== null && Array.isArray(answers) && answers?.length) {
        setIsLoading(true);
        const formData = new FormData();
        for (const answer of answers) {
          if (answer.hasAttachment && answer.answer === 'true') {
            let alreadyUploaded = false;
            const allFilesInAws = answer.supportingDocuments.every(
              (data: any) => data.url
            );
            for (const file of answer.supportingDocuments) {
              const { prevAnswers, backgroundAnswers } =
                backgroundQuestionsRef.current?.getAnswerDetails();
              if (file.url) {
                if (
                  allFilesInAws &&
                  prevAnswers?.[answer.questionId]?.[0]?.supportingDocuments
                    ?.length ===
                    backgroundAnswers?.[answer.questionId]?.[0]
                      ?.supportingDocuments?.length
                ) {
                  alreadyUploaded = true;
                } else {
                  const response = await fetch(file.url);
                  const blob = await response.blob();
                  const filename = file.name;
                  const uploadedFile = new File([blob], filename, {
                    type: blob.type,
                  });
                  formData.append(
                    answer.questionId + '_file',
                    uploadedFile as RcFile
                  );
                }
              } else {
                formData.append(
                  answer.questionId + '_file',
                  file.originFileObj as RcFile
                );
              }
            }
            if (!alreadyUploaded)
              formData.append(answer.questionId, answer.answer);
          } else {
            formData.append(answer.questionId, answer.answer);
          }
        }

        let currentIndex = 0;
        const intervalId = setInterval(() => {
          setLoaderMessage(loaderMessages[currentIndex]);
          currentIndex = (currentIndex + 1) % loaderMessages.length;
        }, 10000);

        const bearerToken = await getAccessTokenSilently();
        AgentService.postBackGroundQuestions(bearerToken, formData)
          .then(async (response) => {
            clearInterval(intervalId);
            await handleFinish();
            setIsLoading(false);
            message.success('Successfully Updated..!');
          })
          .catch((error: any) => {
            clearInterval(intervalId);
            setIsLoading(false);
            message.error('Failed to submit the answers. Please try again.');
            console.error('Error:', error);
          });
      } else {
        notification.error({
          message: 'Error',
          description: 'Please fill all the questions',
        });
        setIsLoading(false);
      }
    }
  };

  const handleFinish = async () => {
    if (auth0Store.user?.user_id) {
      try {
        setIsLoading(true);
        const token = await getAccessTokenSilently();
        await Auth0Service.onboardAgentOnAuth0(token);
        await refreshToken(true);
        setIsLoading(false);
        navigate(RouteConstants.agentDashBoard.path);
      } catch (err) {
        console.error(err);
      }
    }
  };
  const handlePreviousButton = () => {
    setCurrent(current - 1);
  };

  return (
    <AdminSignUpLayout>
      <div style={{ boxSizing: 'border-box' }}>
        <Card className="content-card">
          <CustomStepper
            current={current}
            steps={steps}
            onClickStep={handleStepClick}
            maxStep={maxStep}
          />
        </Card>
        <div style={{ display: 'flex', gap: '20px' }}>
          <Card
            style={{
              width: '20%',
            }}
            className="content-card agent-signup"
          >
            <div>
              <p
                style={{
                  fontSize: '14px',
                  marginBottom: '5px',
                  marginTop: '16px',
                  color: '#ABABAB',
                }}
              >
                Your Agency
              </p>
              <p
                style={{
                  color: '#222',
                  fontSize: '16px',
                  fontWeight: '500',
                  marginTop: '8px',
                }}
              >
                {agentSideStore.agencyName}
              </p>
            </div>
          </Card>
          <Card
            style={{
              width: '85%',
              padding: '0px',
            }}
            className="content-card agent-signup"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px',
              }}
            >
              <div>
                {current === stepKeys[0] && (
                  <p
                    style={{
                      color: '#222',
                      fontSize: '16px',
                      fontWeight: '500',
                    }}
                  >
                    5 years of employment history has been mandated by NIPR
                  </p>
                )}
                {current === stepKeys[1] && (
                  <p
                    style={{
                      color: '#222',
                      fontSize: '16px',
                      fontWeight: '500',
                    }}
                  >
                    Background Questions
                  </p>
                )}
                <div className="desc-style">
                  {current === stepKeys[0] && (
                    <>
                      <p
                        style={{
                          color: '#222',
                          marginBottom: 6,
                          fontSize: '14px',
                        }}
                      >
                        To use InsureTrek, it is important you save atleast 5
                        years of consecutive history of employment.{' '}
                        <span style={{ color: '#0588ca' }}>
                          It’s a one time thing on InsureTrek
                        </span>
                      </p>
                      <p
                        style={{
                          color: '#222',
                          fontSize: '14px',
                          marginTop: 0,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className="font-14 reset-icon-size"
                      >
                        <ExclamationCircleOutlined
                          style={{
                            color: 'var(--primary-color)',
                            marginRight: 6,
                          }}
                        />
                        Latest employment history must be from 2 months ago for
                        successful license application processing
                      </p>
                    </>
                  )}
                  {current === stepKeys[1] && (
                    <>
                      <p style={{ color: '#222', fontSize: '14px' }}>
                        Background questions helps us take you through NIPR
                        compliances.{' '}
                        <span style={{ color: '#0588ca' }}>
                          It’s also a one time thing on InsureTrek
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', alignSelf: 'center' }}>
                {current === stepKeys[2] ? (
                  <Button
                    id="skip-button"
                    style={{
                      width: '135px',
                      paddingLeft: '10px',
                    }}
                    onClick={handleFinish}
                    size="large"
                  >
                    Skip For now
                  </Button>
                ) : current === stepKeys[1] ? (
                  <Button
                    id="previous-button"
                    style={{ width: '135px' }}
                    className="button-navigation"
                    onClick={handlePreviousButton}
                    disabled={isLoading}
                    size="large"
                  >
                    Previous
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  id={current === 1 ? 'save-finish-button' : 'save-next-button'}
                  style={{
                    width: isLoading ? '150px' : '130px',
                    marginLeft: '10px',
                  }}
                  loading={isLoading}
                  className="button primary-button"
                  type="primary"
                  onClick={handleNext}
                  size="large"
                >
                  {current === 1 ? 'Save & finish' : 'Save & Next'}
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div className="desc-style">
          {current === stepKeys[0] && <EmploymentHistory isOnboarded={false} />}
          {current === stepKeys[1] && !checkBgQuestionAdded && (
            <Spin
              spinning={isLoading}
              tip={loaderMessage}
              className="spin-tip"
              size="large"
            >
              <div>
                <BackgroundQuestionsScreen
                  isLoading={isLoading}
                  editMode={false}
                  ref={backgroundQuestionsRef}
                  isAgentSignup={true}
                />
              </div>
            </Spin>
          )}
        </div>
        <Modal
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          centered={true}
          footer={[
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CustomButton
                classNames="button-navigation"
                type="internalNavigation"
                buttonName="Edit Responses"
              />
              <CustomButton
                classNames="button primary-button"
                type="internalNavigation"
                buttonName="Close"
                styles={{ marginTop: '15px' }}
              />
            </div>,
          ]}
        >
          <Result
            status="success"
            title="Responses Successfully Saved and sent to the admin"
          />
        </Modal>
      </div>
      <FloatButton.BackTop tooltip="Scroll to top" />
    </AdminSignUpLayout>
  );
}

export default observer(AgentSignupSteps);
