import { Button, Tour } from 'antd';
import { useEffect, useState } from 'react';

import { IdConstants } from '../../../constants/id.constants';
import { TourConstants } from '../../../constants/tour.constant';
import type { TourProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../../stores/tour.store';

const tourTitleStyle = {
  margin: 0,
  gap: 8,
};

const TourTitle = () => {
  return (
    <div style={{ margin: 0, paddingTop: 6 }}>
      <h2 style={tourTitleStyle}>Manage Territory</h2>
    </div>
  );
};

const ManageTerritoryTour = () => {
  const createTerritory = document.getElementById(
    IdConstants.STATE_GROUPS.CREATE_STATE_GROUPS_BUTTON + '-state-groups'
  );
  const territoryName = document.getElementsByClassName(
    IdConstants.STATE_GROUPS.EDIT_STATE_GROUPS_CLASS_NAME
  )?.[0];
  const alaskaState = document.getElementById('AK');
  const selectedStatesCard = document.getElementById(
    IdConstants.STATE_GROUPS.SELECTED_STATES_CARD
  );
  const saveTerritory = document.getElementById(
    IdConstants.STATE_GROUPS.SAVE_STATE_GROUP + '-state-groups'
  );
  const stateGroupsTable = document.getElementsByClassName(
    IdConstants.STATE_GROUPS.STATE_GROUPS_TABLE_CLASS_NAME
  )?.[0];
  const antTableTbody = stateGroupsTable?.getElementsByClassName(
    IdConstants.ANT_TABLE_TBODY
  )?.[0];
  const firstRow = antTableTbody?.querySelector(
    `.${IdConstants.ANT_TABLE_ROW}:first-child`
  );
  const placeholderCard = antTableTbody?.querySelector(
    `.${IdConstants.ANT_TABLE_PLACEHOLDER}`
  );
  const siderMenuTerritory = document.getElementById(
    TourConstants.SIDER_MENU.TERRITORIES
  );

  const [currentStep, setCurrentStep] = useState(0);
  const stepsCount = 5;

  useEffect(() => {
    setCurrentStep(tourStore.manageTerritoryCurrentStep);
  }, [tourStore.manageTerritoryCurrentStep]);

  const resetStore = () => {
    siderMenuTerritory?.click();
    tourStore.setManageTerritoryCurrentStep(0);
    tourStore.setManageTerritoryTour(false);
    const isSignupDemo = sessionStorage.getItem(
      TourConstants.SIGNUP_DEMO_BY_SECTION
    );
    if (isSignupDemo) {
      tourStore.setSignUpDemoTour(true);
      sessionStorage.removeItem(TourConstants.SIGNUP_DEMO_BY_SECTION);
    }
  };

  const updateCurrentStep = (isNext: boolean) => {
    if (isNext) {
      if (currentStep + 1 > stepsCount) {
        resetStore();
      } else {
        tourStore.setManageTerritoryCurrentStep(true);
      }
      setCurrentStep((currentStep) => currentStep + 1);
    } else {
      tourStore.setManageTerritoryCurrentStep(false);
      setCurrentStep((currentStep) => currentStep - 1);
    }
  };

  const handlePrevClick = (
    prev: HTMLElement | [HTMLElement, string, number] | null,
    selectChild?: boolean
  ) => {
    const prevElement = Array.isArray(prev) ? prev?.[0] : prev;
    if (!prevElement) return;

    if (Array.isArray(prev)) {
      prevElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(prev?.[1]);
        elem?.click();
      }, prev?.[2]);
    } else {
      if (
        selectChild &&
        !(
          prevElement.id === 'AK' &&
          prevElement
            ?.querySelector('.ant-collapse-header')
            ?.getAttribute('aria-expanded') === 'true'
        )
      ) {
        const elem = prevElement.querySelector('*') as HTMLElement;
        elem?.click();
      }
    }
    updateCurrentStep(false);
  };

  const handleNextClick = (
    next: HTMLElement | [HTMLElement, string, number] | null,
    selectChild?: boolean
  ) => {
    const nextElement = Array.isArray(next) ? next?.[0] : next;
    if (!nextElement) {
      updateCurrentStep(true);
      return;
    }

    if (Array.isArray(next)) {
      nextElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(next?.[1]);
        elem?.click();
        updateCurrentStep(true);
      }, next?.[2]);
    } else {
      nextElement?.click();

      if (
        selectChild &&
        !(
          nextElement.id === 'AK' &&
          nextElement
            ?.querySelector('.ant-collapse-header')
            ?.getAttribute('aria-expanded') === 'true'
        )
      ) {
        const elem = nextElement.querySelector('*') as HTMLElement;
        elem?.click();
      }
      updateCurrentStep(true);
    }
  };

  const navigateTour = (
    prev: HTMLElement | [HTMLElement, string, number] | null,
    next: HTMLElement | [HTMLElement, string, number] | null,
    cb?: (isNext: boolean) => void,
    selectChild?: string
  ) => {
    return (
      <div className="antd-navigate-footer">
        <div>{currentStep + 1} of 6</div>
        <div className="flex" style={{ gap: 12 }}>
          <Button
            size="small"
            style={{
              width: 90,
            }}
            onClick={() => {
              resetStore();
            }}
          >
            Skip
          </Button>
          {![0].includes(currentStep) && (
            <Button
              size="small"
              style={{
                width: 90,
              }}
              onClick={() => {
                if (cb) cb(false);
                handlePrevClick(prev, selectChild === 'prev');
              }}
            >
              Previous
            </Button>
          )}
          <Button
            size="small"
            style={{
              width: 90,
              paddingLeft: 0,
            }}
            onClick={() => {
              if (cb) cb(true);
              handleNextClick(next, selectChild === 'next');
            }}
            type="primary"
          >
            {currentStep === stepsCount ? 'Finish' : 'Next'}
          </Button>
        </div>
      </div>
    );
  };

  const steps: TourProps['steps'] = [
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          Begin by selecting "Create Territory."
          {navigateTour(null, createTerritory)}
        </div>
      ),
      target: createTerritory,
      placement: 'bottom',
    },
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          Enter a name for your territory.
          {navigateTour(
            null,
            alaskaState,
            (isNext) => {
              if (isNext) return;
              tourStore.setManageTerritoryTour(false);

              setTimeout(() => {
                window.history.back();
              }, 50);
              setTimeout(() => {
                tourStore.setManageTerritoryCurrentStep(0);
                tourStore.setManageTerritoryTour(true);
              }, 100);
            },
            'next'
          )}
        </div>
      ),
      target: territoryName as HTMLElement,
      placement: 'bottom',
    },
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          Drag and drop states from the left panel to the right to include them
          in your territory. Customize your selection by choosing specific LC's
          and LOA's for each state.
          {navigateTour(territoryName as HTMLElement, alaskaState)}
        </div>
      ),
      target: alaskaState,
      placement: 'bottom',
    },
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          Review all added states in the right panel. Use the option at the
          bottom to reset selections if needed.
          {navigateTour(alaskaState, null, undefined, 'prev')}
        </div>
      ),
      target: selectedStatesCard,
      placement: 'left',
    },
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          Click "Save Territory" to finalize your creation.
          {navigateTour(selectedStatesCard, null, (isNext: boolean) => {
            if (!isNext) return;
            window.history.back();
          })}
        </div>
      ),
      target: saveTerritory,
      placement: 'topLeft',
    },
    {
      title: <TourTitle />,
      description: (
        <div style={{ paddingBottom: 12 }}>
          View your Territories here in this page. You can edit, delete and
          clone the territories as needed!
          {navigateTour(null, null, (isNext: boolean) => {
            if (isNext) return;
            createTerritory?.click();
            updateCurrentStep(false);
          })}
        </div>
      ),
      target: (firstRow || placeholderCard) as HTMLElement,
      placement: 'topLeft',
    },
  ];

  return (
    <>
      <Tour
        rootClassName="onboard-antd"
        open={tourStore.manageTerritoryTour}
        onClose={() => {
          resetStore();
        }}
        steps={steps}
        current={currentStep}
      />
      {tourStore.manageTerritoryTour && <div className="overlay" />}
    </>
  );
};

export default observer(ManageTerritoryTour);
