import {
  AllLicenseResponse,
  licenseConfigurations,
} from '../../../types/response/all-license.type';
import {
  Button,
  Card,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Spin,
  Tag,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';

import { AgentOverview } from '../../../types/data/allAgents.type';
import { AgentService } from '../../../services/agent.service';
import CustomDescription from '../../../components/common/descriptions/description';
import { GenderType } from '../../../enums/genderType.enum';
import { IdConstants } from '../../../constants/id.constants';
import LcLoaCell from '../../../components/licenses/lcLoaCell';
import { LicenseStatus } from '../../../enums/licenseStatus.enum';
import { LicensesService } from '../../../services/licenses.service';
import { LoaDetail } from '../../../types/data/loaDetail.type';
import { PaginatedSuccessResponse } from '../../../types/response/paginatedSuccessResponse.type';
import { ProductDocumentMinimal } from '../../../types/response/data/agents/agentDocument.type';
import RTSCollapseCard from './rtsCollapse';
import { ReloadOutlined } from '@ant-design/icons';
import { RoleType } from '../../../enums/roles.enum';
import RtsByProducerTour from '../../../components/common/Tour/rtsByProducerTour';
import { StateConstants } from '../../../constants/state.constants';
import { TooltipConstants } from '../../../constants/tooltip.constants';
import { TooltipWrapper } from '../agentsOverview/agentOverview/paymentConfig';
import { adminStore } from '../../../stores/admin.store';
import { agentSideStore } from '../../../stores/agentPortalStore';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import dayjs from 'dayjs';
import { getNameString } from '../../../utils/name.utils';
import { isEmpty } from 'lodash';
import { isXDaysOld } from '../../../utils/date.utils';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../../../stores/signupStore';
import { tourStore } from '../../../stores/tour.store';
import { useAuth } from '../../../auth/authProvider';
import { useParams } from 'react-router';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

export interface StateGroupView {
  ActiveStateLicenses: string;
  LOA: AgentOverview;
}

export enum toggleEnum {
  STATE = 'State',
  CARRIERS = 'Carriers',
}

export interface Appointments {
  companyName: string;
  fein: string;
  coCode: string;
  loa: string;
  status: string;
  terminationReason: string;
  statusReasonDate: string;
  appointRenewalDate: string;
  agencyAffiliations: string[];
  stateCode: string;
  agencyId: string;
  agentId: string;
  appointmentLicenseConfiguration: {
    isSupported: string;
    loa?: string;
    loaCode?: string;
    licenseConfiguration?: {
      loaCode: string;
      loa: string;
    };
  };
}

interface RtsViewAgentsProps {
  hideBioInfo?: boolean;
  isAgentPortal?: boolean;
}

const RtsViewAgents: React.FC<RtsViewAgentsProps> = ({
  hideBioInfo,
  isAgentPortal,
}) => {
  const { getAccessTokenSilently } = useAuth();
  const [agent, setAgent] = useState<ProductDocumentMinimal>();
  const { agentId } = useParams();
  const { bearerToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [accordianLoader, setAccordianLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [queryText, setQueryText] = useQueryState('search');
  const [activeLicenses, setActiveLicenses] = useState<AllLicenseResponse[]>();
  const [appointmentsData, setAppointmentsData] = useState<Appointments[]>([]);
  const [appointmentsLastUpdate, setAppointmentsLastUpdate] = useState('');
  const [licenseLastUpdate, setLicenseLastUpdate] = useState('');
  const [isAppointmentRefreshing, setIsAppointmentRefreshing] = useState(false);
  const [isLicenseRefreshing, setIsLicenseRefreshing] = useState(false);
  const [allowRefresh, setAllowRefresh] = useState(false);
  const [appointmentsAllowRefresh, setAppointmentsAllowRefresh] =
    useState(false);

  const loadAgent = async () => {
    setIsLoading(true);
    if (agentId && bearerToken) {
      const response: any = await AgentService.getAgentDetails(
        agentId,
        bearerToken
      ).catch((error) => {
        console.error('Error:: ', error.message);
      });
      if (response?.data) {
        const producer: ProductDocumentMinimal = response.data;
        appStateInfoStore.setCurrentAgentId(producer.id);
        appStateInfoStore.setHeader(getNameString(producer.name));
        const data = response.data;

        // TODO
        setAppointmentsLastUpdate(
          data?.processingFlags?.appointmentsLastUpdatedAt || ''
        );
        setLicenseLastUpdate(
          data?.processingFlags?.licensesLastUpdatedAt || ''
        );

        setAgent(producer);
      }
    }
    if (agentSideStore.agentId && bearerToken) {
      const response: any = await AgentService.getCurrentAgent(
        bearerToken
      ).catch((error) => {
        console.error('Error:: ', error.message);
      });
      if (response?.data) {
        const producer: ProductDocumentMinimal = response.data;
        appStateInfoStore.setCurrentAgentId(producer.id);
        appStateInfoStore.setHeader(getNameString(producer.name));
        const data = response?.data;
        setAppointmentsLastUpdate(
          data?.processingFlags?.appointmentsLastUpdatedAt || ''
        );
        setLicenseLastUpdate(
          data?.processingFlags?.licensesLastUpdatedAt || ''
        );
        setAgent(producer);
      }
    }
    setIsLoading(false);
  };

  const getAppointments = async () => {
    try {
      if (agentId && bearerToken) {
        setAccordianLoader(true);
        const response: any = await AgentService.getAppoinments(
          agentId,
          bearerToken
        ).catch((error) => {
          console.error('Error:: ', error.message);
        });
        setAppointmentsData(response.data);
      }
      if (agentSideStore.agentId && bearerToken) {
        setAccordianLoader(true);
        const response: any = await AgentService.getAppoinmentsForAgentPortal(
          bearerToken
        ).catch((error) => {
          console.error('Error:: ', error.message);
        });
        setAppointmentsData(response.data);
      }
    } catch (e) {
    } finally {
      setAccordianLoader(false);
    }
  };

  const fetchLicenseDetails = async (
    agencyId: string,
    searchTextProp?: string
  ) => {
    if (!agentId) return;
    try {
      if (bearerToken) {
        if (true) {
          setTableLoader(true);

          const response: any = await AgentService.getLicenses(
            agentId || '',
            bearerToken,
            pageNumber,
            pageSize,
            searchTextProp || ''
          );

          if (response) {
            setTotalCount(response.totalCount);
            setActiveLicenses(response.data);
          } else {
            setTotalCount(0);
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setTableLoader(false);
    }
  };

  const fetchLicenseDetailsForAgent = async (
    pageNumberProp?: number,
    pageSizeProp?: number
  ) => {
    try {
      if (bearerToken) {
        let defaultRequestBody;
        setTableLoader(true);
        defaultRequestBody = {
          agents: [],
          status: [LicenseStatus.Active],
        };
        // TODO Change to AgentService.getLicenses
        const response = (await AgentService.getLicensesForAgentPortal(
          // agentId || '',
          bearerToken
        )) as any;
        if (response) {
          setTotalCount(response.totalCount);
          setActiveLicenses(response.data);
          setTableLoader(false);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const onPaginationChange: PaginationProps['onChange'] = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
  };

  useEffect(() => {
    loadAgent();
    getAppointments();
  }, []);

  useEffect(() => {
    if (licenseLastUpdate) {
      if (isXDaysOld(new Date(licenseLastUpdate), 1)) setAllowRefresh(true);
      else setAllowRefresh(false);
    } else {
      setAllowRefresh(true);
    }
  }, [licenseLastUpdate]);

  useEffect(() => {
    if (appointmentsLastUpdate) {
      if (isXDaysOld(new Date(appointmentsLastUpdate), 1))
        setAppointmentsAllowRefresh(true);
      else setAppointmentsAllowRefresh(false);
    } else {
      setAppointmentsAllowRefresh(true);
    }
  }, [appointmentsLastUpdate]);

  useEffect(() => {
    if (adminStore.agency?.id && isAgentPortal) {
      fetchLicenseDetailsForAgent();
    } else if (adminStore.agency?.id && signupStore.isAdminAuthorized) {
      fetchLicenseDetails(adminStore.agency.id);
    }
  }, [pageNumber, pageSize]);

  const activeColumns: ColumnsType<AllLicenseResponse> = [
    {
      title: 'State',
      dataIndex: 'stateCode',
      key: 'stateName',

      render: (text: string, record: AllLicenseResponse) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {StateConstants[text]}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                fontSize: '12px',
                fontWeight: 400,
                marginTop: '-10px',
                marginBottom: '0',
              }}
            >
              {record.licenseNumber}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseConfigurations',
      key: 'loaDetails',
      render: (record: licenseConfigurations) => {
        const filteredLoas = record?.loaDetails?.filter(
          (loa: any) => loa.status !== 'Inactive' && loa.status !== 'missing'
        );
        return (
          <Row align="middle">
            <LcLoaCell
              licenseClass={record?.licenseClass}
              loaDetails={filteredLoas}
            />
          </Row>
        );
      },
    },
  ];

  const FirstRowItems = [
    {
      label: 'First Name',
      descItem: <>{agent?.name?.firstName}</>,
    },
    {
      label: 'Last Name',
      descItem: <>{agent?.name?.lastName}</>,
    },
    {
      label: 'Business Email Address',
      descItem: <>{agent?.businessEmail}</>,
    },
    {
      label: 'Date Of Birth',
      descItem: (
        <>
          {agent?.dateOfBirth
            ? new Date(dayjs(agent?.dateOfBirth).toDate()).toLocaleDateString(
                'en-US',
                {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                }
              )
            : '-'}
        </>
      ),
    },
    {
      label: 'Gender',
      descItem: (
        <Tag>
          {agent?.gender === GenderType.Male
            ? 'Male'
            : agent?.gender === GenderType.Female
            ? 'Female'
            : '-'}
        </Tag>
      ),
    },
  ];
  const SecondRowItems = [
    {
      label: 'Phone',
      descItem: <>{agent?.businessPhone ? agent?.businessPhone : '-'}</>,
    },
    // {
    //   label: 'FAX',
    //   descItem: <>{agent?.fax ? agent?.fax : '-'}</>,
    // },
    {
      label: 'NPN',
      descItem: <>{agent?.npn}</>,
    },
    {
      label: 'SSN',
      descItem: <>{agent?.ssn ? agent?.ssn : '-'}</>,
    },
    // {
    //   label: 'Producer Type',
    //   descItem: (
    //     <>
    //       {agent?.agencyId === adminStore.agency?.id
    //         ? 'Line Only Producer'
    //         : 'General Producer'}
    //     </>
    //   ),
    // },
  ];

  return (
    <div className="width-100">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxHeight: '1000px',
          height: '100%',
        }}
      >
        {hideBioInfo ? (
          <></>
        ) : (
          <div style={{ width: '100%', marginBottom: 10 }}>
            <span className="typography_1">
              <Card>
                <Row style={{ justifyContent: 'space-between' }}>
                  {!isLoading ? (
                    <Typography.Paragraph
                      style={{
                        fontSize: '16px',
                        marginBottom: 0,
                        fontWeight: 500,
                      }}
                    >
                      {getNameString(agent?.name || '')}
                    </Typography.Paragraph>
                  ) : (
                    <Skeleton active paragraph={{ rows: 0 }} />
                  )}
                </Row>
                <hr className="primary-line" style={{ marginTop: '20px' }} />
                <div>
                  <div>
                    {isLoading ? (
                      <Skeleton active />
                    ) : (
                      <div>
                        <CustomDescription
                          column={5}
                          items={FirstRowItems}
                          layout="vertical"
                          style={{ marginTop: '20px' }}
                        />
                        <CustomDescription
                          column={5}
                          items={SecondRowItems}
                          layout="vertical"
                          style={{ marginTop: '20px' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </span>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            width: '100%',
            columnGap: '10px',
            flexGrow: '1',
            maxHeight: '1000px',
            height: '100%',
          }}
        >
          <Card
            style={{
              width: '60%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'scroll',
            }}
            bodyStyle={{
              display: 'flex',
              flexDirection: 'column',
            }}
            className={`custom-scrollbar ${IdConstants.RTS.BY_PRODUCER.ASSIGNMENTS_CARD}`}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
                marginBottom: '15px',
              }}
            >
              <div className="flex align-items-center justify-between">
                <div className="font-12">
                  {licenseLastUpdate ? (
                    <>
                      Last Updated:{' '}
                      <b className="pl-6">
                        {dayjs(licenseLastUpdate).toString()}
                      </b>
                    </>
                  ) : null}
                </div>
                <TooltipWrapper
                  isDisabled={
                    licenseLastUpdate
                      ? !isXDaysOld(new Date(licenseLastUpdate), 1)
                      : false
                  }
                  children={
                    <Button
                      icon={
                        isLicenseRefreshing ? (
                          <Spin size="small" />
                        ) : (
                          <ReloadOutlined />
                        )
                      }
                      disabled={
                        !allowRefresh || isLicenseRefreshing || tableLoader
                      }
                      onClick={async () => {
                        setIsLicenseRefreshing(true);
                        message.info(
                          'Fetching NIPR License Records. please wait for few seconds.'
                        );
                        const token = await getAccessTokenSilently();
                        let updatedAt;
                        if (agentSideStore.agentId) {
                          updatedAt =
                            await AgentService.refreshAgentLicenseRecordForAgentPortal(
                              token
                            );
                        } else {
                          updatedAt =
                            await AgentService.refreshAgentLicenseRecord(
                              token,
                              agentId || ''
                            );
                        }
                        setLicenseLastUpdate(updatedAt);
                        if (adminStore.agency && signupStore.isAdminAuthorized)
                          fetchLicenseDetails(adminStore.agency.id);
                        setIsLicenseRefreshing(false);
                      }}
                    >
                      Refresh Licenses
                    </Button>
                  }
                  message={TooltipConstants.LICENSE_REFRESH_DISABLED}
                />
              </div>
              <Table
                dataSource={activeLicenses}
                columns={activeColumns}
                pagination={false}
                loading={tableLoader}
              />
              {!isEmpty(activeLicenses) && (
                <Row justify="end">
                  <Pagination
                    disabled={tableLoader}
                    showSizeChanger
                    pageSize={pageSize}
                    current={pageNumber}
                    onChange={onPaginationChange}
                    total={totalCount}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                    }
                  />
                </Row>
              )}
            </div>
          </Card>

          <Card
            style={{
              width: '60%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'scroll',
            }}
            bodyStyle={{
              display: 'flex',
              flexDirection: 'column',
            }}
            className={`custom-scrollbar ${IdConstants.RTS.BY_PRODUCER.APPOINTMENTS_CARD}`}
          >
            <div
              className="flex align-items-center justify-between"
              style={{ marginBottom: 12 }}
            >
              <div className="font-12">
                {appointmentsLastUpdate ? (
                  <>
                    Last Updated:{' '}
                    <b className="pl-6">
                      {dayjs(appointmentsLastUpdate).toString()}
                    </b>
                  </>
                ) : null}
              </div>
              <TooltipWrapper
                isDisabled={
                  appointmentsLastUpdate
                    ? !isXDaysOld(new Date(appointmentsLastUpdate), 1)
                    : false
                }
                children={
                  <Button
                    icon={
                      isAppointmentRefreshing ? (
                        <Spin size="small" />
                      ) : (
                        <ReloadOutlined />
                      )
                    }
                    disabled={
                      !appointmentsAllowRefresh ||
                      isAppointmentRefreshing ||
                      accordianLoader
                    }
                    onClick={async () => {
                      setIsAppointmentRefreshing(true);
                      message.info(
                        'Fetching NIPR Appointment Records. please wait for few seconds.'
                      );
                      const token = await getAccessTokenSilently();
                      let updatedAt;
                      if (agentSideStore.agentId) {
                        updatedAt =
                          await AgentService.refreshAgentAppointmentRecordForAgentPortal(
                            token
                          );
                      } else {
                        updatedAt =
                          await AgentService.refreshAgentAppointmentRecord(
                            token,
                            agentId || ''
                          );
                      }
                      setAppointmentsLastUpdate(updatedAt);
                      getAppointments();
                      setIsAppointmentRefreshing(false);
                    }}
                  >
                    Refresh Appointments
                  </Button>
                }
                message={TooltipConstants.APPOINTMENT_REFRESH_DISABLED}
              />
            </div>
            <RTSCollapseCard
              appointments={appointmentsData}
              accordianLoader={accordianLoader}
            />
          </Card>
        </div>
      </div>
      {tourStore.rtsByProducerTour && tourStore.rtsByProducerVisible && (
        <RtsByProducerTour />
      )}
    </div>
  );
};

export default withRoles(observer(RtsViewAgents), [
  RoleType.AGENT,
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
