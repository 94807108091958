import { isArray, isNumber } from 'lodash';

export const getNiprResponseFromLicenseForModal = (
  license: any
): { errors: string[]; messages: string[] } => {
  const allErrors = [];
  const allMessages: any[] = [];

  if (isArray(license?.gatewayTransaction) || license?.gatewayTransaction) {
    const gatewayTransaction = isArray(license?.gatewayTransaction)
      ? license?.gatewayTransaction[0]
      : license?.gatewayTransaction;
    if (gatewayTransaction?.niprGatewayErrorResponse) {
      allErrors.push(
        ...gatewayTransaction.niprGatewayErrorResponse.map(
          (itr: any) => itr.description
        )
      );
    }
    if (
      isArray(
        gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse
          ?.messages
      ) &&
      gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse?.messages?.filter(
        (itr: any) => itr.actionRequired === 'Y'
      ).length > 0
    ) {
      const errors =
        gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse?.messages
          ?.filter((itr: any) => itr.actionRequired === 'Y')
          .map((itr: any) => itr?.comments);
      allErrors.push(...errors);
    } else if (
      gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse
        ?.messages
    ) {
      if (
        gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse
          ?.messages?.actionRequired === 'Y'
      ) {
        const errors =
          gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse
            ?.messages.comments;

        allErrors.push(errors);
      }
    }

    if (
      isArray(
        gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse
          ?.messages
      ) &&
      gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse?.messages?.filter(
        (itr: any) => itr.actionRequired !== 'Y'
      ).length > 0
    ) {
      const message =
        gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse?.messages
          ?.filter((itr: any) => itr.actionRequired !== 'Y')
          .map((itr: any) => itr?.comments);
      if (message && message.length) allMessages.push(...message);
    } else if (
      gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse
        ?.messages?.actionRequired !== 'Y'
    ) {
      const messages =
        gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse
          ?.messages.comments;

      if (messages && messages.length) allMessages.push(messages);
    }
    // logic for if license.response is an array
    if (
      gatewayTransaction?.niprGatewaySuccessResponse?.license?.response
        ?.length > 0
    ) {
      gatewayTransaction?.niprGatewaySuccessResponse?.license?.response.forEach(
        (licenseResponse: any) => {
          if (licenseResponse?.messages?.comments) {
            if (licenseResponse?.actionRequired === 'Y')
              allErrors.push(licenseResponse.messages.comments);
            else allMessages.push(licenseResponse.messages.comments);
          }

          if (
            licenseResponse?.messages?.filter(
              (itr: any) => itr.actionRequired === 'Y'
            ).length > 0
          ) {
            const errors = licenseResponse.messages
              ?.filter((itr: any) => itr.actionRequired === 'Y')
              .map((itr: any) => itr?.comments);
            allErrors.push(...errors);
          }

          if (
            licenseResponse?.messages?.filter(
              (itr: any) => itr.actionRequired !== 'Y'
            ).length > 0
          ) {
            const responses = licenseResponse.messages
              ?.filter((itr: any) => itr.actionRequired !== 'Y')
              .map((itr: any) => itr?.comments);
            if (licenseResponse.approved === 'N') allErrors.push(...responses);
            else allMessages.push(...responses);
          }
        }
      );
    }
    if (
      gatewayTransaction?.niprGatewaySuccessResponse?.license?.loaCodes
        ?.length > 0
    ) {
      gatewayTransaction.niprGatewaySuccessResponse?.license?.loaCodes.forEach(
        (loaResp: any) => {
          if (loaResp?.response?.length) {
            let requiredLoa = '';
            if (isArray(license.licenseConfigurations)) {
              license.licenseConfigurations.forEach((loa: any) => {
                if (loa.loaCode.toString() === loaResp.loaCode.toString()) {
                  requiredLoa = loa.loa;
                }
              });
            } else if (license.licenseConfigurations) {
              license.licenseConfigurations.loaDetails.forEach((loa: any) => {
                if (loa.loaCode.toString() === loaResp.loaCode.toString()) {
                  requiredLoa = loa.loa;
                }
              });
            }
            loaResp?.response.forEach((resp: any) => {
              const responses = resp.messages;
              responses.length > 0 &&
                allErrors.push(
                  ...responses
                    ?.filter((itr: any) => itr?.actionRequired !== 'Y')
                    .map(
                      (itr: any) => `LOA  - ${requiredLoa} - ` + itr?.comments
                    )
                );
              responses.length > 0 &&
                allMessages.push(
                  ...responses
                    ?.filter((itr: any) => itr?.actionRequired === 'Y')
                    .map((itr: any) => itr?.comments)
                );
            });
          }
        }
      );
    } else if (
      gatewayTransaction?.niprGatewaySuccessResponse?.license?.loaCodes &&
      Object.keys(
        gatewayTransaction?.niprGatewaySuccessResponse?.license?.loaCodes
      )
    ) {
      const loaCodes =
        gatewayTransaction.niprGatewaySuccessResponse?.license?.loaCodes;
      loaCodes?.response.forEach((resp: any) => {
        const responses = resp.messages;
        responses.length > 0 &&
          allErrors.push(
            ...responses
              ?.filter((itr: any) => itr?.actionRequired !== 'Y')
              .map((itr: any) => itr?.comments)
          );
        responses.length > 0 &&
          allMessages.push(
            ...responses
              ?.filter((itr: any) => itr?.actionRequired === 'Y')
              .map((itr: any) => itr?.comments)
          );
      });
    }
    // if (
    //   gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse?.messages?.filter(
    //     (itr: any) => itr.actionRequired !== 'Y'
    //   ).length > 0
    // ) {
    //   const messages =
    //     gatewayTransaction?.niprGatewaySuccessResponse?.license?.intResponse?.messages
    //       ?.filter((itr: any) => itr.actionRequired !== 'Y')
    //       .map((itr: any) => itr?.comments);
    //   allMessages.push(...messages);
    // }
  }

  return {
    errors: allErrors,
    messages: allMessages,
  };
};

export const getCostFromTransaction = (transaction: any): number | null => {
  try {
    let cost = 0;
    if (isNumber(transaction?.niprGatewaySuccessResponse?.stateFee))
      cost += transaction?.niprGatewaySuccessResponse?.stateFee;
    if (isNumber(transaction?.niprGatewaySuccessResponse?.transFee))
      cost += transaction?.niprGatewaySuccessResponse?.transFee;
    return cost;
  } catch (e) {
    return null;
  }
};
