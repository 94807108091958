import { ErrorMessageConstants } from '../constants/error-message.constants';
import { ErrorResponse } from '../types/response/errorResponse.type';
import { ExceptionName } from '../enums/exceptionName.enum';

export const isErrorNpnNotFound = (error: ErrorResponse | null): boolean => {
  return (
    (error?.error?.exceptionName === 'RecordNotFoundException' &&
      error?.error?.key === 'npn') ||
    (error?.error?.exceptionName === 'BadRequestException' &&
      error?.error?.message ===
        'This Producer Has No License Information in NIPR')
  );
};

export const isErrorDuplicateEmail = (error: ErrorResponse | null): boolean => {
  return (
    error?.error?.exceptionName === 'DuplicateValueException' &&
    error?.error?.key === 'email'
  );
};

export const isErrorStripeOnboardingRequired = (
  error: ErrorResponse | null
): boolean => {
  return (
    error?.error?.exceptionName === ExceptionName.BAD_REQUEST_EXCEPTION &&
    error?.error?.message ===
      'Please onboard your account on stripe to access this resource'
  );
};

export const isErrorStripeCustomerMissing = (
  error: ErrorResponse | null
): boolean => {
  return (
    error?.error?.exceptionName === ExceptionName.BAD_REQUEST_EXCEPTION &&
    error?.error?.message === 'Customer Id is missing'
  );
};

export const isErrorNoPaymentMethodsFound = (
  error: ErrorResponse | null
): boolean => {
  return (
    error?.error?.exceptionName === ExceptionName.BAD_REQUEST_EXCEPTION &&
    error?.error?.message === 'No payment methods found for customer'
  );
};

export const isErrorUnprocessedScbReport = (
  error: ErrorResponse | null
): boolean => {
  return (
    error?.error?.exceptionName === ExceptionName.BAD_REQUEST_EXCEPTION &&
    error?.error?.message ===
      'This batch is still processing. Please try again after a while.'
  );
};

export const handleBeError = (error: any) => {
  return (error?.response?.data as ErrorResponse) || error;
};

export const generateAssignmentErrorMessage = (error: any): string => {
  if (error?.errorResponses.length === 1)
    return ErrorMessageConstants.ASSIGNMENT_IN_PROGRESS(
      error?.errorResponses[0]?.agentName
    );
  else if (error?.errorResponses.length > 1)
    return ErrorMessageConstants.ASSIGNMENT_IN_PROGRESS_BULK(
      error?.errorResponses.map((itr: any) => itr?.agentName)
    );
  else return '';
};

export const isErrorSsnIsInvalid = (error: ErrorResponse | null): boolean => {
  return (
    error?.error?.exceptionName === ExceptionName.BAD_REQUEST_EXCEPTION &&
    error?.error?.message.toString().includes('The ssn is invalid')
  );
};

export const isErrorMissingLoaApplication = (
  error: ErrorResponse | null
): boolean => {
  return (
    error?.error?.exceptionName === ExceptionName.BAD_REQUEST_EXCEPTION &&
    error?.error?.message ===
      'Applications of missing LOAs are currently not supported.'
  );
};
