import { Button, Spin } from 'antd';
import { LcDragItem, LoaDragItem } from './editStateGroup';
import {
  LicenseConfig,
  StateGroup,
  StateLicenseConfig,
} from '../../../types/data/stateGroup.types';
import { useEffect, useState } from 'react';

import Card from 'antd/es/card/Card';
import { DragItemTypes } from '../../../types/dnd/dragItem.types';
import DroppableStateCard from './droppable/droppableStateCard';
import { IdConstants } from '../../../constants/id.constants';
import { RoleType } from '../../../enums/roles.enum';
import { getLcandLoaCodeFromStateCode } from '../../../utils/getLcandLoaCodeFromStateCode.utils';
import { useDrop } from 'react-dnd';
import { withRoles } from '../../../auth/useRoles';

interface SelectedStatesCardProps {
  currentLoaDragItem: LoaDragItem;
  currentLcDragItem: LcDragItem;
  currentStateDragItemCode: string;
  stateGroup: StateGroup;
  updateStateLicenseConfig: (stateLicenseConfig: StateLicenseConfig) => void;
  updateLicenseConfig: (
    stateCode: string,
    licenseConfig: LicenseConfig
  ) => void;
  removeStateFromStateGroup: (stateCode: string) => void;
  removeLcFromStateGroup: (stateCode: string, lcCode: string) => void;
  removeLoaFromStateGroup: (
    stateCode: string,
    lcCode: string,
    loaCode: string,
    loaId: string
  ) => void;
  addEmptyStateToStateGroup: (
    stateCode: string,
    licenseConfigs?: LicenseConfig[]
  ) => void;
  handleResetData: () => void;
  isLoading?: boolean;
}

function SelectedStatesCard({
  currentLoaDragItem,
  currentLcDragItem,
  currentStateDragItemCode,
  stateGroup,
  updateStateLicenseConfig,
  updateLicenseConfig,
  removeStateFromStateGroup,
  removeLcFromStateGroup,
  removeLoaFromStateGroup,
  addEmptyStateToStateGroup,
  handleResetData,
  isLoading,
}: SelectedStatesCardProps) {
  const [hoverColor, setHoverColor] = useState<string>();
  const [{ isOverCurrent, dragItemType }, dropRef] = useDrop(
    () => ({
      accept: [DragItemTypes.STATE, DragItemTypes.LC, DragItemTypes.LOA],
      drop(_item: unknown, monitor) {
        const dragItemType = monitor.getItemType();
        if (
          dragItemType === DragItemTypes.STATE &&
          !stateGroup.stateLicenseConfigs
            .map((itr) => itr.stateCode)
            .includes(currentStateDragItemCode)
        ) {
          const lcAndLoaData = getLcandLoaCodeFromStateCode(
            currentStateDragItemCode
          );
          addEmptyStateToStateGroup(
            currentStateDragItemCode,
            lcAndLoaData?.map((data) => ({
              lcCode: data.lcCode,
              loaCodes: data.loaCode,
              loaids: data.loaids,
            }))
          );
        } else if (
          dragItemType === DragItemTypes.LC &&
          !stateGroup.stateLicenseConfigs
            .map((itr) => itr.stateCode)
            .includes(currentLcDragItem.stateCode)
        )
          addEmptyStateToStateGroup(currentLcDragItem.stateCode, [
            {
              lcCode: currentLcDragItem.lcCode,
              loaCodes: currentLcDragItem.loaCodes,
              loaids: currentLcDragItem.loaids,
            },
          ]);
        else if (
          dragItemType === DragItemTypes.LOA &&
          !stateGroup.stateLicenseConfigs
            .map((itr) => itr.stateCode)
            .includes(currentLoaDragItem.stateCode)
        )
          addEmptyStateToStateGroup(currentLoaDragItem.stateCode, [
            {
              lcCode: currentLoaDragItem.lcCode,
              loaCodes: [currentLoaDragItem.loaCode],
              loaids: [currentLoaDragItem.loaids],
            },
          ]);
      },
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
        dragItemType: monitor.getItemType(),
      }),
    }),
    [currentStateDragItemCode, currentLcDragItem, currentLoaDragItem]
  );

  useEffect(() => {
    if (
      (dragItemType === DragItemTypes.STATE &&
        !stateGroup.stateLicenseConfigs
          .map((itr) => itr.stateCode)
          .includes(currentStateDragItemCode)) ||
      (dragItemType === DragItemTypes.LC &&
        !stateGroup.stateLicenseConfigs
          .map((itr) => itr.stateCode)
          .includes(currentLcDragItem.stateCode)) ||
      (dragItemType === DragItemTypes.LOA &&
        !stateGroup.stateLicenseConfigs
          .map((itr) => itr.stateCode)
          .includes(currentLoaDragItem.stateCode))
    )
      setHoverColor('azure');
    else setHoverColor('#ffede8');
  }, [isOverCurrent]);

  return (
    <Card
      id={IdConstants.STATE_GROUPS.SELECTED_STATES_CARD}
      style={{
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        flexGrow: '1',
      }}
    >
      <span
        className="typography_1"
        style={{ flexShrink: '0', height: '50px' }}
      >
        Selected States, License Classes and Line of Authority
      </span>
      <div
        ref={dropRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
          marginTop: '20px',
          padding: '20px',
          overflow: 'auto',
          flexGrow: '1',
          ...(isOverCurrent && {
            backgroundColor: hoverColor,
          }),
        }}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <div>
            {' '}
            {stateGroup.stateLicenseConfigs.map((stateItr, index) => {
              return (
                <DroppableStateCard
                  key={index}
                  stateInfo={stateItr}
                  currentLoaDragItem={currentLoaDragItem}
                  currentLcDragItem={currentLcDragItem}
                  updateStateLicenseConfig={updateStateLicenseConfig}
                  updateLicenseConfig={updateLicenseConfig}
                  removeStateFromStateGroup={removeStateFromStateGroup}
                  removeLcFromStateGroup={removeLcFromStateGroup}
                  removeLoaFromStateGroup={removeLoaFromStateGroup}
                />
              );
            })}
          </div>
        )}
      </div>

      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <Button
          id={IdConstants.STATE_GROUPS.RESET_BUTTON + '-state-groups'}
          onClick={() => {
            handleResetData();
          }}
          style={{
            marginTop: '10px',
            width: ' 20%',
          }}
        >
          Reset
        </Button>
      </div>
    </Card>
  );
}

export default withRoles(SelectedStatesCard, [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
