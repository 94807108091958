import { Button, Col, Modal, Row, Typography } from 'antd';

import { DownlineData } from '../../../../pages/agency/downlines/downlines';
import { DownlineService } from '../../../../services/downlines.service';
import { IdConstants } from '../../../../constants/id.constants';
import { InputOTP } from 'antd-input-otp'; // Don't forget to import this too!
import { message } from 'antd';
import { useAuth } from '../../../../auth/authProvider';
import { useState } from 'react';

type ModalProps = {
  visibility: boolean;
  handleVisibility: (isCancel?: boolean) => void;
  email: string;
  downlinesToBeRemoved: DownlineData[];
  reasonToRemoveDownline: string;
  reloadPage: () => void;
  onSuccess?: () => void;
};

function OTPInputModal(props: ModalProps) {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [otpvalue, setValue] = useState<string[]>([]);

  const removeDownlines = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const downlineIds = props.downlinesToBeRemoved
        .map((downline) =>
          downline.agencyType === 'agency'
            ? downline?.agency?.id
            : downline?.producer?.id
        )
        .filter((id): id is string => id !== undefined && id !== null);
      if (token && downlineIds.length > 0) {
        await DownlineService.removeDownlinesById(
          token,
          downlineIds,
          otpvalue.join('')
        );
        if (props.onSuccess) {
          props.onSuccess();
        }
        message.info(
          props.downlinesToBeRemoved.length === 1
            ? 'Downline Removed Successfully!'
            : 'Downlines Removed Successfully!'
        );
        props.handleVisibility();
        props.reloadPage();
      }
    } catch (error: any) {
      console.error(`Error while removing downline ${error}`);
      message.error(
        error?.response?.data?.message ||
          'Something went wrong please try again'
      );
    } finally {
      setValue([]);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props.visibility}
      onCancel={() => {
        if (!loading) {
          props.handleVisibility(false);
          setValue([]);
        }
      }}
      closable
      maskClosable={false}
      centered
      footer={
        <Row justify="space-between">
          <Col>
            <Button
              id={
                IdConstants.AGENCY.DOWNLINES.REMOVE_DOWNLINE_CONFIRMATION_MODAL
                  .CANCEL_BUTTON
              }
              size="large"
              disabled={loading}
              onClick={() => {
                setValue([]);
                props.handleVisibility(true);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              id={
                IdConstants.AGENCY.DOWNLINES.REMOVE_DOWNLINE_CONFIRMATION_MODAL
                  .CONFIRM_BUTTON
              }
              disabled={otpvalue.filter((item) => item !== '').length < 4}
              size="large"
              loading={loading}
              type="primary"
              className="button important-action-button"
              onClick={() => {
                removeDownlines();
              }}
              style={{ width: '100%' }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      }
    >
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '16px',
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        OTP Verification
      </Typography.Paragraph>

      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '14px',
          fontWeight: '400',
          textAlign: 'center',
        }}
      >
        Enter OTP Code sent to {props.email}
      </Typography.Paragraph>
      <InputOTP
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onPaste={(event) => {
          const pastedText = event.clipboardData.getData('text/plain');
          if (!/^[0-9]+$/.test(pastedText)) {
            event.preventDefault();
          }
        }}
        length={4}
        onChange={(data) => {
          setValue(data);
        }}
        value={otpvalue}
        autoSubmit={() => {}}
      />
    </Modal>
  );
}

export default OTPInputModal;
