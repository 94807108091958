import {
  AutoComplete,
  Button,
  Card,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import { RoleType, RoleTypeLabels } from '../../../enums/roles.enum';
import Table, { ColumnsType } from 'antd/es/table';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import { AdminInvitesService } from '../../../services/adminInvites.service';
import { AllAdmins } from '../../../types/data/allAdmins';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { InvitedAdmins_ } from '../../../types/data/invitedAdmins';
import { PageConstants } from '../../../constants/page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import RevokeAccessOrInviteModal from '../../../utils/modal/admins/revoke-admin-access-or-invite/revokeAccessOrInvite.modal';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { adminStore } from '../../../stores/admin.store';
import { applySort } from '../../../utils/common.utils';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { getNameString } from '../../../utils/name.utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { tourStore } from '../../../stores/tour.store';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
function ViewUserInvitations() {
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(10);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [activeData, setActiveData] = useState<any>('');
  const [searchText, setSearchText] = useState('');
  const [prevSearchText, setPrevSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  const { getAccessTokenSilently } = useAuth();
  const [revokeModalVisibility, setRevokeModalVisibility] =
    useState<boolean>(false);
  const [revokeModalDetails, setRevokeModalDetails] = useState<
    AllAdmins | InvitedAdmins_
  >();

  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [controlledFilters, setControlledFilters] = useState([]);
  const [sortValue, setSortValue] = useState({});

  const onRevokeModalClose = () => {
    setRevokeModalVisibility(false);
    fetchUserInvitation();
  };

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchUserInvitation(queryText, newPageNumber, newPageSize);
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups: [],
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const fetchFiltersInfoForUsers = async () => {
    try {
      setIsFilterInfoLoading(true);
      const bearerToken = await getAccessTokenSilently();
      if (bearerToken) {
        const response: any =
          await AdminInvitesService.getFilterInfoForUserInvites(bearerToken);
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.USER_INVITATIONS
          )
        );
        setControlledFilters(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
  };

  const fetchUserInvitation = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object
  ) => {
    try {
      const bearerToken = await getAccessTokenSilently();
      if (bearerToken) {
        if (tourStore.inviteUsersTourPhase === 2 && tourStore.inviteUsersTour) {
          tourStore.setInviteUsersTourVisible(false);
        }
        setIsLoading(true);
        cancelToken && cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const bearerToken = await getAccessTokenSilently();
        const response = await adminStore.loadAllInvitedAdminState(
          searchTextProp ? searchTextProp : queryText || '',
          searchType ? searchType : '',
          pageNumberProp || pageNumber,
          pageSizeProp || pageSize,
          bearerToken,
          { ...requestBody, ...(sort || sortValue || {}) },
          cancelTokenSource
        );
        if (response) {
          setCount(response.count);
          setActiveData(response.invites);
          setCancelToken(undefined);
          setIsLoading(false);
        } else {
          setCount(0);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setCancelToken(undefined);
      }
    } finally {
      if (tourStore.inviteUsersTourPhase === 2 && tourStore.inviteUsersTour) {
        setTimeout(() => {
          tourStore.setInviteUsersTourVisible(true);
        }, 100);
      }
    }
  };

  const invitedAdminColumns: ColumnsType<InvitedAdmins_> = [
    {
      title: 'Role',
      dataIndex: 'inviteeDetails',
      key: 'inviteeDetails.role',
      sorter: true,
      render: (inviteeDetails) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {RoleTypeLabels[inviteeDetails.role as RoleType]}
          </Typography.Paragraph>
        </Row>
      ),
    },

    {
      title: 'Email Address',
      dataIndex: 'inviteeDetails',
      key: 'inviteeDetails.email',
      sorter: true,
      render: ({ email }) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {email}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: (
        <span>
          Invited On{' '}
          <Tooltip title="The invite expires in 30 days from start date!">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: 'invitedOn',
      key: 'createdAt',
      sorter: true,
      render: (text: string, record: InvitedAdmins_) => {
        let color = record.isExpired ? '#ff4d4f' : '#faad14';
        let content = '';
        if (record.isExpired) {
          if (record.status === 'accepted') {
            color = '#81B622';
            content = 'Invite accepted';
            if (record.respondedAt && moment.isDate(record.respondedAt))
              content += ` on ${moment(record.respondedAt).format('ll')}`;
          } else if (record.status === 'rejected') {
            content = 'Invite rejected';
            if (record.respondedAt && moment.isDate(record.respondedAt))
              content += ` on ${moment(record.respondedAt).format('ll')}`;
          } else if (record.status === 'revoked') {
            content = 'Invite revoked';
            if (record.respondedAt && moment.isDate(record.respondedAt))
              content += ` on ${moment(record.respondedAt).format('ll')}`;
          } else {
            content = 'Invite expired';
            if (record.expiryDate && moment.isDate(record.expiryDate))
              content += ` on ${moment(record.expiryDate).format('ll')}`;
          }
        } else if (
          record.expiryDate &&
          moment.isDate(new Date(record.expiryDate))
        ) {
          content = `Invite expires in ${moment(record.expiryDate)
            .startOf('day')
            .diff(moment().startOf('day'), 'days')} day(s)`;
        }

        return (
          <Row align="middle">
            <Tooltip title={content}>
              <Typography.Paragraph
                style={{
                  color: color,
                  fontSize: '14px',
                }}
              >
                {moment(text).format('ll')}
              </Typography.Paragraph>
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: 'Invited By',
      dataIndex: 'invitedBy',
      key: 'inviterDetails.account.name',
      sorter: true,
      render: (_: string, record: InvitedAdmins_) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '14px',
            }}
          >
            {getNameString(record.inviterDetails?.account?.name)}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      render: (text: string[], record: InvitedAdmins_) => {
        if (!record.isExpired)
          return (
            <Row
              align="middle"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Tooltip title={'Revoke Invite'}>
                <Button
                  // shape="circle"
                  onClick={() => {
                    if (!record.isExpired) {
                      setRevokeModalVisibility(true);
                      setRevokeModalDetails(record);
                    }
                  }}
                  style={{
                    fontSize: '14px',
                    zIndex: 1000,
                    cursor: record.isExpired ? 'not-allowed' : 'pointer',
                    color: record.isExpired ? '#999' : '#000',
                  }}
                  disabled={record.isExpired}
                  className="button important-action-button"
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>
            </Row>
          );
      },
    },
  ];
  useEffect(() => {
    fetchUserInvitation();
  }, [queryText, searchType]);

  useEffect(() => {
    if (adminStore.isAccessRevoked) {
      fetchUserInvitation();
      adminStore.setIsAccessRevoked(false);
    }
  }, [adminStore.isAccessRevoked]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
    if (queryText !== prevSearchText && prevSearchText !== '') {
      fetchUserInvitation(queryText || '');
      setPrevSearchText(queryText || '');
    }
  }, [queryText]);

  useEffect(() => {
    if (Object.keys(requestBody).length) fetchUserInvitation();
    setPageNumber(1);
  }, [requestBody]);

  useEffect(() => {
    fetchFiltersInfoForUsers();
  }, []);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <Card
      style={{
        width: '100%',
        height: 'fit-content',
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      ></ConfigProvider>

      {/* Comment by RB */}
      {/* Commenting out the code until new BE code is integrated */}
      <div style={{ display: 'flex', columnGap: '12px', margin: '10px 0px' }}>
        <AutoComplete
          options={options}
          style={{ width: '100%' }}
          value={getSearchDisplayText(
            searchType,
            displaySearchType,
            searchText
          )}
          disabled={isLoading}
          onSelect={handleOnSelect}
          onChange={handleOnChange}
        >
          <Search
            id={IdConstants.SEARCH_INPUT + '-agency-users-view-invitations'}
            size="middle"
            placeholder="Search By Email or Invited By"
            style={{ width: '100%', marginRight: '10px', marginBottom: '10px' }}
            onSearch={handleOnSearch}
          />
        </AutoComplete>
        <FilterButton
          {...{
            filterInfoLoading,
            activeData,
            requestBody,
            isLoading,
            setIsFilterVisible,
          }}
        />
      </div>

      <RenderFilterChip {...{ selectedFilters, removeFilter, clearFilters }} />
      {/* Comment by RB End */}

      <Table
        columns={invitedAdminColumns}
        dataSource={activeData}
        rowSelection={undefined}
        scroll={{ y: 700 }}
        pagination={false}
        className={IdConstants.AGENTS.INVITE_DOWNLINE.VIEW_INVITATIONS}
        loading={isLoading}
        onChange={(pagination, filters, sorter) => {
          const sort = applySort(sorter, fetchUserInvitation);
          setSortValue(sort);
        }}
      />
      {!isEmpty(activeData) && (
        <Row className="pagination" style={{ marginTop: '10px' }} justify="end">
          <Pagination
            defaultCurrent={1}
            total={count}
            pageSize={pageSize}
            onChange={onChange}
            showSizeChanger
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            disabled={isLoading}
          />
        </Row>
      )}
      <RevokeAccessOrInviteModal
        visible={revokeModalVisibility}
        setVisible={onRevokeModalClose}
        adminDetails={revokeModalDetails}
        revokeType={''}
        refetchFilter={() => {
          fetchFiltersInfoForUsers();
        }}
      />
      {/* Comment by RB */}
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        controlledFilters={controlledFilters}
        fetchFilters={() => {
          return new Promise((resolve) =>
            resolve([] as FilterFieldsMetaData[])
          );
        }}
        clearFilters={() => {
          clearFilters();
        }}
        filterPage={PageConstants.USER_INVITATIONS}
      />
    </Card>
  );
}

export default withRoles(observer(ViewUserInvitations), [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
