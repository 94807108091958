import {
  ActiveLicense,
  IndividualOnboardAgentProps,
} from '../pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';

import { AddressType } from '../enums/addressType.enum';
import { AgencyService } from '../services/agency.service';
import { AgentDetail } from '../types/data/agentDetail.type';
import { AssignedState } from '../types/data/stateGroup.types';
import { CreateStateAddress } from '../types/request/agency/addresses/createStateAddress.type';
import { IndividualOnboardSteps } from '../enums/onboardSteps.enum';
import { LicenseApplicationResponse } from '../types/response/license-application.type';
import { PaymentTypeEnum } from '../enums/paymentType.enum';
import { adminStore } from './admin.store';
import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
export interface Licenses {
  state: string;
  licenseNo: string;
  autoRenews: boolean;
  status: string;
  renewalPaymentPayee: string;
  licenseClass: string;
  loa: string[];
  daysToExpire: number;
}

export interface AgentLookupState {
  type: string;
  producer: {
    entityBiographic: {
      biographic: {
        idSsn: string;
        firstName: string;
        lastName: string;
        middleName: string;
        nameSuffix: string;
        dateOfBirth: Date;
        idFein: string | null;
        nameCompany: string | null;
        stateCoId: string | null;
        stateDomicile: string | null;
        npn: string;
        otherNames: {
          state: string;
          nameType: string;
        }[];
      };
      addresses: CreateStateAddress[];
      contactInfos: {
        state: string;
        contactInfos: {
          businessPhone: string;
          businessPhoneUpdateDate: string;
          businessEmail: string;
          businessEmailUpdateDate: string;
          fax: string;
          faxUpdateDate: string;
        }[];
      }[];
    };
    producerLicensing: {
      licenseInformation: {
        state: string;
        licenses: {
          licenseNumber: string;
          dateUpdated: string;
          dateIssueLicenseOrigin: string;
          dateExpireLicense: string;
          licenseClass: string;
          licenseClassCode: string;
          residencyStatus: string;
          active: string;
          details: {
            loa: string;
            loaCode: string;
            authorityIssueDate: string;
            status: string;
            statusReason: string;
            statusReasonDate: string;
            ceCompliance: string;
            ceRenewalDate: string;
            ceCreditsNeeded: string;
          }[];
        }[];
        appointmentInformation: {
          companyName: string;
          fein: string;
          coCode: string;
          loa: string;
          loaCode: string;
          status: string;
          terminationReason: string;
          statusReasonDate: string;
          appointRenewalDate: string;
          agencyAffiliations: any | null;
        }[];
      }[];
      regulatoryActions: {
        state: string;
        regulatoryAction: {
          actionId: string;
          originOfAction: string;
          reasonForAction: string;
          disposition: string;
          dateOfAction: string;
          effectiveDate: string;
          enterDate: string;
          fileRef: string;
          penaltyFineForfeiture: string;
          lengthOfOrder: string;
        };
      }[];
    };
  };
}

const agentInfo = {
  isExistingAgent: false,
  agentEmail: '',
  agentNPN: '',
  agentSsn: '',
  agentFax: '',
  agentGender: '',
  agentDateOfBirth: '',
  agentHomeStateAddresses: [],
  addressLastUpdatedAt: new Date(),
  agentPhone: '',
  firstName: '',
  lastName: '',
  producerId: '',
};

export interface CreateAgentData {
  id?: string;
  agencyId?: string;
  name: { firstName: string; middleName?: string; lastName: string };
  email: string;
  businessEmail: string;
  fax: string;
  phone: string;
  npn: string;
  ssn: string;
  homeState: string;
  isInvited: boolean;
  isVerified: boolean;
  isRegistered: boolean;
  initialPaymentConfig: {
    initialPaymentPayee: string;
    initialPaymentType: {
      type: string;
      capAmount: number | null;
      usedAmount: number | null;
    };
  };
  renewalPaymentConfig: {
    autoRenewEnabled: boolean;
    renewalPaymentPayee: string;
    renewalPaymentType: {
      type: string;
      capAmount: number | null;
      usedAmount: number | null;
    };
  };
  paymentConfig: {
    initialPaymentPayee: string;
    renewalPaymentPayee: string;
    paymentType: any;
    amountCap: number | null;
  };
  autoRenewEnabled: boolean;
  paymentType?: string;
  selfAmountUsed: number;
  parentAmountUsed: number;
  paymentsConfigured?: boolean;
  stateAddresses: CreateStateAddress[];
}

class IndividualOnboardStore {
  isOnboarding: boolean = false;
  currentStep: IndividualOnboardSteps = IndividualOnboardSteps.AGENT_DETAILS;
  maxStep: IndividualOnboardSteps = IndividualOnboardSteps.AGENT_DETAILS;
  isModalVisible: boolean = false;
  assignmentSkipped: boolean = false;
  agentData: AgentLookupState | null = null;
  assignedStates: AssignedState[] | null = null;
  licenses: LicenseApplicationResponse[] = [];
  fetchDetailsSuccessful: boolean = false;
  errorMessage: string = '';
  agencyName: string = '';
  walletBalance: number = 0;
  existingAgentId: string | null = null;
  entityInfoRecordId: string = '';
  producerActiveLicenses: ActiveLicense[] = [];

  getProducerActiveLicenses(): ActiveLicense[] {
    return this.producerActiveLicenses;
  }

  setProducerActiveLicenses(activeProducerLicenses: ActiveLicense[]) {
    this.producerActiveLicenses = activeProducerLicenses;
  }

  getEntityInfoRecordId(): string {
    return this.entityInfoRecordId;
  }

  setEntityInfoRecordId(value: string) {
    this.entityInfoRecordId = value;
  }

  getExistingAgentId(): string | null {
    return this.existingAgentId;
  }

  setExistingAgentId(value: string) {
    this.existingAgentId = value;
  }

  setAssignmentSkipped = (value: boolean) => {
    this.assignmentSkipped = value;
  };

  setAgentId = (value: string) => {
    this.createAgentData.id = value;
  };

  getAgentId = () => {
    return this.createAgentData.id;
  };

  setIsOnboard = (value: boolean) => {
    this.isOnboarding = value;
  };

  setLicenses(value: LicenseApplicationResponse[]) {
    this.licenses = value;
  }

  verifyValue: string = 'ssn';
  npn: string = '';
  ssn: string = '';
  lastName: string = '';
  licenseNo: string = '';
  state: string = '';
  homeState: string = '';

  createAgentData: CreateAgentData = {
    agencyId: '',
    name: { firstName: '', middleName: '', lastName: '' },
    email: '',
    businessEmail: '',
    fax: '',
    phone: '',
    npn: '',
    ssn: '',
    homeState: '',
    isInvited: false,
    isVerified: false,
    isRegistered: false,
    initialPaymentConfig: {
      initialPaymentPayee: 'Agent',
      initialPaymentType: {
        type: 'Full',
        capAmount: null,
        usedAmount: null,
      },
    },
    renewalPaymentConfig: {
      autoRenewEnabled: false,
      renewalPaymentPayee: 'Agent',
      renewalPaymentType: {
        type: 'Full',
        capAmount: null,
        usedAmount: null,
      },
    },
    paymentConfig: {
      initialPaymentPayee: '',
      renewalPaymentPayee: '',
      paymentType: PaymentTypeEnum.NA,
      amountCap: null,
    },
    autoRenewEnabled: false,
    selfAmountUsed: 0,
    parentAmountUsed: 0,
    stateAddresses: [
      {
        state: '',
        addresses: [
          {
            dateUpdated: '',
            addressTypeCode: '',
            addressType: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            state: '',
            zip: '',
            country: '',
          },
          {
            dateUpdated: '',
            addressTypeCode: '',
            addressType: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            state: '',
            zip: '',
            country: '',
          },
          {
            dateUpdated: '',
            addressTypeCode: '',
            addressType: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            state: '',
            zip: '',
            country: '',
          },
        ],
      },
    ],
  };

  agentDetails: AgentDetail = {
    agentName: {
      firstName: '',
      middleName: '',
      lastName: '',
    },
    agencyId: '',
    emailAddress: '',
    businessEmail: '',
    npn: '',
    ssn: '',
    phone: '',
    fax: '',
    homeState: '',
    gender: '',
    dateOfBirth: dayjs(new Date()),
    addresses: [
      {
        dateUpdated: '',
        addressTypeCode: '',
        addressType: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      {
        dateUpdated: '',
        addressTypeCode: '',
        addressType: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      {
        dateUpdated: '',
        addressTypeCode: '',
        addressType: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
    ],
  };

  setAgencyDetails = (value: string) => {};

  setVerifyValue = (value: string) => {
    this.verifyValue = value;
  };

  setFetchDetailsSuccess = (value: boolean) => {
    this.fetchDetailsSuccessful = value;
  };

  setSsn = (values: any) => {
    this.ssn = values['ssn'];
    this.lastName = values['lastName'];
  };

  setnpn = (values: any) => {
    this.npn = values['npn'];
  };

  setLicenseNo = (values: any) => {
    this.licenseNo = values['license'];
    this.state = values['state'];
  };

  constructor() {
    makeAutoObservable(this);
  }

  fetchAgency = async (token: string) => {
    const agencyInfo: any = await AgencyService.getMyAgencyInfo(token);
    this.agencyName = agencyInfo.data.name;
    this.walletBalance = agencyInfo.data.accountId.balance;
  };

  setCurrentStep = (value: IndividualOnboardSteps) => {
    this.currentStep = value;
  };

  setMaxStep = (value: IndividualOnboardSteps) => {
    this.maxStep = value;
  };

  setAgentData = (agentData: AgentLookupState) => {
    this.agentData = agentData;
  };

  resetAgentDetails() {
    this.agentDetails = {
      agentName: {
        firstName: '',
        middleName: '',
        lastName: '',
      },
      agencyId: '',
      emailAddress: '',
      businessEmail: '',
      npn: '',
      ssn: '',
      phone: '',
      fax: '',
      homeState: '',
      gender: '',
      dateOfBirth: dayjs(new Date()),
      addresses: [
        {
          dateUpdated: '',
          addressTypeCode: '',
          addressType: '',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          city: '',
          state: '',
          zip: '',
          country: '',
        },
        {
          dateUpdated: '',
          addressTypeCode: '',
          addressType: '',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          city: '',
          state: '',
          zip: '',
          country: '',
        },
        {
          dateUpdated: '',
          addressTypeCode: '',
          addressType: '',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          city: '',
          state: '',
          zip: '',
          country: '',
        },
      ],
    };
    this.createAgentData.paymentConfig = {
      initialPaymentPayee: '',
      renewalPaymentPayee: '',
      paymentType: PaymentTypeEnum.NA,
      amountCap: -1,
    };
    this.createAgentData.autoRenewEnabled = false;
  }

  setAgentDetails = (object: any) => {
    const licenseInformations =
      object.producer.producerLicensing.licenseInformation;

    for (const licenseInfo of licenseInformations) {
      const matchingLicense = licenseInfo.licenses.find(
        (license: any) => license.residencyStatus === 'R'
      );

      if (matchingLicense) {
        this.homeState = licenseInfo.state;
        this.agentDetails.homeState = this.homeState;
        break;
      }
    }

    this.agentDetails.agentName.firstName =
      object.producer.entityBiographic.biographic.firstName;
    this.agentDetails.agentName.middleName =
      object.producer.entityBiographic.biographic.middleName;
    this.agentDetails.agentName.lastName =
      object.producer.entityBiographic.biographic.lastName;
    this.agentDetails.ssn = object.producer.entityBiographic.biographic.idSsn;
    this.agentDetails.npn = object.producer.entityBiographic.biographic.npn;
    this.agentDetails.agentName.firstName =
      object.producer.entityBiographic.biographic.firstName;
    this.agentDetails.dateOfBirth = dayjs(
      object.producer.entityBiographic.biographic.dateOfBirth
    );

    this.createAgentData.stateAddresses =
      object.producer.entityBiographic.addresses;
    const addressesInfo = object.producer.entityBiographic.addresses;
    const matchingAddresses = addressesInfo.find(
      (addressInfo: any) => addressInfo.state === this.homeState
    );

    if (matchingAddresses) {
      const addresses = matchingAddresses.addresses;

      for (const address of addresses) {
        if (address.addressTypeCode === '1') {
          this.agentDetails.addresses[0] = {
            addressTypeCode: '1',
            addressType: AddressType.RESIDENCE,
            addressLine1: address.addressLine1,
            addressLine2: address.addressLine2,
            addressLine3: address.addressLine3,
            city: address.city,
            state: address.state,
            zip: address.zip,
            country: address.country,
          };
        } else if (address.addressTypeCode === '2') {
          this.agentDetails.addresses[2] = {
            addressTypeCode: '2',
            addressType: AddressType.BUSINESS,
            addressLine1: address.addressLine1,
            addressLine2: address.addressLine2,
            addressLine3: address.addressLine3,
            city: address.city,
            state: address.state,
            zip: address.zip,
            country: address.country,
          };
        } else if (address.addressTypeCode === '3') {
          this.agentDetails.addresses[1] = {
            addressTypeCode: '3',
            addressType: AddressType.MAILING,
            addressLine1: address.addressLine1,
            addressLine2: address.addressLine2,
            addressLine3: address.addressLine3,
            city: address.city,
            state: address.state,
            zip: address.zip,
            country: address.country,
          };
        }
      }
    }
    this.setCreateAgent();
  };

  setAgentPhone = (phone: string) => {
    this.agentDetails.phone = phone;
  };

  setCreateAgent = () => {
    if (this.createAgentData) {
      this.createAgentData.agencyId = adminStore.agency?.id;
      this.createAgentData.name.firstName =
        this.agentDetails.agentName.firstName;
      this.createAgentData.name.middleName =
        this.agentDetails.agentName.middleName;
      this.createAgentData.name.lastName = this.agentDetails.agentName.lastName;
      this.createAgentData.npn = this.agentDetails.npn;
      this.createAgentData.ssn = this.agentDetails.ssn;
      this.createAgentData.email = this.agentDetails.emailAddress;
      this.createAgentData.businessEmail = this.agentDetails.businessEmail;
      this.createAgentData.fax = this.agentDetails.fax;
      this.createAgentData.phone = this.agentDetails.phone;
      this.createAgentData.homeState = this.agentDetails.homeState;
    }
  };

  updateInitialPaymentByAgentId = (
    agents: string[],
    initialPaymentAccount: any
  ) => {
    this.createAgentData.paymentConfig.initialPaymentPayee =
      initialPaymentAccount;
  };
  updateRenewalPaymentByAgentId = (
    agents: string[],
    renewalPaymentAccount: any
  ) => {
    this.createAgentData.paymentConfig.renewalPaymentPayee =
      renewalPaymentAccount;
  };
  updatePaymentTypeByAgentId = (
    agents: string[],
    paymentType: string,
    paymentCap: number
  ) => {
    this.createAgentData.paymentConfig.paymentType = paymentType;
    this.createAgentData.paymentConfig.amountCap =
      paymentType === PaymentTypeEnum.CAP ? paymentCap : -1;
  };

  updateAutoRenewalByAgentId = (agents: string[], autoRenewal: any) => {
    this.createAgentData.autoRenewEnabled = autoRenewal;
  };

  setAssignedStates = (values: any) => {
    this.assignedStates = values;
  };

  updateAgentDetails = (values: any) => {
    this.agentDetails.emailAddress = values['emailAddress'];
    this.createAgentData.email = values['emailAddress']?.toLowerCase();
    this.createAgentData.businessEmail =
      this.createAgentData.businessEmail ||
      values['emailAddress']?.toLowerCase();
    this.agentDetails.fax = values['fax'];
    if (!this.createAgentData.ssn) this.createAgentData.ssn = values['ssn'];
    this.agentDetails.phone = values['phone'];
    this.createAgentData.phone = values['phone'];
    this.agentDetails.addresses[0] = {
      dateUpdated: new Date().toISOString(),
      addressTypeCode: '1',
      addressType: 'Residence',
      addressLine1: values['raddressLine1']
        ? values['raddressLine1'].trim()
        : values['raddressLine1'],
      addressLine2: values['raddressLine2']
        ? values['raddressLine2'].trim()
        : values['raddressLine2'],
      addressLine3: values['raddressLine3']
        ? values['raddressLine3'].trim()
        : values['raddressLine3'],
      zip: values['rzipcode'],
      city: values['rcity'],
      country: values['rcountry'],
      state: values['rstate'],
    };
    this.agentDetails.addresses[1] = {
      dateUpdated: new Date().toISOString(),
      addressTypeCode: '2',
      addressType: 'Business',
      addressLine1: values['baddressLine1']
        ? values['baddressLine1'].trim()
        : values['baddressLine1'],
      addressLine2: values['baddressLine2']
        ? values['baddressLine2'].trim()
        : values['baddressLine2'],
      addressLine3: values['baddressLine3']
        ? values['baddressLine3'].trim()
        : values['baddressLine3'],
      zip: values['bzipcode'],
      city: values['bcity'],
      country: values['bcountry'],
      state: values['bstate'],
    };
    this.agentDetails.addresses[2] = {
      dateUpdated: new Date().toISOString(),
      addressTypeCode: '3',
      addressType: 'Mailing',
      addressLine1: values['maddressLine1']
        ? values['maddressLine1'].trim()
        : values['maddressLine1'],
      addressLine2: values['maddressLine2']
        ? values['maddressLine2'].trim()
        : values['maddressLine2'],
      addressLine3: values['maddressLine3']
        ? values['maddressLine3'].trim()
        : values['maddressLine3'],
      zip: values['mzipcode'],
      city: values['mcity'],
      country: values['mcountry'],
      state: values['mstate'],
    };
  };
  setModalVisibility = (value: boolean) => (this.isModalVisible = value);

  // v2 store
  currentOnboardStep: number = 0;
  agentBioInfo: IndividualOnboardAgentProps = agentInfo;
  isProducerDataFetching: boolean = false;

  setAgentBioInfo(agentBioInfo: IndividualOnboardAgentProps) {
    this.agentBioInfo = agentBioInfo;
  }
  setProducerEmail(agentEmail: string) {
    this.agentBioInfo.agentEmail = agentEmail;
  }
  setCurrentOnboardStep(currentOnboardStep: number) {
    this.currentOnboardStep = currentOnboardStep;
  }
  setIsProducerDataFetching(isProducerDataFetching: boolean) {
    this.isProducerDataFetching = isProducerDataFetching;
  }
  setProducerId(producerId: string) {
    this.agentBioInfo.producerId = producerId;
  }

  resetStore = () => {
    this.currentOnboardStep = 0;
    this.agentBioInfo = agentInfo;
  };
  //
}

export const individualOnboardStore = new IndividualOnboardStore();
