import { Button, Modal, Typography } from 'antd';

import { IdConstants } from '../../../../constants/id.constants';
import { useState } from 'react';
import AgentsByStateGroupModal from '../../../../components/agentByStateGroup/AgentsByStateGroupModal';

type SaveStateGroupModalProps = {
  visible: boolean;
  handleVisibility: () => void;
  onSave: () => void;
  manageAssignments: boolean;
  loading: boolean;
  stateGroupId: string;
};

function SaveStateGroupConfirmationModal(props: SaveStateGroupModalProps) {
  const [isViewAgents, setIsViewAgents] = useState(false);
  return (
    <>
      <Modal
        open={props.visible}
        onCancel={props.handleVisibility}
        closable
        destroyOnClose
        centered
        width={600}
        style={{ boxShadow: 'none' }}
        footer={
          <div
            style={{
              display: 'flex',
              columnGap: '10px',
              paddingTop: 12,
              justifyContent: 'space-between',
            }}
          >
            <Button
              id="agents-button"
              style={{
                background: 'var(--secondary-color)',
                color: 'white',
              }}
              size="large"
              disabled={props.loading}
              onClick={() => setIsViewAgents(true)}
            >
              View Producers
            </Button>
            <div style={{ display: 'flex', gap: 12 }}>
              <Button
                id={
                  IdConstants.STATE_GROUPS.SAVE_STATE_GROUP_MODAL.CANCEL +
                  '-state-groups'
                }
                size="large"
                style={{
                  minWidth: 100,
                }}
                onClick={() => {
                  props.handleVisibility();
                }}
                disabled={props.loading}
              >
                Cancel
              </Button>
              <Button
                id={
                  IdConstants.STATE_GROUPS.SAVE_STATE_GROUP_MODAL.SAVE +
                  '-state-groups'
                }
                htmlType="submit"
                size="large"
                className="button"
                style={{
                  minWidth: 100,
                }}
                onClick={() => {
                  props.onSave();
                }}
                loading={props.loading}
              >
                {/* {props.manageAssignments
              ? "Save and Manage Producer's Assignments"
              : 'Save Territory'} */}
                Save
              </Button>
            </div>
          </div>
        }
      >
        <Typography.Paragraph
          style={{
            color: 'var(--secondary-color)',
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          Are you sure you want to make changes to this territory?{' '}
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{
            color: 'var(--grey-text)',
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          Existing territory will be edited and changes will be made to this
          territory{' '}
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{
            color: 'red',
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          <b> Note:</b> All producers in the territory will have their licenses
          automatically applied for these new assignments if their payment
          configurations, employment history, and background questions are setup{' '}
        </Typography.Paragraph>
      </Modal>
      {isViewAgents && (
        <AgentsByStateGroupModal
          stateGroupId={props.stateGroupId}
          isViewAgents={isViewAgents}
          isEditMode={true}
          close={() => setIsViewAgents(false)}
        />
      )}
    </>
  );
}

export default SaveStateGroupConfirmationModal;
