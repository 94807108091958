import { Button, Modal, message } from 'antd';
import { ModalContainer, TextDetail } from './revoke-invite.style';
import React, { useState } from 'react';

import { DownlineInviteService } from '../../../../services/downline-invite.service';
import { useAuth } from '../../../../auth/authProvider';

interface LicenseModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  invitationDetails: any;
  refresh?: () => void;
}

const RevokeInviteModal: React.FC<LicenseModalProps> = ({
  visible,
  setVisible,
  invitationDetails,
  refresh,
}) => {
  const { bearerToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const RevokeInvite = async () => {
    try {
      if (bearerToken) {
        setIsLoading(true);
        const response: any = await DownlineInviteService.revokeDownlineInvite(
          bearerToken,
          invitationDetails?.id
        );
        if (response.status === 'SUCCESS') {
          message.success('Invite Revoked');
          if (refresh) refresh();
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      width={'500px'}
      open={visible}
      onCancel={() => {
        setVisible(false);
      }}
      okText="Ok"
      footer={null}
    >
      <ModalContainer>
        <TextDetail textColor={'#0588ca'} fontSize={'16px'} fontWeight={500}>
          Revoke Downline Invite
        </TextDetail>
        <div style={{ gap: '12px', display: 'flex', flexDirection: 'column' }}>
          <TextDetail fontSize={'14px'} fontWeight={500}>
            Downline invitation sent to {invitationDetails?.email} will be
            revoked
          </TextDetail>
        </div>

        <div style={{ width: '100%' }}>
          <Button
            onClick={RevokeInvite}
            loading={isLoading}
            style={{ background: '#00a6fb', marginBottom: '12px' }}
            type="primary"
            block
          >
            Revoke Invite
          </Button>
          <Button
            disabled={isLoading}
            block
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default RevokeInviteModal;
