import { InviteType, SignUpSteps } from '../enums/signupPage.enum';

import { DownlineInvite } from '../types/data/downlineInvite.type';
import { InviteResponseEnum } from '../enums/invite-response.enum';
import { NiprTransactionType } from '../enums/niprTransactionType';
import { RoleType } from '../enums/roles.enum';
import { UserType } from '../enums/userType.enum';
import { makeAutoObservable } from 'mobx';

export interface AdminAccountInfo {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface OnboardingData {
  userType?: 'agent' | 'admin';
  adminInvite?: boolean;
  producer?: {
    npn?: string;
  };
  agency?: {
    type?: 'agency' | 'producer'; // this may be populated using invite details
    npn?: string;
    // agencyName?: string;
    // agencyNpn?: string; // this may be populated using invite details
    // producerNpn?: string;
  };
}
export interface AdminAccountBasicInfo {
  accountType: 'producer' | 'agency';
  agencyName?: string;
  agencyNPN: string;
  producerNPN?: string;
  onboardSelf?: boolean;
}

export interface AdminProfileName {
  firstName: string;
  lastName: string;
}
export interface AdminProfileNpn {
  npn: string;
}
export interface SetUpAgency {
  fein: string;
  residentState: string;
}

export interface AgencyDetails {
  name: string;
  npn: string;
  phone: string;
  fax: string;
  businessEmail: string;
  businessAddress?: any;
  mailingAddress?: any;
  residenceAddress?: any;
}

class OnboardingStore {
  accountInfo: AdminAccountInfo = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };
  basicInfo: AdminAccountBasicInfo = {
    accountType: 'agency',
    agencyName: '',
    agencyNPN: '',
    onboardSelf: false,
    producerNPN: '',
  };

  onboardingData: OnboardingData = {
    userType: 'admin',
  };

  setAccountInfo(
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) {
    this.accountInfo.firstName = firstName;
    this.accountInfo.lastName = lastName;
    this.accountInfo.email = email;
    this.accountInfo.password = password;
  }

  setBasicInfo(
    accountType: 'agency' | 'producer',
    agencyNPN: string,
    producerNPN?: string,
    agencyName?: string,
    onboardSelf?: boolean
  ) {
    this.basicInfo.accountType = accountType;
    this.basicInfo.agencyNPN = agencyNPN;
    this.basicInfo.agencyName = agencyName ?? '';
    this.basicInfo.onboardSelf = !!onboardSelf;
    this.basicInfo.producerNPN = producerNPN ?? '';
  }

  setOnboardingData(data: OnboardingData) {
    this.onboardingData = data;
  }
}

export const onboardingStore = new OnboardingStore();

class SignUpStore {
  userType: UserType | null = null;
  isInvited: boolean | null = null;
  inviteType: InviteType | null = null;
  homeState: undefined | string = undefined;
  adminInviteDetails: {
    email: string;
    roleName: RoleType;
    agencyId: string;
    inviteToken: string;
    agencyName: string;
  } | null = null;
  adminId: string | null = null;
  progress: number = 25;
  step: SignUpSteps | null = null;
  adminProfileName: AdminProfileName = {
    firstName: '',
    lastName: '',
  };
  adminProfileNpn: AdminProfileNpn = {
    npn: '',
  };
  firmType: 'firm' | 'general-agent-firm' | null = null;
  agencyFein: string | null = null;
  downlineInviteDetails: DownlineInvite | null = null;
  agencyDetails: AgencyDetails | undefined;
  adminInviteResponse: InviteResponseEnum | undefined;
  downlineInviteResponse: InviteResponseEnum | undefined;
  isAgentAuthorized: boolean | undefined;
  isAdminAuthorized: boolean | undefined;
  entityInfoRecordId: string | undefined;
  isAppLoading = true;

  setIsAppLoading = (value: boolean) => {
    this.isAppLoading = value;
  };

  setFirmType(value: 'firm' | 'general-agent-firm') {
    this.firmType = value;
  }

  setEntityInfoRecordId(value: string) {
    this.entityInfoRecordId = value;
  }

  setIsAgentAuthorized(value: boolean) {
    this.isAgentAuthorized = value;
  }

  setIsAdminAuthorized(value: boolean) {
    this.isAdminAuthorized = value;
  }

  setAdminInviteResponse(value: InviteResponseEnum) {
    this.adminInviteResponse = value;
  }

  setDownlineInviteResponse(value: InviteResponseEnum) {
    this.downlineInviteResponse = value;
  }

  setFein = (value: string) => {
    this.agencyFein = value;
  };

  setUserType = (value: UserType) => {
    this.userType = value;
  };

  setIsInvited = (isInvited: boolean) => {
    this.isInvited = isInvited;
  };
  setInviteType = (inviteType: InviteType | null) => {
    this.inviteType = inviteType;
  };
  setStep = (step: SignUpSteps) => {
    this.step = step;
  };
  setAdminInviteDetails = (
    email: string,
    roleName: RoleType,
    agencyId: string,
    inviteToken: string,
    agencyName: string
  ) => {
    this.adminInviteDetails = {
      email,
      roleName,
      agencyId,
      inviteToken,
      agencyName,
    };
  };

  setHomeState = (value: string) => {
    this.homeState = value;
  };

  setAdminId = (value: string) => {
    this.adminId = value;
  };

  getDownlineInviteDetails = () => this.downlineInviteDetails;
  setDownlineInviteDetails = (
    inviterType: string,
    inviterId: string,
    inviterName: string,
    inviteeEmail: string,
    inviteToken: string,
    creditAmount: number,
    npn: string,
    homeState: string,
    inviteeEntityType: NiprTransactionType,
    agencyName: string
  ) => {
    const inviteDetails = {
      inviteToken,
      inviterId,
      inviterName,
      inviteeEmail,
      inviterType,
      creditAmount,
      npn,
      homeState,
      inviteeEntityType,
      agencyName,
    };
    this.downlineInviteDetails = inviteDetails;
    // this.downlineInviteDetails?.inviterId = inviterId;
  };

  getProfileName = () => {
    return (
      this.adminProfileName.firstName + ' ' + this.adminProfileName.lastName
    );
  };

  getAdminProfileName = () => this.adminProfileName;
  setAdminProfileName = (firstName: string, lastName: string) => {
    this.adminProfileName.firstName = firstName;
    this.adminProfileName.lastName = lastName;
  };

  getAdminProfileNpn = () => this.adminProfileNpn;
  setAdminProfileNpn = (value: any) => {
    this.adminProfileNpn.npn = value['npn'];
  };

  getAgencyDetails = () => this.agencyDetails;
  setAgencyDetails(newAgencyDetails: any) {
    this.agencyDetails = newAgencyDetails;
  }

  feinCheck(fein: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (fein.length > 5) {
        resolve('yes');
      } else {
        resolve('no');
      }
    });
  }
  setSignUpStep(step: string | null) {
    if (!step) return;
    if (step === SignUpSteps.WELCOME_SCREEN) this.step = step;
  }

  validateNoInvites = () => {
    if (signupStore.step === SignUpSteps.INVITATION) {
      signupStore.step = SignUpSteps.ADMIN_PROFILE_1;
      return true;
    }
    return false;
  };
  constructor() {
    makeAutoObservable(this);
  }
}

export const signupStore = new SignUpStore();
