import './customMenu.css';

import React, { Key, useState } from 'react';

import { IdConstants } from '../../../constants/id.constants';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../../stores/tour.store';

type MenuItem = Required<MenuProps>['items'][number];

type MenuItemsProps = {
  items: MenuItem[];
  style: React.CSSProperties;
  defaultOpenKey: string[];
  defaultSelectedKey: string[];
  menuKeys: Key[];
};

function CustomMenu({
  items,
  style,
  defaultOpenKey,
  defaultSelectedKey,
  menuKeys,
}: MenuItemsProps) {
  const [openKeys, setOpenKeys] = useState(defaultOpenKey);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && menuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      if (!tourStore.sideBarOpen) {
        // If we need to keep only one option open at all times
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      } else {
        // If we need to keep more than one option open at all times
        setOpenKeys(keys);
      }
    }
  };

  return (
    <Menu
      mode="inline"
      id={IdConstants.SIDER_MENU_CONTAINER}
      defaultOpenKeys={defaultOpenKey}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      defaultSelectedKeys={defaultSelectedKey}
      selectedKeys={[appStateInfoStore.selectedMenuKey]}
      style={style}
      items={items}
      className="siderMenu"
    />
  );
}

export default observer(CustomMenu);
