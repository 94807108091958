import { MultipleOnboardLookup } from '../../../enums/onboardSteps.enum';
import { difference } from 'lodash';

export const validateNameSsn = (columnName: any) => {
  const hasLastName = 'lastName' in columnName;
  const hasSSN = 'ssn' in columnName;
  const hasGender = 'gender' in columnName;
  const hasNPN = 'npn' in columnName;
  const hasPhone = 'phone' in columnName;
  const hasEmail = 'email' in columnName;

  return hasLastName && hasSSN && hasGender && hasNPN && hasPhone && hasEmail;
};

export const validateNameNpn = (columnName: any) => {
  const hasAgentName = 'Name' in columnName;
  const hasNPN = 'NPN' in columnName;
  return (
    hasAgentName &&
    hasNPN &&
    'Contact Email' in columnName &&
    'SSN' in columnName &&
    'Gender' in columnName &&
    'Phone' in columnName
  );
};

export const validateNameLicenseState = (columnName: any) => {
  const hasName = 'Name' in columnName;
  const hasLicenseNumber = 'License Number' in columnName;
  const hasState = 'State' in columnName;
  return (
    hasName && hasLicenseNumber && hasState && 'Contact Email' in columnName
  );
};

export const validateMultipleOnboardLookup = (
  multipleOnboardLookup: string,
  columnName: any
) => {
  // switch (multipleOnboardLookup) {
  // case MultipleOnboardLookup.SSN:
  //   if (!validateNameSsn(columnName)) {
  //     const ssnColumns = [
  //       'Last Name',
  //       'SSN',
  //       'Contact Email',
  //       'Gender',
  //       'Phone',
  //     ];

  //     const uploadedHeader: string[] = [];
  //     Object.keys(columnName)
  //       .slice(1)
  //       .map((column) => uploadedHeader.push(column.trim()));

  //     throw new Error(
  //       `'${difference(ssnColumns, uploadedHeader).join("', '")}'`
  //     );
  //   }
  //   break;

  // case MultipleOnboardLookup.NPN:
  if (!validateNameSsn(columnName)) {
    const npmColumns = ['lastName', 'npn', 'email', 'ssn', 'gender', 'phone'];
    const uploadedHeader: string[] = [];
    Object.keys(columnName)
      .slice(1)
      .map((column) => uploadedHeader.push(column.trim()));

    throw new Error(`'${difference(npmColumns, uploadedHeader).join("', '")}'`);
  }
  // break;

  // case MultipleOnboardLookup.LICENSE:
  //   if (!validateNameLicenseState(columnName)) {
  //     const nameLicenseStateColumns = [
  //       'Name',
  //       'NPN',
  //       'License Number',
  //       'State',
  //       'Contact Email',
  //     ];
  //     const uploadedHeader: string[] = [];
  //     Object.keys(columnName)
  //       .slice(1)
  //       .map((column) => uploadedHeader.push(column.trim()));

  //     throw new Error(
  //       `'${difference(nameLicenseStateColumns, uploadedHeader).join(
  //         "', '"
  //       )}'`
  //     );
  //   }
  //   break;

  // default:
  //   break;
  // }

  return true;
};

export default validateMultipleOnboardLookup;
