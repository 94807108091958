import '@fontsource/poppins';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root')!;
const root = createRoot(container);
const router = createBrowserRouter([{ path: '*', element: <App /> }]);

root.render(<RouterProvider router={router} />);

serviceWorkerRegistration.register({
  // Custom options for service worker registration
  onUpdate: (registration: any) => {
    // Optionally, you can add logic here to notify the user about updates
    // and prompt them to reload the app.
    console.log('A new version is available. Please refresh the page.');
  },
  onSuccess: (registration: any) => {
    // Optional success callback
    console.log('Service Worker registration successful:', registration);
  },
  // Disable the "Install" prompt
  // This prevents the browser from automatically prompting the user to install the app
  // You can omit or set it to false if you want to enable the prompt
  skipWaiting: true,
  // Other options...
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
