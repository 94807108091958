import { Card, Typography } from 'antd';

import { CardWithLeftLineProps } from '../../../types/props/cardWithLeftLine.props';

const { Text } = Typography;

function CardWithLeftLine({
  lineColor,
  textType,
  date,
  time,
  text,
  description,
  errors,
}: CardWithLeftLineProps) {
  return (
    <Card
      style={{
        width: '100%',
        position: 'relative',
      }}
      bodyStyle={{
        padding: '10px 20px',
        backgroundColor: '#FAFFFF',
        borderColor: '#E6F7EF',
      }}
      color="#DFF4F5"
    >
      <div
        style={{
          position: 'absolute',
          backgroundColor: lineColor,
          left: '3px',
          top: '50%',
          transform: 'translate(0, -50%)',
          height: '90%',
          width: '3px',
          borderRadius: '10px',
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div>
            <Text
              style={{
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '28px',
              }}
              type="secondary"
            >
              {date}
            </Text>
          </div>
          {text.map((line, index) => (
            <div key={index}>
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '28px',
                }}
                {...(textType && {
                  type:
                    textType === 'Success'
                      ? 'success'
                      : textType === 'Danger'
                      ? 'danger'
                      : undefined,
                })}
              >
                {line}
              </Text>
            </div>
          ))}
          <div>
            {Array.isArray(description) ? (
              description.map((item, index) => (
                <div key={index}>
                  <Text
                    style={{
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '28px',
                    }}
                    type="secondary"
                  >
                    {item}
                  </Text>
                </div>
              ))
            ) : (
              <Text
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '28px',
                }}
                type="secondary"
              >
                {description}
              </Text>
            )}
          </div>
        </div>
        <div>
          <Text
            style={{ fontSize: '12px', fontWeight: '400', lineHeight: '28px' }}
            type="secondary"
          >
            {time}
          </Text>
        </div>
      </div>
    </Card>
  );
}

export default CardWithLeftLine;
