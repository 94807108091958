export const ClickableLinkTextForBackgroundQuestions = (
  text: string
): TrustedHTML | string => {
  const linkRegex = /((?:http?s?:\/\/|www\.)[^\s]+)/g;
  const parts = text?.split(linkRegex);

  if (parts?.length > 1) {
    const result = parts.map((data, index) => {
      if (index % 2 === 1) {
        const modifiedData = data.endsWith('.') || data.endsWith(']')
          ? data.slice(0, -1)
          : data;

        const protocol = modifiedData.startsWith('www') ? 'https://' : '';
        return `<a href="${protocol}${modifiedData}" target="_blank" rel="noopener noreferrer">${modifiedData}</a>${
          data.endsWith('.') || data.endsWith(']') ? data[data.length - 1] : ''
        }`;
      } else {
        return `<span>${data}</span>`;
      }
    });

    return result.join('');
  } else {
    return text || '';
  }
};
