import { Button, Card } from 'antd';

import { OnboardedWelcomeScreenProps } from './individualOnboardV2Type.type';
import React from 'react';
import { RouteConstants } from '../../../../constants/routes.constants';
import checkboxCircle from '../../../../assets/images/checkbox-circle-line.png';
import { formatDate } from '../../../../utils/date.utils';
import { getDisplayFees } from '../../../../utils/common.utils';
import insureTrekLogo from '../../../../assets/images/insureTrekLogo.png';
import { useNavigate } from 'react-router';

const OnboardedWelcomeScreen: React.FC<OnboardedWelcomeScreenProps> = ({
  firstName,
  lastName,
  AgentAmount,
  TerritoryCount,
  StateCount,
  producerId,
}) => {
  const navigate = useNavigate();
  return (
    <div className="individual-onboarding-agent-onboarded">
      <div
        className="font-poppins"
        style={{
          padding: 48,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 24,
        }}
      >
        <img src={insureTrekLogo} alt="Insuretrek" />
        <img src={checkboxCircle} alt="Checkbox Circle" />
        <div
          style={{
            fontSize: 20,
            lineHeight: '28px',
            fontWeight: 600,
            color: '#FAFAFA',
          }}
        >
          {' '}
          Producer Onboarded!
        </div>
        <div
          style={{
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 500,
            color: '#99ACB5',
          }}
        >
          {firstName} has been added to your team
        </div>
        <Button
          htmlType="submit"
          size="large"
          id={'individual-onboarding-producer-navigate'}
          className="button primary-button"
          style={{
            width: '359px',
            height: '40px',
          }}
          onClick={() =>
            navigate(
              RouteConstants.agentOverview.path.replace(':agentId', producerId)
            )
          }
        >
          View Producer Profile
        </Button>
        <Card className="individual-onboarding-welcome-card">
          <section style={{ width: '798px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '12px 22px',
                backgroundColor: '#EDF9FF',
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
              }}
            >
              <div>
                {' '}
                <span
                  className="font-poppins"
                  style={{
                    fontSize: 20,
                    lineHeight: '28px',
                    fontWeight: 600,
                    color: 'rgba(7, 33, 45, 1)',
                  }}
                >
                  Estimate Cost
                </span>{' '}
                <br />
                <span
                  className="font-poppins"
                  style={{
                    fontSize: 12,
                    lineHeight: '28px',
                    fontWeight: 400,
                    color: 'rgba(7, 33, 45, 1)',
                  }}
                >
                  For {firstName} {lastName}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                {' '}
                <span
                  style={{
                    fontSize: 20,
                    lineHeight: '28px',
                    fontWeight: 600,
                    color: 'rgba(7, 33, 45, 1)',
                    textAlign: 'right',
                    marginLeft: 'auto',
                  }}
                >
                  ${getDisplayFees(AgentAmount)}
                </span>
                <span
                  style={{
                    fontSize: 12,
                    lineHeight: '28px',
                    fontWeight: 400,
                    color: 'rgba(7, 33, 45, 1)',
                  }}
                >
                  For Licenses & LOA’s
                </span>
              </div>
            </div>
            <div
              style={{
                height: 178,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                className="font-poppins"
                style={{
                  fontSize: 16,
                  lineHeight: '28px',
                  fontWeight: 600,
                  padding: '0 22px',
                  color: 'rgba(7, 33, 45, 1)',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {TerritoryCount ? (
                    <>
                      <span>
                        {TerritoryCount}{' '}
                        {TerritoryCount > 1 ? 'Territories' : 'Territory'}{' '}
                        {StateCount
                          ? '& a total of'
                          : `${TerritoryCount === 1 ? 'is' : 'are'} added`}
                      </span>{' '}
                      <br />
                    </>
                  ) : null}
                  {StateCount ? (
                    <span>
                      {StateCount} State{StateCount > 1 ? 's' : ''}{' '}
                      {StateCount === 1 && !TerritoryCount ? 'is' : 'are'} added
                    </span>
                  ) : null}
                </div>
                <div>
                  <span>Onboarded date</span> <br />
                  <span>{formatDate(new Date().toDateString())}</span>
                </div>
              </div>
              <ul style={{ marginLeft: 12 }}>
                {[
                  "You won't be charged for new or renewed licenses until they are fully approved",
                  "You'll start getting reminders for when licenses are ready to renew",
                ].map((data) => {
                  return (
                    <li
                      key={data}
                      style={{
                        fontSize: 14,
                        lineHeight: '28px',
                        fontWeight: 400,
                        color: '#97AABF',
                      }}
                    >
                      {' '}
                      {data}{' '}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              style={{
                height: '36px',
                padding: '4px 22px',
                background: '#EDF9FF',
              }}
            ></div>
          </section>
        </Card>
      </div>
    </div>
  );
};

export default OnboardedWelcomeScreen;
