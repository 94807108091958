import CustomButton from '../../components/common/button/CustomButton';
import React from 'react';
import { RoleType } from '../../enums/roles.enum';
import { withRoles } from '../../auth/useRoles';

type AgentPortalLicenseProp = {
  setStates?: (option: string) => void;
};

 function AgentSettings({
  setStates,
}: AgentPortalLicenseProp) {
  return (
    <CustomButton
      styles={{ marginTop: '15px' }}
      classNames="button"
      type="tabSwitch"
      buttonName="Start Background Questions"
      tabKey='3'
      setButtonState={(option: string) => {
        if (setStates) {
          setStates(option);
        }
      }}
    />
  );
}

export default withRoles(AgentSettings, [RoleType.AGENT])