export enum LicenseApplicationStatus {
  NOT_APPLIED = 'not_applied',
  APPLIED = 'applied',
  DECLINED = 'declined',
  SUCCESSFUL = 'successful',
}

export const LicenseApplicationStatusLabels: Record<
  LicenseApplicationStatus,
  string
> = {
  [LicenseApplicationStatus.NOT_APPLIED]: 'Not Applied',
  [LicenseApplicationStatus.APPLIED]: 'Applied',
  [LicenseApplicationStatus.DECLINED]: 'Declined',
  [LicenseApplicationStatus.SUCCESSFUL]: 'Successful',
};
