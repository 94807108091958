import AdminSignUpLayout from './signUp/layouts/signUpLayout';
import Loading from './loading';
import TextWithIcon from './common/textWithIcon/textWithIcon';
import { Typography } from 'antd';
import { UserType } from '../enums/userType.enum';
import bottomScreen from '../assets/images/bottomScreen.svg';
import location from '../assets/icons/location.svg';
import mail from '../assets/icons/mail.svg';
import phone from '../assets/icons/phone.svg';
import { signupStore } from '../stores/signupStore';

function Unauthorized() {
  return (
    <AdminSignUpLayout>
      {signupStore.isAppLoading ? (
        <Loading logo fullHeight />
      ) : (
        signupStore.userType && (
          <div
            style={{
              display: 'flex',
              position: 'relative',
              justifyItems: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              margin: '0 10%',
            }}
          >
            <img
              src={bottomScreen}
              alt=""
              style={{
                position: 'absolute',
                bottom: '-50%',
                left: '-5%',
                zIndex: '-1',
                width: '45%',
              }}
            />
            <div
              style={{
                marginTop: '10%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '100%',
              }}
            >
              <div style={{ flexBasis: '40%' }}>
                <Typography.Paragraph
                  style={{
                    fontWeight: '500',
                    fontSize: '48px',
                    color: 'var(--primary-color)',
                  }}
                >
                  {signupStore.userType === UserType.ADMIN && (
                    <span>
                      Access <br /> Revoked <br />
                    </span>
                  )}
                  {signupStore.userType === UserType.AGENT && (
                    <span>
                      You were <br /> Offboarded <br />
                    </span>
                  )}
                </Typography.Paragraph>
              </div>

              <div style={{ flexBasis: '60%' }}>
                <Typography.Paragraph
                  style={{
                    fontWeight: '400',
                    fontSize: '28px',
                  }}
                >
                  If you believe this is a mistake, please contact your agency
                  to request re-authorization.
                  <br />
                  Feel free to reach out to us for further assistance.
                </Typography.Paragraph>
                <div style={{ marginTop: '13%' }}>
                  <a href="mailto:sales@insurtek.com">
                    <TextWithIcon
                      textStyle={{
                        fontWeight: '400',
                        fontSize: '24px',
                        marginTop: '-1%',
                      }}
                      text="sales@insurtek.com"
                      icon={<img src={mail} alt="mail" />}
                    />
                  </a>
                </div>
                <div style={{ marginTop: '5%' }}>
                  <TextWithIcon
                    textStyle={{
                      fontWeight: '400',
                      fontSize: '24px',
                    }}
                    text="+212 xxxxxxxx"
                    icon={<img src={phone} alt="phone" />}
                  />
                </div>
                <div style={{ marginTop: '5%' }}>
                  <TextWithIcon
                    textStyle={{
                      fontWeight: '400',
                      fontSize: '24px',
                    }}
                    text="8745 Henderson Rd STE 220, Tampa, FL 33634, United States"
                    icon={<img src={location} alt="location" />}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </AdminSignUpLayout>
  );
}

export default Unauthorized;
