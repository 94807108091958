import { includes, some } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { RoleType } from '../enums/roles.enum';
import { RouteConstants } from '../constants/routes.constants';
import UnauthorizedLayout from '../components/unauthorizedLayout';
import { useAuth } from './authProvider';

export const withRoles = <P extends object>(
  Component: React.ComponentType<P>,
  requiredRoles: string[],
  showErrorIfDenied: boolean = false
): React.FC<P> => {
  const RoleHandler: React.FC<P> = (props) => {
    const { roles, isAuthenticated } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();
    const hasAccess = useMemo(
      () =>
        requiredRoles.length === 0 ||
        requiredRoles.some((role) => roles.includes(role)),
      [roles]
    );

    useEffect(() => {
      const path = location.pathname;
      if (path === '/') {
        if (
          some([RoleType.ADMIN, RoleType.SUPER_ADMIN], (r) =>
            includes(roles, r)
          )
        )
          navigate(RouteConstants.allAgents.path);
        else if (roles.includes(RoleType.AGENT))
          navigate(RouteConstants.agentDashBoard.path);
      }
    });

    if (hasAccess && isAuthenticated()) {
      return <Component {...props} />;
    } else {
      return showErrorIfDenied ? (
        <UnauthorizedLayout
          title="Access <br /> Forbidden"
          text="You are not authorized to access this page. Please reach out to our team for further assistance."
        />
      ) : null;
    }
  };

  return RoleHandler;
};
