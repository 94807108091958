import {
  AgentsRtsTableType,
  AgentsType,
  AllKeys,
  ExpandedKeys,
} from './agencyRtsTypes';
import {
  Button,
  Card,
  Col,
  FloatButton,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';

import AgencyRtsByCarrierTable from './agencyRtsByCarrier';
import AgencyRtsByStateTable from './agencyRtsByState';
import { AgentService } from '../../../services/agent.service';
import { Appointment } from '../../../types/data/rts.type';
import { DownloadOutlined } from '@ant-design/icons';
import { IdConstants } from '../../../constants/id.constants';
import RtsByAgencyTour from '../../../components/common/Tour/rtsByAgencyTour';
import { StateConstants } from '../../../constants/state.constants';
import type { TableColumnsType } from 'antd';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { exportTableToCSV } from '../../../utils/table-manager/export-table-to-csv';
import { getLcDetailsForLoaAndState } from '../../../utils/getLcDetailsForLoa&State.utils';
import { getNameString } from '../../../utils/name.utils';
import { observer } from 'mobx-react-lite';
import { sortBy } from 'lodash';
import { tourStore } from '../../../stores/tour.store';
import { useAuth } from '../../../auth/authProvider';

const { Search } = Input;

export const TagStyle = {
  borderRadius: '10px',
  marginTop: '5px',
  marginRight: '5px',
  border: `1px solid  var(--border-color)`,
};

// Producers Table
export const expandAgents = (e: AgentsType, updatedFields: Appointment[]) => {
  const columns: TableColumnsType<Appointment> = [
    {
      dataIndex: 'loa',
      title: 'LOA',
      key: 'loa',
      width: '25%',
      // sorter: (a, b) =>
      //   (a?.appointmentLicenseConfiguration?.licenseConfiguration?.loa).localeCompare(
      //     b?.appointmentLicenseConfiguration?.licenseConfiguration?.loa
      //   ),
      render: (text, record) => {
        const appointmentLicenseConfiguration =
          record?.appointmentLicenseConfiguration;

        const loa = appointmentLicenseConfiguration?.licenseConfiguration
          ? appointmentLicenseConfiguration?.licenseConfiguration?.loa
          : appointmentLicenseConfiguration?.loa;

        const loaCode = appointmentLicenseConfiguration?.licenseConfiguration
          ? appointmentLicenseConfiguration?.licenseConfiguration?.loaCode
          : appointmentLicenseConfiguration?.loaCode;

        if (!loa && !loaCode) {
          return <span className="pl-24">-</span>;
        }
        return appointmentLicenseConfiguration?.licenseConfiguration
          ?.licenseClass ? (
          <Tooltip title="Active LOA">
            <Tag color="var(--tag-success-color)" style={TagStyle}>
              <span style={{ color: 'var(--black-color)' }}>
                {loa} ({loaCode})
              </span>
            </Tag>
          </Tooltip>
        ) : (
          <Tooltip title="LC missing">
            <Tag color={'var(--tag-failure-color'} style={TagStyle}>
              <span style={{ color: 'var(--black-color)' }}>
                {loa} ({loaCode})
              </span>
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'status',
      title: 'Status',
      width: '15%',
      key: 'Status',
      // sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => {
        if (!text) return <span className="pl-24">-</span>;
        return record.status === 'Appointed' ? (
          <Tag color="var(--tag-success-color)" style={TagStyle}>
            <span style={{ color: 'var(--black-color)' }}>{record.status}</span>
          </Tag>
        ) : (
          <Tag color={'var(--tag-failure-color'} style={TagStyle}>
            {record.status}
          </Tag>
        );
      },
    },
    {
      dataIndex: 'statusReasonDate',
      title: 'Status Date',
      width: '15%',
      key: 'statusReasonDate',
      // sorter: (a, b) =>
      //   new Date(a.statusReasonDate).getTime() -
      //   new Date(b.statusReasonDate).getTime(),
      render: (text, record) => {
        if (!text) return <span className="pl-24">-</span>;
        const dateObject = new Date(text);
        return dateObject.toLocaleDateString('en-US');
      },
    },
    {
      dataIndex: 'appointmentRenewalDate',
      title: 'Appointment Renewal Date',
      width: '15%',
      key: 'appointRenewalDate',
      // sorter: (a, b) =>
      //   new Date(a.appointmentRenewalDate).getTime() -
      //   new Date(b.appointmentRenewalDate).getTime(),
      render: (text, record) => {
        if (!text || record.status === 'Terminated')
          return <span className="pl-24">-</span>;
        const dateObject = new Date(text);
        return dateObject.toLocaleDateString('en-US');
      },
    },
  ];

  const agentFields = updatedFields.filter(
    (data) => getNameString(data.producer.name) === e.Agents
  );

  return (
    <Table
      style={{ margin: '6px 0 12px' }}
      columns={columns}
      dataSource={agentFields}
      pagination={false}
    />
  );
};

const AgencyRts = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isStateMode, setIsStateMode] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [state, setState] = useState(null);
  const [loa, setLoa] = useState([]);
  const [carrier, setCarrier] = useState('');
  const [tableData, setTableData] = useState<AgentsRtsTableType[]>([]);
  const { getAccessTokenSilently } = useAuth();
  const [expandedStateKeys, setExpandedStateKeys] = useState<ExpandedKeys>({
    agent: [],
    carrier: [],
    state: [],
  });
  const [expandedCarrierKeys, setExpandedCarrierKeys] = useState<ExpandedKeys>({
    agent: [],
    carrier: [],
    state: [],
  });
  const [allExpandedKeys, setAllExpandedKeys] = useState<AllKeys>({
    state: {
      agent: [],
      carrier: [],
      state: [],
    },
    carrier: {
      agent: [],
      carrier: [],
      state: [],
    },
  });
  const sortedLoas = useMemo(
    () =>
      sortBy(appStateInfoStore.getLoasForRts(), (obj) => obj.loa.toLowerCase()),
    [appStateInfoStore.getLoasForRts()]
  );

  const fetchRtsRecords = async (
    state?: string | null,
    loa?: string[],
    carrier?: string
  ) => {
    try {
      setExpandedCarrierKeys({ agent: [], carrier: [], state: [] });
      setExpandedStateKeys({ agent: [], carrier: [], state: [] });
      setIsFetching(true);
      const token = await getAccessTokenSilently();
      const response = await AgentService.fetchRtsRecordsInAgency(
        token,
        isStateMode ? 'stateCode' : 'companyName',
        state,
        carrier,
        loa
      );
      setTableData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchRtsRecords(state, loa, carrier);
  }, [state, loa, carrier, isStateMode]);

  const moveToTop = () => {
    containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getFormattedData = (data?: any[]) => {
    if (!data) return [];
    const flattenedAppoinments = data.flatMap(
      (d) => d?.appointments
    ) as Appointment[];
    return sortBy(flattenedAppoinments, (obj) =>
      obj?.producer?.name?.firstName?.toLowerCase()
    );
  };

  return (
    <div className="width-100" ref={containerRef}>
      <Card>
        <div className="agency-rts-controls">
          <Select
            id="agency-rts-states"
            style={{ width: '100%' }}
            placeholder="Select your State"
            value={state}
            showSearch
            disabled={isFetching}
            onChange={(value) => setState(value)}
            filterOption={(input, option) => {
              const inputValue = input.toLowerCase().trim();
              const optionValue = (
                option?.children as unknown as string
              ).toLowerCase();
              const isMatch = optionValue.includes(inputValue);
              return isMatch;
            }}
            allowClear
            onClear={() => setState(null)}
          >
            {Object.keys(StateConstants).map((stateCode) => (
              <Select.Option key={stateCode} value={stateCode}>
                {StateConstants[stateCode]}
              </Select.Option>
            ))}
          </Select>
          {/* <Search
            id="agency-rts-carriers"
            className="agents-rts-carriers"
            placeholder="Enter Carrier Name"
            // allowClear
            onSearch={(value) => {
              setCarrier(value);
            }}
            disabled={isFetching}
          /> */}
          <Select
            id="agency-rts-license-class"
            style={{ width: '100%' }}
            placeholder="Select LOA"
            mode="multiple"
            showSearch
            value={loa}
            disabled={isFetching}
            onChange={(value) => setLoa(value)}
            filterOption={(input, option) => {
              const inputValue = input.toLowerCase().trim();
              const optionValue = option?.children as unknown as string[];
              const isMatch = optionValue
                .join('')
                .toLowerCase()
                .includes(inputValue);
              return isMatch;
            }}
            allowClear
            onClear={() => setLoa([])}
          >
            {sortedLoas.map((loa) => (
              <Select.Option
                key={`${loa.loa}${loa.loaCode}`}
                value={loa.loaCode}
              >
                {loa.loa} ({loa.loaCode})
              </Select.Option>
            ))}
          </Select>
        </div>
        <Row>
          <Col span={24}>
            <Row
              className="flex align-items-center justify-between"
              style={{ padding: '0 0 12px' }}
            >
              <Row style={{ gap: 8 }}>
                <Tooltip title="Download as CSV">
                  <Button
                    id={IdConstants.RTS.BY_AGENCY.CSV_DOWNLOAD}
                    disabled={isFetching || !tableData?.length}
                    onClick={() =>
                      exportTableToCSV(
                        'agencyRTS',
                        getFormattedData(tableData),
                        'Agency RTS Report'
                      )
                    }
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
                <Button
                  id="filters-button"
                  disabled={isFetching || !tableData?.length}
                  onClick={() => {
                    setIsExpanded(true);
                    if (allExpandedKeys.state?.state?.length) {
                      setExpandedStateKeys(allExpandedKeys.state);
                    }
                    if (allExpandedKeys?.carrier?.carrier?.length) {
                      setExpandedCarrierKeys(allExpandedKeys.carrier);
                    }
                  }}
                >
                  Expand All
                </Button>
                <Button
                  id="filters-button"
                  disabled={isFetching || !tableData?.length}
                  onClick={() => {
                    setExpandedStateKeys({
                      agent: [],
                      carrier: [],
                      state: [],
                    });
                    setExpandedCarrierKeys({
                      agent: [],
                      carrier: [],
                      state: [],
                    });
                    setIsExpanded(false);
                  }}
                >
                  Collapse All
                </Button>
              </Row>
              <div style={{ display: 'flex', gap: 16 }}>
                <Radio.Group
                  disabled={isFetching}
                  defaultValue="state"
                  buttonStyle="solid"
                  onChange={(data) => {
                    setIsStateMode(data.target.value === 'state');
                  }}
                >
                  <Radio.Button
                    className={IdConstants.RTS.BY_AGENCY.STATE_RADIO}
                    value="state"
                  >
                    State
                  </Radio.Button>
                  <Radio.Button
                    className={IdConstants.RTS.BY_AGENCY.CARRIER_RADIO}
                    value="carriers"
                  >
                    Carriers
                  </Radio.Button>
                </Radio.Group>
              </div>
            </Row>
            {isFetching ? (
              <Skeleton active />
            ) : (
              <>
                {isStateMode ? (
                  <AgencyRtsByStateTable
                    expandedKeys={expandedStateKeys}
                    setExpandedKeys={setExpandedStateKeys}
                    tableData={tableData}
                    isExpanded={isExpanded}
                    moveToTop={moveToTop}
                    setAllExpandedKeys={setAllExpandedKeys}
                  />
                ) : (
                  <AgencyRtsByCarrierTable
                    expandedKeys={expandedCarrierKeys}
                    setExpandedKeys={setExpandedCarrierKeys}
                    tableData={tableData}
                    isExpanded={isExpanded}
                    moveToTop={moveToTop}
                    setAllExpandedKeys={setAllExpandedKeys}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </Card>
      <FloatButton.BackTop
        target={() => document.getElementById('ant-layout-container') || window}
        tooltip="Scroll to top"
      />
      {tourStore.rtsByAgencyTour && <RtsByAgencyTour />}
    </div>
  );
};

export default observer(AgencyRts);
