import AdminMenu, { ADMIN_AGENT_MENU_ROUTES } from './siderMenu/siderMenu';
import { ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import AgentSiderMenu from './siderMenu/agentSiderMenu';
import { MenuType } from '../enums/menuType.enum';
import { RouteConstants } from '../constants/routes.constants';
import Sider from 'antd/es/layout/Sider';
import { adminStore } from '../stores/admin.store';
import { agentSideStore } from '../stores/agentPortalStore';
import { auth0Store } from '../stores/auth0Store';
import favIcon from '../assets/images/Logo Favicon.jpg';
import logo from '../assets/icons/insuretrek-logo.svg';
import { observer } from 'mobx-react-lite';

interface MenuProps {
  menuType: MenuType;
}

function Menu(props: MenuProps) {
  const [collapsed, setCollapsed] = useState(false);
  const [historyStack, setHistoryStack] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname?.toLowerCase()?.startsWith('/agent') &&
      ADMIN_AGENT_MENU_ROUTES.some((route: string) =>
        location.pathname?.toLowerCase()?.endsWith(route?.toLowerCase())
      )
    )
      return;
    setHistoryStack(location.pathname);
  }, [location.pathname]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{ height: '100vh', position: 'sticky', top: '0' }}
      width={260}
      collapsedWidth={100}
      theme="light"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div style={{ flex: 1 }}>
          <Card
            style={{
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '0px',
            }}
          >
            <img
              alt="logo"
              style={{
                width: '100%',
                height: '50px',
                marginTop: '-16px',
              }}
              src={collapsed ? favIcon : logo}
            />
          </Card>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              borderRadius: '0px',
              // height: newMenu === MenuType.MainMenu ? '120px' : 70,
            }}
          >
            {props.menuType === MenuType.MainMenu ? (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <Avatar
                      shape="square"
                      size={32}
                      src={auth0Store.user?.picture}
                    />
                  </div>
                  <div>
                    {!collapsed && (
                      <Typography.Paragraph
                        style={{
                          marginTop: '0px',
                          marginBottom: '0px',
                          fontWeight: 500,
                          marginLeft: '10px',
                        }}
                      >
                        <span style={{ fontSize: '14px' }}>
                          {adminStore.account
                            ? `${adminStore.account?.name.firstName} ${adminStore.account?.name.lastName}`
                            : `${agentSideStore.agentName}`}
                        </span>
                      </Typography.Paragraph>
                    )}
                  </div>
                </div>
                {/* TODO New Stripe Card Onboarding to be shown here */}
                {/* <StripeOnboardingButton
              collapsed={collapsed}
              style={{
                marginTop: '20px',
              }}
            /> */}
              </div>
            ) : (
              <div style={{ flex: 1 }}>
                <Button
                  id="back-to-agents-view-button"
                  {...(!collapsed ? { icon: <ArrowLeftOutlined /> } : {})}
                  style={{
                    color: 'var(--primary-color)',
                    border: 'none',
                    boxShadow: 'none',
                    ...(!collapsed && { padding: 0 }),
                  }}
                  onClick={() => {
                    // removed window.history as it saves the state if we navigate within the subsection as well.
                    if (historyStack) {
                      navigate(historyStack);
                    } else {
                      // the current entry in the history stack will be replaced with the new one with { replace: true }
                      navigate(RouteConstants.allAgents.path);
                    }
                  }}
                >
                  {!collapsed && <>Back</>}
                  {collapsed && <LeftOutlined />}
                </Button>
              </div>
            )}
          </Card>
          <AgentSiderMenu collapsed={collapsed} />
          <AdminMenu
            type={props.menuType === MenuType.MainMenu ? 'main' : 'subMenu'}
            collapsed={collapsed}
          />
        </div>
      </div>
    </Sider>
  );
}

export default observer(Menu);
