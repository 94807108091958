import { useEffect, useState } from 'react';

import GeneralProfile from '../agency/general';
import MyAgencyBackgroundQuestions from './backgroundQuestions/myAgencyBackgroundQuestions';
import OwnershipDetails from './ownershipDetails/OwnershipDetails';
import { RoleType } from '../../enums/roles.enum';
import TabView from '../../components/common/tabview/tabview';
import { useSearchParams } from 'react-router-dom';
import { withRoles } from '../../auth/useRoles';

function DetailsAgency() {
  const [selectedTab, setSelectedTab] = useState('1');
  const [searchParams, setSearchParams] = useSearchParams();

  const options = [
    {
      label: 'General',
      key: '1',
    },
    {
      label: 'Ownership Details',
      key: '2',
    },
    {
      label: 'Background Questions',
      key: '3',
    },
  ];

  const handleTabChange = (selected: string) => {
    setSelectedTab(selected);
    setSearchParams({ tab: selected }, { replace: true });
  };

  useEffect(() => {
    const params = searchParams.get('tab');
    if (params && ['1', '2', '3'].includes(params)) setSelectedTab(params);
  }, []);

  return (
    <div style={{ width: '100%' }} className="onboard-active">
      <TabView
        options={options}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
      <div style={{ marginTop: 12 }}>
        {selectedTab === '1' && <GeneralProfile />}
        {selectedTab === '2' && <OwnershipDetails />}
        {selectedTab === '3' && <MyAgencyBackgroundQuestions />}
      </div>
    </div>
  );
}
export default withRoles(DetailsAgency, [RoleType.SUPER_ADMIN, RoleType.ADMIN]);
