import { ApplicantType } from '../enums/applicantType.enum';
import { AssignedState } from '../types/data/stateGroup.types';
import { ResidencyType } from '../enums/residencyType.enum';

export const isStateAssigned = (
  stateCode: string,
  assignedStates: AssignedState[]
): boolean => {
  if (!assignedStates) return false;
  const foundState = assignedStates.find(
    (assignedState) => assignedState.stateCode === stateCode
  );

  if (foundState) return true;
  else return false;
};

export const isLcAssigned = (
  stateCode: string,
  lcCode: string,
  assignedStates: AssignedState[]
): boolean => {
  const stateInfo = assignedStates?.find(
    (state) => state.stateCode === stateCode
  );
  if (!stateInfo) return false;
  const foundLc = stateInfo.licenseConfigs?.find((lc) => lc.lcCode === lcCode);
  if (foundLc) return true;
  else return false;
};

export const isLoaAssigned = (
  stateCode: string,
  lcCode: string,
  loaCode: string,
  assignedStates: AssignedState[]
): boolean => {
  const stateInfo = assignedStates?.find(
    (state) => state.stateCode === stateCode
  );
  if (!stateInfo) return false;

  const foundLc = stateInfo.licenseConfigs?.find((lc) => lc.lcCode === lcCode);
  if (!foundLc) return false;

  const foundLoa = foundLc.loaCodes?.find((loa) => loa === loaCode);
  if (foundLoa) return true;
  else return false;
};

export type StateLicenseConfigMapping = {
  applicantType: ApplicantType;
  residencyType: ResidencyType;
  stateCode: string;
  oldLoaCode: string;
  oldLicenseClassCode: string;
  newLoaCode: string;
  newLoaName?: string;
  newLicenseClassCode: string;
  newLicenseClassName?: string;
};
