import EmptyState from '../common/emptyState/emptyState';
import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { SelectedStateProps } from './agencyLicensingInterfaces.type';

const SelectedState: React.FC<SelectedStateProps> = ({
  currentState,
  onStateSelect,
  filteredStates,
  searchQuery,
  setSearchQuery,
}) => {
  return (
    <div
      style={{
        width: '220px',
        // height: '100%',
        borderRadius: '6px',
        border: '1px solid #D6E4EB',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        gap: '24px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          height: '24px',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '24px',
          color: '#000000',
          textAlign: 'center',
          marginBottom: '16px',
        }}
      >
        {`${filteredStates.length} States Selected`}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F5F6F7',
            borderRadius: '6px',
            padding: '0 12px',
            height: '32px',
            marginRight: '10px',
          }}
        >
          <SearchOutlined style={{ marginRight: '8px' }} />
          <input
            type="text"
            placeholder="Search States"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: '100%', // Fill the remaining width
              border: 'none', // Remove default input border
              backgroundColor: 'transparent', // Transparent background to show parent div's background
              outline: 'none', // Remove outline on focus
              fontSize: '14px', // Example font size
              color: '#333', // Example text color
            }}
          />
        </div>
      </div>
      <div
        style={{
          maxHeight: '475px',
          overflowY: 'auto',
        }}
      >
        {filteredStates?.length === 0 ? (
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              height: '475px',
            }}
          >
            <EmptyState
              image="empty data"
              heading=""
              width="100%"
              content="No States Found"
            />
          </div>
        ) : null}
        {filteredStates?.map((state) => (
          <div
            key={state.code}
            onClick={() => onStateSelect(state)}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              marginBottom: '8px',
              padding: '8px',
              borderRadius: '6px',
              backgroundColor:
                currentState === state.name ? '#E2F0FD' : '#FFFFFF',
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '26px',
                border: '1px solid',
                padding: '2px 6px',
                backgroundColor:
                  currentState === state.name ? '#F1FEF4' : '#F1F8FE',
                borderColor:
                  currentState === state.name ? '#0F9D58' : '#437EE1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px',
              }}
            >
              <span
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 500,
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: currentState === state.name ? '#0F9D58' : '#437EE1',
                }}
              >
                {state.code}
              </span>
            </div>
            <span
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
                color: '#000000',
              }}
            >
              {state.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectedState;
