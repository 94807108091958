import { appStateInfoStore } from '../stores/appStateInfo.store';

export const getLcandLoaCodeFromStateCode = (stateCode: string) => {
  const stateData = appStateInfoStore
    .getLicenseConfigsMasterData()
    .find((data: any) => {
      return data.stateCode === stateCode;
    });
  if (stateData) {
    return stateData.licenseClasses.map((lc) => ({
      lcCode: lc.lcCode,
      loaCode: lc.loaData
        .map((loa) => {
          if (!loa.isRetired && loa.loaCode) {
            return loa.loaCode;
          }
          return '';
        })
        .filter(Boolean),
      loaids: lc.loaData
        .map((loa) => {
          if (!loa.isRetired && loa.id) {
            return loa.id;
          }
          return '';
        })
        .filter(Boolean),
    }));
  }
};
