import React from 'react';

const MinusOutlinedCustom = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.62544 0.675781L13.3354 4.38576V9.63246L9.62544 13.3425H4.37874L0.668762 9.63246V4.38576L4.37874 0.675781H9.62544ZM9.07318 2.00911H4.93102L2.0021 4.93804V9.0802L4.93102 12.0091H9.07318L12.0021 9.0802V4.93804L9.07318 2.00911ZM4.33494 6.34193H9.66824V7.67526H4.33494V6.34193Z"
        fill="#222222"
      />
    </svg>
  );
};

export default MinusOutlinedCustom;
