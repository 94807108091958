import React from 'react';
import loading from '../assets/images/loading.svg';
import loadingGif from '../assets/icons/loading.gif';

/**
 * Renders a loading component with a spinning icon to indicate that content is being loaded.
 * @param None
 * @returns A React component that displays a spinning icon in the center of the screen.
 */
function Loading({ fullHeight = true, logo = false, showAsOverlay = false }) {
  return (
    <div
      style={{
        height: fullHeight ? '100vh' : '20vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(showAsOverlay && {
          position: 'absolute',
          top: 0,
          zIndex: 999,
          margin: 'auto',
          width: '100%',
          backgroundColor: '#fff',
        }),
      }}
    >
      <img
        src={logo ? loadingGif : loading}
        alt="Loading"
        style={logo ? { height: 200 } : {}}
      />
    </div>
  );
}

export default Loading;
