import { Button, Card, Col, Progress, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';

import DraggerComponent from '../../../../components/common/dragger/draggerComponent';
import FileParser from '../../../../utils/file-parser/fileParser';
import LookupTemplatePopup from '../../../../components/common/lookupTemplatePopup/lookupTemplatePopup';
import { OffboardLookupType } from '../../../../enums/lookupType.enum';
import OnboardError from '../../onboardAgents/multipleOnboard/importAgents/error';
import { SelectOutlined } from '@ant-design/icons';
import googleSheetsLogo from '../../../../assets/icons/googleSheetsLogo.svg';
import validateLookup from '../../../../utils/onboard-agents/validateLookup';

interface ImportAgentsProps {
  onComplete: (value: any) => void;
  onUploadFailure: () => void;
  lookupMethod: OffboardLookupType;
  template: {
    templateLink: string | undefined;
    fileName: string;
    selectedCard: string;
    columns: any;
    data: any;
  };
}

function OffboardImportAgents({
  onComplete,
  onUploadFailure,
  lookupMethod,
  template,
}: ImportAgentsProps) {
  const [importAgentsSuccessful, setImportAgentsSuccessful] =
    useState<boolean>();
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [uploadedErrorMessage, setUploadedErrorMessage] = useState<any>('');
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [offboardJsonData, setOffboardJsonData] = useState<any>();
  const [showTemplatePopup, setShowTemplatePopup] = useState<boolean>(false);

  const fileParser = new FileParser();

  const setFileContentHandler = (fileContent: any) => {
    try {
      const offboardJsonData = fileParser.parseCsvToJson(fileContent);
      const offboardJsonDatawithIds = offboardJsonData.map(
        (row: any, index: number) => {
          return {
            id: index + 1,
            ...row,
          };
        }
      );
      setOffboardJsonData(offboardJsonDatawithIds);
    } catch (error) {
      console.error(error);
    }
  };

  const validateUploadedFile = () => {
    if (offboardJsonData && offboardJsonData.length > 0) {
      const headings = offboardJsonData[0];

      try {
        setUploadStatus(uploadStatus);
        validateLookup(lookupMethod, headings);
        setImportAgentsSuccessful(true);
        onComplete(offboardJsonData);
      } catch (error: any) {
        console.error('updatedUploadedContent error', error);
        setUploadStatus(uploadStatus);
        setImportAgentsSuccessful(false);
        setUploadProgress(40);
        setIsFileUploaded(false);
        setUploadedErrorMessage(
          error.message.split(',').map((error: any) => error.trim())
        );
        onUploadFailure();
      }
    }
  };

  const handleFileChange = (uploadedFile: any, uploadedFileContent: any) => {
    const { status, name, percent } = uploadedFile.file;
    if (status === 'uploading') {
      setUploadStatus(status);
      setUploadProgress(percent);
    } else if (status === 'done') {
      setUploadedFileName(name);
      setUploadProgress(percent);
      setFileContentHandler(uploadedFileContent);
      setIsFileUploaded(true);
    }
  };

  useEffect(() => {
    offboardJsonData && validateUploadedFile();
  }, [offboardJsonData]);

  return (
    <Card>
      <Typography.Paragraph
        style={{
          fontSize: '16px',
          fontWeight: 500,
          marginTop: '10px',
        }}
      >
        Keep all your producer data to be offboarded ready in a CSV File
      </Typography.Paragraph>
      {isFileUploaded ? (
        uploadStatus === 'uploading' || uploadStatus === 'done' ? (
          <Card
            style={{
              marginTop: '16px',
              marginBottom: '40px',
              backgroundColor: '#F5F5F7',
            }}
          >
            <Row justify="space-between" align="middle">
              <Col>
                <Row align="middle">
                  <img src={googleSheetsLogo} alt="logo" />

                  <Typography.Paragraph
                    style={{
                      fontSize: '14px',
                      marginLeft: '10px',
                      marginTop: '15px',
                    }}
                  >
                    {uploadedFileName || 'Table Example.csv'}
                  </Typography.Paragraph>
                </Row>
              </Col>
              <Col>
                <Progress
                  type="circle"
                  percent={uploadProgress}
                  size={75}
                  status={
                    importAgentsSuccessful === undefined
                      ? 'active'
                      : importAgentsSuccessful
                      ? 'success'
                      : 'exception'
                  }
                />
              </Col>
            </Row>
          </Card>
        ) : null
      ) : (
        <Card style={{ backgroundColor: '#F5F5F7', marginTop: '16px' }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Row align="middle">
                <img src={googleSheetsLogo} alt="logo" />
                <Typography.Paragraph
                  style={{
                    fontSize: '14px',
                    marginLeft: '10px',
                    marginTop: '15px',
                  }}
                >
                  {template.fileName}
                </Typography.Paragraph>
              </Row>
              <Typography.Paragraph
                style={{ fontSize: '12px', color: '#ABABAB' }}
              >
                Download this attached example file and use it as a reference{' '}
                <br /> to avoid error while matching column attributes
              </Typography.Paragraph>
            </Col>
            <Col>
              <Row justify="end">
                <Button
                  size="large"
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    setShowTemplatePopup(true);
                  }}
                >
                  <SelectOutlined /> Show Template
                </Button>
                <Button href={template.templateLink} size="large" download>
                  Download
                </Button>
              </Row>
            </Col>
          </Row>
        </Card>
      )}
      <Typography.Paragraph
        style={{
          fontSize: '16px',
          fontWeight: 500,
          marginTop: '10px',
          color: 'var(--secondary-color)',
        }}
      >
        Import Producers using CSV File
      </Typography.Paragraph>
      <div>
        <DraggerComponent
          handleFileChange={handleFileChange}
          setIsUploadComplete={setIsFileUploaded}
          isFileUploaded={isFileUploaded}
          acceptedFileSize={35 * 1024 * 1024}
          acceptedFileTypes={['text/csv', '.csv']}
          uploadedFileName={uploadedFileName}
        />
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography.Paragraph style={{ fontSize: '12px', color: '#ABABAB' }}>
            Supported Format: CSV
          </Typography.Paragraph>

          <Typography.Paragraph style={{ fontSize: '12px', color: '#ABABAB' }}>
            Maximum Size: 35MB
          </Typography.Paragraph>
        </Row>
        {uploadStatus === 'exception' && (
          <Card className="import-agents-inner-card">
            <Row
              justify="space-between"
              align="middle"
              style={{ marginTop: '10px' }}
            >
              <Col>
                <Row align="middle">
                  <img src={googleSheetsLogo} alt="logo" />
                  <Typography.Paragraph className="download-sublabel-bold">
                    {uploadedFileName || 'Table Example.csv'}
                  </Typography.Paragraph>
                </Row>
              </Col>
              <Col>
                <Progress
                  type="circle"
                  percent={uploadProgress}
                  size={75}
                  status={'exception'}
                />
              </Col>
            </Row>
          </Card>
        )}
        {uploadedErrorMessage && template.templateLink && (
          <OnboardError
            errorMessage={uploadedErrorMessage}
            templateLink={template.templateLink}
            onClose={() => setUploadedErrorMessage(null)}
          />
        )}
        <LookupTemplatePopup
          visible={showTemplatePopup}
          setVisible={(visible: boolean) => {
            if (!visible) {
              setShowTemplatePopup(false);
            }
            return showTemplatePopup;
          }}
          lookupTemplateDetails={{
            columns: template.columns,
            selectedCard: template.selectedCard,
            data: template.data,
          }}
        />
      </div>
    </Card>
  );
}

export default OffboardImportAgents;
