import { Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import Loading from '../../../components/loading';
import { OnboardingLayout } from '../../../components/layout/onboardingLayout';
import { RouteConstants } from '../../../constants/routes.constants';
import { SignupService } from '../../../services/signup.service';
import emailVerificationIcon from '../../../assets/icons/email-verification.svg';
import logo from '../../../assets/icons/insuretrek-logo.svg';

export const MagicLinkVerification = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setLoading] = useState(true);
  const [isMagicLinkVerified, setMagicLinkVerified] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) completeMagicLinkVerification();
    else
      api['error']({
        message: 'Verification Error',
        description: 'Please re-check the link you entered',
      });
  }, []);

  const completeMagicLinkVerification = () => {
    setLoading(true);
    SignupService.verifyMagicLink(params.id as string)
      .then(() => {
        api['success']({
          message: 'Verification Complete',
          description: 'You can continue with your sign up',
        });
        setMagicLinkVerified(true);
        setTimeout(() => {
          navigate(RouteConstants.onboarding.path);
        }, 3000);
      })
      .catch((err) => {
        api['error']({
          message: 'Verification Error',
          description: 'Please try requesting another link',
        });
        setMagicLinkVerified(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <OnboardingLayout flowType="MagicLink">
      {/* DND Start */}
      {contextHolder}
      {/* DND End */}

      <img alt="logo" src={logo} style={{ width: '200px' }} />
      <img alt="email-verification" src={emailVerificationIcon} />

      {isLoading ? (
        <Loading fullHeight={false} logo={true} />
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography.Title level={2}>
            {isMagicLinkVerified
              ? "We've verified your email!"
              : 'Sorry, we are not able to verify your account'}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {isMagicLinkVerified
              ? 'Head back to your onboarding journey to finish setting up your profile.'
              : 'Please try requesting a new verification link or reach out to support'}
          </Typography.Paragraph>
        </div>
      )}
    </OnboardingLayout>
  );
};
