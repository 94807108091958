export enum IndividualOnboardSteps {
  AGENT_DETAILS = 0,
  ASSIGNMENTS = 1,
  EMPLOYMENT_HISTORY = 4, //2
  BACKGROUND_QUESTIONS = 5, //3
  CONFIGURE_PAYMENTS = 2,
  ACTIONABLE_LICENSES = 3,
}

export enum MultipleOnboardSteps {
  IMPORT_AGENTS = 0,
  AGENT_VIEW = 1,
  ASSIGNMENT = 2,
  PAYMENT_CONFIG = 3,
  // ACTIONABLE_LICENSES = 4,
}

export enum MultipleOnboardLookup {
  SSN = 'Individual SSN and Last Name With NPN',
  NPN = 'Name and NPN Number',
  LICENSE = 'Name, License Number and State',
}
