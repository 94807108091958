import { Button, Typography } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

interface TotalSizeProps {
  isSaveMode?: boolean;
  onSave?: () => void;
  isSaveDisabled?: boolean;
  isSaveLoading?: boolean;
  onCancel: () => void;
}

export const TotalSizeInfo = ({
  isSaveMode,
  onSave,
  isSaveDisabled = false,
  isSaveLoading = true,
  onCancel,
}: TotalSizeProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <Typography.Text
        type="secondary"
        style={{ marginBottom: 4, fontSize: 14 }}
      >
        <InfoCircleOutlined /> Total file size for all uploads should not exceed
        <span style={{ color: 'var(--primary-color)' }}> 25MB </span>{' '}
      </Typography.Text>
      <div
        style={{
          display: 'flex',
          gap: '15px',
        }}
      >
        {!isSaveDisabled && <Button onClick={onCancel}>Cancel</Button>}

        {isSaveMode && (
          <Button
            onClick={() => onSave && onSave()}
            className="button primary-button"
            disabled={isSaveDisabled}
            loading={isSaveLoading}
          >
            Save All
          </Button>
        )}
      </div>
    </div>
  );
};
