import { Button, Form, Input, Modal, Typography } from 'antd';
import React, { useState } from 'react';

import { ModalContainer } from './change-password.style';
import { RightOutlined } from '@ant-design/icons';
import { TextDetail } from '../../application-status/applicationstatus.style';

interface ChangePasswordProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePasswordModal: React.FC<ChangePasswordProps> = ({
  visible,
  setVisible,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  type FieldType = {
    currentPassword?: string;
    newPassword?: string;
    confirmNewPassword?: string;
  };
  return (
    <Modal
      width={'500px'}
      maskClosable={false}
      open={visible}
      onCancel={() => {
        setVisible(false); // Call the setVisible function to close the modal
      }}
      okText="Ok"
      footer={null}
    >
      <ModalContainer>
        <TextDetail textColor={'#0588ca'} fontWeight={500} fontSize={'16px'}>
          Update Email Address
        </TextDetail>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600, width: '600px' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item<FieldType>
            label="Current Password"
            name="currentPassword"
            rules={[
              { required: true, message: 'Please input Current Password!' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="New Password"
            name="newPassword"
            rules={[{ required: true, message: "Please input Producer's name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Confirm New Password"
            name="confirmNewPassword"
            rules={[
              {
                required: true,
                message: 'Please input Reason For Blacklisting',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <div style={{ marginBottom: '12px' }}>
              <Typography.Text>
                <span style={{ color: '#787878' }}>Forgot Password? </span>
                &nbsp;&nbsp;&nbsp;&nbsp;Reset Password via Email{' '}
                <RightOutlined style={{ color: 'red' }} />
              </Typography.Text>
            </div>
            <Button
              style={{ width: '100%', marginBottom: '13px', height: '40px' }}
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Update Password
            </Button>
            <Button style={{ width: '100%', height: '40px' }} htmlType="reset">
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </ModalContainer>
    </Modal>
  );
};

export default ChangePasswordModal;
