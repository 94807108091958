import { useEffect, useState } from 'react';

import { IdConstants } from '../../../constants/id.constants';
import IndividualOnboardOptions from './individualOnboardV2/individualOnboardOptions';
import MultipleOnboard from './multipleOnboard';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import TabView from '../../../components/common/tabview/tabview';
import { TourConstants } from '../../../constants/tour.constant';
import { individualOnboardStore } from '../../../stores/individualOnboardStore';
import { multipleOnboardStore } from '../../../stores/multipleOnboardStore';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../../stores/tour.store';
import { useNavigate } from 'react-router';
import { withRoles } from '../../../auth/useRoles';

function OnboardAgents() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('1');
  const [isBlocking, setIsBlocking] = useState(false);
  const [multipleOnboardBlocker, setMultipleOnboardBlocker] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [multipleOnboardWarningModal, setMultipleOnboardWarningModal] =
    useState(false);

  const handleTabChange = (selected: string) => {
    if (selectedTab === selected) return;
    if (isBlocking) setOpenWarningModal(true);
    if (multipleOnboardBlocker) setMultipleOnboardWarningModal(true);
    else {
      setSelectedTab(selected);
      tourStore.setProducerSection(Number(selected));
      multipleOnboardStore.setCurrentStep(0);
      multipleOnboardStore.resetMultipleOnboard();
    }
  };

  const options = [
    {
      label: 'Individual Producer Onboarding',
      key: '1',
    },
    {
      label: 'Bulk Producer Onboarding',
      key: '2',
    },
  ];

  const continueAgencyOnboardTour = () => {
    if (sessionStorage.getItem(TourConstants.PRODUCER_ONBOARDING_STEP)) {
      const siderMenuProducer = document.getElementById(
        TourConstants.SIDER_MENU.PRODUCER_ONBOARDING
      );

      const sider = document.getElementById(IdConstants.SIDER_MENU_CONTAINER);

      let scrollTimer: boolean | number = false;

      const cta = () => {
        tourStore.setSignUpDemoTour(true);
        tourStore.setShowProducerOnboardTour(false);
        siderMenuProducer?.click();
      };

      const handleScrollEnd = () => {
        if (scrollTimer) return;
        scrollTimer = 1;
        cta();
        sider?.removeEventListener('scrollend', handleScrollEnd);
      };

      const handleScroll = () => {
        if (scrollTimer === false) scrollTimer = 0;
        sider?.addEventListener('scrollend', handleScrollEnd);
        sider?.removeEventListener('scroll', handleScroll);
      };

      sider?.addEventListener('scroll', handleScroll);

      siderMenuProducer?.scrollIntoView({ behavior: 'smooth', block: 'start' });

      setTimeout(() => {
        if (scrollTimer === false) {
          scrollTimer = true;
          cta();
          sider?.removeEventListener('scroll', handleScroll);
          sider?.removeEventListener('scrollend', handleScrollEnd);
        }
      }, 100);
      sessionStorage.removeItem(TourConstants.PRODUCER_ONBOARDING_STEP);
    }
  };

  useEffect(() => {
    tourStore.setProducerSection(1);
    return () => continueAgencyOnboardTour();
  }, []);

  return (
    <div style={{ width: '100%' }} className="onboard-active">
      <TabView
        options={options}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />

      {selectedTab === '1' && <IndividualOnboardOptions />}
      {selectedTab === '2' && (
        <MultipleOnboard
          isBlocking={multipleOnboardBlocker}
          setIsBlocking={setMultipleOnboardBlocker}
          openWarningModal={multipleOnboardWarningModal}
          changeTab={(isLeaving) => {
            if (isLeaving) {
              setSelectedTab('1');
              setMultipleOnboardWarningModal(false);
              setMultipleOnboardBlocker(false);
            } else {
              setMultipleOnboardWarningModal(false);
            }
          }}
        />
      )}
    </div>
  );
}

export default withRoles(observer(OnboardAgents), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
