import { Button, Modal } from 'antd';
import { ModalContainer, TextDetail } from './revokeAccess.style';
import React, { useState } from 'react';

import { AdminInvitesService } from '../../../../services/adminInvites.service';
import { AdminService } from '../../../../services/admin.service';
import { useAuth } from '../../../../auth/authProvider';

interface AdminAccessProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  adminDetails: any;
  revokeType: string;
  refetchFilter?: () => void;
}

const RevokeAccessOrInviteModal: React.FC<AdminAccessProps> = ({
  visible,
  setVisible,
  adminDetails,
  refetchFilter,
  revokeType,
}) => {
  const { bearerToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const RevokeAdminAccessOrAdminInvite = async () => {
    try {
      if (bearerToken) {
        setIsLoading(true);
        // To Revoke Admin Access
        if (revokeType === 'access') {
          const response = await AdminService.revokeAdminAccess(
            adminDetails?.id,
            adminDetails?.role,
            bearerToken
          );

          if (response.status === 'SUCCESS') {
            setVisible(false);
            if (refetchFilter) refetchFilter();
          }
        } else {
          // To Revoke Admin Invite
          const response = await AdminInvitesService.revokeInvite(
            adminDetails?.id,
            bearerToken
          );
          if (response.status === 'SUCCESS') {
            setVisible(false);
            if (refetchFilter) refetchFilter();
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      width={'500px'}
      open={visible}
      onCancel={() => {
        setVisible(false);
      }}
      okText="Ok"
      footer={null}
      maskClosable={false}
    >
      <ModalContainer>
        {revokeType === 'access' ? (
          <TextDetail textColor={'#0588ca'} fontSize={'16px'} fontWeight={500}>
            Revoke Access
          </TextDetail>
        ) : (
          <TextDetail textColor={'#0588ca'} fontSize={'16px'} fontWeight={500}>
            Revoke Invite
          </TextDetail>
        )}
        {revokeType === 'access' ? (
          <TextDetail textColor={'#0588ca'} fontSize={'16px'} fontWeight={500}>
            {adminDetails?.admin} &nbsp;will be permanently removed as an admin
            of your agency.
          </TextDetail>
        ) : (
          <TextDetail textColor={'#0588ca'} fontSize={'16px'} fontWeight={500}>
            Are you certain you want to revoke this invitation.
          </TextDetail>
        )}
        <div style={{ width: '100%' }}>
          <Button
            onClick={RevokeAdminAccessOrAdminInvite}
            loading={isLoading}
            style={{ background: '#00a6fb', marginBottom: '12px' }}
            type="primary"
            block
          >
            Remove
          </Button>
          <Button
            disabled={isLoading}
            block
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default RevokeAccessOrInviteModal;
