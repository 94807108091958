export const ssnData = [
  {
    sNo: '1',
    ssn: '123456789',
    lastName: 'Doe',
    reason: 'New producer for the state of California.',
  },
];

export const nameNpnData = [
  {
    sNo: '1',
    name: 'John Doe',
    npn: '12345678',
    reason: 'New producer for the state of Arizona.',
  },
];

export const licenseStateData = [
  {
    sNo: '1',
    name: 'John Doe',
    licenseNumber: '12345678',
    state: 'TX',
    reason: 'New producer for the state of Texas.',
  },
];
