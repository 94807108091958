import './editStateGroup.css';

import {
  Button,
  Card,
  Collapse,
  Input,
  Skeleton,
  Typography,
  notification,
} from 'antd';
import {
  LicenseConfig,
  StateGroup,
  StateLicenseConfig,
} from '../../../types/data/stateGroup.types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import AddNewAgents from './saveAsNewStateGroup/addAgents';
import { AgencyService } from '../../../services/agency.service';
import { AgentDetails } from '../../../types/data/agentDetail.type';
import { AgentService } from '../../../services/agent.service';
import DiscardConfirmationModal from './modals/discardChanges';
import DraggableLcCard from './draggable/draggableLcCard';
import DraggableStateLabel from './draggable/draggableStateLabel';
import EmptyState from '../../../components/common/emptyState/emptyState';
import { ErrorMessageConstants } from '../../../constants/error-message.constants';
import { ErrorResponse } from '../../../types/response/errorResponse.type';
import { IdConstants } from '../../../constants/id.constants';
import { LcDetails } from '../../../types/data/masterData';
import ManageTerritoryTour from '../../../components/common/Tour/manageTerritoryTour';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { STATE_GROUP_NAME } from '../../../constants/regex.constants';
import SaveNewAgents from './saveAsNewStateGroup/viewStateGroupAgents';
import SaveNewStateGroupModal from './modals/saveNewStateGroupModal';
import SaveStateGroupConfirmationModal from './modals/saveStateGroupModal';
import SelectedStatesCard from './selectedStatesCard';
import TransferAgents from './saveAsNewStateGroup/transferAgents';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { getStateNameFromCode } from '../../../utils/common.utils';
import { isEqual } from 'lodash';
import { produce } from 'immer';
import { tourStore } from '../../../stores/tour.store';
import { useAuth } from '../../../auth/authProvider';
import { withRoles } from '../../../auth/useRoles';

const { Title } = Typography;
const { Search } = Input;
export interface LoaDragItem {
  stateCode: string;
  lcCode: string;
  loaCode: string;
  loaids: string;
}

export interface LcDragItem {
  stateCode: string;
  lcCode: string;
  loaCodes: string[];
  loaids: string[];
}

enum SaveNewStateGroupStep {
  TRANSFER_AGENTS = 'Transfer agents from old territory to new territory',
  ADD_NEW_AGENTS = 'Add new agents to new territory',
  SAVE_NEW_AGENTS = 'View all agents of the new territory',
}

function EditStateGroup() {
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>('');
  const [buttonLoader, setButtonLoading] = useState<boolean>(false);
  const [discardModalVisibility, setDiscardModalVisibility] = useState(false);
  const [
    saveNewStateGroupModalVisibility,
    setNewSaveStateGroupModalVisibility,
  ] = useState(false);
  const [saveStateGroupModalVisibility, setSaveStateGroupModalVisibility] =
    useState(false);
  const [assignmentsModalVisibility, setAssignmentsModalVisibility] =
    useState(false);
  const [stateGroupId, setStateGroupId] = useState<string>();
  const [stateGroupName, setStateGroupName] = useState<string>();
  const [stateGroup, setStateGroup] = useState<StateGroup>({
    id: '',
    name: '',
    stateLicenseConfigs: [],
  });
  const [newStateGroup, setNewStateGroup] = useState<StateGroup>({
    id: '',
    name: '',
    stateLicenseConfigs: [],
  });

  const [preventAssignments, setPreventAssignments] = useState(false);
  const { id } = useParams();
  const [resetData, setResetData] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [agentIds, setAgentIds] = useState<string[]>([]);
  const [prevStateGroup, setPrevStateGroup] = useState({
    name: '',
    stateGroup: {},
  });

  const [newStateGroupAgents, setNewStateGroupAgents] = useState<
    AgentDetails[]
  >([]);
  const [newlyAddedAgents, setNewlyAddedAgents] = useState<AgentDetails[]>([]);
  const [newlyAddedAgentIds, setnewlyAddedAgentIds] = useState<string[]>([]);
  const [newStateGroupAgentIDs, setNewStateGroupAgentIds] = useState<string[]>(
    []
  );
  const [OldStateGroupAgents, setOldStateGroupAgents] = useState<
    AgentDetails[]
  >([]);

  const [currentLoaDragItem, setCurrentLoaDragItem] = useState<LoaDragItem>({
    stateCode: '',
    lcCode: '',
    loaCode: '',
    loaids: '',
  });

  const [step, setStep] = useState<SaveNewStateGroupStep>();

  const [currentLcDragItem, setCurrentLcDragItem] = useState<LcDragItem>({
    stateCode: '',
    lcCode: '',
    loaCodes: [],
    loaids: [],
  });

  const [currentStateDragItemCode, setCurrentStateDragItemCode] =
    useState<string>('');

  const [masterData, setMasterData] = useState([
    ...appStateInfoStore.getLicenseConfigsMasterDataWithoutDuplicates(),
  ]);

  masterData.sort((a, b) => {
    const nameA = getStateNameFromCode(a.stateCode);
    const nameB = getStateNameFromCode(b.stateCode);
    return nameA.localeCompare(nameB);
  });

  useEffect(() => {
    const ids: any[] = [];
    newStateGroupAgents.forEach((agent) => {
      ids.push(agent.id);
    });
    if (ids) setNewStateGroupAgentIds(ids);
  }, [newStateGroupAgents]);
  useEffect(() => {
    const ids: any[] = [];
    newlyAddedAgents.forEach((agent) => {
      ids.push(agent.id);
    });
    if (ids) setnewlyAddedAgentIds(ids);
  }, [newlyAddedAgents]);

  useEffect(() => {
    id ? setStateGroupId(id) : setIsLoading(false);
  }, []);

  useEffect(() => {
    const fetchInfo = async () => {
      if (stateGroupId) {
        await fetchStateGroup(stateGroupId);
        await fetchAgentForStateGroup(stateGroupId);
      }
    };

    fetchInfo();
  }, [stateGroupId]);

  const handleSearch = (text: string) => {
    if (!text) {
      setMasterData([
        ...appStateInfoStore.getLicenseConfigsMasterDataWithoutDuplicates(),
      ]);
      return;
    }
    const newMasterData = appStateInfoStore
      .getLicenseConfigsMasterDataWithoutDuplicates()
      .filter((item) =>
        getStateNameFromCode(item.stateCode)
          .toLowerCase()
          .includes(text.toLowerCase())
      );

    setMasterData(newMasterData);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleSearch(searchText);
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  // Service Functions START
  const fetchStateGroup = async (stateGroupId: string) => {
    const token = await getAccessTokenSilently();
    const fetchedStateGroup = await AgencyService.findStateGroupById(
      stateGroupId,
      token
    ).catch((err) => {
      console.error(err);
    });

    if (fetchedStateGroup && fetchedStateGroup.data) {
      setStateGroup(fetchedStateGroup.data);
      setStateGroupName(fetchedStateGroup.data.name);
      setPrevStateGroup({
        name: fetchedStateGroup.data.name,
        stateGroup: fetchedStateGroup.data,
      });
      appStateInfoStore.setStateGroup(fetchedStateGroup.data.name);
    }
    setIsLoading(false);
  };

  const fetchAgentForStateGroup = async (stateGroupId: string) => {
    const token = await getAccessTokenSilently();
    const response = await AgentService.getAgentsforStateGroup(
      stateGroupId,
      token
    ).catch((error) => {
      console.error('error :: ', error);
    });
    if (response?.data) {
      setOldStateGroupAgents(response.data);
      const ids: any[] = [];
      response.data.forEach((agent) => {
        ids.push(agent.id);
      });
      if (ids) setAgentIds(ids);
    }
  };

  const handleReset = async () => {
    setResetData(true);
    if (stateGroupId) {
      await fetchStateGroup(stateGroupId);
      setResetData(false);
    } else {
      setCurrentStateDragItemCode('');
      setCurrentLcDragItem({
        stateCode: '',
        lcCode: '',
        loaCodes: [],
        loaids: [],
      });
      setCurrentLoaDragItem({
        stateCode: '',
        lcCode: '',
        loaCode: '',
        loaids: '',
      });
      setStateGroup({ id: '', name: '', stateLicenseConfigs: [] });
      setResetData(false);
    }
  };

  const handleSaveStateGroup = () => {
    if (stateGroupId) {
      updateStateGroup();
    } else createStateGroup();
  };

  const updateStateGroup = async () => {
    if (validateStateGroupBeforeSave(stateGroup) && stateGroupName) {
      if (adminStore.account && stateGroupId) {
        setSaveLoading(true);
        const token = await getAccessTokenSilently();
        try {
          await AgencyService.updateStateGroupById(
            stateGroupId,
            stateGroupName,
            stateGroup.stateLicenseConfigs.map((d) => {
              return {
                stateCode: d.stateCode,
                licenseConfigurations: d.licenseConfigs.flatMap(
                  (d) => d.loaids || ''
                ),
              };
            }),
            true,
            token
          );
          navigate(RouteConstants.stateGroups.path);
        } catch (error: any) {
          if (
            error?.error?.message?.includes(
              'A record already exists with (stateGroupName)'
            )
          ) {
            showError(
              'Territory name already exists. Please choose a different name'
            );
          } else {
            if (error?.error?.exceptionName === 'DuplicateValueException') {
              showError(
                ErrorMessageConstants.DUPLICATE_VALUE_ERROR(
                  'Territory Name',
                  error.error.value
                )
              );
            } else {
              showError(ErrorMessageConstants.COMMON);
            }
          }
        } finally {
          setSaveLoading(false);
        }
      }
    }
  };

  const createStateGroup = async () => {
    try {
      if (validateStateGroupBeforeSave(stateGroup) && stateGroupName) {
        let resp: any;
        setButtonLoading(true);
        if (adminStore.account) {
          const token = await getAccessTokenSilently();
          resp = await AgencyService.createStateGroup(
            stateGroupName,
            stateGroup.stateLicenseConfigs.map((d) => ({
              stateCode: d.stateCode,
              licenseConfigurations: d.licenseConfigs?.flatMap(
                (d) => d.loaids || ''
              ),
            })),
            token
          );
        }
        if (resp.status === 'SUCCESS') {
          setButtonLoading(false);
          navigate(RouteConstants.stateGroups.path);
        }
      }
    } catch (error: any) {
      if (error?.error?.exceptionName === 'DuplicateValueException') {
        showError(
          ErrorMessageConstants.DUPLICATE_VALUE_ERROR(
            'Territory Name',
            error?.error?.value
          )
        );
      } else if (
        error?.error?.message ===
        'A stateGroup with one of the given names already exists'
      ) {
        showError(
          'Territory name already exists. Please choose a different name'
        );
      } else {
        showError(ErrorMessageConstants.COMMON);
      }
      setButtonLoading(false);
    }
  };
  // Service Functions END

  // Local State Actions START
  const updateStateLicenseConfig = (stateLicenseConfig: StateLicenseConfig) => {
    const newStateGroup = produce(stateGroup, (draft) => {
      for (let i = 0; i < draft.stateLicenseConfigs.length; i++) {
        if (
          draft.stateLicenseConfigs[i].stateCode ===
          stateLicenseConfig.stateCode
        ) {
          draft.stateLicenseConfigs[i] = stateLicenseConfig;
        }
      }
    });
    setStateGroup(newStateGroup);
  };

  const updateLicenseConfig = (
    stateCode: string,
    licenseConfig: LicenseConfig
  ) => {
    const newStateGroup = produce(stateGroup, (draft) => {
      for (let i = 0; i < draft.stateLicenseConfigs.length; i++) {
        if (draft.stateLicenseConfigs[i].stateCode === stateCode) {
          for (
            let j = 0;
            j < draft.stateLicenseConfigs[i].licenseConfigs.length;
            j++
          ) {
            if (
              draft.stateLicenseConfigs[i].licenseConfigs[j].lcCode ===
              licenseConfig.lcCode
            ) {
              draft.stateLicenseConfigs[i].licenseConfigs[j] = licenseConfig;
            }
          }
        }
      }
    });
    setStateGroup(newStateGroup);
  };

  const removeStateFromStateGroup = (stateCode: string) => {
    const newStateGroup = produce(stateGroup, (draft) => {
      draft.stateLicenseConfigs = draft.stateLicenseConfigs.filter(
        (itr) => itr.stateCode !== stateCode
      );
    });
    setStateGroup(newStateGroup);
  };

  const removeLcFromStateGroup = (stateCode: string, lcCode: string) => {
    let isValid = true;
    const newStateGroup = produce(stateGroup, (draft) => {
      for (let i = 0; i < draft.stateLicenseConfigs.length; i++) {
        if (draft.stateLicenseConfigs[i].stateCode === stateCode) {
          if (draft.stateLicenseConfigs[i].licenseConfigs.length === 1) {
            removeStateFromStateGroup(stateCode);
            isValid = false;
          } else
            draft.stateLicenseConfigs[i].licenseConfigs =
              draft.stateLicenseConfigs[i].licenseConfigs.filter(
                (itr) => itr.lcCode !== lcCode
              );
        }
      }
    });
    isValid && setStateGroup(newStateGroup);
  };

  const removeLoaFromStateGroup = (
    stateCode: string,
    lcCode: string,
    loaCode: string,
    loaId: string
  ) => {
    let isValid = true;
    const newStateGroup = produce(stateGroup, (draft) => {
      for (let i = 0; i < draft.stateLicenseConfigs.length; i++) {
        if (draft.stateLicenseConfigs[i].stateCode === stateCode) {
          for (
            let j = 0;
            j < draft.stateLicenseConfigs[i].licenseConfigs.length;
            j++
          ) {
            if (
              draft.stateLicenseConfigs[i].licenseConfigs[j].lcCode === lcCode
            ) {
              if (
                draft.stateLicenseConfigs[i].licenseConfigs[j].loaCodes
                  .length === 1
              ) {
                removeLcFromStateGroup(stateCode, lcCode);
                isValid = false;
              } else
                draft.stateLicenseConfigs[i].licenseConfigs[j].loaCodes =
                  draft.stateLicenseConfigs[i].licenseConfigs[
                    j
                  ].loaCodes.filter((itr) => itr !== loaCode);
              draft.stateLicenseConfigs[i].licenseConfigs[j].loaids =
                draft.stateLicenseConfigs[i].licenseConfigs[j].loaids?.filter(
                  (itr) => itr !== loaId
                );
            }
          }
        }
      }
    });
    isValid && setStateGroup(newStateGroup);
  };

  const addEmptyStateToStateGroup = (
    stateCode: string,
    licenseConfigs: LicenseConfig[] = []
  ) => {
    const newStateGroup = produce(stateGroup, (draft) => {
      draft.stateLicenseConfigs.push({
        stateCode,
        licenseConfigs: licenseConfigs,
      });
    });
    setStateGroup(newStateGroup);
  };
  // Local State Actions END

  const validateStateGroupBeforeSave = (stateGroup: StateGroup): boolean => {
    //name should not be empty
    if (!stateGroupName) {
      showError('Please add a valid name for the territory');
      return false;
    }

    // atleast one state must be present
    if (stateGroup.stateLicenseConfigs.length === 0) {
      showError('Please add atleast one state');
      return false;
    }

    // no state should be empty
    return stateGroup.stateLicenseConfigs.every((stateItr) => {
      if (stateItr.licenseConfigs.length === 0) {
        showError(
          `Please add at least one license class to the state ${getStateNameFromCode(
            stateItr.stateCode
          )}`
        );
        return false;
      }
      return true;
    });
  };

  const handleDuplicateNameError = () => {
    showError(
      ErrorMessageConstants.DUPLICATE_VALUE_ERROR(
        'Territory Name',
        stateGroupName || ''
      )
    );
    setPreventAssignments(true);
    setNewSaveStateGroupModalVisibility(true);
  };

  const isAgentAdded = () =>
    !!(newStateGroupAgentIDs.length || newlyAddedAgentIds.length);

  const duplicateStateGroup = async () => {
    if (newStateGroupAgentIDs && newlyAddedAgentIds) {
      if (stateGroupId) {
        setIsDuplicateLoading(true);
        const token = await getAccessTokenSilently();
        return AgencyService.createDuplicateStateGroup(
          stateGroupId,
          {
            createStateGroupDto: {
              name: newStateGroup.name,
              stateLicenseConfigs: stateGroup.stateLicenseConfigs,
            },
            transferredAgentIds: newStateGroupAgentIDs,
            newAgentIds: newlyAddedAgentIds,
          },
          token
        )
          .then((resp) => {
            navigate(RouteConstants.stateGroups.path);
            setIsDuplicateLoading(false);
            return true;
          })
          .catch((error: ErrorResponse) => {
            console.error('error :: ', error);
            setIsDuplicateLoading(false);
            showError(
              ErrorMessageConstants.CUSTOM_ERROR(error?.error?.message)
            );
          });
      }
    }
  };

  const getLicenseClass = (data: LcDetails[], stateCode: string) => {
    return data;
  };

  return isLoading ? (
    <Skeleton active />
  ) : (
    <div style={{ width: '100%' }}>
      {contextHolder}
      {!step && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '87vh',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
              alignItems: 'flex-end',
              flexShrink: '0',
            }}
          >
            <span className="typography_1">
              Seamlessly drag and drop each State, along with the coordinating
              license class and Lines of Authority from the left panel to the
              right.
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              columnGap: '10px',
              flexGrow: '1',
              overflow: 'hidden',
            }}
          >
            <Card
              style={{
                width: '60%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <div style={{ flexShrink: '0' }}>
                <Title level={5} style={{ marginTop: '0' }}>
                  Territory Name <span style={{ color: 'red' }}>*</span>
                </Title>
                <Input
                  id={
                    IdConstants.STATE_GROUPS.STATE_GROUPS_INPUT_NAME +
                    '-create-state-groups'
                  }
                  className={
                    IdConstants.STATE_GROUPS.EDIT_STATE_GROUPS_CLASS_NAME
                  }
                  style={{ marginBottom: '10px' }}
                  placeholder="Enter the territory name"
                  value={stateGroupName}
                  showCount
                  maxLength={50}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = STATE_GROUP_NAME;
                    if (regex.test(inputValue)) {
                      if (!inputValue.startsWith(' ')) {
                        setStateGroupName(inputValue);
                      } else {
                        setStateGroupName('');
                      }
                    }
                  }}
                />
                {/* <Search
                  id={IdConstants.SEARCH_INPUT + '-state-group'}
                  placeholder="Search by state"
                  size="middle"
                  style={{
                    borderRadius: 1,
                    flexGrow: 1,
                    height: '50px',
                    marginBottom: '10px',
                  }}
                  value={searchText}
                  // allowClear
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onSearch={() => handleSearch(searchText)}
                  onPressEnter={() => handleSearch(searchText)}
                /> */}
              </div>
              <div style={{ flexShrink: '0' }}>States</div>

              <div style={{ overflowY: 'auto', flexGrow: '1' }}>
                {masterData?.length === 0 ? (
                  <div className="flex-center" style={{ marginTop: 36 }}>
                    <EmptyState
                      image="empty data"
                      heading="No States"
                      content=""
                    />
                  </div>
                ) : (
                  <Collapse
                    items={masterData.map((stateItr, index) => {
                      return {
                        ...stateItr,
                        key: stateItr.stateCode,
                        id: stateItr.stateCode,
                        label: (
                          <DraggableStateLabel
                            key={index}
                            stateCode={stateItr.stateCode}
                            setCurrentStateDragItemCode={
                              setCurrentStateDragItemCode
                            }
                          />
                        ),
                        children: (
                          <div>
                            {getLicenseClass(
                              stateItr.licenseClasses,
                              stateItr.stateCode
                            ).map((lcItr, lcIndex) => {
                              return (
                                <DraggableLcCard
                                  key={lcIndex}
                                  stateCode={stateItr.stateCode}
                                  setCurrentLoaDragItem={setCurrentLoaDragItem}
                                  setCurrentLcDragItem={setCurrentLcDragItem}
                                  lcDetails={lcItr}
                                  lcIndex={lcIndex}
                                />
                              );
                            })}
                          </div>
                        ),
                      };
                    })}
                    defaultActiveKey={['1']}
                    onChange={() => {}}
                  />
                )}
              </div>
            </Card>
            <SelectedStatesCard
              isLoading={resetData}
              handleResetData={handleReset}
              currentLoaDragItem={currentLoaDragItem}
              currentLcDragItem={currentLcDragItem}
              currentStateDragItemCode={currentStateDragItemCode}
              stateGroup={stateGroup}
              updateStateLicenseConfig={updateStateLicenseConfig}
              updateLicenseConfig={updateLicenseConfig}
              removeStateFromStateGroup={removeStateFromStateGroup}
              removeLcFromStateGroup={removeLcFromStateGroup}
              removeLoaFromStateGroup={removeLoaFromStateGroup}
              addEmptyStateToStateGroup={addEmptyStateToStateGroup}
            />
          </div>
          <div
            style={{
              display: 'flex',
              columnGap: '10px',
              marginTop: '20px',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              flexShrink: '0',
            }}
          >
            <div
              style={{
                alignItems: 'flex-end',
              }}
            >
              <Button
                id={IdConstants.STATE_GROUPS.STATE_GROUP_BACK + '-state-groups'}
                onClick={() => {
                  if (
                    prevStateGroup.name !== (stateGroupName || '') ||
                    !isEqual(
                      Object.keys(prevStateGroup?.stateGroup || {})?.length
                        ? prevStateGroup?.stateGroup
                        : {
                            id: '',
                            name: '',
                            stateLicenseConfigs: Array(0),
                          },
                      stateGroup
                    )
                  )
                    setDiscardModalVisibility(true);
                  else navigate(RouteConstants.stateGroups.path);
                }}
              >
                Back
              </Button>
            </div>{' '}
            <div
              style={{
                flexBasis: '1/2',
                display: 'flex',
                columnGap: '10px',
                justifyContent: 'space-between',
              }}
            >
              <Button
                id={IdConstants.STATE_GROUPS.SAVE_STATE_GROUP + '-state-groups'}
                type="primary"
                loading={buttonLoader || saveLoading}
                onClick={() => {
                  if (
                    prevStateGroup.name !== stateGroupName ||
                    !isEqual(prevStateGroup.stateGroup, stateGroup)
                  ) {
                    if (stateGroupId) {
                      if (stateGroupName) {
                        if (appStateInfoStore.cloneEnable) {
                          handleSaveStateGroup();
                        } else {
                          setSaveStateGroupModalVisibility(true);
                        }
                      } else showError('Please give the territory a name');
                    } else {
                      createStateGroup();
                    }
                  } else {
                    navigate(RouteConstants.stateGroups.path, {
                      replace: true,
                    });
                  }
                }}
              >
                Save Territory
              </Button>
              {stateGroupId && (
                <Button
                  id={
                    IdConstants.STATE_GROUPS.SAVE_NEW_STATE_GROUP +
                    '-state-groups'
                  }
                  className="button secondary-button"
                  onClick={() => {
                    setNewSaveStateGroupModalVisibility(true);
                  }}
                >
                  Clone Territory
                </Button>
              )}
            </div>
          </div>
          <DiscardConfirmationModal
            visible={discardModalVisibility}
            handleVisibility={() => {
              setDiscardModalVisibility(false);
            }}
          />
          {!appStateInfoStore.cloneEnable && (
            <SaveStateGroupConfirmationModal
              manageAssignments={
                agentIds && agentIds?.length > 0 ? true : false
              }
              visible={saveStateGroupModalVisibility}
              handleVisibility={() => {
                setSaveStateGroupModalVisibility(false);
              }}
              onSave={handleSaveStateGroup}
              loading={saveLoading}
              stateGroupId={stateGroupId || ''}
            />
          )}
        </div>
      )}
      <SaveNewStateGroupModal
        manageAssignments={
          preventAssignments
            ? !preventAssignments
            : agentIds && agentIds?.length > 0
        }
        visible={saveNewStateGroupModalVisibility}
        handleVisibility={() => {
          setNewSaveStateGroupModalVisibility(false);
        }}
        loading={saveLoading}
        setLoading={() => {
          setIsLoading(false);
        }}
        isAgentAdded={isAgentAdded}
        isDuplicateLoading={isDuplicateLoading}
        duplicateStateGroup={duplicateStateGroup}
        stateGroup={stateGroup}
        setStepState={() => {
          setStep(SaveNewStateGroupStep.TRANSFER_AGENTS);
        }}
        setNewStateGroup={(value: string) => {
          setNewStateGroup({ ...newStateGroup, name: value });
        }}
      />
      {step === SaveNewStateGroupStep.TRANSFER_AGENTS && (
        <TransferAgents
          oldStateGroupAgents={OldStateGroupAgents}
          savedAgentsToBeTransferred={newStateGroupAgents}
          setNewStateGroupAgents={setNewStateGroupAgents}
          onClickNext={() => {
            setStep(SaveNewStateGroupStep.SAVE_NEW_AGENTS);
          }}
          oldStateGroupName={stateGroupName || ''}
          newStateGroup={newStateGroup}
          oldStateGroupId={stateGroupId || ''}
          onBack={() => {
            setNewSaveStateGroupModalVisibility(true);
            setStep(undefined);
          }}
        />
      )}
      {step === SaveNewStateGroupStep.SAVE_NEW_AGENTS && (
        <SaveNewAgents
          newAgents={newStateGroupAgents}
          setStep={() => {
            setStep(SaveNewStateGroupStep.ADD_NEW_AGENTS);
          }}
          continueToAssignments={() => {
            setNewSaveStateGroupModalVisibility(true);
            setStep(undefined);
          }}
          newlyAddedAgents={newlyAddedAgents}
          onBack={(AgentDetails) => {
            setStep(SaveNewStateGroupStep.TRANSFER_AGENTS);
          }}
        />
      )}
      {step === SaveNewStateGroupStep.ADD_NEW_AGENTS && (
        <AddNewAgents
          newStateGroupExistingAgents={newlyAddedAgentIds}
          oldStateGroupId={stateGroupId || ''}
          setNewAgents={(agents: AgentDetails[]) => {
            setNewlyAddedAgents((prev) => [...prev, ...agents]);
          }}
          setStep={() => {
            setStep(SaveNewStateGroupStep.SAVE_NEW_AGENTS);
          }}
        />
      )}
      {/* <StateGroupAssignmentModal
        agentIds={agentIds}
        transferredAgentIds={newStateGroupAgentIDs}
        newAgentIds={newlyAddedAgentIds}
        visible={assignmentsModalVisibility}
        handleVisibility={() => {
          setAssignmentsModalVisibility(false);
        }}
        oldStateGroupId={stateGroupId}
        stateGroup={
          step === SaveNewStateGroupStep.SAVE_NEW_AGENTS
            ? {
                ...stateGroup,
                name: newStateGroup.name,
              }
            : {
                ...stateGroup,
                name: stateGroupName || stateGroup.name,
              }
        }
        type={
          step === SaveNewStateGroupStep.SAVE_NEW_AGENTS
            ? 'duplicate'
            : 'update'
        }
        handleDuplicateNameError={handleDuplicateNameError}
      /> */}
      {tourStore.manageTerritoryTour && <ManageTerritoryTour />}
    </div>
  );
}

export default withRoles(EditStateGroup, [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
