import { Button, Form, Input, Modal, Radio, Select, Spin, message } from 'antd';
import {
  CustomDropdownProps,
  DRLP_OPTIONS_CTA,
  DropdownDrlp,
} from './agencyLicensingInterfaces.type';
import {
  EMAIL_REGEX,
  NPN_REGEX,
  SSN_REGEX,
} from '../../constants/regex.constants';
import React, { useState } from 'react';
import {
  formatPhoneAndFax,
  isPhoneNumberValid,
} from '../../utils/form-utils/format-phone-fax.utils';

import { AgencyService } from '../../services/agency.service';
import { AgentService } from '../../services/agent.service';
import LicensedProducer from './licensedProducer';
import { PlusOutlined } from '@ant-design/icons';
import { ProducerOnboardingLookupType } from '../../pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { getAssignedDrlp } from './reviewSection';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../auth/authProvider';

const { Option } = Select;

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  text,
  options,
  stateCode,
  onUpdateDropDown,
  licenseClassCode,
  onAddNewProducer,
}) => {
  const [npnForm] = Form.useForm();
  const [alreadyExistingAgentForm] = Form.useForm();
  const [npnResp, setNpnResp] = useState<any>();
  const [newAgentForm] = Form.useForm();
  const { bearerToken } = useAuth();
  const [assignedNotOnboarded, setAssignedNotOnboarded] = useState(false);
  const [modalVisible, setModalVisible] = useState({
    npnCheck: false,
    onBoarded: false,
    notOnboarded: false,
    npnCheckLoading: false,
    onBoardedLoading: false,
    notOnboardedLoading: false,
    notOnboardedFetching: false,
  });
  const [selectedType, setSelectedType] = useState({
    value: '',
    isNiprUpdated: false,
  });
  const GENDER_TYPE = ['M', 'F'];
  const handleButtonClick = () => {
    setModalVisible((prev) => ({ ...prev, npnCheck: true }));
  };
  const [currentDrlp, setCurrentDrlp] = useState<any>();
  const [disableFields, setDisableFields] = useState<any>();
  const [watchlistModalVisible, setWatchlistModalVisible] = useState('');

  const handleModalCancel = () => {
    setDisableFields(null);
    setNpnResp(null);
    setCurrentDrlp(null);
    setModalVisible((prev) => ({
      ...prev,
      npnCheck: false,
      onBoarded: false,
      notOnboarded: false,
    }));
  };

  const handleChange = (value: string) => {
    const option = options?.filter((data) => data.id === value)?.[0];
    setCurrentDrlp(option);
    if (option?.isPrefilled) {
      onUpdateDropDown(option);
      return;
    }
    const npn = option.npn || option.drlpNpn;
    // if (option?.isOnboarded) {
    if (!option?.ssn) {
      setDisableFields({
        agentNpn: npn,
        agentName: {
          firstName: option?.name?.firstName,
          middleName: option?.name?.middleName,
          lastName: option?.name?.lastName,
        },
      });
      alreadyExistingAgentForm.setFields([
        {
          name: 'firstName',
          value: option?.name?.firstName,
        },
        {
          name: 'middleName',
          value: option?.name?.middleName,
        },
        {
          name: 'lastName',
          value: option?.name?.lastName,
        },
        {
          name: 'npn',
          value: npn,
        },
        {
          name: 'ssn',
          value: option?.ssn,
        },
      ]);
      setModalVisible((prev) => ({
        ...prev,
        npnCheck: false,
        onBoarded: true,
        notOnboarded: false,
      }));
    } else {
      onUpdateDropDown(option);
    }
    // } else {
    // handleCheckNPN({}, option?.drlpNpn);
    // setModalVisible((prev) => ({
    //   ...prev,
    //   npnCheck: false,
    //   onBoarded: false,
    //   notOnboarded: true,
    // }));
    // }
  };

  const onClose = (value: string) => {
    const option = options?.filter((data) => data.id === value)?.[0];
    onUpdateDropDown(option, DRLP_OPTIONS_CTA.DELETE);
  };

  const handleAddExistingDRLP = async (values: any) => {
    if (!bearerToken) return;
    try {
      let option: any;
      if (!npnResp) {
        option = {
          ...currentDrlp,
          npn: values.npn || currentDrlp?.npn || currentDrlp?.npn, // required
          stateCode,
          drlpLicenseNumber: currentDrlp?.drlpLicenseNumber, // required
          drlpPdbLicenseClass: currentDrlp?.drlpPdbLicenseClass, // required
          hasAppliedApplications: currentDrlp?.hasAppliedApplications, // required
          drlpLicenseId: currentDrlp?.drlpLicenseId, // require
          firmNpn: currentDrlp?.firmNpn, // not require
          agencyId: currentDrlp?.agencyId, // not require
          firmLicenseNumber: currentDrlp?.firmLicenseNumber, // not require
          firmLicenseClass: currentDrlp?.firmLicenseClass, // not require
          dateBegin: currentDrlp?.dateBegin, // not required
          ssn: values?.ssn,
          selected: true,
          isNewDrlpAdded: false,
        };
      } else {
        option = {
          ...npnResp,
          npn: npnResp?.agentNpn || npnResp?.npn || values?.npn,
          drlpLicenseId: npnResp?.agentLicenseId,
          drlpLicenseNumber: npnResp?.agentLicenseNumber, // required
          drlpPdbLicenseClass: npnResp?.agentLicenseClass, // required
          ssn: values?.ssn,
          selected: true,
          isNewDrlpAdded: true,
          firmLicenseClass: licenseClassCode,
          name: npnResp?.agentName || {
            firstName: npnResp?.name?.firstName || values?.firstName,
            middleName: npnResp?.name?.middleName || values?.middleName,
            lastName: npnResp?.name?.lastName || values?.lastName,
          },
          id: npnResp?.id || npnResp?.agentNpn,
        };
      }
      onUpdateDropDown(option, !npnResp ? undefined : DRLP_OPTIONS_CTA.ADD);
      if (npnResp) {
        setNpnResp(null);
      }
      if (currentDrlp) {
        setCurrentDrlp(null);
      }
      if (disableFields) {
        setDisableFields(null);
      }
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data?.error?.message);
    } finally {
      setModalVisible((prev) => ({
        ...prev,
        onBoardedLoading: false,
        onBoarded: false,
      }));
    }
  };

  const hanldeUpdateProducerDetails = async (values: any) => {
    if (!bearerToken) return;
    try {
      setModalVisible((prev) => ({
        ...prev,
        notOnboardedLoading: true,
      }));
      const payload = {
        ssn: values?.ssn,
        businessEmail: values?.email,
        gender: selectedType.value || 'Unknown',
      };
      await AgencyService.updateProducerDetails(
        npnResp?.data?.id,
        payload,
        bearerToken
      );
      onUpdateDropDown(
        {
          stateCode,
          agentId: npnResp?.data?.id,
          isNewlyOnboardedAgent: true,
          name: {
            firstName: values?.firstName,
            middleName: values?.middleName,
            lastName: values?.lastName,
          },
          selected: true,
          isOnboarded: true,
          isNewDrlpAdded: true,
          id: npnResp?.data?.id,
          npn: values?.npn,
          ssn: values?.ssn,
        },
        assignedNotOnboarded ? DRLP_OPTIONS_CTA.MODIFY : DRLP_OPTIONS_CTA.ADD
      );
      if (assignedNotOnboarded) {
        onAddNewProducer({
          existingProducerDetails: {
            name: {
              firstName: values?.firstName,
              middleName: values?.middleName,
              lastName: values?.lastName,
            },
            npn: values?.npn,
          },
        });
        setAssignedNotOnboarded(false);
      }
      // const details = npnResp?.agentDetails;
      // let entityInfoRecordId = npnResp?.agentDetails?.entityInfoRecordId;
      // if (!entityInfoRecordId) {
      //   const response = await LicensesService.getCheckNpnForOnboardingDrlp(
      //     bearerToken,
      //     {
      //       npn: values.npn,
      //       stateCode,
      //     }
      //   );
      //   entityInfoRecordId = response?.agentDetails?.entityInfoRecordId;
      // }
      // const biography =
      //   details?.entityInfo?.producer?.entityBiographic?.biographic;
      // const resp = await LicensesService.addNewAgentToDrlp(
      //   bearerToken,
      //   stateCode,
      //   {
      //     isExistingAgent: details?.isExistingAgent ? true : false,
      //     entityInfoRecordId: entityInfoRecordId,
      //     npn: values.npn,
      //     [details?.isExistingAgent
      //       ? 'onboardExistingAgentDto'
      //       : 'onboardNewAgentDto']: {
      //       [details?.isExistingAgent ? 'updateAgentDto' : 'createAgentDto']: {
      //         email: values?.email || details?.email || biography?.email,
      //         ssn: values?.ssn,
      //         stateAddresses:
      //           details?.entityInfo?.producer?.entityBiographic?.addresses,
      //         isAddressUpdated: false,
      //         isInvited: false,
      //         npn: values.npn,
      //         isVerified: false,
      //         isRegistered: false,
      //         name: {
      //           firstName: biography?.firstName || values?.firstName,
      //           middleName: biography?.middleName || values?.middleName,
      //           lastName: biography?.lastName || values?.lastName,
      //         },
      //         businessEmail:
      //           details?.entityInfo?.producer?.entityBiographic
      //             ?.contactInfos?.[0]?.contactInfos?.[0]?.businessEmail ||
      //           details?.businessEmail ||
      //           details?.email ||
      //           biography?.email ||
      //           values?.email,
      //         agencyId: adminStore.agency?.id,
      //         dateOfBirth: biography?.dateOfBirth,
      //         gender: selectedType.value,
      //         homeState: details?.homeStateAddresses?.[0]?.state,
      //         fax: details?.fax,
      //         phone: values?.phone || details?.phone,
      //       },
      //       entityInfoRecordId: entityInfoRecordId,
      //     },
      //   }
      // );
      // const currentDrlps = resp?.agent;
      // const option = {
      //   ...currentDrlp,
      //   drlpNpn: currentDrlps?.npn || values?.npn || currentDrlps?.drlpNpn, // required
      //   stateCode,
      //   drlpLicenseId: resp?.licenseId,
      //   drlpLicenseNumber: resp?.licenseNumber, // required
      //   hasAppliedApplications: false, // required
      //   agencyId: currentDrlps?.agencyId, // not require
      //   firmNpn: currentDrlps?.firmNpn, // not require
      //   firmLicenseNumber: currentDrlps?.firmLicenseNumber, // not require
      //   firmLicenseClass: currentDrlps?.firmLicenseClass, // not require
      //   dateBegin: currentDrlps?.dateBegin, // not required
      //   agentId: currentDrlps?.id,
      //   isNewlyOnboardedAgent: true,
      //   name: currentDrlps?.name,
      //   selected: true,
      //   isOnboarded: true,
      // };
      message.success('producer details updated successfuly');
      // if (currentDrlps?.ssn)
      // if (containsValidLicense) {
      // onUpdateDropDown(option, DRLP_OPTIONS_CTA.ADD);
      // }
      // else setCurrentDrlp(option);
      setModalVisible((prev) => ({
        ...prev,
        notOnboardedLoading: false,
        notOnboarded: false,
        onBoarded: false,
      }));
      setNpnResp({});
    } catch (error: any) {
      console.error(error);
      message.error(
        error?.response?.data?.error?.message ||
          'Failed to update producer details.Please try again'
      );
    } finally {
      setModalVisible((prev) => ({
        ...prev,
        notOnboardedLoading: false,
      }));
    }
  };

  const handleCheckNPN = async (values: any, watchlistReason?: boolean) => {
    let alreadyOnboarded: DropdownDrlp;
    if (!bearerToken) return;
    try {
      const npn = values?.npn;

      const alreadyAssignedAsDrlp = options
        ?.filter((data) => data.selected)
        ?.find((d) => d.npn === npn || d.drlpNpn === npn);

      if (alreadyAssignedAsDrlp && alreadyAssignedAsDrlp?.isOnboarded) {
        message.error(
          'This producer is already assigned as designated responsible licensed producer for this license class.'
        );
        return;
      }

      alreadyOnboarded = options?.filter(
        (d) => (d.drlpNpn === npn || d.npn === npn) && d?.ssn && d?.isOnboarded
      )?.[0];
      if (alreadyOnboarded) {
        onUpdateDropDown({ ...alreadyOnboarded, selected: true });
        setModalVisible((prev) => ({
          ...prev,
          npnCheck: false,
        }));
        return;
      }

      setModalVisible((prev) => ({
        ...prev,
        ...{ npnCheckLoading: true },
      }));
      const response =
        await AgentService.onboardIndividualProducerForDrlpAssignment(
          {
            npn,
            lookupType: ProducerOnboardingLookupType.LOOKUP_NPN,
            ignoreWatchlistWarning: !!watchlistReason,
            producerEmailAddress: values?.email,
          },
          stateCode,
          bearerToken
        );

      if (response) {
        if (response?.data?.watchListReason) {
          setWatchlistModalVisible(response.data?.watchListReason);
          return;
        }
        // npn was onboarded successfully
        if (alreadyAssignedAsDrlp && response?.data?.couldNotBeOnboarded) {
          message.error(
            'This producer is already assigned as designated responsible licensed producer for this license class.'
          );
          return;
        }
        if (!response?.data?.couldNotBeOnboarded)
          onAddNewProducer(response.data);
        const producerName = response?.data?.existingProducerDetails?.name;
        if (alreadyAssignedAsDrlp) setAssignedNotOnboarded(true);
        if (
          alreadyAssignedAsDrlp &&
          producerName &&
          alreadyAssignedAsDrlp?.ssn
        ) {
          onUpdateDropDown(
            {
              ...alreadyAssignedAsDrlp,
              isOnboarded: !response?.data?.couldNotBeOnboarded,
              name: producerName,
            },
            DRLP_OPTIONS_CTA.MODIFY
          );
          setModalVisible((prev) => ({
            ...prev,
            npnCheck: false,
            npnCheckLoading: false,
          }));
          return;
        }

        if (!response.data.containsValidLicense) {
          if (response.data.couldNotBeOnboarded) {
            message.error(
              `Producer does not contain active licenses. so can't be added as DRLP`
            );
          } else if (response.data.message === 'Onboarding Unsuccessful') {
            message.error('Producer is already a part of another agency');
          } else {
            message.error(
              "Producer is Onboarded.But does not contain active licenses. so can't be added as DRLP"
            );
          }
          return;
        }

        // agent is onboarded in other agency but need to add as drlp for this agency
        if (response.data?.couldNotBeOnboarded) {
          message.error('Producer is already a part of another agency');
          const producerData = response?.data?.existingProducerDetails;
          // if all details present don't need to show the modal, just add it as drlp
          if (
            (producerData?.npn || npn) &&
            producerData?.name?.firstName &&
            producerData?.ssn
          ) {
            onUpdateDropDown(
              {
                stateCode,
                agentId: response?.data?.producerId,
                isNewlyOnboardedAgent: true,
                name: producerData?.name,
                selected: true,
                isOnboarded: false,
                isNewDrlpAdded: true,
                npn: producerData?.npn || npn,
                ssn: producerData?.ssn,
                id: response?.data?.producerId,
              },
              DRLP_OPTIONS_CTA.ADD
            );
            setModalVisible((prev) => ({
              ...prev,
              npnCheck: false,
              npnCheckLoading: false,
            }));
            return;
          }
          // else
          setNpnResp({
            ...(producerData || {}),
            id: response?.data?.producerId,
            agentId: response?.data?.producerId,
            isOnboarded: false,
          });
          setDisableFields({
            agentNpn: producerData?.npn,
            agentName: {
              firstName: producerData?.name?.firstName,
              middleName: producerData?.name?.middleName,
              lastName: producerData?.name?.lastName,
            },
            agentSsn: producerData?.ssn,
          });
          alreadyExistingAgentForm.setFields([
            {
              name: 'firstName',
              value: producerData?.name?.firstName,
            },
            {
              name: 'middleName',
              value: producerData?.name?.middleName,
            },
            {
              name: 'lastName',
              value: producerData?.name?.lastName,
            },
            {
              name: 'npn',
              value: producerData?.npn || npn,
            },
            {
              name: 'ssn',
              value: producerData?.ssn,
            },
          ]);
          setModalVisible((prev) => ({
            ...prev,
            npnCheck: false,
            npnCheckLoading: false,
            onBoarded: true,
          }));
          return;
        }

        if (!response?.data?.isNewAgent) {
          // existing agent
          onUpdateDropDown(
            {
              // drlpNpn:
              //   currentDrlps?.npn || values?.npn || currentDrlps?.drlpNpn, // required
              stateCode,
              // drlpLicenseId: resp?.licenseId,
              // drlpLicenseNumber: resp?.licenseNumber, // required
              // hasAppliedApplications: false, // required
              // agencyId: currentDrlps?.agencyId, // not require
              // firmNpn: currentDrlps?.firmNpn, // not require
              // firmLicenseNumber: currentDrlps?.firmLicenseNumber, // not require
              // firmLicenseClass: currentDrlps?.firmLicenseClass, // not require
              // dateBegin: currentDrlps?.dateBegin, // not required
              agentId: response?.data?.producerId,
              isNewlyOnboardedAgent: true,
              name: response?.data?.existingProducerDetails?.name,
              selected: true,
              isOnboarded: true,
              isNewDrlpAdded: true,
              id: response?.data?.producerId,
              npn: response?.data?.existingProducerDetails?.npn,
              ssn: response?.data?.existingProducerDetails?.ssn,
            },
            DRLP_OPTIONS_CTA.ADD
          );
          setModalVisible((prev) => ({
            ...prev,
            npnCheck: false,
            npnCheckLoading: false,
          }));
          return;
        } else {
          const producerDetails = await AgentService.getProducerDetailsById(
            response.data.producerId,
            bearerToken
          );
          setNpnResp({ ...producerDetails, isOnboarded: true });
          const producerData = producerDetails?.data;
          setDisableFields({
            agentNpn: producerDetails?.data.npn || npn,
            phoneNo: producerDetails?.data?.businessPhone,
            firstName: producerData?.name?.firstName,
            middleName: producerData?.name?.middleName,
            lastName: producerData?.name?.lastName,
            agentData: {
              email: producerData?.contactEmail,
            },
          });
          newAgentForm.setFields([
            {
              name: 'firstName',
              value: producerData?.name?.firstName,
            },
            {
              name: 'middleName',
              value: producerData?.name?.middleName,
            },
            {
              name: 'lastName',
              value: producerData?.name?.lastName,
            },
            {
              name: 'npn',
              value: producerData?.npn || npn,
            },
            {
              name: 'ssn',
              value: producerData?.ssn,
            },
            {
              name: 'email',
              value: producerData?.contactEmail,
            },
            {
              name: 'phone',
              value: producerData?.businessPhone,
            },
          ]);
          setModalVisible((prev) => ({
            ...prev,
            notOnboarded: true,
            npnCheck: false,
            npnCheckLoading: false,
            notOnboardedFetching: false,
          }));
        }
        // const response = await LicensesService.getCheckNpnForOnboardingDrlp(
        //   bearerToken,
        //   {
        //     npn,
        //     stateCode,
        //   }
        // );
        // setNpnResp(response);

        // if (!response.canBeOnboarded) {
        //   if (!response.containsValidLicense && !isNewAgent) {
        //     message.error(
        //       "Agent does not contain active licenses. so can't be added as drlp"
        //     );
        //     return;
        //   } else {
        //     setDisableFields({
        //       ...response,
        //       agentNpn: response?.agentNpn || npn,
        //     });
        //     alreadyExistingAgentForm.setFields([
        //       {
        //         name: 'firstName',
        //         value: response?.agentName?.firstName,
        //       },
        //       {
        //         name: 'middleName',
        //         value: response?.agentName?.middleName,
        //       },
        //       {
        //         name: 'lastName',
        //         value: response?.agentName?.lastName,
        //       },
        //       {
        //         name: 'npn',
        //         value: response?.agentNpn || npn,
        //       },
        //       {
        //         name: 'ssn',
        //         value: response?.agentSsn,
        //       },
        //     ]);
        //   }
        // } else {
        //   if (!response.containsValidLicense && !isNewAgent) {
        //     message.error(
        //       "Agent does not contain active licenses. so can't be added as drlp"
        //     );
        //   }
        //   const entityInfo =
        //     response?.agentDetails?.entityInfo?.producer?.entityBiographic
        //       ?.biographic;
        //   if (response?.agentDetails?.gender) {
        //     setSelectedType({
        //       value: response?.agentDetails?.gender,
        //       isNiprUpdated: true,
        //     });
        //   }
        //   setDisableFields({
        //     ...response,
        //     ...entityInfo,
        //     agentNpn: response?.agentNpn || npn,
        //     phoneNo: response?.agentDetails?.phone || entityInfo?.phone,
        //   });
        //   newAgentForm.setFields([
        //     {
        //       name: 'firstName',
        //       value: entityInfo?.firstName,
        //     },
        //     {
        //       name: 'middleName',
        //       value: entityInfo?.middleName,
        //     },
        //     {
        //       name: 'lastName',
        //       value: entityInfo?.lastName,
        //     },
        //     {
        //       name: 'npn',
        //       value: entityInfo?.agentNpn || npn,
        //     },
        //     {
        //       name: 'ssn',
        //       value: response?.agentDetails?.ssn,
        //     },
        //     {
        //       name: 'email',
        //       value: response?.agentDetails?.email,
        //     },
        //     {
        //       name: 'phone',
        //       value: response?.agentDetails?.phone || entityInfo?.phone,
        //     },
        //   ]);
        // }
        // setModalVisible((prev) => ({
        //   ...prev,
        //   notOnboarded: response?.canBeOnboarded,
        //   npnCheck: false,
        //   onBoarded: !response?.canBeOnboarded,
        //   ...(!isNewAgent
        //     ? { npnCheckLoading: false }
        //     : { notOnboardedFetching: false }),
        // }));
        // setContainsValidLicense(response.containsValidLicense);
      }
    } catch (error: any) {
      console.error(error);
      message.error(
        error?.response?.data?.message ||
          'Failed to onboard npn.Please try again'
      );
    } finally {
      setModalVisible((prev) => ({
        ...prev,
        ...{ npnCheckLoading: false },
      }));
    }
  };

  return (
    <>
      {/* <div style={{ width: '455px' }}> */}
      <Select
        popupClassName="custom-drlp-dropdown"
        className="dropdown-background"
        value={null}
        {...(modalVisible?.npnCheck ? { open: false } : {})}
        onChange={handleChange}
        placeholder="Select DRLP's"
        dropdownRender={(menu) => (
          <>
            <Button
              type="text"
              icon={<PlusOutlined />}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#FAFDFF',
                borderBottom: '1px solid #DAF2FF',
                padding: '12px',
              }}
              onClick={handleButtonClick}
            >
              Add new DRLP
            </Button>
            <div style={{ backgroundColor: '#F5F6F7' }}>{menu}</div>
          </>
        )}
        style={{
          width: '100%',
          height: '36px',
          borderRadius: '6px',
          backgroundColor: '#F5F6F7',
          // padding: '0 16px',
          display: 'flex',
          alignItems: 'center',
          // justifyContent: 'space-between',
          border: '1px solid #00A6FB',
        }}
        dropdownStyle={{
          // borderRadius: '0 0 6px 6px',
          border: '1px solid #DAF2FF',
        }}
      >
        {options
          ?.filter((data) => !data.selected && data.isOnboarded)
          ?.map((data) => {
            return (
              <Option
                id={data?.id}
                value={data?.id}
                style={{
                  backgroundColor: '#FAFDFF',
                  borderBottom: '1px solid #DAF2FF',
                  padding: '10px 22px',
                }}
              >
                {getAssignedDrlp(data)}
              </Option>
            );
          })}
      </Select>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 12 }}>
        {options
          ?.filter((data) => data.selected)
          ?.map((data) => (
            <LicensedProducer
              text={getAssignedDrlp(data)}
              notClosable={false}
              onClose={() => onClose(data.id)}
            />
          ))}
      </div>

      <Modal
        title={<div style={{ marginLeft: '17px' }}>Add new DRLP</div>}
        open={modalVisible.npnCheck}
        onCancel={handleModalCancel}
        closable={!modalVisible.npnCheckLoading}
        footer={null}
        centered
        width={668}
        bodyStyle={{
          height: '290px',
          borderRadius: '6px',
          // gap: '15px',
        }}
      >
        <div
          style={{
            width: '584px',
            height: 'fit-content',
            // gap: '22px',
            backgroundColor: '#FFFFFF',
            padding: '0 20px',
          }}
        >
          <Form onFinish={handleCheckNPN} form={npnForm} layout="vertical">
            <Form.Item
              label={
                <h1
                  style={{
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#222222',
                  }}
                >
                  NPN
                </h1>
              }
              name="npn"
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid NPN Number',
                  pattern: NPN_REGEX,
                },
              ]}
            >
              <Input
                style={{
                  width: '584px',
                  height: '36px',
                  borderRadius: '6px',
                  padding: '0px 16px',
                  backgroundColor: '#F5F6F7',
                  border: '1px solid #CCCCCC',
                  boxSizing: 'border-box',
                  fontSize: '14px',
                  color: '#333333',
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <h1
                  style={{
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#222222',
                  }}
                >
                  Email
                </h1>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid email',
                  pattern: EMAIL_REGEX,
                },
              ]}
            >
              <Input
                style={{
                  width: '584px',
                  height: '36px',
                  borderRadius: '6px',
                  padding: '0px 16px',
                  backgroundColor: '#F5F6F7',
                  border: '1px solid #CCCCCC',
                  boxSizing: 'border-box',
                  fontSize: '14px',
                  color: '#333333',
                }}
              />
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                }}
              >
                <Button
                  loading={modalVisible.npnCheckLoading}
                  htmlType="submit"
                  style={{
                    width: '183px',
                    height: '36px',
                    borderRadius: '8px',
                    padding: '12px 24px',
                    backgroundColor: '#001F45',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    cursor: 'pointer',
                    marginTop: '20px',
                    alignSelf: 'flex-end',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: '#FFFFFF',
                    }}
                  >
                    Onboard NPN
                  </span>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        open={modalVisible.onBoarded}
        onCancel={handleModalCancel}
        centered
        closable={!modalVisible.onBoardedLoading}
        width={668}
        footer={null}
      >
        <h1
          style={{
            width: 101,
            height: 21,
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '21px',
            color: '#222222',
          }}
        >
          Add New DRLP
        </h1>
        <p
          style={{
            width: 584,
            height: 40,
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '20px',
            color: '#848484',
            marginBottom: '20px',
          }}
        >
          It looks like this NPN is already a part of another agency in
          Insuretrek, and can't be added to your team. But, you can still use it
          as a DRLP by filling out the information below.
        </p>
        <Form
          layout="vertical"
          onFinish={handleAddExistingDRLP}
          form={alreadyExistingAgentForm}
          style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          <div style={{ display: 'flex', gap: '40px' }}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                { required: true, message: 'Please enter the first name' },
              ]}
            >
              <Input
                disabled={disableFields?.agentName?.firstName}
                style={{ backgroundColor: '#F5F6F7' }}
              />
            </Form.Item>
            <Form.Item name="middleName" label="Middle Name">
              <Input
                disabled={disableFields?.agentName?.middleName}
                style={{ backgroundColor: '#F5F6F7' }}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                { required: true, message: 'Please enter the last name' },
              ]}
            >
              <Input
                disabled={disableFields?.agentName?.lastName}
                style={{ backgroundColor: '#F5F6F7' }}
              />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', gap: '40px' }}>
            <Form.Item
              name="npn"
              label="NPN"
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid NPN Number',
                  pattern: !disableFields?.agentNpn ? NPN_REGEX : undefined,
                },
              ]}
            >
              <Input
                disabled={disableFields?.agentNpn}
                style={{ backgroundColor: '#F5F6F7' }}
              />
            </Form.Item>
            <Form.Item
              name="ssn"
              label="SSN"
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid SSN Number',
                },
                {
                  pattern: !disableFields?.agentSsn ? SSN_REGEX : undefined,
                  message: 'Please enter a valid SSN',
                },
                // {
                //   validator: (_, value) =>
                //     value && value.length === 9
                //       ? Promise.resolve()
                //       : Promise.reject(new Error('SSN needs to be 9 digits')),
                // },
              ]}
            >
              <Input
                maxLength={9}
                disabled={disableFields?.agentSsn}
                style={{ backgroundColor: '#F5F6F7' }}
              />
            </Form.Item>
          </div>

          <Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                // marginTop: '20px',
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={modalVisible.onBoardedLoading}
                style={{
                  width: '183px',
                  height: '36px',
                  borderRadius: '8px',
                  padding: '12px 24px',
                  backgroundColor: '#001F45',
                  color: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                }}
              >
                Add DRLP
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={modalVisible.notOnboarded}
        onCancel={handleModalCancel}
        centered
        closable={!modalVisible.notOnboardedLoading}
        width={668}
        footer={null}
      >
        <h1
          style={{
            width: 101,
            height: 21,
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '21px',
            color: '#222222',
          }}
        >
          Add New DRLP
        </h1>
        <p
          style={{
            width: 584,
            height: 40,
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '20px',
            color: '#848484',
            marginBottom: '20px',
          }}
        >
          In order to use this NPN as a DRLP, we need to add it to Insuretrek as
          well. Please fill out this information to add this NPN to your team.
        </p>
        {modalVisible.notOnboardedFetching ? (
          <div
            style={{ display: 'grid', minHeight: 420, placeContent: 'center' }}
          >
            <Spin />
          </div>
        ) : (
          <Form
            layout="vertical"
            form={newAgentForm}
            onFinish={hanldeUpdateProducerDetails}
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <div style={{ display: 'flex', gap: '40px' }}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: 'Please enter the first name' },
                ]}
                style={{ width: '100%' }}
              >
                <Input
                  disabled={disableFields?.firstName}
                  style={{ backgroundColor: '#F5F6F7' }}
                />
              </Form.Item>
              <Form.Item
                name="middleName"
                label="Middle Name"
                style={{ width: '100%' }}
              >
                <Input
                  disabled={disableFields?.middleName}
                  style={{ backgroundColor: '#F5F6F7' }}
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: 'Please enter the last name' },
                ]}
                style={{ width: '100%' }}
              >
                <Input
                  disabled={disableFields?.lastName}
                  style={{ backgroundColor: '#F5F6F7' }}
                />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', gap: '40px' }}>
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  { required: true, message: 'Please enter email address' },
                  { type: 'email', message: 'Invalid email address' },
                ]}
                style={{ width: '100%' }} // Ensure the Form.Item takes full width
              >
                <Input
                  disabled={disableFields?.agentDetails?.email}
                  style={{ width: '100%', backgroundColor: '#F5F6F7' }}
                />
              </Form.Item>

              <Form.Item
                name="phone"
                label="Phone number"
                rules={[
                  { required: true },
                  () => ({
                    validator(_, value) {
                      const numericValue = value.replace(/\D/g, '');
                      const formattedValue = formatPhoneAndFax(numericValue);
                      if (!value || isPhoneNumberValid(formattedValue)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'Please enter a valid phone number'
                      );
                    },
                  }),
                ]}
                style={{ width: '100%' }}
              >
                <Input
                  maxLength={10}
                  disabled={disableFields?.phoneNo}
                  style={{ backgroundColor: '#F5F6F7' }}
                />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', gap: '40px' }}>
              <Form.Item
                name="npn"
                label="NPN"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid NPN Number',
                    pattern: NPN_REGEX,
                  },
                ]}
                style={{ width: '100%' }}
              >
                <Input
                  disabled={disableFields?.agentNpn}
                  style={{ backgroundColor: '#F5F6F7' }}
                />
              </Form.Item>
              <Form.Item
                name="ssn"
                label="SSN"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid SSN Number',
                  },
                  {
                    pattern: !disableFields?.agentDetails?.ssn
                      ? SSN_REGEX
                      : undefined,
                    message: 'Please enter a valid SSN Number',
                  },
                  // {
                  //   validator: (_, value) =>
                  //     value && value.length === 9
                  //       ? Promise.resolve()
                  //       : Promise.reject(new Error('SSN needs to be 9 digits')),
                  // },
                ]}
                style={{ width: '100%' }}
              >
                <Input
                  maxLength={9}
                  disabled={disableFields?.agentDetails?.ssn}
                  style={{ backgroundColor: '#F5F6F7' }}
                />
              </Form.Item>
            </div>

            <div
              style={{
                width: '584px',
                height: '77px',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <div
                style={{
                  width: '53px',
                  height: '24px',
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: '#222222',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Gender
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 18,
                  marginLeft: '30px',
                }}
              >
                {GENDER_TYPE?.map((data) => (
                  <div
                    key={data}
                    className="cursor-pointer"
                    style={{
                      padding: '8px 12px',
                      border: `2px solid ${
                        data === selectedType.value
                          ? 'var(--primary-color)'
                          : '#F7FAFC'
                      }`,
                      borderRadius: 6,
                      margin: '3px 0 24px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: 300,
                      backgroundColor: '#F5F6F7',
                      cursor: selectedType.isNiprUpdated
                        ? 'not-allowed'
                        : 'default',
                    }}
                    onClick={() => {
                      if (!selectedType.isNiprUpdated) {
                        setSelectedType((prev) => ({ ...prev, value: data }));
                      }
                    }}
                  >
                    {data}
                    <span style={{ display: 'block' }}>
                      <Radio checked={data === selectedType.value} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={modalVisible.notOnboardedLoading}
                  style={{
                    width: '303px',
                    height: '36px',
                    borderRadius: '8px',
                    padding: '12px 24px',
                    backgroundColor: '#001F45',
                    color: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  Update Producer Details
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </Modal>
      <Modal
        title="Watchlisted Producer"
        open={!!watchlistModalVisible}
        onOk={() => {
          handleCheckNPN(npnForm.getFieldsValue(), true);
          setWatchlistModalVisible('');
        }}
        onCancel={() => {
          setWatchlistModalVisible('');
        }}
        okText="Confirm"
      >
        {watchlistModalVisible}
      </Modal>
    </>
  );
};

export default observer(CustomDropdown);
