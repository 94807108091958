import { Button, Card, Form, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { AddPaymentOptionModal } from './addPaymentOptionModal';
import { Address } from '../../../types/data/address.type';
import { ExceptionName } from '../../../enums/exceptionName.enum';
import { MailOutlined } from '@ant-design/icons';
import { Name } from '../../../types/data/name.type';
import { SignupService } from '../../../services/signup.service';
import dayjs from 'dayjs';
import { getAddressLine } from '../../../pages/agents/onboardAgents/individualOnboardV2/IndividualOnboardHelpers';
import { getDefaultAddress } from '../../../utils/address.utils';
import location from '../../../assets/icons/locationPin.svg';
import { notification } from 'antd';
import { useAuth } from '../../authProvider';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const contactIconItemStyle = {
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '20px',
  color: '#1D2C50',
  display: 'flex',
  alignItems: 'center',
};

interface Details {
  npn: string;
  name: Name | string;
  email: string;
  businessEmail: string;
  businessPhone: string;
  fax: string;
  ssn?: string;
  dateOfBirth?: Date;
  gender?: String;
  businessAddress: Address;
  mailingAddress: Address;
  residenceAddress: Address;
  residentState: string;
  stateAddresses?: { stateCode: string; addresses: Address[] }[];
  fein?: string;
}

export interface UserData {
  agency?: Details;
  producer?: Details;
}

export const VerifyDetails = () => {
  const [form] = Form.useForm();
  const [producerDetails, setProducerDetails] = useState<Details>();
  const [agencyDetails, setAgencyDetails] = useState<Details>();
  const [agencyDetailsEnabled, setAgencyDetailsEnabled] =
    useState<boolean>(false);
  const [producerDetailsEnabled, setProducerDetailsEnabled] =
    useState<boolean>(false);
  const [bothAgencyandProducer, setBothAgencyAndProducer] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stripeLinkFromResponse, setStripeLinkFromResponse] =
    useState<string>('');

  const handleConfirm = () => {
    if (stripeLinkFromResponse) {
      window.location.href = stripeLinkFromResponse;
    }
  };

  const onFinish = async () => {
    const business = agencyDetailsEnabled
      ? agencyDetails?.businessAddress || getDefaultAddress()
      : producerDetails?.businessAddress || getDefaultAddress();

    const residence = agencyDetailsEnabled
      ? agencyDetails?.residenceAddress || getDefaultAddress()
      : producerDetails?.residenceAddress || getDefaultAddress();

    const mailing = agencyDetailsEnabled
      ? agencyDetails?.mailingAddress || getDefaultAddress()
      : producerDetails?.mailingAddress || getDefaultAddress();

    const updatedDetails: Details = {
      npn: npn || '',
      fein: agencyDetails?.fein,
      name: agencyDetailsEnabled
        ? agencyDetails?.name || ''
        : {
            firstName:
              typeof producerDetails?.name === 'object'
                ? producerDetails?.name?.firstName
                : producerDetails?.name || '',
            lastName:
              typeof producerDetails?.name === 'object'
                ? producerDetails?.name?.lastName
                : producerDetails?.name || '',
          },
      email: producerDetails?.email || '',
      businessEmail: businessEmail || '',
      businessPhone: producerDetails?.businessPhone || '',
      fax: fax || '',
      ...(producerDetailsEnabled
        ? {
            ssn: producerDetails?.ssn,
            dateOfBirth: producerDetails?.dateOfBirth
              ? producerDetails?.dateOfBirth
              : new Date(),
            gender: producerDetails?.gender,
          }
        : {}),

      residentState: agencyDetails
        ? agencyDetails.residentState
        : producerDetails?.residentState || '',
      businessAddress: {
        addressLine1: business.addressLine1,
        addressLine2: business.addressLine2,
        addressLine3: business.addressLine3,
        city: business.city,
        state: business.state,
        zip: business.zip,
        country: business.country,
      },
      mailingAddress: {
        addressLine1: mailing.addressLine1,
        addressLine2: mailing.addressLine2,
        addressLine3: mailing.addressLine3,
        city: mailing.city,
        state: mailing.state,
        zip: mailing.zip,
        country: mailing.country,
      },
      residenceAddress: {
        addressLine1: residence.addressLine1,
        addressLine2: residence.addressLine2,
        addressLine3: residence.addressLine3,
        city: residence.city,
        state: residence.state,
        zip: residence.zip,
        country: residence.country,
      },
    };
    try {
      if (bothAgencyandProducer) {
        if (await verifyDetails({ agencyDetails: updatedDetails })) {
          setBothAgencyAndProducer(false);
          setAgencyDetailsEnabled(false);
          setProducerDetailsEnabled(true);
          setAgencyDetails(updatedDetails);
        }
      } else if (agencyDetailsEnabled) {
        if (await verifyDetails({ agencyDetails: updatedDetails }, true)) {
          setAgencyDetails(updatedDetails);
        }
      } else if (producerDetailsEnabled) {
        if (await verifyDetails({ producerDetails: updatedDetails }, true)) {
          setProducerDetails(updatedDetails);
        }
      }
    } catch (error) {
      console.error('Verification failed:', error);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [agencyDetails, producerDetails]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (!token) return;
      const userInfo: UserData = await SignupService.getDetails(token);
      if (userInfo.agency && userInfo.producer) {
        setBothAgencyAndProducer(true);
        setAgencyDetailsEnabled(true);
        setAgencyDetails(userInfo.agency);
        setProducerDetails(userInfo.producer);
      } else if (userInfo.agency) {
        setAgencyDetailsEnabled(true);
        setAgencyDetails(userInfo.agency);
      } else if (userInfo.producer) {
        setProducerDetailsEnabled(true);
        setProducerDetails(userInfo.producer);
      }
    } catch (error) {
      console.error('Error occurred while verifying details:', error);
    } finally {
      setLoading(false);
    }
  };

  const verifyDetails = async (
    requestBody: { agencyDetails?: Details; producerDetails?: Details },
    OnboardingStatus?: boolean
  ) => {
    setLoading(true);
    // if (requestBody.agencyDetails) {
    // requestBody.agencyDetails.stateAddresses = [
    //   {
    //     stateCode: requestBody.agencyDetails.residentState,
    //     addresses: [],
    //   },
    // ];
    // if (
    //   Object.values(requestBody.agencyDetails.businessAddress).filter(
    //     (itr) => !!itr
    //   ).length > 0
    // )
    //   requestBody.agencyDetails.stateAddresses[0].addresses.push({
    //     ...requestBody.agencyDetails.businessAddress,
    //     addressTypeCode: '2',
    //     addressType: 'Business',
    //   });
    // if (
    //   Object.values(requestBody.agencyDetails.mailingAddress).filter(
    //     (itr) => !!itr
    //   ).length > 0
    // )
    //   requestBody.agencyDetails.stateAddresses[0].addresses.push({
    //     ...requestBody.agencyDetails.mailingAddress,
    //     addressTypeCode: '3',
    //     addressType: 'Mailing',
    //   });
    // if (
    //   Object.values(requestBody.agencyDetails.residenceAddress).filter(
    //     (itr) => !!itr
    //   ).length > 0
    // )
    //   requestBody.agencyDetails.stateAddresses[0].addresses.push({
    //     ...requestBody.agencyDetails.residenceAddress,
    //     addressTypeCode: '1',
    //     addressType: 'Residence',
    //   });
    // }
    try {
      const token = await getAccessTokenSilently();
      const requestPayload: {
        entityType: 'agency' | 'producer';
        agency?: any;
        producer?: any;
      } = {
        entityType: requestBody.agencyDetails ? 'agency' : 'producer',
      };
      if (requestBody.agencyDetails)
        requestPayload.agency = requestBody.agencyDetails;
      else if (requestBody.producerDetails)
        requestPayload.producer = requestBody.producerDetails;
      if (!token) return;
      const response = await SignupService.VerifyDetails(
        token,
        requestPayload,
        OnboardingStatus
      );
      if (response && !bothAgencyandProducer) {
        if (response?.data?.response)
          setStripeLinkFromResponse(response?.data?.response);
        else setStripeLinkFromResponse(response.data);
        setIsModalVisible(true);
      }
      return true;
    } catch (error: any) {
      console.error('Error :: verifyDetails ::', error);
      if (
        error?.response?.data?.error?.exceptionName ===
          ExceptionName.DUPLICATE_VALUE_EXCEPTION &&
        error?.response?.data?.error?.key === 'email'
      ) {
        notification.error({
          message: 'Duplicate Account Detected',
          description:
            'An account with this email address already exists. Please use a different email address.',
        });
        form.scrollToField('contactEmail', {
          behavior: 'smooth',
          block: 'center',
        });
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleFinishFailed = ({ errorFields }: any) => {
    notification.error({
      message: 'Incomplete Form Submission',
      description: 'Please fill in the required fields.',
    });

    // Optional: Highlight the first error field
    if (errorFields.length > 0) {
      form.scrollToField(errorFields[0].name, {
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const firstName = producerDetailsEnabled
    ? typeof producerDetails?.name === 'object'
      ? producerDetails?.name?.firstName
      : ''
    : typeof agencyDetails?.name === 'string'
    ? agencyDetails?.name || ''
    : '';

  const lastName = producerDetailsEnabled
    ? typeof producerDetails?.name === 'object'
      ? producerDetails?.name?.lastName
      : ''
    : null;

  const businessEmail = producerDetailsEnabled
    ? producerDetails?.businessEmail
    : agencyDetails?.businessEmail;

  const npn = producerDetailsEnabled
    ? producerDetails?.npn
    : agencyDetails?.npn;

  const fax = producerDetailsEnabled
    ? producerDetails?.fax
    : agencyDetails?.fax;

  const businessPhone = producerDetailsEnabled
    ? producerDetails?.businessPhone
    : agencyDetails?.businessPhone;

  const getAddresses = () => {
    const addresses = [];

    const business = agencyDetailsEnabled
      ? agencyDetails?.businessAddress || getDefaultAddress()
      : producerDetails?.businessAddress || getDefaultAddress();
    addresses.push({
      title: 'Business address',
      ...getAddressLine(business),
    });

    const residence = agencyDetailsEnabled
      ? agencyDetails?.mailingAddress || getDefaultAddress()
      : producerDetails?.residenceAddress || getDefaultAddress();

    addresses.push({
      title: agencyDetailsEnabled ? 'Mailing address' : 'Residence address',
      ...getAddressLine(residence),
    });

    return addresses;
  };

  return (
    <Spin spinning={loading}>
      <div
        style={{
          color: '#222222',
          fontFamily: 'Poppins',
          fontSize: '18px',
          lineHeight: '27px',
          fontWeight: 600,
          textAlign: 'center',
        }}
      >
        Your Info
      </div>
      <Card className="signup-card" style={{ margin: '16px 0' }}>
        <div
          style={{
            fontFamily: 'Poppins',
            padding: '16px 20px 16px 22px',
            backgroundColor: '#EDF9FF',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 16,
              alignItems: 'center',
            }}
          >
            {' '}
            <div
              style={{
                width: 78,
                height: 78,
                borderRadius: '50%',
                display: 'grid',
                placeContent: 'center',
                backgroundColor: '#B5E6FF',
                fontSize: 32,
                color: '#0074B0',
                fontWeight: 'bolder',
              }}
            >
              {firstName?.[0] || ''}
              {lastName?.[0] || ''}
            </div>
            <div style={{ marginBottom: 12 }}>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: '500',
                  wordBreak: 'break-word',
                  lineHeight: '24px',
                  color: '#222222',
                  display: 'flex',
                  gap: 16,
                  alignItems: 'center',
                }}
              >
                {firstName || ''} {lastName || ''}{' '}
              </div>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: '400',
                  lineHeight: '20px',
                  marginTop: 4,
                  color: '#222222',
                  fontFamily: 'Poppins',
                }}
              >
                NPN: {npn}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            fontFamily: 'Poppins',
            padding: '0 20px 16px 22px',
          }}
        >
          <div
            style={{
              fontWeight: 500,
              fontSize: 12,
              lineHeight: '24px',
              color: '#222222',
              padding: '12px 0 6px',
            }}
          >
            Contact Details
          </div>
          <div
            style={{ display: 'flex', columnGap: 24 }}
            className="overide-icon-size"
          >
            <div style={{ ...contactIconItemStyle }}>
              <MailOutlined style={{ marginRight: 3 }} />{' '}
              {businessEmail || <span style={{ marginLeft: 6 }}> - </span>}
            </div>
            <div style={{ ...contactIconItemStyle }}>
              <svg
                style={{ marginRight: 3 }}
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.24371 5.74054C4.868 6.83834 5.7808 7.75114 6.8786 8.37541L7.46827 7.54987C7.66433 7.27547 8.0344 7.19067 8.3304 7.35234C9.26827 7.86467 10.3047 8.17601 11.3859 8.26161C11.7327 8.28907 12 8.57841 12 8.92621V11.9014C12 12.2432 11.7415 12.5296 11.4015 12.5645C11.0482 12.6009 10.6918 12.6191 10.3333 12.6191C4.62639 12.6191 0 7.99274 0 2.28581C0 1.92732 0.0182801 1.57095 0.0545934 1.21765C0.0895334 0.877641 0.37596 0.619141 0.717767 0.619141H3.69295C4.04073 0.619141 4.33009 0.886501 4.35753 1.2332C4.44311 2.31439 4.75447 3.3509 5.2668 4.28877C5.42847 4.58474 5.34367 4.95483 5.06927 5.15085L4.24371 5.74054ZM2.56283 5.30261L3.82947 4.39787C3.47031 3.62257 3.22419 2.80036 3.09818 1.95247H1.33938C1.33535 2.06335 1.33333 2.17447 1.33333 2.28581C1.33333 7.25634 5.3628 11.2858 10.3333 11.2858C10.4447 11.2858 10.5558 11.2838 10.6667 11.2797V9.52094C9.8188 9.39494 8.9966 9.14881 8.22127 8.78967L7.31653 10.0563C6.95053 9.91414 6.59707 9.74681 6.25827 9.55654L6.21953 9.53447C4.91313 8.79161 3.82756 7.70601 3.08467 6.39961L3.06263 6.36087C2.87233 6.02207 2.70503 5.66861 2.56283 5.30261Z"
                  fill="black"
                />
              </svg>{' '}
              {businessPhone || <span style={{ marginLeft: 6 }}> - </span>}
            </div>
            <div style={{ ...contactIconItemStyle }}>
              <svg
                style={{ marginRight: 3 }}
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.332 0.953125C10.7002 0.953125 10.9987 1.2516 10.9987 1.61979V4.28646H12.9987C13.3669 4.28646 13.6654 4.58494 13.6654 4.95312V11.6198C13.6654 11.988 13.3669 12.2865 12.9987 12.2865H10.9987V13.6198C10.9987 13.988 10.7002 14.2865 10.332 14.2865H3.66536C3.29718 14.2865 2.9987 13.988 2.9987 13.6198V12.2865H0.998698C0.630511 12.2865 0.332031 11.988 0.332031 11.6198V4.95312C0.332031 4.58494 0.630511 4.28646 0.998698 4.28646H2.9987V1.61979C2.9987 1.2516 3.29718 0.953125 3.66536 0.953125H10.332ZM9.66536 10.9531H4.33203V12.9531H9.66536V10.9531ZM12.332 5.61979H1.66536V10.9531H2.9987V10.2865C2.9987 9.91826 3.29718 9.61979 3.66536 9.61979H10.332C10.7002 9.61979 10.9987 9.91826 10.9987 10.2865V10.9531H12.332V5.61979ZM4.33203 6.28646V7.61979H2.33203V6.28646H4.33203ZM9.66536 2.28646H4.33203V4.28646H9.66536V2.28646Z"
                  fill="black"
                />
              </svg>

              {fax || <span style={{ marginLeft: 6 }}> - </span>}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'justify-start',
            gap: 24,
            marginTop: 12,
            padding: '12px 24px',
          }}
          className="signup-address"
        >
          {' '}
          {getAddresses().map((data, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  gap: 6,
                }}
              >
                {' '}
                <div
                  className="font-poppins"
                  style={{
                    color: '#848484',
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  {' '}
                  {data.title}:
                </div>{' '}
                <div
                  className="font-poppins"
                  style={{
                    color: '#0C0C21',
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '16.8px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 12,
                  }}
                >
                  <img src={location} alt="location" />{' '}
                  {!data.addressLine1 ? (
                    <div> - </div>
                  ) : (
                    <div>
                      <div>{data.addressLine1}</div>
                      {data.addressLine2}{' '}
                    </div>
                  )}
                </div>{' '}
              </div>
            );
          })}
        </div>
      </Card>
      <div>
        <p
          style={{
            color: '#A1A1A2',
            fontFamily: 'Poppins',
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 400,
            textAlign: 'center',
            margin: '24px 0',
          }}
        >
          Something not quite right? No worries, you can change it in the app.
        </p>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onFinish}
          style={{
            background: 'var(--primary-color)',
            color: 'white',
            height: '40px',
            margin: '6px 0 0',
            fontSize: '14px',
            lineHeightStep: '20px',
            fontWeight: 400,
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Continue to Subscription{' '}
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: 6 }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 3.38867V10.8887H9.5V8.63867C9.5 8.22446 9.83579 7.88867 10.25 7.88867C10.6642 7.88867 11 8.22446 11 8.63867V11.6387C11 12.0529 10.6642 12.3887 10.25 12.3887H1.25C0.835786 12.3887 0.5 12.0529 0.5 11.6387V2.63867C0.5 2.22446 0.835786 1.88867 1.25 1.88867H4.25C4.66421 1.88867 5 2.22446 5 2.63867C5 3.05289 4.66421 3.38867 4.25 3.38867H2ZM6.28033 7.669C5.98744 7.96189 5.51256 7.96189 5.21967 7.669C4.92678 7.37611 4.92678 6.90123 5.21967 6.60834L9.94524 1.88521H7.2621C6.84884 1.88521 6.51383 1.5502 6.51383 1.13694C6.51383 0.723684 6.84884 0.388672 7.2621 0.388672L11.7517 0.388672C11.9432 0.388672 12.1347 0.461726 12.2808 0.607836C12.4269 0.753945 12.5 0.945444 12.5 1.13694V5.62657C12.5 6.03983 12.165 6.37484 11.7517 6.37484C11.3385 6.37484 11.0035 6.03983 11.0035 5.62657V2.94343L6.28033 7.669Z"
              fill="white"
            />
          </svg>
        </Button>
      </div>
      {/* <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={handleFinishFailed}
        disabled={loading}
        className="onboarding-screen"
      >
        {producerDetailsEnabled && (
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: 'Please enter your first name' },
            ]}
            initialValue={
              typeof producerDetails?.name === 'object'
                ? producerDetails?.name?.firstName
                : ''
            }
            style={{ marginBottom: '10px' }}
          >
            <Input disabled placeholder="First Name" />
          </Form.Item>
        )}
        {producerDetailsEnabled && (
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please enter your last name' }]}
            initialValue={
              typeof producerDetails?.name === 'object'
                ? producerDetails?.name?.lastName
                : ''
            }
            style={{ marginBottom: '10px' }}
          >
            <Input disabled placeholder="Last Name" />
          </Form.Item>
        )}
        {!producerDetailsEnabled && (
          <Form.Item
            label="Agency Name"
            name="name"
            rules={[
              { required: true, message: 'Please enter your agency name!' },
            ]}
            initialValue={
              typeof agencyDetails?.name === 'string' ? agencyDetails.name : ''
            }
            style={{ marginBottom: '10px' }}
          >
            <Input disabled placeholder="Agency Name" />
          </Form.Item>
        )}
        <Form.Item
          label="Email Address"
          name="businessEmail"
          rules={[
            { required: true, message: 'Please enter your email address' },
          ]}
          initialValue={
            producerDetailsEnabled
              ? producerDetails?.businessEmail
              : agencyDetails?.businessEmail
          }
          style={{ marginBottom: '10px' }}
        >
          <Input disabled placeholder="Email Address" />
        </Form.Item>

        {producerDetailsEnabled && (
          <Form.Item
            label="Contact Email"
            name="contactEmail"
            rules={
              // when input field is disable we don't want to make this validation
              producerDetails?.email === '' ||
              producerDetails?.email === undefined ||
              producerDetails?.email === null
                ? [
                    {
                      required: true,
                      message: 'Please enter your email address',
                    },
                    {
                      pattern: EMAIL_REGEX,
                      message: 'The input is not a valid email',
                    },
                    {
                      validator: (_, value) =>
                        /[A-Z]/.test(value)
                          ? Promise.reject(
                              new Error(
                                'Email should not contain capital letters'
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]
                : []
            }
            initialValue={producerDetails?.email}
            style={{ marginBottom: '10px' }}
          >
            <Input
              disabled={
                !(
                  producerDetails?.email === '' ||
                  producerDetails?.email === undefined ||
                  producerDetails?.email === null
                )
              }
              placeholder="Contact Email"
            />
          </Form.Item>
        )}
        <Form.Item
          label="NPN"
          name="npn"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={
            producerDetailsEnabled ? producerDetails?.npn : agencyDetails?.npn
          }
          style={{ marginBottom: '10px' }}
        >
          <Input disabled placeholder="NPN" />
        </Form.Item>

        {producerDetailsEnabled && (
          <>
            <Form.Item
              label="SSN"
              name="ssn"
              rules={
                // making this conditional because we get encrypted ssn when a producer is existing in our system
                !producerDetails?.ssn
                  ? [
                      {
                        required: true,
                        pattern: SSN_REGEX,
                        message: 'Please enter a valid ssn',
                      },
                      // {
                      //   validator: (_, value) =>
                      //     value.length === 9
                      //       ? Promise.resolve()
                      //       : Promise.reject(),
                      //   message: 'SSN needs to be 9 digits',
                      // },
                    ]
                  : []
              }
              initialValue={producerDetails?.ssn}
              style={{ marginBottom: '10px' }}
            >
              <Input.Password
                placeholder="SSN"
                maxLength={9}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={producerDetails?.ssn ? true : false}
                onPaste={(event) => {
                  const pastedText = event.clipboardData.getData('text/plain');
                  if (!/^[0-9]+$/.test(pastedText)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Date of Birth"
              name="dateOfBirth"
              rules={[
                {
                  required: true,
                  message: 'Please select your date of birth',
                },
              ]}
              style={{ marginBottom: '10px' }}
              initialValue={
                dayjs(producerDetailsEnabled && producerDetails?.dateOfBirth) ||
                undefined
              }
            >
              <DatePicker
                disabled={
                  producerDetailsEnabled && producerDetails?.dateOfBirth
                    ? true
                    : false
                }
                format="MM/DD/YYYY"
                disabledDate={(current) =>
                  !current.isBefore(moment().subtract(18, 'years').toDate())
                }
              />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: 'Please select your gender',
                },
              ]}
              initialValue={
                (producerDetailsEnabled && producerDetails?.gender) || ''
              }
            >
              <Select
                placeholder="Select Gender"
                style={{ marginBottom: '10px' }}
                disabled={producerDetails?.gender !== 'Unknown' ? true : false}
              >
                <Option value={GenderType.Male}>Male</Option>
                <Option value={GenderType.Female}>Female</Option>
              </Select>
            </Form.Item>{' '}
          </>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            marginBottom: '10px',
          }}
        >
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  const numericValue = value.replace(/\D/g, '');
                  const formattedValue = formatPhoneAndFax(numericValue);
                  if (!value || isPhoneNumberValid(formattedValue)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Please enter a valid phone number');
                },
              }),
            ]}
            initialValue={
              producerDetailsEnabled
                ? producerDetails?.businessPhone
                : agencyDetails?.businessPhone
            }
            style={{ flex: 1 }}
          >
            <Input
              placeholder="Enter Phone number"
              disabled={
                producerDetailsEnabled
                  ? !!producerDetails?.businessPhone
                  : !!agencyDetails?.businessPhone
              }
              onChange={(e) => {
                const formattedValue = formatPhoneAndFax(e.target.value);
                if (!e.target.value || isPhoneNumberValid(formattedValue)) {
                  form.setFieldsValue({ phone: formattedValue });
                }
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                const pastedText = event.clipboardData.getData('text/plain');
                if (!/^[0-9]+$/.test(pastedText)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Fax"
            name="fax"
            initialValue={
              producerDetailsEnabled ? producerDetails?.fax : agencyDetails?.fax
            }
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) return Promise.resolve();

                  const numericValue = value.replace(/\D/g, '');
                  const formattedValue = formatPhoneAndFax(numericValue);
                  if (isPhoneNumberValid(formattedValue)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Please enter a valid fax');
                },
              }),
            ]}
            style={{ flex: 1 }}
          >
            <Input
              placeholder="Enter FAX Number"
              disabled={
                producerDetailsEnabled
                  ? !!producerDetails?.fax
                  : !!agencyDetails?.fax
              }
              onChange={(e) => {
                const formattedValue = formatPhoneAndFax(e.target.value);
                if (!e.target.value || isPhoneNumberValid(formattedValue)) {
                  form.setFieldsValue({ fax: formattedValue });
                }
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                const pastedText = event.clipboardData.getData('text/plain');
                if (!/^[0-9]+$/.test(pastedText)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
        <AddressInput
          viewOnly={true}
          addressDetails={
            agencyDetailsEnabled
              ? agencyDetails?.businessAddress || getDefaultAddress()
              : producerDetails?.businessAddress || getDefaultAddress()
          }
          addressType="business"
          style={{ width: '100%', display: 'block', marginBottom: '10px' }}
          formInputStyle={{ marginBottom: '10px' }}
        />
        <AddressInput
          viewOnly={true}
          addressDetails={
            agencyDetailsEnabled
              ? agencyDetails?.mailingAddress || getDefaultAddress()
              : producerDetails?.mailingAddress || getDefaultAddress()
          }
          addressType="mailing"
          style={{ width: '100%', display: 'block', marginBottom: '10px' }}
          formInputStyle={{ marginBottom: '10px' }}
        />
        <AddressInput
          viewOnly={true}
          addressDetails={
            agencyDetailsEnabled
              ? agencyDetails?.residenceAddress || getDefaultAddress()
              : producerDetails?.residenceAddress || getDefaultAddress()
          }
          addressType="residence"
          style={{ width: '100%', display: 'block' }}
          formInputStyle={{ marginBottom: '10px' }}
        />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              background: 'var(--primary-color)',
              color: 'white',
              minWidth: '150px',
              height: '40px',
              display: 'block',
              marginTop: '10px',
              fontWeight: 400,
            }}
          >
            Continue to Subscription
          </Button>
        </Form.Item>
      </Form> */}
      <AddPaymentOptionModal
        visible={isModalVisible}
        onConfirm={handleConfirm}
      />
    </Spin>
  );
};
