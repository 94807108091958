import { Checkbox, Tooltip } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { StateDisplayProps } from './agencyLicensingInterfaces.type';
import { states } from './stateConstants';
import { statesWithAffiliation } from './statesWithAffiliationsConst';

const StateDisplay: React.FC<StateDisplayProps> = ({
  setSelectedStates,
  selectedStates,
  prefilledStates,
}) => {
  const handleCheckboxChange = (state: string, checked: boolean) => {
    setSelectedStates((prevSelectedStates) => {
      const newSelectedStates = checked
        ? [...prevSelectedStates, state]
        : prevSelectedStates.filter((s) => s !== state);

      return newSelectedStates;
    });
  };

  // Sort states alphabetically
  const sortedStates = states
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  let currentLetter = '';

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 120px)',
        borderRadius: '6px',
        border: '1px solid #E6E6E6',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        gap: '22px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          columnGap: '14px',
          width: '100%',
          height: '100%',
          padding: '10px',
          boxSizing: 'border-box',
          overflowY: 'auto',
        }}
      >
        {sortedStates?.map((state) => {
          const firstLetter = state.name[0].toUpperCase();
          const displayLetter = firstLetter !== currentLetter;
          currentLetter = firstLetter;

          return (
            <div
              key={state.code}
              style={{
                backgroundColor: '#F1F8FE',
                padding: '8px 8px 6px 10px',
                // borderRadius: '12px',
              }}
            >
              {displayLetter && (
                <div
                  style={{
                    width: '10px',
                    height: '24px',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 300,
                    fontStyle: 'italic',
                    fontSize: '14px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#00A6FB',
                    marginBottom: '8px',
                  }}
                >
                  {firstLetter}
                </div>
              )}
              <div
                className="override-checkbox-bg"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  height: '24px',
                  marginBottom: '6px',
                }}
              >
                <Checkbox
                  onChange={(e) =>
                    handleCheckboxChange(state.name, e.target.checked)
                  }
                  checked={
                    selectedStates.includes(state.name) ||
                    prefilledStates.includes(state.name)
                  }
                  disabled={
                    prefilledStates.includes(state.name) ||
                    statesWithAffiliation.includes(state.name)
                  }
                />
                <span
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#000000',
                  }}
                >
                  {state.name}
                </span>
                {statesWithAffiliation.includes(state.name) ? (
                  <div>
                    <Tooltip
                      title={`We do not presently offer support for ${state.name}.`}
                    >
                      <QuestionCircleOutlined
                        style={{
                          marginLeft: '8px',
                          cursor: 'pointer',
                          color: '#97AABF',
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StateDisplay;
