export function getQueryParams(): Record<string, string> {
  const queryParams: Record<string, string> = {};
  const queryString: string = window.location.search.substring(1); // Get the query string without the leading '?'

  const keyValuePairs: string[] = queryString.split('&');

  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    if (key && value) {
      queryParams[key] = value;
    }
  });

  return queryParams;
}
