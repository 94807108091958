import { Col, Row, Skeleton, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../services/agency.service';
import { AssignStateProps } from '../../../types/props/assignStates.props';
import AssignStatesCell from '../assignStates/assignStatesCell';
import EmptyState from '../../common/emptyState/emptyState';
import { ResidencyType } from '../../../enums/residencyType.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { UpdatedStateGroup } from '../../../types/data/stateGroup.types';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { getStateNameFromCode } from '../../../utils/common.utils';
import { observer } from 'mobx-react-lite';
import produce from '@reduxjs/toolkit/node_modules/immer';
import { useAuth } from '../../../auth/authProvider';

export interface ColumnItemType {
  code: string;
  name: string;
  isChecked: boolean;
  price?: string;
}

export interface StateGroupInfo {
  name: string;
  isSelected: boolean;
  currentStateCode: string;
  states: StateInfo[];
  id: string;
}

interface StateInfo {
  code: string;
  name: string;
  currentLcCode: string;
  lcs: LCInfo[];
}
interface LCInfo {
  code: string;
  name: string;
  isSelected: boolean;
  loas: LOAInfo[];
}
interface LOAInfo {
  code: string;
  name: string;
  price: string;
  id: string;
}

function AssignStateGroups({
  assignedStates,
  setAssignedStates,
  setLoading,
  loading,
  givenStateGroup,
  handleVisibility,
  emptyStateButton,
  homeState,
}: AssignStateProps) {
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  // state
  const [stateGroups, setStateGroups] = useState<UpdatedStateGroup[]>([]);
  const [stateGroupsInfo, setStateGroupsInfo] = useState<StateGroupInfo[]>([]);
  const [currentStateGroupId, setCurrentStateGroupId] = useState<string>();
  const { getAccessTokenSilently } = useAuth();
  const [stateGroupAvailable, setStateGroupAvailable] = useState(true);
  const [stateGroupCount, setStateGroupCount] = useState(0);
  const [isScrollValid, setScrollValid] = useState(false);
  const fetchStateGroups = async () => {
    if (!adminStore.account) return;
    setLoading(true);
    const token = await getAccessTokenSilently();
    const response = await AgencyService.getStateGroupsForAgency(token).catch(
      (error) => {
        if (error?.code !== 'ERR_CANCELED')
          showError('There was an error while fetching the territories');
      }
    );
    if (response && response.data) {
      const data = response.data;
      data.sort((a, b) => a.name.localeCompare(b.name));
      data.forEach((item) => {
        item.stateLicenseConfigs.sort((a, b) => {
          const stateNameA = getStateNameFromCode(a.stateCode);
          const stateNameB = getStateNameFromCode(b.stateCode);
          return stateNameA.localeCompare(stateNameB);
        });
      });
      setStateGroups(response.data);
      setScrollValid(true);
      if (response.data.length === 0) {
        setStateGroupAvailable(false);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setStateGroupCount(stateGroupsInfo?.filter((itr) => itr.isSelected).length);
  }, [stateGroupsInfo]);

  const createStateGroupsDataFromStateGroups = () => {
    const stateGroupsData: StateGroupInfo[] = stateGroups.map((stateGroup) => {
      const statesData: StateInfo[] = stateGroup.stateLicenseConfigs.map(
        (stateLicenseConfig) => {
          let lcsData: LCInfo[] = stateLicenseConfig.licenseConfigs.map(
            (licenseConfig) => {
              // preselected stategroups
              const producerResidencyType = homeState?.includes(
                stateLicenseConfig.stateCode
              )
                ? 'R'
                : 'NR';
              const configResidencyType = appStateInfoStore.getResidencyType(
                licenseConfig.lcCode,
                stateLicenseConfig.stateCode
              );
              const isLcSelected =
                producerResidencyType === configResidencyType ||
                configResidencyType === ResidencyType.NotApplicable;

              return {
                code: licenseConfig.lcCode,
                name: licenseConfig.lcName,
                isSelected: isLcSelected,
                loas: licenseConfig?.loaConfigs?.map((data) => {
                  return {
                    code: data.loaCode,
                    name: data.loaName,
                    price: '',
                    id: data.id,
                  };
                }),
              };
            }
          );

          return {
            code: stateLicenseConfig.stateCode,
            name: getStateNameFromCode(stateLicenseConfig.stateCode),
            lcs: lcsData,
            currentLcCode: '',
          };
        }
      );
      return {
        name: stateGroup.name,
        isSelected: givenStateGroup
          ? true
          : assignedStates.some(
              (assignedState) => assignedState.stateGroupId === stateGroup.id
            ),
        states: statesData,
        currentStateCode: '',
        id: stateGroup.id,
      };
    });
    return stateGroupsData;
  };

  useEffect(() => {
    fetchStateGroups();
  }, []);

  function updateSelectedStateGroup(
    stateGroupsData: StateGroupInfo[],
    currentStateGroupId: string | undefined
  ): StateGroupInfo[] {
    return stateGroupsData.map((data) => {
      if (data.id === currentStateGroupId) {
        const currentStateCode = data.states?.[0]?.code;
        return {
          ...data,
          currentStateCode,
          states: data.states.map((state) => ({
            ...state,
            currentLcCode: state.lcs?.[0]?.code,
          })),
        };
      }
      return data;
    });
  }

  useEffect(() => {
    if (!stateGroups) return;

    const stateGroupsData: StateGroupInfo[] =
      createStateGroupsDataFromStateGroups();

    const firstSelectedStateGroup = stateGroupsData.find(
      (data) => data.isSelected === true
    );
    const currentStateGroupId =
      firstSelectedStateGroup?.id || stateGroupsData?.[0]?.id;

    setCurrentStateGroupId(currentStateGroupId);
    setStateGroupsInfo(
      updateSelectedStateGroup(stateGroupsData, currentStateGroupId)
    );
  }, [stateGroups]);

  useEffect(() => {
    if (!currentStateGroupId) return;

    const stateGroupsData: StateGroupInfo[] =
      createStateGroupsDataFromStateGroups();
    setStateGroupsInfo(
      updateSelectedStateGroup(stateGroupsData, currentStateGroupId)
    );

    const element = document.getElementById(currentStateGroupId);
    if (element && isScrollValid) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setScrollValid(false);
    }
  }, [currentStateGroupId]);

  const getCurrentStateGroupInfo = () => {
    return stateGroupsInfo.find(
      (stateGroupInfo) => stateGroupInfo.id === currentStateGroupId
    );
  };

  const getCurrentStateInfo = (): StateInfo | undefined => {
    return getCurrentStateGroupInfo()?.states.find(
      (stateInfo) =>
        stateInfo.code === getCurrentStateGroupInfo()?.currentStateCode
    );
  };

  const getCurrentLcInfo = () => {
    return getCurrentStateInfo()?.lcs.find(
      (lcInfo) => lcInfo.code === getCurrentStateInfo()?.currentLcCode
    );
  };

  // Setters for stateGroupsInfo START
  const setCurrentStateCode = (stateCode: string) => {
    const newStateGroupsInfo = produce(stateGroupsInfo, (draft) => {
      const stateGroupInfo = draft.find(
        (stateGroupInfo) => stateGroupInfo.id === currentStateGroupId
      );
      if (stateGroupInfo) {
        stateGroupInfo.currentStateCode = stateCode;
      }
    });
    setStateGroupsInfo(newStateGroupsInfo);
  };

  const setCurrentLcCode = (lcCode: string) => {
    const newStateGroupsInfo = produce(stateGroupsInfo, (draft) => {
      const stateGroupInfo = draft.find(
        (stateGroupInfo) => stateGroupInfo.id === currentStateGroupId
      );
      if (stateGroupInfo) {
        const stateInfo = stateGroupInfo.states.find(
          (stateInfo) => stateInfo.code === stateGroupInfo.currentStateCode
        );
        if (stateInfo) {
          stateInfo.currentLcCode = lcCode;
        }
      }
    });
    setStateGroupsInfo(newStateGroupsInfo);
  };

  const setIsSelectedForLc = (
    stateGroupId: string,
    stateCode: string,
    lcCode: string,
    isSelected: boolean
  ) => {
    const newStateGroupsInfo = produce(stateGroupsInfo, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].id === stateGroupId) {
          for (let j = 0; j < draft[i].states.length; j++) {
            if (draft[i].states[j].code === stateCode) {
              for (let k = 0; k < draft[i].states[j].lcs.length; k++) {
                if (draft[i].states[j].lcs[k].code === lcCode) {
                  draft[i].states[j].lcs[k].isSelected = isSelected;
                }
              }
            }
          }
        }
      }
    });
    setStateGroupsInfo(newStateGroupsInfo);
  };

  const setIsSelectedForStateGroup = (
    stateGroupId: string,
    isSelected: boolean
  ) => {
    const newStateGroupsInfo = produce(stateGroupsInfo, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].id === stateGroupId) {
          draft[i].isSelected = isSelected;
          // if (!isSelected) {
          for (let j = 0; j < draft[i].states.length; j++) {
            for (let k = 0; k < draft[i].states[j].lcs.length; k++) {
              const producerResidencyType = homeState?.includes(
                draft[i].states[j].code
              )
                ? 'R'
                : 'NR';
              const configResidencyType = appStateInfoStore.getResidencyType(
                draft[i].states[j].lcs[k].code,
                draft[i].states[j].code
              );
              // check if lc residency status matches agent residency status
              if (
                configResidencyType === ResidencyType.NotApplicable ||
                producerResidencyType === configResidencyType
              ) {
                // draft[i].states[j].lcs[k].isSelected = true;
              } else {
                draft[i].states[j].lcs[k].isSelected = false;
              }
            }
          }
          // }
        }
      }
    });
    setStateGroupsInfo(newStateGroupsInfo);
  };
  // Setters for stateGroupsInfo END

  // Setters for stateGroupsInfo START
  const toggleIsSelectedForStateGroup = (stateGroupId: string) => {
    const newAssignedStates = produce(assignedStates, (draft) => {
      const isStateGroupAssigned = givenStateGroup
        ? true
        : draft.find(
            (assignedState) => assignedState.stateGroupId === stateGroupId
          );

      // remove the states where stateGroupId matches
      for (let i = draft.length - 1; i >= 0; i--) {
        if (draft[i].stateGroupId === stateGroupId) {
          draft.splice(i, 1);
          setIsSelectedForStateGroup(stateGroupId, false);
        }
      }
      if (!isStateGroupAssigned) {
        // update local state
        setIsSelectedForStateGroup(stateGroupId, true);

        // remove the states where stateGroupId.state matches
        const stateGroupData = stateGroups.find(
          (itr) => itr.id === stateGroupId
        );
        if (stateGroupData)
          for (let i = draft.length - 1; i >= 0; i--) {
            if (
              stateGroupData.stateLicenseConfigs
                .map((itr) => itr.stateCode)
                .includes(draft[i].stateCode)
            ) {
              draft.splice(i, 1);
            }
          }

        // add all the states under the stateGroup
        const stateGroupInfo = stateGroupsInfo.find(
          (stateGroup) => stateGroup.id === stateGroupId
        );
        if (stateGroupInfo) {
          stateGroupInfo.states.forEach((stateInfo) => {
            draft.push({
              stateGroupId: stateGroupId,
              stateCode: stateInfo.code,
              licenseConfigs: stateInfo.lcs.map((itr) => {
                return {
                  lcCode: itr.code,
                  loaCodes: itr.loas.map((loaItr) => loaItr.code),
                  loaids: itr.loas.map((loaItr) => loaItr.id || ''),
                };
              }),
              stateGroupName: stateGroupInfo.name,
            });
          });
        }
      }
    });
    if (!givenStateGroup) setAssignedStates(newAssignedStates);
  };

  const toggleIsSelectedForLc = (lcCode: string) => {
    const newAssignedStates = produce(assignedStates, (draft) => {
      for (let i = draft.length - 1; i >= 0; i--) {
        const assignedState = draft[i];
        if (
          assignedState.stateGroupId === currentStateGroupId &&
          assignedState.stateCode === getCurrentStateInfo()?.code
        ) {
          const index = assignedState.licenseConfigs
            .map((itr) => itr.lcCode)
            .indexOf(lcCode);
          if (index !== -1) {
            assignedState.licenseConfigs.splice(index, 1);
            setIsSelectedForLc(
              currentStateGroupId || '',
              assignedState.stateCode,
              lcCode,
              false
            );
          } else {
            assignedState.licenseConfigs.push({
              lcCode,
              loaCodes:
                getCurrentStateInfo()
                  ?.lcs.find((itr) => itr.code === lcCode)
                  ?.loas.map((itr) => itr.code) || [],
            });
            setIsSelectedForLc(
              currentStateGroupId || '',
              assignedState.stateCode,
              lcCode,
              true
            );
          }
          draft[i] = assignedState;
        }
      }
      if (
        !draft.some(
          (itr) =>
            itr.stateGroupId === currentStateGroupId &&
            itr.stateCode === getCurrentStateInfo()?.code
        )
      )
        showError(
          `You are trying to select the license class ${appStateInfoStore.getLcNameFromCode(
            lcCode
          )}. Please select the territory ${
            getCurrentStateGroupInfo()?.name
          } first`
        );
    });
    setAssignedStates(newAssignedStates);
  };
  // Setters for stateGroupsInfo END

  return (
    <div style={{ marginTop: '20px' }}>
      {contextHolder}
      {stateGroupAvailable ? (
        <Row
          style={{
            border: '1px solid var(--border-color)',
            borderRadius: '5px',
            overflowX: 'auto',
          }}
        >
          <Col
            span="6"
            style={{
              border: '1px solid var(--hover-color)',
              height: '50vh',
              textAlign: 'center',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            <Row
              align="middle"
              style={{
                textAlign: 'center',
                position: 'sticky',
                top: '0',
                backgroundColor: 'var(--hover-color)',
                zIndex: 10,
                justifyContent: 'space-between',
                height: '15%',
                borderBottom: '1px solid var(--border-color)',
                borderRight: '1px solid var(--border-color)',
              }}
            >
              <Typography.Paragraph
                style={{
                  marginTop: '10px',
                  marginLeft: '20%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: 'var(--secondary-color)',
                }}
              >
                Territory
              </Typography.Paragraph>
            </Row>
            {loading ? (
              <Skeleton active style={{ width: '75%', marginLeft: '5%' }} />
            ) : (
              stateGroupAvailable && (
                <div>
                  {stateGroupsInfo.map((stateGroup) => (
                    <div id={stateGroup.id}>
                      <AssignStatesCell
                        name={stateGroup.name}
                        id={stateGroup.id}
                        disabled={!!givenStateGroup}
                        isChecked={
                          givenStateGroup ? true : stateGroup.isSelected
                        }
                        setCurrentValue={() => {
                          setCurrentStateGroupId(stateGroup.id);
                          toggleIsSelectedForStateGroup(stateGroup.id);
                        }}
                        toggleIsChecked={() => {
                          toggleIsSelectedForStateGroup(stateGroup.id);
                        }}
                        currentValue={currentStateGroupId}
                        currentState={stateGroup.currentStateCode}
                      />
                    </div>
                  ))}
                </div>
              )
            )}
          </Col>
          <Col
            span="6"
            style={{
              border: '1px solid var(--hover-color)',
              height: '50vh',
              textAlign: 'center',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            <Row
              align="middle"
              style={{
                textAlign: 'center',
                position: 'sticky',
                top: '0',
                backgroundColor: 'var(--hover-color)',
                zIndex: 10,
                height: '15%',
                borderBottom: '1px solid var(--border-color)',
                borderRight: '1px solid var(--border-color)',
              }}
            >
              <Typography.Paragraph
                style={{
                  marginTop: '10px',
                  marginLeft: '20%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: 'var(--secondary-color)',
                }}
              >
                States{' '}
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: '12px',
                    color: 'var(--light-grey2)',
                    marginTop: '-1%',
                  }}
                >
                  <br />
                  {getCurrentStateGroupInfo()?.name &&
                    `for ${getCurrentStateGroupInfo()?.name}`}{' '}
                </span>
              </Typography.Paragraph>
            </Row>
            {loading ? (
              <Skeleton active style={{ width: '75%', marginLeft: '5%' }} />
            ) : (
              stateGroupAvailable &&
              (currentStateGroupId ? (
                getCurrentStateGroupInfo()?.states.map((state) => (
                  <div>
                    <AssignStatesCell
                      key={state.code}
                      name={state.name}
                      id={state.code}
                      isChecked={true}
                      setCurrentValue={() => {
                        setCurrentStateCode(state.code);
                      }}
                      toggleIsChecked={() => {}}
                      currentValue={
                        getCurrentStateGroupInfo()?.currentStateCode
                      }
                      disableCheck={
                        !getCurrentStateGroupInfo()?.isSelected && true
                      }
                      disabled={true}
                      currentState={state.code}
                      fromStateGroup={true}
                    />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    height: '80%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <EmptyState
                    heading="No State to show"
                    content="State will come up after choosing a Territory "
                  />
                </div>
              ))
            )}
          </Col>
          <Col
            span="6"
            style={{
              border: '1px solid var(--hover-color)',
              height: '50vh',
              textAlign: 'center',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            <Row
              align="middle"
              style={{
                textAlign: 'center',
                zIndex: 10,
                position: 'sticky',
                top: '0',
                height: '15%',
                backgroundColor: 'var(--hover-color)',
                borderBottom: '1px solid var(--border-color)',
                borderRight: '1px solid var(--border-color)',
              }}
            >
              <Typography.Paragraph
                style={{
                  marginTop: '10px',
                  marginLeft: '15%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: 'var(--secondary-color)',
                }}
              >
                License Classes{' '}
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: '12px',
                    color: 'var(--light-grey2)',
                    marginTop: '-1%',
                  }}
                >
                  <br />
                  {getCurrentStateInfo()?.name &&
                    `for ${getCurrentStateInfo()?.name}`}
                </span>
              </Typography.Paragraph>
            </Row>
            {loading ? (
              <Skeleton active style={{ width: '75%', marginLeft: '5%' }} />
            ) : stateGroupAvailable &&
              getCurrentStateGroupInfo()?.currentStateCode ? (
              getCurrentStateInfo()?.lcs.map((lc) => (
                <div>
                  <AssignStatesCell
                    name={lc.name}
                    id={lc.code}
                    isChecked={lc.isSelected}
                    disabled={lc.isSelected}
                    setCurrentValue={() => {
                      setCurrentLcCode(lc.code);
                    }}
                    toggleIsChecked={() => {
                      toggleIsSelectedForLc(lc.code);
                    }}
                    currentValue={getCurrentStateInfo()?.currentLcCode}
                    disableCheck={
                      !getCurrentStateGroupInfo()?.isSelected && true
                    }
                    currentState={
                      getCurrentStateGroupInfo()?.currentStateCode || ''
                    }
                    residentType={appStateInfoStore.getResidencyType(
                      lc.code,
                      getCurrentStateGroupInfo()?.currentStateCode || ''
                    )}
                    fromStateGroup={true}
                  />
                </div>
              ))
            ) : (
              <div
                style={{
                  height: '80%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <EmptyState
                  heading="No License Class to show"
                  content="License Class will come up after choosing a State"
                />
              </div>
            )}
          </Col>
          <Col
            span="6"
            style={{
              border: '1px solid var(--hover-color)',
              textAlign: 'center',
              height: '50vh',
              overflowY: 'auto',
            }}
          >
            <Row
              align="middle"
              style={{
                textAlign: 'center',
                zIndex: 10,
                position: 'sticky',
                top: '0',
                backgroundColor: 'var(--hover-color)',
                height: '15%',
                borderBottom: '1px solid var(--border-color)',
                borderRight: '1px solid var(--border-color)',
              }}
            >
              <Typography.Paragraph
                style={{
                  marginTop: '10px',
                  marginLeft: '10%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: 'var(--secondary-color)',
                }}
              >
                Lines of Authorities{' '}
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: '12px',
                    color: 'var(--light-grey2)',
                    marginTop: '-1%',
                  }}
                >
                  <br />
                  {getCurrentLcInfo()?.name &&
                    `for ${getCurrentLcInfo()?.name}`}
                </span>
              </Typography.Paragraph>
            </Row>
            {loading ? (
              <Skeleton active style={{ width: '75%', marginLeft: '5%' }} />
            ) : (
              stateGroupAvailable &&
              (getCurrentStateInfo()?.currentLcCode ? (
                getCurrentLcInfo()?.loas.map((loa) => (
                  <AssignStatesCell
                    name={loa.name}
                    id={loa.code}
                    isChecked={!!getCurrentLcInfo()?.isSelected}
                    price={loa.price}
                    toggleIsChecked={() => {}}
                    columnName="loa"
                    disableCheck={
                      !getCurrentStateGroupInfo()?.isSelected && true
                    }
                    disabled={true}
                    currentState={getCurrentStateInfo()?.code || ''}
                    residentType={appStateInfoStore.getResidencyType(
                      getCurrentStateInfo()?.currentLcCode || '',
                      getCurrentStateInfo()?.code || ''
                    )}
                    fromStateGroup={true}
                  />
                ))
              ) : (
                <div
                  style={{
                    height: '80%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <EmptyState
                    heading="No Lines of Authority to show"
                    content="Lines of Authority will come up after choosing a License Class"
                  />
                </div>
              ))
            )}
          </Col>
        </Row>
      ) : (
        <div style={{ width: '130%', textAlign: 'center', marginTop: '7%' }}>
          <EmptyState
            route={RouteConstants.createStateGroup.path}
            heading="No territories to show yet"
            content="Create a new territory to assign it to your producer"
            buttonText="Create territory"
            button={emptyStateButton}
          />
        </div>
      )}
    </div>
  );
}

export default observer(AssignStateGroups);
