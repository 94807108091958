import AdminHeader from './adminHeader';
import AgentHeader from './agentHeader';

export type HeaderProps = {
  readonly headerText: string;
};

const Header = ({ headerText }: HeaderProps) => {
  return (
    <>
      <AgentHeader headerText={headerText} />
      <AdminHeader headerText={headerText} />
    </>
  );
};

export default Header;
