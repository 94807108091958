import { Button, Form, Input, Modal, Typography } from 'antd';
import React, { useState } from 'react';

import { ModalContainer } from './update-email.style';
import { TextDetail } from '../../application-status/applicationstatus.style';

interface UpadteEmailProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  currentEmail: string;
}

const UpdateEmailModal: React.FC<UpadteEmailProps> = ({
  visible,
  setVisible,
  currentEmail,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const emailValidationRegex =
    /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
  const onFinish = async (values: any) => {
    try {
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  type FieldType = {
    email?: string;
    password?: string;
  };
  return (
    <Modal
      width={'500px'}
      open={visible}
      onCancel={() => {
        setVisible(false); // Call the setVisible function to close the modal
      }}
      okText="Ok"
      footer={null}
    >
      <ModalContainer>
        <TextDetail textColor={'#0588ca'} fontWeight={500} fontSize={'16px'}>
          Update Email Address
        </TextDetail>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600, width: '600px' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '15px',
              width: '450px',
            }}
          >
            <Typography.Text style={{ fontSize: '12px' }}>
              Current Email
            </Typography.Text>
            <Typography.Text style={{ fontSize: '16px' }}>
              {currentEmail}
            </Typography.Text>
          </div>

          <Form.Item<FieldType>
            label="New Email Address"
            name="email"
            rules={[
              { required: true, message: 'Please Input New Email Address!' },
              {
                pattern: emailValidationRegex,
                message: 'Please enter a valid email address',
              },
            ]}
          >
            <Input
              onInput={(e: any) =>
                (e.target.value = e.target.value.toLowerCase())
              }
              placeholder="Enter an email address"
            />
          </Form.Item>
          <div
            style={{
              width: '450px',
              marginBottom: '15px',
            }}
          >
            <Typography.Text style={{ color: '#787878', fontSize: '14px' }}>
              A confirmation will be sent to this email, click on the
              confirmation link to verify and add this email address
            </Typography.Text>
          </div>
          <Form.Item<FieldType>
            label="Current Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please Input Current Password!',
              },
            ]}
          >
            <Input type="password" placeholder="Enter password" />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: '100%', marginBottom: '13px', height: '40px' }}
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Change Email
            </Button>
            <Button style={{ width: '100%', height: '40px' }} htmlType="reset">
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </ModalContainer>
    </Modal>
  );
};

export default UpdateEmailModal;
