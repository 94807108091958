import {
  default as Actionable,
  default as AgencyActionableLicenses,
} from '../pages/agency/licenses/actionable';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';

import { ADMIN_AGENT_MENU_ROUTES } from './siderMenu/siderMenu';
import ActionableLicenses from '../pages/agentSidePortal/licenses/actionable';
import AgencyAdmin from '../pages/agency/settings/agencyAdmins';
import AgencyAgentOverview from '../pages/agents/agentsOverview/agencyAgentOverview';
import AgencyApplications from '../pages/agency/licenses/applications';
import AgencyBlackList from '../pages/agency/blacklist/blacklist';
import AgencyPortalAgentOverview from '../pages/agents/agentsOverview/overview';
import AgencyRts from '../pages/agency/agencyRts/agencyRts';
import AgentActivity from '../pages/agents/agentsOverview/agentOverview/activity';
import AgentDashboard from '../pages/agentSidePortal/dashboard/dashboard';
import AgentPortal from '../pages/agentSidePortal/agentPortal';
import AgentPortalLicenses from '../pages/agentSidePortal/agentLicenses';
import AgentSettings from '../pages/agentSidePortal/agentSettings';
import AgentSupportDocuments from '../pages/agentSidePortal/agentInformation/supportingDocuments/agent-support-documents';
import Agents from '../pages/agents/agentsOverview/agents';
import AllLicenses from '../pages/agentSidePortal/licenses/all-licenses';
import AllLicensesAgencyTab from '../pages/agency/licenses/allLicenses/agency/allLicensesAgencyTab';
import AllLicensesTabView from '../pages/agency/licenses/allLicenses/allLicensesTabView';
import Applications from '../pages/agentSidePortal/licenses/applications';
import BackgroundQuestionsScreen from '../pages/agentSidePortal/agentInformation/backgroundQuestions/backgroundQuestions';
import BulkAddressUpdate from '../pages/agency/bulkAddress';
import DetailsAgency from '../pages/agency/details';
import Downlines from '../pages/agency/downlines/downlines';
import EditStateGroup from '../pages/stateGroups/editStateGroup/editStateGroup';
import EmploymentHistory from '../pages/agentSidePortal/agentInformation/backgroundQuestions/employmentHistory';
import ErrorModal from './common/errorModal/errorModal';
import GeneralProfile from '../pages/agency/general';
import Header from './headers/header';
import { Helmet } from 'react-helmet';
import HomeRedirect from '../pages/homeRedirect';
import InviteDownline from '../pages/agents/inviteDownline';
import Invites from '../pages/invites';
import { Layout } from 'antd';
import ManageAssignments from '../pages/agents/manageAssignments/manageAssignment';
import Menu from './menu';
import { MenuType } from '../enums/menuType.enum';
import MyAgencyBackgroundQuestions from '../pages/agency/backgroundQuestions/myAgencyBackgroundQuestions';
import { NODE_ENV } from '../enums/nodeEnv.enum';
import NiprCosts from '../pages/ledger/niprCosts';
import OffboardAgents from '../pages/agents/offboardAgents/offboardAgents';
import OnboardAgents from '../pages/agents/onboardAgents/onboardAgents';
import OnboardNewUserTour from './common/Tour/onboardNewUserTour';
import OwnershipDetails from '../pages/agency/ownershipDetails/OwnershipDetails';
import PageNotFound from '../pages/404';
import PendingAgencyApplications from '../pages/agency/licenses/applications/PendingAgencyApplications';
import PendingProducerApplications from '../pages/agency/licenses/applications/PendingProducerApplications';
import RedirectPage from '../pages/redirect';
import RenewalLicenses from '../pages/agency/licenses/renewals';
import { RouteConstants } from '../constants/routes.constants';
import Rts from '../pages/agents/RTS/rts';
import RtsViewAgents from '../pages/agents/RTS/agentSpecificRTS';
import StateGroups from '../pages/stateGroups/stateGroups';
import StripeCustomerPortal from '../pages/ledger/stripeCustomerPortal';
import SupportDocuments from '../pages/agents/agentsOverview/support-documents';
import { TourConstants } from '../constants/tour.constant';
import TransactionHistory from '../pages/ledger/transactions';
import UpLines from '../pages/agency/uplines/uplines';
import UserProfile from '../pages/agency/settings/userProfile';
import { UserType } from '../enums/userType.enum';
import ViewDownlineInvitations from '../pages/agency/downlines/viewInvitation';
import ViewInvitations from '../pages/agency/uplines/viewInvitation';
import ViewUserInvitations from '../pages/agency/settings/agencyAdminsInvite';
import { agencyProfileStore } from '../stores/agencyProfileStore';
import { appStateInfoStore } from '../stores/appStateInfo.store';
import { findRouteByPath } from '../utils/route.utils';
import { inviteStore } from '../stores/invites.store';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../stores/signupStore';
import { tourStore } from '../stores/tour.store';
import { trackCustomEvent } from '../utils/trackingUtils';
import { useAuth } from '../auth/authProvider';

const { Content } = Layout;

const EditStateGroupWrapper = () => {
  const { id } = useParams();
  return <EditStateGroup key={id} />;
};

function MyLayout() {
  const [historyStack, setHistoryStack] = useState('');
  const { getAccessTokenSilently } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [helmetText, setHelmetText] = useState<string>('InsureTrek');
  const location = useLocation();
  const navigate = useNavigate();
  const [newMenu, setNewMenu] = useState(MenuType.MainMenu);
  const { roles, isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const initializeStores = async () => {
      const token = await getAccessTokenSilently();
      //TODO Onboarding new stripe flow has to enable this
      // await stripeStore.init(token);

      await appStateInfoStore.initializeStore(token);

      if (signupStore.userType === UserType.ADMIN)
        await agencyProfileStore.loadAgencyDetails(token);

      inviteStore.loadInvites(token).then((response) => {
        if (response) {
          if (response.data.length > 0) {
            navigate(RouteConstants.invites.path);
          }
        }
      });
    };
    initializeStores();

    return () => sessionStorage.removeItem(TourConstants.AGENCY_ONBOARD_TOUR);
  }, []);

  useEffect(() => {
    const path = location.pathname;

    const matchedRoute = findRouteByPath(path);
    if (matchedRoute) {
      if (
        // admin portal agenty sider menu route keys
        [
          'admin-portal_agent-activity',
          'admin-portal_agent-overview',
          'admin-portal_agent-rts',
          'admin-portal_agent-support-documents',
          'agency-background-questions',
          'agency-employment-history',
          'admin-portal_agent-license',
          'admin-portal_agent-overview-all-license',
          'admin-portal_agent-overview-pending-applications',
        ].includes(matchedRoute.route.key)
      ) {
        setNewMenu(MenuType.SubMenu);
      } else {
        setNewMenu(MenuType.MainMenu);
        if (matchedRoute.route.headerText)
          appStateInfoStore.setHeader(matchedRoute.route.headerText);
      }
      appStateInfoStore.setIcon(matchedRoute.route.icon);
      appStateInfoStore.setSelectedMenuKey(
        matchedRoute.route.key === RouteConstants.RTSViewAgentsFromTable.key
          ? RouteConstants.agentRTS.key
          : matchedRoute.route.key === RouteConstants.downlines.key ||
            matchedRoute.route.key === RouteConstants.uplines.key
          ? RouteConstants.agencyHierarchy.key
          : matchedRoute.route.key || ''
      );
      appStateInfoStore.setTooltip(matchedRoute.route.tooltip || '');
      setHelmetText(
        matchedRoute.route.helmetText
          ? matchedRoute.route.helmetText + ' | InsureTrek'
          : 'InsureTrek'
      );
    }
  }, [location.pathname, appStateInfoStore.header]);

  useEffect(() => {
    const path = location.pathname;

    const matchedRoute = findRouteByPath(path);
    if (matchedRoute) {
      const path = location.pathname;
      const currentRole = roles[0];

      trackCustomEvent(
        (matchedRoute.route.helmetText
          ? matchedRoute.route.helmetText
          : 'InsureTrek'
        ).trimEnd(),
        path,
        currentRole
      );
    }
    if (
      location.pathname?.toLowerCase()?.startsWith('/agent') &&
      ADMIN_AGENT_MENU_ROUTES.some((route: string) =>
        location.pathname?.toLowerCase()?.endsWith(route?.toLowerCase())
      )
    )
      return;
    setHistoryStack(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{helmetText}</title>
        <meta
          name="robots"
          content={
            process.env.REACT_APP_NODE_ENV !== NODE_ENV.PRODUCTION
              ? 'noindex'
              : undefined
          }
          data-react-helmet="true"
        />
      </Helmet>
      <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
        <Menu menuType={newMenu} />
        <Layout>
          <Header headerText={appStateInfoStore.header} />

          <Content
            id="ant-layout-container"
            style={{
              padding: '0 15px',
              display: 'flex',
              flexGrow: '1',
              overflowY: 'auto',
              margin: '15px 0px',
            }}
          >
            <Routes>
              <Route
                path={RouteConstants.agencyBackgroundQuestions.path}
                element={
                  <div style={{ width: '100%' }}>
                    <BackgroundQuestionsScreen
                      isAgency={true}
                      isLoading={false}
                      editMode={true}
                    />
                  </div>
                }
              />
              <Route
                path={RouteConstants.agencyEmploymentHistory.path}
                element={
                  <EmploymentHistory isAgency={true} isOnboarded={true} />
                }
              />
              <Route
                path={RouteConstants.agentOverviewLicense.path}
                element={<Actionable isAgentOverview={true} />}
              />
              <Route
                path={RouteConstants.agentOverviewAllLicenses.path}
                element={<AllLicensesTabView isAgentOverview={true} />}
              />
              <Route
                path={RouteConstants.rootRedirect.path}
                element={<HomeRedirect />}
              />
              <Route
                path={RouteConstants.allAgents.path}
                element={<AgencyAgentOverview />}
              />
              <Route
                path={RouteConstants.licensesAgency.path}
                element={<AllLicensesAgencyTab />}
              />
              <Route
                path={RouteConstants.agentOverview.path}
                element={<AgencyPortalAgentOverview />}
              />
              <Route
                path={RouteConstants.inviteDownline.path}
                element={
                  <InviteDownline
                    onError={(error) => {
                      console.error('setErrorMessage :: ', error);
                      setErrorMessage(error);
                    }}
                  />
                }
              />
              <Route path={RouteConstants.agentRTS.path} element={<Rts />} />
              <Route
                path={RouteConstants.RTSViewAgentsFromTable.path}
                element={<RtsViewAgents />}
              />
              <Route
                path={RouteConstants.agentPortalRTS.path}
                element={
                  <RtsViewAgents isAgentPortal={true} hideBioInfo={true} />
                }
              />
              <Route
                path={RouteConstants.RTSViewAgentsFromOverView.path}
                element={<RtsViewAgents hideBioInfo={true} />}
              />
              <Route
                path={RouteConstants.agencyRTS.path}
                element={<AgencyRts />}
              />
              <Route
                path={RouteConstants.agentActivity.path}
                element={<AgentActivity />}
              />
              <Route
                path={RouteConstants.agencySupportDocuments.path}
                element={<SupportDocuments />}
              />
              <Route
                path={RouteConstants.agentSupportDocuments.path}
                element={<AgentSupportDocuments />}
              />

              <Route
                path={RouteConstants.agentAllLicenses.path}
                element={<AllLicenses />}
              />
              <Route
                path={RouteConstants.agencyLicenses.path}
                element={<AllLicensesTabView />}
              />
              <Route
                path={RouteConstants.agencyActionableLicenses.path}
                element={<AgencyActionableLicenses />}
              />
              <Route
                path={RouteConstants.renewalLicenses.path}
                element={<RenewalLicenses />}
              />
              <Route
                path={RouteConstants.agentActionableLicenses.path}
                element={<ActionableLicenses />}
              />
              <Route
                path={RouteConstants.agencyHierarchy.path}
                element={<Agents />}
              />
              <Route
                path={RouteConstants.downlines.path}
                element={<Downlines />}
              />
              <Route path={RouteConstants.uplines.path} element={<UpLines />} />
              <Route
                path={RouteConstants.invite_uplines.path}
                element={<ViewInvitations />}
              />
              <Route
                path={RouteConstants.invite_downlines.path}
                element={<ViewDownlineInvitations />}
              />
              <Route
                path={RouteConstants.invite_admins.path}
                element={<ViewUserInvitations />}
              />
              <Route
                path={RouteConstants.stateGroups.path}
                element={<StateGroups />}
              />
              <Route
                path={RouteConstants.createStateGroup.path}
                element={<EditStateGroup />}
              />
              <Route
                path={RouteConstants.editStateGroup.path}
                element={<EditStateGroupWrapper />}
              />
              <Route
                path={RouteConstants.agentDashBoard.path}
                element={<AgentDashboard />}
              />
              <Route
                path={RouteConstants.agencyGeneralProfile.path}
                element={<DetailsAgency />}
              />
              <Route
                path={RouteConstants.agencyAdminProfile.path}
                element={<UserProfile />}
              />

              <Route
                path={RouteConstants.agentProfile.path}
                element={<UserProfile hideDetails={true} />}
              />
              <Route
                path={RouteConstants.onboardAgents.path}
                element={<OnboardAgents />}
              />
              <Route
                path={RouteConstants.offboardAgents.path}
                element={<OffboardAgents />}
              />
              <Route
                path={RouteConstants.agentPortal.path}
                element={
                  <div>
                    <AgentPortal />
                  </div>
                }
              />
              <Route
                path={RouteConstants.agentPortalLicenses.path}
                element={
                  <div>
                    <AgentPortalLicenses />
                  </div>
                }
              />
              <Route
                path={RouteConstants.agentSettings.path}
                element={
                  <div>
                    <AgentSettings />
                  </div>
                }
              />
              <Route
                path={RouteConstants.backgroundQuestions.path}
                element={
                  <div style={{ width: '100%' }}>
                    <BackgroundQuestionsScreen
                      isLoading={false}
                      editMode={true}
                    />
                  </div>
                }
              />

              <Route
                path={RouteConstants.employmentHistory.path}
                element={<EmploymentHistory isOnboarded={true} />}
              />
              <Route
                path={RouteConstants.manageAssignments.path}
                element={<ManageAssignments />}
              />
              <Route
                path={RouteConstants.admins.path}
                element={<AgencyAdmin />}
              />
              <Route
                path={RouteConstants.blacklist.path}
                element={<AgencyBlackList />}
              />
              <Route
                path={RouteConstants.agencyApplications.path}
                element={<PendingAgencyApplications />}
              />
              <Route
                path={RouteConstants.agencyAgentApplications.path}
                element={<PendingProducerApplications />}
              />
              <Route
                path={RouteConstants.agentOverviewPendingApplications.path}
                element={<PendingProducerApplications isAgentOverview={true} />}
              />
              <Route
                path={RouteConstants.agentApplications.path}
                element={<Applications />}
              />
              <Route
                path={RouteConstants.bulkAddressUpdate.path}
                element={<BulkAddressUpdate />}
              />
              <Route
                path={RouteConstants.niprTransaction.path}
                element={<NiprCosts />}
              />

              <Route path={RouteConstants.invites.path} element={<Invites />} />
              <Route
                path={RouteConstants.billing.path}
                element={<StripeCustomerPortal />}
              />
              <Route path="redirect" element={<RedirectPage />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Content>
          {errorMessage && (
            <ErrorModal
              errorMessage={errorMessage}
              onClose={() => setErrorMessage(null)}
            />
          )}

          {tourStore.signupDemoTour && <OnboardNewUserTour />}
        </Layout>
      </Layout>
    </>
  );
}

export default observer(MyLayout);
