import React from 'react';
import { Select } from 'antd';
import { StateConstants } from '../../../constants/state.constants';

const { Option } = Select;

interface StateSelectProps {
  value?: string | null;
  onChange?: (value: string) => void;
  style?: React.CSSProperties | undefined;
  disabled?: boolean;
}

export const StateSelect: React.FC<StateSelectProps> = ({
  value,
  onChange,
  style,
  disabled,
}) => {
  return (
    <Select
      id="state-select-dropdown"
      showSearch
      placeholder="Select State"
      filterOption={(input, option) => {
        const inputValue = input.toLowerCase();
        const optionValue = (
          option?.children as unknown as string
        ).toLowerCase();

        const optionWords = optionValue.split(' ');
        const isMatch = optionWords.some((word) => word.startsWith(inputValue));

        return isMatch;
      }}
      value={value}
      onChange={onChange}
      style={style}
      disabled={disabled}
    >
      {Object.keys(StateConstants).map((stateCode) => (
        <Option key={stateCode} value={stateCode}>
          {StateConstants[stateCode]}
        </Option>
      ))}
    </Select>
  );
};

export default StateSelect;
