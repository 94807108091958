import { Button, Row, Tooltip } from 'antd';

import { PlusCircleOutlined } from '@ant-design/icons';
import React from 'react';

type AgentAssignmentStatusProps = {
  isAssigned: boolean;
  showAssignButton: boolean;
  handleClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

function AgentAssignmentStatus(props: AgentAssignmentStatusProps) {
  return (
    <Row
      style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
      }}
    >
      <div>{props.isAssigned ? 'Assigned' : 'Unassigned'}</div>
      {props.showAssignButton && (
        <Tooltip title="Assign to producer">
          <Button
            className="button"
            onClick={() => {
              props.handleClick && props.handleClick();
            }}
            style={{ padding: '0px 0px' }}
            loading={props.isLoading}
            disabled={props.isDisabled}
            icon={<PlusCircleOutlined style={{ fontSize: '24px' }} />}
          ></Button>
        </Tooltip>
      )}
    </Row>
  );
}

export default AgentAssignmentStatus;
