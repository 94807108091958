import { Modal, Table } from 'antd';
import React, { useState } from 'react';

import { ColumnsType } from 'antd/es/table';
import { CsvJson } from '../../../../stores/multipleOnboardStore';

interface csvPreviewModalProps {
  csvJson: CsvJson[];
  modalVisibility: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const CsvPreviewModal: React.FC<csvPreviewModalProps> = (props) => {
  const { modalVisibility, setModalVisibility, csvJson } = props;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const columnTitles: Record<string, string> = {
    name: 'Producer Name',
    ssn: 'SSN',
    lastName: 'Last Name',
    licenseNumber: 'License Number',
    state: 'State',
    npn: 'NPN',
    prefferredEmail: 'Preferred Email',
    gender: 'Gender',
    phone: 'Phone',
  };

  const columns: ColumnsType<CsvJson> =
    csvJson.length > 0
      ? Object.keys(csvJson[0]).map((key) => ({
          title: columnTitles[key] ?? key,
          dataIndex: key,
          key: key,
          render: (text: string) => (text ? text : '-'),
        }))
      : [];

  return (
    <Modal
      open={modalVisibility}
      style={{
        minHeight: '60%',
        minWidth: '50%',
      }}
      bodyStyle={{ overflow: 'auto' }}
      title="CSV Data Preview"
      centered
      onCancel={() => setModalVisibility(false)}
      footer={null}
    >
      <Table
        columns={columns}
        dataSource={csvJson}
        pagination={{
          pageSize: pageSize,
          current: pageNumber,
          total: csvJson.length,
          onChange: (page, pageSize) => setPageNumber(page),
          onShowSizeChange: (current, size) => setPageSize(size),
          showSizeChanger: true,
        }}
      />
    </Modal>
  );
};

export default CsvPreviewModal;
