import React from 'react';

const WarningOutlinedCustom = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.62062 0.675781L13.3306 4.38576V9.63246L9.62062 13.3425H4.37392L0.66394 9.63246V4.38576L4.37392 0.675781H9.62062ZM9.06835 2.00911H4.9262L1.99727 4.93804V9.0802L4.9262 12.0091H9.06835L11.9973 9.0802V4.93804L9.06835 2.00911ZM6.33009 9.00859H7.66342V10.3419H6.33009V9.00859ZM6.33009 3.67529H7.66342V7.67526H6.33009V3.67529Z"
        fill="black"
      />
    </svg>
  );
};

export default WarningOutlinedCustom;
