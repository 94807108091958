import Loading from '../components/loading';
import { RoleType } from '../enums/roles.enum';
import { RouteConstants } from '../constants/routes.constants';
import { useAuth } from '../auth/authProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { withRoles } from '../auth/useRoles';

const RedirectPage = () => {
  const {
    roles,
    isAuthenticated,
    isOnboarded,
    isOnboardingEnabled,
    onboardingStage,
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      if (isOnboarded) {
        if (
          roles.includes(RoleType.SUPER_ADMIN) ||
          roles.includes(RoleType.ADMIN)
        )
          navigate(RouteConstants.allAgents.path);
        else if (roles.includes(RoleType.AGENT))
          navigate(RouteConstants.agentDashBoard.path);
      } else if (!isOnboardingEnabled) {
        //Should go to Verify details step
        navigate(RouteConstants.onboarding.path, {
          state: { stage: onboardingStage },
        });
        // if not enabled, should go to step based on stage enum
      }
    } else {
      navigate(RouteConstants.rootRedirect.path);
    }
  }, [roles]);

  return <Loading logo={true} />;
};

export default withRoles(RedirectPage, [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
  RoleType.AGENT,
]);
