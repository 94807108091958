import './assignModalstyle.css';

import {
  Button,
  Card,
  ConfigProvider,
  Modal,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
  notification,
} from 'antd';
import RadioButton, { ButtonProp } from '../common/RadioButton/radioButton';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../services/agency.service';
import AssignStateGroups from './assignStateGroups/assignStateGroups';
import AssignStates from './assignStates/assignStates';
import { AssignedState } from '../../types/data/stateGroup.types';
import { IdConstants } from '../../constants/id.constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { getStateNameFromCode } from '../../utils/common.utils';
import { updateProducerAssignmetPayload } from '../../pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { useAuth } from '../../auth/authProvider';

type AssignModalProp = {
  currentAssignedStates: AssignedState[];
  isModalVisible: boolean;
  handleVisibility?: (visibility: boolean) => void;
  agentIds: string[];
  onClose: (isCancel?: boolean) => void;
  emptyStateButton?: boolean;
  callback?: (agentIds: string[], assignedStates: AssignedState[]) => void;
  reportId?: string;
  homeState?: string[];
  isMultipleOnboard?: boolean;
  responseCallback?: (prop: any) => void;
  isLoading?: boolean;
};

function AssignModal(props: AssignModalProp) {
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [stateGroupCount, setStateGroupCount] = useState(0);
  const [stateCount, setStateCount] = useState(0);

  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const { getAccessTokenSilently } = useAuth();
  const [assignedStates, setAssignedStates] = useState([
    ...props.currentAssignedStates,
  ]);

  //TODO Add Enum For Selected Radio
  const [selectedRadio, setSelectedValue] = useState('State');
  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setSelectedValue(value);
  };

  const buttons: ButtonProp[] = [
    { value: 'State', label: 'Assign State ' },
    { value: 'Territory', label: 'Assign Territories' },
  ];

  useEffect(() => {
    setAssignedStates([...props.currentAssignedStates]);
  }, [props.currentAssignedStates]);

  useEffect(() => {
    const assignedStateGroupIds = assignedStates
      .filter((itr) => !!itr.stateGroupId)
      .map((itr) => itr.stateGroupId);

    const assignedStateCodes = assignedStates.filter(
      (itr) => !itr.stateGroupId
    );

    setStateGroupCount([...new Set(assignedStateGroupIds)].length);
    setStateCount(assignedStateCodes.length);
  }, [assignedStates]);

  const validateAssignedStatesBeforeSave = (): boolean => {
    // atleast one state must be assigned
    if (assignedStates.length === 0) {
      showError('Please assign at least one state');
      return false;
    }

    return assignedStates.every((assignedState) => {
      // atleast one licenseConfig must be assigned
      if (assignedState.licenseConfigs.length === 0) {
        showError(
          `Please assign atleast one License Configurations to the state ${getStateNameFromCode(
            assignedState.stateCode
          )}`
        );
        return false;
      }
      return assignedState.licenseConfigs.every((licenseConfig) => {
        // atleast one loaCode must be assigned
        if (licenseConfig.loaCodes.length === 0) {
          showError(
            `Please assign atleast one line of authority to the license class ${appStateInfoStore.getLcNameFromCode(
              licenseConfig.lcCode
            )} under the state ${getStateNameFromCode(assignedState.stateCode)}`
          );

          return false;
        }
        return true;
      });
    });
  };

  const updateAssignedStatesinBE = async () => {
    if (!validateAssignedStatesBeforeSave()) return;
    try {
      setIsButtonLoading(true);
      const token = await getAccessTokenSilently();
      const payload: updateProducerAssignmetPayload[] = [];
      assignedStates.forEach((d) => {
        payload.push({
          ...(d.stateGroupId ? { territoryId: d.stateGroupId } : {}),
          stateCode: d?.stateCode,
          licenseConfigurations: d?.licenseConfigs
            .flatMap((d) => d.loaids)
            .map((d) => d || '')
            .filter((d) => d),
        });
      });
      let response: any;
      if (Array.isArray(props.agentIds)) {
        response = await AgencyService.updateBulkAssignmentsForProducer(
          props.agentIds,
          payload,
          token
        );
      } else {
        response = await AgencyService.updateBulkAssignmentsForProducer(
          [props.agentIds],
          payload,
          token
        );
      }

      props.handleVisibility && props.handleVisibility(false);
      props.onClose();
      props.callback && props.callback(props.agentIds, assignedStates);
      props.responseCallback && props.responseCallback(response);
      message.success(
        response?.message || 'Producer Assignments Updated Successfully'
      );
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data?.message);
    } finally {
      setIsButtonLoading(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: ' #0588ca',
        },
      }}
    >
      {contextHolder}
      <Modal
        closable={false}
        maskClosable={false}
        open={props.isModalVisible}
        destroyOnClose={true}
        footer={[
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
            key="1"
          >
            <div
              style={{
                textAlign: 'left',
                margin: '25px',
                height: 'inherit',
                width: '50%',
              }}
            >
              <Card
                size="small"
                className="description-card"
                style={{
                  width: '65%',
                  textAlign: 'center',
                }}
              >
                You have selected {stateCount} States and {stateGroupCount}{' '}
                Territories
              </Card>
            </div>
            <div
              style={{
                textAlign: 'right',
                margin: '25px',
                right: 0,
                width: '50%',
              }}
            >
              <Button
                id={
                  IdConstants.AGENTS.ASSIGNMENTS.ASSIGN_STATE_GROUP_MODAL
                    .CANCEL_BUTTON
                }
                disabled={isButtonLoading}
                onClick={() => {
                  props.handleVisibility && props.handleVisibility(false);
                  props.onClose(true);
                  //  resetState();
                }}
              >
                Cancel
              </Button>

              <Button
                id={
                  IdConstants.AGENTS.ASSIGNMENTS.ASSIGN_STATE_GROUP_MODAL
                    .ASSIGN_BUTTON
                }
                className="button"
                key="submit"
                disabled={loading}
                loading={isButtonLoading}
                onClick={async () => {
                  if (validateAssignedStatesBeforeSave()) {
                    await updateAssignedStatesinBE();
                  }
                }}
              >
                Assign States and Territories
              </Button>
            </div>
            ,
          </div>,
        ]}
        width={1200}
      >
        <div
          style={{
            margin: '25px',
            cursor: loading ? 'not-allowed' : '',
          }}
        >
          <Typography.Paragraph className="text heading">
            <Space>
              {selectedRadio === 'Territory' ? (
                <span>Assign Territories</span>
              ) : (
                <span>Assign States</span>
              )}
              <Tooltip title="Admins can effortlessly select and assign states or territories, configure License Classes (LC), and designate Lines of Authority (LOAs) for producers—all in one centralized location.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{
              color: 'var(--light-grey2)',
              fontSize: '14px',
              fontWeight: '400',
              marginTop: '-1%',
            }}
          >
            {selectedRadio === 'Territory' ? (
              <span>
                Choose a territory and pick the license classes for all the
                states in the territory
              </span>
            ) : (
              <span>
                Select a state, then indicate the desired license class and
                coordinating lines of authority.
              </span>
            )}
          </Typography.Paragraph>

          <RadioButton
            buttons={buttons}
            id={IdConstants.INDIVIDUAL_ONBOARDING.ASSIGNMENTS.RADIO_GROUP}
            onChange={handleRadioChange}
            value={selectedRadio}
            style={{ pointerEvents: loading ? 'none' : 'auto' }}
            buttonStyle="solid"
            page="assign-state-group-modal"
          />
          <Spin spinning={props.isLoading || false}>
            {selectedRadio === 'Territory' ? (
              <AssignStateGroups
                setLoading={setLoading}
                assignedStates={assignedStates}
                setAssignedStates={setAssignedStates}
                loading={loading}
                emptyStateButton={props.emptyStateButton}
                homeState={props.homeState}
              />
            ) : (
              <AssignStates
                setLoading={setLoading}
                assignedStates={assignedStates}
                setAssignedStates={setAssignedStates}
                loading={loading}
                handleVisibility={() => {
                  props.handleVisibility && props.handleVisibility(false);
                  props.onClose();
                }}
                homeState={props.homeState}
                isMultipleOnboard={props.isMultipleOnboard}
              />
            )}
          </Spin>
        </div>
      </Modal>
    </ConfigProvider>
  );
}

export default AssignModal;
