import { Button, Card, Col, Row, Typography, notification } from 'antd';
import {
  nameNpnColumns,
  ssnColumns,
} from '../../../../utils/table-manager/lookupTemplate.table-manager';
import { useEffect, useState } from 'react';

import { AgentService } from '../../../../services/agent.service';
import CompleteOffboard from './completeOffboard';
import ErrorModal from '../../../../components/common/errorModal/errorModal';
import { IdConstants } from '../../../../constants/id.constants';
import LookupTemplatePopup from '../../../../components/common/lookupTemplatePopup/lookupTemplatePopup';
import OffboardImportAgents from './importAgents';
import { OffboardLookupType } from '../../../../enums/lookupType.enum';
import RadioGroup from '../../../../components/common/cardSelect/cardSelect';
import { RoleType } from '../../../../enums/roles.enum';
import { SelectOutlined } from '@ant-design/icons';
import googleSheetsLogo from '../../../../assets/icons/googleSheetsLogo.svg';
import { nameNpnData } from '../../../../data/mockData/offboardLookupTemplate.mockData';
import { useAuth } from '../../../../auth/authProvider';
import { withRoles } from '../../../../auth/useRoles';

function CSVOffboard() {
  const [importAgentsSuccessful, setImportAgentsSuccessful] =
    useState<boolean>();
  const [api, contextHolder] = notification.useNotification();
  const [startedOffboard, setStartedOffboard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(OffboardLookupType.NPN);
  const [agents, setAgents] = useState<any>();
  const [fileLink, setFileLink] = useState<string>(
    `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Offboard_Name_NPN.csv`
  );
  const [fileName, setFileName] = useState<string>('Offboard-Name-NPN.csv');
  const [showTemplatePopup, setShowTemplatePopup] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { getAccessTokenSilently } = useAuth();

  const infoOptions = [
    {
      title: 'Name and NPN Number',
      desc: 'These above columns should be in your file',
      value: OffboardLookupType.NPN,
    },
    {
      title: 'Name, License Number and State',
      desc: 'These above columns should be in your file',
      value: OffboardLookupType.LICENSE,
    },
  ];

  enum OffboardSteps {
    IMPORT_AGENTS = 'IMPORT_AGENTS',
    OFFBOARD = 'OFFBOARD',
  }

  const [currentStep, setCurrentStep] = useState<OffboardSteps>();

  const [data, setData] = useState<any>(ssnColumns);

  useEffect(() => {
    // if (selectedCard === OffboardLookupType.SSN) {
    //   setColumns(ssnColumns);
    //   setData(ssnData);
    //   setFileName('Offboard-SSN-LastName.csv');
    //   setFileLink(
    //     `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Offboard_SSN_LastName.csv`
    //   );
    // } else if (selectedCard === OffboardLookupType.NPN) {
    setColumns(nameNpnColumns);
    setData(nameNpnData);
    setFileName('Offboard_NPN.csv');
    setFileLink(
      `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Offboard_NPN.csv`
    );
    // } else {
    //   setColumns(licenseStateColumns);
    //   setData(licenseStateData);
    //   setFileName('Offboard-License-State.csv');
    //   setFileLink(
    //     `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Offboard_License_State.csv`
    //   );
    // }
  }, [selectedCard]);

  const offboardAgents = async () => {
    setLoading(true);
    if (agents.length > 0) {
      const token = await getAccessTokenSilently();
      const response: any = await AgentService.offboardAgentsByNpn(
        agents.map((agent: any) => agent.npn),
        token
      )
        .catch((error) => {
          console.error(error);
          api['error']({
            message: 'Error while initiating offboarding',
          });
          // setLoading(false);
          // if (props.setError) {
          //   let errorMessage =
          //     'Something went wrong on our end. Please try again in a few moments.';
          //   if (error?.data?.error?.message) {
          //     errorMessage = error?.data?.error?.message;
          //   }
          //   props.setError(errorMessage);
          // }
        })
        .finally(() => {
          setLoading(false);
        });

      api['success']({
        message: 'Offboarding initiated successfully',
        description: 'You will receive a mail once completed',
      });

      // if (props.onSuccess) {
      //   props.onSuccess();
      // }
      // if (response?.data?.message && props.setError) {
      //   props.setError(response.data.message);
      // }
      // props.setAgentsToBeOffboarded([]);
      // props.handleVisibility();
      // props.reloadPage();
      // setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      {contextHolder}
      {!startedOffboard && (
        <div>
          <Card>
            {/* <Typography.Paragraph
              style={{ fontSize: '16px', fontWeight: 500, marginBottom: 3 }}
            >
              What information would you like to use to offboard producers?
              <span style={{ color: '#F00' }}>*</span>
            </Typography.Paragraph> */}

            {/* <Row justify="space-between">
              <RadioGroup
                options={infoOptions}
                selectedCard={selectedCard}
                onOptionChange={(cardValue: OffboardLookupType) => {
                  setSelectedCard(cardValue);
                }}
              />
            </Row> */}
            <Typography.Paragraph
              style={{
                fontSize: '16px',
                fontWeight: 500,
                color: '#525252',
              }}
            >
              Keep all your producer data to be offboarded ready in a CSV File
            </Typography.Paragraph>
            <Card style={{ backgroundColor: '#F5F5F7', marginTop: '16px' }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Row align="middle">
                    <img src={googleSheetsLogo} alt="logo" />
                    <Typography.Paragraph
                      style={{
                        fontSize: '14px',
                        marginLeft: '10px',
                        marginTop: '15px',
                      }}
                    >
                      {fileName}
                    </Typography.Paragraph>
                  </Row>
                  <Typography.Paragraph
                    style={{ fontSize: '12px', color: '#ABABAB' }}
                  >
                    Download this attached example file and use it as a
                    reference <br /> to avoid error while matching column
                    attributes
                  </Typography.Paragraph>
                </Col>
                <Col>
                  <Row justify="end">
                    <Button
                      id={IdConstants.AGENTS.OFFBOARD.CSV_OFFBOARDING.OPEN_LINK}
                      size="large"
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        setShowTemplatePopup(true);
                      }}
                    >
                      <SelectOutlined /> Show Template
                    </Button>
                    <Button
                      id={IdConstants.AGENTS.OFFBOARD.CSV_OFFBOARDING.DOWNLOAD}
                      href={fileLink}
                      size="large"
                      download
                    >
                      Download
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Card>
          <Button
            id={IdConstants.AGENTS.OFFBOARD.CSV_OFFBOARDING.START_OFFBOARDING}
            size="large"
            style={{
              width: '100%',
              marginTop: '10px',
            }}
            className="button"
            onClick={() => {
              setCurrentStep(OffboardSteps.IMPORT_AGENTS);
              setStartedOffboard(true);
            }}
            disabled={selectedCard === OffboardLookupType.SSN}
          >
            Start Offboarding
          </Button>
        </div>
      )}

      {startedOffboard && (
        <div>
          {currentStep === OffboardSteps.IMPORT_AGENTS && (
            <OffboardImportAgents
              onComplete={(agents: any) => {
                setImportAgentsSuccessful(true);
                setAgents(agents);
              }}
              onUploadFailure={() => {
                setImportAgentsSuccessful(false);
              }}
              lookupMethod={selectedCard}
              template={{
                templateLink: fileLink,
                fileName: fileName,
                selectedCard: selectedCard,
                columns: columns,
                data: data,
              }}
            />
          )}
          {currentStep === OffboardSteps.OFFBOARD && (
            <CompleteOffboard
              agents={agents}
              lookupType={selectedCard}
              setStartedOffboard={setStartedOffboard}
              setError={() => {
                setErrorMessage(
                  'Something went wrong on our end. Please try again in a few moments.'
                );
              }}
            />
          )}
        </div>
      )}
      {currentStep === OffboardSteps.IMPORT_AGENTS && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
            position: 'relative',
          }}
        >
          <Button
            id={IdConstants.AGENTS.OFFBOARD.CSV_OFFBOARDING.PREVIOUS}
            size="large"
            onClick={() => {
              setCurrentStep(undefined);
              setStartedOffboard(false);
            }}
          >
            Previous
          </Button>
          <Button
            id={IdConstants.AGENTS.OFFBOARD.CSV_OFFBOARDING.NEXT}
            size="large"
            className="button"
            onClick={() => {
              offboardAgents();
            }}
            disabled={!importAgentsSuccessful}
            style={{ opacity: importAgentsSuccessful ? '100%' : '50%' }}
            loading={loading}
          >
            Finish
          </Button>
        </div>
      )}

      <LookupTemplatePopup
        visible={showTemplatePopup}
        setVisible={(visible: boolean) => {
          if (!visible) {
            setShowTemplatePopup(false);
          }
          return showTemplatePopup;
        }}
        lookupTemplateDetails={{
          columns: columns,
          selectedCard: selectedCard,
          data: data,
        }}
      />
      {errorMessage && (
        <ErrorModal
          errorMessage={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
      )}
    </div>
  );
}

export default withRoles(CSVOffboard, [RoleType.ADMIN, RoleType.SUPER_ADMIN]);
