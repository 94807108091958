import { Col, Row, Typography, notification } from 'antd';
import {
  isLcAssigned,
  isLoaAssigned,
  isStateAssigned,
} from '../../../utils/assignStates.utils';
import { useEffect, useState } from 'react';

import { AssignStateProps } from '../../../types/props/assignStates.props';
import AssignStatesCell from './assignStatesCell';
import EmptyState from '../../common/emptyState/emptyState';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { getStateNameFromCode } from '../../../utils/common.utils';
import { observer } from 'mobx-react-lite';
import { produce } from 'immer';

export interface ColumnItemType {
  code: string;
  name: string;
  isChecked: boolean;
  price?: string;
}

export interface AssignedStatesStateInfo {
  code: string;
  name: string;
  isSelected: boolean;
  currentLcCode: string;
  lcs: LCInfo[];
}
interface LCInfo {
  code: string;
  name: string;
  isSelected: boolean;
  residentType: string;
  loas: AssignedStatesLOAInfo[];
}
interface AssignedStatesLOAInfo {
  code: string;
  name: string;
  isSelected: boolean;
  price: string;
  residentType: string;
  id: string;
  isRetired: boolean;
}

function AssignStates({
  assignedStates,
  setAssignedStates,
  homeState,
  isMultipleOnboard,
}: AssignStateProps) {
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const [stateCount, setStateCount] = useState(0);
  const [isScrollValid, setScrollValid] = useState(false);
  const showError = (
    selectedItemType: 'loa' | 'lc',
    selectedItem: string,
    dependencyItemType: 'state' | 'lc',
    dependencyItem: string
  ) => {
    api['error']({
      message: 'Error',
      description: `You are trying to select the ${
        selectedItemType === 'loa' ? 'Line of Authority' : 'License Class'
      } ${selectedItem}. Please select the ${
        dependencyItemType === 'lc' ? 'License Class' : 'State'
      } ${dependencyItem} first`,
    });
  };

  // const data
  const masterData = [...appStateInfoStore.getLicenseConfigsMasterData()];

  masterData.sort((a, b) => {
    const nameA = getStateNameFromCode(a.stateCode);
    const nameB = getStateNameFromCode(b.stateCode);
    return nameA.localeCompare(nameB);
  });

  // states
  const [currentStateCode, setCurrentStateCode] = useState<string>('');
  const createStateInfos = () => {
    const result = masterData.map((stateItr) => {
      let lcs = stateItr.licenseClasses.map((lcItr) => {
        let loas = lcItr.loaData.map((loaItr) => {
          const isSelected = isLoaAssigned(
            stateItr.stateCode,
            lcItr.lcCode,
            loaItr.loaCode,
            assignedStates
          );
          return {
            code: loaItr.loaCode,
            name: loaItr.loa,
            id: loaItr.id || '',
            residentType: lcItr.residencyType,
            isSelected,
            isRetired: loaItr.isRetired,
          } as AssignedStatesLOAInfo;
        });

        loas = loas.filter((data) => {
          if (data.isSelected) return true;
          return !data.isRetired;
        });

        const isLcSelected = isLcAssigned(
          stateItr.stateCode,
          lcItr.lcCode,
          assignedStates
        );

        return {
          name: lcItr.licenseClass,
          code: lcItr.lcCode,
          isSelected: isLcSelected,
          residentType: lcItr.residencyType,
          currentLoaCode:
            lcItr.loaData &&
            lcItr.loaData.length > 0 &&
            lcItr.loaData[0].loaCode,
          loas: loas,
        } as LCInfo;
      });

      return {
        code: stateItr.stateCode,
        name: getStateNameFromCode(stateItr.stateCode),
        isSelected: isStateAssigned(stateItr.stateCode, assignedStates),
        currentLcCode: lcs?.[0]?.code,
        residentType: lcs?.[0].residentType,
        lcs,
      } as AssignedStatesStateInfo;
    });
    const selected = result.find((data) => data.isSelected);
    if (selected) {
      setCurrentStateCode(selected.code);
    } else {
      if (result?.[0]?.code) setCurrentStateCode(result?.[0]?.code);
    }
    setScrollValid(true);
    return result;
  };

  useEffect(() => {
    const element = document.getElementById(currentStateCode);
    if (element && isScrollValid) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setScrollValid(false);
    }
  }, [currentStateCode]);

  // View State
  const [stateInfos, setStateInfos] = useState<AssignedStatesStateInfo[]>([]);

  useEffect(() => {
    setStateInfos(createStateInfos());
  }, []);

  // Actions for updating stateInfos START
  const getCurrentStateInfo = () => {
    return stateInfos.find((itr) => itr.code === currentStateCode);
  };

  const getCurrentLcInfo = () => {
    const stateInfo = getCurrentStateInfo();
    return stateInfo?.lcs.find((itr) => itr.code === stateInfo.currentLcCode);
  };

  const setCurrentLcForState = (lcCode: string) => {
    const newStateInfos = stateInfos.map((itr) => {
      if (itr.code === currentStateCode) itr.currentLcCode = lcCode;
      return itr;
    });
    setStateInfos(newStateInfos);
  };

  useEffect(() => {
    setStateCount(
      stateInfos?.filter((itr) => {
        return (
          itr.isSelected &&
          !assignedStates.find(
            (assignmentItr) => itr.code === assignmentItr.stateCode
          )?.stateGroupId
        );
      }).length
    );
  }, [stateInfos]);

  const areAllLicenseClassesResidentTypeRForhomeState = (
    homeState: string[],
    currentStateCode: string
  ): boolean => {
    const licenseClasses: LCInfo[] = stateInfos
      .filter((state) => state.code === currentStateCode)
      .flatMap((state) => state.lcs);
    return (
      licenseClasses.length > 0 &&
      !homeState.includes(currentStateCode) &&
      licenseClasses.every(
        (licenseClass: LCInfo) => licenseClass.residentType === 'R'
      )
    );
  };

  const setIsSelectedForLoa = (
    stateCode: string,
    lcCode: string,
    loaCode: string,
    isSelected: boolean
  ) => {
    const newStateInfos = produce(stateInfos, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        const state = draft[i];
        if (state.code === stateCode) {
          draft[i].isSelected = true;
          for (let j = 0; j < state.lcs.length; j++) {
            const lc = state.lcs[j];
            if (lc.code === lcCode) {
              draft[i].lcs[j].isSelected = true;
              for (let k = 0; k < lc.loas.length; k++) {
                const loa = lc.loas[k];
                if (loa.code === loaCode) {
                  draft[i].lcs[j].loas[k].isSelected = isSelected;
                }
              }
            }
          }
        }
      }
    });
    setStateInfos(newStateInfos);
  };

  const setIsSelectedForLc = (
    stateCode: string,
    lcCode: string,
    isSelected: boolean
  ) => {
    const newStateInfos = produce(stateInfos, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        const state = draft[i];
        if (state.code === stateCode) {
          draft[i].isSelected = true;
          for (let j = 0; j < state.lcs.length; j++) {
            const lc = state.lcs[j];
            if (lc.code === lcCode) {
              draft[i].lcs[j].isSelected = isSelected;
              if (!isSelected) {
                for (let k = 0; k < draft[i].lcs[j].loas.length; k++) {
                  draft[i].lcs[j].loas[k].isSelected = false;
                }
              }
            }
          }
        }
      }
    });
    setStateInfos(newStateInfos);
  };

  const setIsSelectedForState = (stateCode: string, isSelected: boolean) => {
    const newStateInfos = produce(stateInfos, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        const state = draft[i];
        if (state.code === stateCode) {
          draft[i].isSelected = isSelected;
          if (!isSelected) {
            for (let j = 0; j < draft[i].lcs.length; j++) {
              draft[i].lcs[j].isSelected = false;
              for (let k = 0; k < draft[i].lcs[j].loas.length; k++) {
                draft[i].lcs[j].loas[k].isSelected = false;
              }
            }
          }
        }
      }
    });
    setStateInfos(newStateInfos);
  };
  // Actions for updating stateInfos END

  // Actions for Updating AssignedState START
  const toggleIsSelectedForState = (stateCode: string) => {
    const newAssignedStates = produce(assignedStates, (draft) => {
      const foundAssignedState = draft.find(
        (draft) => draft.stateCode === stateCode
      );
      if (foundAssignedState) {
        const foundState = draft.find(
          (assignedState) => assignedState.stateCode === stateCode
        );
        if (foundState) foundState.stateCode = '';
        setIsSelectedForState(stateCode, false);
      } else {
        draft.push({
          stateGroupId: null,
          stateCode,
          licenseConfigs: [],
          stateGroupName: null,
        });
        setIsSelectedForState(stateCode, true);
      }
    });
    setAssignedStates(
      newAssignedStates.filter((assignedState) => assignedState.stateCode)
    );
  };

  const getStateDetails = (lcCode?: string) => {
    let assignedStateDetails = assignedStates;
    const selectedStateDetails = getCurrentStateInfo();
    const isAssigned = assignedStates.filter(
      (data) => data.stateCode === selectedStateDetails?.code
    );
    if (
      (!isAssigned.length ||
        (lcCode && !isAssigned?.[0]?.licenseConfigs?.[0]?.lcCode)) && // to fix the issue of where selecting state and loa without lc
      selectedStateDetails?.code
    ) {
      let selectedDetails = {
        licenseConfigs: lcCode ? [{ lcCode, loaCodes: [], loaids: [] }] : [],
        stateCode: selectedStateDetails?.code,
        stateGroupId: null,
        stateGroupName: null,
      };
      if (!isAssigned.length) {
        assignedStateDetails = [...(assignedStates || {}), selectedDetails];
      } else {
        assignedStateDetails = assignedStates.map((data) => {
          if (data.stateCode === selectedStateDetails.code) {
            return selectedDetails;
          }
          return data;
        });
      }
    }
    return assignedStateDetails;
  };

  const toggleIsSelectedForLc = (lcCode: string) => {
    let isValid = false;
    let assignedStateDetails = getStateDetails();
    const newAssignedStates = produce(assignedStateDetails, (draft) => {
      const foundAssignedState = draft.find(
        (assignedState) => assignedState.stateCode === currentStateCode
      );
      if (foundAssignedState) {
        isValid = true;
        const foundLicenseConfig = foundAssignedState.licenseConfigs.find(
          (licenseConfig) => licenseConfig.lcCode === lcCode
        );

        if (foundLicenseConfig) {
          setIsSelectedForLc(foundAssignedState.stateCode, lcCode, false);
          foundAssignedState.licenseConfigs =
            foundAssignedState.licenseConfigs.filter(
              (licenseConfig) => licenseConfig.lcCode !== lcCode
            );
        } else {
          foundAssignedState.licenseConfigs.push({
            lcCode: lcCode,
            loaCodes: [],
            loaids: [],
          });
          setIsSelectedForLc(foundAssignedState.stateCode, lcCode, true);
        }
      } else
        showError('lc', lcCode, 'state', getCurrentStateInfo()?.name || '');
    });

    setAssignedStates(newAssignedStates);
  };

  const toggleIsSelectedForLoa = (loaCode: string, id: string) => {
    const lcCode = getCurrentStateInfo()?.currentLcCode;
    const assignedStateDetails = getStateDetails(lcCode);
    let isValid: boolean = false;
    const newAssignedStates = produce(assignedStateDetails, (draft) => {
      const foundAssignedState = draft.find(
        (assignedState) => assignedState.stateCode === currentStateCode
      );
      if (foundAssignedState) {
        const foundLicenseConfig = foundAssignedState.licenseConfigs.find(
          (licenseConfig) => licenseConfig.lcCode === lcCode
        );
        if (foundLicenseConfig) {
          isValid = true;
          const index = foundLicenseConfig.loaCodes.indexOf(loaCode);
          if (index !== -1) {
            foundLicenseConfig.loaCodes.splice(index, 1);
            foundLicenseConfig.loaids?.splice(index, 1);
            setIsSelectedForLoa(
              foundAssignedState.stateCode,
              foundLicenseConfig.lcCode,
              loaCode,
              false
            );
          } else {
            foundLicenseConfig.loaCodes.push(loaCode);
            foundLicenseConfig.loaids?.push(id);
            setIsSelectedForLoa(
              foundAssignedState.stateCode,
              foundLicenseConfig.lcCode,
              loaCode,
              true
            );
          }
        } else showError('loa', loaCode, 'lc', getCurrentLcInfo()?.name || '');
      } else
        showError('loa', loaCode, 'state', getCurrentStateInfo()?.name || '');
    });
    isValid && setAssignedStates(newAssignedStates);
  };
  // Actions for Updating AssignedState END

  return (
    <div style={{ marginTop: '20px' }}>
      {contextHolder}
      <Row
        style={{
          border: '1px solid var(--border-color)',
          borderRadius: '5px',
        }}
      >
        <Col
          span="6"
          style={{
            border: '1px solid var(--hover-color)',
            height: '50vh',
            textAlign: 'center',
            overflowY: 'auto',
            position: 'relative',
            whiteSpace: 'nowrap',
          }}
        >
          <Row
            align="middle"
            style={{
              textAlign: 'center',
              position: 'sticky',
              top: '0',
              backgroundColor: 'var(--hover-color)',
              zIndex: 10,
              borderBottom: '1px solid var(--border-color)',
              borderRight: '1px solid var(--border-color)',
              height: '15%',
            }}
          >
            <Typography.Paragraph
              style={{
                marginTop: '10px',
                marginLeft: '20%',
                fontWeight: 600,
                fontSize: '14px',
                color: 'var(--secondary-color)',
              }}
            >
              State
            </Typography.Paragraph>
          </Row>
          {stateInfos.map((state) => {
            const isDisabled = assignedStates.some(
              (itr) => itr.stateGroupId && itr.stateCode === state.code
            );
            const disableTheStateCardForRLicenses =
              areAllLicenseClassesResidentTypeRForhomeState(
                homeState ? homeState : [],
                state.code
              );
            return (
              <div id={state.code}>
                <AssignStatesCell
                  field="state"
                  key={state.code}
                  name={getStateNameFromCode(state.code)}
                  id={state.code}
                  isChecked={state.isSelected}
                  setCurrentValue={() => {
                    setCurrentStateCode(state.code);
                    if (
                      !isDisabled &&
                      !areAllLicenseClassesResidentTypeRForhomeState
                    ) {
                      toggleIsSelectedForState(state.code);
                    }
                  }}
                  toggleIsChecked={() => {
                    if (!isDisabled) {
                      toggleIsSelectedForState(state.code);
                    }
                  }}
                  currentState={state.code}
                  currentValue={currentStateCode}
                  disabled={isDisabled}
                  disableTheStateCardForRLicenses={
                    disableTheStateCardForRLicenses
                  }
                />
              </div>
            );
          })}
        </Col>
        <Col
          span="10"
          style={{
            border: '1px solid var(--hover-color)',
            height: '50vh',
            textAlign: 'center',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          <Row
            align="middle"
            style={{
              zIndex: 10,
              position: 'sticky',
              top: '0',
              height: '15%',
              backgroundColor: 'var(--hover-color)',
              borderBottom: '1px solid var(--border-color)',
              borderRight: '1px solid var(--border-color)',
            }}
          >
            <Typography.Paragraph
              style={{
                marginTop: '10px',
                marginLeft: '10%',
                fontWeight: 600,
                fontSize: '14px',
                color: 'var(--secondary-color)',
              }}
            >
              License Classes
              <span
                style={{
                  fontWeight: 500,
                  fontSize: '12px',
                  color: 'var(--light-grey2)',
                  marginTop: '-1%',
                }}
              >
                <br />
                {currentStateCode &&
                  `for ${getStateNameFromCode(currentStateCode)}`}
              </span>
            </Typography.Paragraph>
          </Row>

          {getCurrentStateInfo() && currentStateCode ? (
            getCurrentStateInfo()?.lcs.map((lc) => (
              <AssignStatesCell
                field="licenses"
                name={lc.name}
                id={lc.code}
                isChecked={lc.isSelected}
                setCurrentValue={() => {
                  setCurrentLcForState(lc.code);
                }}
                toggleIsChecked={() => {
                  toggleIsSelectedForLc(lc.code);
                }}
                currentValue={getCurrentStateInfo()?.currentLcCode || ''}
                disabled={assignedStates.some(
                  (itr) =>
                    itr.stateGroupId &&
                    itr.stateCode === getCurrentStateInfo()?.code
                )}
                residentType={lc.residentType}
                currentState={isMultipleOnboard ? '' : currentStateCode}
                homeState={homeState ? homeState : []}
              />
            ))
          ) : (
            <div
              style={{
                height: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <EmptyState
                heading="No License Class to show"
                content="License Class will come up after choosing a State"
              />
            </div>
          )}
        </Col>
        <Col
          span="8"
          style={{
            border: '1px solid var(--hover-color)',
            textAlign: 'center',
            height: '50vh',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          <Row
            align="middle"
            style={{
              textAlign: 'center',
              zIndex: 10,
              position: 'sticky',
              top: '0',
              backgroundColor: 'var(--hover-color)',
              height: '15%',
              borderBottom: '1px solid var(--border-color)',
              borderRight: '1px solid var(--border-color)',
            }}
          >
            <Typography.Paragraph
              style={{
                marginTop: '10px',
                marginLeft: '15%',
                fontWeight: 600,
                fontSize: '14px',
                color: 'var(--secondary-color)',
              }}
            >
              Lines of Authorities{' '}
              <span
                style={{
                  fontWeight: 500,
                  fontSize: '12px',
                  color: 'var(--light-grey2)',
                  marginTop: '-1%',
                }}
              >
                <br />
                {getCurrentLcInfo()?.name &&
                  `under ${getCurrentLcInfo()?.name}`}
              </span>
            </Typography.Paragraph>
          </Row>
          {getCurrentLcInfo() && getCurrentStateInfo()?.currentLcCode ? (
            getCurrentLcInfo()?.loas.map((loa) => (
              <AssignStatesCell
                field="line-of-authority"
                name={loa.name}
                id={loa.code}
                isChecked={loa.isSelected}
                price={loa.price}
                toggleIsChecked={() => {
                  toggleIsSelectedForLoa(loa.code, loa.id);
                }}
                columnName="loa"
                disabled={assignedStates.some(
                  (itr) =>
                    itr.stateGroupId &&
                    itr.stateCode === getCurrentStateInfo()?.code
                )}
                residentType={loa.residentType}
                currentState={currentStateCode}
                homeState={homeState ? homeState : []}
              />
            ))
          ) : (
            <div
              style={{
                height: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <EmptyState
                heading="No Lines of Authority to show"
                content="Lines of Authority will come up after choosing a License Class"
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default observer(AssignStates);
