import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ReactNode } from 'react';
import { Typography } from 'antd';

type Props = {
  children?: ReactNode;
  onVerify: (token: any) => void;
  refreshReCaptcha: boolean;
  style?: React.CSSProperties;
  showDisclaimer?: boolean;
};
export const ReCaptchaWrapper = ({
  children,
  onVerify,
  refreshReCaptcha,
  showDisclaimer = false,
  style = {},
}: Props) => {
  return (
    <div style={style}>
      <GoogleReCaptcha
        onVerify={onVerify}
        refreshReCaptcha={refreshReCaptcha}
      />
      {children}
      {showDisclaimer && (
        <>
          <Typography.Paragraph
            type="secondary"
            style={{ fontSize: 11, marginTop: 5 }}
            className="no-margin-bottom"
          >
            This site is protected by reCAPTCHA.
          </Typography.Paragraph>
          <Typography.Paragraph
            type="secondary"
            style={{ fontSize: 11 }}
            className="no-margin-bottom"
          >
            Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
            apply
          </Typography.Paragraph>
        </>
      )}
    </div>
  );
};
