import { Row, Tag, Typography } from 'antd';

import { costFormatter } from '../../utils/input.utils';

function FeesCell({
  fees,
  isEstimate,
}: {
  fees: number | null;
  isEstimate: boolean;
}) {
  return fees ? (
    <Row align="middle">
      <Typography.Paragraph
        style={{
          fontSize: '14px',
          fontWeight: 500,
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        ${costFormatter(fees)}
        <br />
        {isEstimate && (
          <Tag
            style={{
              borderRadius: '4px',
              textAlign: 'center',
              marginInlineEnd: '0px',
            }}
            color={'#00a6fb'}
            key={fees}
          >
            Estimated
          </Tag>
        )}
      </Typography.Paragraph>
    </Row>
  ) : (
    <></>
  );
}

export default FeesCell;
