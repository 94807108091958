import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
export const PDFViewer: React.FC<{
  pdfUrl: string;
  setOpenModal?: (open: boolean) => void;
  fileType?: string;
  onPreviewClose?: () => void;
}> = ({ pdfUrl, fileType = 'pdf' }) => {
  return (
    <div>
      <DocViewer
        documents={[
          {
            uri: pdfUrl,
            fileType,
          },
        ]}
        pluginRenderers={DocViewerRenderers}
        style={{ width: '100%', height: 800 }}
      />
    </div>
  );
};
