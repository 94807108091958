import { Avatar, Tooltip } from 'antd';

import React from 'react';

function StatesCircled(prop: {
  label: string;
  backgroundColor?: string;
  labelColor?: string;
  showTooltip?: boolean;
  tooltipTitle?: string;
  style?: React.CSSProperties;
}) {
  return prop.showTooltip ? (
    <Tooltip title={prop.tooltipTitle}>
      <Avatar
        style={{
          backgroundColor: prop.backgroundColor ?? '#D3F3F5',
          color: prop.labelColor ?? '#000000',
          width: '26px',
          height: '26px',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          justifyItems: 'center',
          marginRight: '4px',
          cursor: 'default',
          ...prop.style,
        }}
      >
        {prop.label || <span>-</span>}
      </Avatar>
    </Tooltip>
  ) : (
    <Avatar
      style={{
        backgroundColor: prop.backgroundColor ?? '#D3F3F5',
        color: prop.labelColor ?? '#000000',
        width: '26px',
        height: '26px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyItems: 'center',
        marginRight: '4px',
        cursor: 'pointer',
        ...prop.style,
      }}
    >
      {prop.label || '-'}
    </Avatar>
  );
}

export default StatesCircled;
