import { Button, Layout } from 'antd';

import { IdConstants } from '../../../constants/id.constants';
import { LogoutOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import logo from '../../../assets/icons/insuretrek-logo.svg';
import { useAuth } from '../../../auth/authProvider';

const { Content, Header } = Layout;

interface AdminSignUpLayoutProps {
  children: ReactNode;
}

function AdminSignUpLayout({ children }: AdminSignUpLayoutProps) {
  const { performLogout } = useAuth();

  return (
    <div>
      <Header
        style={{
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'flex-end',
          position: 'relative',
        }}
      >
        <img
          style={{
            margin: 'auto',
            width: '15%',
            marginTop: '10px',
          }}
          src={logo}
        />
        <Button
          id={IdConstants.SIGNUPFLOW.LOGOUT_BUTTON}
          type="primary"
          className="button important-action-button"
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            right: 50,
          }}
          onClick={() => {
            performLogout(window.location.origin);
          }}
          icon={<LogoutOutlined />}
        >
          Logout
        </Button>
      </Header>
      <Content style={{ padding: '20px 50px' }}>{children}</Content>
    </div>
  );
}

export default AdminSignUpLayout;
