import { Card, Row, Skeleton, Typography } from 'antd';
import { useEffect, useState } from 'react';

import { IdConstants } from '../../../../constants/id.constants';
import { RightOutlined } from '@ant-design/icons';
import { hierarchyViewStore } from '../../../../stores/hierarchyViewStore';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../../../auth/authProvider';

export interface AgencyDetails {
  name: string;
  lineOnlyAgentsCount: number;
  generalAgentsCount: number;
  totalAgentDownlinesCount: number;
  totalAgencyDownlinesCount: number;
}
type RootCardProps = {
  agencyId: string;
  isSelected: boolean;
};

function RootCard({ agencyId, isSelected }: RootCardProps) {
  const { getAccessTokenSilently } = useAuth();
  const [open, setOpen] = useState([false, false]);
  const [isLoading, setLoading] = useState(false);

  const loadInit = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();

    await hierarchyViewStore
      .getDistributionChannelDetails(agencyId, token, true)

      .catch((error: any) => {
        console.error(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    loadInit();
  }, []);

  const handleCollapse = (index: number, value: boolean) => {
    setOpen((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = value;
      return updatedState;
    });
  };

  return (
    <Card
      style={{
        border: '1px solid var(--border-color)',
        margin: 'auto',
        width: '100%',
        cursor: 'pointer',
      }}
      bodyStyle={{ padding: '5px 10px' }}
    >
      {/*<Image src={icon} preview={false} />
      <Text type="secondary"> Your Agency</Text> */}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <Row
            id={IdConstants.HIERARCHY_VIEW.TOTAL_AGENTS}
            style={{
              cursor: 'pointer',
              justifyContent: 'space-between',
              paddingRight: '10px',
            }}
            onClick={() => {
              handleCollapse(0, !open[0]);
            }}
          >
            {' '}
            <Typography.Paragraph
              style={{
                fontSize: '12px',
                fontWeight: 400,
                color: 'var(--secondary-color)',
              }}
            >
              Total Producers
              <RightOutlined
                style={{
                  color: 'var(--primary-color)',
                  marginLeft: '10px',
                  transform: open[0] ? 'rotate(-90deg)' : 'rotate(0deg)',
                }}
              />
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {hierarchyViewStore.lineOnlyAgentsCount}
            </Typography.Paragraph>
          </Row>{' '}
          {open?.[0] && (
            <div style={{ paddingRight: '10px' }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <Row>
                  <div
                    style={{
                      width: '3px',
                      height: '20px',
                      backgroundColor: 'var(--border-color)',
                      marginLeft: '10px',
                    }}
                  ></div>
                  <Typography.Paragraph
                    style={{
                      marginTop: '3px',
                      fontSize: '10px',
                      color: '#858585',
                      marginLeft: '10px',
                    }}
                  >
                    Line Only Producers
                  </Typography.Paragraph>
                </Row>
                <Typography.Paragraph
                  style={{
                    fontSize: '10px',
                    fontWeight: 400,
                  }}
                >
                  {hierarchyViewStore.lineOnlyAgentsCount}
                </Typography.Paragraph>
              </Row>
            </div>
          )}
          <hr
            style={{
              marginTop: '2px',
              backgroundColor: 'var(--border-color)',
              border: '0.4px dashed var(--border-color)',
            }}
          />
          <Row
            id={IdConstants.HIERARCHY_VIEW.TOTAL_DOWNLINES}
            style={{
              cursor: 'pointer',
              justifyContent: 'space-between',
              paddingRight: '10px',
            }}
            onClick={() => {
              handleCollapse(1, !open[1]);
            }}
          >
            {' '}
            <Typography.Paragraph
              style={{
                fontSize: '12px',
                fontWeight: 400,
                color: 'var(--secondary-color)',
              }}
            >
              Total Downlines
              <RightOutlined
                style={{
                  color: 'var(--primary-color)',
                  marginLeft: '10px',
                  transform: open[1] ? 'rotate(-90deg)' : 'rotate(0deg)',
                }}
              />
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {hierarchyViewStore?.distributionChannelDetails?.length}
            </Typography.Paragraph>
          </Row>{' '}
          {open[1] && (
            <div style={{ paddingRight: '10px' }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <Row>
                  <div
                    style={{
                      width: '3px',
                      height: '20px',
                      backgroundColor: 'var(--border-color)',
                      marginLeft: '10px',
                    }}
                  ></div>
                  <Typography.Paragraph
                    style={{
                      marginTop: '3px',
                      fontSize: '10px',
                      color: '#858585',
                      marginLeft: '10px',
                    }}
                  >
                    Producers
                  </Typography.Paragraph>
                </Row>
                <Typography.Paragraph
                  style={{
                    fontSize: '10px',
                    fontWeight: 400,
                  }}
                >
                  {hierarchyViewStore.downlineAgentCount}
                </Typography.Paragraph>
              </Row>
              <Row style={{ justifyContent: 'space-between' }}>
                <Row>
                  <div
                    style={{
                      width: '3px',
                      height: '20px',
                      backgroundColor: 'var(--border-color)',
                      marginLeft: '10px',
                    }}
                  ></div>
                  <Typography.Paragraph
                    style={{
                      marginTop: '3px',
                      fontSize: '10px',
                      color: '#858585',
                      marginLeft: '10px',
                    }}
                  >
                    Agency
                  </Typography.Paragraph>
                </Row>
                <Typography.Paragraph
                  style={{
                    fontSize: '10px',
                    fontWeight: 400,
                  }}
                >
                  {hierarchyViewStore.downlineAgencyCount}
                </Typography.Paragraph>
              </Row>
            </div>
          )}
        </div>
      )}
    </Card>
  );
}

export default observer(RootCard);
