import { isNumber } from 'lodash';
import { makeAutoObservable } from 'mobx';

class TourStore {
  sideBarOpen: boolean = false;
  signupDemoTour: boolean = false;
  signUpDemoStep: number = 0;
  demoTourVisible: boolean = false;
  showWalletStep: boolean = false;

  agencySetupTourVisible: boolean = false;
  agencySetupTourPhase: number = 1;
  agencySetupCurrentStep: number = 0;

  showProducerOnboardingTour: boolean = false;
  producerOnboardingTour: boolean = false;
  producerOnboardingCurrentStep: number = 0;
  producerTourCompletedStep: number = 0;
  producerSection: number = 1;

  rtsByProducerTour: boolean = false;
  rtsByProducerCurrentStep: number = 0;
  rtsAgentsLoading: boolean = false;
  rtsByProducerVisible: boolean = false;

  rtsByAgencyTour: boolean = false;
  rtsByAgencyCurrentStep: number = 0;
  rtsByProducerLoading: boolean = false;

  manageTerritoryTour: boolean = false;
  manageTerritoryCurrentStep: number = 0;

  inviteDownlineDemoTour: boolean = false;
  inviteDownlineDemoTourVisible: boolean = false;
  inviteDownlineDemoTourCurrentStep: number = 0;
  inviteDownlineDemoTourPhase: number = 1;

  inviteUsersTour: boolean = false;
  inviteUsersTourVisible: boolean = false;
  inviteUsersTourCurrentStep: number = 0;
  inviteUsersTourPhase: number = 1;

  walletTour: boolean = false;
  walletCurrentStep: number = 0;
  walletLoading: boolean = false;
  walletModal: boolean = false;

  setWalletTour(open: boolean) {
    this.walletTour = open;
  }
  setWalletModal(open: boolean) {
    this.walletModal = open;
  }
  setWalletLoading(loading: boolean) {
    this.walletLoading = loading;
  }
  setWalletCurrentStep(isNext: boolean | number) {
    if (isNumber(isNext)) this.walletCurrentStep = isNext;
    else this.walletCurrentStep = this.walletCurrentStep + (isNext ? 1 : -1);
  }

  setSideBarOpen(open: boolean) {
    this.sideBarOpen = open;
  }

  setRtsByProducerLoading(loading: boolean) {
    this.rtsByProducerLoading = loading;
  }

  setAgencySetupCurrentStep(isNext: boolean | number) {
    if (isNumber(isNext)) this.agencySetupCurrentStep = isNext;
    else
      this.agencySetupCurrentStep =
        this.agencySetupCurrentStep + (isNext ? 1 : -1);
  }
  setAgencySetupPhase(phase: number) {
    this.agencySetupTourPhase = phase;
  }
  setAgencySetupTourVisible(visible: boolean) {
    this.agencySetupTourVisible = visible;
  }

  setSignUpDemoTour = (show: boolean) => {
    this.signupDemoTour = show;
  };
  setDemoTourVisible = (show: boolean) => {
    this.demoTourVisible = show;
  };
  setShowWalletStep = (show: boolean) => {
    this.showWalletStep = show;
  };
  setSignUpDemoStep = (isNext: boolean | number) => {
    if (isNumber(isNext)) this.signUpDemoStep = isNext;
    else this.signUpDemoStep = this.signUpDemoStep + (isNext ? 1 : -1);
  };

  setShowProducerOnboardTour = (show: boolean) => {
    this.showProducerOnboardingTour = show;
  };

  setProducerOnboardingTour = (isOpen: boolean) => {
    this.producerOnboardingTour = isOpen;
  };

  setProducerOnboardingCurrentStep = (step: number) => {
    this.producerOnboardingCurrentStep = step;
  };

  setProducerCompletedStep = (completedStep: number) => {
    this.producerTourCompletedStep = completedStep;
  };

  setProducerSection = (section: number) => {
    this.producerSection = section;
  };

  setManageTerritoryTour = (isOpen: boolean) => {
    this.manageTerritoryTour = isOpen;
  };
  setManageTerritoryCurrentStep = (isNext: boolean | number) => {
    if (isNumber(isNext)) this.manageTerritoryCurrentStep = isNext;
    else
      this.manageTerritoryCurrentStep =
        this.manageTerritoryCurrentStep + (isNext ? 1 : -1);
  };

  setRtsAgentsLoading = (isLoading: boolean) => {
    this.rtsAgentsLoading = isLoading;
  };
  setRtsByProducerTour = (isOpen: boolean) => {
    this.rtsByProducerTour = isOpen;
  };
  setRtsByProducerVisible = (isVisible: boolean) => {
    this.rtsByProducerVisible = isVisible;
  };
  setRtsByProducerCurrentStep = (isNext: boolean | number) => {
    if (isNumber(isNext)) this.rtsByProducerCurrentStep = isNext;
    else
      this.rtsByProducerCurrentStep =
        this.rtsByProducerCurrentStep + (isNext ? 1 : -1);
  };

  setRtsByAgencyTour = (isOpen: boolean) => {
    this.rtsByAgencyTour = isOpen;
  };
  setRtsByAgencyCurrentStep = (isNext: boolean | number) => {
    if (isNumber(isNext)) this.rtsByAgencyCurrentStep = isNext;
    else
      this.rtsByAgencyCurrentStep =
        this.rtsByAgencyCurrentStep + (isNext ? 1 : -1);
  };

  setInviteDownlineDemoTourPhase = (phase: number) => {
    this.inviteDownlineDemoTourPhase = phase;
  };
  setInviteDownlineDemoTour = (isOpen: boolean) => {
    this.inviteDownlineDemoTour = isOpen;
  };
  setInviteDownlineDemoTourVisible = (isOpen: boolean) => {
    this.inviteDownlineDemoTourVisible = isOpen;
  };
  setInviteDownlineDemoTourCurrentStep = (isNext: boolean | number) => {
    if (isNumber(isNext)) this.inviteDownlineDemoTourCurrentStep = isNext;
    else
      this.inviteDownlineDemoTourCurrentStep =
        this.inviteDownlineDemoTourCurrentStep + (isNext ? 1 : -1);
  };

  setInviteUsersTourPhase = (phase: number) => {
    this.inviteUsersTourPhase = phase;
  };
  setInviteUsersTour = (isOpen: boolean) => {
    this.inviteUsersTour = isOpen;
  };
  setInviteUsersTourVisible = (isOpen: boolean) => {
    this.inviteUsersTourVisible = isOpen;
  };
  setInviteUsersTourCurrentStep = (isNext: boolean | number) => {
    if (isNumber(isNext)) this.inviteUsersTourCurrentStep = isNext;
    else
      this.inviteUsersTourCurrentStep =
        this.inviteUsersTourCurrentStep + (isNext ? 1 : -1);
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export const tourStore = new TourStore();
