import { FiltersType, LabelPairType } from '../types/common/filters.type';

export const setFilterGroups = (selectedFilters: FiltersType) => {
  return Object.entries(selectedFilters.data).map(([key, value]) => {
    if (value.isDate) {
      const filters = Array.isArray(value.labelValuePair)
        ? value.labelValuePair
        : [];
      return {
        operator: 'or',
        filters: [
          {
            condition: 'range',
            value: filters.map(
              (filterValue: LabelPairType) => filterValue.value
            ),
            key: filters?.[0]?.label || '',
            label: filters?.[0]?.label || '',
          },
        ],
      };
    }

    const filterValues = Array.isArray(value.labelValuePair)
      ? value.labelValuePair.map((labelPair) => labelPair.value)
      : [value.labelValuePair.value];

    const filterCondition = filterValues.length > 1 ? 'contains' : 'is';

    return {
      operator: 'or',
      filters: filterValues.map((filterValue: string) => ({
        key: value.key,
        condition: filterCondition,
        value: filterValue,
      })),
    };
  });
};
