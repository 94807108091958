export enum AppEventSubTypes {
  // Gateway
  CreateGatewayTransaction = 'create_gateway_transaction',

  // Onboarding
  FilledEmploymentHistory = 'filled_employment_history',
  FilledBGQuestions = 'filled_bg_questions',
  Onboarded = 'onboarded',

  // Assignment Update
  AssignmentAdded = 'assignment_added',
  AssignmentRemoved = 'assignment_removed',
  AssignmentUpdated = 'assignment_updated',

  // State Update
  StateAdded = 'state_added',
  StateRemoved = 'state_removed',
  StateAssignmentUpdated = 'state_assignment_updated',

  // License Application
  NewLicenseApplication = 'new_license_application',
  LicenseRenewal = 'license_renewal',
  MissingLOAs = 'missing_loas_application',

  // License Response
  LicenseApplicationResponse = 'license_application_response',
  LicenseRenewalResponse = 'license_renewal_response',
  MissingLOAsResponse = 'missing_loas_response',
  LicenseExpired = 'license_expired',

  // License Confirmation
  IssuedConfirmation = 'issued_confirmation',
  RejectedConfirmation = 'rejected_confirmation',

  // Territory Move
  StateGroupAdded = 'state_group_added',
  StateGroupRemoved = 'state_group_removed',
  StateGroupUpdated = 'state_group_updated',
  StateGroupDuplicated = 'state_group_duplicated',
  StateGroupConvertedToAssignments = 'state_group_converted_to_assignments',

  // Agent Portal
  UpdatedBGQuestions = 'updated_bg_questions',
  UpdatedEmploymentHistory = 'updated_employment_history',

  // Offboarding
  Offboarded = 'offboarded',

  // Watchlisting
  Watchlisted = 'watchlisted',
}
