import { Address } from '../types/data/address.type';

export const getDefaultAddress = (): Address => ({
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  state: '',
  zip: '',
  country: '',
});
