import { Button, Modal, Row, Table, Typography } from 'antd';

export interface LookupTemplateDetails {
  selectedCard: string;
  columns: any;
  data: any;
}

type lookupTemplateProps = {
  visible: boolean;
  setVisible: (visible: boolean) => boolean;
  lookupTemplateDetails: LookupTemplateDetails;
};

function LookupTemplatePopup(props: lookupTemplateProps) {
  return (
    <Modal
      width={750}
      open={props.visible}
      closable={false}
      centered
      footer={
        <div>
          <Button
            size="large"
            style={{
              width: '100%',
              marginLeft: '-5px',
            }}
            onClick={() => {
              props.setVisible(false);
            }}
          >
            Close
          </Button>
        </div>
      }
    >
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        {props.lookupTemplateDetails.selectedCard}
      </Typography.Paragraph>
      <Typography.Paragraph className="desc-style">
        These columns should be in your file
      </Typography.Paragraph>
      <Row>
        <Table
          style={{ width: '100%' }}
          columns={props.lookupTemplateDetails.columns}
          dataSource={props.lookupTemplateDetails.data}
          pagination={false}
        />
      </Row>
    </Modal>
  );
}

export default LookupTemplatePopup;
