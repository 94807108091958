import { Button, Col, Row, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';

import Loading from '../../components/loading';
import { StripeService } from '../../services/stripe.service';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../auth/authProvider';

const StripeCustomerPortal = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [billingPortalURL, setBillingPortalURL] = useState<string>('');
  const [api, contextHolder] = notification.useNotification();
  const { bearerToken } = useAuth();

  useEffect(() => {
    if (bearerToken) {
      StripeService.getBillingPortalURL(bearerToken)
        .then((response: any) => {
          setBillingPortalURL(response?.data?.billingDashboardUrl);
          window.open(
            response?.data?.billingDashboardUrl,
            '_blank',
            'rel=noopener noreferrer'
          );
        })
        .catch((err) => {
          console.log('Error while getting billing portal :: ', err);
          api['error']({
            message: 'Something went wrong',
            description:
              'Unable to retrieve your payment configuration. Please try again later',
          });
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError(true);
      api['error']({
        message: 'Something went wrong',
        description:
          'Unable to retrieve your payment configuration. Please try again later',
      });
    }
  }, []);

  return (
    <>
      {/* DND */}
      {contextHolder}
      {/* DND END */}
      <Row style={{ flexGrow: 'inherit' }} justify={'center'}>
        <Col xs={12}>
          {loading ? (
            <Loading />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <Typography.Paragraph>
                You will be navigated to our partner's portal to view your
                invoices, payment methods and subscription details
              </Typography.Paragraph>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type="primary"
                  disabled={error}
                  style={{ width: '50%' }}
                  target="_blank"
                  href={billingPortalURL}
                  className="btn btn-primary"
                >
                  Navigate
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default observer(StripeCustomerPortal);
