import {
  AgencyLoadedData,
  DownlineAgencyHierarchyDetails,
} from '../../../../stores/hierarchyViewStore';
import { Card, Typography } from 'antd';

import { RightOutlined } from '@ant-design/icons';
import { getNameString } from '../../../../utils/name.utils';
import { observer } from 'mobx-react-lite';

type CardProps = {
  data: DownlineAgencyHierarchyDetails;

  isSelected: boolean;
};

function DownlinesCard({ data, isSelected }: CardProps) {
  return (
    <Card
    key={data._id}
      style={{
        border: isSelected
          ? '1px solid var(--secondary-color)'
          : '1px solid var(--border-color)',
        margin: '0px 10px',
        width: '175px',
      }}
      bodyStyle={{
        padding: '5px 10px',
        display: 'flex',
        flexDirection: 'row',
        columnGap: '5px',
      }}
    >
      <div className="width-100">
        {data.name && (
          <div>
            <Typography.Paragraph
              style={{ fontSize: '8px', color: 'var(--primary-color)' }}
            >
              {data.type === 'agency' ? 'Agency' : 'Producer'}
            </Typography.Paragraph>
            <div
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex',
                marginBottom: 6,
                gap: 12,
                width: '100%',
              }}
            >
              <Typography.Paragraph
                style={{ fontSize: '12px', marginBottom: 0 }}
              >
                {getNameString(data.name)}
              </Typography.Paragraph>
              <RightOutlined />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}

export default observer(DownlinesCard);
