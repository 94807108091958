import 'dayjs/locale/zh-cn';

import { Button, Calendar, Col, Popover, Row, Select } from 'antd';
import React, { useState } from 'react';

import { CalendarOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';

dayjs.extend(dayLocaleData);

type pickerProp = {
  needed?: boolean;
  selectedValue?: Dayjs;
  type: 'month' | 'date';
  setSelectedValue?: (date: any) => void;
  customButton?: boolean;
};

/**
 * CustomPicker Component
 *
 * @param {object} props - The component props.
 * @param {boolean} props.needed - Determines whether the picker is needed or not.
 * @param {Dayjs} props.selectedvalue - The selected value of the picker.
 * @param {function} props.setSelectedValue - Callback function to set the selected value.
 * @param {string} props.type - The type of the picker ('month' or other).
 * @returns {JSX.Element} The CustomPicker component.
 */

function CustomPicker({
  needed,
  selectedValue,
  setSelectedValue,
  type,
  customButton,
}: pickerProp) {
  const [open, setOpen] = useState(false);

  const handleDateChange = (date: Dayjs, isYear?: string) => {
    setSelectedValue && setSelectedValue(date);
    if (!isYear && type === 'month') {
      setOpen(false);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    !needed && setOpen(newOpen);
  };

  if (type === 'month') {
    return (
      <Popover
        open={open}
        className="ant-custom-picker-popover"
        onOpenChange={handleOpenChange}
        popupVisible={needed}
        content={
          <div style={{ width: 250 }}>
            <Calendar
              fullscreen={false}
              onChange={handleDateChange}
              value={selectedValue ? selectedValue : dayjs()}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                type !== 'year' && onTypeChange('year');
                const currentYear = new Date().getFullYear();
                const options = [];
                for (let i = currentYear; i > currentYear - 30; i -= 1) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>
                  );
                }
                if (!value) {
                  value = dayjs();
                }
                return (
                  <div style={{ padding: 8 }}>
                    <Row gutter={8}>
                      <Col>
                        <Select
                          id={options.toString()}
                          size="small"
                          className="my-year-select"
                          value={value.year()}
                          onChange={(newYear) => {
                            const now = value.clone().year(newYear);
                            handleDateChange(now, 'year');
                          }}
                        >
                          {options}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                );
              }}
              disabledDate={(date) => {
                const currentMonth = new Date().getMonth();
                const currentYear = new Date().getFullYear();
                const month = date.month();
                const year = date.year();
                if (year !== currentYear) return false;
                else return currentMonth < month;
              }}
            />
          </div>
        }
      >
        {customButton ? (
          <Button
            placeholder="Enter your Start Date"
            disabled={needed}
            style={{
              borderColor: 'var(--border-color)',
              marginRight: '20px',
              minWidth: '100%',
            }}
            className="custom-date-button flex justify-between align-items-center"
          >
            <p>{selectedValue?.format('MM/YYYY')}</p>
            <CalendarOutlined style={{ color: 'var(--secondary-color)' }} />
          </Button>
        ) : (
          <Button
            icon={
              <CalendarOutlined style={{ color: 'var(--secondary-color)' }} />
            }
            disabled={needed}
            style={{ borderColor: 'var(--border-color)', marginLeft: '10px' }}
          />
        )}
      </Popover>
    );
  } else {
    return (
      <Popover
        open={open}
        onOpenChange={handleOpenChange}
        popupVisible={needed}
        content={
          <div style={{ width: 250 }}>
            <Calendar
              fullscreen={false}
              onChange={handleDateChange}
              value={selectedValue}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                const start = 0;
                const end = 12;
                const monthOptions = [];
                let current = value.clone();
                const localeData = value.localeData();
                const months = [];
                for (let i = 0; i < 12; i++) {
                  current = current.month(i);
                  months.push(localeData.monthsShort(current));
                }

                for (let i = start; i < end; i++) {
                  monthOptions.push(
                    <Select.Option key={i} value={i} className="month-item">
                      {months[i]}
                    </Select.Option>
                  );
                }

                const year = value.year();
                const month = value.month();
                const options = [];
                for (let i = year; i > year - 60; i -= 1) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>
                  );
                }
                return (
                  <div style={{ padding: 8 }}>
                    <Row gutter={8}>
                      <Col>
                        <Select
                          size="small"
                          className="my-year-select"
                          value={year}
                          onChange={(newYear) => {
                            const now = value.clone().year(newYear);
                            onChange(now);
                          }}
                        >
                          {options}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          size="small"
                          value={month}
                          onChange={(newMonth) => {
                            const now = value.clone().month(newMonth);
                            onChange(now);
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                );
              }}
            />
          </div>
        }
      >
        <Button
          icon={
            <CalendarOutlined style={{ color: 'var(--secondary-color)' }} />
          }
          disabled={needed}
          style={{ borderColor: 'var(--border-color)', marginLeft: 5 }}
        />
      </Popover>
    );
  }
}

export default CustomPicker;
