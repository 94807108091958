import React, { ReactNode } from 'react';

import Icon from '@ant-design/icons';

type IconProps = {
  color?: string;
  icon: string | ReactNode;
  altText?: string;
};

function CustomIcon({ color, icon, altText }: IconProps) {
  return typeof icon === 'string' ? (
    <Icon
      component={() => (
        <>
          {<img src={icon} alt={altText} style={{ width: 16, height: 16 }} />}
        </>
      )}
    />
  ) : (
    <>{icon}</>
  );
}

export default CustomIcon;
