import {
  Button,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import {
  HeadingContainer,
  ModalContainer,
  TextWrapper,
  TitleCard,
} from './apply-license.style';
import React, { useState } from 'react';

import { ColumnsType } from 'antd/es/table';
import { IdConstants } from '../../../../constants/id.constants';
import { LicenseApplicationResponse } from '../../../../types/response/license-application.type';
import { LicensesService } from '../../../../services/licenses.service';
import { LoaDetail } from '../../../../types/data/loaDetail.type';
import { LoaStatus } from '../../../../enums/loaStatus.enum';
import { SuccessMessageConstants } from '../../../../constants/succes-message.constants';
import { useAuth } from '../../../../auth/authProvider';

interface LicenseModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  licenseDetails: LicenseApplicationResponse[];
  licenseIds: any;
  selectedFilter: string;
  setRefreshLicenseData?: React.Dispatch<React.SetStateAction<boolean>>;
  successCallback: () => void;
}
enum Tab {
  INACTIVE_LICENSES = 'inactive',
  MISSING_LICENSES = 'missing',
  MISSING_LOAS = 'missing_loas',
}
const ApplyLicenseModal: React.FC<LicenseModalProps> = ({
  visible,
  setVisible,
  licenseDetails,
  licenseIds,
  selectedFilter,
  setRefreshLicenseData,
  successCallback,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { bearerToken } = useAuth();
  const { refreshToken } = useAuth();

  const columns: ColumnsType<LicenseApplicationResponse> = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',

      render: (text: string, record: LicenseApplicationResponse) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                fontWeight: 500,
                marginBottom: 0,
              }}
            >
              {text}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
    {
      title: 'License Class ',
      dataIndex: 'licenseClass',
      key: 'licenseClass',

      render: (text: string) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '12px',
              fontWeight: 400,
              marginBottom: 0,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'LOAs',
      dataIndex: 'loaDetails',
      key: 'loaDetails',

      render: (_: any, record: LicenseApplicationResponse) => (
        <Row align="middle">
          <div
            style={{
              fontSize: '14px',
              color: 'var(--hint-primary-color)',
              marginBottom: 0,
            }}
          >
            {record.license.loaDetails
              .filter((loa: LoaDetail) =>
                selectedFilter === Tab.MISSING_LOAS
                  ? loa.status === LoaStatus.PENDING
                  : true
              )
              .map((loa: LoaDetail) => {
                return (
                  <Space size={[0, 8]} wrap>
                    <Tag
                      color="#F7FCFC"
                      style={{
                        borderRadius: '10px',
                        borderColor: 'var(--border-color)',
                        border: '2px solid #DFF4F5',
                      }}
                    >
                      <span style={{ color: 'black' }}>{loa.loa}</span>
                    </Tag>
                  </Space>
                );
              })}
          </div>
        </Row>
      ),
    },
    {
      title: 'Est cost',
      dataIndex: 'estimatedCost',
      key: 'estimatedCost',

      render: (text: string, record: LicenseApplicationResponse) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              ${' '}
              {record?.estimatedCost ||
                'Cost will be updated once application is submitted to NIPR'}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
  ];

  const applyLicense = async () => {
    try {
      if (bearerToken) {
        setIsLoading(true);
        const createLicense: any = await LicensesService.applyBulkLicenses(
          licenseIds,
          bearerToken
        );
        if (createLicense.status === 'SUCCESS') {
          message.success(
            SuccessMessageConstants.LICENSE_APPLICATION_IN_PROGESS
          );
          setVisible(false);
          setIsLoading(false);
          setRefreshLicenseData && setRefreshLicenseData(true);
          successCallback();
          refreshToken();
        } else {
          message.error('Failed To Apply License');
          setVisible(false);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error('error', error);
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      width={900}
      open={visible}
      onCancel={() => {
        setVisible(false); // Call the setVisible function to close the modal
      }}
      okText="Ok"
      footer={null}
    >
      <ModalContainer>
        <HeadingContainer>
          <TitleCard>
            <TextWrapper fontSize={'16px'}>
              Review License Application
            </TextWrapper>
            <TextWrapper fontSize={'12px'} textColor={'#C2C2C2'}>
              Review your license application for any discrepancy
            </TextWrapper>
          </TitleCard>
          <div style={{ marginLeft: '30px' }}>
            <Button
              id={IdConstants.APPLY_LICENSES_MODAL.CANCEL}
              onClick={() => {
                setVisible(false);
              }}
              style={{ marginRight: '10px', width: '170px' }}
            >
              Cancel Application
            </Button>
            <Button
              id={IdConstants.APPLY_LICENSES_MODAL.APPLY}
              loading={isLoading}
              onClick={() => {
                applyLicense();
              }}
              style={{ width: '170px' }}
              type="primary"
            >
              Apply License
            </Button>
          </div>
        </HeadingContainer>
        <HeadingContainer>
          <TitleCard>
            <TextWrapper fontSize={'14px'}>
              Total Cost Of Application
            </TextWrapper>
            <TextWrapper
              fontSize={'20px'}
              fontWeight={600}
              textColor={'#00a6fb'}
            >
              ${' '}
              {licenseDetails.reduce(
                (acc: any, current: LicenseApplicationResponse) =>
                  acc + current?.estimatedCost,
                0
              )}
            </TextWrapper>
          </TitleCard>
        </HeadingContainer>
        <HeadingContainer>
          <Table
            scroll={{ y: 240 }}
            style={{ width: '100%' }}
            columns={columns}
            dataSource={licenseDetails}
            pagination={false}
          />
        </HeadingContainer>
      </ModalContainer>
    </Modal>
  );
};

export default ApplyLicenseModal;
