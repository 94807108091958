import {
  AutoComplete,
  Button,
  Card,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Typography,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import Table, { ColumnsType } from 'antd/es/table';
import { applySort, capitalizeFirstLetter } from '../../../utils/common.utils';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import { AgentsDownlineUpline } from '../../../types/data/agencyDownline';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PageConstants } from '../../../constants/page.constants';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { UpLineService } from '../../../services/uplines.service';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router-dom';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
function UpLines() {
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(10);
  const [activeData, setActiveData] = useState<any>([]);
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const { bearerToken } = useAuth();
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState({});

  // Adding Filters
  const [requestBody, setRequestBody] = useState<Object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  const navigate = useNavigate();

  const columns: ColumnsType<AgentsDownlineUpline> = [
    {
      title: 'Name',
      dataIndex: 'agency',
      key: 'uplineDetails.agency.name',
      sorter: true,
      render: (text: string, record: AgentsDownlineUpline) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {record?.agency?.name}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'npn',
      sorter: true,
      render: (text: string) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
    // {
    //   title: 'Upline Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   render: (text: string) => (
    //     <Row align="middle" style={{ textAlign: 'center' }}>
    //       <Typography.Paragraph
    //         style={{
    //           fontSize: '14px',
    //           fontWeight: 400,
    //           textAlign: 'center',
    //         }}
    //       >
    //         {capitalizeFirstLetter(text)}
    //       </Typography.Paragraph>
    //     </Row>
    //   ),
    // },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (text: string) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            {moment(text).format('MMMM DD, YYYY')}
          </Typography.Paragraph>
        </Row>
      ),
    },
  ];

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchUplines(queryText || '', newPageNumber, newPageSize);
  };

  useEffect(() => {
    bearerToken && fetchUplines();
  }, [bearerToken, queryText, searchType]);

  const fetchUplines = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    try {
      if (bearerToken) {
        const defaultRequestBody = {};
        setIsLoading(true);
        const reqBody = {
          ...requestBody,
          ...defaultRequestBody,
          ...(sort || sortValue || {}),
        };
        cancelToken && cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const response: any = await UpLineService.getAgenciesUpline(
          reqBody,
          size || pageSize,
          page || pageNumber,
          bearerToken,
          searchTextProp ? searchTextProp : queryText || '',
          searchType ? searchType : '',
          cancelTokenSource
        );
        if (response) {
          setCancelToken(undefined);
          setActiveData(response.data);
          setCount(response.totalCount);
          setIsLoading(false);
        } else {
          setCount(0);
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setCancelToken(undefined);
      }
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      const path = '/license/my-agency/applications';
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any = await UpLineService.getFilterDetails(
          token,
          pageSize,
          pageNumber,
          path,
          searchText
        );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.UPLINES
          )
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups: [],
    };
    setRequestBody(updatedRequestBody);
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) fetchUplines('', 1);
    setPageNumber(1);
  }, [requestBody]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_UPLINES;

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <Card style={{ width: '100%', height: 'fit-content' }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        <SimpleFilter
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setApplyFilter={() => {}}
          setRequestBody={setRequestBody}
          requestBody={requestBody}
          filterLoader={filterInfoLoading}
          visibility={isFilterVisible}
          setVisibility={() => {
            setIsFilterVisible(false);
          }}
          fetchFilters={() => fetchFiltersInfoForFindAll()}
          clearFilters={() => {
            clearFilters();
          }}
          pageName={pageName}
          filterPage={PageConstants.UPLINES}
        />
        <Row
          justify="space-between"
          className="radio-buttons"
          style={{ alignItems: 'center' }}
        >
          <Typography.Text
            type="secondary"
            className="reset-icon-size"
            style={{ fontSize: '14px', marginBottom: 0 }}
          >
            <Space>
              <InfoCircleOutlined />
              View your uplines
            </Space>
          </Typography.Text>
          <Space>
            <Button
              id={
                IdConstants.AGENCY.UPLINES.MY_INVITES_BUTTON + '-agency-uplines'
              }
              onClick={() => navigate(RouteConstants.invite_uplines.path)}
            >
              View Invitations
            </Button>
          </Space>
        </Row>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
            columnGap: 10,
          }}
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            disabled={isLoading}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-agency-uplines'}
              size="middle"
              placeholder="Search Uplines by Name or NPN"
              onSearch={handleOnSearch}
            />
          </AutoComplete>
          <FilterButton
            {...{
              filterInfoLoading,
              activeData,
              requestBody,
              isLoading,
              setIsFilterVisible,
              pageName,
            }}
          />
        </div>

        <RenderFilterChip
          {...{
            selectedFilters,
            removeFilter,
            clearFilters,
            pageName,
          }}
        />

        <>
          <Table
            rowSelection={undefined}
            columns={columns}
            dataSource={activeData}
            pagination={false}
            loading={isLoading}
            onChange={(pagination, filters, sorter) => {
              const sort = applySort(sorter, fetchUplines);
              setSortValue(sort);
            }}
          />
        </>
        {!isEmpty(activeData) && (
          <Row
            className="pagination"
            style={{ marginTop: '10px' }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              disabled={isLoading}
              total={count}
              current={pageNumber}
              pageSize={pageSize}
              onChange={onChange}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
            />
          </Row>
        )}
      </ConfigProvider>
    </Card>
  );
}

export default withRoles(UpLines, [RoleType.SUPER_ADMIN, RoleType.ADMIN]);
