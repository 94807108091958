import { DatePicker, Typography } from 'antd';
import { EventValue, RangeValue } from 'rc-picker/lib/interface';

import type { Dayjs } from 'dayjs';
import { FiltersType } from '../../../../types/common/filters.type';
import React from 'react';
import dayjs from 'dayjs';
type RadioProps = {
  options: any;
  label: string;
  keyData: string;
  filters: FiltersType;
  selectedFilters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
};
const { RangePicker } = DatePicker;

const disabledDate = (current: Dayjs | null): boolean => {
  return !!current && current.isAfter(dayjs(), 'day');
};

function DateFilter({
  label,
  filters,
  selectedFilters,
  setFilters,
  keyData,
}: RadioProps) {
  const selectedValue = filters.data[label]?.labelValuePair || '';
  const value = Array.isArray(selectedValue)
    ? ([dayjs(selectedValue?.[0]?.value), dayjs(selectedValue?.[1]?.value)] as [
        EventValue<Dayjs>,
        EventValue<Dayjs>
      ])
    : null;

  const onRangeChange = (
    values: RangeValue<Dayjs>,
    dates: [string, string]
  ) => {
    if (values && dayjs.isDayjs(values[0]) && dayjs.isDayjs(values[1])) {
      const updatedFilters: FiltersType = {
        data: {
          ...selectedFilters.data,
          [label]: {
            isDate: true,
            labelValuePair: [
              {
                value: dayjs(dates[0]).startOf('day').toISOString(),
                label: keyData,
                condition: 'from',
              },
              {
                value: dayjs(dates[1]).endOf('day').toISOString(),
                label: keyData,
                condition: 'to',
              },
            ],
            key: keyData,
          },
        },
      };
      setFilters(updatedFilters);
    } else {
      const filteredItems = { ...selectedFilters };
      const newData = { ...filteredItems.data };
      delete newData[label];
      const updatedFilters = { ...filteredItems, data: newData };
      setFilters(updatedFilters);
    }
  };

  return (
    <div>
      {' '}
      <Typography.Paragraph
        style={{
          fontSize: '14px',
          color: 'var(--secondary-color)',
          fontWeight: 500,
        }}
      >
        {label}
      </Typography.Paragraph>
      <RangePicker
        defaultValue={value}
        disabledDate={disabledDate}
        format="YYYY/MM/DD"
        onChange={onRangeChange}
      />
    </div>
  );
}

export default DateFilter;
