import {
  DollarOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  HomeOutlined,
} from '@ant-design/icons';

import EngineLight from './engineLight';
import { PaymentTypeEnum } from '../../enums/paymentType.enum';
import { RouteConstants } from '../../constants/routes.constants';
import { Row } from 'antd';
import { StateConstants } from '../../constants/state.constants';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { getEmploymentHistoryTooltip } from './actionableEngineLights';
import { getNameString } from '../../utils/name.utils';
import { getPayerTooltip } from '../../utils/licenses/engineLight.utils';
import { getStateNameFromCode } from '../../utils/common.utils';
import { isArray } from 'lodash';

type EngineLightsProps = {
  payer: string;
  setPayerCallBack: () => void;
  disable?: boolean;
  isAgentPortal?: boolean;
  record: any;
};

function EngineLights(props: EngineLightsProps) {
  const handlePayerClick = () => {
    props.setPayerCallBack();
  };
  let backgroundQuestionsAnswered = false;
  if (props.record?.producerDetails?.engineLights) {
    const backgroundQuestion =
      props.record?.producerDetails?.engineLights?.backgroundQuestion;
    if (isArray(backgroundQuestion)) {
      let commonQuestionsAnswered = false;
      let nonUniformAnswered = true;
      backgroundQuestion?.forEach((status: any) => {
        if (status.stateCode === 'ALL') {
          commonQuestionsAnswered = status.isAllAnswered;
        } else if (status.stateCode === props.record?.stateCode) {
          nonUniformAnswered = status.isAllAnswered;
        }
      });
      backgroundQuestionsAnswered =
        commonQuestionsAnswered && nonUniformAnswered;
    }
  }
  return (
    <Row
      style={{
        columnGap: '8px',
        height: '20px',
        flexFlow: 'nowrap',
        ...(props.disable ? { pointerEvents: 'none' } : {}),
      }}
    >
      <EngineLight
        icon={<DollarOutlined />}
        status={
          !props.record?.producerDetails?.engineLights?.paymentConfig
            ? 'red'
            : 'green'
        }
        tooltip={
          props.disable
            ? null
            : getPayerTooltip(
                props.payer,
                props.record?.producerDetails?.engineLights?.paymentConfig
              )
        }
        onClick={handlePayerClick}
      />
      <EngineLight
        icon={<FieldTimeOutlined />}
        status={
          !props.record.producerDetails?.engineLights?.employmentHistory
            ? 'red'
            : 'green'
        }
        tooltip={
          props.disable
            ? null
            : props.record.producerDetails?.engineLights.employmentHistory
            ? 'Agent employment history is filled'
            : getEmploymentHistoryTooltip(
                props?.record?.producerDetails?.employmentHistory
              )
        }
        onClick={() => {
          if (
            !props.record.producerDetails?.engineLights?.employmentHistory &&
            props.record?.producerDetails?.producerId
          ) {
            appStateInfoStore.setHeader(
              getNameString(props.record.producerDetails.name)
            );
            window.open(
              props.isAgentPortal
                ? `${process.env.REACT_APP_REDIRECT_URI}${RouteConstants.employmentHistory.path}`
                : `${
                    process.env.REACT_APP_REDIRECT_URI
                  }${RouteConstants.agencyEmploymentHistory.path.replace(
                    ':agentId',
                    props.record?.producerDetails?.producerId
                  )}`,
              '_blank',
              'rel=noopener noreferrer'
            );
          }
        }}
      />
      <EngineLight
        icon={<FileTextOutlined />}
        status={!backgroundQuestionsAnswered ? 'red' : 'green'}
        tooltip={
          props.disable
            ? null
            : backgroundQuestionsAnswered
            ? 'Producer background questions are answered'
            : 'Producer background questions are not answered'
        }
        onClick={() => {
          if (
            !backgroundQuestionsAnswered &&
            props.record?.producerDetails?.producerId
          ) {
            appStateInfoStore.setHeader(
              getNameString(props.record.producerDetails.name)
            );
            window.open(
              props.isAgentPortal
                ? `${process.env.REACT_APP_REDIRECT_URI}${RouteConstants.backgroundQuestions.path}`
                : `${
                    process.env.REACT_APP_REDIRECT_URI
                  }${RouteConstants.agencyBackgroundQuestions.path.replace(
                    ':agentId',
                    props.record?.producerDetails?.producerId
                  )}`,
              '_blank',
              'rel=noopener noreferrer'
            );
          }
        }}
      />
      <EngineLight
        icon={<HomeOutlined />}
        status={
          props.record.producerDetails?.engineLights?.isResidentLicenseActive
            ? 'green'
            : props.record.producerDetails?.engineLights?.homeState
            ? 'yellow'
            : 'red'
        }
        tooltip={
          props.disable
            ? null
            : props.record.producerDetails?.engineLights
                ?.isResidentLicenseActive
            ? 'Resident License is active'
            : // ? `Resident state: ${getStateNameFromCode(
              //     StateConstants[props.record.producerDetails?.residentState]
              //   )}`
              'Resident license is missing'
        }
      />
    </Row>
  );
}

export default EngineLights;
