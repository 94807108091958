import './modalStyle.css';

import { Button, ConfigProvider, Modal, Radio, Space, Typography } from 'antd';
import React, { useState } from 'react';

import { AgencyService } from '../../../../services/agency.service';
import { DeleteStateGroup } from '../../../../enums/stateGroups.enum';
import { IdConstants } from '../../../../constants/id.constants';
import { RouteConstants } from '../../../../constants/routes.constants';
import { adminStore } from '../../../../stores/admin.store';
import { useAuth } from '../../../../auth/authProvider';
import { useNavigate } from 'react-router';

type DeleteModalProps = {
  stateGroupId: string | undefined;
  visible: boolean;
  handleVisibility: (confirm?: boolean) => void;
  viewAgents: () => void;
};

function DeleteConfirmationModal(props: DeleteModalProps) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth();

  const [loading, setLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState<
    DeleteStateGroup | undefined
  >();

  const deleteAssignments = async () => {
    setLoading(true);
    if (adminStore.account && props.stateGroupId) {
      const token = await getAccessTokenSilently();
      await AgencyService.deleteStateGroupById(
        props.stateGroupId,
        true,
        token
      ).catch((error) => {
        console.error('error :: ', error);
      });
    }
    setLoading(false);
    navigate(RouteConstants.stateGroups.path);
    props.handleVisibility(true);
  };
  const convertToStatesAssignments = async () => {
    setLoading(true);
    if (adminStore.account && props.stateGroupId) {
      const token = await getAccessTokenSilently();
      await AgencyService.deleteStateGroupById(
        props.stateGroupId,
        false,
        token
      ).catch((error) => {
        console.error('error :: ', error);
      });
    }
    setLoading(false);
    navigate(RouteConstants.stateGroups.path);
    props.handleVisibility(true);
  };

  const handleRadioChange = (e: any) => {
    setSelectedRadio(e.target.value);
  };

  const handleDelete = async () => {
    if (selectedRadio === DeleteStateGroup.Delete) {
      await deleteAssignments();
    } else {
      await convertToStatesAssignments();
    }
  };

  return (
    <Modal
      open={props.visible}
      onCancel={() => props.handleVisibility()}
      closable
      centered
      destroyOnClose
      width="600px"
      style={{ boxShadow: 'none' }}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            id="agents-button"
            style={{
              background: 'var(--secondary-color)',
              color: 'white',
            }}
            size="large"
            onClick={props.viewAgents}
          >
            View Producers
          </Button>
          <div>
            <Button
              id={
                IdConstants.STATE_GROUPS.DELETE_CONFIRMATION_MODAL.CANCEL +
                '-state-groups'
              }
              size="large"
              onClick={() => {
                props.handleVisibility();
                setSelectedRadio(undefined);
              }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              id={
                IdConstants.STATE_GROUPS.DELETE_CONFIRMATION_MODAL
                  .DELETE_STATE_GROUP + '-state-groups'
              }
              htmlType="submit"
              size="large"
              className="button"
              style={{
                // width: '100%',
                marginLeft: 12,
                marginBottom: '10px',
                opacity: !selectedRadio ? '50%' : '100%',
              }}
              onClick={handleDelete}
              disabled={!selectedRadio}
              loading={loading}
            >
              Delete Territory
            </Button>
          </div>
        </div>
      }
    >
      <div
        style={{
          marginBottom: '1em',
          paddingRight: 24,
        }}
      >
        <Typography.Paragraph
          style={{
            color: 'var(--secondary-color)',
            fontSize: '16px',
            marginBottom: 0,
            fontWeight: 500,
          }}
        >
          Are you sure you want to delete this territory?
        </Typography.Paragraph>
      </div>
      <Typography.Paragraph
        style={{
          color: 'var(--grey-text)',
          fontSize: '14px',
          fontWeight: '400',
        }}
      >
        This territory will no longer exist. This action cannot be undone.
      </Typography.Paragraph>
      <hr className="secondary-line" />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#f7fcfc',
          },
        }}
      >
        <Radio.Group
          onChange={(event) => {
            setSelectedRadio(event.target.value);
          }}
          style={{ marginTop: '2%' }}
          value={selectedRadio}
        >
          <Space direction="vertical">
            <Radio
              id={
                IdConstants.STATE_GROUPS.DELETE_CONFIRMATION_MODAL
                  .DELETE_ASSIGNMENT + '-state-groups'
              }
              value={DeleteStateGroup.Delete}
            >
              Delete Assignments
            </Radio>
            <Typography.Paragraph
              style={{ color: '#787878', fontSize: '12px', marginTop: '-7px' }}
            >
              All the assignments will be deleted for all producers
            </Typography.Paragraph>
            <Radio
              id={
                IdConstants.STATE_GROUPS.DELETE_CONFIRMATION_MODAL
                  .CONVERT_ASSIGNMENT + '-state-groups'
              }
              value={DeleteStateGroup.Convert}
            >
              Convert Assignments to State Assignments
            </Radio>
            <Typography.Paragraph
              style={{ color: '#787878', fontSize: '12px', marginTop: '-7px' }}
            >
              All the assignments will be converted to State Assignments for all
              producers
            </Typography.Paragraph>
          </Space>
        </Radio.Group>
      </ConfigProvider>
    </Modal>
  );
}

export default DeleteConfirmationModal;
