export const licenseStateColumns = [
  {
    dataIndex: 'sNo',
    title: 'S No',
    align: 'center',
  },
  {
    dataIndex: 'name',
    title: 'Name',
  },
  {
    dataIndex: 'licenseNumber',
    title: 'License Number',
    align: 'center',
  },
  {
    dataIndex: 'state',
    title: 'State',
    align: 'center',
  },
  {
    dataIndex: 'reason',
    title: 'Reason (optional)',
  },
];

export const ssnColumns = [
  {
    dataIndex: 'sNo',
    key: 'sNo',
    title: 'S No',
    align: 'center',
  },
  {
    dataIndex: 'ssn',
    key: 'ssn',
    title: 'SSN',
    align: 'center',
  },
  {
    dataIndex: 'lastName',
    key: 'lastName',
    title: 'Last Name',
  },
  {
    dataIndex: 'reason',
    key: 'reason',
    title: 'Reason (optional)',
  },
];

export const nameNpnColumns = [
  {
    dataIndex: 'sNo',
    key: 'sNo',
    title: 'S No',
    align: 'center',
  },
  // {
  //   dataIndex: 'name',
  //   key: 'name',
  //   title: 'Name',
  // },
  {
    dataIndex: 'npn',
    key: 'npn',
    title: 'NPN',
    align: 'center',
  },
  // {
  //   dataIndex: 'reason',
  //   key: 'reason',
  //   title: 'Reason (optional)',
  // },
];
