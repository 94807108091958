export const ssnData = [
  {
    sNo: '1',
    ssn: '123456789',
    lastName: 'Doe',
    reason: 'End of producer contract.',
  },
];

export const nameNpnData = [
  {
    sNo: '1',
    // name: 'John Doe',
    npn: '12345678',
    // reason: 'Producer contract terminated.',
  },
];

export const licenseStateData = [
  {
    sNo: '1',
    name: 'John Doe',
    licenseNumber: '12345678',
    state: 'TX',
    reason: 'Producer resigned.',
  },
];
