import {
  AccountLink,
  StripeAccountDetailsDto,
} from '../types/data/stripe/stripeAccount.type';

import { AgencyService } from '../services/agency.service';
import { AgentService } from '../services/agent.service';
import { StripeBalance } from '../types/data/stripe/stripeBalance.type';
import { makeAutoObservable } from 'mobx';
import { signupStore } from './signupStore';
import { tourStore } from './tour.store';

class StripeStore {
  balance: number | null = null;
  pendingBalance: number | null = null;
  lastUpdated: Date | null = null;
  stripeDetails: StripeAccountDetailsDto | undefined;
  loading: boolean = false;

  /**
   * Setter to update both balance variables using a StripeBalance object
   */
  updateBalanceObjects = (balance: StripeBalance) => {
    try {
      const availableBalance = balance.available[0].amount;
      this.setBalance(availableBalance);
    } catch (e) {}
    try {
      const pendingBalance = balance.pending[0].amount;
      this.setPendingBalance(pendingBalance);
    } catch (e) {}
  };

  getBalance = (): number | null => this.balance;
  setBalance(balance: number): void {
    this.balance = balance;
  }

  getPendingBalance = (): number | null => this.pendingBalance;
  setPendingBalance(pendingBalance: number): void {
    this.pendingBalance = pendingBalance;
  }

  getLastUpdated = (): Date | null => this.lastUpdated;
  setLastUpdated(lastUpdated: Date): void {
    this.lastUpdated = lastUpdated;
  }

  getStripeDetails = (): StripeAccountDetailsDto | undefined => {
    return this.stripeDetails;
  };
  setStripeDetails = (value: StripeAccountDetailsDto): void => {
    this.stripeDetails = value;
  };

  /**
   * Initialiser for the store
   */
  init = async (token: string) => {
    this.loading = true;
    await this.loadStripeDetails(token);
    this.loading = false;
  };

  /**
   * checks if user is onboarded on stripe and fetches stripe details if they are onboarded
   */
  refreshIsOnboarded = async (token: string) => {
    if (signupStore.isAdminAuthorized) {
      const response = await AgencyService.refreshIsOnboardedForCurrentAgency(
        token
      ).catch((e) => {
        console.error(e);
      });
      if (response?.data) this.loadStripeDetails(token);
      return response?.data;
    } else if (signupStore.isAgentAuthorized) {
      const response = await AgentService.refreshIsOnboardedForCurrentAgent(
        token
      ).catch((e) => {
        console.error(e);
      });
      if (response?.data) this.loadStripeDetails(token);
      return response?.data;
    }
  };

  /**
   * loads stripe details from the BE and updates the store state
   */
  loadStripeDetails = async (token: string) => {
    if (signupStore.isAdminAuthorized) {
      const response = await AgencyService.getStripeDetailsForCurrentAgency(
        token
      ).catch((e) => {
        console.error(e);
      });
      if (response) this.setStripeDetails(response.data);
      if (response?.data?.isOnboarded === false) {
        tourStore.setShowWalletStep(true);
      }
      tourStore.setDemoTourVisible(true);
    } else if (signupStore.isAgentAuthorized) {
      const response = await AgentService.getStripeDetailsForCurrentAgent(
        token
      ).catch((e) => {
        console.error(e);
      });
      if (response) this.setStripeDetails(response.data);
    }
  };

  /**
   * Fetches a newly created stripe connect account link from BE and updates store state
   * @param token
   * @returns
   */
  getActiveOnboardingLink = async (token: string): Promise<string | void> => {
    if (signupStore.isAdminAuthorized) {
      const newAccountLink =
        await AgencyService.refreshStripeOnboardingLinkForCurrentAgency(token);
      this.setAccountLink(newAccountLink.data);
      return newAccountLink.data.url;
    } else if (signupStore.isAgentAuthorized) {
      const newAccountLink =
        await AgentService.refreshStripeOnboardingLinkForCurrentAgent(token);
      this.setAccountLink(newAccountLink.data);
      return newAccountLink.data.url;
    }
  };

  setAccountLink = (value: AccountLink) => {
    if (this.stripeDetails) this.stripeDetails.onboardingLink = value;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export const stripeStore = new StripeStore();
