import { Button, Modal, Typography } from 'antd';

import React from 'react';

interface AddPaymentOptionModalProps {
  visible: boolean;
  onConfirm: () => void;
}

export const AddPaymentOptionModal: React.FC<AddPaymentOptionModalProps> = ({
  visible,
  onConfirm,
}) => {
  return (
    <Modal
      title="Start Your Subscription"
      closable={false}
      maskClosable={false}
      centered
      open={visible}
      footer={[
        <Button key="submit" type="primary" onClick={onConfirm}>
          Continue to Subscription
        </Button>,
      ]}
    >
      <Typography>
        You will be redirected from InsureTrek to Stripe to add a payment
        method.
      </Typography>
      <Typography.Paragraph type="secondary" style={{ marginTop: '20px' }}>
        You are getting started with Insuretrek’s Standard Monthly Plan - $25/mo
        per NPN onboarded. Proceed to add your payment details to start your
        subscription.
      </Typography.Paragraph>
    </Modal>
  );
};
