import styled from 'styled-components';
interface TextDetailProps {
  textColor?: string;
  fontSize?: string;
  fontWeight?: number;
}
export const DashboardCard = styled.div`
  display: flex;
  width: 1136px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fffdfd;
`;
export const CardHeading = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const DetailsCard = styled.div`
  display: flex;
  width: 180px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const TextDetail = styled.div<TextDetailProps>`
  color: ${(props) => props.textColor || '#222'};
  font-family: Poppins;
  font-size: ${(props) => props.fontSize || '10px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 20px; /* 200% */
`;

export const PaymentCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const AllocationCard = styled.div`
  display: flex;
  width: 1136px;
  align-items: flex-start;
  gap: 20px;
`;

export const AllocationInformationCard = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fff;
`;

export const PaymentDetails = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 22px;
`;

export const RecentTransaction = styled.div`
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #dff4f5;
  background: #f5fcfc;
`;

export const AmountAllocatedCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const Renewal = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fff;
`;

export const AutoRenewalCard = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const AutoRenewalDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
