export const formatPhoneAndFax = (value: string): string => {
  if (!value) return value;
  const phoneNumber = value.replace(/\D/g, '');

  // Apply the pattern (###-###-####)
  if (phoneNumber.length > 0) {
    const numericPart = phoneNumber.slice(0, 10);
    const formattedNumber = numericPart.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (formattedNumber && formattedNumber[0].length === numericPart.length) {
      formattedNumber.shift();
      return formattedNumber.filter(Boolean).join('-') || '';
    }
  }
  return value;
};

export const isPhoneNumberValid = (value: string): boolean => {
  const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/;
  return phoneNumberPattern.test(value);
};
