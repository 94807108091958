import './empHistory.css';

import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  Space,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import {
  EmployeeHistory,
  agentSideStore,
} from '../../../../stores/agentPortalStore';
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty, isUndefined } from 'lodash';

import CustomPicker from '../../../../components/common/customDatePicker/customPicker';
import DropdownSelect from '../../../../components/common/dropdowns/dropdownSelect';
import { ErrorMessageConstants } from '../../../../constants/error-message.constants';
import { IdConstants } from '../../../../constants/id.constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import InputField from '../../../../components/common/input/inputField';
import ObjectID from 'bson-objectid';
import StateSelect from '../../../../components/common/stateSelect/StateSelect';

export enum EmploymentType {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  CONTRACTOR = 'CONTRACTOR',
  SEASONAL_EMPLOYEE = 'SEASONAL_EMPLOYEE',
}

const options = [
  {
    label: 'Full Time',
    value: EmploymentType.FULL_TIME,
  },
  {
    label: 'Part Time',
    value: EmploymentType.PART_TIME,
  },
];

type getDetailsProp = {
  buttonClick?: any;
  setLoading: any;
  emp: EmployeeHistory;
  isOnboarded: boolean;
  saveEmployeehistory: () => void;
  isNewRecord?: boolean;
  index?: number;
};

export default function GetDetails({
  buttonClick,
  emp,
  isOnboarded,
  isNewRecord,
  setLoading,
  saveEmployeehistory,
  index,
}: getDetailsProp) {
  function checkDates(
    startDate: Dayjs,
    currentId: ObjectID | null,
    endDate?: Dayjs
  ) {
    const empHistory: EmployeeHistory[] = agentSideStore.getEmpHistory();
    const isCurrentlyWorking = endDate === undefined || endDate === null;

    const dateAlreadyExists = empHistory.some((history) => {
      if (history.id === null) return false;
      if (
        currentId !== null &&
        history.id !== null &&
        currentId === history.id
      ) {
        return false;
      }

      return (
        (history.startDate && history.startDate.isSame(startDate, 'month')) ||
        (history.endDate && history.endDate.isSame(endDate || '', 'month'))
      );
    });

    if (dateAlreadyExists) {
      return false;
    }

    const dateOverlaps = empHistory.some((history) => {
      if (
        currentId !== null &&
        history.id !== null &&
        currentId === history.id
      ) {
        return false;
      }

      const empStartDate = history.startDate;
      const empEndDate = history.endDate;

      if (empEndDate) {
        if (history.isCurrentWorkingRole && isCurrentlyWorking) {
          return history.isCurrentWorkingRole && isCurrentlyWorking;
        } else if (
          startDate.isBefore(empStartDate, 'month') &&
          endDate &&
          endDate.isAfter(empEndDate, 'month')
        ) {
          return (
            startDate.isBefore(empStartDate, 'month') &&
            endDate &&
            endDate.isAfter(empEndDate, 'month')
          );
        } else {
          return (
            (startDate.isAfter(empStartDate, 'month') &&
              startDate.isBefore(empEndDate, 'month')) ||
            (endDate &&
              endDate.isAfter(empStartDate, 'month') &&
              endDate.isBefore(empEndDate, 'month'))
          );
        }
      } else {
        return (
          startDate.isAfter(empStartDate) ||
          isCurrentlyWorking ||
          (!isCurrentlyWorking &&
            endDate &&
            endDate.isAfter(empStartDate, 'month'))
        );
      }
    });

    if (dateOverlaps) {
      return false;
    }

    return true;
  }
  const [selectedOptionValue, setOption] = useState(emp.employmentType);
  const [startDate, setStartDate] = useState<Dayjs>(
    emp.startDate ? emp.startDate : dayjs(new Date())
  );
  const [endDate, setEndDate] = useState<Dayjs | undefined>(
    emp.endDate ? emp.endDate : undefined
  );
  const [endDateDisabled, setEndDateDisabled] = useState(emp.endDateDisabled);
  const [title, setTitle] = useState(emp.title);
  const [companyName, setCompanyName] = useState(emp.companyName);
  const [city, setCity] = useState(emp.city);
  const [stateCode, setStateCode] = useState(emp.stateCode || null);
  const [description, setDescription] = useState(emp.description);

  const [employeeData, setEmployeeData] = useState<EmployeeHistory>(emp);

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const handleCheckbox = () => {
    let checkboxSelected = !endDateDisabled;
    setEndDateDisabled(checkboxSelected);
    if (checkboxSelected) {
      // setEndDate(dayjs(new Date()));
      setEndDate(undefined);
    }
  };

  const handleInputChange = (value: string, key: string) => {
    setDescription(value);
  };

  const removeResponses = async () => {
    index !== undefined &&
      index !== null &&
      agentSideStore.removeEmpHistory(index);
    await saveEmployeehistory();
    if (agentSideStore.getEmpHistory()?.length === 0)
      agentSideStore.setIsEditing(true);
    agentSideStore.setIsUpdating(false);
  };

  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const saveResponses = async () => {
    try {
      if (
        title.length === 0 ||
        isEmpty(city) ||
        isEmpty(stateCode) ||
        isEmpty(companyName)
      ) {
        showError(ErrorMessageConstants.EMPTY_FIELDS);
      } else if (endDate && startDate > endDate) {
        showError(ErrorMessageConstants.END_DATE_LESSER_THAN_START_DATE);
      } else if (
        employeeData.id &&
        (endDate || endDateDisabled) &&
        !checkDates(startDate, emp.id, endDate)
      ) {
        showError(ErrorMessageConstants.WORKING_PERIOD_EXISTS);
      } else if (
        startDate > dayjs(new Date()) ||
        (endDate && endDate > dayjs(new Date()))
      ) {
        showError(ErrorMessageConstants.FUTURE_DATE);
      } else {
        if (!employeeData.id) {
          employeeData.id = ObjectID();
        }
        employeeData.employmentType = selectedOptionValue;
        employeeData.startDate = startDate;
        if (endDate && !endDateDisabled) employeeData.endDate = endDate;
        employeeData.title = title;
        employeeData.companyName = companyName;
        employeeData.city = city;
        employeeData.stateCode = stateCode;
        employeeData.description = description;
        employeeData.endDateDisabled = endDateDisabled;

        employeeData.isCurrentWorkingRole = endDateDisabled;

        agentSideStore.addEmpHistory(employeeData);
        await saveEmployeehistory();
        buttonClick(false);
      }
    } catch (error) {
      console.error('error in employee history information', error);
      showError(ErrorMessageConstants.EMP_HISTORY);
    }
  };

  useEffect(() => {
    setEndDateDisabled(
      !!(emp?.isEditing && !emp.endDate) || !!emp?.isCurrentWorkingRole
    );
  }, [emp]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {contextHolder}
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            gap: '20px',
            width: '100%',
          }}
        >
          <InputField
            id={IdConstants.EMPLOYMENT_HISTORY.JOB_TITLE}
            label="Job Title"
            value={title}
            compulsory={true}
            placeholder="Enter your job title"
            isDisabled={false}
            setInputValue={setTitle}
            bodyStyles={{ flexBasis: '30%' }}
            onChange={(e: any) => {
              const value = e.target.value;
              const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
              const capitalizedValue =
                cleanedValue.charAt(0).toUpperCase() + cleanedValue.slice(1);
              setTitle(capitalizedValue);
            }}
            onKeyPress={(event: any) => {
              const regex = /^[A-Za-z\s]+$/;
              const char = String.fromCharCode(event.which || event.keyCode);
              if (!regex.test(char)) {
                event.preventDefault();
              }
            }}
            onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
              const pastedText = event.clipboardData.getData('text/plain');
              const regex = /^[A-Za-z\s]+$/;
              if (!regex.test(pastedText)) {
                event.preventDefault();
              }
            }}
          />
          <InputField
            id={IdConstants.EMPLOYMENT_HISTORY.COMPANY_NAME}
            label="Company Name"
            value={companyName}
            compulsory={true}
            placeholder="Enter your company name"
            isDisabled={false}
            setInputValue={setCompanyName}
            bodyStyles={{ flexBasis: '30%' }}
            onChange={(e: any) => {
              setCompanyName(e.target.value);
            }}
          />

          <div
            style={{
              flexBasis: '10%',
              minWidth: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography.Paragraph>
              Employment Type{' '}
              <span style={{ color: 'var(--primary-color)' }}>*</span>
            </Typography.Paragraph>
            <DropdownSelect
              id={IdConstants.EMPLOYMENT_HISTORY.EMPLOYMENT_TYPE}
              placeholder="Select your employment type"
              options={options}
              selectedOption={
                options.find((option) => option.value === selectedOptionValue)
                  ?.value || EmploymentType.PART_TIME
              }
              setSelectedOption={(newOption) => {
                newOption =
                  options.find((option) => option.value === newOption)?.value ||
                  EmploymentType.PART_TIME;
                setOption(newOption);
              }}
              styles={{
                marginTop: ' -10px',
                width: '100%',
                flexGrow: '1',
              }}
            />
          </div>

          <div
            style={{
              flexBasis: '15%',
              display: 'flex',
            }}
          >
            <InputField
              id={IdConstants.EMPLOYMENT_HISTORY.CITY}
              label="City"
              compulsory={true}
              placeholder="Enter the city where you worked"
              isDisabled={false}
              value={city}
              setInputValue={setCity}
              onChange={(e: any) => {
                const value = e.target.value;
                const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
                const capitalizedValue =
                  cleanedValue.charAt(0).toUpperCase() + cleanedValue.slice(1);
                setCity(capitalizedValue);
              }}
              onKeyPress={(event: any) => {
                const regex = /^[A-Za-z,.\s]+$/;
                const char = String.fromCharCode(event.which || event.keyCode);
                if (!regex.test(char)) {
                  event.preventDefault();
                }
              }}
              onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
                const pastedText = event.clipboardData.getData('text/plain');
                const regex = /^[A-Za-z,.\s]+$/;
                if (!regex.test(pastedText)) {
                  event.preventDefault();
                }
              }}
              bodyStyles={{ width: '100%' }}
            />
          </div>
          <div
            style={{
              flexBasis: '15%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography.Paragraph>
              State <span style={{ color: 'var(--primary-color)' }}>*</span>
            </Typography.Paragraph>
            <Form.Item
              rules={[{ required: true, message: 'Please select the State' }]}
            >
              <StateSelect
                value={stateCode}
                style={{
                  marginTop: '-6px',
                  maxWidth: '100%',
                }}
                onChange={(value: string) => {
                  setStateCode(value);
                }}
              />
            </Form.Item>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            gap: '20px',
            marginTop: '20px',
            width: '100%',
          }}
        >
          <div style={{ flexBasis: '20%' }}>
            <Typography.Paragraph>
              Start Date{' '}
              <span style={{ color: 'var(--primary-color)' }}>*</span>
              <Tooltip title="Starting date of employment (there should be no skips in employment dates and dates should be successive)">
                <InfoCircleOutlined
                  style={{ marginLeft: 6 }}
                  className="cursor-pointer"
                />
              </Tooltip>
            </Typography.Paragraph>
            <div
              style={{
                display: 'flex',
              }}
            >
              <CustomPicker
                customButton={true}
                type="month"
                setSelectedValue={handleStartDateChange}
                selectedValue={startDate ? startDate : dayjs(new Date())}
              />
            </div>
          </div>

          <div style={{ flexBasis: '20%' }}>
            <Typography.Paragraph
              style={{ opacity: endDateDisabled ? 0.5 : 1 }}
            >
              End Date <span style={{ color: 'var(--primary-color)' }}>*</span>
              <Tooltip title=" Ending date of the accounted time. (If the accounted time is the present, use the current date. Do not leave blank.)">
                <InfoCircleOutlined
                  style={{ marginLeft: 6 }}
                  className="cursor-pointer"
                />
              </Tooltip>
            </Typography.Paragraph>
            <div
              style={{
                display: 'flex',
              }}
            >
              <CustomPicker
                type="month"
                customButton={true}
                needed={endDateDisabled}
                setSelectedValue={handleEndDateChange}
                selectedValue={
                  !isUndefined(endDate) && !endDateDisabled
                    ? endDate
                    : undefined
                }
              />
            </div>
          </div>
          <div style={{ flexBasis: '60%' }}>
            <Typography.Paragraph className="input-labels">
              Description
            </Typography.Paragraph>
            <Input.TextArea
              id={IdConstants.EMPLOYMENT_HISTORY.DESCRIPTION}
              defaultValue={description}
              onChange={(value) => {
                handleInputChange(value.target.value, 'description');
              }}
              placeholder="Enter a brief job description"
              style={{
                height: '10px',
                resize: 'none',
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#0588ca',
              },
            }}
          >
            <Checkbox
              id={IdConstants.EMPLOYMENT_HISTORY.CHECK_BUTTON}
              onClick={handleCheckbox}
              checked={endDateDisabled}
            >
              <Typography.Paragraph style={{ marginTop: '15px' }}>
                I am currently working in this role
              </Typography.Paragraph>
            </Checkbox>
          </ConfigProvider>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <Space size={20}>
            {index !== undefined && index !== null && emp.isEditing && (
              <Button
                id={IdConstants.EMPLOYMENT_HISTORY.DELETE_BUTTON}
                style={{
                  alignContent: 'left',
                  width: '120px',
                }}
                type="default"
                className="button important-action-button"
                size="middle"
                onClick={removeResponses}
                danger
              >
                Delete
              </Button>
            )}
            {agentSideStore.getEmpHistory().length > 0 && (
              <Button
                id={IdConstants.EMPLOYMENT_HISTORY.CANCEL_BUTTON}
                style={{ alignContent: 'right', width: '120px' }}
                onClick={() => buttonClick(false)}
                size="middle"
                type="default"
                danger
              >
                Cancel
              </Button>
            )}
            <Button
              id={IdConstants.EMPLOYMENT_HISTORY.SAVE_BUTTON}
              style={{ alignContent: 'right', width: '120px' }}
              className="button secondary-button"
              onClick={saveResponses}
              size="middle"
            >
              Save
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
}
