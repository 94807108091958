import { Button, Card, Divider, Modal, Typography } from 'antd';

import { ActionableLicenseResponseError } from '../../types/response/actionable-license.type';
import { ClickableLinkText } from '../clickableLink';
import React from 'react';

interface ApplyLicenseErrorModalProps {
  applyLicenseErrorModalVisibility: boolean;
  setApplyLicenseErrorModalVisibility: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  errors: ActionableLicenseResponseError[];
  fetchLicense?: () => void;
}

function ApplyLicenseErrorModal({
  applyLicenseErrorModalVisibility,
  setApplyLicenseErrorModalVisibility,
  errors,
  fetchLicense,
}: ApplyLicenseErrorModalProps) {
  return (
    <Modal
      open={applyLicenseErrorModalVisibility}
      maskClosable={false}
      onCancel={() => {
        setApplyLicenseErrorModalVisibility(false);
        if (fetchLicense) {
          fetchLicense();
        }
      }}
      footer={
        <Button
          size="large"
          onClick={() => {
            setApplyLicenseErrorModalVisibility(false);
            if (fetchLicense) {
              fetchLicense();
            }
          }}
          style={{ width: '100%' }}
        >
          Go Back
        </Button>
      }
      bodyStyle={{
        height: '100%',
        maxHeight: '500px',
        display: 'flex',
        flexGrow: '1',
      }}
      width={800}
    >
      <div
        className="custom-scrollbar"
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <Typography.Paragraph style={{ fontSize: '24px', fontWeight: '500' }}>
          License Application Status
        </Typography.Paragraph>

        <div style={{ overflow: 'auto', display: 'flex' }}>
          {errors.map((data, dataIndex) => (
            <div
              className="custom-scrollbar"
              key={`error-${dataIndex}`}
              style={{ overflow: 'auto', width: '100%' }}
            >
              <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />
              <Card style={{ width: '100%' }} bodyStyle={{ padding: '8px' }}>
                {data.LoaErrorDetails.errorMessages.length > 0 && (
                  <div>
                    <Typography.Paragraph style={{ marginLeft: '15px' }}>
                      LOA Errors
                    </Typography.Paragraph>
                    <div
                      className="custom-scrollbar"
                      style={{
                        height: '155px',
                        overflow: 'auto',
                        marginBottom: '10px',
                      }}
                    >
                      {data.LoaErrorDetails.errorMessages.map(
                        (errorMessage, index) => (
                          <Card
                            key={`loa-error-${dataIndex}-${index}`}
                            size="small"
                            style={{
                              background: 'var(--secondary-light-color)',
                              marginBottom: '5px',
                            }}
                          >
                            <Typography.Paragraph
                              style={{
                                margin: 0,
                                marginLeft: 10,
                                display: 'inline',
                              }}
                            >
                              {ClickableLinkText(errorMessage)}
                            </Typography.Paragraph>
                          </Card>
                        )
                      )}
                    </div>
                  </div>
                )}
                {data.LcErrorDetails.errorMessages.length > 0 && (
                  <div>
                    <Typography.Paragraph style={{ marginLeft: '15px' }}>
                      LC Errors
                    </Typography.Paragraph>
                    <div
                      className="custom-scrollbar"
                      style={{
                        height: '155px',
                        overflow: 'auto',
                        marginBottom: '10px',
                      }}
                    >
                      {data.LcErrorDetails.errorMessages.map(
                        (errorMessage, index) => (
                          <Card
                            key={`lc-error-${dataIndex}-${index}`}
                            size="small"
                            style={{
                              background: 'var(--secondary-light-color)',
                              marginBottom: '10px',
                            }}
                          >
                            <Typography.Paragraph
                              style={{
                                display: 'inline',
                                alignItems: 'center',
                                margin: 0,
                                marginLeft: 10,
                              }}
                            >
                              {ClickableLinkText(errorMessage)}
                            </Typography.Paragraph>
                          </Card>
                        )
                      )}
                    </div>
                  </div>
                )}
                {data.intermediateErrorDetails.errorMessages.length > 0 && (
                  <div>
                    <Typography.Paragraph style={{ marginLeft: '15px' }}>
                      Comments from NIPR that need your attention
                    </Typography.Paragraph>
                    <div
                      className="custom-scrollbar"
                      style={{
                        height: '155px',
                        overflow: 'auto',
                        marginBottom: '10px',
                      }}
                    >
                      {data.intermediateErrorDetails.errorMessages.map(
                        (errorMessage, index) => (
                          <Card
                            key={`lc-error-${dataIndex}-${index}`}
                            size="small"
                            style={{
                              background: 'var(--secondary-light-color)',
                              marginBottom: '5px',
                            }}
                          >
                            <Typography.Paragraph type="secondary">
                              {errorMessage.actionable === 'Y'
                                ? 'Action Required'
                                : 'No Action Required'}
                            </Typography.Paragraph>
                            <Typography.Paragraph
                              style={{
                                display: 'inline',
                                alignItems: 'center',
                                margin: 0,
                                marginLeft: 10,
                              }}
                            >
                              {ClickableLinkText(errorMessage.message)}
                            </Typography.Paragraph>
                          </Card>
                        )
                      )}
                    </div>
                  </div>
                )}
                {data.mainErrorDetails.errorMessages.length > 0 && (
                  <div>
                    <Typography.Paragraph style={{ marginLeft: '15px' }}>
                      Errors
                    </Typography.Paragraph>
                    <div
                      className="custom-scrollbar"
                      style={{
                        height: '155px',
                        overflow: 'auto',
                        marginBottom: '10px',
                      }}
                    >
                      {data.mainErrorDetails.errorMessages.map(
                        (errorMessage, index) => (
                          <Card
                            key={`lc-error-${dataIndex}-${index}`}
                            size="small"
                            style={{
                              background: 'var(--secondary-light-color)',
                              marginBottom: '5px',
                            }}
                          >
                            <Typography.Paragraph
                              style={{
                                display: 'inline',
                                alignItems: 'center',
                                margin: 0,
                                marginLeft: 10,
                              }}
                            >
                              {ClickableLinkText(errorMessage)}
                            </Typography.Paragraph>
                          </Card>
                        )
                      )}
                    </div>
                  </div>
                )}
              </Card>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default ApplyLicenseErrorModal;
