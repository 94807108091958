export const CsvExportFields = {
  AGENCY_RTS_REPORT: {
    'Producer First Name': 'producer.name.firstName',
    'Producer Middle Name': 'producer.name.middleName',
    'Producer Last Name': 'producer.name.lastName',
    'Producer NPN': 'producer.npn',
    State: 'stateCode',
    LOA: 'appointmentLicenseConfiguration.licenseConfiguration.loa',
    'LOA Code': 'appointmentLicenseConfiguration.licenseConfiguration.loaCode',
    'Co Code': 'coCode',
    'Company Name': 'companyName',
    FEIN: 'fein',
    Status: 'status',
    'Appointment Renewal Date': 'appointmentRenewalDate',
    'Status Reason Date': 'statusReasonDate',
    'Terminated Reason': 'terminationReason',
  },
};
