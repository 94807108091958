import { AgentActivityTimelineEvent } from '../../types/response/data/agents/agentActivityTimelineEvent.type';
import { AppEventSubTypes } from '../../enums/appEventSubType.enum';
import { AppEventTypes } from '../../enums/appEventType.enum';
import CardWithLeftLine from '../common/cardWithLeftLine/cardWithLeftLine';
import { TerminalStatus } from '../../enums/terminalStatus.enum';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { getStateNameFromCode } from '../../utils/common.utils';

type CardProps = {
  activity: AgentActivityTimelineEvent;
};

function formatDateTime(inputString: string) {
  const dateObject = new Date(inputString);

  const optionsDate: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = dateObject.toLocaleString('en-US', optionsDate);
  const formattedTime = dateObject.toLocaleString('en-US', optionsTime);

  return { date: formattedDate, time: formattedTime };
}

function getCardTitleFromAgentActivityEvent(
  event: AgentActivityTimelineEvent
): string[] {
  switch (event.appEventSubType) {
    case AppEventSubTypes.Onboarded:
      return ['Producer was onboarded'];

    case AppEventSubTypes.Offboarded:
      return ['Producer was offboarded'];

    case AppEventSubTypes.NewLicenseApplication:
      return [
        `New license application initiated for the state ${event.additionalMetadata?.license?.stateName} and license class ${event.additionalMetadata?.license?.licenseClass}`,
      ];

    case AppEventSubTypes.MissingLOAs:
      return [
        `License renewal initiated for license number ${event.additionalMetadata?.license?.licenseNumber} for the state ${event.additionalMetadata?.license?.stateName} and license class ${event.additionalMetadata?.license?.licenseClass}`,
      ];

    case AppEventSubTypes.FilledBGQuestions:
      return ['Completed filling background questions'];

    case AppEventSubTypes.UpdatedBGQuestions:
      return ['Background question has been updated.'];

    case AppEventSubTypes.FilledEmploymentHistory:
      return ['Completed filling employment history'];

    case AppEventSubTypes.UpdatedEmploymentHistory:
      return ['Employment history has been updated.'];

    case AppEventSubTypes.LicenseExpired:
      return [`License has expired`];

    case AppEventSubTypes.StateAdded:
      return ['A new state was added to the assignment'];

    case AppEventSubTypes.StateAssignmentUpdated:
      return ['State assignment was updated'];

    case AppEventSubTypes.StateRemoved:
      return ['A state was removed from the assignment'];

    case AppEventSubTypes.StateGroupAdded:
      return ['A new territory was added to the assignment'];

    case AppEventSubTypes.StateGroupDuplicated:
      return ['A new territory was added to the assignment'];

    case AppEventSubTypes.StateGroupUpdated:
      return [
        `State group ${event.additionalMetadata?.stateGroupDifferences?.stateGroupName} was updated`,
      ];

    case AppEventSubTypes.StateGroupRemoved:
      return ['A territory was removed from the assignment'];

    case AppEventSubTypes.CreateGatewayTransaction:
      return [
        `${
          event.terminalStatus === 'failure'
            ? `License was rejected`
            : event.application?.applicationType === `creation`
            ? `New license application initiated`
            : `New license application renewal initiated`
        }`,
      ];

    case AppEventSubTypes.Watchlisted:
      return ['Producer has been watchlisted'];

    case AppEventSubTypes.StateGroupConvertedToAssignments:
      return [
        'A territory was removed and converted into individual state assignments',
      ];
    default:
      break;
  }
  return [];
}

function getCardDescriptionFromAgentActivityEvent(
  event: AgentActivityTimelineEvent
): string | string[] {
  switch (event.appEventSubType) {
    case AppEventSubTypes.Onboarded:
      return `- Onboarded onto the platform by ${event.admin?.firstName} ${event.admin?.lastName}`;

    case AppEventSubTypes.Watchlisted:
      return `- Producer was watchlisted by ${event.admin?.firstName} ${event.admin?.lastName}`;

    case AppEventSubTypes.Offboarded:
      return `- Producer was offboarded by ${event.admin?.firstName} ${event.admin?.lastName}`;

    case AppEventSubTypes.UpdatedEmploymentHistory:
      return '- Changes have been made to the employment history';

    case AppEventSubTypes.UpdatedBGQuestions:
      return 'Changes have been made to the background questions';

    case AppEventSubTypes.LicenseExpired:
      return `- License with license number ${
        event.license?.licenseNumber
      } is no longer valid since ${
        formatDateTime(event.license?.expiryDate).date
      }`;

    case AppEventSubTypes.StateAdded:
      return (
        `- Assignment now includes an additional state, ` +
        event.additionalMetadata?.stateName +
        `(${event.additionalMetadata?.stateCode})`
      );

    case AppEventSubTypes.StateAssignmentUpdated:
      const stateAssignmentDescription: string[] = [];

      if (
        event.additionalMetadata?.stateAssignmentDifferences
          ?.licenseClassDifferences?.length !== 0 &&
        event.additionalMetadata?.stateAssignmentDifferences?.loaCodeDifferences
          ?.length !== 0
      ) {
        stateAssignmentDescription.push(
          `State assignment updated for the states : ${event.additionalMetadata?.stateAssignmentDifferences?.licenseClassDifferences.map(
            (lcCodeDiff: any) => {
              const stateName = lcCodeDiff.stateName;

              const lcAdded = lcCodeDiff.lcAdded.map((lc: any) => lc.lcName);
              const lcRemoved = lcCodeDiff.lcRemoved.map(
                (lc: any) => lc.lcName
              );

              const addedString =
                lcAdded.length > 0
                  ? `Added license classes - ${lcAdded.join(', ')}`
                  : '';
              const removedString =
                lcRemoved.length > 0
                  ? `Removed license classes - ${lcRemoved.join(', ')}`
                  : '';

              return `- State ${stateName}: ${addedString} ${removedString}`;
            }
          )} ${event.additionalMetadata?.stateAssignmentDifferences?.loaCodeDifferences.map(
            (loaCodeDiff: any) => {
              const stateName = loaCodeDiff.stateName;

              const addedLoaNames = loaCodeDiff.addedLoaNames.map(
                (loa: any) => loa
              );
              const removedLoaNames = loaCodeDiff.removedLoaNames.map(
                (loa: any) => loa
              );

              const addedLoaString =
                addedLoaNames.length > 0
                  ? `Added LOAs - ${addedLoaNames.join(', ')} `
                  : '';
              const removedLoaString =
                removedLoaNames.length > 0
                  ? `Removed LOAs - ${removedLoaNames.join(', ')} `
                  : '';

              return `- State ${stateName}: ${addedLoaString} ${removedLoaString} `;
            }
          )}`
        );
      }

      if (
        event.additionalMetadata?.stateAssignmentDifferences
          ?.licenseClassDifferences?.length !== 0
      ) {
        event.additionalMetadata?.stateAssignmentDifferences?.licenseClassDifferences.forEach(
          (lcCodeDiff: any) => {
            const stateName = lcCodeDiff.stateName;

            const lcAdded = lcCodeDiff.lcAdded.map((lc: any) => lc.lcName);
            const lcRemoved = lcCodeDiff.lcRemoved.map((lc: any) => lc.lcName);

            const addedString =
              lcAdded.length > 0
                ? `Added license classes - ${lcAdded.join(', ')} `
                : '';
            const removedString =
              lcRemoved.length > 0
                ? `Removed license classes - ${lcRemoved.join(', ')} `
                : '';

            if (addedString || removedString) {
              stateAssignmentDescription.push(
                `- State assignment updated for the state ${stateName}: ${addedString} ${removedString} `
              );
            }
          }
        );
      }

      if (
        event.additionalMetadata?.stateAssignmentDifferences?.loaCodeDifferences
          ?.length !== 0
      ) {
        event.additionalMetadata?.stateAssignmentDifferences?.loaCodeDifferences.forEach(
          (loaCodeDiff: any) => {
            const stateName = loaCodeDiff.stateName;

            const addedLoaNames = loaCodeDiff.addedLoaNames.map(
              (loa: any) => loa
            );
            const removedLoaNames = loaCodeDiff.removedLoaNames.map(
              (loa: any) => loa
            );

            const addedLoaString =
              addedLoaNames.length > 0
                ? `Added LOAs - ${addedLoaNames.join(', ')} `
                : '';
            const removedLoaString =
              removedLoaNames.length > 0
                ? `Removed LOAs - ${removedLoaNames.join(', ')} `
                : '';

            if (addedLoaString || removedLoaString) {
              stateAssignmentDescription.push(
                `- State assignment updated for the state ${stateName}: ${addedLoaString} ${removedLoaString} `
              );
            }
          }
        );
      }

      return stateAssignmentDescription;

    //Changes have been made to the employment history.
    case AppEventSubTypes.StateRemoved:
      return (
        `- Assignment no longer includes ` +
        event.additionalMetadata?.stateName +
        `(${event.additionalMetadata?.stateCode})`
      );

    case AppEventSubTypes.StateGroupAdded:
      return (
        `- Introduced  ` +
        event.additionalMetadata?.stateGroupName +
        ` as a new territory`
      );

    case AppEventSubTypes.StateGroupUpdated:
      let description: string[] = [];

      const addedStatesList =
        event.additionalMetadata?.stateGroupDifferences?.addedStatesList;

      if (Array.isArray(addedStatesList) && addedStatesList.length > 0) {
        if (addedStatesList.length === 1) {
          description.push(
            ` - State of ${getStateNameFromCode(
              addedStatesList[0].stateCode
            )}(${addedStatesList[0].stateCode}) was added `
          );
        } else {
          description.push(
            ` - States added: ${addedStatesList
              .map(
                (addedState) =>
                  `${getStateNameFromCode(addedState.stateCode)}(${
                    addedState.stateCode
                  })`
              )
              .join(', ')} `
          );
        }
      }

      const removedStatesList =
        event.additionalMetadata?.stateGroupDifferences?.removedStatesList;

      if (Array.isArray(removedStatesList) && removedStatesList.length > 0) {
        if (removedStatesList.length === 1) {
          description.push(
            `- State of ${getStateNameFromCode(
              removedStatesList[0].stateCode
            )}(${removedStatesList[0].stateCode}) was removed `
          );
        } else {
          description.push(
            `- States removed: ${removedStatesList
              .map(
                (removedState) =>
                  `${getStateNameFromCode(removedState.stateCode)}(${
                    removedState.stateCode
                  })`
              )
              .join(', ')} `
          );
        }
      }

      const individualLcDiff =
        event.additionalMetadata?.stateGroupDifferences
          ?.licenseClassDifferences;

      if (Array.isArray(individualLcDiff) && individualLcDiff.length === 1) {
        const firstDifference = individualLcDiff[0];

        if (firstDifference.lcAdded) {
          const lcAdded = firstDifference.lcAdded;

          if (lcAdded.length === 1) {
            description.push(
              ` - License class ${appStateInfoStore.getLcNameFromCode(
                lcAdded[0]?.lcCode
              )} was added for the state of ${getStateNameFromCode(
                firstDifference.stateCode
              )}(${firstDifference.stateCode}) `
            );
          } else if (lcAdded.length > 1) {
            description.push(
              ` - License classes added: ${lcAdded
                .map(
                  (individualLc: any) =>
                    ` ${appStateInfoStore.getLcNameFromCode(
                      individualLc?.lcCode
                    )}`
                )
                .join(', ')} `
            );
          }
        }
        if (firstDifference.lcRemoved) {
          const lcRemoved = firstDifference.lcRemoved;

          if (lcRemoved.length === 1) {
            description.push(
              ` - License class ${appStateInfoStore.getLcNameFromCode(
                lcRemoved[0]?.lcCode
              )} was removed for the state of ${getStateNameFromCode(
                firstDifference.stateCode
              )}(${firstDifference.stateCode}) `
            );
          } else if (lcRemoved.length > 1) {
            description.push(
              ` - License classes removed: ${lcRemoved
                .map(
                  (individualLc: any) =>
                    ` ${appStateInfoStore.getLcNameFromCode(
                      individualLc?.lcCode
                    )}`
                )
                .join(', ')} `
            );
          }
        }
      }

      const licenseClassDifferences =
        event.additionalMetadata?.stateGroupDifferences
          ?.licenseClassDifferences;

      if (
        Array.isArray(licenseClassDifferences) &&
        licenseClassDifferences.length > 1
      ) {
        for (const licenseClassDiff of licenseClassDifferences) {
          let state = licenseClassDiff?.stateCode;

          if (licenseClassDiff?.lcAdded.length) {
            for (const lc of licenseClassDiff?.lcAdded) {
              description.push(
                ` - License class ${appStateInfoStore.getLcNameFromCode(
                  lc.lcCode
                )} was added for ${getStateNameFromCode(state)}(${state})`
              );
            }
          } else if (licenseClassDiff?.lcRemoved.length) {
            description.push(
              ` - License class ${licenseClassDiff?.lcRemoved
                .map((lc: any) =>
                  appStateInfoStore.getLcNameFromCode(lc.lcCode)
                )
                .join(
                  ', '
                )} was removed for the state of ${getStateNameFromCode(
                state
              )}(${state}) `
            );
          }
        }
      }

      const loaCodeDifferences =
        event.additionalMetadata?.stateGroupDifferences?.loaCodeDifferences;

      if (
        Array.isArray(loaCodeDifferences) &&
        loaCodeDifferences.length === 1
      ) {
        const loaDiff = loaCodeDifferences[0];

        if (loaDiff.loaCodeAdded && loaDiff.loaCodeAdded.length > 0) {
          let loaAddedText = loaDiff.loaCodeAdded
            .filter((itr: any) => itr.trim() !== '')
            .map((loa: any) => {
              return `- Added ${appStateInfoStore.getLoaNameFromCode(
                loa
              )} for the License Class ${appStateInfoStore.getLcNameFromCode(
                loaDiff.lcCode
              )} of the state ${getStateNameFromCode(loaDiff.stateCode)} (${
                loaDiff.stateCode
              }) `;
            })
            .join(', ');

          description.push(loaAddedText);
        }

        if (loaDiff.loaCodeRemoved && loaDiff.loaCodeRemoved.length > 0) {
          let loaRemovedText = loaDiff.loaCodeRemoved
            .filter((itr: any) => itr.trim() !== '')
            .map((loa: any) => {
              return `- Removed ${appStateInfoStore.getLoaNameFromCode(
                loa
              )} for the License Class ${appStateInfoStore.getLcNameFromCode(
                loaDiff.lcCode
              )} of the state ${getStateNameFromCode(loaDiff.stateCode)} (${
                loaDiff.stateCode
              }) `;
            })
            .join(', ');

          description.push(loaRemovedText);
        }
      } else if (
        Array.isArray(loaCodeDifferences) &&
        loaCodeDifferences.length > 1
      ) {
        loaCodeDifferences.forEach((loaDiff) => {
          let lc = loaDiff.lcCode;
          let state = loaDiff.stateCode;

          if (
            loaDiff.loaCodeAdded.length !== 0 &&
            loaDiff.loaCodeRemoved.length !== 0
          ) {
            let loaAddedText = loaDiff.loaCodeAdded
              .filter((itr: any) => itr.trim() !== '')
              .map((loa: any) => {
                return `- Added ${appStateInfoStore.getLoaNameFromCode(
                  loa
                )} for the License Class ${appStateInfoStore.getLcNameFromCode(
                  lc
                )} of the state ${getStateNameFromCode(state)} (${state}) `;
              })
              .join(', ');

            let loaRemovedText = loaDiff.loaCodeRemoved
              .filter((itr: any) => itr.trim() !== '')
              .map((loa: any) => {
                return `- Removed ${appStateInfoStore.getLoaNameFromCode(
                  loa
                )} for the License Class ${appStateInfoStore.getLcNameFromCode(
                  lc
                )} of the state ${getStateNameFromCode(state)} (${state}) `;
              })
              .join(', ');

            description.push(loaAddedText, loaRemovedText);
          }

          if (loaDiff.loaCodeAdded.length !== 0) {
            let loaAddedText = loaDiff.loaCodeAdded
              .filter((itr: any) => itr.trim() !== '')
              .map((loa: any) => {
                return `- Added ${appStateInfoStore.getLoaNameFromCode(
                  loa
                )} for the License Class ${appStateInfoStore.getLcNameFromCode(
                  lc
                )} of the state ${getStateNameFromCode(state)} (${state}) `;
              })
              .join(', ');

            description.push(loaAddedText);
          }

          if (loaDiff.loaCodeRemoved.length !== 0) {
            let loaRemovedText = loaDiff.loaCodeRemoved
              .filter((itr: any) => itr.trim() !== '')
              .map((loa: any) => {
                return `- Removed ${appStateInfoStore.getLoaNameFromCode(
                  loa
                )} for the License Class ${appStateInfoStore.getLcNameFromCode(
                  lc
                )} of the state ${getStateNameFromCode(state)} (${state}) `;
              })
              .join(', ');

            description.push(loaRemovedText);
          }
        });
      }

      return description;

    case AppEventSubTypes.StateGroupDuplicated:
      return (
        `- Introduced  ` +
        event.additionalMetadata?.stateGroupName +
        ` as a new territory`
      );

    case AppEventSubTypes.StateGroupConvertedToAssignments:
      return (
        `- Removed  ` +
        event.additionalMetadata?.stateGroupName +
        ` territory and saved the states as separate assignments`
      );

    case AppEventSubTypes.StateGroupRemoved:
      return `Removed ` + event.additionalMetadata?.stateGroupName;

    case AppEventSubTypes.CreateGatewayTransaction:
      return event.terminalStatus === TerminalStatus.FAILURE
        ? `- License application failed for the license number ${event?.license?.licenseNumber} for the license class ${event.license?.licenseClass} of the state ${event.license?.stateName}(${event.license?.state})`
        : event.additionalMetadata?.error
        ? `- License application failed for the license number ${event?.license?.licenseNumber} for the license class ${event.license?.licenseClass} of the state ${event.license?.stateName}(${event.license?.state})`
        : `- License application has been initiated
            with license number ${event.license?.licenseNumber} for the license class ${event.license?.licenseClass} of the state ${event.license?.stateName}(${event.license?.state})`;

    default:
      break;
  }
  return '';
}

function getTypeFromAgentActivityEvent(
  eventType: AppEventTypes,
  eventSubType: AppEventSubTypes,
  event: AgentActivityTimelineEvent
): 'Success' | 'Danger' | 'Warning' {
  switch (eventSubType) {
    case AppEventSubTypes.Onboarded:
      return 'Success';

    case AppEventSubTypes.LicenseExpired:
      return 'Danger';

    case AppEventSubTypes.Offboarded:
      return 'Danger';

    case AppEventSubTypes.StateRemoved:
      return 'Danger';

    case AppEventSubTypes.StateGroupRemoved:
      return 'Danger';

    case AppEventSubTypes.StateGroupConvertedToAssignments:
      return 'Danger';

    case AppEventSubTypes.FilledBGQuestions:
      return 'Success';

    case AppEventSubTypes.CreateGatewayTransaction:
      return event.terminalStatus === TerminalStatus.FAILURE ||
        event.additionalMetadata?.error
        ? 'Danger'
        : 'Success';

    case AppEventSubTypes.FilledEmploymentHistory:
      return 'Success';

    case AppEventSubTypes.Watchlisted:
      return 'Danger';

    default:
      break;
  }
  return 'Success';
}

function ActivityCard(props: CardProps) {
  const { date, time } = formatDateTime(props.activity.createdAt);
  const title = getCardTitleFromAgentActivityEvent(props.activity);
  const description = getCardDescriptionFromAgentActivityEvent(props.activity);

  return (
    <div>
      <CardWithLeftLine
        text={title}
        date={date}
        time={time}
        lineColor={
          props.activity.terminalStatus === TerminalStatus.FAILURE ||
          props.activity.terminalStatus === TerminalStatus.WARNING ||
          props.activity.appEventSubType === AppEventSubTypes.Offboarded ||
          props.activity.appEventSubType === AppEventSubTypes.StateRemoved ||
          props.activity.appEventSubType ===
            AppEventSubTypes.StateGroupConvertedToAssignments ||
          props.activity.appEventSubType ===
            AppEventSubTypes.StateGroupRemoved ||
          props.activity.appEventSubType === AppEventSubTypes.Watchlisted ||
          props.activity.additionalMetadata?.error
            ? '#f00'
            : props.activity.terminalStatus === TerminalStatus.SUCCESS
            ? '#0a1'
            : 'var(--primary-color)'
        }
        textType={getTypeFromAgentActivityEvent(
          props.activity.appEventType,
          props.activity.appEventSubType,
          props.activity
        )}
        description={description}
      />
    </div>
  );
}

export default ActivityCard;
