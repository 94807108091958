import React, { ReactNode } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

type fileInfo = {
  link: string;
  fileName: string;
};

type Props = {
  classNames?: string;
  type: 'internalNavigation' | 'externalNavigation' | 'download' | 'tabSwitch';
  styles?: React.CSSProperties;
  downloadFile?: fileInfo[];
  destination?: string;
  buttonName: string | ReactNode;
  file?: fileInfo;
  setButtonState?: (option: string) => void;
  setModalState?: (option: boolean) => void;
  tabKey?: string;
  size?: 'large' | 'small' | 'middle';
};

export default function CustomButton({
  classNames,
  styles,
  type,
  destination,
  buttonName,
  file,
  setButtonState,
  setModalState,
  tabKey,
  size,
}: Props) {
  const navigate = useNavigate();

  const handleClicks = {
    externalNavigation: () => window.open(destination!, '_blank'),
    internalNavigation: () => navigate(destination!),
    download: () => {
      if (file) {
        const { link, fileName } = file;
        const downloadLink = document.createElement('a');
        downloadLink.href = link;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },
    tabSwitch: () => {
      if (tabKey && setButtonState) {
        setButtonState(tabKey);
      }
    },
    modalPopup: () => {
      setModalState && setModalState(true);
    },
  };
  const handleClick: (() => void) | undefined = handleClicks[type];

  return (
    <>
      <Button
        id={buttonName?.toString().toLowerCase().replace(' ', '-')}
        size={size}
        className={classNames}
        style={styles ? styles : {}}
        onClick={handleClick}
      >
        {buttonName}
      </Button>
    </>
  );
}
