import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

interface CustomStepsProps {
  current: number;
  steps: string[];
  onClickStep: (step: number) => void;
  maxStep: number;
  minStep?: number;
  disabled?: boolean;
}

const CustomSteps: React.FC<CustomStepsProps> = ({
  current,
  steps,
  onClickStep,
  maxStep,
  minStep,
  disabled,
}) => {
  const handleStepClick = (index: number) => {
    if (minStep) {
      if (index <= maxStep && index >= minStep) {
        onClickStep(index);
      }
    } else {
      if (index <= maxStep) {
        onClickStep(index);
      }
    }
  };

  return (
    <Steps size="small" current={current} onChange={() => {}}>
      {steps.map((step, index) => {
        return (
          <Step
            key={index}
            title={step}
            disabled={disabled}
            onClick={() => {
              handleStepClick(index);
            }}
            style={minStep && index > minStep ? { cursor: 'not-allowed' } : {}}
            className={index > maxStep || disabled ? 'disabled-step' : ''}
          />
        );
      })}
    </Steps>
  );
};

export default CustomSteps;
