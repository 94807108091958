import {
  Button,
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { AccountWithProducerDocument } from '../../../../types/response/data/agents/agentDocument.type';
import { AgentService } from '../../../../services/agent.service';
import { ColumnsType } from 'antd/es/table';
import ErrorModal from '../../../../components/common/errorModal/errorModal';
import { IdConstants } from '../../../../constants/id.constants';
import OffboardConfirmModal from './offboardConfirmationModal';
import { RoleType } from '../../../../enums/roles.enum';
import { RouteConstants } from '../../../../constants/routes.constants';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { applySort } from '../../../../utils/common.utils';
import { getNameString } from '../../../../utils/name.utils';
import getProducerDetailsTableColumns from '../../../../tables/producerDetails.table';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useQueryState } from '../../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../../auth/useRoles';

const { Search } = Input;

function IndividualOffboard() {
  const [selectedRows, setSelectedRows] = useState<
    AccountWithProducerDocument[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [agentCount, setAgentCount] = useState(0);
  const [sortValue, setSortValue] = useState({});
  const [agents, setAgents] = useState<AccountWithProducerDocument[]>([]);
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [agentsToBeOffboarded, setAgentsToBeOffboarded] = useState<
    AccountWithProducerDocument[]
  >([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    loadAgents(queryText || '', newPageNumber, newPageSize);
  };

  useEffect(() => {
    if (agentCount === 0 && pageNumber > 1) {
      setPageNumber(1);
    }
  }, [agentCount]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const loadAgents = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    try {
      const response = await AgentService.getAllProducersForAgency(
        token,
        size || pageSize,
        page || pageNumber,
        searchTextProp ? searchTextProp : queryText || '',
        { ...(sort || sortValue || {}) }
      );

      if (response?.data) {
        const newAgents: AccountWithProducerDocument[] = response.data;
        if (newAgents.length === 0 && pageNumber > 1) {
          setPageNumber(1);
        }

        setAgentCount(response.totalCount);
        setAgents(newAgents);
        setSelectedRows([]);
      }
      agents && setIsLoading(false);
    } catch (error) {
      console.error('Error! Unable to fetch agents at the moment', error);
    } finally {
      setIsLoading(false);
    }
  };

  const redirect = (record: AccountWithProducerDocument) => {
    appStateInfoStore.setHeader(
      getNameString(record.producer?.name ? record.producer?.name : record.name)
    );
    navigate(
      RouteConstants.agentOverview.path.replace(':agentId', record.producer.id)
    );
  };

  const tableColumns: ColumnsType<AccountWithProducerDocument> = [
    ...getProducerDetailsTableColumns({
      showContactEmail: true,
      showPaymentConfig: true,
      showResidentState: true,
      onAgentClick: redirect,
    }),
    {
      dataIndex: '_id',
      render: (_id: string, record: AccountWithProducerDocument) => (
        <Button
          className="button important-action-button"
          onClick={() => {
            setAgentsToBeOffboarded([record]);
            setModalVisibility(true);
          }}
          disabled={selectedRows.length > 0}
          style={selectedRows.length > 0 ? { opacity: '50%' } : {}}
        >
          <Tooltip title="Offboard producer">
            <DeleteOutlined />
          </Tooltip>
        </Button>
      ),
    },
  ];

  const handleOffboarding = (value: any) => {
    setAgentsToBeOffboarded(value);
  };

  useEffect(() => {
    loadAgents();
  }, [queryText]);

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: AccountWithProducerDocument[]
    ) => {
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRows.map(
      (sa) => sa?.producer?.id + sa?.agency?.id
    ),
  };

  return (
    <div className="offboard-agents">
      <Card
        style={{ width: '100%', marginTop: '20px' }}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
        }}
      >
        <Row justify="space-between" className="radio-buttons reset-icon-size">
          <Typography.Text
            type="secondary"
            style={{ fontSize: '14px', marginBottom: 0 }}
          >
            <Space>
              <InfoCircleOutlined />
              Select producers you want to offboard
            </Space>
          </Typography.Text>
          <Button
            id={IdConstants.AGENTS.OFFBOARD.INDIVIDUAL_OFFBOARDING.BUTTON}
            className="button important-action-button"
            disabled={selectedRows.length > 0 && !isLoading ? false : true}
            onClick={() => {
              setAgentsToBeOffboarded(selectedRows);
              setModalVisibility(true);
            }}
            style={{
              opacity: selectedRows.length > 0 && !isLoading ? '100%' : '50%',
            }}
          >
            Offboard Selected
          </Button>
        </Row>
        {/* <Search
          size="middle"
          id={IdConstants.SEARCH_INPUT + '-offboard-individual'}
          placeholder="Search by Producers, NPN"
          style={{
            borderRadius: 1,
            color: '#92A69E',
            borderColor: 'var(--border-color)',
          }}
          value={searchText}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          onSearch={(value) => {
            if (queryText !== value) {
              setPageNumber(1);
              setQueryText(value);
            }
          }}
          onPressEnter={(e) => {
            if (queryText !== (e.target as HTMLInputElement).value) {
              setPageNumber(1);
              setQueryText((e.target as HTMLInputElement).value);
            }
          }}
          disabled={isLoading}
        /> */}
        <Row>
          <Col span={24}>
            <Table
              rowKey={(record) => record?.producer?.id + record?.agency?.id}
              columns={tableColumns}
              dataSource={agents}
              onChange={(pagination, filters, sorter) => {
                const sort = applySort(sorter, loadAgents);
                setSortValue(sort);
              }}
              rowSelection={{ type: 'checkbox', ...rowSelection }}
              style={{ height: '100%', marginBottom: '10px' }}
              pagination={false}
              loading={isLoading}
            />
          </Col>

          {!isEmpty(agents) && (
            <Col span={24}>
              <Row justify="end">
                <Pagination
                  pageSize={pageSize}
                  current={pageNumber}
                  total={agentCount}
                  onChange={onPageChange}
                  showSizeChanger
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  disabled={isLoading}
                />
              </Row>
            </Col>
          )}
        </Row>

        <OffboardConfirmModal
          handleVisibility={(isCancel?: boolean) => {
            if (!isCancel) {
              setSelectedRows([]);
            }
            setAgentsToBeOffboarded([]);
            setModalVisibility(false);
          }}
          visibility={modalVisibility}
          agentsToBeOffboarded={agentsToBeOffboarded}
          setAgentsToBeOffboarded={handleOffboarding}
          setError={(msg) => {
            setErrorMessage(msg);
          }}
          reloadPage={() => {
            loadAgents();
            return;
          }}
        />
      </Card>
      {errorMessage && (
        <ErrorModal
          errorMessage={errorMessage}
          onClose={() => {
            loadAgents();
            setErrorMessage(null);
          }}
        />
      )}
    </div>
  );
}

export default withRoles(observer(IndividualOffboard), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
