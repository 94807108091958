import { StateDetails } from '../types/data/masterData';
import { appStateInfoStore } from '../stores/appStateInfo.store';
import { forEach } from 'lodash';

export const getLcDetailsForLoaAndState = (loaCode: string, state: string) => {
  const lcDetails: { lcCode: string; licenseClass: string } = {
    lcCode: '',
    licenseClass: '',
  };
  const masterData: StateDetails[] = [
    ...appStateInfoStore.getLicenseConfigsMasterData(),
  ];
  forEach(masterData, (md) => {
    if (md.stateCode === state) {
      md.licenseClasses.forEach((lc) => {
        lc.loaData.forEach((loa) => {
          if (loa.loaCode === loaCode) {
            lcDetails.lcCode = lc.lcCode;
            lcDetails.licenseClass = lc.licenseClass;
          }
        });
      });
    }
  });
  return lcDetails;
};
