import { Button, Typography } from 'antd';

import { ApplicationFilterType } from '../../../../types/data/advancedFilters.type';
import { ClearOutlined } from '@ant-design/icons';
import { FilterChip } from './filterChip';
import { PlausibleEventNameConstants } from '../../../../constants/eventName.constants';
import { PlausiblePageConstants } from '../../../../constants/plausible-page.constants';
import { trackCustomEvent } from '../../../../utils/trackingUtils';

export const RenderFilterChip: React.FC<ApplicationFilterType> = ({
  selectedFilters,
  removeFilter,
  clearFilters,
  pageName = PlausiblePageConstants.UNKNOWN,
}) => {
  return (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      {Object.keys(selectedFilters.data).length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#8D8D8D',
              marginRight: '2px',
              marginLeft: '2px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: 0,
            }}
          >
            Filters Applied:
          </Typography.Paragraph>
          <div
            className="custom-scrollbar"
            style={{
              flex: 1,
              margin: '0px 10px',
              display: 'flex',
              width: '100%',
              overflowX: 'auto',
              // height: '50px',
              overflowY: 'hidden',
            }}
          >
            <FilterChip
              selectedFilters={selectedFilters}
              removeFilters={removeFilter}
            />
          </div>
          <Button
            onClick={() => {
              clearFilters();
              trackCustomEvent(
                PlausibleEventNameConstants.CLEAR_ALL_FILTER_OPTION,
                pageName
              );
            }}
            type="ghost"
            style={{ padding: '4px' }}
          >
            <Typography.Text
              style={{
                fontSize: '14px',
                color: 'var(--hint-primary-color)',
                cursor: 'pointer',
                display: 'flex',
                fontFamily: 'Poppins',
                gap: 5,
              }}
            >
              <ClearOutlined />
              Clear All Filters
            </Typography.Text>
          </Button>
        </div>
      )}
    </div>
  );
};
