import moment from 'moment';

/**
 * - Converts a date object into a date time string
 * - Example Output - "Sat Feb 10 2024, 02:23PM"
 * @param dateObj
 * @returns
 */
export function formatDateWithTime(dateObj: Date): string {
  if (!dateObj) return '';
  const monthsOfYear = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (typeof dateObj === 'string') dateObj = new Date(dateObj);
  const month = monthsOfYear[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  let hour = dateObj.getHours();
  const minute = dateObj.getMinutes();
  const period = hour >= 12 ? 'PM' : 'AM';

  // Convert hour to 12-hour format
  if (hour > 12) {
    hour -= 12;
  } else if (hour === 0) {
    hour = 12;
  }

  return `${month} ${day} ${year}, ${hour.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')}${period}`;
}

/**
 * Checks if the given date is x days old or older.
 * @param date The date to check.
 * @param x The number of days to compare against.
 * @returns True if the date is x days old or older, otherwise false.
 */
export function isXDaysOld(date: Date, x: number): boolean {
  if (!date) return false;

  // Calculate the difference in milliseconds between the current date and the provided date
  const differenceMs = new Date().getTime() - new Date(date).getTime();

  // Convert milliseconds to days
  const differenceDays = differenceMs / (1000 * 60 * 60 * 24);

  // Check if the difference is greater than or equal to x
  return differenceDays >= x;
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export const UnresolvedTooltip = () => {
  const currentDateTime = moment(new Date());
  const currentHour = currentDateTime.hour();
  const isBefore7PM = currentHour < 19; // 7 PM is represented as 19 in 24-hour format

  let nextEventDate;
  if (isBefore7PM) {
    // If current time is before 7 PM, display current date
    nextEventDate = currentDateTime.format('ll');
  } else {
    // If current time is after 7 PM, display tomorrow's date
    nextEventDate = currentDateTime.add(1, 'day').format('ll');
  }

  return `Applications are refreshed every 24 hours. Next event to occur ${nextEventDate} at 7pm EST.`;
};

// Utility function to convert days to appropriate unit
// below 30 days - returns 'x days'
// between 31 and 364 days - returns 'x months'
// 365 days and more - return 'x years y months'
export function formatDays(days: number): string {
  const daysInYear = 365;
  const daysInMonth = 30;

  if (days < daysInMonth) {
    const dayLabel = days > 1 ? 'days' : 'day';
    return `${days} ${dayLabel}`;
  } else if (days >= daysInYear) {
    const years = Math.floor(days / daysInYear);
    const remainingDays = days % daysInYear;
    const remainingMonths = Math.round(remainingDays / daysInMonth);
    const yearLabel = years > 1 ? 'years' : 'year';
    const monthLabel = remainingMonths > 1 ? 'months' : 'month';
    return remainingMonths > 0
      ? `${years} ${yearLabel} ${remainingMonths} ${monthLabel}`
      : `${years} ${yearLabel}`;
  } else {
    const months = Math.round(days / daysInMonth);
    const monthLabel = months > 1 ? 'months' : 'month';
    return `${months} ${monthLabel}`;
  }
}

export function convertMomentToDate(momentObj: moment.Moment): Date {
  const defaultDate = new Date();
  defaultDate.setUTCFullYear(momentObj.year());
  defaultDate.setUTCMonth(momentObj.month());
  defaultDate.setUTCDate(momentObj.date());
  defaultDate.setUTCHours(0);
  defaultDate.setUTCMinutes(0);
  defaultDate.setUTCSeconds(0);
  defaultDate.setUTCMilliseconds(0);
  return defaultDate;
}
