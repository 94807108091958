import * as xlsx from 'xlsx';

class FileParser {
  parseCsvToJson = (fileData: string) => {
    const lines = fileData.split('\n');
    const headers = lines[0].split(',');
    const multipleOnboardJsonData = [];

    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(',');
      const jsonObject: { [key: string]: string } = {};

      for (let j = 0; j < headers.length; j++) {
        const cleanedKey = headers[j]?.replace(/"/g, '').trim();
        const cleanedValue = values[j]?.replace(/"/g, '').replace(/\r/g, '');

        jsonObject[cleanedKey] = cleanedValue;
      }

      multipleOnboardJsonData.push(jsonObject);
    }

    return multipleOnboardJsonData;
  };

  parseXLSX(xlsxData: ArrayBuffer): any[] {
    const workbook = xlsx.read(new Uint8Array(xlsxData), { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const multipleOnboardJsonData = xlsx.utils.sheet_to_json(worksheet);

    return multipleOnboardJsonData;
  }

  parseFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target?.result;
        if (fileData instanceof ArrayBuffer) {
          if (file.type === 'text/csv') {
            const textDecoder = new TextDecoder();
            const csvData = textDecoder.decode(fileData);
            const parsedData = this.parseCsvToJson(csvData);
            resolve(parsedData);
          } else if (
            file.type ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.ms-excel'
          ) {
            const multipleOnboardJsonData = this.parseXLSX(fileData);
            resolve(multipleOnboardJsonData);
          } else {
            reject(new Error('Unsupported file type'));
          }
        } else {
          reject(new Error('Failed to read file as ArrayBuffer'));
        }
      };
      reader.onerror = (event) => {
        reject(event.target?.error);
      };
      reader.readAsArrayBuffer(new Blob([file]));
    });
  }
}

export default FileParser;
