import { Button, Card, Form, Input, Modal, Select, Typography } from 'antd';
import IsWatchlistedModal, {
  WatchlistedAgentDetails,
} from './onboardAgents/individualOnboard/watchlist';
import { NPN_REGEX, SSN_REGEX } from '../../constants/regex.constants';
import {
  isErrorDuplicateEmail,
  isErrorNpnNotFound,
} from '../../utils/errorUtils';

import { BlackListService } from '../../services/blacklist.service';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { DownlineInviteService } from '../../services/downline-invite.service';
import { DownlineService } from '../../services/downlines.service';
import { ErrorMessageConstants } from '../../constants/error-message.constants';
import { IdConstants } from '../../constants/id.constants';
import InviteDownLineModal from './inviteDownlineModal';
import InviteDownlineTour from '../../components/common/Tour/inviteDownlineTour';
import { NiprLookupService } from '../../services/nipr-lookup.service';
import { NiprTransactionType } from '../../enums/niprTransactionType';
import { RoleType } from '../../enums/roles.enum';
import { RouteConstants } from '../../constants/routes.constants';
import StateSelect from '../../components/common/stateSelect/StateSelect';
import { adminStore } from '../../stores/admin.store';
import { getNameString } from '../../utils/name.utils';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../stores/tour.store';
import { useAuth } from '../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { withRoles } from '../../auth/useRoles';

export interface VerifyDownlineDetails {
  npn?: string;
  ssn?: string;
  lastName?: string;
  name: string;
  licenseNumber?: string;
  state?: string;
  email?: string;
}
export interface CreditDetails {
  credit: number;
  date: number;
  month: number;
  year: number;
}

export interface SendInviteObject {
  inviterEntityDetails?: {
    agentId?: string;
    agencyId?: string;
  };
  inviterDetails?: {
    agencyId?: string;
    adminId?: string;
  };
  inviteeDetails?: {
    email?: string;
    npn?: string;
    licenseNumber?: string;
    state?: string;
  };
  invitedType?: string;
  inviteType?: string;
  agencyType: string;
  invitedOn?: string;
  adminId?: string;
  email?: string;
  npn?: string;
  ssn?: string;
  lastName?: string;
  licenseNumber?: string;
  state?: string;
  invitedUsing?: string;
  invitationDate?: string;
  // creditAmount?: number;
  isInviteeRegistered?: Boolean;
  expiryDate?: string;
  homeState?: string;
  entityType?: NiprTransactionType;
}

function InviteDownline({
  onError,
}: {
  onError: (error: string | null) => void;
}) {
  const { bearerToken } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [downlineLookup, setDownlineLookup] = useState<string>('npn');
  const [fetchDetailsSuccess, setFetchDetailsSuccess] =
    useState<boolean>(false);
  const [inviteLoading, setInviteLoading] = useState<boolean>(false);
  const [downlineDetails, setDownlineDetails] = useState<VerifyDownlineDetails>(
    {
      name: '',
      email: '',
    }
  );
  const [sendInviteObject, setSendInviteObject] = useState<SendInviteObject>();
  const [sendInviteComplete, setsendInviteComplete] = useState<boolean>(false);
  const [downlineType, setDownlineType] = useState<NiprTransactionType>();
  const [inviteDownlineForm] = Form.useForm();
  const [watchlistedAgent, setWatchlistedAgent] =
    useState<WatchlistedAgentDetails>();
  const [watchlistModalVisible, setWatchlistModalVisible] = useState(false);
  const [validateForm, setValidateForm] = useState(() => () => {});

  const [modalVisibility, setModalVisibility] = useState(false);
  const onClose = () => {
    setModalVisibility(false);
  };

  const handleNavigate = () => {
    navigate(RouteConstants.downlines.path);
    if (tourStore.inviteDownlineDemoTour) {
      setTimeout(() => {
        tourStore.setInviteDownlineDemoTourCurrentStep(0);
        tourStore.setInviteDownlineDemoTourPhase(3);
        tourStore.setInviteDownlineDemoTourVisible(true);
      }, 100);
    }
  };
  const handleSendInviteComplete = () => {
    setsendInviteComplete(true);
  };

  const handleVerifyDownline = async (values: any) => {
    setLoading(true);
    setDownlineDetails(values);
    try {
      if (bearerToken) {
        // let npn;
        // let niprNpnResponse;
        // if (downlineLookup === 'ssn-lastName') {
        //   const niprSsnLookupResp: any = await NiprLookupService.niprSsnLookup(
        //     values.ssn,
        //     values.lastname,
        //     bearerToken
        //   ).catch((error) => {
        //     setFetchDetailsSuccess(false);
        //     console.error('catch error :: niprSsnLookupResp :: ', error);
        //     throw error;
        //   });
        //   npn = niprSsnLookupResp?.data?.data;
        //   setDownlineDetails((prev) => ({
        //     ...prev,
        //     npn: niprSsnLookupResp?.data?.data,
        //   }));
        //   niprSsnLookupResp && setFetchDetailsSuccess(true);
        // } else if (downlineLookup === 'licenseNumber-state') {
        //   const niprLicenseLookup: any =
        //     await NiprLookupService.niprLicenseLookup(
        //       values.license,
        //       values.state,
        //       bearerToken
        //     ).catch((error) => {
        //       setFetchDetailsSuccess(false);
        //       console.error('catch error :: niprLicenseLookup :: ', error);
        //       throw error;
        //     });
        //   npn = niprLicenseLookup?.data?.data?.npn;
        //   setDownlineDetails((prev) => ({
        //     ...prev,
        //     npn: niprLicenseLookup?.data?.data?.npn,
        //   }));
        //   niprLicenseLookup && setFetchDetailsSuccess(true);
        // }
        // const niprNpnLookup: any = await DownlineService.verifyDownline(
        //   npn ? npn : values.npn,
        //   values.email,
        //   bearerToken
        // ).catch((error: any) => {
        //   setFetchDetailsSuccess(false);
        //   console.error('catch error :: niprNpnLookup :: ', error);
        //   throw error;
        // });
        // niprNpnResponse = niprNpnLookup;
        // let agentBlacklisted = false;
        try {
          if (bearerToken && adminStore.agency?.id) {
            // const watchlistResponse: any =
            //   await BlackListService.getBlacklistedAgentsGivenAgency(
            //     npn ||
            //       niprNpnResponse?.data?.producer?.entityBiographic?.biographic
            //         .npn ||
            //       values.npn,
            //     bearerToken
            //   ).catch((error) => {
            //     console.error(error);
            //   });
            // if (watchlistResponse.data?.name) {
            //   const found = watchlistResponse.data.agencyInfo.find(
            //     (itr: any) => {
            //       if (itr.agencyId === adminStore.agency?.id) {
            //         return itr;
            //       }
            //     }
            //   );
            //   if (found) {
            //     agentBlacklisted = true;
            //     setWatchlistedAgent({
            //       agentName: watchlistResponse.data.name,
            //       npn:
            //         npn ||
            //         niprNpnResponse?.data?.producer?.entityBiographic
            //           ?.biographic.npn ||
            //         values.npn,
            //       reason: found.reason,
            //       downlineInvite: true,
            //     });
            //     setWatchlistModalVisible(true);
            //     setLoading(false);
            //   }
            // } else {
            //   niprNpnLookup && setFetchDetailsSuccess(true);
            //   setLoading(false);
            //   if (tourStore.inviteDownlineDemoTour) {
            //     setTimeout(() => {
            //       tourStore.setInviteDownlineDemoTourVisible(true);
            //       tourStore.setInviteDownlineDemoTourCurrentStep(0);
            //       tourStore.setInviteDownlineDemoTourPhase(2);
            //     }, 100);
            //   }
            // }

            // setDownlineType(niprNpnResponse?.data?.type);
            // if (niprNpnResponse?.data?.type === 'FIRM') {
            //   const name =
            //     niprNpnResponse?.data?.producer?.entityBiographic?.biographic
            //       ?.nameCompany;
            //   inviteDownlineForm.setFieldValue('name', name);
            //   setDownlineDetails((prev) => ({
            //     ...prev,
            //     name: name,
            //   }));
            // } else {
            //   const name = getNameString({
            //     firstName:
            //       niprNpnResponse?.data?.producer?.entityBiographic?.biographic
            //         ?.firstName,
            //     lastName:
            //       niprNpnResponse?.data?.producer?.entityBiographic?.biographic
            //         ?.lastName,
            //   });
            //   setDownlineDetails((prev) => ({
            //     ...prev,
            //     name: name,
            //   }));
            //   if (downlineLookup === 'ssn-lastName') {
            //     inviteDownlineForm.setFieldValue(
            //       'lastName',
            //       niprNpnResponse?.data?.producer?.entityBiographic?.biographic
            //         ?.lastName
            //     );
            //   } else {
            //     const name =
            //       niprNpnResponse?.data?.producer?.entityBiographic?.biographic
            //         ?.firstName +
            //       ' ' +
            //       niprNpnResponse?.data?.producer?.entityBiographic?.biographic
            //         ?.lastName;
            //     inviteDownlineForm.setFieldValue('name', name);
            //   }
            // }

            // const licenses =
            //   niprNpnResponse?.data?.producer?.producerLicensing
            //     ?.licenseInformation;
            // const homeStateLicenses = licenses.find((stateItr: any) => {
            //   return stateItr.licenses.every((licenseItr: any) => {
            //     return licenseItr.residencyStatus === 'R';
            //   });
            // });
            // const homeState = homeStateLicenses?.state;
            if (adminStore.account) {
              const sendInviteOb: SendInviteObject = {
                ...sendInviteObject,
                inviterDetails: {
                  agencyId: adminStore?.agency?.id,
                  adminId: adminStore?.account?.id,
                },
                inviteeDetails: {
                  email: values.email,
                  npn: values?.npn,
                  state: values?.state,
                  licenseNumber: values?.license,
                },
                inviteType: 'downline',
                agencyType: 'agency',
                // Uncomment if needed
                // ssn: values?.ssn,
                // lastName: values?.lastname,

                // Uncomment if needed
                // isInviteeRegistered: true,
                // homeState: homeState,
                invitedUsing: downlineLookup,
                invitedOn: new Date().toISOString(),
              };
              setSendInviteObject(sendInviteOb);
              handleSendInvitation(sendInviteOb);
            }

            // if (!agentBlacklisted) setModalVisibility(true);
            setLoading(false);
          }
        } catch (error: any) {
          setLoading(false);
          console.error(error);
        }
      }
    } catch (error: any) {
      console.error('handleVerifyDownline Error :: ', error);
      setLoading(false);
      if (isErrorNpnNotFound(error))
        onError(ErrorMessageConstants.NPN_NOT_FOUND);
      else if (isErrorDuplicateEmail(error))
        onError(ErrorMessageConstants.DUPLICATE_EMAIL);
      else
        onError(
          ErrorMessageConstants.CUSTOM_ERROR(
            'Downline Invite failed. Please try again.'
          )
        );
    } finally {
      setLoading(false);
    }
  };

  const handleSendInvitation = async (sendInvite?: SendInviteObject) => {
    setInviteLoading(true);
    try {
      // if (bearerToken && fetchDetailsSuccess) {
      if (bearerToken) {
        let isFormValid = false;
        if (validateForm) {
          try {
            await validateForm();
            isFormValid = true;
          } catch (e) {}
        }
        const inviteData = sendInvite ? sendInvite : sendInviteObject;
        if (isFormValid && inviteData) {
          const downlineInviteResp =
            await DownlineInviteService.postDownlineInvite(
              inviteData,
              bearerToken
            ).catch((error) => {
              setModalVisibility(false);
              throw error;
            });
          setFetchDetailsSuccess(true);
          setInviteLoading(false);
          setModalVisibility(false);
          downlineInviteResp && setFetchDetailsSuccess(true);
          handleSendInviteComplete();
        }
      }
    } catch (error: any) {
      console.error('handleSendInvitation Error :: ', error);

      setInviteLoading(false);
      setModalVisibility(false);
      onError(
        ErrorMessageConstants.CUSTOM_ERROR(error.response?.data?.message)
      );
    }
  };

  const dropdownChange = (value: string) => {
    switch (value) {
      case 'npn':
        form.setFieldsValue({ verification: 'npn' });
        setDownlineLookup(value);
        break;
      case 'ssn-lastName':
        form.setFieldsValue({ verification: 'ssn-lastName' });
        setDownlineLookup(value);
        break;
      case 'licenseNumber-state':
        form.setFieldsValue({ verification: 'licenseNumber-state' });
        setDownlineLookup(value);
        break;
      default:
        form.setFieldsValue({ verification: 'npn' });
        setDownlineLookup(value);
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Card
        style={{
          marginTop: '15px',
          marginBottom: '20px',
          width: '100%',
        }}
      >
        <Typography.Paragraph
          style={{
            fontSize: '20px',
            fontWeight: 500,
            color: 'var(--secondary-color)',
          }}
        >
          Verify Downline before you send an invite
        </Typography.Paragraph>
        <Form
          layout="vertical"
          onFinish={handleVerifyDownline}
          disabled={loading}
          autoComplete="false"
          form={inviteDownlineForm}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Form.Item
              label="Verify Downline using"
              name="verification"
              style={{ width: '50%' }}
              initialValue="npn"
            >
              <div
                id={IdConstants.AGENTS.INVITE_DOWNLINE.DROP_DOWN + 'Wrapper'}
              >
                <Select
                  id={IdConstants.AGENTS.INVITE_DOWNLINE.DROP_DOWN}
                  placeholder="Select an option"
                  onChange={dropdownChange}
                  defaultValue={'npn'}
                >
                  <Select.Option value="npn">NPN Number</Select.Option>
                  {/* <Select.Option value="ssn-lastName">
                    Social Security Number
                  </Select.Option> */}
                  {/* <Select.Option value="fein">FEIN Number</Select.Option> */}
                  <Select.Option value="licenseNumber-state">
                    License Number
                  </Select.Option>
                </Select>
              </div>
            </Form.Item>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.verification !== currentValues.verification
              }
            >
              {({ getFieldValue }) =>
                downlineLookup === 'npn' ? (
                  <Form.Item
                    style={{ width: '50%' }}
                    name="npn"
                    label="NPN Number"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a valid NPN Number',
                        pattern: NPN_REGEX,
                      },
                    ]}
                  >
                    <Input
                      id={IdConstants.AGENTS.INVITE_DOWNLINE.NPN_NUMBER}
                      placeholder="Enter a National Producer Number"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        const pastedText =
                          event.clipboardData.getData('text/plain');
                        if (!/^[0-9]+$/.test(pastedText)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                ) : downlineLookup === 'ssn-lastName' ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '50%',
                      // marginRight: '20px',
                    }}
                  >
                    <Form.Item
                      style={{ width: '50%', marginRight: '5%' }}
                      name="ssn"
                      label="Social Security Number"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a valid SSN Number',
                          pattern: SSN_REGEX,
                        },
                      ]}
                    >
                      <Input
                        id={
                          IdConstants.AGENTS.INVITE_DOWNLINE
                            .SOCIAL_SECURITY_NUMBER
                        }
                        maxLength={9}
                        placeholder="Enter a Social Security Number"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          const pastedText =
                            event.clipboardData.getData('text/plain');
                          if (!/^[0-9]+$/.test(pastedText)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '50%' }}
                      name="lastname"
                      label="Last Name"
                      rules={[{ required: true }]}
                    >
                      <Input
                        id={IdConstants.AGENTS.INVITE_DOWNLINE.LAST_NAME_INPUT}
                        placeholder="Enter a Last Name"
                        onChange={(e) => {
                          const value = e.target.value;
                          const cleanedValue = value.replace(
                            /[^A-Za-z\s']/g,
                            ''
                          );
                          const capitalizedValue =
                            cleanedValue.charAt(0).toUpperCase() +
                            cleanedValue.slice(1);
                          form.setFieldsValue({ lastname: capitalizedValue });
                        }}
                        onKeyPress={(event) => {
                          const regex = /^[A-Za-z\s']+$/;
                          const char = String.fromCharCode(
                            event.which || event.keyCode
                          );
                          if (!regex.test(char)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          const pastedText =
                            event.clipboardData.getData('text/plain');
                          const regex = /^[A-Za-z\s']+$/;
                          if (!regex.test(pastedText)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                ) : downlineLookup === 'licenseNumber-state' ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '50%',
                      // marginRight: '20px',
                    }}
                  >
                    <Form.Item
                      style={{ width: '50%', marginRight: '5%' }}
                      name="license"
                      label="License Number"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a valid License Number',
                          pattern: /^[a-zA-Z0-9-_]*$/,
                        },
                      ]}
                    >
                      <Input
                        id={
                          IdConstants.AGENTS.INVITE_DOWNLINE
                            .LICENSES_NUMBER_INPUT
                        }
                        placeholder="Enter a License Number"
                        onKeyPress={(event) => {
                          if (!/[a-zA-Z0-9-_]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          const pastedText =
                            event.clipboardData.getData('text/plain');
                          if (!/^[a-zA-Z0-9-_]+$/.test(pastedText)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '50%' }}
                      name="state"
                      label="Select State"
                      rules={[
                        { required: true, message: 'Please select the State' },
                      ]}
                    >
                      <StateSelect
                        onChange={(value: string) => {
                          form.setFieldsValue({ state: value });
                        }}
                      />
                    </Form.Item>
                  </div>
                ) : null
              }
            </Form.Item>
            <Form.Item
              style={{ width: '48%' }}
              label="Email Address"
              name="email"
              rules={[
                { required: true },
                {
                  type: 'email',
                  message: 'Please enter a valid email address',
                },
              ]}
            >
              <Input
                id={IdConstants.AGENTS.INVITE_DOWNLINE.EMAIL_ADDRESS}
                onInput={(e: any) =>
                  (e.target.value = e.target.value.toLowerCase())
                }
                placeholder="Enter an email address"
              />
            </Form.Item>
          </div>
          <div style={{ textAlign: 'center' }}>
            {/* {!verified && ( */}
            <Form.Item>
              <Button
                id={IdConstants.AGENTS.INVITE_DOWNLINE.VERIFY_DOWNLINE_BUTTON}
                htmlType="submit"
                size="large"
                className="button primary-button"
                loading={loading}
                type="primary"
                style={{ width: '30%' }}
              >
                Verify Downline
              </Button>
            </Form.Item>
            {/* )} */}
          </div>
        </Form>
      </Card>

      {modalVisibility && (
        <InviteDownLineModal
          visible={modalVisibility}
          handleVisibility={setModalVisibility}
          onClose={onClose}
          downLineDetails={downlineDetails}
          inviteLoading={inviteLoading}
          handleSendInvitation={handleSendInvitation}
          downLineType={downlineType}
        />
      )}

      {sendInviteComplete && (
        <Modal centered closable={false} open={true} footer={null}>
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ marginBottom: '10px' }}>
              Downline Invite Sent Successfully
            </h2>
            <CheckCircleTwoTone style={{ fontSize: '36px' }} color="#00FF00" />
            <p>
              Your invitation for {downlineDetails.name} has been sent
              successfully.
            </p>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button onClick={handleNavigate}>OK</Button>
          </div>
        </Modal>
      )}
      <IsWatchlistedModal
        visible={watchlistModalVisible}
        setVisible={(visible: boolean) => {
          setFetchDetailsSuccess(true);
          setWatchlistModalVisible(false);
          setModalVisibility(true);
          return watchlistModalVisible;
        }}
        onClose={() => {
          setWatchlistModalVisible(false);
        }}
        agents={watchlistedAgent ? [watchlistedAgent] : []}
        type="individual"
      />
      {tourStore.inviteDownlineDemoTour &&
        tourStore.inviteDownlineDemoTourVisible && <InviteDownlineTour />}
    </div>
  );
}

export default withRoles(observer(InviteDownline), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
