export class PlausiblePageConstants {
  public static readonly AGENCY_WATCHLIST = 'Watchlist';
  public static readonly AGENCY_DOWNLINES = 'Downlines';
  public static readonly AGENCY_VIEW_INVITATION = 'View invitations';
  public static readonly AGENCY_NEEDS_ATTENTION = 'Needs Attention';
  public static readonly AGENCY_PENDING_APPLICATION = 'Pending Application';
  public static readonly AGENCY_RENEWALS = 'Renewals';
  public static readonly AGENCY_ALL_LICENSES_AGENTS = 'All Licenses - Agents';
  public static readonly AGENCY_ALL_LICENSES_AGENCY = 'All Licenses - Agency';
  public static readonly AGENCY_SETTINGS_USERS = 'Settings - Users';
  public static readonly AGENCY_UPLINES = 'Uplines';
  public static readonly AGENCY_OVERVIEW = 'Overview';
  public static readonly AGENCY_DISTRIBUTION_CHANNEL = 'Distribution Channel';
  public static readonly AGENCY_ASSIGNMENTS = 'Assignments';
  public static readonly AGENCY_RTS_AGENT = 'Ready to sell - by Agent';
  public static readonly AGENCY_LICENSING_TRANSACTIONS =
    'Licensing Transactions';
  public static readonly UNKNOWN = 'Unknown';
}
