import { CsvExportFields } from '../../constants/csv-export.constants';
import { flatten } from 'flat';
import { isEmpty } from 'lodash';
import moment from 'moment';

export const exportTableToCSV = (
  usecase: 'agencyRTS',
  data: any,
  requestedFileName?: string
) => {
  var csvData = '';
  let currentDate = moment();

  let fileName =
    requestedFileName ||
    'Insuretrek report' + ' - ' + moment(currentDate).format('MMM DD, YYYY');
  if (usecase === 'agencyRTS') csvData = getCsvDataForAgencyRTS(data);

  var uri = 'data:text/csv;charset=utf-8,' + escape(csvData);

  var link = document.createElement('a');
  link.href = uri;
  link.setAttribute('style', 'visibility:hidden');
  link.download = fileName + '.csv';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getCsvDataForAgencyRTS = (data: any[]): string => {
  var csvData = '';
  var headerRow = Object.keys(CsvExportFields.AGENCY_RTS_REPORT).join(',');
  csvData += headerRow + '\r\n';

  for (let i = 0; i < data.length; i++) {
    var row = '';
    const flattenValue: any = flatten(data[i]);
    Object.values(CsvExportFields.AGENCY_RTS_REPORT).forEach((value) => {
      row +=
        (isEmpty(flattenValue[value])
          ? ''
          : (flattenValue[value] as string).replaceAll(',', ';')) + ',';
    });
    row.slice(0, row.length - 1);

    csvData += row + '\r\n';
  }
  return csvData;
};
