import { Checkbox, Tag, Tooltip, Typography } from 'antd';

import { ApplicantType } from '../../../../enums/applicantType.enum';
import AssignmentLoa from './assignmentLoa';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LicenseClassProps } from './individualOnboardV2Type.type';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { agencyProfileStore } from '../../../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { getDisplayFees } from '../../../../utils/common.utils';
import { observer } from 'mobx-react-lite';

const { Text } = Typography;

const AssignmentLicenseClass: React.FC<LicenseClassProps> = ({
  text,
  licenseClassCode,
  isCheckboxNeeded = true,
  selectedIds,
  LoaDetails,
  handleCheckboxChange,
  stateCode,
  disableCheckBox = false,
  isLicenseChecked,
  activeLicense,
}) => {
  const handleClassCheckboxChange = (e: CheckboxChangeEvent) => {
    handleCheckboxChange(licenseClassCode, text, null, e.target.checked);
  };

  const getFees = () => {
    if (!isLicenseChecked) return null;

    const agencyHomeState = agencyProfileStore?.agencyDetails?.homeState;

    const loaCount = selectedIds?.length || 0;

    return (
      appStateInfoStore.calculateFeesForAgencyLicense(
        stateCode,
        loaCount,
        agencyHomeState,
        ApplicantType.INDIVIDUAL
      ) || null
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        borderRadius: '6px',
        padding: '16px',
        gap: '15px',
        backgroundColor: '#F1F8FE',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginTop: '20px',
      }}
    >
      <div
        className="override-checkbox-bg"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {isCheckboxNeeded && (
          <Checkbox
            checked={isLicenseChecked}
            style={{ marginRight: '20px' }}
            onChange={handleClassCheckboxChange}
            disabled={disableCheckBox}
          />
        )}
        <Text
          style={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            color: 'rgba(34, 34, 34, 1)',
            margin: 0,
          }}
        >
          {text}
        </Text>
        {activeLicense && (
          <Tag
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '20px',
              color: 'rgba(34, 34, 34, 1)',
              marginLeft: 10,
              backgroundColor: '#F1FEF4',
              borderColor: '#F1FEF4',
            }}
          >
            Active
          </Tag>
        )}
        <div
          className="override-icon-size"
          style={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            color: '#00A6FB',
            margin: 0,
            marginLeft: '12px',
          }}
        >
          {getFees()?.fees ? (
            <div>
              ( Estimated fees:{' '}
              <span style={{ fontWeight: 500 }}>
                ${getDisplayFees(getFees()?.fees)}
              </span>{' '}
              )
            </div>
          ) : null}{' '}
          {getFees()?.isRetaliatory ? (
            <Tooltip
              title={
                <div>
                  We are currently building fee calculations for retaliatory
                  states. For more information, please refer{' '}
                  <a target="__blank" href={getFees()?.retaliatoryLink}>
                    {getFees()?.retaliatoryLink}
                  </a>
                </div>
              }
            >
              <QuestionCircleOutlined
                style={{
                  cursor: 'pointer',
                  color: '#97AABF',
                }}
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '15px',
          marginLeft: isCheckboxNeeded ? '40px' : '0',
          flexWrap: 'wrap',
        }}
      >
        {LoaDetails?.map((loa, index) => {
          let activeLoa = false;
          if (activeLicense) {
            const foundLoa = activeLicense.loaDetails.some(
              (loaD) => loaD.loaCode === loa.loaCode
            );
            activeLoa = foundLoa;
          }
          return (
            <AssignmentLoa
              key={index}
              disableCheckBox={disableCheckBox}
              text={(loa.loa || loa.loaName) + ' (' + loa.loaCode + ')'}
              loaId={loa.loaCode || ''}
              loaDbId={loa.id || ''}
              licenseClassCode={licenseClassCode}
              selectedIds={selectedIds}
              handleCheckboxChange={handleCheckboxChange}
              isCheckboxNeeded={isCheckboxNeeded}
              activeLoa={activeLoa}
            />
          );
        })}
      </div>
    </div>
  );
};

export default observer(AssignmentLicenseClass);
