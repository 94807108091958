import { Checkbox, Input, Typography } from 'antd';
import {
  FilterOptions,
  FiltersType,
  LabelPairType,
} from '../../../../types/common/filters.type';
import React, { useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { normalizeSpaces } from '../../../../utils/filters/normalize-search-space.utils';

type CheckboxProps = {
  keyData: string;
  options: FilterOptions[];
  label: string;
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  search?: boolean;
};

function CheckBoxFilter(props: CheckboxProps) {
  const { options, label, filters, setFilters, keyData, search } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredOptions, setFilteredOptions] =
    useState<FilterOptions[]>(options);

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, options]);

  const handleCheckboxChange = (checkedValue: string, checkedLabel: string) => {
    const currentFilter = filters.data[label];
    const isValueAlreadyPresent = (
      (currentFilter?.labelValuePair as LabelPairType[]) || []
    ).some((item) => item.value === checkedValue);

    const updatedLabelValuePair = isValueAlreadyPresent
      ? ((currentFilter?.labelValuePair as LabelPairType[]) || []).filter(
          (item) => item.value !== checkedValue
        )
      : [
          ...((currentFilter?.labelValuePair as LabelPairType[]) || []),
          { value: checkedValue, label: checkedLabel },
        ];

    const updatedFilters: FiltersType = {
      data: {
        ...filters.data,
        [label]: {
          key: keyData,
          labelValuePair: updatedLabelValuePair,
        },
      },
    };

    if (!updatedLabelValuePair.length) delete updatedFilters['data'][label];

    setFilters(updatedFilters);
  };

  const handleSearch = (value: string) => {
    // Normalize the search value
    const lowercasedValue = normalizeSpaces(value.toLowerCase());

    // Filter options with normalized labels or values
    const filtered = options.filter((option) =>
      normalizeSpaces(
        (option.label || option.value).toString().toLowerCase()
      ).includes(lowercasedValue)
    );

    // Update the filtered options
    setFilteredOptions(filtered);
  };

  const renderCheckboxes = () =>
    filteredOptions
      .sort((a, b) => {
        const labelA = a.label || a.value;
        const labelB = b.label || b.value;
        return labelA.localeCompare(labelB);
      })
      .map((option, index) => (
        <Checkbox
          key={index}
          style={{
            fontSize: search ? '13px' : '14px',
            fontFamily: search ? 'Poppins' : undefined,
          }}
          className="filter-checkbox"
          checked={
            (filters.data[label]?.labelValuePair as LabelPairType[])?.some(
              (itr) => itr.value === option.value
            ) || false
          }
          onChange={() =>
            handleCheckboxChange(option.value, option.label || option.value)
          }
        >
          {option.label || option.value}
        </Checkbox>
      ));

  return (
    <div>
      <Typography.Paragraph
        style={{
          fontSize: '14px',
          color: 'var(--secondary-color)',
          fontWeight: 500,
        }}
      >
        {label}
      </Typography.Paragraph>

      {search && (
        <div
          style={{
            border: '1px solid var(--border-color)',
            padding: '5px',
            borderRadius: '5px',
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined style={{ color: '#92A69E' }} />}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{
              color: '#92A69E',
              width: '99%',
              borderColor: 'var(--border-color)',
            }}
          />
          <div
            className="custom-scrollbar"
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '2%',
              overflow: 'auto',
              maxHeight: '120px',
              marginTop: '5px',
            }}
          >
            {renderCheckboxes()}
          </div>
        </div>
      )}
      {!search && <div>{renderCheckboxes()}</div>}
    </div>
  );
}

export default CheckBoxFilter;
