import { Avatar, Card, Tooltip } from 'antd';
import { LcDragItem, LoaDragItem } from '../editStateGroup';
import React, { useEffect } from 'react';

import { DragItemTypes } from '../../../../types/dnd/dragItem.types';
import DraggableLoaCard from './draggableLoaCard';
import IconDraggable from '../../../../assets/icons/iconDraggable.svg';
import { IdConstants } from '../../../../constants/id.constants';
import { LcDetails } from '../../../../types/data/masterData';
import { ResidencyType } from '../../../../enums/residencyType.enum';
import Title from 'antd/es/typography/Title';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { getResidentToolTip } from '../../../../utils/table-manager/residentType.utils';
import { useDrag } from 'react-dnd';

interface DraggableLcCardProps {
  lcDetails: LcDetails;
  lcIndex: number;
  setCurrentLoaDragItem: (props: LoaDragItem) => void;
  setCurrentLcDragItem: (props: LcDragItem) => void;
  stateCode: string;
}
function DraggableLcCard({
  stateCode,
  lcDetails,
  lcIndex,
  setCurrentLoaDragItem,
  setCurrentLcDragItem,
}: DraggableLcCardProps) {
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: DragItemTypes.LC,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    []
  );

  useEffect(() => {
    isDragging &&
      setCurrentLcDragItem({
        ...{
          stateCode,
          lcCode: lcDetails.lcCode,
          loaCodes: lcDetails.loaData.map((itr) => itr.loaCode || ''),
          loaids: lcDetails.loaData.map((itr) => itr.id || ''),
        },
      });
  }, [isDragging]);

  return (
    <Card
      id={
        IdConstants.STATE_GROUPS.DRAGGABLE_LC_CARD +
        '-' +
        lcDetails.lcCode +
        '-state-groups'
      }
      style={{
        ...(lcIndex === 0 ? {} : { marginTop: '10px' }),
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
      bodyStyle={{ padding: '10px 0px 15px 10px' }}
      ref={dragRef}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '12px',
        }}
      >
        <Title level={5} style={{ marginTop: '0' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            {lcDetails.licenseClass} -{' '}
            {lcDetails.residencyType &&
            lcDetails.residencyType !== ResidencyType.NotApplicable ? (
              <Tooltip title={getResidentToolTip(lcDetails.residencyType)}>
                <Avatar
                  style={{
                    backgroundColor: '#D3F3F5',
                    color: '#000000',
                    width: '26px',
                    height: '26px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    justifyItems: 'center',
                    marginRight: '4px',
                    cursor: 'default',
                  }}
                >
                  {lcDetails.residencyType || <span>-</span>}
                </Avatar>
              </Tooltip>
            ) : lcDetails.residencyType ? (
              <>
                <Tooltip title={getResidentToolTip(ResidencyType.Resident)}>
                  <Avatar
                    style={{
                      backgroundColor: '#D3F3F5',
                      color: '#000000',
                      width: '26px',
                      height: '26px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      justifyItems: 'center',
                      marginRight: '0',
                      cursor: 'default',
                    }}
                  >
                    R
                  </Avatar>
                </Tooltip>
                <Tooltip title={getResidentToolTip(ResidencyType.NonResident)}>
                  <Avatar
                    style={{
                      backgroundColor: '#D3F3F5',
                      color: '#000000',
                      width: '26px',
                      height: '26px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      justifyItems: 'center',
                      marginLeft: '0',
                      cursor: 'default',
                    }}
                  >
                    NR
                  </Avatar>
                </Tooltip>
              </>
            ) : null}
          </div>
        </Title>
        <img src={IconDraggable} alt="draggable-icon" />
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          rowGap: '4px',
        }}
      >
        {lcDetails.loaData.map((loaItr, index) => {
          return (
            <DraggableLoaCard
              key={index}
              loaDetails={loaItr}
              stateCode={stateCode}
              lcCode={lcDetails.lcCode}
              setCurrentLoaDragItem={setCurrentLoaDragItem}
            />
          );
        })}
      </div>
    </Card>
  );
}

export default DraggableLcCard;
