import { Button, Card, Input, Table, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { AgentService } from '../../../../services/agent.service';
import { ColumnType } from 'antd/lib/table';
import { LicensesService } from '../../../../services/licenses.service';
import OffboardConfirmModal from '../individualOffboard/offboardConfirmationModal';
import { OffboardLookupType } from '../../../../enums/lookupType.enum';
import { adminStore } from '../../../../stores/admin.store';
import { useAuth } from '../../../../auth/authProvider';

interface EditableColumnType<T> extends ColumnType<T> {
  editTable?: boolean;
}

type CompleteOffboardProps = {
  lookupType: OffboardLookupType;
  agents: any;
  setStartedOffboard: Dispatch<SetStateAction<boolean>>;
  setError: () => void;
};

function CompleteOffboard(props: CompleteOffboardProps) {
  const { getAccessTokenSilently } = useAuth();
  const [agents, setAgents] = useState(props.agents);
  const [changedRows, setChangedRows] = useState<string[]>([]);
  const [editingRows, setEditingRows] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [prevData, setPrevData] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [recordsVerified, setRecordsVerified] = useState(false);
  const agencyId = adminStore.agency?.id;
  const [verifiedNameNPNs, setVerifiedNameNPNs] = useState<any>([]);
  const [offboardLookupType, setOffboardLookupType] = useState('');
  const [licenseStateVerified, setLicenseStateVerified] = useState<any>([]);
  const [agentIds, setAgentIds] = useState<string[]>([]);
  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    if (!agents.length) {
      window.location.reload();
    }
  }, [agents]);

  async function verifyOffboardNPN(npn: any) {
    const token = await getAccessTokenSilently();
    if (agencyId && token) {
      const response: any = await AgentService.findManyByNPN(
        null,
        null,
        [npn],
        token
      ).catch((error) => {
        console.error(error);
      });
      try {
        if (response && response.data.totalCount > 0) {
          const newAgentsData = response.data.data.map((item: any) => ({
            name: item.name.firstName + ' ' + item.name.lastName,
            npn: item.npn,
            _id: item.id,
          }));
          const uniqueNewAgentsData = newAgentsData.filter(
            (newDataItem: any) => {
              return !verifiedNameNPNs.some(
                (existingItem: any) => existingItem.npn === newDataItem.npn
              );
            }
          );
          if (!(uniqueNewAgentsData.length === 0)) {
            setVerifiedNameNPNs((prevVerifiedNameNPNs: any) => [
              ...prevVerifiedNameNPNs,
              ...uniqueNewAgentsData,
            ]);
          }
          const updatedAgents = agents.map((agent: any) => {
            const verifiedAgent = verifiedNameNPNs.find(
              (value: any) => value.npn === agent.NPN
            );
            const agentName = verifiedAgent ? verifiedAgent.name : agent.Name;
            return {
              ...agent,
              Name: agentName,
            };
          });
          setAgents(updatedAgents);
        }
      } catch (error) {
        console.error('verifyOffboardNPN :: Error', error);
      }
    }
  }

  async function verifyOffboardLicenseState(
    licenseNumber: string,
    state: string
  ) {
    const token = await getAccessTokenSilently();
    if (agencyId && token) {
      let licenseStateObject = [{ licenseNumber: licenseNumber, state: state }];
      const response: any = await LicensesService.findManyByLicenseStateArray(
        licenseStateObject,
        token
      ).catch((error) => {
        console.error(error);
      });
      if (response && response.data.totalCount > 0) {
        console.error('No matching record found in the InsureTrek directory');
      } else if (response) {
        const newAgentsData = response.data
          .map((item: any) => {
            const matchingLicense = licenseStateObject.some(
              (stateItem: any) =>
                stateItem.licenseNumber === item.licenseNumber &&
                stateItem.state === item.state
            );
            if (matchingLicense) {
              return {
                name:
                  item.agent.name.firstName + ' ' + item.agent.name.lastName,
                licenseNumber: item.licenseNumber,
                state: item.state,
                npn: item.agent.npn,
                _id: item.agent.id,
              };
            }
            return null;
          })
          .filter(Boolean);
        const uniqueNewAgentsData = newAgentsData.filter((newDataItem: any) => {
          return !licenseStateVerified.some(
            (existingItem: any) =>
              existingItem.licenseNumber === newDataItem.licenseNumber &&
              existingItem.state === newDataItem.state
          );
        });
        if (!(uniqueNewAgentsData.length === 0)) {
          setLicenseStateVerified((prevLicenseStateVerified: any) => [
            ...prevLicenseStateVerified,
            ...uniqueNewAgentsData,
          ]);
        }
        const updatedAgents = agents.map((agent: any) => {
          const verifiedAgent = licenseStateVerified.find(
            (value: any) =>
              value.licenseNumber === agent['License Number'] &&
              value.state === agent.State
          );
          const agentName = verifiedAgent ? verifiedAgent.name : agent.Name;
          return {
            ...agent,
            Name: agentName,
          };
        });
        const npnMapping: any = {};
        licenseStateVerified.forEach((item: any) => {
          npnMapping[`${item.licenseNumber}-${item.state}`] = item.npn;
        });

        const updatedAgentsWithNPN = updatedAgents.map((agent: any) => {
          const npnKey = `${agent['License Number']}-${agent.State}`;
          const npn = npnMapping[npnKey] || '';
          return {
            ...agent,
            npn: npn,
          };
        });
        setAgents(updatedAgentsWithNPN);
      }
    }
  }

  async function verifyOffBoard(
    lookupType: string,
    offBoardJsonData: any,
    agencyId: any
  ) {
    const token = await getAccessTokenSilently();
    setOffboardLookupType(lookupType);
    if (agencyId && token) {
      if (lookupType === 'Name and NPN Number') {
        const npnArray = offBoardJsonData
          .filter((item: any) => item.NPN && item.NPN.trim() !== '')
          .map((item: any) => item.NPN);
        const response: any = await AgentService.findManyByNPN(
          null,
          null,
          npnArray,
          token
        ).catch((error) => {
          console.error(error);
        });
        if (response.data && response.data.data) {
          const newAgentsData = response.data.data.map((item: any) => ({
            name: item.name.firstName + ' ' + item.name.lastName,
            npn: item.npn,
            _id: item.id,
          }));

          const uniqueNewAgentsData = newAgentsData.filter(
            (newDataItem: any) => {
              return !verifiedNameNPNs.some(
                (existingItem: any) => existingItem.npn === newDataItem.npn
              );
            }
          );
          if (!(uniqueNewAgentsData.length === 0)) {
            setVerifiedNameNPNs((prevVerifiedNameNPNs: any) => [
              ...prevVerifiedNameNPNs,
              ...uniqueNewAgentsData,
            ]);
          }
        }
      }
      if (lookupType === 'Individual SSN and Last Name') {
        const ssnLastNameArray = offBoardJsonData
          .filter(
            (item: any) =>
              item.SSN &&
              item.SSN.trim() !== '' &&
              item.Last_Name &&
              item.Last_Name.trim() !== ''
          )
          .map((item: any) => item.SSN);
        await AgentService.findManyBySSNLastName(ssnLastNameArray, token).catch(
          (error) => {
            console.error(error);
          }
        );
      }
      if (lookupType === 'Name, License Number and State') {
        const licenseStateArray = offBoardJsonData
          .filter(
            (item: any) =>
              item['License Number'] &&
              item['License Number'].trim() !== '' &&
              item.State &&
              item.State.trim() !== ''
          )
          .map((item: any) => ({
            licenseNumber: item['License Number'],
            state: item.State,
          }));
        const response: any = await LicensesService.findManyByLicenseStateArray(
          licenseStateArray,
          token
        ).catch((error) => {
          console.error(error);
        });
        if (response === undefined) {
          console.error('No matching record found in the InsureTrek directory');
        } else if (response) {
          const newAgentsData = response.data
            .map((item: any) => {
              const matchingLicense = licenseStateArray.some(
                (stateItem: any) =>
                  stateItem.licenseNumber === item.licenseNumber &&
                  stateItem.state === item.state
              );
              if (matchingLicense) {
                return {
                  name:
                    item.agent.name.firstName + ' ' + item.agent.name.lastName,
                  licenseNumber: item.licenseNumber,
                  state: item.state,
                  npn: item.agent.npn,
                  _id: item.agent.id,
                };
              }
              return null;
            })
            .filter(Boolean);
          const uniqueNewAgentsData = newAgentsData.filter(
            (newDataItem: any) => {
              return !licenseStateVerified.some(
                (existingItem: any) =>
                  existingItem.licenseNumber === newDataItem.licenseNumber &&
                  existingItem.state === newDataItem.state
              );
            }
          );
          if (!(uniqueNewAgentsData.length === 0)) {
            setLicenseStateVerified((prevLicenseStateVerified: any) => [
              ...prevLicenseStateVerified,
              ...uniqueNewAgentsData,
            ]);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (offboardLookupType === 'Name and NPN Number') {
      const updatedAgents = agents.map((agent: any) => {
        const verifiedAgent = verifiedNameNPNs.find(
          (value: any) => value.npn === agent.NPN
        );
        const agentName = verifiedAgent ? verifiedAgent.name : agent.Name;
        const agentId = verifiedAgent ? verifiedAgent._id : undefined;
        return {
          ...agent,
          Name: agentName,
          _id: agentId,
        };
      });
      const npnMapping: any = {};
      verifiedNameNPNs.forEach((item: any) => {
        npnMapping[`${item.npn}`] = item.npn;
      });

      const updatedAgentsWithNPN = updatedAgents.map((agent: any) => {
        const npnKey = `${agent['NPN']}`;
        const npn = npnMapping[npnKey] || '';
        return {
          ...agent,
          npn: npn,
        };
      });
      setAgents(updatedAgentsWithNPN);
    }

    if (offboardLookupType === 'Name, License Number and State') {
      const updatedAgents = agents.map((agent: any) => {
        const verifiedAgent = licenseStateVerified.find(
          (value: any) =>
            value.licenseNumber === agent['License Number'] &&
            value.state === agent['State']
        );
        const agentName = verifiedAgent ? verifiedAgent.name : agent['Name'];
        const agentId = verifiedAgent ? verifiedAgent._id : undefined;
        return {
          ...agent,
          Name: agentName,
          _id: agentId,
        };
      });
      const npnMapping: any = {};
      licenseStateVerified.forEach((item: any) => {
        npnMapping[`${item.licenseNumber}-${item.state}`] = item.npn;
      });

      const updatedAgentsWithNPN = updatedAgents.map((agent: any) => {
        const npnKey = `${agent['License Number']}-${agent.State}`;
        const npn = npnMapping[npnKey] || '';
        return {
          ...agent,
          npn: npn,
        };
      });
      setAgents(updatedAgentsWithNPN);
    }
  }, [verifiedNameNPNs, licenseStateVerified]);

  useEffect(() => {
    verifyOffBoard(props.lookupType, agents, agencyId);
    if (
      offboardLookupType === 'Name and NPN Number' &&
      agents.every((agent: any) =>
        verifiedNameNPNs.some(
          (verifiedAgent: any) => verifiedAgent.npn === agent.NPN
        )
      )
    ) {
      setRecordsVerified(true);
    } else if (offboardLookupType === 'Individual SSN and Last Name') {
      setRecordsVerified(true);
    } else if (
      offboardLookupType === 'Name, License Number and State' &&
      agents.every((agent: any) =>
        licenseStateVerified.some(
          (verifiedAgent: any) =>
            verifiedAgent.licenseNumber === agent['License Number'] &&
            verifiedAgent.state === agent.State
        )
      )
    ) {
      setRecordsVerified(true);
    } else {
      setRecordsVerified(false);
    }
  }, [selectedRows, verifiedNameNPNs, licenseStateVerified]);

  const rowKey = (row: any) => row.id || JSON.stringify(row);

  const NPNcolumns = [
    {
      title: 'Producer',
      dataIndex: 'Name',
      key: 'Name',
      editTable: true,
    },
    {
      title: 'NPN Number',
      dataIndex: 'NPN',
      key: 'NPN',
      editTable: true,
    },
    {
      title: 'Status',
      key: 'status',
      render: (text: any, record: any, index: number) => {
        const isEmptyAgentName = !record['Name'];
        const isEmptyNPNNumber = !record['NPN'];

        let statusMessage = '';
        let statusColor = '';

        if (isEmptyAgentName || isEmptyNPNNumber) {
          statusMessage = 'Empty Column';
          statusColor = '#F5222D';
        } else {
          if (
            verifiedNameNPNs.some((item: any) => item.npn === record['NPN'])
          ) {
            statusColor = '#52C41A';
            statusMessage = 'Verified';
          } else {
            statusColor = '#F5222D';
            statusMessage = 'NPN Error: No Match Found';
          }
        }

        if (!editingRows[index]) {
          return statusMessage === 'Verified' ? (
            <span style={{ color: statusColor }}>
              <CheckCircleOutlined style={{ marginRight: '4px' }} />
              {statusMessage}{' '}
            </span>
          ) : (
            <span style={{ color: statusColor }}>
              <CloseCircleOutlined style={{ marginRight: '4px' }} />
              {statusMessage}
            </span>
          );
        }

        if (record['Status'] !== 'Verified') {
          setRecordsVerified(false);
        } else {
          setRecordsVerified(true);
        }
      },
    },
    {
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any, index: number) => {
        if (editingRows[record.id]) {
          const isRowChanged = changedRows.includes(record.id);

          return (
            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}
            >
              <Button
                disabled={!isRowChanged}
                onClick={() => {
                  handleEdit(record.id);
                  verifyOffboardNPN(record['NPN']);
                }}
              >
                Save
              </Button>
              <Button onClick={() => handleCancel(record.id)}>Cancel</Button>
            </div>
          );
        }

        return <Button onClick={() => handleEdit(record.id)}>Edit</Button>;
      },
    },
  ];

  const SSNcolumns = [
    {
      title: 'SSN',
      dataIndex: 'SSN',
      key: 'SSN',
      editTable: true,
    },
    {
      title: 'Producer',
      dataIndex: 'Name',
      key: 'Name',
      editTable: true,
    },
    {
      title: 'NPN Number',
      dataIndex: 'NPN',
      key: 'NPN',
      editTable: true,
    },
    {
      title: 'Status',
      key: 'status',
      render: (text: any, record: any, index: number) => {
        const isEmptySSN = !record['SSN'];
        const isEmptyAgentName = !record['Name'];
        const isEmptyNPNNumber = !record['NPN'];

        let statusMessage = '';
        let statusColor = '';

        if (isEmptyAgentName || isEmptyNPNNumber || isEmptySSN) {
          statusMessage = 'Empty Column';
          statusColor = '#F5222D';
        } else {
          statusColor = '#52C41A';
          statusMessage = 'Verified';
        }

        if (!editingRows[index]) {
          return statusMessage === 'Empty Column' ? (
            <span style={{ color: statusColor }}>
              <CloseCircleOutlined style={{ marginRight: '4px' }} />
              {statusMessage}
            </span>
          ) : (
            <span style={{ color: statusColor }}>
              <CheckCircleOutlined style={{ marginRight: '4px' }} />
              {statusMessage}
            </span>
          );
        }
      },
    },
    {
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any, index: number) => {
        if (editingRows[record.id]) {
          const isRowChanged = changedRows.includes(record.id);

          return (
            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}
            >
              <Button
                disabled={!isRowChanged}
                onClick={() => {
                  handleEdit(record.id);
                }}
              >
                Save
              </Button>
              <Button onClick={() => handleCancel(record.id)}>Cancel</Button>
            </div>
          );
        }

        return <Button onClick={() => handleEdit(record.id)}>Edit</Button>;
      },
    },
  ];

  const LicenseColumns = [
    {
      title: 'License Number',
      dataIndex: 'License Number',
      key: 'licenseNumber',
      editTable: true,
    },
    {
      title: 'State',
      dataIndex: 'State',
      key: 'state',
      editTable: true,
    },
    {
      title: 'Producer',
      dataIndex: 'Name',
      key: 'Name',
      editTable: true,
    },
    {
      title: 'NPN Number',
      dataIndex: 'npn',
      key: 'npn',
      editTable: false,
    },
    {
      title: 'Status',
      key: 'status',
      render: (text: any, record: any, index: number) => {
        const isEmptyAgentName = !record['Name'];
        const isEmptyLicenseNumber = !record['License Number'];

        let statusMessage = '';
        let statusColor = '';

        if (isEmptyAgentName || isEmptyLicenseNumber) {
          statusMessage = 'Empty Column';
          statusColor = '#F5222D';
        } else {
          if (
            licenseStateVerified.some(
              (item: any) =>
                item.licenseNumber === record['License Number'] &&
                item.state === record['State']
            )
          ) {
            statusColor = '#52C41A';
            statusMessage = 'Verified';
          } else {
            statusColor = '#F5222D';
            statusMessage = 'License Lookup Error: No Match Found';
          }
        }

        if (!editingRows[index]) {
          return statusMessage === 'Verified' ? (
            <span style={{ color: statusColor }}>
              <CheckCircleOutlined style={{ marginRight: '4px' }} />
              {statusMessage}{' '}
            </span>
          ) : (
            <span style={{ color: statusColor }}>
              <CloseCircleOutlined style={{ marginRight: '4px' }} />
              {statusMessage}
            </span>
          );
        }

        if (record['Status'] !== 'Verified') {
          setRecordsVerified(false);
        } else {
          setRecordsVerified(true);
        }
      },
    },
    {
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any, index: number) => {
        if (editingRows[record.id]) {
          const isRowChanged = changedRows.includes(record.id);

          return (
            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}
            >
              <Button
                disabled={!isRowChanged}
                onClick={() => {
                  handleEdit(record.id);
                  verifyOffboardLicenseState(
                    record['License Number'],
                    record['State']
                  );
                }}
              >
                Save
              </Button>
              <Button onClick={() => handleCancel(record.id)}>Cancel</Button>
            </div>
          );
        }

        return <Button onClick={() => handleEdit(record.id)}>Edit</Button>;
      },
    },
  ];

  const handleEdit = (id: string) => {
    setPrevData(agents);
    setEditingRows((prevEditingRows) => ({
      ...prevEditingRows,
      [id]: !prevEditingRows[id],
    }));
    if (
      agents.every((agent: any) =>
        verifiedNameNPNs.some(
          (verifiedAgent: any) => verifiedAgent.npn === agent.NPN
        )
      )
    ) {
      setRecordsVerified(true);
    } else {
      setRecordsVerified(false);
    }
  };

  const handleCancel = (id: string) => {
    setAgents(prevData);
    let allAgentsVerified;
    if (offboardLookupType === 'Name and NPN Number') {
      allAgentsVerified = agents.every((agent: any) =>
        verifiedNameNPNs.some(
          (verifiedAgent: any) => verifiedAgent.npn === agent.NPN
        )
      );
    } else if (offboardLookupType === 'Name, License Number and State') {
      allAgentsVerified = agents.every((agent: any) =>
        licenseStateVerified.some(
          (verifiedAgent: any) => verifiedAgent.npn === agent.NPN
        )
      );
    }
    if (allAgentsVerified) {
      setRecordsVerified(allAgentsVerified);
    }
    setEditingRows((prevEditingRows) => ({
      ...prevEditingRows,
      [id]: false,
    }));
  };

  const handleDelete = () => {
    const updatedAgents = agents.filter((agent: any) => {
      if (!selectedRows.map((itr: any) => itr['id']).includes(agent['id']))
        return agent;
    });
    setAgents(updatedAgents);
    setSelectedRows([]);
  };

  const handleInputChange = (value: any, record: any, dataIndex: string) => {
    const updatedRecord = {
      ...record,
      [dataIndex]: value,
    };
    const recordIndex = agents.findIndex((item: any) => item.id === record.id);
    const updates = [...agents];
    updates[recordIndex] = updatedRecord;

    // setUpdatedDataSource(updates);
    setAgents(updates);

    if (value !== record[dataIndex]) {
      if (!changedRows.includes(record.id)) {
        setChangedRows((prevChangedRows: any) => [
          ...prevChangedRows,
          record.id,
        ]);
      }
    } else {
      setChangedRows((prevChangedRows: any) =>
        prevChangedRows.filter((rowId: any) => rowId !== record.id)
      );
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      if (setSelectedRowKeys) {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
      }
    },
    selectedRowKeys: selectedRowKeys,
  };

  const expandedColumns = (columns: any) => {
    const newColumns = columns.map((column: any) => {
      if ((column as EditableColumnType<any>).editTable) {
        const editableColumn = column as EditableColumnType<any>;
        return {
          ...editableColumn,
          render: (text: string, record: any) => {
            if (editingRows[record.id]) {
              return (
                <Input
                  placeholder="Enter a value"
                  value={text}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(
                      e.target.value,
                      record,
                      String(editableColumn.dataIndex) || ''
                    )
                  }
                />
              );
            }
            return text;
          },
        };
      } else {
        return column;
      }
    });
    return newColumns;
  };

  const handleSetModalDetails = async () => {
    try {
      const transformedAgents = agents.map((agent: any) => {
        if (agent.Name) {
          const [firstName, lastName] = agent.Name.split(' ');
          return {
            ...agent,
            name: {
              firstName,
              lastName,
            },
          };
        } else {
          return agent;
        }
      });
      setAgents(transformedAgents);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleOffboardAgents = async () => {
    if (offboardLookupType === 'Name and NPN Number') {
      setAgentIds(
        verifiedNameNPNs.map((agents: any) => {
          return agents._id;
        })
      );
    } else if (offboardLookupType === 'Name, License Number and State') {
      setAgentIds(
        licenseStateVerified.map((agents: any) => {
          return agents._id;
        })
      );
    } else {
      return;
    }
  };

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Paragraph
          style={{
            fontSize: '16px',
            fontWeight: 500,
            marginTop: '10px',
            color: 'var(--secondary-color)',
          }}
        >
          Verify CSV
        </Typography.Paragraph>
        {selectedRows.length > 0 && (
          <div style={{ display: 'flex' }}>
            <Typography.Paragraph
              style={{
                fontSize: '12px',
                fontWeight: 400,
                marginTop: '10px',
                color: '#787878',
                marginRight: '10px',
              }}
            >
              Remove selected producers from the Offboard List
            </Typography.Paragraph>
            <Button
              onClick={handleDelete}
              size="large"
              style={{
                backgroundColor: '#F00',
                color: '#FFF',
              }}
            >
              Remove
            </Button>
          </div>
        )}
      </div>
      <div>
        <Table
          columns={
            props.lookupType === OffboardLookupType.NPN
              ? expandedColumns(NPNcolumns)
              : props.lookupType === OffboardLookupType.SSN
              ? expandedColumns(SSNcolumns)
              : expandedColumns(LicenseColumns)
          }
          rowSelection={rowSelection}
          dataSource={agents}
          rowKey={rowKey}
          pagination={false}
          scroll={{ y: '100%' }}
        />

        <Button
          size="large"
          className="button"
          style={{
            width: '100%',
            marginTop: '10px',
            opacity: recordsVerified ? '100%' : '50%',
          }}
          disabled={
            !recordsVerified ||
            selectedRows.length !== 0 ||
            selectedRows.length > 0
          }
          onClick={() => {
            handleSetModalDetails();
            setModalVisibility(true);
          }}
        >
          Producer Offboarding
        </Button>
      </div>
      {modalVisibility && (
        <OffboardConfirmModal
          handleVisibility={() => {
            setModalVisibility(false);
          }}
          visibility={modalVisibility}
          agentsToBeOffboarded={agents}
          setAgentsToBeOffboarded={handleOffboardAgents}
          reloadPage={() => {
            props.setStartedOffboard(false);
          }}
          setError={() => {
            props.setError();
          }}
        />
      )}
    </Card>
  );
}

export default CompleteOffboard;
