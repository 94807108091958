export enum TransStatus {
  AWAITING_STATE_VALIDATION = 'AV',
  UNDER_STATE_REVIEW = 'SR',
  HOLDING_TANK = 'HT', // awaiting payment
  PROCESSING_COMPLETE = 'PC',
}

export const TransStatusLabels: { [key in TransStatus]: string } = {
  [TransStatus.AWAITING_STATE_VALIDATION]: 'Awaiting State Validation',
  [TransStatus.UNDER_STATE_REVIEW]: 'Under State Review',
  [TransStatus.HOLDING_TANK]: 'Holding Tank - Awaiting Payment',
  [TransStatus.PROCESSING_COMPLETE]: 'Processing Complete',
};
