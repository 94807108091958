import './dropdown.css';

import { ConfigProvider, Select } from 'antd';

import React from 'react';

type Option = {
  label: string;
  value: string;
};

type DropdownProps = {
  options: Option[];
  selectedOption: string;
  setSelectedOption: (option: string, optionValue?: number) => void;
  styles?: React.CSSProperties;
  index?: number;
  bordered?: boolean;
  placeholder?: string;
  id?: string;
  disabled?: boolean;
};

/**
 * DropdownSelect Component
 *
 * @param {object} props - The component props.
 * @param {Array} props.options - The array of options for the dropdown select.
 * @param {string} props.selectedOption - The selected option value.
 * @param {function} props.setSelectedOption - Callback function to set the selected option.
 * @param {object} props.styles - Custom styles to apply to the dropdown select.
 * @param {number} props.index - Optional index parameter used when the component is used in a list.
 * @returns {JSX.Element} The DropdownSelect component.
 */

function DropdownSelect({
  options,
  selectedOption,
  setSelectedOption,
  styles,
  index,
  bordered,
  placeholder,
  id,
  disabled,
}: DropdownProps) {
  const handleOptionChange = (option: string) => {
    if (index !== undefined) {
      setSelectedOption(option, index);
    } else {
      setSelectedOption(option);
    }
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#DFF4F5',
          },
        }}
      >
        <Select
          id={id || 'drop-down-select'}
          placeholder={placeholder}
          bordered={bordered}
          value={selectedOption}
          style={styles}
          onChange={handleOptionChange}
          options={options.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          disabled={disabled}
        />
      </ConfigProvider>
    </div>
  );
}

export default DropdownSelect;
