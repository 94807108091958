import { Row, Tag, Tooltip } from 'antd';

import { ApplicationTypeEnumInBackend } from '../../enums/applicationType.enum';
import { convertStringToCaps } from '../../utils/name.utils';

function ApplicationTypeCell({
  applicationType,
}: {
  applicationType: ApplicationTypeEnumInBackend | null;
}) {
  return applicationType ? (
    <Row align="middle">
      <Tooltip
        title={
          applicationType === ApplicationTypeEnumInBackend.creation
            ? 'Application was submitted successfully, and Application Status is now considered "In Progress". '
            : applicationType === ApplicationTypeEnumInBackend.renewal
            ? 'Renewals will be managed in the License Renewals section.'
            : null
        }
      >
        <Tag
          style={{ borderRadius: '4px' }}
          color={'var(--secondary-color)'}
          key={applicationType}
        >
          {convertStringToCaps(applicationType)}
        </Tag>
      </Tooltip>
    </Row>
  ) : (
    <></>
  );
}

export default ApplicationTypeCell;
