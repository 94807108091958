import { Button, Col, Modal, Row, Typography } from 'antd';

import { DownlineData } from '../../../../pages/agency/downlines/downlines';
import { DownlineService } from '../../../../services/downlines.service';
import { IdConstants } from '../../../../constants/id.constants';
import OTPInputModal from './send-otp';
import { useAuth } from '../../../../auth/authProvider';
import { useState } from 'react';

type ModalProps = {
  visibility: boolean;
  handleVisibility: (isCancel?: boolean) => void;
  downlinesToBeRemoved: DownlineData[];
  setRemoveDownlines: (value: any) => void;
  npn: string;
  email: string;
  reloadPage: () => void;
  onSuccess?: () => void;
};

function RemoveDownlineModal(props: ModalProps) {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [showOTPField, setShowOTPField] = useState(false);
  const [agencyEmail, setEmail] = useState<string>('');
  const [reasonToRemoveDownline, setReasonToRemoveDownline] =
    useState<string>('');

  // const sendOTP = async () => {
  //   try {
  //     setLoading(true);
  //     const token = await getAccessTokenSilently();
  //     const response = await DownlineService.sendOTP(
  //       token,
  //       reasonToRemoveDownline,
  //       props.downlinesToBeRemoved
  //     ).catch((error) => {
  //       setLoading(false);
  //     });
  //     if (response.status === 'SUCCESS') {
  //       setEmail(response.data.agencyEmail);
  //       setShowOTPField(true);
  //       props.handleVisibility(false);
  //     }
  // props.setRemoveDownlines([]);
  // props.handleVisibility();
  //     setLoading(false);
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const sendOTP = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();

      const downlineIds = props.downlinesToBeRemoved
        .map((downline) =>
          downline.agencyType === 'agency'
            ? downline?.agency?.id
            : downline?.producer?.id
        )
        .filter((id): id is string => id !== undefined && id !== null);

      if (token && downlineIds.length > 0) {
        const response = await DownlineService.sendOTP(token, downlineIds);
        setEmail(response.data);
        setShowOTPField(true);
        props.handleVisibility(true);
      }
    } catch (error) {
      console.error(`Error while removing downline ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={props.visibility}
        onCancel={() => {
          props.handleVisibility(false);
          setReasonToRemoveDownline('');
        }}
        closable
        maskClosable={false}
        centered
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                id={
                  IdConstants.AGENCY.DOWNLINES
                    .REMOVE_DOWNLINE_CONFIRMATION_MODAL.CANCEL_BUTTON
                }
                size="large"
                disabled={loading}
                onClick={() => {
                  props.setRemoveDownlines([]);
                  props.handleVisibility(true);
                  setReasonToRemoveDownline('');
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                id={
                  IdConstants.AGENCY.DOWNLINES
                    .REMOVE_DOWNLINE_CONFIRMATION_MODAL.CONFIRM_BUTTON
                }
                size="large"
                loading={loading}
                type="primary"
                className="button important-action-button"
                onClick={() => {
                  sendOTP();
                }}
                style={{ width: '100%' }}
              >
                Remove Downline
              </Button>
            </Col>
          </Row>
        }
      >
        <Typography.Paragraph
          style={{
            color: 'var(--secondary-color)',
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          Remove{' '}
          {props.downlinesToBeRemoved.length === 1 ? `Downline` : `Downlines`}
        </Typography.Paragraph>

        <Typography.Paragraph
          style={{
            color: 'var(--secondary-color)',
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          Selected{' '}
          {props.downlinesToBeRemoved.length === 1 ? `Downline` : `Downlines`}{' '}
          will be removed from your agency
        </Typography.Paragraph>

        <Row>
          <Col span={10}>
            <Typography.Paragraph
              style={{ color: '#787878', fontSize: '12px' }}
            >
              Producer Name
            </Typography.Paragraph>
          </Col>
          <Col>
            <Typography.Paragraph
              style={{ color: '#787878', fontSize: '12px' }}
            >
              NPN
            </Typography.Paragraph>
          </Col>
        </Row>
        {props.downlinesToBeRemoved.map((agent, index: number) => {
          return (
            <Row key={index}>
              <Col span={10}>
                <Typography.Paragraph style={{ fontSize: '12px' }}>
                  {agent.agencyType === 'agency'
                    ? agent?.agency?.name
                    : agent?.producer?.name}
                </Typography.Paragraph>
              </Col>
              <Col>
                <Typography.Paragraph style={{ fontSize: '12px' }}>
                  {agent.npn}
                </Typography.Paragraph>
              </Col>
            </Row>
          );
        })}
      </Modal>
      <OTPInputModal
        visibility={showOTPField}
        handleVisibility={() => {
          setEmail('');
          setShowOTPField(false);
        }}
        email={agencyEmail}
        downlinesToBeRemoved={props.downlinesToBeRemoved}
        reasonToRemoveDownline={reasonToRemoveDownline}
        reloadPage={() => {
          props.reloadPage();
        }}
      />
    </>
  );
}

export default RemoveDownlineModal;
