import { CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { ApplicantType } from '../../../../../enums/applicantType.enum';
import MinusOutlinedCustom from '../../../../../components/common/icons/minusOutlinedCustom';
import { Tooltip } from 'antd';
import WarningOutlinedCustom from '../../../../../components/common/icons/warningOutlinedCustom';
import { agencyLicensesType } from '../../../../../types/request/agency/allLicenses/allLicenses.type';
import { agencyProfileStore } from '../../../../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../../../../stores/appStateInfo.store';
import { getDisplayFees } from '../../../../../utils/common.utils';

interface listAgentType {
  styles?: object;
  listStyle?: object;
  licenseDetails: agencyLicensesType[];
  stateCode: string;
}
const ListAgentDetails: React.FC<listAgentType> = ({
  styles = {},
  listStyle = {},
  licenseDetails,
  stateCode,
}) => {
  const getFees = (loaCount: number) => {
    const agencyHomeState = agencyProfileStore?.agencyDetails?.homeState;

    return (
      appStateInfoStore.calculateFeesForAgencyLicense(
        stateCode,
        loaCount,
        agencyHomeState,
        ApplicantType.FIRM
      ) || null
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: 15,
        flexGrow: '1',
        ...styles,
      }}
    >
      {licenseDetails.map((data) => {
        const fees = getFees(data?.licenseConfigurations?.loaDetails?.length);
        return (
          <div
            style={{
              border: '1px solid rgba(181, 230, 255, 1)',
              background: 'rgba(255, 255, 255, 1)',
              padding: '16px',
              borderRadius: 6,
              maxWidth: '100%',
              ...listStyle,
            }}
          >
            <div
              style={{
                color: 'rgba(34, 34, 34, 1)',
                fontSize: 14,
                lineHeight: '20px',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {data?.licenseConfigurations?.licenseClass}
              {' ('}
              {data?.licenseConfigurations?.licenseClassCode}
              {')'}
              <div
                className="override-icon-size"
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 12,
                  color: '#00A6FB',
                  margin: 0,
                  marginLeft: '12px',
                }}
              >
                {fees?.fees ? (
                  <div>
                    ( Estimated fees:{' '}
                    <span style={{ fontWeight: 500 }}>
                      ${getDisplayFees(fees?.fees)}
                    </span>{' '}
                    )
                  </div>
                ) : null}{' '}
                {fees?.isRetaliatory ? (
                  <Tooltip
                    title={
                      <div>
                        We are currently building fee calculations for
                        retaliatory states. For more information, please refer{' '}
                        <a target="__blank" href={fees?.retaliatoryLink}>
                          {fees?.retaliatoryLink}
                        </a>
                      </div>
                    }
                  >
                    <QuestionCircleOutlined
                      style={{
                        cursor: 'pointer',
                        color: '#97AABF',
                      }}
                    />
                  </Tooltip>
                ) : null}
              </div>
              {/* <span
                style={{
                  color: '#00A6FB',
                  display: 'inline-block',
                  marginLeft: 6,
                }}
              >
                (${data.amount})
              </span> */}
            </div>

            <div
              style={{
                display: 'flex',
                rowGap: 16,
                columnGap: 8,
                flexWrap: 'wrap',
                margin: '18px 0 6px',
              }}
            >
              {data?.licenseConfigurations?.loaDetails.map((ty) => {
                const isWarn = ['Inactive', 'Missing'].includes(ty.status);
                return (
                  <div
                    style={{
                      border: `1px solid ${isWarn ? '#FFC942' : '#E6E6E6'}`,
                      borderRadius: 8,
                      padding: '3px 12px',
                      display: 'flex',
                      gap: 6,
                      alignItems: 'center',
                      ...(isWarn ? { backgroundColor: '#FCEFCF' } : {}),
                    }}
                  >
                    {ty.status === 'Active' ? (
                      <CheckCircleOutlined size={14} />
                    ) : ty.status === 'Inactive' ? (
                      <WarningOutlinedCustom />
                    ) : ty.status === 'Pending' ? (
                      <MinusOutlinedCustom />
                    ) : null}
                    {ty.loa}
                    {' ('}
                    {ty.loaCode}
                    {')'}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListAgentDetails;
