import './descriptions.css';

import React, { ReactNode } from 'react';

import { Descriptions } from 'antd';

export type DescriptionsItem = {
  label: string;
  descItem: ReactNode;
};

type DescriptionProps = {
  column: number;
  layout?: 'vertical' | 'horizontal';
  style?: React.CSSProperties;
  items: DescriptionsItem[];
};

function CustomDescription(props: DescriptionProps) {
  return (
    <Descriptions
      column={props.column}
      layout={props.layout}
      style={props.style}
    >
      {props.items.map((item) => {
        return (
          <Descriptions.Item label={item.label}>
            {item.descItem}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
}

export default CustomDescription;
