import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';

import { GeneralTabType } from '../../../../../enums/generalTabs.enum';
import { IdConstants } from '../../../../../constants/id.constants';
import { RouteConstants } from '../../../../../constants/routes.constants';
import { useNavigate } from 'react-router';

const FillOwnershipDetails = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: '6px 24px',
        border: '1px solid #FFC942',
        backgroundColor: '#FCEFCF',
        borderRadius: 12,
        marginBottom: 16,
      }}
    >
      <p style={{ fontSize: 18, fontWeight: 'bold' }}>
        <InfoCircleOutlined style={{ marginRight: 12, color: '#E2A200' }} />
        Fill Ownership Details
      </p>
      <p style={{ marginLeft: 30 }}>
        Please fill the ownership details to proceed forward with the agency
        licenses application
      </p>
      <p
        style={{
          color: '#9F7200',
          textDecoration: 'underline',
          marginLeft: 30,
        }}
        id={IdConstants.AGENCY.ALL_LICENSES.FILL_OWNERSHIP_DETAILS}
        onClick={() =>
          navigate(
            RouteConstants.agencyGeneralProfile.path +
              `?tab=${GeneralTabType.OWNERSHIP_DETAILS}`
          )
        }
      >
        <span className="cursor-pointer">
          Click here to fill the details <RightOutlined />
        </span>
      </p>
    </div>
  );
};

export default FillOwnershipDetails;
