import BackgroundQuestionsScreen from '../../../pages/agentSidePortal/agentInformation/backgroundQuestions/backgroundQuestions';
import CustomTab from '../../../components/common/customTab/customTab';
import { IdConstants } from '../../../constants/id.constants';
import { Input } from 'antd';
import { useState } from 'react';

const { Search } = Input;
export const AGENCY_BACKGROUND_QUESTIONS_TYPE = {
  ALL: 'All',
  ASSINGED_STATES: 'Assigned States',
};

const MyAgencyBackgroundQuestions = () => {
  const [selectedTab, setSelectedTab] = useState(
    AGENCY_BACKGROUND_QUESTIONS_TYPE.ALL
  );
  const [searchValue, setSearchValue] = useState('');

  return (
    <div className="my-5 override-icon-size width-100 my-agency-background-questions">
      <div className="flex justify-between items-center" style={{ gap: 12 }}>
        <div className="tabs">
          <CustomTab
            selectedTab={selectedTab}
            tabList={Object.values(AGENCY_BACKGROUND_QUESTIONS_TYPE)}
            onSelect={(value) => {
              setSelectedTab(value);
            }}
          />
        </div>
        <Search
          id={IdConstants.SEARCH_INPUT + '-my-agency-background-questions'}
          placeholder="Search"
          style={{ width: '312px', height: 36 }}
          onSearch={(value) => {
            setSearchValue(value);
          }}
          onPressEnter={(e) => {
            setSearchValue((e.target as HTMLInputElement).value);
          }}
        />
      </div>
      <div style={{ width: '100%' }} className="my-5">
        <BackgroundQuestionsScreen
          isLoading={false}
          editMode={true}
          isAgency
          isCollapsible
          isMyAgency
          selectedType={selectedTab}
          searchValue={searchValue}
        />
      </div>
    </div>
  );
};

export default MyAgencyBackgroundQuestions;
