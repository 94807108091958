import {
  AssignmentOptions,
  INDIVIDUAL_ONBOARD_STEPS,
  IndividualOnboardAgentProps,
  IndividualOnboardStateGroupProps,
  StateLicenses,
  paymentOptionsType,
  updateProducerAssignmetPayload,
} from './individualOnboardV2Type.type';
import { Button, Card, Layout, Modal, Skeleton, message } from 'antd';
import {
  getStateCodeFromName,
  getStateNameFromCode,
} from '../../../../utils/common.utils';
import {
  steps,
  updateAgentBioInfo,
  validateAssignmentStep,
  validateOnboardingStep,
} from './IndividualOnboardHelpers';
import { useCallback, useEffect, useState } from 'react';

import { AgencyService } from '../../../../services/agency.service';
import { AgentService } from '../../../../services/agent.service';
import { ApplicantType } from '../../../../enums/applicantType.enum';
import CustomSteps from '../../../../components/common/steps/customStepper';
import IndividualOnboardAssignments from './individualOnboardAssignments';
import IndividualOnboardDetails from './individualOnboardDetails';
import { LeftOutlined } from '@ant-design/icons';
import OnboardedWelcomeScreen from './onboardedWelcomeScreen';
import { RouteConstants } from '../../../../constants/routes.constants';
import { agencyProfileStore } from '../../../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { currentStateFee } from '../../../../types/data/feeCalculation.type';
import { individualOnboardStore } from '../../../../stores/individualOnboardStore';
import insureTrekLogo from '../../../../assets/images/insureTrekLogo.png';
import { observer } from 'mobx-react-lite';
import siderImage from '../../../../assets/images/agencyLicensingSidebar.png';
import { useAuth } from '../../../../auth/authProvider';
import { useNavigate } from 'react-router';

const { Content, Sider, Footer } = Layout;

const IndividualOnboardV2 = observer(() => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentOnboardStep, setCurrentOnboardStep] = useState(
    INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS
  );
  const [warningModal, setWarningModal] = useState(false);
  const [agentBioInfo, setAgentBioInfo] = useState<IndividualOnboardAgentProps>(
    {
      isExistingAgent: false,
      agentEmail: '',
      agentNPN: '',
      agentSsn: '',
      agentFax: '',
      agentGender: '',
      agentDateOfBirth: '',
      agentHomeStateAddresses: [],
      addressLastUpdatedAt: new Date(),
      agentPhone: '',
      firstName: '',
      lastName: '',
      producerId: '',
    }
  );
  const [paymentOptions, setPaymentOptions] = useState<paymentOptionsType>({
    newLicenses: '',
    renewalLicenses: '',
    autoApply: false,
  });
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [stateGroups, setStateGroups] = useState<
    IndividualOnboardStateGroupProps[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<StateLicenses[]>([]); // selected states in assignments
  const [selectedTerritory, setSelectedTerritory] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedTab, setSelectedTab] = useState(AssignmentOptions.TERRITORIES);
  const TerritoryCount = stateGroups?.filter((data) => data.isSelected)?.length;
  const StateCount = selectedItems?.length;
  const isDataFetching = individualOnboardStore.isProducerDataFetching;
  const [producerEstimatedFee, setProducerEstimatedFee] = useState<number>(0);

  const updateProducerDetails = async () => {
    let isError = false;
    try {
      setLoading(true);
      const ifNotUpdated =
        !agentBioInfo.agentUpdatedDOB &&
        !agentBioInfo.agentUpdatedGender &&
        !agentBioInfo.agentUpdatedSSN &&
        !paymentOptions.newLicenses;
      if (!ifNotUpdated) {
        const bearerToken = await getAccessTokenSilently();
        const payload = {
          ...(agentBioInfo.agentUpdatedDOB
            ? { dateOfBirth: agentBioInfo.agentUpdatedDOB }
            : {}),
          ...(agentBioInfo.agentUpdatedGender
            ? { gender: agentBioInfo.agentUpdatedGender }
            : {}),
          ...(agentBioInfo.agentUpdatedSSN
            ? { ssn: agentBioInfo.agentUpdatedSSN }
            : {}),
          ...(paymentOptions.newLicenses
            ? {
                paymentConfig: {
                  licenseInitialPayee: paymentOptions.newLicenses,
                  licenseRenewalPayee: paymentOptions.renewalLicenses,
                  paymentType: 'full',
                  cappedAmount: 0,
                  isAutoRenewalActive: paymentOptions.autoApply,
                },
              }
            : {}),
        };
        const response = await AgencyService.updateProducerDetails(
          agentBioInfo.producerId,
          payload,
          bearerToken
        );
        message.success(response?.message);
        setAgentBioInfo(updateAgentBioInfo);
      }
      setCurrentOnboardStep((prev) => prev + 1);
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data?.message);
      isError = true;
    } finally {
      setLoading(false);
    }
    return isError;
  };

  const updateAssignmentDetails = async () => {
    let isError = false;
    try {
      setLoading(true);
      const bearerToken = await getAccessTokenSilently();
      const payload: updateProducerAssignmetPayload[] = [];
      const selectedStateGroup = stateGroups?.filter((d) => d.isSelected) || [];
      selectedStateGroup.forEach((d) => {
        d.stateLicenseConfigs.forEach((data) => {
          payload.push({
            territoryId: d.id,
            stateCode: data?.stateCode,
            licenseConfigurations: data?.licenseConfigs
              .flatMap((d) => d.loaConfigs)
              .map((d) => d.id || '')
              .filter((d) => d),
          });
        });
      });
      selectedItems.forEach((data) => {
        const licenseConfigurations: string[] = [];
        Object.entries(data).forEach(([k, v]) => {
          if (typeof v === 'string') return;
          v?.loas?.forEach((element) => {
            licenseConfigurations.push(element?.id || '');
          });
        });
        payload.push({
          stateCode: getStateCodeFromName(data.name) || '',
          licenseConfigurations,
        });
      });
      const response = await AgencyService.updateBulkAssignmentsForProducer(
        [agentBioInfo.producerId],
        payload,
        bearerToken
      );
      message.success(
        response?.message || 'Producer Assignments Updated Successfully'
      );
      await AgentOnboardFees();
      setIsOnboarded(true);
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data?.message);
      isError = true;
    } finally {
      setLoading(false);
    }
    return isError;
  };

  const handleNext = async () => {
    let isError = false;

    // validation for each step
    if (currentStep === INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS) {
      isError = validateOnboardingStep(agentBioInfo, paymentOptions);
    } else if (
      currentStep === INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS
    ) {
      isError = validateAssignmentStep(selectedItems, stateGroups);
      if (!isError) {
        const selectedState = selectedItems?.[0]?.name;
        const selectedTerritory = stateGroups?.filter((d) => d.isSelected);
        if (
          selectedTab === AssignmentOptions.TERRITORIES &&
          selectedTerritory?.length
        ) {
          setSelectedTerritory(selectedTerritory?.[0]?.name);
          setSelectedState(
            getStateNameFromCode(
              selectedTerritory?.[0]?.stateLicenseConfigs?.[0]?.stateCode
            )
          );
        } else if (selectedState) {
          setSelectedState(selectedState);
        }
      }
    }

    if (isError) return;

    if (currentStep === INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS) {
      isError = await updateProducerDetails();
    } else if (
      currentStep ===
      INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS_REVIEW
    ) {
      isError = await updateAssignmentDetails();
    }

    if (isError) return;

    const nextStep = currentStep + 1;
    if (nextStep < steps.length) {
      setCurrentStep(nextStep);
    }
  };

  const handlePrevious = () => {
    const prevStep = currentStep - 1;
    if (prevStep === 0) {
      setCurrentOnboardStep(0);
    }
    setCurrentStep(prevStep);

    if (prevStep === INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS) {
      if (selectedTab === AssignmentOptions.TERRITORIES) {
        const firstTerritory = stateGroups?.[0]?.name;
        const firstTerritoryState =
          stateGroups?.[0]?.stateLicenseConfigs?.[0]?.stateCode;

        if (firstTerritory) setSelectedTerritory(firstTerritory);
        if (firstTerritoryState)
          setSelectedState(getStateNameFromCode(firstTerritoryState));
      }
    }
  };

  const handleStepClick = (step: number) => {
    if (currentStep === 2 && step === 1) setCurrentStep(step);
  };

  const isAssignmentAssigned = () => {
    const selectedStateGroup = stateGroups.filter((data) => data.isSelected);
    if (selectedStateGroup?.length) return true;

    return selectedItems?.some((data) => data.name);
  };

  useEffect(() => {
    // on refresh , navigate to onboard screen
    if (!sessionStorage.getItem('isOnboarding'))
      navigate(RouteConstants.onboardAgents.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      event.preventDefault();
      sessionStorage.removeItem('isOnboarding');
    };

    // on refresh, show warning modal
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const producerId = individualOnboardStore.agentBioInfo.producerId;

  const getProducerDetails = async () => {
    try {
      const bearerToken = await getAccessTokenSilently();
      const producerDetails = await AgentService.getProducerDetailsById(
        producerId,
        bearerToken
      );
      const producer = producerDetails?.data;

      setAgentBioInfo({
        isExistingAgent: false,
        agentEmail:
          producer?.contactEmail ||
          agentBioInfo?.agentEmail ||
          producer?.businessEmail ||
          '',
        agentNPN: producer?.npn,
        agentFax: producer?.businessPhone,
        agentSsn: producer?.ssn,
        agentDateOfBirth: producer?.dateOfBirth,
        agentGender: producer?.gender,
        agentHomeStateAddresses: producer?.address,
        addressLastUpdatedAt: producer?.addressLastUpdatedAt || new Date(),
        agentPhone: producer?.businessPhone,
        firstName: producer?.name?.firstName || '',
        lastName: producer?.name?.lastName || '',
        producerId,
      });
      setPaymentOptions((prev) => ({
        ...prev,
        autoApply: producer?.paymentConfig?.isAutoRenewalActive,
        newLicenses: producer?.paymentConfig?.licenseInitialPayee,
        renewalLicenses: producer?.paymentConfig?.licenseRenewalPayee,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      individualOnboardStore.setIsProducerDataFetching(false);
    }
  };

  useEffect(() => {
    if (producerId) {
      getProducerDetails();
    }
    return () => {
      individualOnboardStore.resetStore();
      sessionStorage.removeItem('isOnboarding');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producerId]);

  useEffect(() => {
    setCurrentOnboardStep(individualOnboardStore.currentOnboardStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualOnboardStore.currentOnboardStep]);

  const AgentOnboardFees = async () => {
    const selectedStates: currentStateFee[] = [];
    const producerActiveLicenses =
      individualOnboardStore.getProducerActiveLicenses();
    stateGroups
      .filter((data) => data.isSelected)
      ?.forEach((d) => {
        d.stateLicenseConfigs.forEach((data) => {
          const isActiveLicensePresentForState = producerActiveLicenses.find(
            (license) => license.stateCode === data.stateCode
          );
          if (isActiveLicensePresentForState) {
            // data.licenseConfigs.forEach((licenseConfig) => {
            //   if (
            //     licenseConfig.lcCode ===
            //     isActiveLicensePresentForState.licenseClassCode
            //   ) {
            selectedStates.push({
              stateCode: data.stateCode,
              active: true,
            });
            // } else {
            //   selectedStates.push({
            //     stateCode: data.stateCode,
            //     active: false,
            //   });
            // }
            // });
          } else {
            selectedStates.push({ stateCode: data.stateCode, active: false });
          }
        });
      });

    // selected state fees
    selectedItems?.forEach((d) => {
      const stateCode = getStateCodeFromName(d.name) || '';

      const isActiveLicensePresentForState = producerActiveLicenses.find(
        (license) => license.stateCode === getStateCodeFromName(d.name)
      );
      if (isActiveLicensePresentForState) {
        const lcCodes = Object.keys(d)?.filter((d) => d !== 'name');
        lcCodes?.forEach((licenseConfig) => {
          if (
            licenseConfig === isActiveLicensePresentForState.licenseClassCode
          ) {
            selectedStates.push({ stateCode: stateCode, active: true });
          } else {
            selectedStates.push({ stateCode: stateCode, active: false });
          }
        });
      } else {
        selectedStates.push({ stateCode: stateCode, active: false });
      }
    });
    try {
      const token = await getAccessTokenSilently();
      const response = await AgencyService.calculateFee(token, {
        currentState: selectedStates,
      });
      if (response) {
        const applicationFee = response?.applicationFee || 0;
        setProducerEstimatedFee(applicationFee);
      }
    } catch (error) {
      console.error(`Error while fetching fee | ${error}`);
    }
  };

  if (isOnboarded)
    return (
      <OnboardedWelcomeScreen
        firstName={agentBioInfo.firstName}
        lastName={agentBioInfo.lastName}
        AgentAmount={producerEstimatedFee}
        TerritoryCount={TerritoryCount}
        StateCount={StateCount}
        producerId={producerId}
      />
    );

  return (
    <Layout
      style={{ minHeight: '100vh' }}
      className="individual-onboarding-v2-layout"
    >
      <Sider
        style={{
          height: '100vh',
          position: 'sticky',
          top: '0',
          backgroundImage: `url(${siderImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        width={258}
        theme="light"
      >
        {/* Content inside Sider */}
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: '30px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '40px',
            }}
          >
            <img
              alt="logo"
              style={{
                width: '164px',
                height: '42.8px',
              }}
              src={insureTrekLogo}
            />
          </div>
          <div
            style={{
              width: '198px',
              height: '27px',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '27px',
              color: '#FFFFFF',
              marginBottom: '20px',
            }}
          >
            Producer Onboarding
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{
                width: '201px',
                height: '36px',
                borderRadius: '6px',
                padding: '12px 24px',
                backgroundColor: '#E8EBEE',
                border: 'none',
                gap: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                setWarningModal(true);
              }}
            >
              <LeftOutlined style={{ fontSize: '4px' }} />
              Go Back
            </Button>
          </div>
          <div
            style={{
              width: '201px',
              height: '1px',
              border: '0.5px solid #374F6D',
              marginTop: '30px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <p
              style={{
                width: '198px',
                height: '54px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '27px',
                color: '#FFFFFF',
                textAlign: 'left',
                marginTop: '30px',
              }}
            >
              Add a producer to your team
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: '198px',
                height: '54px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#00A6FB',
                textAlign: 'left',
                margin: 0,
              }}
            >
              Get their current information and licenses from NIPR, and start
              tracking their information in InsureTrek.
            </p>
          </div>
        </div>
      </Sider>
      {/* Main Content Layout */}
      <Layout>
        {isDataFetching ? (
          <Card
            style={{
              margin: '24px',
            }}
          >
            {' '}
            <Skeleton avatar paragraph={{ rows: 2 }} active />
          </Card>
        ) : (
          <Content
            style={{
              flex: 1,
              padding: '20px',
              overflow: 'auto',
            }}
          >
            {currentOnboardStep ===
            INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS ? (
              <IndividualOnboardDetails
                {...{
                  agentBioInfo,
                  setAgentBioInfo,
                  paymentOptions,
                  setPaymentOptions,
                  loading,
                }}
              />
            ) : null}
            {currentOnboardStep ===
            INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS ? (
              <IndividualOnboardAssignments
                {...{
                  agentBioInfo,
                  selectedItems,
                  setSelectedItems,
                  setStateGroups,
                  stateGroups,
                  selectedTerritory,
                  setSelectedTerritory,
                  selectedState,
                  setSelectedState,
                  selectedTab,
                  setSelectedTab,
                }}
                isReview={
                  currentStep ===
                  INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS_REVIEW
                }
              />
            ) : null}
          </Content>
        )}
        {!isDataFetching ? (
          <Footer
            style={{
              height: '64px',
              border: '1px solid #E6E6E6',
              backgroundColor: '#FFFFFF',
              padding: '20px',
              gap: '4px',
              position: 'sticky',
              bottom: '0px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <CustomSteps
              steps={steps}
              disabled={loading}
              current={currentStep}
              onClickStep={handleStepClick}
              maxStep={steps.length}
            />
            {currentStep !== 0 ? (
              <Button
                style={{
                  height: '35px',
                  borderRadius: '6px',
                  padding: '12px 24px 12px 16px',
                  backgroundColor: '#E8EBEE',
                  color: '#000000',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '16px',
                  marginLeft: '40px',
                }}
                onClick={handlePrevious}
                disabled={loading} // disable previous on onboard and assign step
              >
                <span
                  style={{
                    fontSize: '22px',
                    lineHeight: '1em',
                    marginRight: '5px', // Optional: add some space between text and symbol
                  }}
                >
                  &lt;
                </span>
                <span
                  className="font-poppins"
                  style={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#222222',
                  }}
                >
                  Previous
                </span>
              </Button>
            ) : null}
            <Button
              loading={loading}
              disabled={
                loading || (currentStep === 1 && !isAssignmentAssigned())
              }
              style={{
                marginLeft: '10px',
                height: '36px',
                backgroundColor: '#00A6FB',
                color: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={handleNext}
            >
              {currentStep === 2 ? 'Finish' : 'Next'}
              {!(currentStep === 2) && (
                <span
                  style={{
                    fontSize: '22px',
                    lineHeight: '1em',
                    marginLeft: '5px', // add some space between text and symbol
                  }}
                >
                  &gt;
                </span>
              )}
            </Button>
          </Footer>
        ) : null}
      </Layout>
      <Modal
        title={
          // currentOnboardStep === 0
          //   ? 'Are you sure you want to leave the onboarding process?'
          //   :
          'Are you sure you want to exit the onboarding process?'
        }
        open={warningModal}
        maskClosable={false}
        closable={false}
        width={600}
        onOk={() => {
          setWarningModal(false);
        }}
        onCancel={() => {
          navigate(RouteConstants.onboardAgents.path);
          setWarningModal(false);
        }}
        okText={'Complete onboarding'}
        cancelButtonProps={{
          className: 'leave-onboard important-action-button',
        }}
        cancelText={
          // currentOnboardStep === 0 ? 'Leave' :
          'Exit and Complete Later'
        }
      >
        {/* {currentOnboardStep === 0 ? (
          <p>
            Leaving the onboarding process at this stage will discard all the
            information and your onboarding progress will not be saved. Are you
            sure you want to exit?
          </p>
        ) : ( */}
        <p>
          The producer has been onboarded. However, the onboarding process
          involves additional steps that should be completed in their respective
          sections on the platform if you choose to exit. Are you sure?
        </p>
        {/* )} */}
      </Modal>
    </Layout>
  );
});

export default IndividualOnboardV2;
