import { Typography } from 'antd';

const { Text } = Typography;

export const getSearchTypeByLabel = (
  label: string,
  searchFilters: any[]
): string => {
  const filter = searchFilters.find((filter) => filter.filterLabel === label);
  return filter ? filter.key : '';
};

export const sortSearchTypesByPage = (searchFilter: any, page: string) => {
  return searchFilter.sort((a: any, b: any) => {
    const aDisplay = a.display.find((d: any) => d.page === page);
    const bDisplay = b.display.find((d: any) => d.page === page);

    const aOrder = aDisplay ? aDisplay.displayOrder : Infinity;
    const bOrder = bDisplay ? bDisplay.displayOrder : Infinity;
    return aOrder - bOrder;
  });
};

export const getOptionsForSearch = (fields: string[], searchText: string) => {
  const options = searchText
    ? fields.map((searchField) => ({
        value: `${searchText} in ${searchField}`,
        label: (
          <span>
            <Text strong>{searchText}</Text>
            {`  `}
            <Text
              style={{
                backgroundColor: 'var(--light-grey3)',
                padding: '0 4px',
                borderRadius: '4px',
              }}
            >
              in: {searchField}
            </Text>
          </span>
        ),
      }))
    : [];
  searchText &&
    options.push({
      value: `${searchText} in any`,
      label: (
        <span>
          <Text strong>{searchText}</Text>
        </span>
      ),
    });
  return options;
};

export const getSearchDisplayText = (
  searchType: string,
  displaySearchType: string,
  searchText: string
) => {
  return searchType && searchType !== 'any'
    ? `in: ${displaySearchType}: ${searchText}`
    : searchText;
};
