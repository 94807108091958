import { ReactNode, useState } from 'react';

import { Tooltip } from 'antd';

type EngineLightProps = {
  icon: ReactNode;
  tooltip: string | null;
  status: 'green' | 'yellow' | 'red';
  onClick?: () => void;
};

function EngineLight(props: EngineLightProps) {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getColor = (): string => {
    if (props.status === 'yellow') return isHovered ? '#FFA500' : '#FFC04D';
    if (props.status === 'red')
      return isHovered ? 'var(--important-color)' : '#ff978e';
    else return isHovered ? '#009921' : '#9ed8ac';
  };

  return (
    <Tooltip title={props.tooltip}>
      <div
        style={{
          width: '18px',
          height: '24px',
          marginBottom: '2px',
          cursor: 'pointer',
        }}
        onClick={props.onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span
          style={{
            color: getColor(),
            transition: 'color 0.3s ease, font-size 0.3s ease',
            fontSize: !isHovered ? '18px' : '14px',
          }}
        >
          {props.icon}
        </span>
      </div>
    </Tooltip>
  );
}

export default EngineLight;
