import {
  AgencyLoadedData,
  DownlineAgencyHierarchyDetails,
} from '../../../../stores/hierarchyViewStore';

import DownlinesCard from './downlinesCard';
import { getNameString } from '../../../../utils/name.utils';
import { observer } from 'mobx-react-lite';

type downlineCardWithPositionedLinesProps = {
  item: DownlineAgencyHierarchyDetails;

  index: number;
  isLastIndex: boolean;
  currentChain: { id: string; agencyName: string }[];
};

function DownlineCardWithPositionedLines({
  item,
  index,
  isLastIndex,
  currentChain,
}: Readonly<downlineCardWithPositionedLinesProps>) {
  const lines = getNameString(item.name)?.length / 19;
  const endCurve = 15 + lines * 5 + (lines > 2 ? 1 : 0);

  return (
    <div style={{ position: 'relative' }}>
      {/* START Horizontal Line */}
      {index === 0 && (
        <div
          style={{
            height: '1px',
            width: '50%',
            left: '-40%',
            backgroundColor: 'var(--light-grey2)',
            position: 'absolute',
            top: '50%',
          }}
        />
      )}
      {/* END Horizontal Line */}
      {/* START Veritcal Lines */}
      {index !== 0 && (
        <div
          style={{
            width: '1px',
            height: '50%',
            backgroundColor: 'var(--light-grey2)',
            position: 'absolute',
            bottom: '70%',
          }}
        />
      )}
      {index === 0 && !isLastIndex && (
        <div
          style={{
            width: '1px',
            height: '50%',
            backgroundColor: 'var(--light-grey2)',
            position: 'absolute',
            top: '70%',
          }}
        />
      )}
      {index !== 0 && !isLastIndex && (
        <div
          style={{
            width: '1px',
            height: '75%',
            backgroundColor: 'var(--light-grey2)',
            position: 'absolute',
            top: '30%',
          }}
        />
      )}
      {/* END Veritcal Lines */}
      {/* START Curve */}
      {index === 0 ? (
        !isLastIndex && (
          <svg
            width="20"
            height="50%"
            style={{ position: 'absolute', bottom: '0', left: '-10' }}
          >
            <path
              d={`M0 0 Q10 -2 10.5 ${endCurve}`}
              fill="none"
              stroke="var(--light-grey2)"
              stroke-width="1"
            ></path>
          </svg>
        )
      ) : (
        <svg
          width="20"
          height="50%"
          style={{ position: 'absolute', bottom: '20%', left: '-10' }}
        >
          <path
            d="M40 10 Q8 20 10.5 0"
            fill="none"
            stroke="var(--light-grey2)"
            stroke-width="1"
          ></path>
        </svg>
      )}
      {/* END Curve */}

      <DownlinesCard
        data={item}
        isSelected={currentChain.map((d) => d.id)?.includes(item._id)}
      />
    </div>
  );
}

export default observer(DownlineCardWithPositionedLines);
