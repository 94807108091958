import { MatchedRoute } from '../types/common/matchedRoute.type';
import { RouteConstants } from '../constants/routes.constants';

export const findRouteByPath = (path: string): MatchedRoute | null => {
  for (const value of Object.values(RouteConstants)) {
    const route = value;
    const regex = pathToRegex(route.path);
    const match = path.match(regex);

    if (match) {
      // Extract parameters from the path
      const params: Record<string, string> = {};
      const paramKeys = route.path.match(/:\w+/g) || [];
      paramKeys.forEach((paramKey, index) => {
        params[paramKey.slice(1)] = match[index + 1];
      });

      return { route, params };
    }
  }

  return null; // No match found
};

// Helper function to convert path with parameters to regex
const pathToRegex = (path: string): RegExp => {
  const regexString = path.replace(/:\w+/g, '([^/]+)');
  return new RegExp(`^${regexString}$`);
};
