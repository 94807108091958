import { Button, Modal, Typography } from 'antd';

import { IdConstants } from '../../../../constants/id.constants';
import React from 'react';
import { RouteConstants } from '../../../../constants/routes.constants';
import { useNavigate } from 'react-router';

type DiscardModalProps = {
  visible: boolean;
  handleVisibility: () => void;
};
function DiscardConfirmationModal(props: DiscardModalProps) {
  const navigate = useNavigate();

  return (
    <Modal
      open={props.visible}
      onCancel={props.handleVisibility}
      closable
      destroyOnClose
      centered
      style={{ boxShadow: 'none' }}
      footer={
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <Button
            id={IdConstants.STATE_GROUPS.DISCARD_MODAL.CANCEL}
            size="large"
            style={{
              width: '100%',
              marginLeft: '-5px',
            }}
            onClick={() => {
              props.handleVisibility();
            }}
          >
            Cancel
          </Button>
          <Button
            id={IdConstants.STATE_GROUPS.DISCARD_MODAL.DISCARD_CHANGES}
            htmlType="submit"
            size="large"
            className="button"
            style={{
              width: '100%',
              marginBottom: '10px',
            }}
            onClick={() => {
              navigate(RouteConstants.stateGroups.path);
            }}
          >
            Discard Changes
          </Button>
        </div>
      }
    >
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        Are you sure you want to discard changes made to this territory?
      </Typography.Paragraph>
      <Typography.Paragraph
        style={{
          color: 'var(--grey-text)',
          fontSize: '14px',
          fontWeight: '400',
        }}
      >
        All changes made to this territory will be reverted
      </Typography.Paragraph>
    </Modal>
  );
}

export default DiscardConfirmationModal;
