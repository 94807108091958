import { LcDragItem, LoaDragItem } from '../editStateGroup';
import {
  LicenseConfig,
  StateLicenseConfig,
} from '../../../../types/data/stateGroup.types';
import { useEffect, useState } from 'react';

import { Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DragItemTypes } from '../../../../types/dnd/dragItem.types';
import DroppableLcCard from './droppableLcCard';
import { IdConstants } from '../../../../constants/id.constants';
import { getStateNameFromCode } from '../../../../utils/common.utils';
import { useDrop } from 'react-dnd';

export interface HoverDetails {
  isHovering: boolean;
  lcCode: string;
  loaCode: string;
}

interface DroppableStateCardProps {
  stateInfo: StateLicenseConfig;
  currentLoaDragItem: LoaDragItem;
  currentLcDragItem: LcDragItem;
  updateStateLicenseConfig: (stateLicenseConfig: StateLicenseConfig) => void;
  updateLicenseConfig: (
    stateCode: string,
    licenseConfig: LicenseConfig
  ) => void;
  removeStateFromStateGroup: (stateCode: string) => void;
  removeLcFromStateGroup: (stateCode: string, lcCode: string) => void;
  removeLoaFromStateGroup: (
    stateCode: string,
    lcCode: string,
    loaCode: string,
    loaId: string
  ) => void;
}
function DroppableStateCard({
  stateInfo,
  currentLoaDragItem,
  currentLcDragItem,
  updateStateLicenseConfig,
  updateLicenseConfig,
  removeStateFromStateGroup,
  removeLcFromStateGroup,
  removeLoaFromStateGroup,
}: DroppableStateCardProps) {
  const [hoverColor, setHoverColor] = useState<string>();
  const [{ isOverCurrent, dragItemType }, dropRef] = useDrop(
    () => ({
      accept: [DragItemTypes.LC, DragItemTypes.LOA],
      drop(_item: unknown, monitor) {
        const dragItemType = monitor.getItemType();
        if (
          dragItemType === DragItemTypes.LC &&
          currentLcDragItem.stateCode === stateInfo.stateCode &&
          !stateInfo.licenseConfigs
            .map((itr) => itr.lcCode)
            .includes(currentLcDragItem.lcCode)
        ) {
          updateStateLicenseConfig({
            ...stateInfo,
            licenseConfigs: [
              ...stateInfo.licenseConfigs,
              {
                lcCode: currentLcDragItem.lcCode,
                loaCodes: currentLcDragItem.loaCodes,
                loaids: currentLcDragItem.loaids,
              },
            ],
          });
        } else if (
          dragItemType === DragItemTypes.LOA &&
          currentLoaDragItem.stateCode === stateInfo.stateCode &&
          !stateInfo.licenseConfigs
            .map((itr) => itr.lcCode)
            .includes(currentLoaDragItem.lcCode)
        )
          updateStateLicenseConfig({
            ...stateInfo,
            licenseConfigs: [
              ...stateInfo.licenseConfigs,
              {
                lcCode: currentLoaDragItem.lcCode,
                loaCodes: [currentLoaDragItem.loaCode],
                loaids: [currentLoaDragItem.loaids],
              },
            ],
          });
      },
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
        dragItemType: monitor.getItemType(),
      }),
    }),
    [currentLcDragItem, currentLoaDragItem]
  );

  useEffect(() => {
    if (
      (dragItemType === DragItemTypes.LC &&
        currentLcDragItem.stateCode === stateInfo.stateCode &&
        !stateInfo.licenseConfigs
          .map((itr) => itr.lcCode)
          .includes(currentLcDragItem.lcCode)) ||
      (dragItemType === DragItemTypes.LOA &&
        currentLoaDragItem.stateCode === stateInfo.stateCode &&
        !stateInfo.licenseConfigs
          .map((itr) => itr.lcCode)
          .includes(currentLoaDragItem.lcCode))
    )
      setHoverColor('azure');
    else setHoverColor('#ffede8');
  }, [isOverCurrent]);

  const [currentHoverDetails, setCurrentHoverDetails] = useState<HoverDetails>({
    isHovering: false,
    lcCode: '',
    loaCode: '',
  });

  const updateIsHovering = (isHovering: boolean) => {
    setCurrentHoverDetails({ ...currentHoverDetails, isHovering: isHovering });
  };

  const updateLcCodeForHoverDetails = (lcCode: string) => {
    setCurrentHoverDetails({ loaCode: '', lcCode: lcCode, isHovering: true });
  };

  const updateLoaCodeForHoverDetails = (loaCode: string, lcCode?: string) => {
    setCurrentHoverDetails({
      ...currentHoverDetails,
      loaCode: loaCode,
      ...(lcCode && { lcCode }),
    });
  };

  return (
    <Card
      id={
        IdConstants.STATE_GROUPS.DROPPABLE_STATE_LABEL +
        '-' +
        getStateNameFromCode(stateInfo.stateCode) +
        '-state-groups'
      }
      bodyStyle={{ display: 'flex', flexDirection: 'column' }}
      key={stateInfo.stateCode}
      ref={dropRef}
      style={{
        ...(isOverCurrent && {
          backgroundColor: hoverColor,
        }),
      }}
      onMouseEnter={() => {
        updateIsHovering(true);
      }}
      onMouseLeave={() => {
        updateIsHovering(false);
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span className="typography_1">
          {getStateNameFromCode(stateInfo.stateCode)}
        </span>

        <CloseOutlined
          style={{
            visibility:
              currentHoverDetails.isHovering &&
              !currentHoverDetails.lcCode &&
              !currentHoverDetails.loaCode
                ? 'visible'
                : 'hidden',
            cursor: 'pointer',
          }}
          onClick={() => {
            removeStateFromStateGroup(stateInfo.stateCode);
          }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {stateInfo.licenseConfigs.map((licenseItr, index) => {
          return (
            <DroppableLcCard
              key={index}
              licenseInfo={licenseItr}
              currentLoaDragItem={currentLoaDragItem}
              stateCode={stateInfo.stateCode}
              updateLicenseConfig={updateLicenseConfig}
              currentHoverDetails={currentHoverDetails}
              updateLcCodeForHoverDetails={updateLcCodeForHoverDetails}
              updateLoaCodeForHoverDetails={updateLoaCodeForHoverDetails}
              removeLcFromStateGroup={removeLcFromStateGroup}
              removeLoaFromStateGroup={removeLoaFromStateGroup}
            />
          );
        })}
      </div>
    </Card>
  );
}

export default DroppableStateCard;
