import {
  Button,
  Card,
  ConfigProvider,
  Skeleton,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  capitalizeFirstLetter,
  getStateNameFromCode,
} from '../../../../utils/common.utils';

import { AgencyService } from '../../../../services/agency.service';
import AssignModal from '../../../../components/assignModal/assignModal';
import { AssignedState } from '../../../../types/data/stateGroup.types';
import { ColumnsType } from 'antd/es/table';
import { IdConstants } from '../../../../constants/id.constants';
import { MultipleOnboardSteps } from '../../../../enums/onboardSteps.enum';
import { StateConstants } from '../../../../constants/state.constants';
import StatesCircled from '../../../../components/common/statesCircled/StatesCircled';
import TextWithIcon from '../../../../components/common/textWithIcon/textWithIcon';
import emptyTable from '../../../../assets/images/emptyTable.svg';
import { generateAssignmentErrorMessage } from '../../../../utils/errorUtils';
import { groupBy } from 'lodash';
import { individualOnboardStore } from '../../../../stores/individualOnboardStore';
import { multipleOnboardStore } from '../../../../stores/multipleOnboardStore';
import { observer } from 'mobx-react-lite';
import { updateProducerAssignmetPayload } from '../individualOnboardV2/individualOnboardV2Type.type';
import { useAuth } from '../../../../auth/authProvider';

interface groupedData {
  stateGroupName: string;
  stateCodes: string[];
}

interface MultipOnboardProps {
  assignedStates: AssignedState[];
  setAssignedStates: React.Dispatch<React.SetStateAction<AssignedState[]>>;
  groupedData: groupedData[];
  setGroupedData: React.Dispatch<React.SetStateAction<groupedData[]>>;
}

const MultipleOnboardingAssignments: React.FC<MultipOnboardProps> = ({
  assignedStates,
  setAssignedStates,
  groupedData,
  setGroupedData,
}) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth();

  // error toast setup
  const [api, contextHolder] = notification.useNotification();

  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'State/Territory',
      key: 'stateGroupName',
      dataIndex: 'stateGroupName',
      render: (stateGroupName: any, record: any) => (
        <div>
          {stateGroupName
            ? capitalizeFirstLetter(stateGroupName)
            : StateConstants[record.stateCodes] || record.stateCodes}
        </div>
      ),
    },
    {
      title: 'States',
      key: 'stateCode',
      dataIndex: 'stateCode',
      width: '700px',
      render: (text: any, record: any) => (
        <Space>
          <div style={{ display: 'flex' }}>
            {record.stateCodes.map((stateCode: string) => (
              <StatesCircled
                label={stateCode}
                key={stateCode}
                showTooltip
                tooltipTitle={getStateNameFromCode(stateCode)}
              />
            ))}
          </div>
        </Space>
      ),
    },
  ];

  const fetchAgency = async () => {
    const accessToken = await getAccessTokenSilently();
    individualOnboardStore.fetchAgency(accessToken).catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    fetchAgency();
    multipleOnboardStore.setMinStep(MultipleOnboardSteps.ASSIGNMENT);
  }, []);

  const handleRemoveAssignments = async () => {
    setRemoveLoading(true);
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const payload: updateProducerAssignmetPayload[] = [];

      let updatedAssignedStates = assignedStates.filter((d) => {
        if (d.stateGroupName) return !selectedRows.includes(d.stateGroupName);
        return !selectedRows.includes(getStateNameFromCode(d.stateCode));
      });

      updatedAssignedStates.forEach((d) => {
        payload.push({
          ...(d.stateGroupId ? { territoryId: d.stateGroupId } : {}),
          stateCode: d?.stateCode,
          licenseConfigurations: d?.licenseConfigs
            .flatMap((d) => d.loaids)
            .map((d) => d || '')
            .filter((d) => d),
        });
      });
      await AgencyService.updateBulkAssignmentsForProducer(
        multipleOnboardStore.bulkAgentsIds,
        payload,
        token
      );
      setAssignedStates(updatedAssignedStates);
      setGroupedData(getGroupedData(updatedAssignedStates));
    } catch (error: any) {
      console.error(error);
    } finally {
      setSelectedRows([]);
      setRemoveLoading(false);
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows.map((d) => d.stateGroupName));
    },
  };

  const getGroupedData = (assignedStates: AssignedState[]) => {
    const groupedData: groupedData[] = [];
    const states = assignedStates.filter((d) => !d.stateGroupId);
    states.forEach((d) => {
      groupedData.push({
        stateCodes: [d.stateCode],
        stateGroupName: getStateNameFromCode(d.stateCode),
      });
    });
    const territories = assignedStates.filter((d) => d.stateGroupId);
    const groupedTerritories = groupBy(territories, 'stateGroupName');
    Object.entries(groupedTerritories)?.forEach(([k, v]) => {
      groupedData.push({
        stateCodes: v?.map((d) => d.stateCode),
        stateGroupName: k,
      });
    });
    return groupedData;
  };

  const CommonFirstRowComponents = () => (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ marginTop: '10px' }}>
          <Typography.Paragraph
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: 'var(--secondary-color)',
            }}
          >
            Assign State or Territories
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{
              marginTop: '-15px',
            }}
          >
            <TextWithIcon
              iconColor="#97BFBF"
              textStyle={{
                fontSize: '12px',
                fontWeight: 500,
                color: '#97BFBF',
              }}
              text="Assign States or Territories to this producers"
              iconAlignment="left"
              icon={<InfoCircleOutlined />}
            />
          </Typography.Paragraph>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Button
            id={IdConstants.AGENTS.INDIVIDUAL_ONBOARDING.ASSIGN}
            className="button"
            size="large"
            style={{
              marginTop: '-42px',
              opacity: loading ? '50%' : '100%',
            }}
            onClick={() => setModalVisibility(true)}
            disabled={loading}
          >
            <Tooltip title={loading ? null : 'Add Assignments'}>
              <PlusCircleOutlined />
            </Tooltip>
          </Button>
          {isModalVisible && (
            <AssignModal
              currentAssignedStates={assignedStates}
              agentIds={multipleOnboardStore.bulkAgentsIds}
              handleVisibility={setModalVisibility}
              isModalVisible={isModalVisible}
              onClose={() => {}}
              reportId={multipleOnboardStore.reportId}
              callback={(agentIds, assignedStates) => {
                setGroupedData(getGroupedData(assignedStates));
                setAssignedStates(assignedStates);
              }}
              // homeState={agentsHomeState}
              responseCallback={(response) => {
                if (
                  response?.data?.data?.errorResponses &&
                  response?.data?.data?.errorResponses.length > 0
                ) {
                  showError(
                    generateAssignmentErrorMessage(response?.data?.data)
                  );
                }
              }}
            />
          )}

          {/* <FilterButton
            {...{
              filterInfoLoading,
              activeData: assignedStates,
              requestBody,
              isLoading: loading,
              setIsFilterVisible,
              buttonSize: 'large',
              styles: { marginTop: '-42px' },
            }}
          /> */}
        </div>
      </div>
    </div>
  );

  // useEffect(() => {
  //   if (homeStateMissingAgents.length !== 0) {
  //     setAssignmentErrorModalVisiblity(true);
  //   }
  // }, [homeStateMissingAgents]);

  return (
    <div>
      {contextHolder}
      <CommonFirstRowComponents />
      {!assignedStates.length ? (
        <div>
          {loading ? (
            <Skeleton active />
          ) : (
            <Card
              style={{
                borderColor: '#E6E6E6',
                backgroundColor: '#F7FCFC',
                textAlign: 'center',
              }}
            >
              <img alt="" src={emptyTable} />
              <Typography.Paragraph
                style={{
                  fontSize: '14px',
                  color: 'var(--secondary-color)',
                }}
              >
                No State/ Territory Assigned
              </Typography.Paragraph>
              <Tooltip title={loading ? null : 'Add Assignments'}>
                <Button
                  id={IdConstants.AGENTS.INDIVIDUAL_ONBOARDING.ASSIGN_NOW}
                  className="button"
                  onClick={() => setModalVisibility(true)}
                  disabled={loading}
                >
                  <PlusCircleOutlined />
                </Button>
              </Tooltip>
            </Card>
          )}
        </div>
      ) : (
        <div>
          <div>
            {selectedRows.length > 0 && (
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Paragraph
                  style={{
                    fontSize: '12px',
                    color: '#787878',
                    marginTop: '7px',
                  }}
                >
                  {selectedRows.length} selected
                </Typography.Paragraph>

                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Typography.Paragraph
                    style={{
                      fontSize: '12px',
                      color: '#787878',
                      marginRight: '10px',
                      marginTop: '7px',
                    }}
                  >
                    Remove Selected Assignment
                  </Typography.Paragraph>
                  <Button
                    style={{
                      backgroundColor: '#FF0000',
                      color: '#ffffff',
                      border: 'none',
                    }}
                    loading={removeLoading}
                    onClick={handleRemoveAssignments}
                  >
                    {' '}
                    Remove
                  </Button>
                </div>
              </div>
            )}
          </div>
          {loading ? (
            <Skeleton active />
          ) : (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#dff4f5',
                },
              }}
            >
              <Table
                rowKey={(record) => record.stateGroupName}
                columns={columns}
                dataSource={groupedData}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            </ConfigProvider>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(MultipleOnboardingAssignments);
