export enum niprEventSubtype {
  EntityInfo = 'entity_info',
  FeinLookup = 'fein_lookup',
  SsnLookup = 'ssn_lookup',
  LicenseLookup = 'license_lookup',
  CreateScbReport = 'create_scb_report',
  RetrieveScbReport = 'retrieve_scb_report',
  CreateGatewayTransaction = 'create_gateway_transaction',
  PollGatewayTransaction = 'poll_gateway_transaction',
}
